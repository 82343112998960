// import React, { useEffect, useState } from "react";
// import BaseApi from "../../../../Http/BaseApi";
// import { ChevronDoubleRightIcon } from "@heroicons/react/outline";

// const headers = {
//   title: { label: "Title of the Paper", path: "title" },
//   journal: { label: "Journal Name", path: "journal" },
//   journal_type: { label: "Journal Type", path: "journal_type" },
//   issn: { label: "ISSN", path: "issn" },
//   month: { label: "Month of Publish", path: "monthName" },
//   year: { label: "Year of Publish", path: "year" },
//   corresponding_principal: {
//     label: "Are you corresponding/principal author?",
//     path: "corresponding_principal",
//   },
//   funding_source: { label: "Funding Source", path: "funding_source" },
//   funding_source_details: {
//     label: "Funding Source Details",
//     path: "funding_source_details",
//   },
//   author_names: {
//     label: "Author Names: (As appeared in the article)",
//     path: "author_names",
//   },
//   url: { label: "URL", path: "url" },
//   doi: { label: "DOI", path: "doi" },
//   volume: { label: "Volume", path: "volume" },
//   issue: { label: "Issue", path: "issue" },
//   impact_factor: {
//     label: "Impact Factor (Thomson Reuters only)",
//     path: "impact_factor",
//   },
//   page_no: { label: "Page No/Paper No (Optional)", path: "page_no" },
//   editor_name: { label: "Editor Name", path: "editor_name" },
//   publisher_name: { label: "Publisher Name", path: "publisher_name" },
//   indexed_in: { label: "Indexed in", path: "indexed_in" },
//   indexed_in_others: {
//     label: "Indexed in others",
//     path: "indexed_in_others",
//   },
// };

// const getValueByPath = (obj, path) =>
//   path?.split(".").reduce((acc, part) => acc && acc[part], obj);

// const JournalPublication = ({ view }) => {
//   const [journalPublications, setJournalPublications] = useState([]); // Array to store publications
//   const [showAll, setShowAll] = useState(false); // State to toggle "Read more/less"

//   useEffect(() => {
//     getData();
//   }, []);

//   const getData = async () => {
//     try {
//       const response = await BaseApi().get("/profile/journal-publications");
//       const filterField = view === "iqac" ? "iqac" : "website";

//       // Filter for iqac or website and sort by year in descending order
//       const filteredPublications = response.data.data.filter(
//         (q) => q[filterField] === "yes"
//       );

//       const sortedPublications = filteredPublications.sort((a, b) => {
//         return b.year - a.year; // Sort by year (latest first)
//       });

//       setJournalPublications(sortedPublications);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const renderAPAStyle = (publication) => {
//     const authorNames = getValueByPath(publication, headers.author_names.path);
//     const year = getValueByPath(publication, headers.year.path);
//     const title = getValueByPath(publication, headers.title.path);
//     const journal = getValueByPath(publication, headers.journal.path);
//     const volume = getValueByPath(publication, headers.volume.path);
//     const issue = getValueByPath(publication, headers.issn.path);
//     const pageNo = getValueByPath(publication, headers.page_no.path);
//     const url = getValueByPath(publication, headers.url.path);
//     const impact_factor = getValueByPath(
//       publication,
//       headers.impact_factor.path
//     );

//     return (
//       <div className="pb-2 mb-2 border-b">
//         <p>
//           <span className="font-normal">{authorNames}</span> ({year}). {title}.
//           <i>{journal}</i>, <i>{volume}</i>({issue}), {pageNo}.
//           {url && (
//             <a
//               href={url}
//               target="_blank"
//               rel="noreferrer"
//               className="inline text-blue-500"
//             >
//               {url}
//             </a>
//           )}
//           {impact_factor && <>.IF: {impact_factor}</>}
//         </p>
//       </div>
//     );
//   };

//   // Limit to show only 5 items initially
//   const visiblePublications = showAll
//     ? journalPublications
//     : journalPublications.slice(0, 5);

//   return (
//     <div>
//       <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
//         Research Publications
//       </h2>

//       {journalPublications.length ? (
//         <div>
//           {visiblePublications.map((publication, index) => (
//             <div key={index} className="flex items-start px-2">
//               <span className="flex items-center justify-center w-6 h-6 p-2 mt-1 mr-2 text-gray-700 bg-blue-200 rounded-full">
//                 {index + 1}
//               </span>
//               {renderAPAStyle(publication)}
//             </div>
//           ))}

//           {/* Show Read More / Read Less based on state */}
//           <div className="text-center mt-2">
//             <button
//               className="text-sm text-blue-500 rounded hover:text-blue-900"
//               onClick={() => setShowAll(!showAll)}
//             >
//               {showAll ? "Read Less" : "Read More"}
//             </button>
//           </div>
//         </div>
//       ) : (
//         <p className="py-4 text-center text-gray-500">No data found</p>
//       )}
//     </div>
//   );
// };

// export default JournalPublication;

import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { useDispatch, useSelector } from "react-redux";

const headers = {
  title: { label: "Title of the Paper", path: "title" },
  journal: { label: "Journal Name", path: "journal" },
  journal_type: { label: "Journal Type", path: "journal_type" },
  issn: { label: "ISSN", path: "issn" },
  month: { label: "Month of Publish", path: "monthName" },
  year: { label: "Year of Publish", path: "year" },
  corresponding_principal: {
    label: "Are you corresponding/principal author?",
    path: "corresponding_principal",
  },
  funding_source: { label: "Funding Source", path: "funding_source" },
  funding_source_details: {
    label: "Funding Source Details",
    path: "funding_source_details",
  },
  author_names: {
    label: "Author Names: (As appeared in the article)",
    path: "author_names",
  },
  url: { label: "URL", path: "url" },
  doi: { label: "DOI", path: "doi" },
  volume: { label: "Volume", path: "volume" },
  issue: { label: "Issue", path: "issue" },
  impact_factor: {
    label: "Impact Factor (Thomson Reuters only)",
    path: "impact_factor",
  },
  page_no: { label: "Page No/Paper No (Optional)", path: "page_no" },
  editor_name: { label: "Editor Name", path: "editor_name" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed in", path: "indexed_in" },
  indexed_in_others: {
    label: "Indexed in others",
    path: "indexed_in_others",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const JournalPublication = ({ view }) => {
  const [journalPublications, setJournalPublications] = useState([]); // Array to store publications
  const [showAll, setShowAll] = useState(false); // State to toggle "Read more/less"
  const [profileCounts, setProfileCounts] = useState(null);
  const authData = useSelector((state) => state.auth);

  useEffect(() => {
    getData();
    getProfileCounts(authData.user.id);
  }, [authData]);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/journal-publications");
      const filterField = view === "iqac" ? "iqac" : "website";

      // Filter for iqac or website and sort by year in descending order
      const filteredPublications = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      const sortedPublications = filteredPublications.sort((a, b) => {
        return b.year - a.year; // Sort by year (latest first)
      });

      setJournalPublications(sortedPublications);
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileCounts = async (id) => {
    try {
      const response = await BaseApi().get(`profile-counts/${id}`); // Replace with the actual endpoint
      setProfileCounts(response.data); // Assuming response.data contains the count
    } catch (error) {
      console.log("Error fetching profile counts", error);
    }
  };

  const renderAPAStyle = (publication) => {
    const authorNames = getValueByPath(publication, headers.author_names.path);
    const year = getValueByPath(publication, headers.year.path);
    const title = getValueByPath(publication, headers.title.path);
    const journal = getValueByPath(publication, headers.journal.path);
    const volume = getValueByPath(publication, headers.volume.path);
    const issue = getValueByPath(publication, headers.issn.path);
    const pageNo = getValueByPath(publication, headers.page_no.path);
    const url = getValueByPath(publication, headers.url.path);
    const impact_factor = getValueByPath(
      publication,
      headers.impact_factor.path
    );

    return (
      <div className="pb-2 mb-2 border-b">
        <p>
          <span className="font-normal">{authorNames}</span> ({year}). {title}.
          <i>{journal}</i>, <i>{volume}</i>({issue}), {pageNo}.
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="inline text-blue-500"
            >
              {url}
            </a>
          )}
          {impact_factor && <>. IF: {impact_factor}</>}
        </p>
      </div>
    );
  };

  // Limit to show only 5 items initially
  const visiblePublications = showAll
    ? journalPublications
    : journalPublications.slice(0, 5);

  return (
    <div>
      {/* Header with count */}
      <div className="flex justify-between px-2 py-2 mb-3 border-b">
        <h2 className="text-xl font-medium text-blue-900">
          Research Publications
        </h2>
        <span className="px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full me-2 dark:bg-blue-900 dark:text-blue-300 mr-2">
          {journalPublications.length}
        </span>
      </div>

      {journalPublications.length ? (
        <div>
          {visiblePublications.map((publication, index) => (
            <div key={index} className="flex items-start px-2">
              <span className="flex items-center justify-center w-6 h-6 p-2 mt-1 mr-2 text-gray-700 bg-blue-200 rounded-full">
                {index + 1}
              </span>
              {renderAPAStyle(publication)}
            </div>
          ))}

          {/* Show Read More / Read Less based on state */}
          <div className="text-center mt-2">
            <button
              className="text-sm text-blue-500 rounded hover:text-blue-900"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default JournalPublication;
