import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import { getHostelsAsync } from "../../Redux/MasterSlice";
import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
import moment from "moment";
import { Form, Formik } from "formik";
import CustomSelect from "../../Components/Utils/CustomSelect";
import MakeHostelTransfer from "./MakeHostelTransfer";
import can from "../../Middleware/Permission";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function HostelTransfer() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [hostelers, setHostelers] = useState();
  const [searchInput, setSearchInput] = useState("");
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const hostels = [...master.hostels];

  const [selectedIds, setSelectedIds] = useState([]);

  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;
  const initialSearchValues = {
    hostel_id: "",
  };

  const validationSchema = Yup.object().shape({
    hostel_id: Yup.string().nullable().required("This field is required!"),
  });
  useEffect(() => {
    if (hostels.length === 0) {
      dispatch(getHostelsAsync())
        .unwrap()
        .then((data) => {
          if (data?.length == 1) {
            handleSearch({
              hostel_id: data[0].id,
            });
          }
        });
    } else {
      if (hostels?.length == 1) {
        handleSearch({
          hostel_id: hostels[0].id,
        });
      }
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callHostelers(searchQuery);
    setLoading(false);
  };

  async function callHostelers(searchQuery) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`hostelers-for-transfer`, {
        params: searchQuery,
      })
      .then((data) => {
        setHostelers(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  const handleCheckbox = (event) => {
    const target = event.target;
    var value = target.value;
    setSelectedIds((prevSelectedIds) => {
      if (target.checked && !prevSelectedIds.includes(value)) {
        return [...prevSelectedIds, value];
      } else if (!target.checked && prevSelectedIds.includes(value)) {
        return prevSelectedIds.filter((item) => item !== value);
      }
      return prevSelectedIds;
    });
    // if (target.checked) {
    //   console.log("value set");
    //   setSelectedIds([...selectedIds, value]);
    // } else {
    //   var index = selectedIds.indexOf(value);
    //   if (index != -1) {
    //     setSelectedIds(selectedIds.filter((item) => item != value));
    //   }
    // }
  };

  const reloadApplicant = async () => {
    setSelectedIds([]);
    // setHostel();
    callHostelers(formikRef.current.values);
  };

  console.log(selectedIds);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    console.log("all click");
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const allCheckboxes = document.querySelectorAll(
      'input[type="checkbox"].form-check-input'
    );
    allCheckboxes.forEach((checkbox) => {
      // console.log(checkbox);
      checkbox.checked = isChecked;
      handleCheckbox({
        target: checkbox,
      }); // call the existing handleCheckbox function to update the state
    });
  };

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='Hostel Transfer' />

            {hostels.length > 1 && (
              <Formik
                innerRef={formikRef}
                initialValues={initialSearchValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className='grid gap-2 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <CustomSelect
                      options={hostels}
                      name='hostel_id'
                      label='Select Hostel'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "hostel_id",
                          selectedOption.id
                        );
                      }}
                    />

                    <div className='p-5 mt-1 text-left '>
                      <button
                        type='submit'
                        className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && <span>Searching...</span>}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}
            {hostelers?.length > 0 && (
              <div>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='inline-block mt-5 text-gray-700 form-label'
                >
                  Search By Name
                </label>
                <input
                  className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                  placeholder='Type here...'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>
                  <th className='table-head'>
                    {" "}
                    <label>
                      <input
                        className='float-left w-8 h-8 mt-1 mr-5 text-blue-600 align-top transition duration-200 bg-white border border-gray-300 rounded-sm cursor-pointer focus:outline-none'
                        type='checkbox'
                        value='select-all'
                        checked={selectAll}
                        onChange={(event) => handleSelectAll(event)}
                      />
                      Select all
                    </label>
                  </th>

                  <th className='table-head'>Name</th>

                  <th className='table-head'>Enrollment ID</th>

                  <th className='table-head'>In Date</th>
                  <th className='table-head'>Out Date</th>
                  <th className='table-head'>Room No.</th>
                  <th className='table-head'>Bed No.</th>
                  <th className='table-head'>Guardian Contact No.</th>
                </tr>
              </thead>
              <tbody>
                {hostelers?.length > 0 &&
                  hostelers
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((hosteler) => {
                      return (
                        <>
                          <tr
                            className={
                              (hosteler.room_no == null ||
                                hosteler.in_date == null) &&
                              "bg-red-100"
                            }
                          >
                            <td className='table-data'>{++sl}</td>

                            <td className={"table-data" + " w-32"}>
                              <input
                                key={hosteler.id}
                                className='float-left w-8 h-8 mt-1 mr-5 text-blue-600 align-top transition duration-200 bg-white border border-gray-300 rounded-sm cursor-pointer form-check-input focus:outline-none'
                                type='checkbox'
                                value={hosteler.id}
                                onChange={(event) => handleCheckbox(event)}
                                id={hosteler.id}
                              />
                            </td>

                            <td className='table-data'>
                              {hosteler.student?.name} <br /> <br />
                              <span className='mt-2 text-red-500'>
                                {hosteler.remarks}
                              </span>
                            </td>
                            <td className='table-data'>
                              {hosteler.student?.programmes
                                .filter((p) => p.pivot.active == 1)
                                .map((prog) => {
                                  return (
                                    <span
                                      key={prog.pivot.id}
                                      className='text-blue-500 cursor-pointer'
                                      onClick={() =>
                                        window.open(
                                          `/admitted-students/${prog.pivot.id}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {
                                        <>
                                          {prog.pivot.unique_idd ||
                                            prog.pivot.unique_id}
                                        </>
                                      }

                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "15px",
                                          marginLeft: "1px",
                                        }}
                                      >
                                        {prog.pivot.discontinued == 1 &&
                                          "Programme Discontinued"}
                                        {prog.pivot.alumni == 1 && "Alumni"}
                                      </span>
                                      <br />
                                    </span>
                                  );
                                })}
                            </td>

                            <td className='table-data'>
                              {hosteler.in_date &&
                                moment(hosteler.in_date).format("Do MMM YYYY")}
                              {hosteler.not_reported == 1 && "Seat Not Taken"}
                            </td>
                            <td className='table-data'>
                              {hosteler.out_date &&
                                moment(hosteler.out_date).format("Do MMM YYYY")}
                            </td>
                            <td className='table-data'>{hosteler.room_no}</td>
                            <td className='table-data'>{hosteler.bed_no}</td>

                            <td className='table-data'>
                              {hosteler.guardian_contact_no}
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}

            {hostelers?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}

            <div className='m-10 text-center'>
              {can("Hostel Transfer") && (
                <Button
                  classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  value={"Proceed"}
                  icon={"ChevronDoubleRightIcon"}
                  onClick={() => {
                    if (selectedIds.length > 0) {
                      setShowModal(true);
                    } else {
                      alert("Please select atleast one student.");
                    }

                    // navigate(
                    //   `/applicants/make-admission/${applicant.id}`
                    // );
                  }}
                />
              )}
            </div>
            {showModal ? (
              <MakeHostelTransfer
                academic_session_id={
                  formikRef.current?.values?.academic_session_id
                }
                onMakeFeeCollection={reloadApplicant}
                selectedIds={selectedIds}
                hostels={hostels.filter(
                  (h) => h.id != formikRef.current.values?.hostel_id
                )}
                setShowModal={setShowModal}
                loading={loading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
