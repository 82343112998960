import React, { useRef } from "react";
import { Formik, Form } from "formik";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Button from "../../../Components/Utils/Button";

const WebsiteForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
}) => {
  const formikRef = useRef(null);
  const websiteType = [
    { id: "yes", name: "Yes" },
    { id: "no", name: "No" },
  ];

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className='p-5'>
        <CustomSelect
          // defaultInputValue={initialValues.visibility}
          options={websiteType}
          name='visibility'
          label='Select Website Visibility'
          isSearchable={true}
          getOptionValue={(option) => `${option.id}`}
          getOptionLabel={(option) => `${option.name}`}
          onChange={async (selectedOption) => {
            formikRef.current?.setFieldValue("visibility", selectedOption.id);
          }}
        />

        <div className='flex justify-between col-span-2 mt-10'>
          <Button
            type='back'
            value='Back'
            icon={"SaveIcon"}
            onClick={onCancel}
          />

          <Button type='submit' value='Save' icon={"SaveIcon"} />
        </div>
      </Form>
    </Formik>
  );
};

export default WebsiteForm;
