import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import Modal from "../../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../../utils/TableComponent";
import { toast } from "react-toastify";

const PresentResearchScholar = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [presentResearchScholars, setPresentResearchScholars] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    researcher_name: "",
    role: "",
    thesis_title: "",
    status: "Ongoing",
    advisoryCommittee: "",
    iqac: "",
  });

  const headers = {
    researcher_name: {
      label: "Name of the Ph. D. Scholar",
      path: "researcher_name",
    },
    role: { label: "Role", path: "role" },
    thesis_title: { label: "Thesis Title", path: "thesis_title" },
    status: { label: "Status", path: "status" },
    advisoryCommittee: {
      label: "Members of the Research Advisory Committee",
      path: "advisoryCommittee",
    },
  };

  const validationSchema = Yup.object({
    researcher_name: Yup.string().required("This field is required!"),
    role: Yup.string().required("This field is required!"),
    thesis_title: Yup.string().required("This field is required!"),
    status: Yup.string().required("This field is required!"),
    advisoryCommittee: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/present-research-scholars"
      );
      setPresentResearchScholars(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(
          `/profile/present-research-scholars/${editId}`,
          values
        );
        toast.success(`Present Research Scholar Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/present-research-scholars", values);
        toast.success("Present Research Scholar Added Successfully");
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = presentResearchScholars.find(
        (presentResearchScholar) => presentResearchScholar.id === id
      );
      setInitialValues({
        id: editableData.id,
        researcher_name: editableData.researcher_name,
        role: editableData.role,
        thesis_title: editableData.thesis_title,
        status: editableData.status,
        advisoryCommittee: editableData.advisoryCommittee,
        iqac: editableData.iqac,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      role: "",
      status: "Ongoing",
      advisoryCommittee: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      role: "",
      status: "Ongoing",
      advisoryCommittee: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    try {
      await BaseApi().delete(`/profile/present-research-scholars/${id}`);
      toast.success("Present Research Scholar Deleted Successfully");
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Present Research Scholar :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <>
              <FormPage
                partialEdit={
                  initialValues.iqac == "yes" &&
                  initialValues.status == "Ongoing"
                }
                initialValues={initialValues} // Pass initial values for editing
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isEditMode={isEditing}
              />
            </>
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            editable={true}
            editCheckField="status"
            headers={headers}
            data={presentResearchScholars}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default PresentResearchScholar;
