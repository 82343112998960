import React, { useEffect, useRef, useState } from "react";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import {
  getAcademicSessionsAsync,
  getFormsAsync,
} from "../../Redux/MasterSlice";
// import Select from "../../Components/Utils/Select";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Button from "../../Components/Utils/Button";
// import Select from "../../Components/Utils/Select";
import CustomSelect from "../../Components/Utils/CustomSelect";
import BaseApi from "../../Http/BaseApi";
import ReactToPrint from "react-to-print";
import Button from "../../Components/Utils/Button";
import can from "../../Middleware/Permission";
import { useNavigate } from "react-router-dom";
// import Input from "../../Components/Utils/Input";

export default function AttendanceSheet() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  // const [startPage, setStartPage] = useState();
  const [loading, setLoading] = useState(false);
  // const [maxPageToLoad] = useState(20);
  const [lastPage, setLastPage] = useState(0);
  const [examVenues, setExamVenues] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [initialValues, setInitialValues] = useState();
  // const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const forms = [...master.forms].filter((form) => {
    return [1, 2, 3, 4].includes(form.entrance_flag);
  });
  const academicSessions = [...master.academicSessions];
  const componentRef = useRef();
  const navigate = useNavigate();

  //   const dispatch = useDispatch();
  useEffect(() => {
    if (!can("View Attendance Sheet")) {
      navigate(`/not-found`);
    }
    setUpAuto();

    // setInitialValues({
    //   form_id: null,
    //   exam_venue_id: null,
    //   academic_session_id: null,
    //   subject_id: null,
    //   page: 1,
    // });

    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const setUpAuto = async () => {
    if (forms.length === 0) {
      await dispatch(getFormsAsync()).unwrap();
    }
    if (academicSessions.length === 0) {
      await dispatch(getAcademicSessionsAsync()).unwrap();
    }

    // const params = queryString.parse(window.location.search);
    const params = new URLSearchParams(window.location.search);

    setInitialValues({
      form_id: params.get("form_id") || null,
      exam_venue_id: params.get("exam_venue_id") || null,
      academic_session_id: params.get("academic_session_id") || null,
      subject_id: params.get("subject_id") || null,
      page: 1,
    });

    if (params.get("form_id")) {
      callExamVenue(params.get("form_id"));
      callSubjects(params.get("form_id"));
    }

    // console.log({
    //   form_id: params.get("form_id") || null,
    //   exam_venue_id: params.get("exam_venue_id") || null,
    //   academic_session_id: params.get("academic_session_id") || null,
    //   subject_id: params.get("subject_id") || null,
    //   page: 1,
    // });
  };

  useEffect(() => {
    if (
      initialValues &&
      initialValues.form_id &&
      initialValues.exam_venue_id &&
      initialValues.academic_session_id
    ) {
      formikRef.current?.submitForm();
    }
  }, [initialValues?.form_id]);

  const validationSchema = Yup.object().shape({
    form_id: Yup.string().nullable().required("This field is required!"),
    exam_venue_id: Yup.string().nullable().required("This field is required!"),

    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  // let pageCalled = 0;
  const handleSearch = async (searchQuery) => {
    // pageCalled++;

    setLoading(true);
    if (searchQuery.page == 1) {
      setApplicants([]);
    }
    // console.log(searchQuery);
    await BaseApi()
      .get(`attendance-sheet`, {
        params: searchQuery,
      })
      .then((response) => {
        const value = response.data;
        if (value.current_page == 1) {
          setApplicants(value.data);
        } else {
          setApplicants((prevMovies) => [...prevMovies, ...value.data]);
        }

        // console.log(value);
        let nextPage = ++searchQuery.page;
        setLastPage(value?.last_page);

        // if (nextPage <= value?.last_page && pageCalled <= maxPageToLoad) {
        if (nextPage <= value?.last_page) {
          // console.log(pageCalled, "called again");
          setInitialValues({
            form_id: searchQuery.form_id,
            academic_session_id: searchQuery.academic_session_id,
            exam_venue_id: searchQuery.exam_venue_id,
            subject_id: searchQuery.subject_id,
            page: nextPage,
          });
          handleSearch({
            form_id: searchQuery.form_id,
            academic_session_id: searchQuery.academic_session_id,
            exam_venue_id: searchQuery.exam_venue_id,
            subject_id: searchQuery.subject_id,
            page: nextPage,
          });
        } else {
          setInitialValues({
            form_id: searchQuery.form_id,
            academic_session_id: searchQuery.academic_session_id,
            exam_venue_id: searchQuery.exam_venue_id,
            subject_id: searchQuery.subject_id,
            page: 1,
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });

    console.log(applicants);
  };
  const callExamVenue = async (form_id) => {
    const examVenues = await BaseApi().get(`exam-venues`, {
      params: { form_id: form_id },
    });
    setExamVenues(examVenues.data);
  };

  const callSubjects = async (form_id) => {
    const subjects = await BaseApi().get(`subjects`, {
      params: { form_id: form_id },
    });
    setSubjects(subjects.data);
  };
  let thClass =
    "  border border-black    text-black   font-extrabold      tracking-normal leading-4";

  let tdClass =
    " border  border-black text-black    font-bold pl-4   tracking-normal leading-4";

  //   let sl = 0;
  // console.log(formikRef.current?.values?.academic_session_id);
  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='Attendance Sheet' />
            {initialValues?.form_id > 0 && (
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <CustomSelect
                      value={initialValues.form_id}
                      options={forms}
                      name='form_id'
                      label='Select Form'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "form_id",
                          selectedOption.id
                        );

                        callExamVenue(selectedOption.id);
                        callSubjects(selectedOption.id);
                      }}
                    />

                    <CustomSelect
                      value={initialValues.academic_session_id}
                      options={academicSessions}
                      name='academic_session_id'
                      label='Select Academic Session'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.label} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          selectedOption.id
                        );
                      }}
                    />
                    <CustomSelect
                      value={initialValues.exam_venue_id}
                      options={examVenues}
                      name='exam_venue_id'
                      label='Select Test Center'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.venue} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "exam_venue_id",
                          selectedOption.id
                        );
                      }}
                    />
                    <CustomSelect
                      options={subjects}
                      name='subject_id'
                      label='Select Subject (Only for DURAT)'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "subject_id",
                          selectedOption.id
                        );
                      }}
                    />
                    {/* <Input
                    type='number'
                    name='page'
                    label='Start Page'
                    placeholder='1'
                  /> */}
                    {/* 
                  <input
                    placeholder='Start Page'
                    className='w-32 border-2 '
                    onChange={(e) => setStartPage(e.target.value)}
                  /> */}
                    <div className='mt-5 text-left'>
                      <button
                        type='submit'
                        className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && (
                          <span>
                            Searching... {initialValues?.page} / {lastPage}
                          </span>
                        )}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
            {/* <Button
              classNames="float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            /> */}

            <ReactToPrint
              trigger={() => (
                <Button
                  classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 mb-5'
                  value={"Print"}
                  icon={"PrinterIcon"}
                />
              )}
              content={() => componentRef.current}
            />
            <div ref={componentRef}>
              <table className='w-full'>
                <tr>
                  <td
                    style={{ width: "60%" }}
                    className='p-3 text-center border border-black'
                  >
                    <p className='text-2xl '>Dibrugarh University</p>

                    <p className='text-2xl'>
                      {forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.code == "BEDCET" && <>B.Ed. Common Entrance Test</>}

                      {forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 3 &&
                        "Programme: " +
                          forms.find(
                            (form) =>
                              form.id == formikRef.current?.values?.form_id
                          )?.name}

                      {((forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 1 &&
                        forms.find(
                          (form) =>
                            form.id == formikRef.current?.values?.form_id
                        )?.code != "BEDCET") ||
                        forms.find(
                          (form) =>
                            form.id == formikRef.current?.values?.form_id
                        )?.entrance_flag == 4) &&
                        forms.find(
                          (form) =>
                            form.id == formikRef.current?.values?.form_id
                        )?.name}

                      {forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.name == "DURAT" &&
                        "Dibrugarh University Research Admission Test"}
                    </p>
                    <p className='text-xl'>
                      Session: &nbsp;
                      {
                        academicSessions.find(
                          (session) =>
                            session.id ==
                            formikRef.current?.values?.academic_session_id
                        )?.label
                      }
                    </p>
                  </td>
                  <td
                    style={{ width: "40%" }}
                    className='p-3 text-center border border-black'
                  >
                    <p className='text-xl font-bold'>
                      {forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 1 &&
                        forms.find(
                          (form) =>
                            form.id == formikRef.current?.values?.form_id
                        )?.code == "BEDCET" && <>B.Ed. CET - 2024</>}

                      {forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 2 && <>DURAT - 2024</>}
                    </p>

                    <p className='text-xl font-bold'>
                      {forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 3 && <>DUPGET - 2024</>}
                    </p>

                    <p className='text-xl font-bold'>
                      {(forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 1 ||
                        forms.find(
                          (form) =>
                            form.id == formikRef.current?.values?.form_id
                        )?.entrance_flag == 4) &&
                        forms.find(
                          (form) =>
                            form.id == formikRef.current?.values?.form_id
                        )?.code != "BEDCET" && <>ADMISSION TEST</>}
                    </p>

                    <p className='text-lg uppercase'>Attendance Sheet</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan='2' className='p-5 text-base border border-black'>
                    Center: &nbsp;
                    {
                      examVenues.find(
                        (venue) =>
                          venue.id == formikRef.current?.values?.exam_venue_id
                      )?.venue
                    }
                    <br />
                    {forms.find(
                      (form) => form.id == formikRef.current?.values?.form_id
                    )?.name == "DURAT" && (
                      <>
                        {" "}
                        Subject: &nbsp;
                        {
                          subjects?.find(
                            (subject) =>
                              subject.id ==
                              formikRef.current?.values?.subject_id
                          )?.name
                        }{" "}
                      </>
                    )}
                    <br />
                    Venue: &nbsp; ________________ <br />
                    Date: &nbsp;&nbsp;&nbsp; ________________
                  </td>
                </tr>
              </table>

              <table
                id='datatable'
                className='min-w-full bg-white dark:bg-gray-800'
              >
                <thead>
                  <tr className='w-full h-16 py-8 border-b border-gray-300 dark:border-gray-200'>
                    <th className={"pl-8" + thClass}>#</th>
                    <th className={thClass}>Roll No.</th>
                    <th className={thClass + "w-32"}>Name</th>
                    <th className={thClass}>Photo</th>

                    {((forms.find(
                      (form) => form.id == formikRef.current?.values?.form_id
                    )?.entrance_flag == 1 &&
                      forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.code != "BEDCET") ||
                      forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 4 ||
                      forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.entrance_flag == 2) && (
                      <>
                        <th className={thClass}>
                          Signature of <br /> the Candidate
                        </th>
                        <th className={thClass}>
                          Signature of the Candidate <br /> (To be signed at the{" "}
                          <br /> time of examination )
                        </th>
                      </>
                    )}
                    {(forms.find(
                      (form) => form.id == formikRef.current?.values?.form_id
                    )?.entrance_flag == 3 ||
                      forms.find(
                        (form) => form.id == formikRef.current?.values?.form_id
                      )?.code == "BEDCET") && (
                      <>
                        <th className={thClass}>Signature</th>
                        <th className={thClass}>
                          <table className='h-16 min-w-full bg-white'>
                            <tr>
                              <td
                                style={{ width: "50%" }}
                                className='text-sm border border-black'
                              >
                                OMR Sheet No.
                              </td>
                              <td
                                style={{ width: "50%" }}
                                className='text-sm border border-black'
                              >
                                Question Booklet No.
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{ width: "50%" }}
                                className='text-sm border border-black'
                              >
                                Question Booklet Set
                              </td>
                              <td
                                style={{ width: "50%" }}
                                className='text-sm border border-black'
                              >
                                Signature of the Candidate
                              </td>
                            </tr>
                          </table>
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {applicants.length > 0 &&
                    applicants.map((applicant, index) => {
                      return (
                        <>
                          <tr>
                            <td className={tdClass}>{++index}</td>
                            <td className={tdClass}>
                              {applicant.entrance_roll_no}
                            </td>
                            <td className={tdClass}>
                              <span
                                className='cursor-pointer'
                                onClick={() =>
                                  window.open(
                                    `/applicants/${applicant.id}`,
                                    "_blank"
                                  )
                                }
                              >
                                {applicant.student?.name}
                              </span>
                            </td>
                            <td className='w-32 font-bold leading-4 tracking-normal text-black border border-black whitespace-nowrap'>
                              <img
                                className='w-32 h-32 mx-auto '
                                src={applicant.student?.student_photo}
                                data-test={applicant.student?.student_photo}
                                onError={(e) => {
                                  console.log(e.target.src);
                                  if (e.target.src != "/assets/no-photo.jpg")
                                    e.target.src = "/assets/no-photo.jpg";
                                }}
                              />
                            </td>
                            {(forms.find(
                              (form) =>
                                form.id == formikRef.current?.values?.form_id
                            )?.entrance_flag == 3 ||
                              forms.find(
                                (form) =>
                                  form.id == formikRef.current?.values?.form_id
                              )?.code == "BEDCET") && (
                              <td className='w-32 font-bold leading-4 tracking-normal text-black border border-black whitespace-nowrap'>
                                {" "}
                                <img
                                  className='w-32 h-16 mx-auto'
                                  src={applicant.student?.student_signature}
                                  data-test={
                                    applicant.student?.student_signature
                                  }
                                  onError={(e) => {
                                    console.log(e.target.src);
                                    if (e.target.src != "/assets/no-sign.jpg")
                                      e.target.src = "/assets/no-sign.jpg";
                                  }}
                                />
                              </td>
                            )}

                            {((forms.find(
                              (form) =>
                                form.id == formikRef.current?.values?.form_id
                            )?.entrance_flag == 1 &&
                              forms.find(
                                (form) =>
                                  form.id == formikRef.current?.values?.form_id
                              )?.code != "BEDCET") ||
                              forms.find(
                                (form) =>
                                  form.id == formikRef.current?.values?.form_id
                              )?.entrance_flag == 4 ||
                              forms.find(
                                (form) =>
                                  form.id == formikRef.current?.values?.form_id
                              )?.entrance_flag == 2) && (
                              <>
                                <td className={tdClass}>
                                  <img
                                    className='w-32 h-16 mx-auto'
                                    src={applicant.student?.student_signature}
                                    data-test={
                                      applicant.student?.student_signature
                                    }
                                    onError={(e) => {
                                      console.log(e.target.src);
                                      if (e.target.src != "/assets/no-sign.jpg")
                                        e.target.src = "/assets/no-sign.jpg";
                                    }}
                                  />
                                </td>
                                <td className={tdClass}></td>
                              </>
                            )}

                            {(forms.find(
                              (form) =>
                                form.id == formikRef.current?.values?.form_id
                            )?.entrance_flag == 3 ||
                              forms.find(
                                (form) =>
                                  form.id == formikRef.current?.values?.form_id
                              )?.code == "BEDCET") && (
                              <td className='border border-black'>
                                <table className='w-full h-32 bg-white'>
                                  <tr>
                                    <td
                                      style={{ width: "50%" }}
                                      className='text-center border border-black text-stone-400'
                                    >
                                      OMR Sheet No.
                                    </td>
                                    <td
                                      style={{ width: "50%" }}
                                      className='text-center border border-black text-stone-400'
                                    >
                                      Question Booklet No.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{ width: "50%" }}
                                      className='pl-5 text-center border border-black text-stone-400'
                                    >
                                      Question Booklet Set
                                    </td>

                                    <td
                                      style={{ width: "50%" }}
                                      className='pl-5 text-center border border-black text-stone-400'
                                    >
                                      Signature of the Candidate
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
                {(forms.find(
                  (form) => form.id == formikRef.current?.values?.form_id
                )?.entrance_flag == 3 ||
                  forms.find(
                    (form) => form.id == formikRef.current?.values?.form_id
                  )?.code == "BEDCET") && (
                  <tfoot className={thClass}>
                    <tr>
                      <td colSpan='3'>
                        <br />
                        <br /> &nbsp;&nbsp;&nbsp;Signature of the Invigilator:
                        <br />
                        <br />
                      </td>
                      <td colSpan='2'>
                        <br />
                        <br />
                        Signature of the Observer: <br />
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan='3'>
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp; Name of the Invigilator: <br />
                        <br />
                      </td>
                      <td colSpan='2'>
                        <br />
                        <br />
                        Name of the Observer:
                        <br />
                        <br />
                      </td>
                    </tr>
                  </tfoot>
                )}

                {((forms.find(
                  (form) => form.id == formikRef.current?.values?.form_id
                )?.entrance_flag == 1 &&
                  forms.find(
                    (form) => form.id == formikRef.current?.values?.form_id
                  )?.code != "BEDCET") ||
                  forms.find(
                    (form) => form.id == formikRef.current?.values?.form_id
                  )?.entrance_flag == 4 ||
                  forms.find(
                    (form) => form.id == formikRef.current?.values?.form_id
                  )?.entrance_flag == 2) && (
                  <tfoot className={thClass}>
                    <tr>
                      <td colSpan='4'>
                        <br />
                        <br /> &nbsp;&nbsp;&nbsp;Signature of the Invigilator:
                        <br />
                        <br />
                        <br />
                      </td>
                      <td colSpan='2'>
                        <br />
                        <br />
                        Signature of the Officer in Charge: <br />
                        <br />
                        <br />
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>

            {applicants?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
