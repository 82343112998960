import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  title: { label: "Title of the Paper", path: "title" },
  journal: { label: "Journal Name", path: "journal" },
  issn: { label: "ISSN", path: "issn" },
  month: { label: "Month of Publish", path: "monthName" },
  year: { label: "Year of Publish", path: "year" },
  corresponding_principal: {
    label: "Are you corresponding/principal author?",
    path: "corresponding_principal",
  },
  funding_source: { label: "Funding Source", path: "funding_source" },
  funding_source_details: {
    label: "Funding Source Details",
    path: "funding_source_details",
  },
  author_names: {
    label: "Author Names: (As appeared in the article)",
    path: "author_names",
  },
  url: { label: "URL", path: "url" },
  doi: { label: "DOI", path: "doi" },
  volume: { label: "Volume", path: "volume" },
  page_no: { label: "Page No/Paper No (Optional)", path: "page_no" },
  editor_name: { label: "Editor Name", path: "editor_name" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed in", path: "indexed_in" },
  indexed_in_others: {
    label: "Indexed in others",
    path: "indexed_in_others",
  },
};

const IqacJournalPublication = () => {
  const [journalPublications, setJournalPublications] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/journal-publications?from_iqac=1"
      );
      setJournalPublications(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50'>
        <h1>Research Publications: </h1>
        <div className='flex items-end justify-center space-x-2 capitalize text-md'>
          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>From: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>To: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className=''>
            <Button
              type='filter'
              value='Filter'
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={journalPublications}
        type='journal-publications'
        setData={setJournalPublications}
      />
    </div>
  );
};

export default IqacJournalPublication;
