import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import { useNavigate } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

export default function LeaveCreateIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state) => state.auth);
  const [staff, setStaff] = useState([]);

  // const [selectedIds, setSelectedIds] = useState([]);
  const [leave_types, setLeaveTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  // const master = useSelector((state) => state.master);
  // const staffLeaveType = [...master.staffLeaveTypes];

  useEffect(() => {
    if (leave_types.length === 0) {
      setLoading(true);
      BaseApi()
        .get(`/leaves/remaining/${authData.user.id}`)
        .then((response) => {
          setLeaveTypes(response.data);
          // console.log('data is', response.data)
        })
        .catch((error) => {
          console.log("error is", error);
        });
      setLoading(false);
    }
    getStaff(authData.user.id);
  }, [dispatch]);

  const getStaff = async (id) => {
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        setStaff(data.data);
      })
      .catch(() => {
        // const message =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
        // setErrorMessage(message);
      });
    setLoading(false);
  };

  let thClass =
    " text-gray-600   border p-2  lg:whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 lg:whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Apply for Leave' />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className={thClass}>
                    Forwarding Authority:{" "}
                    {staff?.forwarding_authority?.full_name} <br />
                    Forwarding Authority 2:{" "}
                    {staff?.forwarding_authority2?.full_name}
                  </th>
                  <th className={thClass}>
                    Approving Authority: {staff?.approving_authority?.full_name}
                  </th>
                  <th className={thClass}>
                    <button
                      type='button'
                      className='px-2 py-1 mt-1 text-red-700 bg-red-100'
                      onClick={() => {
                        navigate(`/staff/edit-authority/${staff?.id}`);
                      }}
                    >
                      Edit Authority
                    </button>
                    <button
                      type='button'
                      className='px-2 py-1 mt-1 ml-1 text-orange-700 bg-orange-100'
                      onClick={() => {
                        navigate(`/profile`);
                      }}
                    >
                      Edit Profile
                    </button>
                  </th>
                </tr>
                <tr>
                  <th className={thClass}>Type</th>
                  <th className={thClass}> Remaining Leave</th>
                  <th colSpan='2' className={thClass}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {leave_types.length > 0 &&
                  leave_types.map((leave) => {
                    return (
                      <tr
                        key={leave.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass}>
                          {leave.id == 3 ? "Only " + leave.name : leave.name}
                        </td>
                        <td className={tdClass}>
                          {leave.id == 3 && <>N/A</>}
                          {[1, 2, 4, 5].includes(leave.id) && (
                            <>{leave.pivot?.no_of_leave - leave.count}</>
                          )}
                          {leave.id == 6 && leave.pivot?.no_of_leave == -1 && (
                            <>Not Calculated</>
                          )}
                          {leave.id == 6 && leave.pivot?.no_of_leave != -1 && (
                            <>
                              Accumulated {leave.pivot?.no_of_leave} as on{" "}
                              {moment(leave.pivot?.date).format("DD/MM/YYYY")}
                            </>
                          )}
                        </td>

                        <td className={tdClass}>
                          <Button
                            classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                            value={"Apply"}
                            icon={"ChevronDoubleRightIcon"}
                            onClick={() => {
                              if (staff.fathers_name == null) {
                                window.alert(
                                  "Please complete your profile first."
                                );
                                return;
                              }
                              if (staff.email_verified != 1) {
                                window.alert(
                                  "Please verify your email from edit profile"
                                );
                                return;
                              }
                              if (staff?.approver_id == null) {
                                window.alert(
                                  "Please Add Your Approving and/or Forwarding Authority."
                                );
                                return;
                              }
                              if (
                                staff?.type == 2 &&
                                staff?.forwarder_id == null
                              ) {
                                window.alert(
                                  "Please Add Your Forwarding Authority."
                                );
                                return;
                              }
                              if (
                                !window.confirm(
                                  `Please Confirm Your Approving and/or Forwarding  Authority. \n\nForwarding Authority: ${
                                    staff?.forwarding_authority?.full_name ?? ""
                                  } 
                                  \nForwarding Authority 2: ${
                                    staff?.forwarding_authority2?.full_name ??
                                    ""
                                  } 
                                  \nApproving Authority: ${
                                    staff?.approving_authority?.full_name ?? ""
                                  }
                                  `
                                )
                              ) {
                                return;
                              }
                              navigate(`/leaves/create/${leave.id}`);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
