import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import messageSlice from "./MessageSlice";
import BaseApi from "../Http/BaseApi";

const user = JSON.parse(localStorage.getItem("userData"));

// export const getLoggedInUserAsync = createAsyncThunk(
//     'auth/getLoggedInUserAsync',
//     async ()=>{
//          let userData = localStorage.getItem("userData");

//         return userData;

//     }
// );

export const postLoginAsync = createAsyncThunk(
  "auth/postLoginAsync",
  async (data, thunkAPI) => {
    const { payload } = data;

    try {
      const response = await BaseApi(payload.panel).post("login", payload);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const postChangePasswordAsync = createAsyncThunk(
  "auth/postChangePasswordAsync",
  async (data, thunkAPI) => {
    const { payload } = data;

    try {
      const response = await BaseApi(payload.panel).post(
        "change-password",
        payload
      );
      // thunkAPI.dispatch(messageSlice.actions.setMessage(response.data.message));

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await BaseApi()
    .post("logout")
    .then(() => {
      localStorage.removeItem("userData");
      localStorage.removeItem("lastAccess");
    })
    .catch(() => {
      localStorage.removeItem("userData");
      localStorage.removeItem("lastAccess");
    });
});

const initialState = user
  ? { isLoggedIn: true, user: user.user, panel: user.panel }
  : { isLoggedIn: false, user: null, panel: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    // [getLoggedInUserAsync.fulfilled]: (state, action)=>{
    //     console.log('local data found');
    //     return {...state, user: action.payload, isLoggedIn: true, errorMessage:''} ;
    // },

    [postLoginAsync.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.panel = action.payload.panel;
      localStorage.setItem("userData", JSON.stringify(action.payload));

      // return {...state, user: action.payload, isLoggedIn: true, errorMessage:''} ;
      // return {...state, companies: action.payload} ;
    },

    [postLoginAsync.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.panel = null;

      // return {...state, user: {}, isLoggedIn: false, errorMessage:'Invalid Credentials!'} ;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.panel = null;
    },
    [logout.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.panel = null;
    },
    [postChangePasswordAsync.fulfilled]: () => {},
  },
});

export default authSlice;
