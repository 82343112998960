import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import { getHostelsAsync } from "../../Redux/MasterSlice";
import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Alert from "../../Components/Utils/Alert";
// import Button from "../../Components/Utils/Button";
// import tableToCSV from "../../Components/Utils/TableToCSV";
// import Select from "../../Components/Utils/Select";
// import Select from "../../Components/Utils/Select";
// import moment from "moment";
// import BasicInput from "../../Components/Utils/BasicInput";
// import { toast } from "react-toastify";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";
import { toast } from "react-toastify";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { Form, Formik } from "formik";
import { monthNameList } from "../../Constants/months";

export default function HostelMessFees() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [CSV, setCSV] = useState(false);
  // const [edit, setEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [hostelers, setHostelers] = useState();
  const [messDetails, setMessDetails] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [year, setYear] = useState("");
  const [hostelId, setHostelId] = useState("");
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const hostels = [...master.hostels];
  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;

  const initialSearchValues = {
    hostel_id: "",
  };

  const validationSchema = Yup.object().shape({
    hostel_id: Yup.string().nullable().required("This field is required!"),
  });
  useEffect(() => {
    if (hostels.length === 0) {
      dispatch(getHostelsAsync())
        .unwrap()
        .then((data) => {
          console.log(data[0]);
          if (data?.length == 1) {
            handleSearch({
              hostel_id: data[0].id,
            });
          }
        });
    } else {
      if (hostels?.length == 1) {
        handleSearch({
          hostel_id: hostels[0].id,
        });
      }
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callHostelers(searchQuery);
    if (hostels?.length > 1) {
      await callMessFeeDetails(year, searchQuery.hostel_id);
    }

    setLoading(false);
  };

  async function callHostelers(searchQuery) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`hostelers`, {
        params: searchQuery,
      })
      .then((data) => {
        setHostelers(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }
  async function callMessFeeDetails(year, hostel_id) {
    console.log(hostel_id);
    setLoading(true);
    await BaseApi()
      .get(`hostel-mess-fees`, {
        params: { year, hostel_id },
      })
      .then((data) => {
        setYear(year);
        setHostelId(hostel_id);
        setMessDetails(data.data);
        console.log(data.data);
        console.log(messDetails);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  const handleUpdate = async (payload) => {
    await BaseApi()
      .post(`hostel-mess-fees`, payload)
      .then((data) => {
        toast.success("Data Updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        let _messDetails = [...messDetails];
        if (payload.status == true) {
          _messDetails.push(data.data);
          setMessDetails(_messDetails);
        } else {
          console.log("asdasd");
          console.log(payload);
          let _index = messDetails.findIndex((md) => {
            return (
              md.student_id == payload.student_id &&
              md.hostel_id == payload.hostel_id &&
              md.year == payload.year &&
              md.month == payload.month
            );
          });
          _messDetails.splice(_index, 1);
          console.log(_messDetails);
          setMessDetails(_messDetails);
        }

        setLoading(false);
      });
    setLoading(false);
  };

  //   const handleUpdate = async (payload) => {
  //     await BaseApi().post(`update-hostelers/${payload.id}`, payload);
  //     toast.success("Data Updated!", {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,

  //       progress: undefined,
  //     });
  //     let _hostelers = [...hostelers];
  //     console.log(_hostelers);
  //     if ("room_no" in payload) {
  //       _hostelers.find((h) => h.id == payload.id).room_no = payload.room_no;
  //     }

  //     if ("bed_no" in payload) {
  //       _hostelers.find((h) => h.id == payload.id).bed_no = payload.bed_no;
  //     }

  //     if ("in_date" in payload) {
  //       _hostelers.find((h) => h.id == payload.id).in_date = payload.in_date;
  //     }

  //     if ("out_date" in payload) {
  //       _hostelers.find((h) => h.id == payload.id).out_date = payload.out_date;
  //     }

  //     setHostelers(_hostelers);

  //     setLoading(false);
  //   };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  //   const handleDeletePayment = async (payment) => {
  //     await BaseApi().delete(`payments/${payment.id}`, {
  //       params: { remove_admission_permission: 1 },
  //     });
  //     let newPayments = payments.filter((e) => e.id != payment.id);
  //     setPayments(newPayments);
  //   };
  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <header className='rounded-sm '>
              <div className='flex justify-between px-5 py-2 mx-auto mt-1 max-w-7xl '>
                <h1 className='text-2xl font-bold '>
                  Hostel Mess Dues of{" "}
                  <select
                    onChange={(e) => {
                      if (hostels?.length == 1) {
                        callMessFeeDetails(e.target.value, hostels[0]?.id);
                      } else {
                        setYear(e.target.value);
                      }
                    }}
                    label='Select Year'
                    name='year'
                  >
                    <option selected value={""}>
                      Select
                    </option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </select>
                </h1>
                {/* <Button value={props.buttonText ?? "Add New"} icon={"PlusIcon"} /> */}
              </div>
            </header>

            {hostels.length > 1 && year && (
              <Formik
                innerRef={formikRef}
                initialValues={initialSearchValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className='grid gap-2 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <CustomSelect
                      options={hostels}
                      name='hostel_id'
                      label='Select Hostel'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "hostel_id",
                          selectedOption.id
                        );
                      }}
                    />

                    <div className='p-5 mt-1 text-left '>
                      <button
                        type='submit'
                        className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && <span>Searching...</span>}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={async () => {
                setCSV(true);
                setTimeout(function () {
                  tableToCSV("datatable");
                }, 1000);
                setTimeout(function () {
                  setCSV(false);
                }, 1000);
              }}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}

            {year && hostelId && (
              <>
                {hostelers?.length > 0 && (
                  <div>
                    <div></div>
                    <div>
                      <label
                        htmlFor='exampleFormControlInput1'
                        className='inline-block mt-5 text-gray-700 form-label'
                      >
                        Search By Name
                      </label>
                      <input
                        className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                        placeholder='Type here...'
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <table
                  id='datatable'
                  className='min-w-full mt-5 bg-white dark:bg-gray-800'
                >
                  <thead>
                    <tr>
                      <th className='table-head'></th>
                      <th colSpan='14' className='table-head'>
                        {hostels.length > 1 && (
                          <>
                            {hostels.find((h) => h.id == hostelId).name} -{" "}
                            {year}
                          </>
                        )}
                        {hostels.length == 1 && hostels[0]?.name} - {year}
                      </th>
                    </tr>
                    <tr>
                      <th className='table-head'>#</th>

                      <th className='table-head'>Name</th>

                      <th className='table-head'>Enrollment ID</th>
                      {monthNameList.map((m) => {
                        return (
                          <>
                            <th className='table-head'>{m}</th>
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {hostelers?.length > 0 &&
                      hostelers
                        .filter((p) => {
                          if (searchInput.length > 0) {
                            return (
                              p.student.name
                                .toString()
                                .toLowerCase()
                                .indexOf(searchInput.toLowerCase()) > -1
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((hosteler) => {
                          return (
                            <>
                              <tr>
                                <td className='table-data'>{++sl}</td>

                                <td className='table-data'>
                                  {hosteler.student?.name}
                                </td>
                                <td className='table-data'>
                                  {hosteler.student?.programmes
                                    .filter((p) => p.pivot.active == 1)
                                    .map((prog) => {
                                      return (
                                        <span
                                          key={prog.pivot.id}
                                          className='text-blue-500 cursor-pointer'
                                          onClick={() =>
                                            window.open(
                                              `/admitted-students/${prog.pivot.id}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          {
                                            <>
                                              {prog.pivot.unique_idd ||
                                                prog.pivot.unique_id}
                                            </>
                                          }

                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "15px",
                                              marginLeft: "1px",
                                            }}
                                          >
                                            {prog.pivot.discontinued == 1 &&
                                              "Programme Discontinued"}
                                            {prog.pivot.alumni == 1 && "Alumni"}
                                          </span>
                                          <br />
                                        </span>
                                      );
                                    })}

                                  {/* <span
                                    className='text-blue-500 cursor-pointer'
                                    onClick={() =>
                                      window.open(
                                        `/admitted-students/${
                                          hosteler.student?.programmes?.length >
                                            0 &&
                                          hosteler.student?.programmes[
                                            hosteler.student?.programmes
                                              .length - 1
                                          ].pivot.id
                                        }`,
                                        "_blank"
                                      )
                                    }
                                  >
                                   

                                    {hosteler.student?.programmes?.length >
                                      0 && (
                                      <>
                                        {hosteler.student?.programmes[
                                          hosteler.student?.programmes.length -
                                            1
                                        ].pivot.unique_idd ||
                                          hosteler.student?.programmes[
                                            hosteler.student?.programmes
                                              .length - 1
                                          ].pivot.unique_id}
                                      </>
                                    )}
                                  </span> */}
                                </td>
                                {monthNameList.map((month) => {
                                  return (
                                    <>
                                      {" "}
                                      <td className='table-data'>
                                        {(CSV || hostels.length > 1) && (
                                          <>
                                            {messDetails.some((md) => {
                                              return (
                                                md.student_id ==
                                                  hosteler?.student?.id &&
                                                md.hostel_id == hostelId &&
                                                md.year == year &&
                                                md.month == month
                                              );
                                            }) == true
                                              ? "Paid"
                                              : "Pending"}
                                          </>
                                        )}
                                        {!CSV && hostels.length == 1 && (
                                          <input
                                            className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                            type='checkbox'
                                            onChange={async (e) => {
                                              let conf =
                                                window.confirm("Are you sure!");
                                              if (conf == false) {
                                                return;
                                              }
                                              console.log(e.target.checked);
                                              e.target.disabled = true;
                                              await handleUpdate({
                                                student_id:
                                                  hosteler?.student?.id,
                                                hostel_id: hostelId,
                                                year: year,
                                                month: month,
                                                status: e.target.checked,
                                              }).catch((e) => {
                                                alert("Something Went Wrong!");
                                                e.target.disabled = false;
                                              });
                                              e.target.disabled = false;
                                              console.log(e.target.value);
                                            }}
                                            checked={messDetails.some((md) => {
                                              return (
                                                md.student_id ==
                                                  hosteler?.student?.id &&
                                                md.hostel_id == hostelId &&
                                                md.year == year &&
                                                md.month == month
                                              );
                                            })}
                                          />
                                        )}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                  </tbody>
                </table>
              </>
            )}
            {/* </Form>
            </Formik> */}
            {hostelers?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
