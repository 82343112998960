import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const HonorsAward = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [honorsAwards, setHonorsAwards] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    award_name: "",
    award_details: "",
    month: "",
    year: "",
    awarded_by: "",
  });
  const headers = {
    award_name: { label: "Award Name", path: "award_name" },
    award_details: { label: "Award Details", path: "award_details" },
    month: { label: "Month of the Award", path: "monthName" },
    year: { label: "Year of the Award", path: "year" },
    awarded_by: { label: "Awarded By", path: "awarded_by" },
  };

  const validationSchema = Yup.object({
    award_name: Yup.string().required("This field is required!"),
    award_details: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    // year: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("This field is required!")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    awarded_by: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/honors-awards");
      setHonorsAwards(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/honors-awards/${editId}`, values);
        toast.success(`Honors and Award Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/honors-awards", values);
        toast.success(`Honors and Award Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = honorsAwards.find(
        (honorsAward) => honorsAward.id === id
      );
      setInitialValues({
        id: editableData.id,
        award_name: editableData.award_name,
        award_details: editableData.award_details,
        month: editableData.month,
        year: editableData.year,
        awarded_by: editableData.awarded_by,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      award_name: "",
      award_details: "",
      month: "",
      year: "",
      awarded_by: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      award_name: "",
      award_details: "",
      month: "",
      year: "",
      awarded_by: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/honors-awards/${id}`);
      toast.success(`Honors and Award Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Honors and Awards :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={honorsAwards}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default HonorsAward;
