import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";

export default function NoticeCategories() {
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);

  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  const [noticeCategories, setNoticeCategories] = useState([]);

  const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    setInitialValues({
      id: null,
      name: "",
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (noticeCategories.length === 0) {
      getNoticeCategories();
    }

    // dispatch(getOldStudentsAsync());
  }, []);

  const getNoticeCategories = async () => {
    await BaseApi()
      .get(`noticeCategories`)
      .then((response) => {
        setNoticeCategories(response.data);
      });
  };
  const onEdit = (event, noticeCategory) => {
    event.preventDefault();
    setInitialValues({
      id: noticeCategory.id,
      name: noticeCategory.name,
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFormRole("edit");
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      console.log("asdsadsd");
      await BaseApi()
        .put(`noticeCategories/${payload.id}`, payload)
        .then(() => {
          getNoticeCategories();
        });
    } else {
      await BaseApi()
        .post(`noticeCategories`, payload)
        .then(() => {
          getNoticeCategories();
        });
    }
    setLoading(false);
    setFormRole("add");
    resetForm();
    setInitialValues({
      id: null,
      name: "",
    });
  };
  const handleDelete = async (event, payload) => {
    event.target.disabled = true;
    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`noticeCategories/${payload.id}`)
        .then(() => {
          getNoticeCategories();
        });
    }

    event.target.disabled = false;
    setDeleteConfirm({});
  };

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Notice Category Master' />

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <Input
                    label={
                      formRole === "add"
                        ? "Create New Notice Category"
                        : "Edit Notice Category"
                    }
                    name='name'
                    placeholder='Academic'
                  />

                  <div className='mt-5 text-left'>
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <Button
                        value={loading ? "Updating..." : "Update"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    )}
                  </div>
                </div>
              </Form>
            </Formik>

            <br />
            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>Name</th>
                  <th colSpan='2' className={thClass}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {noticeCategories.length > 0 &&
                  noticeCategories.map((noticeCategory, i) => {
                    return (
                      <tr
                        key={noticeCategory.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass + "w-32"}>{++i}</td>
                        <td className={tdClass}>{noticeCategory.name}</td>
                        <td className={tdClass}>
                          <button
                            className='px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700'
                            onClick={(event) => onEdit(event, noticeCategory)}
                          >
                            Edit
                          </button>
                        </td>
                        <td className={tdClass}>
                          {(deleteConfirm.state == 0 ||
                            deleteConfirm.itemId != noticeCategory.id) && (
                            <button
                              className='px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700'
                              onClick={() =>
                                setDeleteConfirm({
                                  itemId: noticeCategory.id,
                                  state: 1,
                                })
                              }
                            >
                              Delete
                            </button>
                          )}
                          {deleteConfirm.state == 1 &&
                            deleteConfirm.itemId == noticeCategory.id && (
                              <>
                                <p className='text-red-700'> Are You Sure? </p>

                                <button
                                  className='outline-button-danger'
                                  onClick={(event) =>
                                    handleDelete(event, noticeCategory)
                                  }
                                >
                                  Yes
                                </button>
                                <span className='mr-2'></span>
                                <button
                                  className='outline-button-success'
                                  onClick={() => setDeleteConfirm({})}
                                >
                                  No
                                </button>
                              </>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
