import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaFileLines,
  FaPeopleGroup,
  FaRegObjectUngroup,
} from "react-icons/fa6";

const headers = {
  collaboration_name: {
    label: "Name of the Institute/ Organization/Individual",
    path: "collaboration_name",
  },
  collaboration_type: {
    label: "Type of Collaboration",
    path: "collaboration_type",
  },
  other_collaboration: {
    label: "Other Collaboration",
    path: "other_collaboration",
  },
  from_date: { label: "From Date Collaboration", path: "from_date" },
  to_date: { label: "To Date Collaboration", path: "to_date" },
  collaboration_objective: {
    label: "Objective of the Collaboration",
    path: "collaboration_objective",
  },

  collaboration_details: {
    label: "Level of the collaboration",
    path: "collaboration_details",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const Collaboration = ({ view }) => {
  const [collaborations, setCollaborations] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/collaborations");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setCollaborations(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/collaborations");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredCollaborations = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by from_date in descending order
      const sortedCollaborations = filteredCollaborations.sort((a, b) => {
        const dateA = moment(getValueByPath(a, headers.from_date.path));
        const dateB = moment(getValueByPath(b, headers.from_date.path));
        return dateB - dateA; // Descending order
      });

      setCollaborations(sortedCollaborations);
    } catch (error) {
      console.error(error);
    }
  };

  const renderCollaboration = (collaboration) => {
    const collaborationName = getValueByPath(
      collaboration,
      headers.collaboration_name.path
    );
    const collaborationType = getValueByPath(
      collaboration,
      headers.collaboration_type.path
    );
    const otherCollaboration = getValueByPath(
      collaboration,
      headers.other_collaboration.path
    );
    const collaborationObjective = getValueByPath(
      collaboration,
      headers.collaboration_objective.path
    );
    const collaborationDetails = getValueByPath(
      collaboration,
      headers.collaboration_details.path
    );
    const fromDate = formatDate(
      getValueByPath(collaboration, headers.from_date.path)
    );
    const toDate = formatDate(
      getValueByPath(collaboration, headers.to_date.path)
    );

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>
            {collaborationName}
          </h3>

          <div className='flex items-center gap-1'>
            <FaPeopleGroup className={"text-yellow-500"} />
            Type:{" "}
            <span className=''>
              {collaborationType === "Others"
                ? otherCollaboration
                : collaborationType}
            </span>
          </div>

          <span className='flex items-center gap-1'>
            <FaFileLines className='text-yellow-500' />
            Level: {collaborationDetails}
          </span>
          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaRegObjectUngroup className='text-yellow-500' />
              Objective: {collaborationObjective}
            </span>

            <span className='flex items-center gap-1'>
              <FaCalendarDays className='text-yellow-500' />
              {fromDate} - {toDate}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        {/* <SiHiveBlockchain /> */}
        Collaboration
      </h2>
      {collaborations.length ? (
        <div className=''>
          {collaborations.map((collaboration, index) => (
            <div key={index} className=''>
              {renderCollaboration(collaboration)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Collaboration;
