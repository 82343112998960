import React from "react";
import Modal from "./Modal";
import { FaCheckSquare, FaCross, FaSquare, FaTimes } from "react-icons/fa";
import moment from "moment";

const IqacSubmitPreviewModal = ({
  data,
  type,
  selectedRows,
  headers,
  onSubmit,
  onCancel,
}) => {
  const headerValues = Object.values(headers).slice(0, 50);

  const getValueByPath = (obj, path) =>
    path?.split(".").reduce((acc, part) => acc && acc[part], obj);

  const isDate = (value) => {
    // Regex to match typical date formats e.g., 01/01/2000, 2000-01-01, etc.
    const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$|^\d{4}-\d{1,2}-\d{1,2}$/;
    return dateRegex.test(value) && !isNaN(Date.parse(value));
  };
  const formatDate = (value, format = "DD/MM/YYYY") => {
    // Format the date using moment
    return moment(value).format(format);
  };

  return (
    <Modal className='sm:max-w-lg'>
      <div className='p-4'>
        <h2 className='text-lg font-bold text-blue-900 capitalize '>
          Submit to {type}
        </h2>
        <h3 className='mb-4 text-sm font-medium text-blue-500'>
          Preview Selected Items
        </h3>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead>
            <tr>
              <th className='px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                #
              </th>
              <th className='px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                Selected
              </th>
              {headerValues.map((header, index) => (
                <th
                  key={index}
                  className='px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className='px-4 py-2 text-sm text-gray-900'>
                  {rowIndex + 1}
                </td>
                <td className='px-4 py-2 text-sm text-gray-900'>
                  {selectedRows.includes(row.id) ? (
                    <FaCheckSquare className='text-green-600' />
                  ) : (
                    <FaTimes className='text-red-500' />
                  )}
                </td>
                {headerValues.map((header, cellIndex) => (
                  <td
                    key={cellIndex}
                    className='px-4 py-2 text-sm text-gray-900'
                  >
                    {
                      // Check if the value is a date and format it
                      isDate(getValueByPath(row, header.path))
                        ? formatDate(getValueByPath(row, header.path))
                        : getValueByPath(row, header.path)
                    }
                    {/* {row[header.path]} */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='flex justify-end gap-4 mt-4'>
          <button
            className='flex items-center justify-center px-2 py-1 text-center text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
            onClick={onCancel}
          >
            Cancel
          </button>

          <button
            onClick={onSubmit}
            className='inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-indigo-700 focus:ring-indigo-500'
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IqacSubmitPreviewModal;
