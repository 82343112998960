import React, { useState } from "react";
import PresentResearchScholar from "./Components/PresentResearchScholar";
import PhdAwardee from "./Components/PhdAwardee";
import ExternalPhdScholar from "./Components/ExternalPhdScholar";
import ThesisExaminerUniversitie from "./Components/ThesisExaminerUniversitie";

const PhdGuidance = ({ view }) => {
  const [activeTab, setActiveTab] = useState(0); // Set initial activeTab to 0

  const tabs = [
    {
      id: 0,
      label: "Present Research Scholar",
      content: <PresentResearchScholar view={view ?? "website"} />,
    },
    {
      id: 1,
      label: "Ph. D. Awardee",
      content: <PhdAwardee view={view ?? "website"} />,
    },
    {
      id: 2,
      label: "External Ph. D. Scholar",
      content: <ExternalPhdScholar view={view ?? "website"} />,
    },
    {
      id: 3,
      label: "Thesis Examiner of other Universities",
      content: <ThesisExaminerUniversitie view={view ?? "website"} />,
    },
  ].filter((tab, index) => !(view === "website" && index === 3));

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className='relative max-w-full mx-auto'>
      <div className='flex w-full overflow-x-auto border-b border-gray-200'>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`${
              activeTab === tab.id
                ? "border-b-2 border-blue-600 text-blue-800"
                : "text-gray-500"
            } focus:outline-none py-2 px-4 mx-2`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className='mt-4'>
        {tabs.map((tab) => (
          <div key={tab.id}>
            {activeTab === tab.id && (
              <>
                <p>{tab.content}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhdGuidance;
