import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BaseApi from "../../../../Http/BaseApi";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import { toast } from "react-toastify";

const EContentDevelopment = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [eContentDevelopments, setEContentDevelopments] = useState([]);
  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    type: "",
    others_type: "",
    modules: "",
    enrolled_student: "",
    url: "",
    month: "",
    year: "",
  });
  const headers = {
    type: { label: "Type ", path: "type" },
    others_type: { label: "Others Type ", path: "others_type" },
    modules: { label: "Number of Modules", path: "modules" },
    enrolled_student: {
      label: "Number of Enrolled Students",
      path: "enrolled_student",
    },
    url: { label: "URL", path: "url" },
    month: { label: "Month of Publish", path: "monthName" },
    year: { label: "Year of Publish", path: "year" },
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("This field is required!"),
    others_type: Yup.string().when("type", {
      is: "Others",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    modules: Yup.string().required("This field is required!"),
    enrolled_student: Yup.string().required("This field is required!"),
    url: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/e-content-developments");
      setEContentDevelopments(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(
          `/profile/e-content-developments/${editId}`,
          values
        );
        toast.success(`E-Content Development Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/e-content-developments", values);
        toast.success(`E-Content Development Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = eContentDevelopments.find(
        (eContentDevelopment) => eContentDevelopment.id === id
      );
      setInitialValues({
        id: editableData.id,
        type: editableData.type,
        others_type: editableData.others_type,
        modules: editableData.modules,
        enrolled_student: editableData.enrolled_student,
        url: editableData.url,
        month: editableData.month,
        year: editableData.year,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      type: "",
      others_type: "",
      modules: "",
      enrolled_student: "",
      url: "",
      month: "",
      year: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      type: "",
      others_type: "",
      modules: "",
      enrolled_student: "",
      url: "",
      month: "",
      year: "",
    });
    setIsEditing(false);
    setIsAdding(false);
    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/e-content-developments/${id}`);
      toast.success(`E-Content Development Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>E-Content Development :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={eContentDevelopments}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default EContentDevelopment;
