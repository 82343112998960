import React, { useEffect, useRef, useState } from "react";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import {
  getAcademicSessionsAsync,
  getDistrictsAsync,
  getFormsAsync,
  getStatesAsync,
} from "../../Redux/MasterSlice";
import tableToCSV from "../../Components/Utils/TableToCSV";

// import Select from "../../Components/Utils/Select";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
import { getApplicantsAsync } from "../../Redux/ApplicantSlice";
import Button from "../../Components/Utils/Button";
import Alert from "../../Components/Utils/Alert";
import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Input from "../../Components/Utils/Input";
import moment from "moment";

export default function ApplicantIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();
  // const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const forms = [...master.forms];
  const states = [...master.states];
  const districts = [...master.districts];
  const academicSessions = [...master.academicSessions];

  let errorMessage = useSelector((state) => state.applicants.errorMessage);
  let lastPage = useSelector((state) => state.applicants.lastPage);
  let allApplicants = useSelector((state) => state.applicants.applicants);
  let allPrevExamData = useSelector((state) => state.applicants.prevExamData);
  let allExtraExamData = useSelector((state) => state.applicants.extraExamData);
  let allEntranceExamData = useSelector(
    (state) => state.applicants.entranceExamData
  );
  let allExtraData = useSelector((state) => state.applicants.extraData);
  // let formSections = useSelector((state) => state.applicants.formSections);

  //   const dispatch = useDispatch();
  console.log(allApplicants);
  useEffect(() => {
    setInitialValues({
      form_id: null,
      application_no: "",

      academic_session_id: null,
      page: 1,
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (forms.length === 0) {
      dispatch(getFormsAsync());
    }
    if (states.length === 0) {
      dispatch(getStatesAsync());
    }
    if (districts.length === 0) {
      dispatch(getDistrictsAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    form_id: Yup.string().nullable().required("This field is required!"),

    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  const handleSearch = async (searchQuery) => {
    setLoading(true);

    // console.log(searchQuery);
    await dispatch(getApplicantsAsync({ payload: searchQuery }))
      .unwrap()
      .then((value) => {
        console.log(value);
        let nextPage = ++searchQuery.page;
        if (nextPage <= value?.form_students?.last_page) {
          setInitialValues({
            form_id: searchQuery.form_id,
            application_no: searchQuery.application_no,
            academic_session_id: searchQuery.academic_session_id,
            page: nextPage,
          });
          handleSearch({
            form_id: searchQuery.form_id,
            application_no: searchQuery.application_no,
            academic_session_id: searchQuery.academic_session_id,
            page: nextPage,
          });
        } else {
          setInitialValues({
            form_id: searchQuery.form_id,
            application_no: searchQuery.application_no,
            academic_session_id: searchQuery.academic_session_id,
            page: 1,
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  let thClass =
    " border border-slate-100 text-gray-600  whitespace-nowrap dark:text-gray-400 font-normal px-2 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " border border-slate-100 text-gray-600 whitespace-nowrap dark:text-gray-400 font-normal px-2   text-left text-sm tracking-normal leading-4";
  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  let sl = 0;

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='New Applicants' />
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <Input
                    type='number'
                    name='application_no'
                    label='Application Number (Optional)'
                    placeholder='Enter Application Number'
                  />

                  <CustomSelect
                    options={forms}
                    name='form_id'
                    label='Select Form'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "form_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Academic Session'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && (
                        <span>
                          Searching... {initialValues?.page} / {lastPage}
                        </span>
                      )}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr className='w-full h-16 py-8'>
                  <th className={"pl-8" + thClass}>#</th>
                  <th className={thClass}>Application No.</th>
                  <th className={thClass}>Applied Date</th>
                  <th className={thClass}>Entrance Roll No.</th>
                  <th className={thClass}>Entrance Score</th>
                  <th className={thClass}>DHE ID</th>
                  <th className={thClass}>ABC ID</th>
                  <th className={thClass}>Permanent Resident of Assam </th>
                  <th className={thClass}>Center</th>
                  <th className={thClass}>Name</th>
                  <th className={thClass}>DOB</th>

                  <th className={thClass}>Phone Number</th>

                  <th className={thClass}>Email </th>
                  <th className={thClass}>Gender</th>
                  <th className={thClass}>Caste</th>
                  <th className={thClass}>Community</th>
                  <th className={thClass}>Religion</th>
                  <th className={thClass}>Nationality</th>
                  <th className={thClass}>Reservations</th>
                  <th className={thClass}>Specializations/Branch</th>

                  {allExtraExamData[0]?.length > 0 &&
                    allExtraExamData[0]?.map(() => (
                      <>
                        <th className={thClass}>Examination</th>
                        <th className={thClass}>Subject 1</th>
                        <th className={thClass}>Subject 1 Marks Secured</th>
                        <th className={thClass}>Subject 1 Outof</th>
                        <th className={thClass}>Subject 2</th>
                        <th className={thClass}>Subject 2 Marks Secured</th>
                        <th className={thClass}>Subject 2 Outof</th>
                        <th className={thClass}>Subject 3</th>
                        <th className={thClass}>Subject 3 Marks Secured</th>
                        <th className={thClass}>Subject 3 Outof</th>
                        <th className={thClass}>
                          Percent of Subject/Subjects{" "}
                        </th>
                      </>
                    ))}

                  {allPrevExamData[0]?.length > 0 &&
                    allPrevExamData[0]?.map(() => (
                      <>
                        <th className={thClass}>Examination Passed</th>
                        <th className={thClass}>Board/Council/University</th>
                        <th className={thClass}>Roll No.</th>
                        <th className={thClass}>Year of Passing</th>
                        <th className={thClass}>Subject Takens</th>
                        <th className={thClass}>Class/Division</th>
                        <th className={thClass}>Percentage(%)</th>
                      </>
                    ))}

                  {allEntranceExamData[0]?.length > 0 &&
                    allEntranceExamData[0]?.map(() => (
                      <>
                        <th className={thClass}>Entrance Examination</th>
                        <th className={thClass}>Roll No.</th>
                        <th className={thClass}>Score/Percentile</th>
                        <th className={thClass}>Rank</th>

                        <th className={thClass}>Year</th>
                      </>
                    ))}

                  {allExtraData[0]?.length > 0 &&
                    allExtraData[0]?.map((extra_field) => (
                      <>
                        <th className={thClass}>
                          {extra_field.section?.title}
                          <small>{extra_field.field?.name} </small>
                        </th>
                      </>
                    ))}
                  <th className={thClass}>Action</th>
                </tr>
              </thead>
              <tbody>
                {allApplicants?.length > 0 &&
                  allApplicants?.map(
                    (applicants, mainIndex) =>
                      applicants?.length > 0 &&
                      applicants?.map((applicant) => (
                        <tr
                          key={applicant.id}
                          className='h-24 border-b border-gray-300 dark:border-gray-200'
                        >
                          <td className={"pl-8" + tdClass}>{++sl}</td>
                          <td className={tdClass}>
                            <a
                              className='font-bold text-blue-600 cursor-pointer hover:text-blue-800 focus:ring-blue-500'
                              onClick={() => {
                                navigate(`/applicants/${applicant.id}`);
                              }}
                            >
                              {applicant.id}
                            </a>
                          </td>
                          <td className={tdClass}>
                            {moment(applicant.created_at).format("Do MMM YYYY")}
                          </td>
                          <td className={tdClass}>
                            {applicant.entrance_roll_no}
                          </td>
                          <td className={tdClass}>
                            {applicant.entrance_result?.marks}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.dhe_unique_id}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.abc_id}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.domicile == 1 ? "Yes" : "No"}
                          </td>

                          <td className={tdClass}>
                            {applicant.exam_venue?.venue}
                          </td>
                          <td className={tdClass}>{applicant.student?.name}</td>
                          <td className={tdClass}>{applicant.student?.dob}</td>
                          <td className={tdClass}>
                            {applicant.student?.phone}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.email}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.gender}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.hcaste?.name}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.community_id != null &&
                              applicant.student?.community_id != 9 && (
                                <>{applicant.student?.community?.name}</>
                              )}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.religion}
                          </td>
                          <td className={tdClass}>
                            {applicant.student?.nationality}
                          </td>
                          <td className={tdClass}>
                            {applicant.reservations?.length > 0 &&
                              applicant.reservations.map((reservation) => (
                                <> {reservation?.reservation.name}</>
                              ))}

                            {applicant.student?.domicile == 1 &&
                              applicant.student?.hcaste?.name &&
                              applicant.student?.hcaste?.name != "General" && (
                                <span
                                  className='badge'
                                  style={{ fontSize: ".875rem" }}
                                >
                                  {applicant.student?.hcaste?.name}
                                </span>
                              )}
                          </td>
                          <td className={tdClass}>
                            {applicant.subject_takens?.length > 0 &&
                              applicant.subject_takens.map(
                                (subjects, index) => (
                                  <span key={index}>
                                    Pref {subjects.preference}-
                                    <b>{subjects.subject.name}</b> <br />
                                  </span>
                                )
                              )}
                          </td>
                          {allExtraExamData[mainIndex]?.length > 0 &&
                            allExtraExamData[mainIndex]?.map((data) => {
                              let extra_exam = data["result"].find(
                                (data) =>
                                  data.student_id == applicant.student_id
                              );

                              return (
                                <>
                                  <td className={tdClass}>
                                    {extra_exam?.examination}
                                  </td>
                                  <td className={tdClass}>
                                    {extra_exam?.subject1}
                                  </td>
                                  <td className={tdClass}>
                                    {extra_exam?.sub1markssecured}
                                  </td>
                                  <td className={tdClass}>
                                    {extra_exam?.sub1outof}
                                  </td>

                                  <td className={tdClass}>
                                    {extra_exam?.subject2}
                                  </td>
                                  <td className={tdClass}>
                                    {extra_exam?.sub2markssecured}
                                  </td>
                                  <td className={tdClass}>
                                    {extra_exam?.sub2outof}
                                  </td>

                                  <td className={tdClass}>
                                    {extra_exam?.subject3}
                                  </td>
                                  <td className={tdClass}>
                                    {extra_exam?.sub3markssecured}
                                  </td>
                                  <td className={tdClass}>
                                    {extra_exam?.sub3outof}
                                  </td>

                                  <td className={tdClass}>
                                    {extra_exam?.totalpercent}
                                  </td>
                                </>
                              );
                            })}
                          {allPrevExamData[mainIndex]?.length > 0 &&
                            allPrevExamData[mainIndex]?.map((data) => {
                              let previous_exam = data["result"].find(
                                (data) =>
                                  data.student_id == applicant.student_id
                              );
                              // console.log(previous_exam);
                              return (
                                <>
                                  <td className={tdClass}>
                                    {previous_exam?.examination}
                                  </td>
                                  <td className={tdClass}>
                                    {previous_exam?.board}
                                  </td>
                                  <td className={tdClass}>
                                    {previous_exam?.mode}
                                  </td>
                                  <td className={tdClass}>
                                    {previous_exam?.yearofpassing}
                                  </td>
                                  <td className={tdClass}>
                                    {previous_exam?.subjects}
                                  </td>

                                  <td className={tdClass}>
                                    {previous_exam?.division}
                                  </td>
                                  <td className={tdClass}>
                                    {previous_exam?.percentage}
                                  </td>
                                </>
                              );
                            })}
                          {allEntranceExamData[mainIndex]?.length > 0 &&
                            allEntranceExamData[mainIndex]?.map((data) => {
                              let entrance_exam = data["result"].find(
                                (data) =>
                                  data.student_id == applicant.student_id
                              );
                              return (
                                <>
                                  <td className={tdClass}>
                                    {entrance_exam?.examination}
                                  </td>
                                  <td className={tdClass}>
                                    {entrance_exam?.roll_no}
                                  </td>
                                  <td className={tdClass}>
                                    {entrance_exam?.score}
                                  </td>
                                  <td className={tdClass}>
                                    {entrance_exam?.rank}
                                  </td>

                                  <td className={tdClass}>
                                    {entrance_exam?.year}
                                  </td>
                                </>
                              );
                            })}
                          {allExtraData[mainIndex]?.length > 0 &&
                            allExtraData[mainIndex]?.map((data) => {
                              let value = data["data"].find(
                                (data) =>
                                  data.student_id == applicant.student_id
                              );

                              //  if type of valus is undefined
                              if (typeof value == "undefined") {
                                return (
                                  <>
                                    <td className={tdClass}></td>
                                  </>
                                );
                              }

                              if (
                                data.field?.input_type == "select" &&
                                data.field?.input_data != null &&
                                data.field?.name == "State"
                              ) {
                                return (
                                  <>
                                    <td className={tdClass}>
                                      {
                                        states?.find((s) => s.id == value?.data)
                                          ?.name
                                      }
                                    </td>
                                  </>
                                );
                              } else if (
                                data.field?.input_type == "dependentSelect" &&
                                data.field?.input_data != null &&
                                data.field?.name == "District"
                              ) {
                                return (
                                  <>
                                    <td className={tdClass}>
                                      {
                                        districts?.find(
                                          (s) => s.id == value?.data
                                        )?.name
                                      }
                                    </td>
                                  </>
                                );
                              } else if (
                                data.field.input_type == "select" &&
                                data.field.input_datai != null
                              ) {
                                if (value?.data && value?.data != null) {
                                  let array =
                                    data?.field?.input_datai?.split(",");

                                  let position = array.indexOf(value?.data);

                                  let arrayv =
                                    data?.field?.input_datav?.split(",");

                                  value = arrayv[position];
                                  return (
                                    <>
                                      <td className={tdClass}>{value ?? ""}</td>
                                    </>
                                  );
                                }
                              } else {
                                return (
                                  <>
                                    <td className={tdClass}>
                                      {value?.data ?? ""}
                                    </td>
                                  </>
                                );
                              }
                            })}
                          <td className='pr-6'></td>
                        </tr>
                      ))
                  )}
              </tbody>
            </table>
            {allApplicants?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
