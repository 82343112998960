import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { toast } from "react-toastify";

import {
  getDepartmentsAsync,
  getDesignationsAsync,
} from "../../Redux/MasterSlice";
import SearchableDropdown from "../../Components/Utils/SearchableDropdown";
import { ThreeDots } from "react-loader-spinner";
// import CustomSelect from "../../Components/Utils/CustomSelect";

export default function EditAuthority() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  const [staff, setStaff] = useState([]);
  // const [singleStaff, setSingleStaff] = useState([]);
  const formikRef = useRef();
  const master = useSelector((state) => state.master);
  const departments = [...master.departments];
  const designations = [...master.designations];
  const authData = useSelector((state) => state.auth);

  const [formRole, setFormRole] = useState("edit");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
    if (designations.length === 0) {
      dispatch(getDesignationsAsync());
    }
    if (authData.user.id == id) {
      getStaff();
    } else {
      setErrorMessage("Unauthorised Access");
    }

    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getStaff() {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`staff_minimal`, {})
      .then((data) => {
        setStaff(data.data);
        let _singleStaff = data.data.find((e) => e.id == id);
        console.log(_singleStaff);
        setInitialValues({
          id: id,
          // forwarder_id: Yup.string().when("forwarder_idd", {
          //   is: (type) => type != null || type != "",
          //   then: Yup.string().required("This field is required!"),
          // }),
          forwarder_id: _singleStaff.forwarder_id,
          forwarder_idd: _singleStaff.forwarder_idd,
          approver_id: _singleStaff.approver_id,
        });
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  // const onEdit = (event, staff) => {
  //   event.preventDefault();
  //   setInitialValues({
  //     id: staff.id,
  //     name: staff.name,
  //     email: staff.email,
  //     phone: staff.phone,
  //   });

  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setFormRole("edit");
  // };

  const validationSchema = Yup.object().shape({
    approver_id: Yup.string().nullable().required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    console.log(payload);
    console.log(payload.forwarder_idd != null && payload.forwarder_idd != "");
    if (
      payload.forwarder_idd != null &&
      payload.forwarder_idd != "" &&
      (payload.forwarder_id == null || payload.forwarder_id == "")
    ) {
      alert(
        "You can not add second forwarding authority without first forwarding authority "
      );
      return;
    }
    if (
      payload.forwarder_idd != "" &&
      payload.forwarder_id != "" &&
      payload.forwarder_idd != null &&
      payload.forwarder_id != null &&
      payload.forwarder_idd == payload.forwarder_id
    ) {
      alert("Two forwarding authorities can not be same.");
      return;
    }
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      await BaseApi()
        .post(`staff/${payload.id}`, payload)
        .then((response) => {
          let _staff = [...staff];
          const index = _staff.findIndex((s) => s.id === response.data.id);
          _staff[index] = response.data;
          setStaff(_staff);
          toast.success("Data Updated!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
          toast.error(message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        });
    }

    setLoading(false);
    setFormRole("edit");
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Edit Authorities' />

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {initialValues && (
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <h3 className='pt-6 text-xl'></h3>

                  <div className='grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    {staff.length > 0 && initialValues && (
                      <>
                        <div>
                          {" "}
                          <SearchableDropdown
                            name='forwarder_id'
                            id='forwarder_id'
                            label='Forwarding Authority'
                            options={
                              authData?.user.type == 2
                                ? staff
                                    ?.filter(
                                      (s) =>
                                        [1, 2, 3].includes(s.type) &&
                                        s.status == 1
                                    )
                                    .sort((a, b) => {
                                      if (a.name < b.name) {
                                        return -1;
                                      }
                                      if (a.name > b.name) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                                : staff
                                    ?.filter(
                                      (s) =>
                                        [1, 3].includes(s.type) && s.status == 1
                                    )

                                    ?.sort((a, b) => {
                                      if (a.name < b.name) {
                                        return -1;
                                      }
                                      if (a.name > b.name) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                            }
                            defaultOption={staff.find(
                              (s) => s.id == initialValues.forwarder_id
                            )}
                            setSelectedOption={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "forwarder_id",
                                selectedOption.id ?? ""
                              );
                            }}
                          />
                          <small className='text-red-700'>
                            (Please leave this field blank if forwarding
                            authority not required)
                          </small>
                        </div>

                        <div>
                          {" "}
                          <SearchableDropdown
                            name='forwarder_idd'
                            id='forwarder_idd'
                            label='Forwarding Authority 2'
                            options={
                              authData?.user.type == 2
                                ? staff
                                    ?.filter(
                                      (s) =>
                                        [1, 2, 3].includes(s.type) &&
                                        s.status == 1
                                    )
                                    .sort((a, b) => {
                                      if (a.name < b.name) {
                                        return -1;
                                      }
                                      if (a.name > b.name) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                                : staff
                                    ?.filter(
                                      (s) =>
                                        [1, 3].includes(s.type) && s.status == 1
                                    )

                                    ?.sort((a, b) => {
                                      if (a.name < b.name) {
                                        return -1;
                                      }
                                      if (a.name > b.name) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                            }
                            defaultOption={staff.find(
                              (s) => s.id == initialValues.forwarder_idd
                            )}
                            setSelectedOption={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "forwarder_idd",
                                selectedOption.id ?? ""
                              );
                            }}
                          />
                          <small className='text-red-700'>
                            (Please leave this field blank if forwarding
                            authority 2 not required)
                          </small>
                        </div>
                      </>
                    )}

                    {staff.length > 0 && initialValues && (
                      <CustomSelect
                        defaultInputValue={
                          staff.find((s) => s.id == initialValues.approver_id)
                            ?.name
                        }
                        options={
                          authData?.user.type === 2
                            ? staff?.filter(
                                (s) => s.name === "Deputy Registrar(Admin)"
                              )
                            : staff?.filter(
                                (s) =>
                                  s.name === "Vice-Chancellor" ||
                                  s.name === "Registrar"
                              )
                        }
                        name='approver_id'
                        label='Approving Authority'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name}  `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "approver_id",
                            selectedOption.id
                          );
                        }}
                      />
                    )}
                    {/* <CustomSelect
                    options={staff}
                    name="forwarder_id"
                    label="Forwarding Authority"
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.designation?.name} -   ${option.department?.name})  `
                    }
                    defaultInputValue={"asd"}
                    onChange={(selectedOption) => {
                      formikRef.current?.setFieldValue(
                        "forwarder_id",
                        selectedOption.id
                      );
                    }}
                  /> */}
                    <div className='mt-5 text-left'>
                      {formRole === "add" ? (
                        <Button
                          value={loading ? "Saving..." : "Save"}
                          icon={"SaveIcon"}
                          type='submit'
                        />
                      ) : (
                        <>
                          <Button
                            value={loading ? "Updating..." : "Update"}
                            icon={"SaveIcon"}
                            type='submit'
                          />
                          <span className='mr-5'></span>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
