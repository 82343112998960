import React, { useEffect, useRef, useState } from "react";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

// import Select from "../../Components/Utils/Select";
// import moment from "moment";
import {
  getAcademicSessionsAsync,
  getHostelsAsync,
  getProgrammesAsync,
} from "../../../Redux/MasterSlice";
import PageTitle from "../../../Components/Common/PageTitle";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Button from "../../../Components/Utils/Button";
import tableToCSV from "../../../Components/Utils/TableToCSV";
import can from "../../../Middleware/Permission";
import BaseApi from "../../../Http/BaseApi";
import Alert from "../../../Components/Utils/Alert";
import MakeHostelFeeCollection from "./MakeHostelFeeCollection";
// import { SearchNor } from "../../Constants/SearchNor";

export default function HostelFeeCollection() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [hostel, setHostel] = useState();

  const [loading, setLoading] = useState(false);
  // const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const hostels = [...master.hostels];
  const [hostelers, setHostelers] = useState({});

  const academicSessions = [...master.academicSessions];
  const [selectedIds, setSelectedIds] = useState([]);

  //   const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(messageSlice.actions.clearMessage());
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    if (hostels.length === 0) {
      dispatch(getHostelsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const initialSearchValues = {
    hostel_id: "",
    academic_session_id: "",
  };
  const validationSchema = Yup.object().shape({
    hostel_id: Yup.string().nullable().required("This field is required!"),
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callHostelers(searchQuery);
    setHostel(hostels.find((h) => h.id == searchQuery.hostel_id));
    setLoading(false);
  };

  async function callHostelers(searchQuery) {
    console.log("as");
    setLoading(true);
    setHostelers({});
    await BaseApi()
      .get(`renewable-hostelers`, {
        params: searchQuery,
      })
      .then((data) => {
        setHostelers(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  const handleCheckbox = (event) => {
    const target = event.target;
    var value = target.value;
    setSelectedIds((prevSelectedIds) => {
      if (target.checked && !prevSelectedIds.includes(value)) {
        return [...prevSelectedIds, value];
      } else if (!target.checked && prevSelectedIds.includes(value)) {
        return prevSelectedIds.filter((item) => item !== value);
      }
      return prevSelectedIds;
    });
    // if (target.checked) {
    //   console.log("value set");
    //   setSelectedIds([...selectedIds, value]);
    // } else {
    //   var index = selectedIds.indexOf(value);
    //   if (index != -1) {
    //     setSelectedIds(selectedIds.filter((item) => item != value));
    //   }
    // }
  };
  console.log(selectedIds);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    console.log("all click");
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const allCheckboxes = document.querySelectorAll(
      'input[type="checkbox"].form-check-input'
    );
    allCheckboxes.forEach((checkbox) => {
      // console.log(checkbox);
      checkbox.checked = isChecked;
      handleCheckbox({
        target: checkbox,
      }); // call the existing handleCheckbox function to update the state
    });
  };

  const reloadApplicant = async () => {
    setSelectedIds([]);
    setHostel();
    callHostelers();
  };
  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full p-5 overflow-x-scroll'>
            <PageTitle name='Search Hostel Boarders ' />

            {hostels.length > 1 && (
              <Formik
                innerRef={formikRef}
                initialValues={initialSearchValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className='grid gap-2 p-5 pt-6  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <CustomSelect
                      options={hostels}
                      name='hostel_id'
                      label='Select Hostel'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "hostel_id",
                          selectedOption.id
                        );
                      }}
                    />
                    <CustomSelect
                      options={academicSessions.filter((ac) => ac.id == 5)}
                      // options={academicSessions}
                      name='academic_session_id'
                      label='Select Academic Session'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.label} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          selectedOption.id
                        );
                      }}
                    />

                    <div className='p-5 mt-1 text-left '>
                      <button
                        type='submit'
                        className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && <span>Searching...</span>}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr className='w-full h-16 py-8 border-b border-gray-300 dark:border-gray-200'>
                  <th className='table-head'>SL</th>
                  <th className='table-head'>
                    <label>
                      <input
                        className='float-left w-8 h-8 mt-1 mr-5 text-blue-600 align-top transition duration-200 bg-white border border-gray-300 rounded-sm cursor-pointer  focus:outline-none'
                        type='checkbox'
                        value='select-all'
                        checked={selectAll}
                        onChange={(event) => handleSelectAll(event)}
                      />
                      Select all
                    </label>
                  </th>
                  <th className='table-head'>ID</th>
                  <th className='table-head'>Name</th>

                  <th className='table-head'>Hostel</th>
                </tr>
              </thead>
              <tbody>
                {hostelers?.length > 0 &&
                  hostelers.map((hosteler, index) => {
                    return (
                      <>
                        <tr>
                          <td className='table-data'>{++index}</td>
                          <td className={"table-data" + " w-32"}>
                            <input
                              className='float-left w-8 h-8 mt-1 mr-5 text-blue-600 align-top transition duration-200 bg-white border border-gray-300 rounded-sm cursor-pointer form-check-input focus:outline-none'
                              type='checkbox'
                              value={hosteler.student.id}
                              onChange={(event) => handleCheckbox(event)}
                              id={hosteler.student.id}
                            />
                          </td>
                          <td className='table-data'>
                            <span
                              className='text-blue-500 cursor-pointer'
                              onClick={() =>
                                window.open(
                                  `/admitted-students/${
                                    hosteler.student?.programmes?.length > 0 &&
                                    hosteler.student?.programmes[
                                      hosteler.student?.programmes.length - 1
                                    ].pivot.id
                                  }`,
                                  "_blank"
                                )
                              }
                            >
                              {hosteler.student?.programmes?.length > 0 &&
                                (hosteler.student?.programmes[
                                  hosteler.student?.programmes.length - 1
                                ].pivot.unique_id ||
                                  hosteler.student?.programmes[
                                    hosteler.student?.programmes.length - 1
                                  ].pivot.unique_idd)}
                            </span>

                            <br />
                            <br />
                            <span
                              style={{
                                color: "red",
                                fontSize: "15px",
                              }}
                            >
                              {hosteler.student?.programmes?.length > 0 &&
                                hosteler.student?.programmes[
                                  hosteler.student?.programmes.length - 1
                                ].pivot.discontinued == 1 &&
                                "Programme Discontinued"}
                              {hosteler.student?.programmes?.length > 0 &&
                                hosteler.student?.programmes[
                                  hosteler.student?.programmes.length - 1
                                ].pivot.alumni == 1 &&
                                "Alumni"}
                            </span>
                          </td>
                          <td className='table-data'>
                            {hosteler.student?.name}
                          </td>{" "}
                          <td className='table-data'>
                            {hosteler.hostel?.name}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {hostelers.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
            <div className='m-10 text-center'>
              {can("Fee Collection") && (
                <Button
                  classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  value={"Proceed"}
                  icon={"ChevronDoubleRightIcon"}
                  onClick={() => {
                    if (selectedIds.length > 0) {
                      setShowModal(true);
                    } else {
                      alert("Please select atleast one student.");
                    }

                    // navigate(
                    //   `/applicants/make-admission/${applicant.id}`
                    // );
                  }}
                />
              )}
            </div>
          </div>

          {showModal ? (
            <MakeHostelFeeCollection
              academic_session_id={
                formikRef.current?.values?.academic_session_id
              }
              onMakeFeeCollection={reloadApplicant}
              selectedIds={selectedIds}
              hostel={hostel}
              setShowModal={setShowModal}
              loading={loading}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
