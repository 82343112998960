import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import { FaCheckSquare, FaIdCard, FaUser, FaUsers } from "react-icons/fa";
import { FaCalendarDays, FaLightbulb, FaRegMap } from "react-icons/fa6";
import moment from "moment";

const headers = {
  title: {
    label: "Title of the Invention",
    path: "title",
  },
  inventors_creators: {
    label: "Inventors",
    path: "inventors_creators",
  },
  applicant_name: {
    label: "Applicant’s Name",
    path: "applicant_name",
  },
  application_number: {
    label: "Application Number",
    path: "application_number",
  },
  status: {
    label: "Status",
    path: "status",
  },
  patent_number: {
    label: "Patent Number",
    path: "patent_number",
  },
  patent_granted_date: {
    label: "Patent Granted Date",
    path: "patent_granted_date",
  },
  filling_country: {
    label: "Filing Country",
    path: "filling_country",
  },
  invention: {
    label: "Field of Invention",
    path: "invention",
  },
  filling_date: {
    label: "Filing Date",
    path: "filling_date",
  },
  published_date: {
    label: "Published Date",
    path: "published_date",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const Patent = ({ view }) => {
  const [patents, setPatents] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/patents");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setPatents(response.data.data.filter((q) => q[filterField] === "yes"));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/patents");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredPatents = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by published_date (latest date first)
      const sortedPatents = filteredPatents.sort((a, b) => {
        const dateA = moment(getValueByPath(a, headers.published_date.path));
        const dateB = moment(getValueByPath(b, headers.published_date.path));
        return dateB - dateA; // Descending order
      });

      setPatents(sortedPatents);
    } catch (error) {
      console.error(error);
    }
  };

  const renderPatent = (honorsAward) => {
    const title = getValueByPath(honorsAward, headers.title.path);
    const inventorsCreators = getValueByPath(
      honorsAward,
      headers.inventors_creators.path
    );
    const applicantName = getValueByPath(
      honorsAward,
      headers.applicant_name.path
    );
    const applicationNumber = getValueByPath(
      honorsAward,
      headers.application_number.path
    );
    const status = getValueByPath(honorsAward, headers.status.path);
    const patentNumber = getValueByPath(
      honorsAward,
      headers.patent_number.path
    );
    const patentGrantedDate = getValueByPath(
      honorsAward,
      headers.patent_granted_date.path
    );
    const invention = getValueByPath(honorsAward, headers.invention.path);
    const fillingDate = getValueByPath(honorsAward, headers.filling_date.path);
    const fillingCountry = getValueByPath(
      honorsAward,
      headers.filling_country.path
    );
    const publishedDate = getValueByPath(
      honorsAward,
      headers.published_date.path
    );

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>

          <p className='flex items-center gap-2 text-green-600 whitespace-nowrap'>
            <FaUser />
            <span className='text-green-600'>{inventorsCreators}</span>
          </p>

          <div className='flex flex-wrap items-center gap-4'>
            <div className='flex items-center gap-1 whitespace-nowrap'>
              <span className='flex items-center gap-1'>
                <FaIdCard className='text-yellow-500' />
                Application No:
              </span>
              <span>{applicationNumber}</span>
            </div>

            <div className='flex items-center gap-1 whitespace-nowrap'>
              <FaLightbulb className='text-yellow-500' />
              {invention}
            </div>

            <span className='flex items-center gap-1 whitespace-nowrap'>
              <FaCheckSquare
                className={
                  status == "Published" ? "text-yellow-500" : "text-green-500"
                }
              />
              {status}
            </span>

            <div className='flex items-center gap-1 whitespace-nowrap'>
              <FaCalendarDays className='text-yellow-500' />
              Filed {moment(fillingDate).format("DD MMM YYYY")}
            </div>
            <div className='flex items-center gap-1 whitespace-nowrap'>
              <FaCalendarDays className='text-yellow-500' />
              Published {moment(publishedDate).format("DD MMM YYYY")}
            </div>

            {status == "Granted" && (
              <>
                <div className='flex items-center gap-1 whitespace-nowrap'>
                  <span className='flex items-center gap-1'>
                    <FaIdCard className='text-yellow-500' />
                    Patent No:
                  </span>
                  <span>{patentNumber}</span>
                </div>

                <div className='flex items-center gap-1 whitespace-nowrap'>
                  <FaCalendarDays className='text-yellow-500' />
                  Granted {moment(patentGrantedDate).format("YYYY-MM-DD")}
                </div>
              </>
            )}

            <div className='flex items-center gap-1 whitespace-nowrap'>
              <FaRegMap className='text-yellow-500' />
              {fillingCountry}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        <FaUsers />
        Patent
      </h2>
      {patents.length ? (
        <div>
          {patents.map((patent, index) => (
            <div key={index}>{renderPatent(patent)}</div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Patent;
