import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  fdp_name: { label: "Name of the FDP", path: "fdp_name" },
  start_date: { label: "FDP Start date", path: "start_date" },
  end_date: { label: "FDP End date", path: "end_date" },
  organizer: { label: "Organizer", path: "organizer" },
  mode: { label: "Mode of FDP", path: "mode" },
  details: { label: "Details of FDP", path: "details" },
};

const FIPActivitiesParticipation = () => {
  const [fIPActivitiesParticipations, setFIPActivitiesParticipations] =
    useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/fip-activities-participations"
      );
      setFIPActivitiesParticipations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        FIP activities/Participation in FDP
      </h2>
      <TableViewProfile
        headers={headers}
        data={fIPActivitiesParticipations}
        type='fip-activities-participations'
        setData={setFIPActivitiesParticipations}
      />
    </div>
  );
};

export default FIPActivitiesParticipation;
