import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  name: { label: "Name of the Professional Body", path: "name" },
  member_type: { label: "Member Type", path: "member_type" },
  month: { label: "Month of Joining", path: "monthName" },
  year: { label: "Year of Joining", path: "year" },
  till_month: { label: "Till Month", path: "tillMonthName" },
  till_year: { label: "Till Year", path: "till_year" },
};

const BodiesOrganization = () => {
  const [bodiesOrganizations, setBodiesOrganizations] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/bodies-organizations");
      setBodiesOrganizations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Memberships in Professional Bodies/Organization
      </h2>
      <TableViewProfile
        headers={headers}
        data={bodiesOrganizations}
        type="bodies-organizations"
        setData={setBodiesOrganizations}
      />
    </div>
  );
};

export default BodiesOrganization;
