import React, { useRef, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import Button from "../../../../Components/Utils/Button";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5";
import { XIcon } from "@heroicons/react/outline";
import Input from "../../../../Components/Utils/Input";

const FormPage = ({ initialValues, validationSchema, onSubmit, onCancel }) => {
  const formikRef = useRef(null);
  const [specializationCount, setSpecializationCount] = useState(
    Math.max(initialValues.specializations.length ?? 0, 1)
  );
  console.log(initialValues.specializations);

  const broadAreaTypes = [
    { id: 1, name: "Art and Culture" },
    { id: 2, name: "Language and Literature" },
    { id: 3, name: "History" },
    { id: 4, name: "Political Science" },
    { id: 5, name: "Sociology" },
    { id: 6, name: "Economics" },
    { id: 7, name: "Education" },
    { id: 8, name: "Commerce" },
    { id: 9, name: "Physics" },
    { id: 10, name: "Chemistry" },
    { id: 11, name: "Mathematics" },
    { id: 12, name: "Statistics" },
    { id: 13, name: "Anthropology" },
    { id: 14, name: "Pharmaceutical Sciences" },
    { id: 15, name: "Life Sciences" },
    { id: 16, name: "Applied Geology" },
    { id: 17, name: "Petroleum Technology" },
    { id: 18, name: "Philosophy" },
    { id: 19, name: "Law" },
    { id: 20, name: "Women’s Studies" },
    { id: 21, name: "Physical Education And Sports" },
    { id: 22, name: "Applied Psychology" },
    { id: 23, name: "Library and Information Science" },
    { id: 24, name: "Management Studies" },
    { id: 25, name: "Tea and Agro Studies" },
    { id: 26, name: "Computer Science and Applications" },
    { id: 27, name: "Atmospheric Studies" },
    { id: 28, name: "Journalism and Mass Communication" },
    { id: 29, name: "Biotechnology and Bioinformatics" },
    { id: 30, name: "Geography" },
    { id: 31, name: "Social Work Studies" },
    { id: 32, name: "Computer Science and Engineering" },
    { id: 33, name: "Petroleum Engineering" },
    { id: 34, name: "Mechanical Engineering" },
    { id: 35, name: "Electronics and Communication Engineering" },
    { id: 36, name: "Civil Engineering" },
    { id: 37, name: "Instrumentation Engineering" },
  ];

  const specializationTypes = [
    { id: 1, name: "Physics" },
    { id: 2, name: "Chemistry" },
    { id: 3, name: "Mathematics" },
  ];

  const handleAddSpecialization = () => {
    setSpecializationCount((prevCount) => prevCount + 1);
  };

  const handleRemoveSpecialization = (index) => {
    setSpecializationCount((prevCount) => prevCount - 1);
    formikRef.current?.setFieldValue(`specializations.${index}`, "");
  };

  return (
    <>
      <h1 className="flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase ">
        Expertise
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form className="p-5">
            <CustomSelect
              defaultInputValue={initialValues.broad_area}
              options={broadAreaTypes}
              name="broad_area"
              label={
                <div className="flex items-center">
                  Broad Area <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "broad_area",
                  selectedOption.name
                );
              }}
            />

            <FieldArray name="specializations">
              {() => (
                <>
                  {[...Array(specializationCount)].map((_, index) => (
                    <div key={index} className="flex items-center">
                      <div className="w-full">
                        <Input
                          name={`specializations.${index}`}
                          ltype="text"
                          label="Specialization/Area of Research "
                        />
                      </div>

                      {index === specializationCount - 1 && (
                        <div
                          className="flex items-center ml-2 cursor-pointer"
                          onClick={() => handleAddSpecialization()}
                        >
                          <IoAddCircle
                            className="mt-5 text-green-600"
                            size={32}
                          />
                        </div>
                      )}
                      {index !== specializationCount - 1 && (
                        <div
                          className="flex items-center ml-2 cursor-pointer"
                          onClick={() => handleRemoveSpecialization(index)}
                        >
                          <IoRemoveCircle
                            className="mt-5 text-red-600"
                            size={32}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
            <div className="mt-10 text-sm text-red-400">
              Click the{" "}
              <IoAddCircle className="inline text-green-600 " size={32} /> Icon
              to add more than one Specializations
            </div>

            <div className="flex justify-between col-span-2 mt-10">
              {/* <Button
              type="back"
              value="Back"
              icon={"SaveIcon"}
              onClick={onCancel}
            /> */}

              <button
                className="flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={onCancel}
              >
                <XIcon className="w-5 h-5 mr-2" /> Cancel
              </button>

              <Button type="submit" value="Update" icon={"SaveIcon"} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
