import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";
// import Input from "../../Components/Utils/Input";
import moment from "moment";
import messageSlice from "../../../Redux/MessageSlice";
import BaseApi from "../../../Http/BaseApi";
// import Select from "../../../Components/Utils/Select";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

export default function PaymentEdit({ onEdit, payment, setShowModal }) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("called again");
    dispatch(messageSlice.actions.clearMessage());

    setInitialValues({
      payment_id: payment.id,
      challan_no: payment.challan_no,
      //   type: payment.type,
      pay_date: moment(payment.pay_date).format("YYYY-MM-DD"),
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    payment_id: Yup.string().required("This field is required!"),

    challan_no: Yup.string().required("This field is required!"),
    pay_date: Yup.string().required("This field is required!"),
  });
  const handleSubmit = async (payload) => {
    setLoading(true);
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);

    const updatedPayment = await BaseApi().post(
      `payments/${payment.id}`,
      payload
    );
    setLoading(false);
    setInitialValues({
      payment_id: payment.id,
      challan_no: payment.challan_no,
      //   type: payment.type,
      pay_date: moment(payment.pay_date).format("YYYY-MM-DD"),
    });

    onEdit(updatedPayment.data);
    setShowModal(false);
  };

  console.log(message);
  return (
    <>
      <div className="justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative   w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}

          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                {payment.student?.name} - {payment.receipt_no}
                {"-"}
                {payment.id}
              </h3>
            </div>
            {/*body*/}
            <p style={{ width: "700px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className="relative p-6 flex-auto">
                    <div className="grid grid-cols-2 gap-4">
                      {/* <Select label="Select Payment Type" name="type">
                          <option>Select</option>
                          <option value={"Online"}>Online(Billdesk)</option>
                          <option value={"SBI Collect"}>SBI Collect</option>
                          <option value={"POS"}>POS</option>
                        </Select> */}

                      <Input label="Payment Date" type="date" name="pay_date" />
                      <Input label="Ref. No." name="challan_no" />
                    </div>

                    <p className="my-4 text-slate-500 text-lg leading-relaxed"></p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>

                    <Button
                      disabled={loading}
                      value={loading ? "Updating..." : "Update"}
                      icon={"SaveIcon"}
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
