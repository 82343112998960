import React, { useRef } from "react";
import { Formik, Form } from "formik";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Button from "../../../Components/Utils/Button";

const IqacForm = ({ initialValues, validationSchema, onSubmit, onCancel }) => {
  const formikRef = useRef(null);
  const iqacType = [
    { id: "yes", name: "Yes" },
    { id: "no", name: "No" },
  ];

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className='p-5'>
        <CustomSelect
          // defaultInputValue={initialValues.visibility}
          options={iqacType}
          name='visibility'
          label='Select IQAC Visibility'
          isSearchable={true}
          getOptionValue={(option) => `${option.id}`}
          getOptionLabel={(option) => `${option.name}`}
          onChange={async (selectedOption) => {
            formikRef.current?.setFieldValue("visibility", selectedOption.id);
          }}
        />

        <div className='flex justify-between col-span-2'>
          <Button
            type='back'
            value='Back'
            icon={"SaveIcon"}
            onClick={onCancel}
          />

          <Button type='submit' value='Save' icon={"SaveIcon"} />
        </div>
      </Form>
    </Formik>
  );
};

export default IqacForm;
