import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../Components/Common/PageTitle";

export default function AccountMasters() {
  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Account Master Settings" />
            <div className=" p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-6 gap-8">
              <Link
                to="/payment-heads"
                className="font-medium   rounded mx-10 p-10 border-indigo-300 border-2 text-center text-indigo-600 hover:text-indigo-500"
              >
                Master Payment Heads
              </Link>
              <Link
                to="/payment-groups"
                className="font-medium   rounded mx-10 p-10 border-indigo-300 border-2 text-center text-indigo-600 hover:text-indigo-500"
              >
                Master Payment Groups
              </Link>
              <Link
                to="/payment-group-heads"
                className="font-medium   rounded mx-10 p-10 border-indigo-300 border-2 text-center text-indigo-600 hover:text-indigo-500"
              >
                Assign Heads to Group
              </Link>
              <Link
                to="/payment-group-programmes"
                className="font-medium   rounded mx-10 p-10 border-indigo-300 border-2 text-center text-indigo-600 hover:text-indigo-500"
              >
                Assign Groups to Programme
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
