import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormPage from "./FormPage";
import { toast } from "react-toastify";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import Modal from "../../../utils/Modal";
import TableComponent from "../../../utils/TableComponent";

const Design = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [designs, setDesigns] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    creators: "",
    design_number: "",
    registration_date: "",
    country: "",
  });
  const headers = {
    title: {
      label: "Title of the Design",
      path: "title",
    },
    creators: {
      label: "Creators",
      path: "creators",
    },
    design_number: {
      label: "Design Number",
      path: "design_number",
    },
    registration_date: {
      label: "Registration Date",
      path: "registration_date",
    },
    country: {
      label: "Country",
      path: "country",
    },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    creators: Yup.string().required("This field is required!"),
    design_number: Yup.string().required("This field is required!"),
    registration_date: Yup.string().required("This field is required!"),
    country: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/designs");
      setDesigns(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/designs/${editId}`, values);
        toast.success(`Design Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/designs", values);
        toast.success(`Design Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = designs.find((design) => design.id === id);
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        creators: editableData.creators,
        design_number: editableData.design_number,
        registration_date: editableData.registration_date,
        country: editableData.country,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      creators: "",
      design_number: "",
      registration_date: "",
      country: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      creators: "",
      design_number: "",
      registration_date: "",
      country: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/designs/${id}`);
      toast.success(`Design Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Designs :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={designs}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default Design;
