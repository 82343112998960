import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";
// import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

import {
  // addPaymentGroupAsync,

  getPaymentGroupsAsync,
  getPaymentHeadsAsync,
  // updatePaymentGroupAsync,
} from "../../../Redux/MasterSlice";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
import CustomSelect from "../../../Components/Utils/CustomSelect";
// import BasicInput from "../../../Components/Utils/BasicInput";
import PaymentGroupHeadPreview from "./PaymentGroupHeadPreview";
import BaseApi from "../../../Http/BaseApi";
// import Input from "../../../Components/Utils/Input";
import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";
// import BasicInput from "../../../Components/Utils/BasicInput";

export default function PaymentGroupHead() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentGroupHeads, setPaymentGroupHeads] = useState([]);

  const { message } = useSelector((state) => state.message);

  const master = useSelector((state) => state.master);
  const paymentGroups = [...master.paymentGroups];
  const paymentHeads = [...master.paymentHeads];
  const [showModal, setShowModal] = useState(false);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (paymentGroups.length === 0) {
      dispatch(getPaymentGroupsAsync());
    }
    if (paymentHeads.length === 0) {
      dispatch(getPaymentHeadsAsync());
    }
    setInitialValues({
      payment_group_id: "",
      details: [],
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    payment_group_id: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);
    setLoading(true);
    await BaseApi().post(`payment-group-heads`, payload);

    setLoading(false);
    resetForm({ payment_group_id: "", details: [] });

    setInitialValues({
      payment_group_id: "",
      details: [],
    });
    setShowModal(false);
    dispatch(messageSlice.actions.setMessage("Saved Successfully"));
  };

  useEffect(() => {
    paymentHeads.map((head) => {
      const exists = paymentGroupHeads.find(
        (e) => e.payment_head_id === head.id
      );

      if (exists != null && initialValues) {
        let copy = { ...initialValues };
        let details = copy.details;
        details.push({
          payment_head_id: head.id,
          amount: exists.amount,
        });
        setInitialValues({
          payment_group_id: initialValues.payment_group_id,
          details,
        });

        // const details = [...initialValues.details].push({
        //   amount: 12,
        //   payment_head_id: head.id,
        // });

        // formikRef.current?.setFieldValue(
        //   `details[${index}].amount`,
        //   exists.amount
        // );

        // formikRef.current?.setFieldValue(
        //   `details[${index}].payment_head_id`,
        //   head.id
        // );
      } else if (initialValues) {
        console.log("null");
        let copy = { ...initialValues };
        let details = copy.details;
        console.log();
        details.push({
          payment_head_id: head.id,
          amount: null,
        });
        setInitialValues({
          payment_group_id: initialValues.payment_group_id,
          details,
        });

        // formikRef.current?.setFieldValue(`details[${index}].amount`, null);

        // formikRef.current?.setFieldValue(
        //   `details[${index}].payment_head_id`,
        //   head.id
        // );
      }
    });

    // dispatch(getOldStudentsAsync());
  }, [paymentGroupHeads]);

  const callPaymentGroupHeads = async (payment_group_id) => {
    setLoading(true);
    const _paymentGroupHeads = await BaseApi().get(`payment-group-heads`, {
      params: { payment_group_id: payment_group_id },
    });

    setPaymentGroupHeads(_paymentGroupHeads.data);

    setInitialValues({
      payment_group_id: payment_group_id,
      details: [],
    });
    setLoading(false);
  };

  console.log(initialValues);

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Assign Heads to Group" />
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=" py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                  <CustomSelect
                    options={paymentGroups}
                    name="payment_group_id"
                    label="Select Group"
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "payment_group_id",
                        selectedOption.id
                      );

                      callPaymentGroupHeads(selectedOption.id);
                    }}
                  />
                </div>

                {loading == true && (
                  <>
                    <div className="flex">
                      <span className="m-auto">
                        <ThreeDots
                          height="100"
                          width="100"
                          color="grey"
                          ariaLabel="loading"
                        />
                      </span>
                    </div>
                  </>
                )}
                <div className=" py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 pt-6 gap-8">
                  {initialValues?.details.map((head, index) => {
                    console.log("abc");
                    return (
                      <>
                        <Input
                          type="number"
                          label={
                            paymentHeads.find(
                              (e) => e.id == head.payment_head_id
                            )?.name
                          }
                          name={`details[${index}].amount`}
                          // onChange={(e) => {
                          //   console.log(e.target.value);
                          //   formikRef.current?.setFieldValue(
                          //     `details[${index}].amount`,
                          //     e.target.value
                          //   );

                          //   formikRef.current?.setFieldValue(
                          //     `details[${index}].payment_head_id`,
                          //     head.id
                          //   );
                          // }}
                        />
                      </>
                    );
                  })}
                </div>

                <div className="text-center mt-5">
                  <Button
                    type="button"
                    value="Preview"
                    icon={"EyeIcon"}
                    onClick={() => {
                      console.log(formikRef.current?.values);
                      setShowModal(true);
                    }}
                  />
                </div>

                {showModal ? (
                  <PaymentGroupHeadPreview
                    data={formikRef.current?.values}
                    setShowModal={setShowModal}
                    loading={loading}
                  />
                ) : null}
              </Form>
            </Formik>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}
