import React, { useEffect, useState } from "react";
import {
  AcademicCapIcon,
  BookOpenIcon,
  UserGroupIcon,
  UsersIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAsync,
  updateDashboardNotesAsync,
} from "../../Redux/MasterSlice";
import { Link, useNavigate } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Registering the components necessary for the bar chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function RNDDashboard() {
  const dispatch = useDispatch();
  const [savingNote, setSavingNote] = useState(false);
  const navigate = useNavigate();

  const master = useSelector((state) => state.master);
  const dashboardData = { ...master.dashboardData };

  const [note, setNote] = useState(dashboardData.dashboardNote);
  const [timer, setTimer] = useState(null);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      saveNote({ note: value });
    }, 1000);
    setTimer(newTimer);
  };

  const saveNote = async (noteContent) => {
    setSavingNote(true);
    console.log("Note auto-saved:", noteContent);
    // Simulating a save operation (e.g., API call)
    // localStorage.setItem("quickNote", noteContent);
    await dispatch(updateDashboardNotesAsync(noteContent)).unwrap();
    setSavingNote(false);
  };

  useEffect(() => {
    if (Object.keys(dashboardData).length === 0) {
      dispatch(getDashboardAsync());
    }
  }, []);
  useEffect(() => {
    setNote(dashboardData.dashboardNote);
  }, [dashboardData.dashboardNote]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Dashboard Stats */}
      <div className="grid grid-cols-3 gap-6 px-5 pt-5">
        <div
          onClick={() => navigate(`/registered-phd-students-records`, "_self")}
          className="flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl "
        >
          <UsersIcon className="w-12 h-12 text-red-600" />
          <h3 className="w-full mt-2 overflow-hidden text-lg font-semibold text-center text-gray-700 whitespace-nowrap overflow-ellipsis">
            Registered Ph. D. Scholars
          </h3>
          <p className="text-xl font-bold">{dashboardData.phdCount}</p>
        </div>{" "}
        <div
          onClick={() => navigate(`/phd-students-records`, "_self")}
          className="flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl "
        >
          <UsersIcon className="w-12 h-12 text-green-600" />
          <h3 className="w-full mt-2 overflow-hidden text-lg font-semibold text-center text-gray-700 whitespace-nowrap overflow-ellipsis">
            Not Registered Ph. D. Scholars
          </h3>
          <p className="text-xl font-bold">
            {dashboardData.phdNotRegisteredCount}
          </p>
        </div>
        <div
          onClick={() => navigate(`/dashboard`, "_self")}
          className="flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl "
        >
          <UsersIcon className="w-12 h-12 text-blue-600" />
          <h3 className="w-full mt-2 overflow-hidden text-lg font-semibold text-center text-gray-700 whitespace-nowrap overflow-ellipsis">
            Total Ph. D. Scholars
          </h3>
          <p className="text-xl font-bold">
            {dashboardData.phdNotRegisteredCount + dashboardData.phdCount}
          </p>
        </div>
      </div>

      {/* Recent Activity & Quick Actions */}
      <div className="grid grid-cols-2 gap-4 px-5 mt-8">
        {/* Quick Note Section */}
        <div className="w-full p-6 bg-white shadow-md rounded-xl ">
          <h2 className="pb-4 text-lg font-semibold text-gray-700 border-b">
            Quick Note
            <small className="float-right text-gray-400">
              {savingNote && "Saving..."}
            </small>
          </h2>
          <textarea
            className="w-full h-32 p-4 mt-2 text-base text-gray-700 bg-yellow-100 border-none rounded-md focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            placeholder="Type your notes here..."
            value={note}
            onChange={handleNoteChange}
          ></textarea>
        </div>
        {/* Quick Actions Section */}
        <div className="w-full p-6 bg-white shadow-md rounded-xl">
          <h2 className="pb-4 text-lg font-semibold text-gray-700 border-b">
            Quick Actions
          </h2>
          {/* Actions list placeholder */}
          <div className="mt-4">
            <div className="grid grid-cols-1 gap-2 p-2 ">
              <Link
                to="/phd-students-records"
                className="flex items-center justify-between w-full hover:bg-gray-50"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                Enrolled Ph. D. Scholars (Not Registered)
                <UsersIcon
                  className="inline w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
              </Link>
              <Link
                to="/registered-phd-students-records"
                className="flex items-center justify-between w-full hover:bg-gray-50"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                Registered Ph. D. Scholars Record
                <AcademicCapIcon
                  className="inline w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
              </Link>
              <Link
                to="/awarded-phd-students-records"
                className="flex items-center justify-between w-full hover:bg-gray-50"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                Ph. D. Awardees
                <AcademicCapIcon
                  className="inline w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
