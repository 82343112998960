import { ErrorMessage, Field } from "formik";
import React from "react";

export default function Select(props) {
  let classNames = props.readOnly ? "bg-gray-100" : "";

  return (
    <>
      <div className="">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {props.label}
        </label>

        <Field
          as="select"
          id={props.id}
          name={props.name}
          autoComplete=""
          disabled={props.disabled}
          className={`rounded-none mt-2 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
        >
          {props.children}
        </Field>
        <ErrorMessage
          name={props.name}
          component="div"
          className="text-red-500 py-2"
        />
      </div>
    </>
  );
}
