import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import BaseApi from "../../../../Http/BaseApi";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);

  const formikRef = useRef(null);
  const [organizationMasters, setOrganizationMasters] = useState([]);

  useEffect(() => {
    getOrganizationMasters();
  }, []);

  const getOrganizationMasters = async () => {
    try {
      const response = await BaseApi().get("/organization-masters");
      console.log("Organization Masters:", response.data);
      setOrganizationMasters(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Experience
      </h1>
      {organizationMasters.length > 0 && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className='grid grid-cols-2 gap-3 p-5 '>
            <Input
              type='text'
              name='institute_organization'
              label={
                <div className='flex items-center'>
                  Institute/Organization
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              placeholder='Your Institute/Organization'
            />

            <Input
              type='text'
              name='department'
              label={
                <div className='flex items-center'>
                  Department <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              placeholder='Department'
            />

            <CustomSelect
              defaultInputValue={
                organizationMasters?.find(
                  (organization) =>
                    organization.id == initialValues.organization_master_id
                )?.name
              }
              options={organizationMasters}
              name='organization_master_id'
              label={
                <div className='flex items-center'>
                  Organization Type
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "organization_master_id",
                  selectedOption.id
                );
              }}
            />

            <Input
              type='text'
              name='organization_url'
              label={
                <div className='flex items-center'>
                  Organization&apos;s Website
                </div>
              }
              placeholder="Organization's Website"
            />

            <Input
              type='text'
              name='designation'
              label={
                <div className='flex items-center'>
                  Designation
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              placeholder='Designation'
            />

            <Input
              type='date'
              name='start_date'
              label={
                <div className='flex items-center'>
                  Start Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              placeholder='Start Date'
            />

            <Input
              type='date'
              name='end_date'
              label='End Date'
              placeholder='End Date'
            />

            <div className='flex justify-between col-span-2 mt-10'>
              {/* <Button
                type='back'
                value='Back'
                icon={"SaveIcon"}
                onClick={onCancel}
              /> */}

              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default FormPage;
