import React from "react";
import { Link } from "react-router-dom";
import * as HIcons from "@heroicons/react/outline";

function SideBarLink(props) {
  const { ...icons } = HIcons;
  const TheIcon = icons[props.icon];

  // const SvgIcon = (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="24"
  //     height="24"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     stroke="#000000"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   >
  //     <path d="M9 18l6-6-6-6" />
  //   </svg>
  // );
  return (
    <Link
      to={props.to}
      className="p-2 mt-3 flex items-center rounded-md px-1 duration-300 cursor-pointer hover:bg-gray-200 text-gray-900"
      href="#!"
      data-mdb-ripple="true"
      data-mdb-ripple-color="primary"
    >
      <TheIcon className="h-4 w-4 mr-2 text-gray-900" aria-hidden="true" />
      <span>{props.label}</span>
    </Link>
  );
}

export default SideBarLink;
