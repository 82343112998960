import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import Input from "../../Components/Utils/Input";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
// import ActionConfirm from "../../Components/Utils/ActionConfirm";
import { getDepartmentsAsync } from "../../Redux/MasterSlice";
import { getDesignationsAsync } from "../../Redux/MasterSlice";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Select from "../../Components/Utils/Select";
import Upload from "../../Components/Utils/Upload";

export default function StaffCreate() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [staff, setStaff] = useState([]);

  const [formRole, setFormRole] = useState("add");

  const master = useSelector((state) => state.master);
  const departments = [...master.departments];
  const designations = [...master.designations];
  const authData = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    setInitialValues({
      id: null,
      name: "",
      email: "",
      phone: "",
      // password: "",
      department_id: "",
      designation_id: "",
      category: "",
      religion: "",
      gender: "",
      pwd: "",
      type: "",
      dob: "",
      dojoining: "",
      nature_of_employee: "",
      // rank: "",
      // pbr: "",
      pan: "",
      account_no: "",
      ifsc: "",
      branch: "",
      bank: "",
      blood_group: "",
      address: "",
      // fp_id: "",
      emergency_contact: "",
      photo: "",
      // signature: "",
      // remarks: "",
      salutation: "",
      // casual_count: "",
      // restricted_count: "",
      // station_count: "N/A",
      // duty_count: "",
      // special_casual_count: "",
      // earned_count: "",
    });
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
    if (designations.length === 0) {
      dispatch(getDesignationsAsync());
    }
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    email: Yup.string().when("type", {
      is: (type) => type !== "6",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(),
    }),
    phone: Yup.string().when("type", {
      is: (type) => type !== "6",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(),
    }),
    type: Yup.string().required("This field is required!"),
    gender: Yup.string().required("This field is required!"),
    // account_no: Yup.string().required("This field is required!"),

    department_id: Yup.string().required("This field is required!"),
    designation_id: Yup.string().required("This field is required!"),

    // pan: Yup.string().nullable().required("This field is required!"),
    dob: Yup.string().nullable().required("This field is required!"),
    nature_of_employee: Yup.string()
      .nullable()
      .required("This field is required!"),
    // casual_count: Yup.number()
    //   .typeError("Please enter a number")

    //   .integer("Can't include a decimal point")
    //   .min(0, "Can't be a negative number")
    //   .when("type", {
    //     is: (type) => type == 3,
    //     then: Yup.number().max(12, "Maximum 12 for Officers"),
    //   })
    //   .when("type", {
    //     is: (type) => type == 1,
    //     then: Yup.number().max(8, "Maximum 8 for Teachers"),
    //   })

    //   .required("This field is required!"),
    // restricted_count: Yup.number()
    //   .typeError("Please enter a number")

    //   .integer("Can't include a decimal point")
    //   .min(0, "Can't be a negative number")
    //   .max(2, "Must be less than or equal to 2")
    //   .required("This field is required!"),
    // station_count: Yup.string().required("This field is required!"),
    // duty_count: Yup.string().required("This field is required!"),
    // special_casual_count: Yup.string().required("This field is required!"),

    // password: Yup.string().when("id", {
    //   is: (id) => id == null,
    //   then: Yup.string().required("This field is required!"),
    // }),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    console.log("ad");
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      // await BaseApi()
      //   .post(`staff/${payload.id}`, payload)
      //   .then((response) => {
      //     let _staff = [...staff];
      //     const index = _staff.findIndex((s) => s.id === response.data.id);
      //     _staff[index] = response.data;
      //     setStaff(_staff);
      //   })
      //   .catch((error) => {
      //     const message =
      //       (error.response &&
      //         error.response.data &&
      //         error.response.data.message) ||
      //       error.message ||
      //       error.toString();
      //     setErrorMessage(message);
      //   });
    } else {
      await BaseApi()
        .post(`staff`, payload)
        .then((s) => {
          let _staff = [...staff];
          _staff.push(s.data);

          setStaff(_staff);

          resetForm();
          setInitialValues({
            id: null,
            name: "",
            email: "",
            phone: "",
            // password: "",
            department_id: "",
            designation_id: "",
            category: "",
            religion: "",
            gender: "",
            pwd: "",
            type: "",
            dob: "",
            dojoining: "",
            nature_of_employee: "",
            // rank: "",
            fathers_name: "",
            mothers_name: "",
            pan: "",
            account_no: "",
            ifsc: "",

            branch: "",
            bank: "",
            address: "",
            // fp_id: "",
            emergency_contact: "",
            photo: "",
            // signature: "",
            remarks: "",
            salutation: "",
            // casual_count: "",
            // restricted_count: "",
            // station_count: "N/A",
            // duty_count: "",
            // special_casual_count: "",
            // earned_count: "",
          });
          setErrorMessage("Data successfully saved");
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        })
        .catch((error) => {
          var message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          Object.keys(error?.response?.data?.errors).map((key) => {
            console.log(key);
            message = message + "  " + error?.response?.data?.errors[key][0];
          });
          setErrorMessage(message);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    }

    setLoading(false);
    setFormRole("add");
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Create ERP Users' />

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <h3 className='pt-6 mb-10 text-xl'>
                  {formRole === "add" ? "Create New" : "Edit"} User:
                </h3>
                <div className='grid grid-cols-4 gap-8'>
                  <div className=''>
                    <Select name='type' label='Type *'>
                      <option value=''>Select Any One</option>

                      {authData.user.email == "establishmenta@dibru.ac.in" && (
                        <>
                          <option value='1'>Teaching</option>
                          <option value='3'>Officer</option>
                        </>
                      )}
                      {authData.user.email == "establishmentb@dibru.ac.in" && (
                        <>
                          <option value='2'>Employee (Grade III)</option>
                          <option value='6'>Employee (Grade IV)</option>
                        </>
                      )}
                      {authData.user.email != "establishmenta@dibru.ac.in" &&
                        authData.user.email != "establishmentb@dibru.ac.in" && (
                          <>
                            {" "}
                            <option value='1'>Teaching</option>
                            <option value='3'>Officer</option>
                            <option value='2'>Employee (Grade III)</option>
                            <option value='6'>Employee (Grade IV)</option>
                          </>
                        )}
                    </Select>
                  </div>
                  <div></div>
                  <div></div>

                  <Upload
                    label='Profile Photo (Image)'
                    type='file'
                    name='photo'
                    onChange={(event) => {
                      let myFile = event.currentTarget.files[0];
                      formikRef.current.setFieldValue("photo", myFile);
                    }}
                  />

                  <Select
                    label='Salutation'
                    name='salutation'
                    placeholder='Salutation'
                  >
                    <option value=''>Select</option>
                    <option value='Prof.'>Prof.</option>
                    <option value='Dr.'>Dr.</option>
                    <option value='Mr.'>Mr.</option>
                    <option value='Mrs.'>Mrs.</option>
                    <option value='Ms.'>Ms.</option>
                  </Select>

                  <Input
                    label='Name *'
                    name='name'
                    placeholder='Enter Staff Name'
                  />
                  <Input
                    label="Father's Name"
                    name='fathers_name'
                    placeholder="Enter Father's Name"
                  />
                  <Input
                    label="Mother's Name"
                    name='mothers_name'
                    placeholder="Enter Mother's Name"
                  />

                  <Input
                    label='Email (Optional for Grave IV)'
                    name='email'
                    placeholder='Enter Email'
                  />
                  <Input
                    label='Phone (Optional for Grave IV)'
                    name='phone'
                    placeholder='Enter Phone'
                  />
                  {/* <Input
                    label="New Password"
                    name="password"
                    placeholder="Enter New Password"
                  /> */}
                  <CustomSelect
                    options={departments}
                    name='department_id'
                    label='Select Department/Branch *'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "department_id",
                        selectedOption.id
                      );
                    }}
                  />
                  <CustomSelect
                    options={designations}
                    name='designation_id'
                    label='Select Designation *'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "designation_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <Select name='category' label='Caste'>
                    <option value=''>Select Any One</option>
                    <option value='GENERAL'>GENERAL</option>
                    <option value='OBC/MOBC'>OBC/MOBC</option>
                    <option value='SC'>SC</option>
                    <option value='ST-P'>ST-P</option>
                    <option value='ST-H'>ST-H</option>
                  </Select>

                  <Select name='religion' label='Religion'>
                    <option value=''>Select Any One</option>
                    <option value='Hindu'>Hindu</option>
                    <option value='Muslim'>Muslim</option>
                    <option value='Christians'>Christians</option>
                    <option value='Sikhs'>Sikhs</option>
                    <option value='Buddhists'>Buddhists</option>
                    <option value='Jains'>Jains</option>
                  </Select>

                  <Select name='gender' label='Gender'>
                    <option value=''>Select Any One</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                  </Select>
                  <Select name='pwd' label='Pwd'>
                    <option value=''>Select Any One</option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </Select>
                  {/* <Input
                    label="Aadhaar Number"
                    name="aadhaar"
                    placeholder="Enter Assdhar No."
                  /> */}
                  <Input label='Date of Birth' type='date' name='dob' />
                  <Input label='Date of Joining' type='date' name='dojoining' />
                  {/* <Input
                    label="Nature of Employment"
                    name="nature_of_employee"
                    placeholder="Nature of Employment"
                  /> */}
                  <Select name='nature_of_employee' label='Nature of Employee'>
                    <option value=''>Select Any One</option>
                    <option value='Permanent'>Permanent</option>
                    <option value='Contractual'>Contractual</option>
                  </Select>
                  {/* <Input label="Rank" name="rank" placeholder="Rank" /> */}
                  {/* <Input label="PBR" name="pbr" placeholder="pbr" /> */}
                  <Input
                    label='Pan Card No'
                    name='pan'
                    placeholder='Pan Card No'
                  />
                  <Input
                    label='Bank Account No.'
                    name='account_no'
                    placeholder='Bank Account No.'
                  />
                  {/* ifsc, branch, bank */}
                  <Input
                    label='IFSC Code'
                    name='ifsc'
                    placeholder='IFSC Code'
                  />
                  <Input label='Branch' name='branch' placeholder='Branch' />
                  <Input label='Bank' name='bank' placeholder='Bank' />

                  <Select
                    label='Blood Group'
                    name='blood_group'
                    placeholder='Blood Group'
                  >
                    <option value=''>Select</option>
                    <option value='A+'>A+</option>
                    <option value='A-'>A-</option>
                    <option value='B+'>B+</option>
                    <option value='B-'>B-</option>
                    <option value='AB+'>AB+</option>
                    <option value='AB-'>AB-</option>
                    <option value='O+'>O+</option>
                    <option value='O-'>O-</option>
                  </Select>

                  <Input label='Address' name='address' placeholder='Address' />
                  {/* <Input
                    label="Employee Id"
                    name="fp_id"
                    placeholder="Employee Id"
                  /> */}
                  <Input
                    label='Emergency Contact Number'
                    name='emergency_contact'
                    placeholder='Emergency Contact Number'
                  />

                  <div className='col-span-4 mt-5 mb-10 text-left'>
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        type='submit'
                      />
                    ) : (
                      <>
                        <Button
                          value={loading ? "Updating..." : "Update"}
                          icon={"SaveIcon"}
                          type='submit'
                        />
                        <span className='mr-5'></span>
                        <Button
                          classNames='bg-red-600 hover:bg-red-700 focus:ring-red-500'
                          value='Cancel'
                          icon={"XIcon"}
                          type='button'
                          onClick={() => {
                            // setInitialValues({
                            //   id: null,
                            //   name: "",
                            //   email: "",
                            //   phone: "",
                            //   password: "",
                            // });
                            setFormRole("add");
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
