import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";
import Alert from "../../../Components/Utils/Alert";
import Button from "../../../Components/Utils/Button";
import BaseApi from "../../../Http/BaseApi";
// import Select from "../../Components/Utils/Select";
import tableToCSV from "../../../Components/Utils/TableToCSV";

import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function DeletedPayments() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState();

  const [searchInput, setSearchInput] = useState("");

  // const { message } = useSelector((state) => state.message);

  let errorMessage = useSelector((state) => state.applicants.errorMessage);
  // let formSections = useSelector((state) => state.applicants.formSections);

  //   const dispatch = useDispatch();
  useEffect(() => {
    callDeletedPayments();
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function callDeletedPayments() {
    console.log("as");
    setLoading(true);
    const _payments = await BaseApi().get(`payments`, {
      params: { deleted: 1 },
    });
    setLoading(false);
    setPayments(_payments.data);
  }
  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5 overflow-x-scroll ">
            <PageTitle name="List of Deleted Payments" />

            <div className="">
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            <Button
              classNames="float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className="flex">
                  <span className="m-auto">
                    <ThreeDots
                      height="100"
                      width="100"
                      color="grey"
                      ariaLabel="loading"
                    />
                  </span>
                </div>
              </>
            )}

            {payments?.length > 0 && (
              <>
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label inline-block m-0   text-gray-700"
                >
                  Search By Name
                </label>
                <input
                  className="  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               "
                  placeholder="Type here..."
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}

            <table
              id="datatable"
              className="min-w-full  bg-white dark:bg-gray-800"
            >
              <thead>
                <tr>
                  <th className="table-head">#</th>

                  <th className="table-head">Name</th>
                  <th className="table-head">Programme</th>
                  <th className="table-head">Semester</th>
                  <th className="table-head">Ref. No.</th>
                  <th className="table-head">Receipt No.</th>
                  <th className="table-head">Date</th>
                  <th className="table-head">Amount</th>
                  <th className="table-head">Type</th>
                  <th className="table-head">Payment Type</th>

                  <th className="table-head">Created By</th>
                  <th className="table-head">Deleted By</th>
                  <th className="table-head">Deleted At</th>
                  <th className="table-head">Reason</th>
                  <th className="table-head">View</th>
                </tr>
              </thead>
              <tbody>
                {payments?.length > 0 &&
                  payments
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((payment, index) => {
                      return (
                        <>
                          <tr className="bg-gray-100">
                            <td className="table-data">{++index}</td>

                            <td className="table-data">
                              {payment.student?.name}
                            </td>
                            <td className="table-data">
                              {payment.programme?.name}
                            </td>
                            <td className="table-data">{payment.year_sem}</td>
                            <td className="table-data">{payment.challan_no}</td>
                            <td className="table-data">
                              {payment.receipt_no}
                              {"-"}
                              {payment.id}
                            </td>
                            <td className="table-data">
                              {moment(payment.pay_date).format("Do MMM YYYY")}
                            </td>
                            <td className="table-data">{payment.amount}</td>
                            <td className="table-data">{payment.type}</td>
                            <td className="table-data">
                              {payment.payment_type}
                            </td>

                            <td className="table-data">
                              {payment.staff?.name || "Department"}
                            </td>
                            <td className="table-data">
                              {payment.deleted_by?.name || "Department"}
                            </td>
                            <td className="table-data">
                              {moment(payment.deleted_at).format("Do MMM YYYY")}
                            </td>
                            <td className="table-data">
                              {payment.delete_reason}
                            </td>
                            <td className="table-data">
                              <a
                                target="_blank"
                                href={`https://staffapi.dibru.work/print_payment/${payment.id}`}
                                className=""
                                rel="noreferrer"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {payments?.length == 0 && (
              <div className="text-center my-10">No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
