import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import { MdCancel, MdDelete, MdFileOpen } from "react-icons/md";
import moment from "moment";
import RecipientsModal from "./RecipientsModal";
import { useSelector } from "react-redux";
import {
  FiAlertCircle,
  FiCalendar,
  FiFileText,
  FiXCircle,
} from "react-icons/fi";
import {
  FaCheckCircle,
  FaClock,
  FaFileAlt,
  FaLink,
  FaPlus,
} from "react-icons/fa";
import { toast } from "react-toastify";
import can from "../../Middleware/Permission";
import StaffSelectionModal from "./StaffSelectionModal";
import {
  FaFileArrowUp,
  FaFileCircleMinus,
  FaFileCirclePlus,
} from "react-icons/fa6";

export default function NoticeAndCircularsIndex() {
  const [notices, setNotices] = useState([]);
  const [noticeCategories, setNoticeCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [meetingTab, setMeetingTab] = useState(""); // Sub-tab state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedNoticeId, setSelectedNoticeId] = useState(null); // Selected notice ID
  const [selectedNotice, setSelectedNotice] = useState();

  const authData = useSelector((state) => state.auth);
  const [recipientModalIsOpen, setRecipientModalIsOpen] = useState(false);
  const openRecipientModal = () => setRecipientModalIsOpen(true);
  const closeRecipientModal = () => setRecipientModalIsOpen(false);
  const [staffResults, setStaffResults] = useState([]);
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const master = useSelector((state) => state.master);

  const departments = [...master.departments];
  const getStaff = async () => {
    console.log("get staff");

    await BaseApi()
      .get(`staff_minimal`)
      .then((response) => {
        setStaffResults(response.data);
      });
  };
  useEffect(() => {
    getNoticeCategories();
    getStaff();
  }, []);

  const toggleStaffSelection = (staffId) => {
    setSelectedStaffIds((prev) =>
      prev.includes(staffId)
        ? prev.filter((id) => id !== staffId)
        : [...prev, staffId]
    );
  };

  useEffect(() => {
    if (selectedCategory) {
      getNotices();
    }
  }, [selectedCategory]);

  const getNotices = async () => {
    try {
      const response = await BaseApi().get(
        "/notices?admin_notice_category_id=" + selectedCategory
      );
      setNotices(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getNoticeCategories = async () => {
    await BaseApi()
      .get(`noticeCategories`)
      .then((response) => {
        setNoticeCategories(response.data);
      });
  };

  // API call to delete a notice/circular
  const withdrawNotice = async (id) => {
    const confirmationText = window.prompt(
      "To confirm, please type 'withdraw' below. This will send emails to all recipients notifying them of the withdrawal."
    );

    if (confirmationText && confirmationText.toLowerCase() == "withdraw") {
      try {
        await BaseApi().post(`/notices/${id}/withdraw`);
        getNotices();
        toast.success("Item withdrawn successfully");
      } catch (error) {
        toast.error("Error withdrawing item");
        console.error("Error withdrawing item:", error);
      }
    } else {
      toast.error("Withdrawal canceled or invalid input.");
    }
  };

  // API call to cancel a meeting
  const cancelMeeting = async (id) => {
    const confirmationText = window.prompt(
      "To confirm, please type 'cancel' below. This will send emails to all recipients notifying them of the cancellation."
    );

    if (confirmationText && confirmationText.toLowerCase() === "cancel") {
      try {
        await BaseApi().post(`/notices/${id}/cancel`);
        getNotices();
        toast.success("Meeting cancelled successfully");
      } catch (error) {
        toast.error("Error cancelling meeting");
        console.error("Error cancelling meeting:", error);
      }
    } else {
      toast.error("Cancellation aborted or invalid input.");
    }
  };

  const sendReminder = async (id) => {
    if (
      window.confirm("Are you sure you want to send reminder for this meeting?")
    ) {
      try {
        await BaseApi().post(`/notices/${id}/send-reminder`);
        getNotices();
        toast.success("Reminder sent successfully");
      } catch (error) {
        toast.error("Error sending reminder");
        console.error("Error :", error);
      }
    }
  };

  // Handle clicking on the recipients count link
  const handleOpenModal = (noticeId) => {
    setSelectedNoticeId(noticeId); // Set the notice ID
    setIsModalOpen(true); // Open the modal
  };

  // Filter meetings based on the selected sub-tab (Upcoming, Past, Cancelled)
  const filteredNotices = () => {
    const currentDate = moment().startOf("day");

    if (meetingTab == "upcoming") {
      return notices.filter(
        (notice) =>
          moment(notice.date).isSameOrAfter(currentDate) && notice.status != -1
      );
    } else if (meetingTab == "past") {
      return notices.filter(
        (notice) =>
          moment(notice.date).isBefore(currentDate) && notice.status != -1
      );
    } else if (meetingTab == "cancelled") {
      return notices.filter((notice) => notice.status == -1);
    } else {
      console.log("abc");

      return notices;
    }
  };

  const getCategoryIcon = (categoryId, classNames) => {
    switch (categoryId) {
      case 1:
        return <FiAlertCircle className={classNames} />; // Notice Icon
      case 2:
        return <FiFileText className={classNames} />; // Circular Icon
      case 3:
        return <FiCalendar className={classNames} />; // Meeting Invitation Icon
      default:
        return null;
    }
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5'>
            <PageTitle name='Notice, Circulars and Meetings' />
            {/* Tab styles for notice categories */}
            <div className='flex items-center justify-start w-full mt-5'>
              {noticeCategories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => {
                    setSelectedCategory(category.id);
                    if (category.id == 3) {
                      setMeetingTab("upcoming");
                    } else {
                      setMeetingTab("");
                    }
                  }}
                  className={`px-4 py-2 flex gap-2 items-center mx-2 text-sm font-semibold rounded-lg ${
                    selectedCategory == category.id
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {getCategoryIcon(
                    category.id,
                    selectedCategory == category.id
                      ? "text-white"
                      : "text-gray-700"
                  )}
                  {category.name}
                </button>
              ))}

              <Link to='/notice-circulars-create' className='ml-auto'>
                <button className='px-4 py-2 text-sm font-semibold text-white bg-green-500 rounded-lg'>
                  <FaPlus className='inline' /> Create New
                </button>
              </Link>
            </div>

            {/* Sub-tabs for meetings */}
            {selectedCategory == 3 && (
              <div className='flex justify-start mt-4'>
                <button
                  onClick={() => setMeetingTab("upcoming")}
                  className={`px-4 flex items-center py-2 mx-2 text-sm font-semibold rounded-lg ${
                    meetingTab == "upcoming"
                      ? "bg-green-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  <FaClock className='mr-1' />
                  Upcoming Meetings
                </button>
                <button
                  onClick={() => setMeetingTab("past")}
                  className={`px-4 flex items-center py-2 mx-2 text-sm font-semibold rounded-lg ${
                    meetingTab == "past"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  <FaCheckCircle className='mr-1' />
                  Past Meetings
                </button>
                <button
                  onClick={() => setMeetingTab("cancelled")}
                  className={`px-4 flex items-center py-2 mx-2 text-sm font-semibold rounded-lg ${
                    meetingTab == "cancelled"
                      ? "bg-red-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  <FiXCircle className='mr-1' />
                  Cancelled Meetings
                </button>
              </div>
            )}
            {/* Render notices in table format */}
            <div className='flex flex-col mt-5 overflow-x-auto'>
              <table className='min-w-full text-xs border divide-y divide-gray-200'>
                <thead className='bg-gray-100'>
                  <tr>
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      #
                    </th>
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      Title
                    </th>
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      Description
                    </th>
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      Date
                    </th>
                    {selectedCategory == 3 && (
                      <>
                        <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                          Venue
                        </th>

                        <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                          Link
                        </th>
                      </>
                    )}
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      Recipients
                    </th>
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      File
                    </th>
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      Created By
                    </th>
                    <th className='px-4 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase border-b'>
                      {selectedCategory == 3 ? "Action" : "Action"}
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {filteredNotices().map((notice, index) => (
                    <tr
                      key={notice.id}
                      className='transition-colors duration-200 hover:bg-gray-50'
                    >
                      <td className='px-4 py-4 border-b whitespace-nowrap'>
                        {index + 1}
                      </td>
                      <td className='px-4 py-4 border-b whitespace-nowrap'>
                        {notice.title} <br />
                        {notice.type == "Addendum" && (
                          <span className='px-2 py-1 mt-1 text-yellow-600 bg-yellow-100 rounded-md'>
                            Addendum of {notice.parent?.title}
                          </span>
                        )}
                        {notice.type == "Corrigendum" && (
                          <span className='px-3 py-1 text-yellow-700 bg-yellow-100'>
                            Corrigendum of {notice.parent?.title}
                          </span>
                        )}
                      </td>
                      <td className='px-4 py-4 border-b whitespace-nowrap'>
                        {notice.description}
                      </td>
                      <td className='px-4 py-4 border-b whitespace-nowrap'>
                        {notice.date}
                      </td>
                      {selectedCategory == 3 && (
                        <>
                          <td className='px-4 py-4 border-b whitespace-nowrap'>
                            {notice.venue} at{" "}
                            {moment(notice.time, "HH:mm").format("hh:mm A")}{" "}
                            <br />
                            {notice.duration}
                          </td>

                          <td className='px-4 py-4 border-b whitespace-nowrap'>
                            {notice.link && (
                              <a
                                href={notice.link}
                                target='_blank'
                                className='text-blue-500 hover:underline'
                                rel='noreferrer'
                              >
                                <FaLink className='inline text-xs' />
                              </a>
                            )}
                          </td>
                        </>
                      )}
                      <td className='px-4 py-4 border-b whitespace-nowrap'>
                        {/* badge style */}
                        <button
                          className='px-2 py-1 text-xs font-semibold leading-5 text-blue-800 bg-blue-100 rounded-full'
                          onClick={() => handleOpenModal(notice.id)}
                        >
                          {notice.recipients_count}
                        </button>
                      </td>
                      <td className='px-4 py-4 border-b whitespace-nowrap'>
                        {notice.document_url && (
                          <a
                            href={notice.file_url}
                            target='_blank'
                            className='text-blue-500 hover:underline'
                            rel='noreferrer'
                          >
                            <MdFileOpen className='text-lg' />
                          </a>
                        )}
                      </td>
                      <td className='px-4 py-4 border-b whitespace-nowrap'>
                        {notice.created_by?.name} <br />
                      </td>
                      {selectedCategory == 3 ? (
                        <td className='px-4 py-4 border-b whitespace-nowrap'>
                          {meetingTab == "upcoming" &&
                            notice.status == 0 &&
                            notice.staff_id == authData.user.id && (
                              <>
                                <button
                                  className='flex items-center text-red-500 transition-colors duration-150 hover:text-red-700'
                                  onClick={() => cancelMeeting(notice.id)}
                                >
                                  <MdCancel className='mr-1 text-lg' /> Cancel
                                </button>
                                {meetingTab == "upcoming" && (
                                  <>
                                    <button
                                      className='flex items-center mt-2 text-blue-500 transition-colors duration-150 hover:text-blue-700'
                                      onClick={() => sendReminder(notice.id)}
                                    >
                                      <FaClock className='mr-1 text-lg' /> Send
                                      Reminder Email
                                    </button>
                                    <button
                                      onClick={() => {
                                        setSelectedNotice(notice);
                                        openRecipientModal();
                                      }}
                                      className='flex items-center mt-2 text-green-500 transition-colors duration-150 hover:text-green-700'
                                    >
                                      <FaPlus className='mr-1 text-lg' /> Add
                                      more recipient
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          {notice.status == -1 && (
                            <>
                              <span className='px-2 py-1 text-red-500 bg-red-100 rounded-full'>
                                Cancelled
                              </span>
                            </>
                          )}
                        </td>
                      ) : (
                        <td className='px-4 py-4 border-b whitespace-nowrap'>
                          {notice.staff_id == authData.user.id &&
                            notice.status == 0 && (
                              <>
                                <button
                                  className='flex items-center text-red-500 transition-colors duration-150 hover:text-red-700'
                                  onClick={() => withdrawNotice(notice.id)}
                                >
                                  <MdDelete className='mr-1 text-lg' /> Withdraw{" "}
                                  {
                                    noticeCategories.find(
                                      (category) =>
                                        category.id == selectedCategory
                                    )?.name
                                  }
                                </button>
                                {/* add more recipient button */}
                                <button
                                  onClick={() => {
                                    setSelectedNotice(notice);
                                    openRecipientModal();
                                  }}
                                  className='flex items-center mt-2 text-green-500 transition-colors duration-150 hover:text-green-700'
                                >
                                  <FaPlus className='mr-1 text-lg' /> Add more
                                  recipient
                                </button>

                                <Link
                                  to={`/notice-circulars-create?parent_notice_id=${notice.id}&type=Addendum`}
                                  className='flex items-center mt-2 text-blue-500 transition-colors duration-150 hover:text-blue-700'
                                >
                                  <FaFileCirclePlus className='mr-1 text-lg' />{" "}
                                  Add Addendum
                                </Link>

                                <Link
                                  to={`/notice-circulars-create?parent_notice_id=${notice.id}&type=Corrigendum`}
                                  className='flex items-center mt-2 text-purple-500 transition-colors duration-150 hover:text-purple-700'
                                >
                                  <FaFileCircleMinus className='mr-1 text-lg' />{" "}
                                  Add Corrigendum
                                </Link>
                              </>
                            )}
                          {notice.status == -1 && (
                            <>
                              <span className='px-2 py-1 text-orange-500 bg-orange-100 rounded-full'>
                                Withdrawn
                              </span>
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Staff selection modal */}
      <StaffSelectionModal
        isOpen={recipientModalIsOpen}
        closeModal={closeRecipientModal}
        staffResults={staffResults}
        selectedStaffIds={selectedStaffIds}
        toggleStaffSelection={toggleStaffSelection}
        setStaffResults={setStaffResults}
        setSelectedStaffIds={setSelectedStaffIds}
        departments={departments}
        selectedNotice={selectedNotice}
        isAddMore={true}
        getNotices={getNotices}
      />

      {/* Recipients Modal */}
      <RecipientsModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        noticeId={selectedNoticeId}
      />
    </>
  );
}
