import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";

import moment from "moment";
import {
  FaBriefcase,
  FaCalendarCheck,
  FaCalendarDays,
  FaFile,
} from "react-icons/fa6";

const headers = {
  title: { label: "Title of the lecture/talk", path: "title" },
  type: { label: "Type of lecture/talk", path: "type" },
  organization_name: {
    label: "Organization Name",
    path: "organization_name",
  },
  workshop_name: {
    label: "Name of the Workshop/Seminar/Occasion/Event",
    path: "workshop_name",
  },
  lecture_date: { label: "Lecture Date", path: "lecture_date" },
  lecture_details: { label: "Lecture Details", path: "lecture_details" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const LecturesTalk = ({ view }) => {
  const [lecturesTalks, setLecturesTalks] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/lectures-talks");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setLecturesTalks(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/lectures-talks");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredTalks = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by lecture_date in descending order
      const sortedTalks = filteredTalks.sort((a, b) => {
        const dateA = moment(getValueByPath(a, headers.lecture_date.path));
        const dateB = moment(getValueByPath(b, headers.lecture_date.path));
        return dateB - dateA; // Descending order
      });

      setLecturesTalks(sortedTalks);
    } catch (error) {
      console.error(error);
    }
  };

  const renderLectureTalk = (lecture) => {
    // const title = lecture.title;
    // const type = lecture.type;
    // const organizationName = lecture.organization_name;
    // const workshopName = lecture.workshop_name;
    // const lectureDate = moment(lecture.lecture_date).format("DD MMM YYYY");
    // const lectureDetails = lecture.lecture_details;

    const title = getValueByPath(lecture, headers.title.path);
    const type = getValueByPath(lecture, headers.type.path);
    const organizationName = getValueByPath(
      lecture,
      headers.organization_name.path
    );
    const workshopName = getValueByPath(lecture, headers.workshop_name.path);
    const lectureDate = moment(
      getValueByPath(lecture, headers.lecture_date.path)
    ).format("DD MMM YYYY");
    const lectureDetails = getValueByPath(
      lecture,
      headers.lecture_details.path
    );

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>
          <p className='flex items-center gap-2'>
            <FaBriefcase /> Organization:
            <span className='text-green-600'>{organizationName}</span>
          </p>
          <p className='flex items-center gap-2'>
            <FaCalendarCheck /> Workshop/Seminar/Event:
            <span className='text-green-600'>{workshopName}</span>
          </p>
          <p className='text-sm text-gray-500'>
            <span className='px-3 py-1 text-green-800 bg-green-100 rounded-full'>
              {" "}
              {type}
            </span>
          </p>
          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaCalendarDays className='text-yellow-500' />
              {lectureDate}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Lectures/Talks
      </h2>
      {lecturesTalks.length ? (
        <div className=''>
          {lecturesTalks.map((lectureTalk, index) => (
            <div key={index} className=''>
              {renderLectureTalk(lectureTalk)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default LecturesTalk;
