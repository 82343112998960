import React, { useEffect, useRef, useState } from "react";
import Button from "../../Components/Utils/Button";
// import Input from '../../Components/Utils/Input';
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import { toast } from "react-toastify";

import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../Redux/MasterSlice";
// import Select from '../../Components/Utils/Select';
import CustomSelect from "../../Components/Utils/CustomSelect";
// import moment from 'moment';
import { useNavigate } from "react-router-dom";
// import { SearchNor } from '../../Constants/SearchNor';
import {
  addPunishmentsAsync,
  getAdmittedStudentsAsync,
  updateAdmittedStudentsAsync,
} from "../../Redux/StudentSlice";
import { YearSemesters } from "../../Constants/YearSemesters";
import can from "../../Middleware/Permission";
import tableToCSV from "../../Components/Utils/TableToCSV";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import DropDown from "../../Components/Utils/Dropdown";
import BasicInput from "../../Components/Utils/BasicInput";
import BaseApi from "../../Http/BaseApi";
import Input from "../../Components/Utils/Input";
import moment from "moment";

export default function AdmittedStudentIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const formikRef2 = useRef();
  const [isPHD, setIsPHD] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPunishmentModal, setShowPunishmentModal] = useState(false);
  const [selectedProgrammeStudent, setSelectedProgrammeStudent] = useState();
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];

  let students = useSelector((state) => state.students.admittedStudents);
  const authData = useSelector((state) => state.auth);
  const [searchInput, setSearchInput] = useState("");

  const [punishment, setPunishment] = useState({
    programme_student_id: "",
    remarks: "",
    from_date: "",
    to_date: "",
  });

  //   const dispatch = useDispatch();
  console.log(message);
  useEffect(() => {
    // dispatch(messageSlice.actions.clearMessage());
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const initialValues = {
    programme_id: null,
    semester: null,
    academic_session_id: null,
    nor: 10,
  };

  const initialValues2 = {
    phone: "",
  };

  const validationSchema = !isPHD
    ? Yup.object().shape({
        programme_id: Yup.string()
          .nullable()
          .required("This field is required!"),
        semester: Yup.string().nullable().required("This field is required!"),
        academic_session_id: Yup.string()
          .nullable()
          .required("This field is required!"),
      })
    : Yup.object().shape({
        programme_id: Yup.string()
          .nullable()
          .required("This field is required!"),
      });

  const validationSchema2 = Yup.object().shape({
    phone: Yup.string().required("This field is required!"),
  });

  const handleSearch = async (searchQuery) => {
    setEdit(false);

    setLoading(true);
    console.log(searchQuery);
    await dispatch(getAdmittedStudentsAsync({ payload: searchQuery }))
      .unwrap()
      .then(() => {})
      .catch(() => {});
    setLoading(false);
  };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  const updateStudent = async (payload) => {
    if (payload.discontinued == 1) {
      await dispatch(
        updateAdmittedStudentsAsync({
          fields: {
            discontinued: 1,
          },
          table: "programme_student",
          id: payload.programme_student_id,
        })
      );
    } else if (payload.discontinued == 0) {
      await dispatch(
        updateAdmittedStudentsAsync({
          fields: {
            discontinued: 0,
          },
          table: "programme_student",
          id: payload.programme_student_id,
        })
      );
    } else if (payload.semester == "Mark Alumni") {
      await dispatch(
        updateAdmittedStudentsAsync({
          fields: {
            alumni: 1,
          },
          table: "programme_student",
          id: payload.programme_student_id,
        })
      );
    } else if (payload.semester == "Cancel Alumni") {
      await dispatch(
        updateAdmittedStudentsAsync({
          fields: {
            alumni: 0,
          },
          table: "programme_student",
          id: payload.programme_student_id,
        })
      );
    } else {
      await dispatch(
        updateAdmittedStudentsAsync({
          fields: {
            semester: payload.semester,
            academic_session_id: 3,
          },
          table: "programme_student",
          id: payload.programme_student_id,
        })
      );
    }

    toast.success("Data Updated!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,

      progress: undefined,
    });
  };

  const verifyPassword = async () => {
    try {
      const response = await BaseApi().post(`check-password`, {
        password: password,
      });
      if (response.data == "OK") {
        setPassword("");
        setEdit(true);
        setShowPasswordInput(false);
      } else {
        window.alert("Incorrect Password!");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      window.alert(message);
    }
  };

  const handleChangePunishment = (event) => {
    console.log(event.target.name);
    const values = { ...punishment };
    values[event.target.name] = event.target.value;

    setPunishment(values);
  };

  const addPunishment = async (e) => {
    e.preventDefault();

    try {
      await dispatch(addPunishmentsAsync(punishment));

      toast.success("Punishment Added!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,

        progress: undefined,
      });
      setShowPunishmentModal(false);
      setEdit(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      window.alert(message);
    }

    setPunishment({
      programme_student_id: "",
      remarks: "",
      from_date: "",
      to_date: "",
    });
  };

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full min-h-screen overflow-x-scroll'>
            <PageTitle name='Admitted Student List' />

            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={programmes}
                    name='programme_id'
                    label='Select Programme'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption.id
                      );
                      if (selectedOption.programmetype_id == 2) {
                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          ""
                        );
                        formikRef.current?.setFieldValue("semester", "");
                        setIsPHD(true);
                      } else {
                        setIsPHD(false);
                      }
                    }}
                  />
                  {!isPHD && (
                    <>
                      <CustomSelect
                        options={YearSemesters}
                        name='semester'
                        label='Select Year/Semester'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "semester",
                            selectedOption.value
                          );
                        }}
                      />

                      <CustomSelect
                        options={academicSessions}
                        name='academic_session_id'
                        label='Select Academic Session'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "academic_session_id",
                            selectedOption.id
                          );
                        }}
                      />
                    </>
                  )}
                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>

            <Formik
              innerRef={formikRef2}
              initialValues={initialValues2}
              validationSchema={validationSchema2}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <Input
                    type='text'
                    name='phone'
                    label='Search By Contact Number'
                    placeholder='Enter Contact Number'
                  />

                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            {edit && students.length > 0 && (
              <>
                <Button
                  type='button'
                  classNames=' mr-2 ml-5   bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  value={"Done"}
                  icon={"CheckIcon"}
                  onClick={() => {
                    setEdit(false);
                  }}
                />
              </>
            )}
            {!edit && students.length > 0 && authData.panel == "department" && (
              <Button
                type='button'
                classNames='mr-2 ml-5  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                value={"Edit"}
                icon={"PencilIcon"}
                onClick={() => {
                  // setEdit(true);
                  setShowModal(true);
                }}
              />
            )}

            {students?.length > 0 && (
              <>
                {" "}
                <br />
                <br />
                <label
                  htmlFor='exampleFormControlInput1'
                  className='inline-block m-0 text-gray-700 form-label'
                >
                  Search By Name
                </label>
                <input
                  className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                  placeholder='Type here...'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}
            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr className='w-full h-16 py-8 border-b border-gray-300 dark:border-gray-200'>
                  <th className='table-head'>SL</th>
                  <th className='table-head'>Identity Card</th>

                  <th className='table-head'>ERP ID</th>
                  <th className='table-head'>DHE ID</th>
                  <th className='table-head'>ABC ID</th>
                  <th className='table-head'>DU Reg. No.</th>
                  <th className='table-head'>Name</th>
                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Year/Sem.</th>
                  <th className='table-head'>Academic Session</th>
                  <th className='table-head'>Admitted/Promoted Date</th>
                  <th className='table-head'>Mobile No.</th>
                  <th className='table-head'>Email</th>
                  <th className='table-head'>Hostel</th>
                  <th className='table-head'>Details</th>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 &&
                  students
                    .filter((s) => {
                      if (searchInput.length > 0) {
                        return (
                          s.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((student, index) => (
                      <tr
                        key={student.id}
                        className='h-24 border-b border-gray-300 dark:border-gray-200'
                      >
                        <td className='table-data'>{++index}</td>
                        <td className='table-data whitespace-nowrap'>
                          {/* {(student.semester==0 ||  student.semester==1 || (student.semester==3 && [95, 96, 97,98,99].includes(student.programme_id)) ) &&  (can("Manage ID Card") || authData.panel == "department" )&& ( */}
                          {(can("Manage ID Card") ||
                            authData.panel == "department") && (
                            <Button
                              classNames={
                                student.id_card == null
                                  ? " bg-orange-600 hover:bg-green-700 focus:ring-green-500"
                                  : " bg-green-600 hover:bg-green-700 focus:ring-green-500"
                              }
                              value={
                                student.id_card == null
                                  ? "Gen. ID Card"
                                  : "View ID Card"
                              }
                              icon={"IdentificationIcon"}
                              onClick={() => {
                                navigate(
                                  `/admitted-students/id-card/${student.id}`
                                );
                              }}
                            />
                          )}
                        </td>
                        <td
                          className='cursor-pointer  table-data'
                          style={{
                            color:
                              student.punishments &&
                              student.punishments.length > 0
                                ? "red"
                                : "",
                          }}
                          onClick={() => {
                            navigate(`/admitted-students/${student.id}`);
                          }}
                        >
                          <span className='text-blue-600'>
                            {" "}
                            {student.unique_id || student.unique_idd}
                          </span>
                        </td>
                        <td className='table-data'>
                          {student.student?.dhe_unique_id}
                        </td>
                        <td className='table-data'>
                          {student.student?.abc_id}
                        </td>
                        <td className='table-data'>
                          {student.student?.du_registration_no}
                        </td>
                        <td className='table-data'>
                          <div className='flex items-center'>
                            <div className='w-8 h-8'>
                              <img
                                src={student.student?.student_photo}
                                className='w-full h-full overflow-hidden rounded-full shadow'
                                onError={(e) => {
                                  console.log(e.target.src);
                                  if (e.target.src != "/assets/no-photo.jpg")
                                    e.target.src = "/assets/no-photo.jpg";
                                }}
                              />
                            </div>
                            <p className='ml-2 text-sm leading-4 tracking-normal text-gray-800 dark:text-gray-100'>
                              {student.student?.name}
                            </p>
                          </div>
                          {edit && (
                            <>
                              <hr className='m-4' />

                              <div className='flex items-center'>
                                {student.discontinued == 1 && (
                                  <button
                                    onClick={async (e) => {
                                      e.target.disabled = true;
                                      if (window.confirm("Are you sure?")) {
                                        await updateStudent({
                                          programme_student_id: student.id,
                                          discontinued: 0,
                                        });
                                      }
                                      e.target.disabled = false;
                                    }}
                                    className='flex items-start text-sm font-medium text-orange-600'
                                  >
                                    Cancel Discontinued
                                    <ExclamationCircleIcon
                                      className='w-4 h-4 ml-1'
                                      aria-hidden='true'
                                    />
                                  </button>
                                )}
                                {student.discontinued != 1 && (
                                  <button
                                    onClick={async (e) => {
                                      e.target.disabled = true;
                                      if (window.confirm("Are you sure?")) {
                                        await updateStudent({
                                          programme_student_id: student.id,
                                          discontinued: 1,
                                        });
                                      }
                                      e.target.disabled = false;
                                    }}
                                    className='flex items-start text-sm font-medium text-blue-700'
                                  >
                                    Mark Discontinued
                                    <ExclamationCircleIcon
                                      className='w-4 h-4 ml-1'
                                      aria-hidden='true'
                                    />
                                  </button>
                                )}
                                {student.semester != 1 && (
                                  <DropDown
                                    options={[
                                      // { label: '1 :: 2022-2023', value: 1 },
                                      // { label: '2 :: 2022-2023', value: 2 },
                                      // { label: '3 :: 2022-2023', value: 3 },
                                      // { label: '5 :: 2022-2023', value: 5 },
                                      // { label: '7 :: 2022-2023', value: 7 },
                                      // { label: '9 :: 2022-2023', value: 9 },
                                      {
                                        label: "Mark Alumni",
                                        value: "Mark Alumni",
                                      },
                                      {
                                        label: "Cancel Alumni",
                                        value: "Cancel Alumni",
                                      },
                                    ]}
                                    onClick={(value) =>
                                      updateStudent({
                                        programme_student_id: student.id,
                                        semester: value,
                                      })
                                    }
                                    title='Mark Alumni'
                                  />
                                )}
                              </div>
                              <div>
                                <button
                                  type='button'
                                  onClick={() => {
                                    setShowPunishmentModal(true);
                                    setSelectedProgrammeStudent(student);
                                    const values = { ...punishment };
                                    values["programme_student_id"] = student.id;

                                    setPunishment(values);
                                  }}
                                  className='flex items-start text-sm font-medium text-red-700'
                                >
                                  Add Disciplinary Action
                                  <ExclamationCircleIcon
                                    className='w-4 h-4 ml-1'
                                    aria-hidden='true'
                                  />
                                </button>
                              </div>
                            </>
                          )}
                        </td>
                        <td className='table-data'>
                          {student.programme?.code}
                        </td>
                        <td className='table-data'>
                          {
                            YearSemesters.find(
                              (ys) => ys.value == student.semester
                            )?.label
                          }{" "}
                          <br />
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                            }}
                          >
                            {student.alumni == 1 && "Alumni"}
                            {student.discontinued == 1 && "Discontinued"}
                          </span>
                        </td>
                        <td className='table-data'>
                          {student.academic_session?.label}
                        </td>
                        <td className='table-data'>
                          {moment(student.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className='table-data'>{student.student?.phone}</td>
                        <td className='table-data'>{student.student?.email}</td>
                        <td className='table-data'>
                          {student.student?.hostels?.find(
                            (h) =>
                              h.pivot?.payment_id != null &&
                              h.pivot?.in_date != null &&
                              h.pivot?.out_date == null
                          )?.code ?? "No"}
                        </td>

                        <td className='table-data'>
                          <Button
                            classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                            value={"Details"}
                            icon={"ChevronDoubleRightIcon"}
                            onClick={() => {
                              navigate(`/admitted-students/${student.id}`);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {showModal ? (
              <>
                <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                  <div className='relative w-auto max-w-3xl mx-auto my-6'>
                    {/*content*/}
                    <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                        <h3 className='text-3xl font-semibold'>Instructions</h3>
                        <button
                          className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-100 focus:outline-none'
                          onClick={() => {
                            setEdit(false);
                            setShowModal(false);
                          }}
                        >
                          X
                        </button>
                      </div>
                      {/*body*/}
                      <div className='relative flex-auto p-6'>
                        <p className='my-4 text-lg leading-relaxed text-black'>
                          Please read the instructions carefully
                        </p>

                        <p className='my-4 text-lg leading-relaxed text-slate-500'>
                          1. The changes made here will be reflected globally in
                          the ERP Portal. Please be careful while editing.
                          <br />
                          2. Please ensure that all the students are registred
                          in the ERP Portal.
                        </p>
                      </div>
                      {/*footer*/}
                      <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
                        <button
                          className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                          type='button'
                          onClick={() => {
                            setEdit(true);

                            setShowModal(false);
                          }}
                        >
                          I agree to the instructions
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
              </>
            ) : null}
            {showPasswordInput ? (
              <>
                <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                  <div className='relative w-auto max-w-3xl mx-auto my-6'>
                    {/*content*/}
                    <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                        <h3 className='text-3xl font-semibold'>
                          Please Enter your password
                        </h3>
                        <button
                          className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-100 focus:outline-none'
                          onClick={() => {
                            setEdit(false);
                            setShowPasswordInput(false);
                            setShowModal(false);
                          }}
                        >
                          X
                        </button>
                      </div>
                      {/*body*/}
                      <div className='relative flex-auto p-6'>
                        <p className='my-4 text-lg leading-relaxed text-slate-500'>
                          <BasicInput
                            type='password'
                            label='Password'
                            name='password'
                            id='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </p>
                      </div>
                      {/*footer*/}
                      <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
                        <button
                          className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                          type='button'
                          onClick={async (event) => {
                            event.target.disabled = true;
                            await verifyPassword();
                            event.target.disabled = false;
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
              </>
            ) : null}

            {showPunishmentModal ? (
              <>
                <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                  <div className='relative w-auto max-w-3xl mx-auto my-6'>
                    {/*content*/}
                    <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                        <h3 className='text-3xl font-semibold'>
                          {selectedProgrammeStudent.student?.name}
                          <small className='text-sm'>
                            <br />
                            {selectedProgrammeStudent.unique_id ||
                              selectedProgrammeStudent.unique_idd}
                          </small>
                        </h3>

                        <button
                          className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-100 focus:outline-none'
                          onClick={() => {
                            setEdit(false);
                            setShowPunishmentModal(false);
                          }}
                        >
                          X
                        </button>
                      </div>
                      {/*body*/}
                      <div className='relative flex-auto p-6'>
                        <p className='text-lg leading-relaxed text-slate-500'>
                          Please enter the details of Punishment.
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p className='my-4 text-lg leading-relaxed text-slate-500'>
                          <BasicInput
                            type='text'
                            name='remarks'
                            value={punishment.remarks}
                            onChange={(event) => handleChangePunishment(event)}
                            label='Action Taken'
                            placeholder='Details of Punishment'
                          />
                          <br />
                          <BasicInput
                            type='date'
                            name='from_date'
                            value={punishment.from_date}
                            onChange={(event) => handleChangePunishment(event)}
                            label='Start From'
                          />
                          <br />
                          <BasicInput
                            type='date'
                            name='to_date'
                            value={punishment.to_date}
                            onChange={(event) => handleChangePunishment(event)}
                            label='End Date (Leave blank to make Permanent)'
                          />
                        </p>
                      </div>
                      {/*footer*/}
                      <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
                        <button
                          className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                          type='button'
                          onClick={async (event) => {
                            event.target.disabled = true;
                            await addPunishment(event);
                            event.target.disabled = false;
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
              </>
            ) : null}

            {students.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
