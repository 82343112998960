import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";

// import Input from "../../Components/Utils/Input";
import moment from "moment";
import messageSlice from "../../../Redux/MessageSlice";
import { getPaymentHeadsAsync } from "../../../Redux/MasterSlice";
import BaseApi from "../../../Http/BaseApi";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Select from "../../../Components/Utils/Select";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

export default function MakeHostelFeeCollection({
  onMakeFeeCollection,
  selectedIds,
  hostel,
  academic_session_id,
  setShowModal,
}) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

  const [paymentGroupHeads, setPaymentGroupHeads] = useState([]);
  const [paymentGroups] = useState(hostel?.payment_groups);

  const master = useSelector((state) => state.master);
  const paymentHeads = [...master.paymentHeads];
  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();

  const dispatch = useDispatch();
  let sl = 0;
  console.log(academic_session_id);
  useEffect(() => {
    console.log("called again");
    dispatch(messageSlice.actions.clearMessage());

    if (paymentHeads.length === 0) {
      dispatch(getPaymentHeadsAsync());
    }

    setInitialValues({
      selectedIds: selectedIds,
      hostel_id: hostel?.id,
      academic_session_id: academic_session_id,
      payment_group_id: "",
      semester: "",
      type: "",
      challan_no: "",
      pay_date: moment(new Date()).format("YYYY-MM-DD"),
      // pay_date: "2022-02-02",
      details: [],
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  useEffect(() => {
    console.log(paymentGroupHeads);
    if (paymentGroupHeads.length > 0) {
      paymentGroupHeads.map((head) => {
        let copy = { ...initialValues };
        let details = copy.details;
        details.push({
          checked: true,
          payment_head_id: head.payment_head_id,
          amount: head.amount,
        });
        setInitialValues({
          selectedIds: initialValues.selectedIds,
          academic_session_id: initialValues.academic_session_id,
          hostel_id: initialValues.hostel_id,

          payment_group_id: initialValues.payment_group_id,
          semester: initialValues.semester,
          type: initialValues.type,
          challan_no: initialValues.challan_no,
          pay_date: initialValues.pay_date,
          details,
        });
      });
    }

    // dispatch(getOldStudentsAsync());
  }, [paymentGroupHeads]);

  const validationSchema = Yup.object().shape({
    // selectedIds: Yup.string().required("This field is required!"),
    payment_group_id: Yup.string().required("This field is required!"),

    type: Yup.string().required("This field is required!"),
    pay_date: Yup.string().required("This field is required!"),

    challan_no: Yup.string().when("type", {
      is: (type) => type != "Online",
      then: Yup.string().required("This field is required!"),
    }),
  });

  const handleSubmit = async (payload) => {
    console.log(payload);
    setLoading(true);
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);

    await BaseApi().post(`make-hostel-fee-collection`, payload);
    setLoading(false);

    setInitialValues({
      selectedIds: selectedIds,
      programme_id: "",
      hostel_id: "",
      academic_session_id: "",
      payment_group_id: "",
      semester: "",
      type: "",
      challan_no: "",
      pay_date: moment(new Date()).format("YYYY-MM-DD"),
      details: [],
    });
    onMakeFeeCollection();
    setShowModal(false);
  };

  const handlePaymentHeadInputChange = (event) => {
    console.log(initialValues);
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      let copy = { ...initialValues };
      let details = copy.details;

      details.find((e) => e.payment_head_id == value).checked = true;
      console.log(details);
      setInitialValues({
        selectedIds: initialValues.selectedIds,
        academic_session_id: initialValues.academic_session_id,
        hostel_id: initialValues.hostel_id,

        payment_group_id: initialValues.payment_group_id,
        semester: initialValues.semester,
        type: initialValues.type,
        challan_no: initialValues.challan_no,
        pay_date: initialValues.pay_date,
        details: details,
      });
    } else {
      let copy = { ...initialValues };
      let details = copy.details;

      details.find((e) => e.payment_head_id == value).checked = false;
      console.log(details);
      setInitialValues({
        selectedIds: initialValues.selectedIds,
        academic_session_id: initialValues.academic_session_id,
        hostel_id: initialValues.hostel_id,

        payment_group_id: initialValues.payment_group_id,
        semester: initialValues.semester,
        type: initialValues.type,
        challan_no: initialValues.challan_no,
        pay_date: initialValues.pay_date,
        details: details,
      });
    }
  };

  console.log(initialValues);
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  console.log(message);
  return (
    <>
      <div className='justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative   w-auto my-6 mx-auto max-w-3xl'>
          {/*content*/}

          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-3xl font-semibold'>
                Student Selected:{selectedIds.length}
              </h3>
            </div>
            {/*body*/}
            <p style={{ width: "700px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative p-6 flex-auto'>
                    <div className='grid grid-cols-2 gap-4'>
                      <div className=''>
                        {paymentGroups?.length > 0 && (
                          <CustomSelect
                            isClearable={true}
                            options={paymentGroups}
                            name='payment_group_id'
                            label='Select Payment Receipt'
                            isSearchable={true}
                            getOptionValue={(option) => `${option}`}
                            getOptionLabel={(option) => `${option.name} `}
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "payment_group_id",
                                selectedOption.id
                              );

                              if (
                                initialValues.payment_group_id !=
                                selectedOption.id
                              ) {
                                setInitialValues({
                                  selectedIds: initialValues.selectedIds,
                                  academic_session_id:
                                    initialValues.academic_session_id,
                                  hostel_id: initialValues.hostel_id,

                                  payment_group_id: selectedOption.id,
                                  semester: initialValues.semester,
                                  type: initialValues.type,
                                  challan_no: initialValues.challan_no,
                                  pay_date: initialValues.pay_date,

                                  details: [],
                                });
                                setPaymentGroupHeads(
                                  paymentGroups.find(
                                    (e) => e.id == selectedOption.id
                                  )?.payment_group_heads
                                );
                              } else {
                                setPaymentGroupHeads(
                                  paymentGroups.find(
                                    (e) => e.id == selectedOption.id
                                  )?.payment_group_heads
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <table className='min-w-full mt-10   bg-white '>
                      <thead>
                        <tr>
                          <th className={thClass}>Select</th>
                          <th className={thClass}>#</th>
                          <th className={thClass}>Head</th>
                          <th className={thClass}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {initialValues?.details.map((head) => {
                          console.log("abc");
                          return (
                            <>
                              <tr className={head.checked ? "" : "bg-gray-100"}>
                                <td className={tdClass}>
                                  <input
                                    className='form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                                    type='checkbox'
                                    checked={head.checked}
                                    value={head.payment_head_id}
                                    onChange={(event) =>
                                      handlePaymentHeadInputChange(event)
                                    }
                                    id={"head-id" + head.payment_head_id}
                                  />
                                </td>
                                <td className={tdClass}>{++sl}</td>
                                <td className={tdClass}>
                                  {
                                    paymentHeads.find(
                                      (e) => e.id == head.payment_head_id
                                    )?.name
                                  }
                                </td>
                                <td className={tdClass}>{head.amount}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th className={thClass}></th>
                          <th className={thClass}></th>
                          <th className={thClass}>Total</th>
                          <th className={thClass}>
                            {initialValues?.details
                              ?.filter((e) => e.checked == true)
                              .reduce(
                                (partialSum, detail) =>
                                  partialSum + (parseInt(detail.amount) || 0),
                                0
                              )}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                    <p className='my-4 text-slate-500 text-lg leading-relaxed'></p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <Select label='Select Payment Type' name='type'>
                      <option selected value={""}>
                        Select
                      </option>
                      <option value={"Online"}>Online(Billdesk)</option>
                      <option value={"SBI Collect"}>SBI Collect</option>
                      <option value={"POS"}>POS</option>
                      <option value={"Other"}>Other</option>
                    </Select>
                    <Input label='Payment Date' type='date' name='pay_date' />
                    <Input label='Ref. No.' name='challan_no' />

                    <Button
                      disabled={loading}
                      value={loading ? "Saving..." : "Submit"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
}
