import {   Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
 // import Select from "../../../Components/Utils/Select";
 
export default function UpdateLeaveModal({
  staff_id,
  setShowUpdateLeaveModal,
}) {
   const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("called again");

    setInitialValues({
      staff_id: staff_id,
      casual_count: "",
      restricted_count: "",
      station_count: "N/A",
      duty_count: "",
      special_casual_count: "",
      earned_count: "",
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    staff_id: Yup.string().required("This field is required!"),
    casual_count: Yup.number()
    .typeError("Please enter a number")

    .integer("Can't include a decimal point")
    .min(0, "Can't be a negative number")
    // .when("type", {
    //   is: (type) => type == 3,
    //   then: Yup.number().max(12, "Maximum 12 for Officers"),
    // })
    // .when("type", {
    //   is: (type) => type == 1,
    //   then: Yup.number().max(8, "Maximum 8 for Teachers"),
    // })

    .required("This field is required!"),
  restricted_count: Yup.number()
    .typeError("Please enter a number")

    .integer("Can't include a decimal point")
    .min(0, "Can't be a negative number")
    .max(2, "Must be less than or equal to 2")
    .required("This field is required!"),
  station_count: Yup.string().required("This field is required!"),
//   duty_count: Yup.string().required("This field is required!"),
//   special_casual_count: Yup.string().required("This field is required!"),

   
  });
  const handleSubmit = async (payload) => {
    setLoading(true);
    console.log(payload);

    const _data = await BaseApi().post(`update-staff-leaves`, payload);

    setShowUpdateLeaveModal(_data.data);
    setLoading(false);
    setInitialValues({
        staff_id: staff_id,
        casual_count: "",
        restricted_count: "",
        station_count: "N/A",
        duty_count: "",
        special_casual_count: "",
        earned_count: "",
    });

    setShowUpdateLeaveModal(false);
  };

  
  return (
    <>
      <div className='justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative   w-auto my-6 mx-auto max-w-3xl'>
          {/*content*/}

          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-3xl font-semibold'>Assign Leave</h3>
            </div>
            {/*body*/}
            <p style={{ width: "700px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative p-6 flex-auto'>
                    <div className='grid grid-cols-3 gap-4'>
                    <Input
                    label='Remaining Casual Leave'
                    name='casual_count'
                    placeholder='Enter number of remaining casual leave.'
                  />
                  <Input
                    label='Remaining Restricted Leave'
                    name='restricted_count'
                    placeholder='Enter number of remaining restricted leave.'
                  />

                  <Input
                    label='Remaining Station Leave'
                    name='station_count'
                    readOnly={true}
                    placeholder='Enter number of remaining station leave.'
                  />
                  {/* <Input
                    label='Remaining Duty Leave'
                    name='duty_count'
                    placeholder='Enter number of remaining duty leave.'
                  />
                  <Input
                    label='Remaining Special Casual Leave'
                    name='special_casual_count'
                    placeholder='Enter number of remaining specual casual leave.'
                  />
                  <Input
                    label='Remaining Earned Leave as on Today'
                    name='earned_count'
                    placeholder='Enter number of remaining earned leave.'
                  /> */}
                    </div>

                    <p className='my-4 text-slate-500 text-lg leading-relaxed'></p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => setShowUpdateLeaveModal(false)}
                    >
                      Close
                    </button>

                    <Button
                      disabled={loading}
                      value={loading ? "..." : "Update"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
}
