import React, { useEffect, useRef, useState } from "react";
import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import { toast } from "react-toastify";

import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../Redux/MasterSlice";
// import Select from "../../Components/Utils/Select";
import CustomSelect from "../../Components/Utils/CustomSelect";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import { YearSemesters } from "../../Constants/YearSemesters";
import tableToCSV from "../../Components/Utils/TableToCSV";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import BaseApi from "../../Http/BaseApi";

export default function ExStudents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const authData = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];

  //   const dispatch = useDispatch();
  console.log(message);
  useEffect(() => {
    // dispatch(messageSlice.actions.clearMessage());
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const initialValues = {
    programme_id: null,
    // semester: null,
    academic_session_id: null,
    type: null,
    nor: 10,
  };
  const validationSchema = Yup.object().shape({
    programme_id:
      authData.user.email == "admission@dibru.ac.in"
        ? Yup.string().nullable()
        : Yup.string().nullable().required("This field is required!"),
    type: Yup.string().nullable().required("This field is required!"),
    // semester: Yup.string().nullable().required("This field is required!"),
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  const handleSearch = async (searchQuery) => {
    setEdit(false);

    setLoading(true);
    console.log(searchQuery);

    await BaseApi()
      .get(`ex-students`, { params: searchQuery })
      .then((data) => {
        setStudents(data.data);
      })
      .catch(() => {
        window.alert("Something went wrong!");
      });

    setLoading(false);
  };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  const updateStudent = async (payload) => {
    let postData = {};
    if (payload.discontinued == 1) {
      postData = {
        fields: {
          discontinued: 1,
        },
        table: "programme_student",
        id: payload.programme_student_id,
      };
    } else if (payload.discontinued == 0) {
      postData = {
        fields: {
          discontinued: 0,
        },
        table: "programme_student",
        id: payload.programme_student_id,
      };
    } else if (payload.alumni == 0) {
      postData = {
        fields: {
          alumni: 0,
        },
        table: "programme_student",
        id: payload.programme_student_id,
      };
    } else if (payload.alumni == 1) {
      postData = {
        fields: {
          alumni: 1,
        },
        table: "programme_student",
        id: payload.programme_student_id,
      };
    }
    const response = await BaseApi().post(
      `admitted-students/${postData.id}`,
      postData
    );
    let _students = [...students];
    const index = _students.findIndex((student) => student.id == postData.id);

    _students[index] = response.data;
    setStudents(_students);

    toast.success("Data Updated!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,

      progress: undefined,
    });
  };
  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full min-h-screen overflow-x-scroll'>
            <PageTitle name='Ex. Student List' />

            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4'>
                  <CustomSelect
                    options={programmes}
                    name='programme_id'
                    label='Select Programme'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption.id
                      );
                    }}
                  />

                  {/* <CustomSelect
                    options={YearSemesters}
                    name="semester"
                    label="Select Semester"
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "semester",
                        selectedOption.value
                      );
                    }}
                  /> */}

                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Academic Session'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <CustomSelect
                    options={[
                      { label: "Discontinued", value: "discontinued" },
                      { label: "Alumni", value: "alumni" },
                      { label: "Any", value: "any" },
                    ]}
                    name='type'
                    label='Select Type'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "type",
                        selectedOption.value
                      );
                    }}
                  />

                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            {edit && students.length > 0 && authData.panel == "department" && (
              <>
                <Button
                  type='button'
                  classNames=' mr-2 ml-5  bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  value={"Done"}
                  icon={"CheckIcon"}
                  onClick={() => {
                    setEdit(false);
                  }}
                />
              </>
            )}
            {!edit && students.length > 0 && authData.panel == "department" && (
              <Button
                type='button'
                classNames='mr-2  ml-5  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                value={"Edit"}
                icon={"PencilIcon"}
                onClick={() => {
                  // setEdit(true);
                  setShowModal(true);
                }}
              />
            )}
            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr className='w-full h-16 py-8 border-b border-gray-300 dark:border-gray-200'>
                  <th className='table-head'>SL</th>
                  <th className='table-head'>ID</th>
                  <th className='table-head'>Status</th>
                  <th className='table-head'>Name</th>
                  <th className='table-head'>Gender</th>
                  <th className='table-head'>Caste</th>
                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Semester</th>
                  <th className='table-head'>Academic Session</th>
                  <th className='table-head'>Mobile No.</th>
                  <th className='table-head'>Email</th>
                  <th className='table-head'>Hostel</th>
                  <th className='table-head'>Details</th>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 &&
                  students.map((student, index) => (
                    <tr
                      key={student.id}
                      className='h-24 border-b border-gray-300 dark:border-gray-200'
                    >
                      <td className='table-data'>{++index}</td>

                      <td className='table-data'>
                        {student.unique_id || student.unique_idd}
                      </td>
                      <td className='table-data'>
                        <span
                          style={{
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {student.alumni == 1 && "Alumni"} <br />
                          {student.discontinued == 1 && "Discontinued"}
                        </span>
                      </td>
                      <td className='table-data'>
                        <div className='flex items-center'>
                          <div className='w-8 h-8'>
                            <img
                              src={student.student?.student_photo}
                              className='w-full h-full overflow-hidden rounded-full shadow'
                              onError={(e) => {
                                console.log(e.target.src);
                                if (e.target.src != "/assets/no-photo.jpg")
                                  e.target.src = "/assets/no-photo.jpg";
                              }}
                            />
                          </div>
                          <p className='ml-2 text-sm leading-4 tracking-normal text-gray-800 dark:text-gray-100'>
                            {student.student?.name}
                          </p>
                        </div>
                        {edit && (
                          <>
                            <hr className='m-4' />

                            <div className='flex items-center'>
                              {student.discontinued == 1 && (
                                <button
                                  onClick={async (e) => {
                                    e.target.disabled = true;
                                    if (window.confirm("Are you sure?")) {
                                      await updateStudent({
                                        programme_student_id: student.id,
                                        discontinued: 0,
                                      });
                                    }
                                    e.target.disabled = false;
                                  }}
                                  className='flex items-start text-sm font-medium text-orange-600'
                                >
                                  Cancel Discontinued
                                  <ExclamationCircleIcon
                                    className='w-4 h-4 ml-1'
                                    aria-hidden='true'
                                  />
                                </button>
                              )}
                              {student.discontinued != 1 && (
                                <button
                                  onClick={async (e) => {
                                    e.target.disabled = true;
                                    if (window.confirm("Are you sure?")) {
                                      await updateStudent({
                                        programme_student_id: student.id,
                                        discontinued: 1,
                                      });
                                    }
                                    e.target.disabled = false;
                                  }}
                                  className='flex items-start text-sm font-medium text-blue-700'
                                >
                                  Mark Discontinued
                                  <ExclamationCircleIcon
                                    className='w-4 h-4 ml-1'
                                    aria-hidden='true'
                                  />
                                </button>
                              )}

                              {student.alumni == 1 && (
                                <button
                                  onClick={async (e) => {
                                    e.target.disabled = true;
                                    if (window.confirm("Are you sure?")) {
                                      await updateStudent({
                                        programme_student_id: student.id,
                                        alumni: 0,
                                      });
                                    }
                                    e.target.disabled = false;
                                  }}
                                  className='flex items-start ml-5 text-sm font-medium text-orange-600'
                                >
                                  Cancel Alumni
                                  <ExclamationCircleIcon
                                    className='w-4 h-4 ml-1'
                                    aria-hidden='true'
                                  />
                                </button>
                              )}
                              {student.alumni != 1 && (
                                <button
                                  onClick={async (e) => {
                                    e.target.disabled = true;
                                    if (window.confirm("Are you sure?")) {
                                      await updateStudent({
                                        programme_student_id: student.id,
                                        alumni: 1,
                                      });
                                    }
                                    e.target.disabled = false;
                                  }}
                                  className='flex items-start ml-5 text-sm font-medium text-blue-700'
                                >
                                  Mark alumni
                                  <ExclamationCircleIcon
                                    className='w-4 h-4 ml-1'
                                    aria-hidden='true'
                                  />
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </td>
                      <td className='table-data'>{student.student.gender}</td>
                      <td className='table-data'>
                        {student.student.hcaste?.name}
                      </td>
                      <td className='table-data'>{student.programme?.code}</td>
                      <td className='table-data'>
                        {student.semester} <br />
                      </td>
                      <td className='table-data'>
                        {student.academic_session?.label}
                      </td>
                      <td className='table-data'>{student.student?.phone}</td>
                      <td className='table-data'>{student.student?.email}</td>
                      <td className='table-data'>
                        {student.student?.hostels?.find(
                          (h) =>
                            h.pivot?.payment_id != null &&
                            h.pivot?.out_date == null
                        )?.code ?? "No"}
                      </td>

                      <td className='table-data'>
                        <Button
                          classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                          value={"Details"}
                          icon={"ChevronDoubleRightIcon"}
                          onClick={() => {
                            navigate(`/admitted-students/${student.id}`);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {showModal ? (
              <>
                <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                  <div className='relative w-auto max-w-3xl mx-auto my-6'>
                    {/*content*/}
                    <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                        <h3 className='text-3xl font-semibold'>Instructions</h3>
                        <button
                          className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-100 focus:outline-none'
                          onClick={() => {
                            setEdit(false);
                            setShowModal(false);
                          }}
                        >
                          X
                        </button>
                      </div>
                      {/*body*/}
                      <div className='relative flex-auto p-6'>
                        <p className='my-4 text-lg leading-relaxed text-black'>
                          Please read the instructions carefully
                        </p>

                        <p className='my-4 text-lg leading-relaxed text-slate-500'>
                          1. The changes made here will be reflected globally in
                          the ERP Portal. Please be careful while editing.
                          <br />
                          2. Please ensure that all the students are registred
                          in the ERP Portal.
                        </p>
                      </div>
                      {/*footer*/}
                      <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
                        <button
                          className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                          type='button'
                          onClick={() => {
                            setEdit(true);
                            setShowModal(false);
                          }}
                        >
                          I agree to the instructions
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
              </>
            ) : null}

            {students.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
