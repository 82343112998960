import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import Input from "../../Components/Utils/Input";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
import Select from "../../Components/Utils/Select";
// import TextArea from "../../Components/Utils/TextArea";
import LeaveApplyPreview from "./LeaveApplyPreview";
import BaseApi from "../../Http/BaseApi";
// import TextArea from "../../Components/Utils/TextArea";
import { toast } from "react-toastify";
import Upload from "../../Components/Utils/Upload";
import { getLeaveTypesAsync } from "../../Redux/MasterSlice";
import { ThreeDots } from "react-loader-spinner";
import SearchableDropdown from "../../Components/Utils/SearchableDropdown";
import SelectLeaveDatesModal from "./SelectLeaveDatesModal";
//  import objectToFormData from "../../Components/Utils/ObjToFormData";

export default function LeaveCreate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [staff, setStaff] = useState([]);
  const [allStaff, setAllStaff] = useState([]);

  // const [formRole, setFormRole] = useState("add");
  const [hasStationLeave, setHasStationLeave] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const leaveTypes = [...master.leaveTypes];

  const [showDatesModal, setShowDatesModal] = useState(false);
  const [holidayIncluded, setHolidayIncluded] = useState();

  const [formikValues, setFormikValues] = useState(formikRef.current?.values);

  useEffect(() => {
    // Update formikValues state whenever Formik values change
    console.log("update formik values");
    setFormikValues(formikRef.current?.values);
  }, [formikRef.current?.values]);

  const MAX_FILE_SIZE = 1024000; //1000KB

  // console.log(id, staff, formRole);
  console.log(loading);
  const proposed_incharge = {
    incharge_id: "",
    pi_remarks: "",
  };
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    if (leaveTypes.length == 0) {
      dispatch(getLeaveTypesAsync());
    }
    setInitialValues({
      leave_type_id: id,
      from_date: "",
      to_date: "",
      total_days: "",
      file: "",
      arrangement: "",
      proposed_incharge: [{ ...proposed_incharge }],
      reason: "",
      station_leave: "",
      sl_from_date: "",
      sl_from_time: "",
      sl_to_date: "",
      sl_to_time: "",
      address: "",
      leave_dates: [],
    });
    if (authData) {
      getStaff(authData.user?.id);
    }
    if (allStaff.length == 0) {
      getAllStaff();
    }
  }, [dispatch]);
  // console.log("id is--------", id)

  async function getAllStaff() {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`/staff_minimal`)
      .then((data) => {
        let _allstaff = data.data;
        setAllStaff(_allstaff);
        console.log("-------", allStaff);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  const getStaff = async (id) => {
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        setStaff(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
    setLoading(false);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    let values = { ...formikRef.current?.values };

    let proposed_incharges = [
      ...values.proposed_incharge,
      { ...proposed_incharge },
    ];
    values.proposed_incharge = proposed_incharges;
    setInitialValues(values);

    formikRef.current?.setFieldValue("proposed_incharge", proposed_incharges);
  };

  const handleRemoveFields = (index, event) => {
    event.preventDefault();
    let values = { ...formikRef.current?.values };
    let proposed_incharges = [...values.proposed_incharge];

    if (proposed_incharges.length === 1) {
      return toast.error("Can not Remove Row...");
    }
    proposed_incharges.splice(index, 1);
    values.proposed_incharge = proposed_incharges;
    setInitialValues(values);

    formikRef.current?.setFieldValue("proposed_incharge", proposed_incharges);

    // console.log("rows", values1.length);
  };

  var validationSchema = null;

  if (id == 6) {
    validationSchema = Yup.object().shape({
      from_date: Yup.date().required("This field is required!"),
      to_date: Yup.date()
        .required("This field is required!")
        .min(
          Yup.ref("from_date"),
          "To date must be greater or equal to from date"
        ),
      total_days: Yup.number()
        .min(1, "Min value 1")
        .max(60, `Maximum permitted days 60`)
        .required("This field is required!"),
      // file: Yup.mixed().required("This field is required!").test("is-valid-size", "Max allowed size is 1MB",
      // value => value && value.size <= MAX_FILE_SIZE),
      // arrangement: Yup.string().required("This field is required!"),
      proposed_incharge: Yup.array().of(
        Yup.object().shape({
          incharge_id: Yup.number("Please select from the list"),
          pi_remarks: Yup.string().when("incharge_id", {
            is: (incharge_id) => incharge_id > 0,
            then: Yup.string().required("This field is required!"),
          }),
        })
      ),
      reason: Yup.string()
        .required("This field is required!")
        .max(255, "Maximum 250 character allowed"),
      station_leave: Yup.string().required("This field is required!"),
      sl_from_date: Yup.date().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.date().required("This field is required!"),
      }),
      sl_from_time: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),
      sl_to_date: Yup.date().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.date()
          .min(
            Yup.ref("sl_from_date"),
            "To date must be greater or equal to from date"
          )
          .required("This field is required!"),
      }),
      sl_to_time: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),

      address: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),
    });
  } else if (id == 4 || id == 5) {
    validationSchema = Yup.object().shape({
      from_date: Yup.date().required("This field is required!"),
      to_date: Yup.date()
        .required("This field is required!")
        .min(
          Yup.ref("from_date"),
          "To date must be greater or equal to from date"
        ),
      total_days: Yup.number()
        .min(1, "Min value 1")
        .max(
          id == 5 ? 4 : id == 4 ? 30 : null,
          `Maximum permitted days ${id == 5 ? 4 : id == 4 ? 30 : null}`
        )
        .required("This field is required!"),
      file: Yup.mixed()
        .required("This field is required!")
        .test(
          "is-valid-size",
          "Max allowed size is 1MB",
          (value) => value && value.size <= MAX_FILE_SIZE
        ),
      // arrangement: Yup.string().required("This field is required!"),
      proposed_incharge: Yup.array().of(
        Yup.object().shape({
          incharge_id: Yup.number("Please select from the list"),
          pi_remarks: Yup.string().when("incharge_id", {
            is: (incharge_id) => incharge_id > 0,
            then: Yup.string().required("This field is required!"),
          }),
        })
      ),
      reason: Yup.string()
        .required("This field is required!")
        .max(255, "Maximum 250 character allowed"),
      station_leave: Yup.string().required("This field is required!"),
      sl_from_date: Yup.date().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.date().required("This field is required!"),
      }),
      sl_from_time: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),
      sl_to_date: Yup.date().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.date()
          .min(
            Yup.ref("sl_from_date"),
            "To date must be greater or equal to from date"
          )
          .required("This field is required!"),
      }),
      sl_to_time: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),

      address: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),
    });
  } else if (id == 3) {
    validationSchema = Yup.object().shape({
      from_date: Yup.date().required("This field is required!"),
      to_date: Yup.date()
        .required("This field is required!")
        .min(
          Yup.ref("from_date"),
          "To date must be greater or equal to from date"
        ),
      total_days: Yup.number()
        .min(1, "Min value 1")

        .required("This field is required!"),
      // file: Yup.string().required("This field is required!"),
      // arrangement: Yup.string().required("This field is required!"),
      // proposed_incharge: Yup.string().required("This field is required!"),
      proposed_incharge: Yup.array().of(
        Yup.object().shape({
          incharge_id: Yup.number("Please select from the list"),
          pi_remarks: Yup.string().when("incharge_id", {
            is: (incharge_id) => incharge_id > 0,
            then: Yup.string().required("This field is required!"),
          }),
        })
      ),
      reason: Yup.string()
        .required("This field is required!")
        .max(255, "Maximum 250 character allowed"),

      sl_from_time: Yup.string().required("This field is required!"),

      sl_to_time: Yup.string().required("This field is required!"),

      address: Yup.string().required("This field is required!"),
    });
  } else {
    validationSchema = Yup.object().shape({
      from_date: Yup.date().required("This field is required!"),
      to_date: Yup.date()
        .required("This field is required!")
        .min(
          Yup.ref("from_date"),
          "To date must be greater or equal to from date"
        ),
      total_days: Yup.number()
        .min(1, "Min value 1")
        .max(
          id == 1 ? 4 : id == 2 ? 2 : null,
          `Maximum permitted days ${id == 1 ? 4 : id == 2 ? 2 : null}`
        )
        .required("This field is required!"),
      // file: Yup.string().required("This field is required!"),
      // arrangement: Yup.string().required("This field is required!"),
      proposed_incharge: Yup.array().of(
        Yup.object().shape({
          incharge_id: Yup.number("Please select from the list"),
          pi_remarks: Yup.string().when("incharge_id", {
            is: (incharge_id) => incharge_id > 0,
            then: Yup.string().required("This field is required!"),
          }),
        })
      ),
      reason: Yup.string()
        .required("This field is required!")
        .max(255, "Maximum 250 character allowed"),
      station_leave: Yup.string().required("This field is required!"),
      sl_from_date: Yup.date().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.date().required("This field is required!"),
      }),
      sl_from_time: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),
      sl_to_date: Yup.date().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.date()
          .min(
            Yup.ref("sl_from_date"),
            "To date must be greater or equal to from date"
          )
          .required("This field is required!"),
      }),
      sl_to_time: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),

      address: Yup.string().when("station_leave", {
        is: (station_leave) => station_leave == "true",
        then: Yup.string().required("This field is required!"),
      }),
    });
  }

  const handleSubmit = async (payload, { resetForm }) => {
    for (let i = 0; i < payload.proposed_incharge?.length; i++) {
      console.log(payload.proposed_incharge[i]);
      if (
        payload.proposed_incharge[i].incharge_id != null &&
        payload.proposed_incharge[i].incharge_id != "" &&
        payload.proposed_incharge[i].incharge_id <= 0
      ) {
        alert("Proposed Incharge Not Set Properly");
        return;
      }
    }

    if (showPreview == false) {
      setShowPreview(true);
      return;
    }
    if (window.confirm("Are you sure?") != true) {
      return;
    }

    console.log("data is-", payload);

    setLoading(true);
    // setFormRole("add");
    console.log(payload);
    var form_data = new FormData();

    for (var key in payload) {
      console.log(key);
      if (key === "proposed_incharge") {
        // append nested object
        for (let piKey in payload[key]) {
          for (let piKey1 in payload[key][piKey]) {
            form_data.append(
              `proposed_incharge[${piKey}][${piKey1}]`,
              payload[key][piKey][piKey1]
            );
          }

          // form_data.append(`proposed_incharge[${piKey}]`, payload[key][piKey]);
        }
      } else if (key === "leave_dates") {
        // append nested object
        for (let piKey in payload[key]) {
          for (let piKey1 in payload[key][piKey]) {
            form_data.append(
              `leave_dates[${piKey}][${piKey1}]`,
              payload[key][piKey][piKey1]
            );
          }

          // form_data.append(`proposed_incharge[${piKey}]`, payload[key][piKey]);
        }
      } else {
        form_data.append(
          key,

          payload[key]
        );
      }
    }

    try {
      let response = await BaseApi().post("/leave", form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      resetForm();
      setInitialValues({
        from_date: "",
        to_date: "",
        total_days: "",
        arrangement: "",
        proposed_incharge: [{ ...proposed_incharge }],
        reason: "",
        station_leave: "",
        sl_from_date: "",
        sl_from_time: "",
        sl_to_date: "",
        sl_to_time: "",
        address: "",
      });
      setShowPreview(false);
      setLoading(false);

      navigate(`/leaves`);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,

        progress: undefined,
      });
      setLoading(false);
    }
  };

  const calculateDays = async (from_date, to_date) => {
    if (from_date == "" || to_date == "") {
      formikRef.current?.setFieldValue("total_days", "");

      return;
    }
    setLoading(true);
    let response = await BaseApi().get("/leave/get_no_of_days", {
      params: {
        from_date,
        to_date,
        leave_id: id,
      },
    });
    console.log(response);
    formikRef.current?.setFieldValue("total_days", response.data.count);
    // Transform the data
    const dateStatuses = Object.entries(response.data.dateStatuses).map(
      ([date, status]) => ({
        date,
        status,
        apply_leave: status === "Eligible",
      })
    );

    formikRef.current?.setFieldValue("leave_dates", dateStatuses);
    setLoading(false);
  };

  const handleLeaveDateChange = (newLeaveDates) => {
    console.log(newLeaveDates);
    formikRef.current?.setFieldValue("leave_dates", newLeaveDates);

    // Calculate the new total days
    const totalDays = newLeaveDates.reduce((count, date) => {
      return date.apply_leave ? count + 1 : count;
    }, 0);

    // Update the total_days in Formik
    formikRef.current?.setFieldValue("total_days", totalDays);
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle
              name={`Apply for ${leaveTypes.find((lt) => lt.id == id)?.name} `}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            <a
              className='float-right text-blue-700'
              href='/holiday-calendar'
              target='_blank'
            >
              View Holiday Calendar
            </a>
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <h3 className='pt-6 text-xl'>
                  {/* {formRole === "add" ? "Create New" : "Edit"} Staff: */}
                </h3>
                <div className='grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <div className=''>
                    <label
                      htmlFor={"from_date"}
                      className='block text-sm font-medium text-gray-700'
                    >
                      From *
                    </label>

                    <Field
                      id='from_date'
                      type='date'
                      name='from_date'
                      // value={props.value}
                      // onChange={props.onChange}
                      onChange={(event) => {
                        formikRef.current?.setFieldValue(
                          "from_date",
                          event.target.value
                        );
                        calculateDays(
                          event.target.value,
                          formikRef.current?.values.to_date
                        );
                      }}
                      placeholder='from_date'
                      className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                    />
                    <ErrorMessage
                      name='from_date'
                      component='div'
                      className='py-2 text-red-500'
                    />
                  </div>
                  {id == 3 && (
                    <Select name='sl_from_time' label='Select'>
                      <option value=''>Select</option>
                      <option value='Forenoon'>Forenoon</option>
                      <option value='Afternoon'>Afternoon</option>
                    </Select>
                  )}
                  <div className=''>
                    <label
                      htmlFor={"to_date"}
                      className='block text-sm font-medium text-gray-700'
                    >
                      To *
                    </label>

                    <Field
                      id='to_date'
                      type='date'
                      name='to_date'
                      // value={props.value}
                      // onChange={props.onChange}
                      onChange={(event) => {
                        formikRef.current?.setFieldValue(
                          "to_date",
                          event.target.value
                        );
                        calculateDays(
                          formikRef.current?.values.from_date,
                          event.target.value
                        );
                      }}
                      placeholder='to_date'
                      className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                    />
                    <ErrorMessage
                      name='to_date'
                      component='div'
                      className='py-2 text-red-500'
                    />
                  </div>
                  {id == 3 && (
                    <Select name='sl_to_time' label='Select *'>
                      <option value=''>Select</option>
                      <option value='Forenoon'>Forenoon</option>
                      <option value='Afternoon'>Afternoon</option>
                    </Select>
                  )}
                  {/* <Input
                    label="Leave To"
                    type="date"
                    name="to_date"
                    placeholder="To Date"
                  /> */}
                  <div>
                    <Input
                      readOnly={true}
                      label={"No. of Days *"}
                      name='total_days'
                      placeholder='No. of Days'
                    />
                    {id == 3 || id == 4 || id == 6 || id == 2 ? (
                      ""
                    ) : (
                      <>
                        <span>
                          ({holidayIncluded ? "Including" : "Excluding"}{" "}
                          Saturday/Sunday/Holidays)
                        </span>{" "}
                        <br />
                        {(id == 1 || id == 5) && (
                          <button
                            onClick={() => {
                              if (holidayIncluded == true) {
                                calculateDays(
                                  formikRef.current?.values.from_date,
                                  formikRef.current?.values.to_date
                                );
                                setHolidayIncluded(false);
                              } else {
                                setShowDatesModal(true);
                              }
                            }}
                            type='button'
                            className='font-bold text-blue-700'
                          >
                            Click here to{" "}
                            {holidayIncluded ? "exclude" : "include"}, if
                            applicable{" "}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  {id == 3 && (
                    <Input
                      as='textarea'
                      label='Address during Absence *'
                      name='address'
                      placeholder='Address during Absence'
                    />
                  )}
                  {id != 3 && (
                    <>
                      <Input
                        name='arrangement'
                        label='Arrangement of Classes/Lab (if applicable)'
                      />
                      {/* <Input
                        name="proposed_incharge"
                        label="Proposed In-charge (if applicable)"
                      /> */}
                    </>
                  )}{" "}
                  <Input
                    as='textarea'
                    label='Reason for Leave*'
                    type='text'
                    name='reason'
                    placeholder='Reason for Leave'
                  />
                  <Upload
                    label={
                      id == 4 || id == 5
                        ? "Supporting Document *"
                        : "Supporting Document(if any)"
                    }
                    type='file'
                    name='file'
                    onChange={(event) => {
                      console.log("asd");
                      let myFile = event.currentTarget.files[0];
                      formikRef.current.setFieldValue("file", myFile);
                    }}
                  />
                </div>
                <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />
                <b className='text-lg'>Proposed In-charge</b>
                <br />

                {initialValues?.proposed_incharge.map((inputField, index) => (
                  <div key={index} className='grid grid-cols-12 gap-2'>
                    <div className='col-span-6 sm:col-span-3'>
                      <div>
                        {" "}
                        <SearchableDropdown
                          name={`proposed_incharge[${index}].incharge_id`}
                          placeholder='Search...'
                          label='Select In-charge'
                          options={allStaff}
                          setSelectedOption={(selectedOption) => {
                            console.log(selectedOption);
                            formikRef.current?.setFieldValue(
                              `proposed_incharge[${index}].incharge_id`,
                              selectedOption.id ?? ""
                            );
                          }}
                        />
                        {/* <Input
                            label="Incharge"
                            type="text"
                            name={`proposed_incharge[${index}].incharge`}
                            placeholder="Incharge"
                          /> */}
                      </div>
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <Input
                        type='text'
                        name={`proposed_incharge[${index}].pi_remarks`}
                        // onChange={(event) => handleChangeDetails(index, event)}
                        label='Remark'
                      />
                    </div>
                    <div className='col-span-6 sm:col-span-1 '>
                      {index === 0 ? <br /> : <></>}
                      <div className='flex flex-row '>
                        <div className='pt-2 '>
                          <Button
                            type='button'
                            onClick={handleAddFields}
                            value=''
                            icon={"PlusIcon"}
                          />
                        </div>
                        <span className='mr-2'></span>
                        <div className='pt-2 '>
                          <Button
                            onClick={(event) =>
                              handleRemoveFields(index, event)
                            }
                            value=''
                            icon={"MinusIcon"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />
                {id != 3 && (
                  <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>
                    <div className=''>
                      <label className='block text-sm font-medium text-gray-700'>
                        Would you like to apply for station leave during this
                        period? *
                      </label>
                      <select
                        defaultValue={""}
                        onChange={(event) => {
                          console.log(event.target.value);
                          setHasStationLeave(event.target.value);
                          formikRef.current?.setFieldValue(
                            "station_leave",
                            event.target.value
                          );
                        }}
                        name='station_leave'
                        className={`rounded-none mt-2 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm `}
                      >
                        <option value=''>Select Any One</option>
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                      <ErrorMessage
                        name='station_leave'
                        component='div'
                        className='py-2 text-red-500'
                      />
                    </div>
                  </div>
                )}
                <hr className='m-10' />

                {hasStationLeave == "true" && (
                  <>
                    {" "}
                    <div className='grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6'>
                      <Input
                        label='Out of Station from *'
                        type='date'
                        name='sl_from_date'
                        placeholder='From Date'
                      />

                      <Select name='sl_from_time' label='Select *'>
                        <option value=''>Select</option>
                        <option value='Forenoon'>Forenoon</option>
                        <option value='Afternoon'>Afternoon</option>
                      </Select>
                      <div></div>

                      <Input
                        label='Out of Station to *'
                        type='date'
                        name='sl_to_date'
                        placeholder='To Date'
                      />

                      <Select name='sl_to_time' label='Select *'>
                        <option value=''>Select</option>
                        <option value='Forenoon'>Forenoon</option>
                        <option value='Afternoon'>Afternoon</option>
                      </Select>
                      <div></div>
                    </div>
                    <div className='grid gap-8 mt-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4'>
                      <Input
                        as='textarea'
                        label='Address during Absence *'
                        name='address'
                        placeholder='Address during Absence'
                      />
                    </div>
                  </>
                )}

                <div className='mt-5 text-center'>
                  <Button value={"Next"} icon={"SaveIcon"} type='submit' />
                </div>
                <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />

                {showPreview ? (
                  <LeaveApplyPreview
                    leaveTypes={leaveTypes}
                    staff={staff}
                    allStaff={allStaff}
                    setShowPreview={setShowPreview}
                    data={formikRef.current?.values}
                    loading={loading}
                  />
                ) : null}

                {/* <div className="mt-5 text-left">
                  <Button
                    value={loading ? "Saving..." : "Apply"}
                    icon={"SaveIcon"}
                    type="submit"
                  />
                </div> */}

                {showDatesModal ? (
                  <SelectLeaveDatesModal
                    setShowDatesModal={setShowDatesModal}
                    data={formikValues}
                    handleLeaveDateChange={handleLeaveDateChange}
                    setHolidayIncluded={setHolidayIncluded}
                  />
                ) : null}
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
