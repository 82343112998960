import React, { useEffect, useRef, useState } from "react";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

// import Select from "../../Components/Utils/Select";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../../Redux/MasterSlice";
// import { getAdmittedStudentsAsync } from "../../../Redux/StudentSlice";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { YearSemesters } from "../../../Constants/YearSemesters";
import Button from "../../../Components/Utils/Button";
import tableToCSV from "../../../Components/Utils/TableToCSV";
// import can from "../../Middleware/Permission";
// import MakeFeeCollection from "./MakeFeeCollection";

import BaseApi from "../../../Http/BaseApi";
import StudentPromotionModal from "./StudentPromotionModal";
import PageTitle from "../../../Components/Common/PageTitle";
// import { SearchNor } from "../../Constants/SearchNor";

export default function StudentPromotion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const authData = useSelector((state) => state.auth);

  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentFilter, setStudentFilter] = useState();
  // const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const [selectedIds, setSelectedIds] = useState([]);
  const [students, setStudents] = useState([]);
  // const [programme_id, setProgrammeID] = useState("");
  // const [semester_id, setSemesterID] = useState("");
  // const [academic_session, setAcademicSession] = useState("");

  // let students = useSelector((state) => state.students.admittedStudents);

  //   const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(messageSlice.actions.clearMessage());
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const initialValues = {
    programme_id: null,
    semester: null,
    academic_session_id: null,
    nor: 10,
  };
  const validationSchema = Yup.object().shape({
    programme_id: Yup.string().nullable().required("This field is required!"),
    semester: Yup.string().nullable().required("This field is required!"),
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  const handleSearch = async (searchQuery) => {
    setLoading(true);
    setSelectedIds([]);
    setStudents([]);
    console.log(searchQuery);
    const response = await BaseApi().get(`admitted-students`, {
      params: searchQuery,
    });
    setStudents(response.data);
    // await dispatch(getAdmittedStudentsAsync({ payload: searchQuery }))
    //   .unwrap()
    //   .then(() => {})
    //   .catch(() => {});
    //studentFilter
    setStudentFilter(searchQuery);

    // setAcademicSession(studentFilter.academic_session_id);
    // setProgrammeID(studentFilter.programme_id);
    // setSemesterID(studentFilter.semester)
    // console.log(message);
    // console.log('data is---', programme_id,semester_id,academic_session)

    setLoading(false);
  };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  const handleCheckbox = (event) => {
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      setSelectedIds([...selectedIds, value]);
    } else {
      var index = selectedIds.indexOf(value);
      if (index != -1) {
        setSelectedIds(selectedIds.filter((item) => item != value));
      }
    }
  };

  const reloadApplicant = async () => {
    handleSearch(studentFilter);
  };

  return (
    <>
      {true && (
        <>
          <div className='py-2'>
            <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
              <div className='w-full overflow-x-scroll'>
                <PageTitle name='Promote Students' />

                <Formik
                  innerRef={formikRef}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSearch}
                >
                  <Form>
                    <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                      <CustomSelect
                        options={programmes.filter(
                          (p) => p.programmetype_id != 2
                        )}
                        name='programme_id'
                        label='Select Programme'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "programme_id",
                            selectedOption.id
                          );
                        }}
                      />

                      <CustomSelect
                        options={YearSemesters}
                        name='semester'
                        label='Select Year/Semester'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "semester",
                            selectedOption.value
                          );
                        }}
                      />

                      <CustomSelect
                        options={academicSessions}
                        name='academic_session_id'
                        label='Select Academic Session'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "academic_session_id",
                            selectedOption.id
                          );
                        }}
                      />

                      <div className='mt-5 text-left'>
                        <button
                          type='submit'
                          className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          disabled={loading}
                        >
                          {loading && <span>Searching...</span>}
                          {!loading && <span>Search</span>}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
                <Button
                  classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                  value={"Download CSV"}
                  onClick={() => tableToCSV("datatable")}
                  icon={"DownloadIcon"}
                />
                <table
                  id='datatable'
                  className='min-w-full bg-white dark:bg-gray-800'
                >
                  <thead>
                    <tr className='w-full h-16 py-8 border-b border-gray-300 dark:border-gray-200'>
                      <th className='table-head'>SL</th>
                      <th className='table-head'></th>
                      <th className='table-head'>ID</th>
                      <th className='table-head'>Name</th>
                      <th className='table-head'>Programme</th>
                      <th className='table-head'>Year/Semester</th>
                      <th className='table-head'>Mobile No.</th>
                      <th className='table-head'>Email</th>

                      <th className='table-head'>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.length > 0 &&
                      students.map((student, index) => (
                        <tr
                          key={student.id}
                          className='h-24 border-b border-gray-300 dark:border-gray-200'
                        >
                          <td className='table-data'>{++index}</td>

                          <td className={"table-data" + "w-32"}>
                            <input
                              className='float-left w-8 h-8 mt-1 mr-5 text-blue-600 align-top transition duration-200 bg-white border border-gray-300 rounded-sm cursor-pointer form-check-input focus:outline-none'
                              type='checkbox'
                              value={student.id}
                              onChange={(event) => handleCheckbox(event)}
                              id={student.id}
                            />
                          </td>
                          <td className='table-data'>
                            {student.unique_id || student.unique_idd}
                          </td>
                          <td className='table-data'>
                            <div className='flex items-center'>
                              <div className='w-8 h-8'>
                                <img
                                  src={student.student?.student_photo}
                                  className='w-full h-full overflow-hidden rounded-full shadow'
                                  onError={(e) => {
                                    console.log(e.target.src);
                                    if (e.target.src != "/assets/no-photo.jpg")
                                      e.target.src = "/assets/no-photo.jpg";
                                  }}
                                />
                              </div>
                              <p className='ml-2 text-sm leading-4 tracking-normal text-gray-800 dark:text-gray-100'>
                                {student.student?.name}
                              </p>
                            </div>
                          </td>
                          <td className='table-data'>
                            {student.programme?.code}
                          </td>
                          <td className='table-data'>{student.semester}</td>
                          <td className='table-data'>
                            {student.student?.phone}
                          </td>
                          <td className='table-data'>
                            {student.student?.email}
                          </td>

                          <td className='table-data'>
                            <Button
                              classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                              value={"Details"}
                              icon={"ChevronDoubleRightIcon"}
                              onClick={() => {
                                navigate(`/admitted-students/${student.id}`);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {students.length == 0 && (
                  <div className='my-10 text-center'>No Data</div>
                )}
                <div className='m-10 text-center'>
                  {authData.panel == "department" && (
                    <>
                      <Button
                        classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                        value={"Promote"}
                        icon={"ChevronDoubleRightIcon"}
                        onClick={() => {
                          if (selectedIds.length > 0) {
                            setType("promotion");
                            console.log(studentFilter);
                            setShowModal(true);
                          } else {
                            alert("Please select atleast one student.");
                          }
                        }}
                      />
                      {/* <Button
                    name="type"
                    classNames="ml-10 bg-red-600 hover:bg-red-700 focus:ring-red-500"
                    value={"Demotion"}
                    icon={"ChevronDoubleRightIcon"}
                    onClick={() => {
                      if (selectedIds.length > 0) {
                        setType("demotion");
                        setShowModal(true);
                      } else {
                        alert("Please select atleast one student.");
                      }
                    }}
                  /> */}
                    </>
                  )}
                </div>
              </div>

              {showModal ? (
                <StudentPromotionModal
                  onStudentPromotionModal={reloadApplicant}
                  selectedIds={selectedIds}
                  type={type}
                  studentFilter={studentFilter}
                  setShowModal={setShowModal}
                  loading={loading}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
