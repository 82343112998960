import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaFileLines,
  FaMapLocationDot,
  FaRegComments,
  FaUsers,
  FaUserTie,
} from "react-icons/fa6";

const headers = {
  name: { label: "Name of the workshop/ training programme", path: "name" },
  description: { label: "Brief Description", path: "description" },
  organized_by: { label: " Organized by", path: "organized_by" },
  held_at: { label: "Held at", path: "held_at" },
  // month: { label: "Month", path: "monthName" },
  // year: { label: "Year", path: "year" },
  role: { label: "Your Role", path: "role" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const WorkshopTraining = ({ view }) => {
  const [workshopTrainings, setWorkshopTrainings] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/workshop-trainings");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setWorkshopTrainings(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/workshop-trainings");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredData = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      const sortedData = filteredData.sort((a, b) => {
        const startDateA = getValueByPath(a, headers.start_date.path);
        const startDateB = getValueByPath(b, headers.start_date.path);
        const dateA = startDateA ? moment(startDateA) : moment("1900-01-01");
        const dateB = startDateB ? moment(startDateB) : moment("1900-01-01");
        return dateB - dateA; // Descending order
      });

      setWorkshopTrainings(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const renderWorkshopTraining = (workshopTraining) => {
    const name = getValueByPath(workshopTraining, headers.name.path);
    const description = formatDate(
      getValueByPath(workshopTraining, headers.description.path)
    );
    const organizedBy = getValueByPath(
      workshopTraining,
      headers.organized_by.path
    );
    const heldAt = getValueByPath(workshopTraining, headers.held_at.path);
    const startDate = formatDate(
      getValueByPath(workshopTraining, headers.start_date.path)
    );
    const endDate = formatDate(
      getValueByPath(workshopTraining, headers.start_date.path)
    );

    const role = getValueByPath(workshopTraining, headers.role.path);

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>

          <span className='flex items-center gap-1'>
            <FaUserTie className='text-yellow-500' />
            Organized By: {organizedBy}
          </span>

          <span className='flex items-center gap-1'>
            <FaUsers className='text-yellow-500' />
            Role: {role}
          </span>
          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaMapLocationDot className='text-yellow-500' />
              {heldAt}
            </span>
 
            <span className='flex items-center gap-1'>
              <FaCalendarDays className='text-yellow-500' />
              {startDate} - {endDate}
 
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        {/* <SiHiveBlockchain /> */}
        Participation in Workshop/Training Programme
      </h2>
      {workshopTrainings.length ? (
        <div className=''>
          {workshopTrainings.map((workshopTraining, index) => (
            <div key={index} className=''>
              {renderWorkshopTraining(workshopTraining)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default WorkshopTraining;
