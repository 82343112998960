import { ErrorMessage } from "formik";
import React from "react";
import ReactSelect from "react-select";

export default function CustomSelect(props) {
  const menuIsOpen = props.readOnly ? false : props.menuIsOpen;

  return (
    <>
      <div className={props.className}>
        <label
          htmlFor={props.id}
          className='block text-sm font-medium text-gray-700'
        >
          {props.label}
        </label>

        <ReactSelect
          // value={props.options?.find((option) => option.id == props.value)}
          defaultInputValue={props.defaultInputValue}
          menuIsOpen={menuIsOpen}
          // key={props.key}

          ref={props.reference}
          isClearable={true}
          backspaceRemovesValue={true}
          className={`   rounded-none relative block w-full   py-2  placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm `}
          options={props.options}
          name={props.name}
          isSearchable={props.isSearchable ?? false}
          getOptionValue={props.getOptionValue}
          getOptionLabel={props.getOptionLabel}
          onChange={props.onChange}
        />

        {/* <Field
          as="select"
          id={props.id}
          name={props.name}
          autoComplete=""
          disabled={props.disabled}
          
        >
          {props.children}
        </Field> */}
        <ErrorMessage
          name={props.name}
          component='div'
          className='py-2 text-red-500'
        />
      </div>
    </>
  );
}
