import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  const collaborationTypes = [
    { id: 1, name: "Research" },
    { id: 2, name: "Co-Author" },
    { id: 3, name: "Internship" },
    { id: 4, name: "Student Exchange" },
    { id: 5, name: "Others" },
  ];
  const labelCollaborationTypes = [
    { id: 1, name: "National" },
    { id: 2, name: "International" },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Collaboration
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-2 gap-3 p-5 '>
            <Input
              type='text'
              label={
                <div className='flex items-center'>
                  Name of the Institute/ Organization/Individual
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='collaboration_name'
            />
            <CustomSelect
              defaultInputValue={initialValues.collaboration_type}
              options={collaborationTypes}
              name='collaboration_type'
              label={
                <div className='flex items-center'>
                  Type of Collaboration
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "collaboration_type",
                  selectedOption.name
                );
              }}
            />
            {values.collaboration_type === "Others" && (
              <Input
                type='text'
                label={
                  <div className='flex items-center'>
                    Other Collaboration
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                name='other_collaboration'
              />
            )}

            <CustomSelect
              defaultInputValue={initialValues.collaboration_details}
              options={labelCollaborationTypes}
              name='collaboration_details'
              label={
                <div className='flex items-center'>
                  Level of the Collaboration
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "collaboration_details",
                  selectedOption.name
                );
              }}
            />

            <Input
              type='date'
              name='from_date'
              label={
                <div className='flex items-center'>
                  From Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input type='date' name='to_date' label='To Date' />
            <Input
              type='textarea'
              label={
                <div className='flex items-center'>
                  Objective of the Collaboration
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='collaboration_objective'
            />

            <div className='flex justify-between col-span-2 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
