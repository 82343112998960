import React, { useEffect, useState } from "react";
import {
  FaClipboardList,
  FaPeopleGroup,
  FaUniregistry,
  FaUserGraduate,
} from "react-icons/fa6";
import { FaRegThumbsUp, FaUniversity } from "react-icons/fa";
import BaseApi from "../../../../../../Http/BaseApi";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: { label: "Role", path: "role" },
  thesis_title: { label: "Thesis Title", path: "thesis_title" },
  other_institute: {
    label: "Name of the University/Institute",
    path: "other_institute",
  },
  status: { label: "Status", path: "status" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const ExternalPhdScholar = ({ view }) => {
  const [externalPhdScholars, setExternalPhdScholars] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/external-phd-scholars");
      const filterField = view === "iqac" ? "iqac" : "website";
      setExternalPhdScholars(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderExternalPhdScholar = (guidance) => {
    const scholarName = getValueByPath(guidance, headers.researcher_name.path);
    const role = getValueByPath(guidance, headers.role.path);
    const thesisTitle = getValueByPath(guidance, headers.thesis_title.path);

    const otherInstitute = getValueByPath(
      guidance,
      headers.other_institute.path
    );
    const status = getValueByPath(guidance, headers.status.path);

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'> Role: {role}</h3>
          <p className='flex items-center gap-2'>
            <span className='w-64 text-green-600'>
              <FaUserGraduate className='inline' /> {scholarName}
            </span>

            <FaClipboardList />
            <span className='text-gray-800'>{thesisTitle}</span>
          </p>

          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaRegThumbsUp
                className={
                  status === "Ongoing" ? "text-yellow-500" : "text-red-500"
                }
              />
              {status}
            </span>

            <span className='flex items-center gap-1'>
              <FaUniversity className='text-yellow-500' />
              {otherInstitute}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        External Ph. D. Scholar
      </h2>
      {externalPhdScholars.length ? (
        <div className=''>
          {externalPhdScholars.map((guidance, index) => (
            <div key={index} className=''>
              {renderExternalPhdScholar(guidance)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default ExternalPhdScholar;
