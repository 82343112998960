import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaFileLines,
  FaRegComments,
  FaUserTie,
} from "react-icons/fa6";

const headers = {
  fdp_name: { label: "Name of the FDP", path: "fdp_name" },
  start_date: { label: "FDP Start date", path: "start_date" },
  end_date: { label: "FDP End date", path: "end_date" },
  organizer: { label: "Organizer", path: "organizer" },
  mode: { label: "Mode of FDP", path: "mode" },
  details: { label: "Details of FDP", path: "details" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const FIPActivitiesParticipation = ({ view }) => {
  const [fIPActivitiesParticipations, setFIPActivitiesParticipations] =
    useState([]);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get(
  //       "/profile/fip-activities-participations"
  //     );
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setFIPActivitiesParticipations(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/fip-activities-participations"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredData = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by start_date (latest first)
      const sortedData = filteredData.sort((a, b) => {
        const dateA = getValueByPath(a, headers.start_date.path);
        const dateB = getValueByPath(b, headers.start_date.path);

        return moment(dateB).diff(moment(dateA)); // Latest date first
      });

      setFIPActivitiesParticipations(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const renderFIPActivitiesParticipation = (fIPActivitiesParticipation) => {
    const fdpName = getValueByPath(
      fIPActivitiesParticipation,
      headers.fdp_name.path
    );
    const startDate = formatDate(
      getValueByPath(fIPActivitiesParticipation, headers.start_date.path)
    );
    const endDate = formatDate(
      getValueByPath(fIPActivitiesParticipation, headers.end_date.path)
    );
    const organizer = getValueByPath(
      fIPActivitiesParticipation,
      headers.organizer.path
    );
    const mode = getValueByPath(fIPActivitiesParticipation, headers.mode.path);
    const details = getValueByPath(
      fIPActivitiesParticipation,
      headers.details.path
    );

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{fdpName}</h3>
          <span className='flex items-center gap-1'>
            <FaUserTie className='text-yellow-500' />
            Organizer: {organizer}
          </span>

          {/* <span className="flex items-center gap-1">
            <FaFileLines className="text-yellow-500" />
            Details: {details}
          </span> */}

          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaRegComments className='text-yellow-500' />
              Mode: {mode}
            </span>

            <span className='flex items-center gap-1'>
              <FaCalendarDays className='text-yellow-500' />
              {startDate} - {endDate}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        {/* <SiHiveBlockchain /> */}
        FIP activities/Participation in FDP
      </h2>
      {fIPActivitiesParticipations.length ? (
        <div className=''>
          {fIPActivitiesParticipations.map(
            (fIPActivitiesParticipation, index) => (
              <div key={index} className=''>
                {renderFIPActivitiesParticipation(fIPActivitiesParticipation)}
              </div>
            )
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default FIPActivitiesParticipation;
