import moment from "moment";
import React from "react";
import QRCode from "react-qr-code";
import { encode as base64_encode } from "base-64";

export default function IDCardComponent({ staff: staff, componentRef, zoom }) {
  let idBase64 = base64_encode(staff?.id_card?.id);

  return (
    <div className={zoom ? "main-div-zoom" : "main-div"} ref={componentRef}>
      <div className='grid grid-cols-6 pt-1 pl-2 pr-2'>
        <div>
          <img
            style={{ filter: "saturate(200%)" }}
            src='https://erp.dibru.work/images/dibru.png'
            className='w-10 rounded-full'
            alt='Avatar'
          />
        </div>
        <div className='col-span-4'>
          <p className='text-xs font-bold text-center'>
            <img
              style={{ width: "100px" }}
              className='w-20 ml-auto mr-auto mt-o '
              src='/assets/logo-assamese.png'
            />
            Dibrugarh University
          </p>
          <p className='text-xs text-center ' style={{ fontSize: ".50rem" }}>
            Dibrugarh-786004, Assam, India
          </p>
        </div>
        <div>
          <QRCode
            value={
              "https://verify.dibru.ac.in" + `/staff-id-card/?id=${idBase64}`
            }
            className='float-right '
            level='L'
            size={45}
          />
        </div>
      </div>
      <div className='grid grid-cols-6' style={{ marginTop: "-8px" }}>
        <div className='col-span-2 text-center'>
          <span className='pl-2 font-bold text-center text-xxs'></span>
        </div>
        <div className='col-span-2'>
          <div className='text-center'>
            <span
              className='font-bold text-center text-xxs'
              style={{ border: "1px solid", padding: "1px 4px 1px 4px" }}
            >
              Identity Card
            </span>
          </div>
        </div>
        <div className='col-span-2 pr-2 text-right'>
          <span className='font-bold text-center text-xxs'>
            No.: {staff?.id_card?.sl_no}
          </span>
        </div>
      </div>
      <div className='hidden image background'>
        <img
          src='https://erp.dibru.work/du_blur_image.png'
          style={{
            height: "1in",
            width: "1in",
            margin: "0 auto",

            display: "block",
            opacity: "0.3",
          }}
        />
      </div>
      {(staff.id_card == null || staff.id_card.status == 0) && (
        <div className='-rotate-45  background'>
          <p
            style={{
              height: "1in",
              width: "1in",
              margin: "0 auto",
              padding: "20px",
              display: "block",
              opacity: "0.9",
            }}
          >
            Preview
          </p>
        </div>
      )}
      <div
        className='grid grid-cols-6 pt-1 pl-2 pr-2'
        style={({ color: "#000 !important" }, { fontWeight: "700" })}
      >
        <div className='col-span-5'>
          <div className='grid grid-cols-9 text-xxs '>
            <div className='col-span-3'>Employee ID: </div>
            <div className='col-span-6'>{staff.unique_id}</div>
            <div className='col-span-3'>Name: </div>
            <div className='col-span-6 capitalize'>
              {staff?.full_name?.toLowerCase()}
            </div>
            <div className='col-span-3'>Father&apos;s Name: </div>
            <div className='col-span-6 capitalize'>
              {staff?.fathers_name?.toLowerCase()}
            </div>

            <div className='col-span-3'>Designation: </div>
            <div className='col-span-6'>{staff?.designation?.name}</div>

            <div className='col-span-3'>Blood Group: </div>
            <div className='col-span-2'>{staff?.blood_group}</div>
            <div className='col-span-2'>D.O.B.: </div>
            <div className='col-span-2'>
              {moment(staff?.dob).format("DD-MM-YYYY")}
            </div>

            <div className='col-span-3'>Address: </div>
            <div className='col-span-6 capitalize'>
              {staff?.address?.toLowerCase()}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-end justify-between'>
          <img
            src={staff?.profile_pic}
            className='float-right w-10'
            alt='Avatar'
          />
        </div>
      </div>
      <div
        className='grid items-end grid-cols-3 gap-4 px-2 text-center'
        style={{
          position: "absolute",
          bottom: "15px",
          margin: " 0 auto",
          width: "3.3in",
        }}
      >
        <img
          className='h-auto mt-o justify-self-start '
          src={staff?.signature_file}
        />
        <div></div>
        <img
          style={{ filter: "brightness(0%)", width: "50px" }}
          className=' mt-o justify-self-end'
          src='/assets/joint-reg-sign.png'
        />
      </div>
      <div
        className='px-2 text-center'
        style={{
          position: "absolute",
          bottom: "1px",
          margin: " 0 auto",
          width: "3.3in",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          className='text-xxs'
          style={({ color: "#000 !important" }, { "font-weight": "700" })}
        >
          {" "}
          Card Holder&apos;s Signature
        </p>
        <p
          className='text-xxs'
          style={({ color: "#000 !important" }, { "font-weight": "700" })}
        >
          Issued On:{" "}
          {staff.id_card?.created_at
            ? moment(staff.id_card?.created_at).format("DD-MM-YYYY")
            : " "}{" "}
        </p>

        <p
          className='text-xxs'
          style={({ color: "#000 !important" }, { "font-weight": "700" })}
        >
          {" "}
          {staff.id_card?.created_at &&
          moment(staff.id_card?.created_at).isBefore("2023-09-28")
            ? "Joint Registrar (Admin)"
            : "Registrar"}
        </p>
      </div>
    </div>
  );
}
