import React from "react";
import moment from "moment";

export default function LeaveApplyPreview({
  leaveTypes,
  staff,
  allStaff,
  data,
  setShowPreview,
  loading,
}) {
  console.log(data);
  console.log(setShowPreview);
  return (
    <>
      <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative max-w-4xl mx-auto my-6 lg:w-3/6'>
          {/*content*/}
          <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
              <h3 className='text-3xl font-semibold'>Preview</h3>
              <button className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none'>
                <span className='block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none'>
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className='relative flex-auto p-4'>
              <div className='my-1 text-sm text-center '>
                <table className='w-full text-left table-auto'>
                  <tr>
                    <th className='table-head'>Leave Type:</th>
                    <td className='table-data'>
                      {
                        leaveTypes.find((lt) => lt.id == data.leave_type_id)
                          ?.name
                      }{" "}
                    </td>
                  </tr>
                  <tr>
                    <th className='table-head'>From:</th>
                    <td className='table-data'>
                      {moment(data.from_date).format("DD-MM-YYYY")}{" "}
                      {data.leave_type_id == 3 && (
                        <>{data.sl_from_time ? data.sl_from_time : ""}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className='table-head'>To:</th>
                    <td className='table-data'>
                      {moment(data.to_date).format("DD-MM-YYYY")}{" "}
                      {data.leave_type_id == 3 && (
                        <>{data.sl_to_time ? data.sl_to_time : ""}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className='table-head'>No. of Days:</th>
                    <td className='table-data'>{data.total_days}</td>
                  </tr>
                  <tr>
                    <th className='table-head'>Reason/Remarks:</th>
                    <td className='table-data'>{data.reason} </td>
                  </tr>
                  <tr>
                    <th className='table-head'>Proposed In-charge:</th>
                    <td className='table-data'>
                      {data.proposed_incharge?.map((incharge) => (
                        <>
                          {allStaff.find((s) => s.id == incharge.incharge_id)
                            ?.full_name
                            ? allStaff.find((s) => s.id == incharge.incharge_id)
                                ?.full_name +
                              (allStaff.find(
                                (s) => s.id == incharge.incharge_id
                              )?.designation?.name != undefined
                                ? "[" +
                                  allStaff.find(
                                    (s) => s.id == incharge.incharge_id
                                  )?.designation?.name +
                                  "]"
                                : "")
                            : "Not Selected"}
                          -{incharge.pi_remarks} <br />
                        </>
                      ))}
                    </td>
                  </tr>
                  {data.leave_type_id != 3 && (
                    <>
                      <tr>
                        <th className='table-head'>
                          Arrangement of Classes/Lab:
                        </th>
                        <td className='table-data'>{data.arrangement}</td>
                      </tr>

                      <tr>
                        <th className='table-head'>Have Station Leave?:</th>
                        <td className='table-data'>
                          {data.station_leave == "true" ? "Yes" : "No"}
                          {data.station_leave == "true" && (
                            <>
                              {" "}
                              <hr className='my-1' />
                              <b> From:</b>{" "}
                              {moment(data.sl_from_date).format("DD-MM-YYYY")}{" "}
                              {data.sl_from_time} <br />
                              <b> To: </b>
                              {moment(data.sl_to_date).format(
                                "DD-MM-YYYY"
                              )}{" "}
                              {data.sl_to_time}
                            </>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <th className='table-head'>Address during Absence:</th>
                    <td className='table-data'>{data.address}</td>
                  </tr>
                  <tr>
                    <th className='table-head'>Supporting Document:</th>
                    <td className='table-data'>
                      {data.file ? "Selected" : "Not Selected"}
                    </td>
                  </tr>
                  <tr>
                    <th className='table-head'>Forwarding Authority:</th>
                    <td className='table-data'>
                      {staff.forwarding_authority?.full_name} -{" "}
                      {staff.forwarding_authority?.designation?.name} -{" "}
                      {staff.forwarding_authority?.department?.name}
                      <br />
                      {staff.forwarding_authority2?.full_name} -{" "}
                      {staff.forwarding_authority2?.designation?.name} -{" "}
                      {staff.forwarding_authority2?.department?.name}
                    </td>
                  </tr>
                  <tr>
                    <th className='table-head'>Approving Authority:</th>
                    <td className='table-data'>
                      {staff.approving_authority?.full_name}{" "}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            {/*footer*/}
            <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
              <button
                className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                type='button'
                onClick={() => setShowPreview(false)}
              >
                Close
              </button>
              <button
                disabled={loading}
                className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none'
                type='submit'
              >
                {loading ? "Saving..." : "Apply"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
    </>
  );
}
