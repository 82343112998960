import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import BaseApi from "../../Http/BaseApi";

const RecipientsModal = ({ isOpen, closeModal, noticeId }) => {
  const [recipients, setRecipients] = useState([]);

  // Fetch recipients when the modal opens
  useEffect(() => {
    if (noticeId && isOpen) {
      fetchRecipients();
    }
  }, [noticeId, isOpen]);

  const fetchRecipients = async () => {
    try {
      const response = await BaseApi().get(`/notices/${noticeId}/recipients`);
      setRecipients(response.data);
    } catch (error) {
      console.error("Error fetching recipients:", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} className='relative z-50'>
      <div
        className='fixed inset-0 bg-black bg-opacity-30'
        aria-hidden='true'
      />
      <div className='fixed inset-0 flex items-center justify-center p-4'>
        <Dialog.Panel className='w-full p-6 bg-white rounded-lg shadow-md max-w-8xl'>
          <Dialog.Title className='text-lg font-semibold text-gray-700'>
            Recipients List:
          </Dialog.Title>

          <div className='mt-4'>
            {recipients.length > 0 ? (
              <div className='grid grid-cols-6 gap-4 p-3 rounded-md bg-gray-50'>
                {/* Recipients Data */}
                {recipients.map((recipient, index) => (
                  <div
                    key={recipient.id}
                    className='flex flex-col items-start justify-between p-2 bg-gray-100 rounded-md'
                  >
                    <span>{recipient?.staff?.name}</span>
                    <span className='text-sm text-gray-500'>
                      {recipient?.staff?.designation?.name}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <p className='text-sm text-gray-500'>No recipients found.</p>
            )}
          </div>

          <div className='mt-4 text-right'>
            <button
              onClick={closeModal}
              className='px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600'
            >
              Close
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default RecipientsModal;
