import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
import { Form, Formik } from "formik";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { useLocation } from "react-router-dom";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function OfferedSubjects() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [students, setStudents] = useState({});
  const [subjectmasters, setSubjectmasters] = useState({});

  const location = useLocation(); // Get the current location object

  useEffect(() => {
    // Parse the query parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    const subjectmasterIdParam = searchParams.get("subjectmaster_id");

    // Check if the query parameter exists and is not null
    if (subjectmasterIdParam) {
      // Call handleSearch with the subjectmaster_id query parameter
      handleSearch({ subjectmaster_id: subjectmasterIdParam });
    } else {
      // If no query parameter found, load subjectmasters
      // getSubjectMasters();
    }
  }, [location.search]);
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;
  const initialSearchValues = {
    subjectmaster_id: "",
  };

  const validationSchema = Yup.object().shape({
    subjectmaster_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  useEffect(() => {
    getSubjectMasters();

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);
  const getSubjectMasters = async () => {
    const _subjectmasters = await BaseApi().get(`subject-masters`);

    console.log(_subjectmasters.data);
    setSubjectmasters(_subjectmasters.data);
  };
  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callStudents(searchQuery);
    setLoading(false);
  };

  async function callStudents(searchQuery) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`subject-masters/students`, {
        params: searchQuery,
      })
      .then((data) => {
        setStudents(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full min-h-screen px-5 overflow-x-scroll '>
            <PageTitle name='Subject/Course wise Student List' />

            {subjectmasters.length > 0 && (
              <Formik
                innerRef={formikRef}
                initialValues={initialSearchValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className='grid gap-2 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <CustomSelect
                      options={subjectmasters}
                      name='subjectmaster_id'
                      label='Select Subject/Course'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) =>
                        `${option.name} - (${option.cbcs_type?.name}) `
                      }
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "subjectmaster_id",
                          selectedOption.id
                        );
                      }}
                    />

                    <div className='p-5 mt-1 text-left '>
                      <button
                        type='submit'
                        className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && <span>Searching...</span>}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Subject/Course Name</th>
                  <th className='table-head'>Type</th>
                  <th className='table-head'>Offered By</th>
                  <th className='table-head'>Students Name</th>

                  <th className='table-head'>Enrollment ID</th>

                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Department</th>
                </tr>
              </thead>
              <tbody>
                {students?.length > 0 &&
                  students.map((student) => {
                    return (
                      <>
                        <tr>
                          <td className='table-data'>{++sl}</td>
                          <td className='table-data'>
                            {student.subjectmaster?.name}
                          </td>
                          <td className='table-data'>
                            {student.cbcs_type?.name}
                          </td>
                          <td className='table-data'>
                            {student.subjectmaster?.department?.name}
                          </td>

                          <td className='table-data'>
                            {student.programme_student?.student?.name}
                            <br />
                            <span className='text-red-600'>
                              {student.programme_student?.alumni == 1 &&
                                "Alumni"}
                              <br />
                              {student.programme_student?.discontinued == 1 &&
                                "Discontinued"}
                            </span>
                          </td>
                          <td className='table-data'>
                            <span
                              className='text-blue-500 cursor-pointer'
                              onClick={() =>
                                window.open(
                                  `/admitted-students/${student.programme_student?.id}`,
                                  "_blank"
                                )
                              }
                            >
                              {student.programme_student?.unique_id ||
                                student.programme_student?.unique_idd}
                            </span>
                          </td>
                          <td className='table-data'>
                            {student.programme_student?.programme?.name}
                          </td>
                          <td className='table-data'>
                            {student.programme_student?.department?.name}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}
            {students?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
