import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import FormPage from "./FormPage";
import Modal from "../../../utils/Modal";
import TableComponent from "../../../utils/TableComponent";
import { toast } from "react-toastify";

const BooksEdited = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [booksEdits, setBooksEdits] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    publisher: "",
    month: "",
    year: "",
    editor_names: "",
    isbn: "",
    book_type: "",
    book_type_others: "",
    indexed_in: "",
  });
  const headers = {
    title: {
      label: "Title of the Book",
      path: "title",
    },
    publisher: {
      label: "Publisher",
      path: "publisher",
    },
    month: {
      label: "Month of Publish",
      path: "monthName",
    },
    year: {
      label: "Year of Publish",
      path: "year",
    },
    editor_names: {
      label: "Editor Names: (As per Book)",
      path: "editor_names",
    },
    isbn: {
      label: "ISBN",
      path: "isbn",
    },
    book_type: {
      label: "Book Type",
      path: "book_type",
    },
    book_type_others: {
      label: "Book Type Others",
      path: "book_type_others",
    },
    indexed_in: {
      label: "Indexed In",
      path: "indexed_in",
    },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    publisher: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    // year: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    editor_names: Yup.string().required("This field is required!"),
    isbn: Yup.string().required("This field is required!"),
    book_type: Yup.string().required("This field is required!"),
    book_type_others: Yup.string().when("book_type", {
      is: "Others",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    // indexed_in: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/edited-books");
      setBooksEdits(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/edited-books/${editId}`, values);
        toast.success(`Book Chapters Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/edited-books", values);
        toast.success(`Book Chapters Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = booksEdits.find((BooksEdit) => BooksEdit.id === id);
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        publisher: editableData.publisher,
        month: editableData.month,
        year: editableData.year,
        editor_names: editableData.editor_names,
        isbn: editableData.isbn,
        book_type: editableData.book_type,
        book_type_others: editableData.book_type_others,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      publisher: "",
      month: "",
      year: "",
      editor_names: "",
      isbn: "",
      book_type: "",
      book_type_others: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      publisher: "",
      month: "",
      year: "",
      editor_names: "",
      isbn: "",
      book_type: "",
      book_type_others: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/edited-books/${id}`);
      toast.success(`Book Chapters Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Books Edited :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={booksEdits}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default BooksEdited;
