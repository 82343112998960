import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaBuilding, FaGraduationCap, FaIdCard } from "react-icons/fa6";

const headers = {
  qualification_master_id: {
    label: "Qualification Type",
    path: "qualification_master.name",
  },
  qualification: {
    label: "Qualification",
    path: "qualification",
  },
  institute: { label: "Institute", path: "institute" },
  subject: { label: "Subject", path: "subject" },
  pass_year: { label: "Pass Year", path: "pass_year" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const EduQualification = ({ view }) => {
  const [qualifications, setQualifications] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/educational-qualifications"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      setQualifications(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderQualification = (qualification) => {
    const qualificationType = getValueByPath(
      qualification,
      headers.qualification_master_id.path
    );
    const qualificationName = getValueByPath(
      qualification,
      headers.qualification.path
    );
    const institute = getValueByPath(qualification, headers.institute.path);
    const subject = getValueByPath(qualification, headers.subject.path);
    // const passYear = getValueByPath(qualification, headers.pass_year.path);

    return (
      <div className="flex items-center justify-start w-full py-2 border-b">
        {/* <p className="w-1/4 font-normal text-gray-600">
          <span className="px-3 py-1 text-sm font-medium text-gray-500 bg-blue-200 rounded-full">
            {passYear}
          </span>
        </p> */}
        <p className="flex flex-col w-3/4">
          <span className="text-base font-medium text-gray-600 flex items-center gap-2">
            <FaGraduationCap className="text-yellow-500" />
            {qualificationName} in {subject}
          </span>
          <span className="text-sm font-normal text-gray-500 flex items-center gap-2">
            <FaBuilding className="text-yellow-500" />
            {institute}
            {/* {qualificationType} */}
          </span>
        </p>
      </div>
    );
  };

  return (
    <div>
      <h2 className="flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        <FaGraduationCap />
        Educational Qualifications
      </h2>
      {qualifications.length ? (
        <div className="space-y-4">
          {qualifications.map((qualification, index) => (
            <div key={index} className="flex items-center px-2">
              {renderQualification(qualification)}
            </div>
          ))}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default EduQualification;
