// import { useSelector } from "react-redux";
import store from "../Redux/Store";

// const user = {
//   permissions: ["create user", "delete user"],
// };

function can(permission) {
  //   const authData = useSelector((state) => state.auth);
  const authData = store.getState().auth;

  return (authData.user?.permissions || []).find((p) => p.name == permission)
    ? true
    : false;
}
export default can;
