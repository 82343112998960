import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";

import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
import moment from "moment";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function HostelerDepartmentView() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [hostelers, setHostelers] = useState();
  const [searchInput, setSearchInput] = useState("");
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();

  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;

  useEffect(() => {
    handleSearch();

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callHostelers(searchQuery);
    setLoading(false);
  };

  async function callHostelers(searchQuery) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`hostelers`, {
        params: searchQuery,
      })
      .then((data) => {
        setHostelers(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='List of Hostel Boarders' />

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}
            {hostelers?.length > 0 && (
              <div>
                <div>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='inline-block mt-5 text-gray-700 form-label'
                  >
                    Search By Name
                  </label>
                  <input
                    className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                    placeholder='Type here...'
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </div>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Name</th>

                  <th className='table-head'>Enrollment ID</th>
                  <th className='table-head'>Hostel </th>

                  <th className='table-head'>In Date</th>
                  <th className='table-head'>Out Date</th>
                  <th className='table-head'>Room No.</th>
                  <th className='table-head'>Bed No.</th>
                  <th className='table-head'>Guardian Contact No.</th>
                </tr>
              </thead>
              <tbody>
                {hostelers?.length > 0 &&
                  hostelers
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((hosteler) => {
                      return (
                        <>
                          <tr
                            className={
                              (hosteler.room_no == null ||
                                hosteler.in_date == null) &&
                              "bg-red-100"
                            }
                          >
                            <td className='table-data'>{++sl}</td>

                            <td className='table-data'>
                              {hosteler.student?.name}
                            </td>
                            <td className='table-data'>
                              {hosteler.student?.programmes
                                .filter((p) => p.pivot.active == 1)
                                .map((prog) => {
                                  return (
                                    <span
                                      key={prog.pivot.id}
                                      className='text-blue-500 cursor-pointer'
                                      onClick={() =>
                                        window.open(
                                          `/admitted-students/${prog.pivot.id}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {
                                        <>
                                          {prog.pivot.unique_idd ||
                                            prog.pivot.unique_id}
                                        </>
                                      }

                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "15px",
                                          marginLeft: "1px",
                                        }}
                                      >
                                        {prog.pivot.discontinued == 1 &&
                                          "Programme Discontinued"}
                                        {prog.pivot.alumni == 1 && "Alumni"}
                                      </span>
                                      <br />
                                    </span>
                                  );
                                })}
                            </td>

                            <td className='table-data'>
                              {hosteler.hostel?.name}
                            </td>
                            <td className='table-data'>
                              {hosteler.in_date &&
                                moment(hosteler.in_date).format("Do MMM YYYY")}
                            </td>
                            <td className='table-data'>
                              {hosteler.out_date &&
                                moment(hosteler.out_date).format("Do MMM YYYY")}
                            </td>
                            <td className='table-data'>{hosteler.room_no}</td>
                            <td className='table-data'>{hosteler.bed_no}</td>

                            <td className='table-data'>
                              {hosteler.guardian_contact_no}
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}

            {hostelers?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
