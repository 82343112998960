import { MenuIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import can from "../../Middleware/Permission";

// import { useSelector } from "react-redux";
import SideBarLink from "./SideBarLink";
import { Link, useLocation } from "react-router-dom";
function SideBar() {
  // const authData = useSelector((state) => state.auth);
  const authData = useSelector((state) => state.auth);
  const [searchInput, setSearchInput] = useState("");
  const location = useLocation(); // Get the current location

  const partnerMenu = [
    { name: "Dashboard", link: "/dashboard", icon: "DesktopComputerIcon" },
    // { name: "List of Students", link: "/old-students" },
    // { name: "Verified Students", link: "/admitted-students" },
  ];
  if (authData.panel == "department") {
    // partnerMenu.push({
    //   name: "Registered Students",
    //   link: "/old-students",
    //   icon: "UsersIcon",
    // });
    partnerMenu.push({
      name: "Verified Students",
      link: "/admitted-students",
      icon: "CheckCircleIcon",
    });
    partnerMenu.push({
      name: "View Hostel Boarders",
      link: "/hostelers",
      icon: "UserGroupIcon",
    });
    partnerMenu.push({
      name: "Enrolled Ph. D. Scholars ((Not Registered)",
      link: "/phd-students-records",
      icon: "ClipboardListIcon",
    });
    partnerMenu.push({
      name: "Registered Ph. D. Scholars Record",
      link: "/registered-phd-students-records",
      icon: "ClipboardListIcon",
    });
    partnerMenu.push({
      name: "List of Ph. D. Awardees",
      link: "/awarded-phd-students-records",
      icon: "AcademicCapIcon",
    });
    if (
      authData.user.email == "csl@dibru.ac.in" ||
      authData.user.email == "dhist@dibru.ac.in"
    ) {
      partnerMenu.push({
        name: "Promote Students",
        link: "/promote-student",
        icon: "CheckCircleIcon",
      });
    }

    partnerMenu.push({
      name: "Subject/Course wise Students",
      link: "/subjects",
      icon: "CheckCircleIcon",
    });

    // partnerMenu.push({
    //   name: "Student Attendance",
    //   link: "/student-attendance",
    //   icon: "CalendarIcon",
    // });

    partnerMenu.push({
      name: "Ex Students",
      link: "/ex-students",
      icon: "XIcon",
    });
  }

  if (
    authData.panel == "department" &&
    (authData.user.id == 25 || //Centre for Computer Science and Applications
      authData.user.id == 9 || //Centre for Tea and Agro Studies
      authData.user.id == 32 || //Centre for Social Work Studies
      authData.user.id == 12 || //Department of Petroleum Technology
      authData.user.id == 16 || //Centre for Studies in Physical Education and Sports
      authData.user.id == 20 || //Dr. Bhupen Hazarika Centre for studies in Performing Arts
      authData.user.id == 14 || //Centre for Library and Information Science Studies
      authData.user.id == 29 || //Department of Pharmaceutical Sciences
      authData.user.id == 8 || //Centre for Management Studies
      authData.user.id == 30 || //M. Sc. in Physics
      authData.user.id == 34 || // women studies
      authData.user.id == 6 || // M.Sc. in Biotechnology and Bioinformatics
      authData.user.id == 7 || //M. Sc. in Life Sciences (Botany/Zoology)
      authData.user.id == 10 || //M.Com. in Finance/Marketing
      authData.user.id == 11 || //M. Sc. Tech. in Applied Geophysics
      authData.user.id == 13 || //M.A/M.Sc. in Geography
      authData.user.id == 36 || //M.A. in History
      authData.user.id == 38 || //M. A. in Sociology
      authData.user.id == 37 || //M. A. in Political Science
      authData.user.id == 35 || //M.A. in Economics
      authData.user.id == 33 || //M.A. in Mass Communication
      authData.user.id == 31 || //M.A./M. Sc. in Statistics
      authData.user.id == 28 || //M.A./ M.Sc. in Mathematics
      authData.user.id == 27 || //M.Sc. in Chemistry
      authData.user.id == 26 || //M.A./ M.Sc. in Anthropology
      authData.user.id == 23 || //M.A. in English
      authData.user.id == 22 || //M.A. in Assamese
      authData.user.id == 21 || //M.A. in Philosophy
      authData.user.id == 19 || //M.A. in Bodo
      authData.user.id == 17 || //M. Ed. M.A. in Education
      authData.user.id == 15 || //M.A. in Applied Psychology
      authData.user.id == 13 || //M.A/M.Sc. in Geography
      authData.user.id == 11 || //M. Sc. Tech in Applied Geophysics M.Sc. In Applied Geology
      authData.user.id == 18) //Centre for Juridical Studies
  ) {
    partnerMenu.push({
      name: "List of Applicants",
      link: "/applicants",
      icon: "UserGroupIcon",
    });
  }

  if (can("Apply Leave")) {
    partnerMenu.push({
      name: "Apply For Leaves",
      link: "/leaves/create",
      icon: "CheckCircleIcon",
    });
    partnerMenu.push({
      name: "View My Applied Leaves",
      link: "/leaves",
      icon: "CalendarIcon",
    });
    partnerMenu.push({
      name: "Forward/Approve Leaves",
      link: "/leave/approvals",
      icon: "PaperAirplaneIcon",
    });
    partnerMenu.push({
      name: "Leaves Forwarded/Approved by me",
      link: "/leave/resolved-approvals",
      icon: "CheckIcon",
    });

    if (
      authData.user.email === "vc@dibru.ac.in" ||
      authData.user.email === "registrar@dibru.ac.in"
    ) {
      partnerMenu.push({
        name: "View All Leave Applications",
        link: "/leave-report",
        icon: "CalendarIcon",
      });

      partnerMenu.push({
        name: "List of Teacher and Officers",

        link: "/staff-list",
        icon: "UserIcon",
      });
    }

    partnerMenu.push({
      name: "Report a Problem",
      link: "/report-problem",
      icon: "ExclamationCircleIcon",
    });
  }

  if (
    authData.user.email == "establishmenta@dibru.ac.in" ||
    authData.user.email == "establishmentb@dibru.ac.in"
  ) {
    partnerMenu.push({
      name: "Forward/Approve Leaves",
      link: "/leave/approvals",
      icon: "PaperAirplaneIcon",
    });
    partnerMenu.push({
      name: "Leaves Forwarded/Approved by me",
      link: "/leave/resolved-approvals",
      icon: "CheckIcon",
    });

    partnerMenu.push({
      name: "View All Leave Applications",
      link: "/leave-report",
      icon: "CalendarIcon",
    });
  }
  if (can("View Students")) {
    partnerMenu.push({
      name: "Verified Students",
      link: "/admitted-students",
      icon: "CheckCircleIcon",
    });
  }
  if (can("Manage PHD Scholar") || can("View PHD Scholar")) {
    partnerMenu.push({
      name: "Enrolled Ph. D. Scholars (Not Registered)",
      link: "/phd-students-records",
      icon: "ClipboardListIcon",
    });
    partnerMenu.push({
      name: "Registered Ph. D. Scholars Record",
      link: "/registered-phd-students-records",
      icon: "ClipboardListIcon",
    });
    partnerMenu.push({
      name: "List of Ph. D. Awardees",
      link: "/awarded-phd-students-records",
      icon: "AcademicCapIcon",
    });
  }
  if (can("Manage PHD Scholar")) {
    partnerMenu.push({
      name: "Ph. D. Scholar Monthly Fee Record",
      link: "/phd-monthly-fee",
      icon: "CalendarIcon",
    });
  }
  if (can("Manage Fellowship") || can("View PHD Scholar")) {
    partnerMenu.push({
      name: "Fellowship Holders",
      link: "/fellowship-holders",
      icon: "AcademicCapIcon",
    });
  }

  if (can("View Applicants")) {
    partnerMenu.push({
      name: "List of Applicants",
      link: "/applicants",
      icon: "UserGroupIcon",
    });
    partnerMenu.push({
      name: "Applicants Count",
      link: "/applicant-counts",
      icon: "HashtagIcon",
    });
  }

  if (can("View DUPGET Count")) {
    partnerMenu.push({
      name: "Applicants Count (DUPGET)",
      link: "/applicant-counts/dupget",
      icon: "HashtagIcon",
    });
  }
  if (can("View BEDCET Count")) {
    partnerMenu.push({
      name: "Applicants Count (B.Ed. CET)",
      link: "/applicant-counts/bedcet",
      icon: "HashtagIcon",
    });

    partnerMenu.push({
      name: "Applicants Count (DURAT)",
      link: "/applicant-counts/durat",
      icon: "HashtagIcon",
    });
  }

  if (can("View Attendance Sheet")) {
    partnerMenu.push({
      name: "Attendance Sheet",
      link: "/attendance-sheet",
      icon: "ClipboardListIcon",
    });
  }

  if (can("Make Admission")) {
    partnerMenu.push({
      name: "Applicant Search",
      link: "/applicant-search",
      icon: "ClipboardListIcon",
    });
    partnerMenu.push({
      name: "Successful Payment List",
      link: "/payments",
      icon: "ClipboardListIcon",
    });
    partnerMenu.push({
      name: "Pending Payments",
      link: "/payments-pending",
      icon: "ClipboardListIcon",
    });
  }

  if (can("View Hostelers")) {
    partnerMenu.push({
      name: "View Hostel Boarders",
      link: "/hostelers",
      icon: "UserGroupIcon",
    });
    partnerMenu.push({
      name: "Mess Dues Records",
      link: "/hostel-mess-fees",
      icon: "CurrencyRupeeIcon",
    });
    partnerMenu.push({
      name: "Ex Hostel Boarders",
      link: "/ex-hostelers",
      icon: "UserGroupIcon",
    });

    partnerMenu.push({
      name: "Seat Not Taken List ",
      link: "/not-reported-hostelers",
      icon: "UserGroupIcon",
    });
  }
  if (can("Hostel Transfer")) {
    partnerMenu.push({
      name: "Hostel Transfer",
      link: "/hostel-transfer",
      icon: "UserGroupIcon",
    });
  }

  if (can("Create Staff")) {
    partnerMenu.push({
      name: "Create ERP Users",
      link: "/staff/create",
      icon: "UserIcon",
    });
  }

  if (can("View Staff")) {
    partnerMenu.push({
      name:
        authData.user.email === "establishmenta@dibru.ac.in"
          ? "List of Teacher and Officers"
          : authData.user.email === "establishmentb@dibru.ac.in"
          ? "List of Employees (Grade III & IV)"
          : "List of ERP Users",
      link: "/staff",
      icon: "UserIcon",
    });
    // if (authData.user.email === "establishmentb@dibru.ac.in") {
    //   partnerMenu.push({
    //     name: "List of Employees (Grade IV)",

    //     link: "/staff",
    //     icon: "UserIcon",
    //   });
    // }
  }

  if (can("Manage Permission")) {
    partnerMenu.push({
      name: "User Permission",
      link: "/staff/permission",
      icon: "UserIcon",
    });
  }
  if (can("View Deleted Payments")) {
    partnerMenu.push({
      name: "Deleted Payments",
      link: "/payments-deleted",
      icon: "TrashIcon",
    });
  }

  if (can("Account Masters")) {
    partnerMenu.push({
      name: "Account Masters",
      link: "/account-masters",
      icon: "CogIcon",
    });
  }
  if (can("Manage ID Card")) {
    partnerMenu.push({
      name: "ID Card Validity Master",
      link: "/id-card-masters",
      icon: "CalendarIcon",
    });
  }
  if (can("Fee Collection") && authData.panel == "staff") {
    partnerMenu.push({
      name: "Renewal Fee Collection",
      link: "/fee-collection",
      icon: "CurrencyRupeeIcon",
    });
  }
  if (can("Fee Collection") && authData.panel == "department") {
    partnerMenu.push({
      name: "Renewal Fee Collection",
      link: "/department-fee-collection",
      icon: "CurrencyRupeeIcon",
    });

    partnerMenu.push({
      name: "Successful Payment List",
      link: "/payments",
      icon: "ClipboardListIcon",
    });
    partnerMenu.push({
      name: "Pending Payments",
      link: "/payments-pending",
      icon: "ClipboardListIcon",
    });
  }
  if (can("View Ex Students")) {
    partnerMenu.push({
      name: "Ex Students",
      link: "/ex-students",
      icon: "XIcon",
    });
  }

  if (can("Manage Reports")) {
    partnerMenu.push({
      name: "All Admitted Students",
      link: "/admitted-student-report",
      icon: "UserGroupIcon",
    });
  }

  if (can("Submit Budget")) {
    partnerMenu.push({
      name: "Budget Receipt Entry",
      link: "/budget-proposal-entry/receipt",
      icon: "CurrencyRupeeIcon",
    });
    partnerMenu.push({
      name: "Budget Payment Entry",
      link: "/budget-proposal-entry/payment",
      icon: "CurrencyRupeeIcon",
    });
    partnerMenu.push({
      name: "Budget Final Submit",
      link: "/budget-proposal-submit",
      icon: "CurrencyRupeeIcon",
    });
  }

  if (authData.panel != "department" && can("Fee Collection")) {
    partnerMenu.push({
      name: "Fee Collection By Hostel",
      link: "/hostel-fee-collection",
      icon: "CurrencyRupeeIcon",
    });
  }

  if (can("Voucher Entry")) {
    // partnerMenu.push({
    //   name: "Voucher Entry Portal",
    //   link: "/voucher-portal",
    //   icon: "CurrencyRupeeIcon",
    // });
  }

  if (can("Manage Courses")) {
    partnerMenu.push({
      name: "Subject/Course wise Students",
      link: "/subjects",
      icon: "CheckCircleIcon",
    });
    partnerMenu.push({
      name: "Subject/Course Apply Status",
      link: "/subjects/status",
      icon: "HashtagIcon",
    });
  }

  if (authData.user.email == "admission@dibru.ac.in") {
    partnerMenu.push({
      name: "Masters",
      link: "/masters",
      icon: "CogIcon",
    });
  }

  if (authData.panel == "staff" && [1, 2, 3].includes(authData.user.type)) {
    partnerMenu.push({
      name: "Download Pay Slips",
      link: "/pay-slips",
      icon: "DownloadIcon",
    });
  }

  if (authData.user.email == "iqac@dibru.ac.in") {
    partnerMenu.push({
      name: "Faculty DCS",
      link: "/faculty-profile-iqac",
      icon: "UserIcon",
    });
  }

  if (authData.user.email == "demouser@dibru.ac.in") {
    partnerMenu.push({
      name: "Faculty DCS",
      link: "/faculty-profile",
      icon: "UserIcon",
    });
  }

  if (can("File Tracking")) {
    partnerMenu.push({
      name: "File Tracking",
      link: "/file-tracking",
      icon: "DocumentIcon",
    });
  }

  if (authData.panel == "staff") {
    partnerMenu.push({
      name: "Create Notice/Circular/Meetings",
      link: "/notice-circulars-create",
      icon: "CalendarIcon",
    });
    partnerMenu.push({
      name: "View Notice/Circular/Meetings",
      link: "/notice-circulars",
      icon: "CalendarIcon",
    });
  }

  // if (authData.panel == "staff") {

  // }
  // partnerMenu.push({
  //   name: "View Profile",
  //   link: "/view-profile",
  //   icon: "EyeIcon",
  // });

  // partnerMenu.push({
  //   name: "Profile Website View",
  //   link: "/faculty-profile-website",
  //   icon: "GlobeIcon",
  // });

  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsMobile(false);
      setIsOpen(true);
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebarUrls = [
    "/view-profile",
    "/faculty-profile",
    "/faculty-profile-iqac",
    "/list-of-files",
    "/file-tracking",
    "/file-create",
    "/notice-circulars-create",
    "/notice-circulars",
  ];

  useEffect(() => {
    const dynamicPaths = ["/file-details"]; // Paths with dynamic segments

    const isDynamicPath = dynamicPaths.some((path) =>
      location.pathname.startsWith(path)
    );

    console.log(location.pathname);

    if (closeSidebarUrls.includes(location.pathname) || isDynamicPath) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [location.pathname]);

  return (
    <div>
      <button
        className='absolute p-5 text-gray-900 bg-transparent z-1 '
        onClick={toggleMenu}
      >
        <MenuIcon className='w-6 h-6' />
      </button>
      <div
        className={`${isOpen ? "lg:flex lg:flex-col flex-grow" : "hidden"}  `}
      >
        <aside className='sidebar z-10  h-screen  shadow-md p-2 w-[300px] overflow-y-auto text-left bg-white   '>
          <div className='text-xl text-gray-900'>
            <div className='flex items-center p-1 mt-1'>
              <button
                className='p-1 text-gray-900 bg-transparent lg:relative '
                onClick={toggleMenu}
              >
                <MenuIcon className='w-6 h-6' />
              </button>

              <Link
                to='/dashboard'
                className=' cursor-pointer font-bold text-gray-900 text-[15px] ml-3'
              >
                Dibrugarh University ERP
              </Link>
            </div>
            <div className='my-3 bg-gray-600 h-[1px]'></div>
          </div>

          <>
            <input
              className='
                  block
                 w-full
                   px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
              placeholder='Search...'
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </>

          <ul className='relative px-1'>
            <li
              className='relative'
              onClick={() => {
                if (isMobile) setIsOpen(false);
              }}
            >
              {partnerMenu.length > 0 &&
                partnerMenu
                  .filter((menu) => {
                    if (searchInput.length > 0) {
                      return (
                        menu.name
                          .toString()
                          .toLowerCase()
                          .indexOf(searchInput.toLowerCase()) > -1
                      );
                    } else {
                      return true;
                    }
                  })
                  .map((item, index) => (
                    <SideBarLink
                      key={index}
                      to={item.link}
                      icon={item.icon}
                      label={item.name}
                    />
                  ))}
            </li>
          </ul>
        </aside>
      </div>
    </div>
  );
}

export default SideBar;
