/* This example requires Tailwind CSS v2.0+ */
import React, { useCallback, useEffect, useState } from "react";
import { Fragment } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/AuthSlice";
import EventBus from "../../Middleware/EventBus";
import BaseApi from "../../Http/BaseApi";
// import SideBar from "./SideBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPANModalOpen, setIsPANModalOpen] = useState(false);
  const [pan, setPAN] = useState("");
  const [confirmPAN, setConfirmPAN] = useState("");
  const authData = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Automatically open the modal when the component mounts

    getStaff(authData.user.id);
  }, []);

  const getStaff = async (id) => {
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        setPAN(data.data.pan);
        if (data.data.pan == null && [1, 2, 3].includes(data.data.type)) {
          setIsPANModalOpen(true);
        }
      })
      .catch(() => {});
  };

  const validateInput = (value) => /^[a-zA-Z0-9]*$/.test(value);

  const handlePANChange = (event) => {
    if (validateInput(event.target.value)) {
      setPAN(event.target.value?.toUpperCase());
    }
  };

  const handleConfirmPANChange = (event) => {
    if (validateInput(event.target.value)) {
      setConfirmPAN(event.target.value?.toUpperCase());
    }
  };

  const handlePANSubmit = async () => {
    if (pan !== confirmPAN) {
      alert("PAN numbers do not match. Please try again.");
      return;
    }
    if (pan.length !== 10) {
      alert("Please enter a valid 10-character PAN number.");
      return;
    }
    try {
      setLoading(true);
      await BaseApi().post("/pan-update", { pan });
      alert("PAN updated successfully.");
      setIsPANModalOpen(false);
    } catch (e) {
      alert("An error occurred while updating your PAN. Please try again.");
    } finally {
      setLoading(false);
    }

    // Here you would typically handle the submission to the backend
    console.log("PAN submitted:", pan);
  };

  // const closeModal = () => {
  //   setIsPANModalOpen(false);
  // };
  // console.log(authData);
  if (!authData.isLoggedIn) {
    // console.log("aaaaa");

    window.location.pathname("/");
  }

  const logOut = useCallback(() => {
    // console.log("logout call");

    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate("/");
      });
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [authData.user, logOut]);

  // const [state, setState] = useContext(userContext)

  // console.log(authData);
  if (!authData.isLoggedIn) {
    return <Navigate to='/department-login' />;
  }

  return (
    <Disclosure
      as='nav'
      className='border-b-2 bg-gradient-to-r to-gray-200 from-white '
    >
      {/* <Disclosure as="nav" className="bg-gradient-to-r from-cyan-700 to-blue-400">  */}

      <>
        <div className='max-w-full px-2 mx-auto ml-10 sm:px-6 '>
          <Dialog
            open={isPANModalOpen}
            className='relative z-50'
            onClose={() => {}}
          >
            {/* The overlay */}
            <div className='fixed inset-0 bg-black/30' aria-hidden='true' />

            {/* The modal container */}
            <div className='fixed inset-0 flex items-center justify-center p-4'>
              <Dialog.Panel className='max-w-4xl p-6 mx-auto bg-white rounded'>
                <Dialog.Title className='text-lg font-bold'>
                  Submit your PAN Details <br />
                  <small className='text-red-600'>
                    This is required for mandatory compliance of TDS rules under
                    the Income Tax Act, 1961.
                  </small>
                </Dialog.Title>
                <div className='mt-4'>
                  <label
                    htmlFor='pan'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Enter PAN
                  </label>
                  <input
                    type='text'
                    name='pan'
                    id='pan'
                    className={`uppercase appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                    value={pan}
                    onChange={handlePANChange}
                  />
                </div>
                <div className='mt-4'>
                  <label
                    htmlFor='confirmPan'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Confirm PAN
                  </label>
                  <input
                    type='text'
                    name='confirmPan'
                    id='confirmPan'
                    className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm `}
                    value={confirmPAN}
                    onChange={handleConfirmPANChange}
                  />
                </div>
                <div className='flex justify-end gap-4 mt-6'>
                  <button
                    type='button'
                    className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                    onClick={handlePANSubmit}
                  >
                    Submit
                    {loading && (
                      <div className='inline-block w-4 h-4 ml-2 border-b-2 border-gray-900 rounded-full animate-spin'></div>
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>

          <div className='relative flex justify-between h-16 items-left'>
            {/* <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"> */}
            {/* Mobile menu button*/}
            {/* <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button> */}
            {/* </div> */}
            {/* <Disclosure.Panel className="pt-6" style={{ marginTop: "100px" }}>
                <SideBar />
              </Disclosure.Panel> */}
            <div className='flex items-center justify-start flex-1 sm:items-stretch sm:justify-start'>
              <div className='flex items-center flex-shrink-0'>
                <img
                  src='https://erp.dibru.work/images/dibru.png'
                  className='w-8 mr-1 rounded-full'
                  alt='Avatar'
                />
                <span className='font-bold text-gray-900 capitalize'>
                  {authData.user.full_name ?? authData.user.name}
                </span>
              </div>
            </div>
            <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
              {/* <button
                type="button"
                className="p-1 text-gray-400 bg-gray-800 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="w-6 h-6" aria-hidden="true" />
              </button> */}

              {/* Profile dropdown */}
              <Menu as='div' className='relative ml-3'>
                <div>
                  <Menu.Button className='flex text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                    <span className='sr-only'>Open user menu</span>
                    <img
                      className='w-8 h-8 rounded-full'
                      src={authData.user.profile_pic}
                      onError={(e) => {
                        console.log(e.target.src);
                        if (
                          e.target.src !=
                            "https://ui-avatars.com/api/name=?" +
                              authData.user.name ??
                          "NO"
                        )
                          e.target.src =
                            "https://ui-avatars.com/api/?name=" +
                              authData.user.name ?? "NO";
                      }}
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/profile'
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/change-password'
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Change Password
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                          onClick={logOut}
                        >
                          Sign out
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </>
    </Disclosure>
  );
}
