import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";

const headers = {
  title: {
    label: "Title of the Book",
    path: "title",
  },
  publisher: {
    label: "Publisher",
    path: "publisher",
  },
  month: {
    label: "Month of Publish",
    path: "monthName",
  },
  year: {
    label: "Year of Publish",
    path: "year",
  },
  author_names: {
    label: "Author Names: (As per Book)",
    path: "author_names",
  },
  isbn: {
    label: "ISBN",
    path: "isbn",
  },
  book_type: {
    label: "Book Type",
    path: "book_type",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const BooksAuthored = ({ view }) => {
  const [booksAuthoreds, setBooksAuthoreds] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/authored-books");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setBooksAuthoreds(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/authored-books");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredBooks = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year and month (descending)
      const sortedBooks = filteredBooks.sort((a, b) => {
        const yearDiff = parseInt(b.year) - parseInt(a.year);
        if (yearDiff !== 0) return yearDiff;
        // Assuming months are stored as full month names (e.g., "January")
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return (
          monthNames.indexOf(b.monthName) - monthNames.indexOf(a.monthName)
        );
      });

      setBooksAuthoreds(sortedBooks);
    } catch (error) {
      console.error(error);
    }
  };

  const renderBooksAuthored = (book) => {
    const authorNames = getValueByPath(book, headers.author_names.path);
    const year = getValueByPath(book, headers.year.path);
    const title = getValueByPath(book, headers.title.path);
    const publisher = getValueByPath(book, headers.publisher.path);
    const isbn = getValueByPath(book, headers.isbn.path);

    return (
      <div className="pb-2 mb-2 border-b">
        <p>
          <span className="font-semibold">{authorNames}</span> ({year}),{" "}
          <i>{title}</i>, {publisher}, ISBN: {isbn}
        </p>
      </div>
    );
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Books Authored
      </h2>
      {booksAuthoreds.length ? (
        <div>
          {booksAuthoreds.map((book, index) => (
            <div key={index} className="flex items-start px-2">
              <span className="flex items-center justify-center w-6 h-6 p-2 mt-1 mr-2 text-gray-700 bg-blue-200 rounded-full">
                {index + 1}
              </span>
              {renderBooksAuthored(book)}
            </div>
          ))}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default BooksAuthored;
