import React, { useState, useEffect } from "react";

const Modal = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <div className='fixed inset-0 z-10 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity duration-300 ease-in-out'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>
        &#8203;
        <div
          className={`inline-block align-bottom overflow-scroll bg-white rounded-lg text-left shadow-xl transform transition-all ease-in-out duration-300 sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full ${className} ${
            isOpen
              ? "opacity-100 translate-y-0 scale-100"
              : "opacity-0 translate-y-4 scale-95"
          }`}
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
