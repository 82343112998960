import React, { useEffect, useState } from "react";
import {
  AcademicCapIcon,
  BookOpenIcon,
  UserGroupIcon,
  UsersIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAsync,
  updateDashboardNotesAsync,
} from "../../Redux/MasterSlice";
import { Link, useNavigate } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Registering the components necessary for the bar chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const [savingNote, setSavingNote] = useState(false);
  const navigate = useNavigate();

  const master = useSelector((state) => state.master);
  const dashboardData = { ...master.dashboardData };

  const [note, setNote] = useState(dashboardData.dashboardNote);
  const [timer, setTimer] = useState(null);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      saveNote({ note: value });
    }, 1000);
    setTimer(newTimer);
  };

  const saveNote = async (noteContent) => {
    setSavingNote(true);
    console.log("Note auto-saved:", noteContent);
    // Simulating a save operation (e.g., API call)
    // localStorage.setItem("quickNote", noteContent);
    await dispatch(updateDashboardNotesAsync(noteContent)).unwrap();
    setSavingNote(false);
  };

  useEffect(() => {
    if (Object.keys(dashboardData).length === 0) {
      dispatch(getDashboardAsync());
    }
  }, []);
  useEffect(() => {
    setNote(dashboardData.dashboardNote);

    if (dashboardData.paymentChartData) {
      const paymentsData = dashboardData.paymentChartData;

      // Transform the data from the backend to fit Chart.js dataset structure
      const chartLabels = paymentsData.map(
        (payment) =>
          `${payment.year}-${payment.month.toString().padStart(2, "0")}`
      );
      const chartData = paymentsData.map((payment) => payment.total);

      // Update the feeData state with the new labels and data
      setFeeData({
        labels: chartLabels,
        datasets: [
          {
            ...feeData.datasets[0],
            data: chartData,
          },
        ],
      });
    }
  }, [dashboardData.paymentChartData, dashboardData.dashboardNote]);

  const [feeData, setFeeData] = useState({
    labels: [], // Will hold the month names
    datasets: [
      {
        label: "Monthly Fee Collection",
        data: [], // Will hold the fee totals
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  });

  // Options for the chart
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Dashboard Stats */}
      <div className="flex flex-wrap justify-around gap-6 px-5 pt-5">
        {/* Students Card */}
        <div
          onClick={() => navigate(`/student-counts`, "_self")}
          className="flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5"
        >
          <UserGroupIcon className="w-12 h-12 text-purple-600" />
          <h3 className="mt-2 text-lg font-semibold text-gray-700">
            Students <small className="text-xs text-gray-500">2024-25</small>
          </h3>
          <p className="text-xl font-bold">{dashboardData.studentCount}</p>
        </div>

        {/* Courses Card */}
        <div
          onClick={() => navigate(`/programmes`, "_self")}
          className="flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5"
        >
          <BookOpenIcon className="w-12 h-12 text-green-600" />
          <h3 className="mt-2 text-lg font-semibold text-gray-700">
            Programmes
          </h3>
          <p className="text-xl font-bold">{dashboardData.programmeCount}</p>
        </div>

        {/* Teachers Card */}
        <div
          onClick={() => navigate(`/registered-phd-students-records`, "_self")}
          className="flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5"
        >
          <UsersIcon className="w-12 h-12 text-red-600" />
          <h3 className="w-full mt-2 overflow-hidden text-lg font-semibold text-center text-gray-700 whitespace-nowrap overflow-ellipsis">
            Registered Ph. D. Scholars
          </h3>
          <p className="text-xl font-bold">{dashboardData.phdCount}</p>
        </div>

        {/* Security Card */}
        <div
          onClick={() => navigate(`/hostelers`, "_self")}
          className="flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5"
        >
          <OfficeBuildingIcon className="w-12 h-12 text-yellow-600" />
          <h3 className="mt-2 text-lg font-semibold text-gray-700">
            Hostel Boarders
          </h3>
          <p className="text-xl font-bold">{dashboardData.hostelerCount}</p>
        </div>
      </div>

      {/* Recent Activity & Quick Actions */}
      <div className="grid grid-cols-2 gap-4 px-5 mt-8">
        {/* Monthly Fee Collection Chart */}
        <div className="w-full p-6 bg-white shadow-md rounded-xl">
          <h2 className="pb-4 text-lg font-semibold text-gray-700 border-b">
            Monthly Fee Collection
          </h2>
          <Bar data={feeData} options={options} />
        </div>
        {/* Quick Note Section */}
        <div className="w-full p-6 bg-white shadow-md rounded-xl ">
          <h2 className="pb-4 text-lg font-semibold text-gray-700 border-b">
            Quick Note
            <small className="float-right text-gray-400">
              {savingNote && "Saving..."}
            </small>
          </h2>
          <textarea
            className="w-full h-56 p-4 mt-2 text-base text-gray-700 bg-yellow-100 border-none rounded-md focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            placeholder="Type your notes here..."
            value={note}
            onChange={handleNoteChange}
          ></textarea>
        </div>

        {/* Recent Activity Section */}
        <div className="w-full p-6 bg-white shadow-md rounded-xl ">
          <h2 className="pb-4 text-lg font-semibold text-gray-700 border-b">
            Applicant Count (2024-2025)
          </h2>
          {/* Activity list placeholder */}
          <ul className="mt-4">
            <li className="flex items-center justify-between p-2 hover:bg-gray-50">
              <span>DUPGET Applicants</span>
              {dashboardData.dupgetCount}
            </li>
            <li className="flex items-center justify-between p-2 hover:bg-gray-50">
              <span>DURAT Applicants</span>
              {dashboardData.duratCount}
            </li>
            <li className="flex items-center justify-between p-2 hover:bg-gray-50">
              <span>BEd CET Applicants</span>
              {dashboardData.bedCetCount}
            </li>

            <li className="flex items-center justify-between p-2 hover:bg-gray-50">
              <Link
                to="/applicant-counts"
                className="flex items-center justify-center w-full text-blue-600"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                View All
              </Link>
            </li>

            {/* More list items */}
          </ul>
        </div>

        {/* Quick Actions Section */}
        <div className="w-full p-6 bg-white shadow-md rounded-xl">
          <h2 className="pb-4 text-lg font-semibold text-gray-700 border-b">
            Quick Actions
          </h2>
          {/* Actions list placeholder */}
          <ul className="mt-4">
            <li className="grid grid-cols-1 gap-2 p-2 ">
              <Link
                to="/admitted-students"
                className="flex items-center justify-between w-full hover:bg-gray-50"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                Admitted Students
                <UsersIcon
                  className="inline w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
              </Link>
              <Link
                to="/registered-phd-students-records"
                className="flex items-center justify-between w-full hover:bg-gray-50"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                Registered Ph. D. Scholars Record
                <AcademicCapIcon
                  className="inline w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
              </Link>
              <Link
                to="/awarded-phd-students-records"
                className="flex items-center justify-between w-full hover:bg-gray-50"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                Ph. D. Awardees
                <AcademicCapIcon
                  className="inline w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
              </Link>
              <Link
                to="/hostelers"
                className="flex items-center justify-between w-full hover:bg-gray-50"
                href="#!"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
              >
                List of Hostel Boarders
                <OfficeBuildingIcon
                  className="inline w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
              </Link>
              {/* <Link
                to='/payments'
                className='flex items-center justify-between w-full hover:bg-gray-50'
                href='#!'
                data-mdb-ripple='true'
                data-mdb-ripple-color='primary'
              >
                View Payments
                <CurrencyRupeeIcon
                  className='inline w-6 h-6 text-green-500'
                  aria-hidden='true'
                />
              </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
