import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";
import Button from "../../Components/Utils/Button";
import {
  FaBuilding,
  FaFileAlt,
  FaFileExport,
  FaHourglassHalf,
  FaInbox,
  FaListUl,
  FaPlus,
  FaSearch,
} from "react-icons/fa";

const FileTrackingDashboard = () => {
  const [stats, setStats] = useState({
    createdFiles: 0,
    forwardAcceptPending: 0,
    forwardedNotAccepted: 0,
    filesInBranch: 0,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch dashboard statistics
    fetchStats();
  }, []);

  const fetchStats = async () => {
    setLoading(true);
    try {
      const response = await BaseApi().get("/ft-dashboard-stats");
      setStats(response.data); // Assuming response.data contains the stats
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stats:", error);
      setLoading(false);
    }
  };

  return (
    <div className='w-full p-6 bg-white'>
      <h1 className='flex justify-between mb-6 text-3xl font-semibold text-gray-800'>
        File Tracking Dashboard
        {/* Incoming Files Button */}
        <div
          onClick={() => navigate("/list-of-files?status=search")}
          className='flex px-4 py-3 text-sm text-center text-white transition-all duration-300 rounded-lg shadow-lg cursor-pointer bg-gradient-to-r from-orange-500 to-orange-700 hover:from-orange-600 hover:to-orange-800'
        >
          <FaSearch className='w-5 h-5 mr-2' />
          Search Files
        </div>
      </h1>

      {loading ? (
        <p>Loading stats...</p>
      ) : (
        <div className='grid grid-cols-1 gap-6 mb-8 md:grid-cols-2 lg:grid-cols-4'>
          <div
            onClick={() => navigate("/list-of-files?status=created")}
            className='flex flex-col justify-between p-4 bg-blue-100 rounded-lg shadow-md cursor-pointer'
          >
            <div>
              <div className='flex items-center'>
                <FaFileAlt className='mr-2 text-2xl text-blue-600' />
                <h3 className='font-semibold text-gray-600 text-md'>
                  Created Files
                </h3>
              </div>
              <p className='text-sm text-gray-500'>Files created by you</p>
            </div>
            <p className='mt-4 text-3xl font-bold text-blue-700'>
              {stats.createdFiles}
            </p>
          </div>

          <div
            onClick={() =>
              navigate("/list-of-files?status=pending_acceptances")
            }
            className='flex flex-col justify-between p-4 bg-yellow-100 rounded-lg shadow-md cursor-pointer'
          >
            <div>
              <div className='flex items-center'>
                <FaHourglassHalf className='mr-2 text-2xl text-yellow-600' />
                <h3 className='font-semibold text-gray-600 text-md'>
                  Pending Acceptances
                </h3>
              </div>
              <p className='text-sm text-gray-500'>
                Forwarded files awaiting for your acceptance
              </p>
            </div>
            <p className='mt-4 text-3xl font-bold text-yellow-700'>
              {stats.forwardAcceptPending}
            </p>
          </div>

          <div
            onClick={() =>
              navigate("/list-of-files?status=forwarded_not_accepted")
            }
            className='flex flex-col justify-between p-4 bg-red-100 rounded-lg shadow-md cursor-pointer'
          >
            <div>
              <div className='flex items-center'>
                <FaFileExport className='mr-2 text-2xl text-red-600' />
                <h3 className='font-semibold text-gray-600 text-md'>
                  Forwarded Files
                </h3>
              </div>
              <p className='text-sm text-gray-500'>
                Files forwarded by you but not yet accepted
              </p>
            </div>
            <p className='mt-4 text-3xl font-bold text-red-700'>
              {stats.forwardedNotAccepted}
            </p>
          </div>

          <div
            onClick={() => navigate("/list-of-files?status=files_in_branch")}
            className='flex flex-col justify-between p-4 bg-green-100 rounded-lg shadow-md cursor-pointer'
          >
            <div>
              <div className='flex items-center'>
                <FaBuilding className='mr-2 text-2xl text-green-600' />
                <h3 className='font-semibold text-gray-600 text-md'>
                  Files in Your Branch
                </h3>
              </div>
              <p className='text-sm text-gray-500'>
                Files pending action in your branch
              </p>
            </div>
            <p className='mt-4 text-3xl font-bold text-green-700'>
              {stats.filesInBranch}
            </p>
          </div>
        </div>
      )}

      <div className='grid grid-cols-1 gap-6 mt-20 md:grid-cols-3'>
        {/* Create New File Button */}
        <div
          onClick={() => navigate("/file-create")}
          className='flex items-center justify-center w-full px-4 py-3 font-semibold text-center text-white transition-all duration-300 rounded-lg shadow-lg cursor-pointer bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800'
        >
          <FaPlus className='w-5 h-5 mr-2' />
          Create New File
        </div>

        {/* List of Files Button */}
        <div
          onClick={() => navigate("/list-of-files")}
          className='flex items-center justify-center w-full px-4 py-3 font-semibold text-center text-white transition-all duration-300 rounded-lg shadow-lg cursor-pointer bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800'
        >
          <FaListUl className='w-5 h-5 mr-2' />
          List of Files
        </div>

        {/* Incoming Files Button */}
        <div
          onClick={() => navigate("/list-of-files?status=pending_acceptances")}
          className='flex items-center justify-center w-full px-4 py-3 font-semibold text-center text-white transition-all duration-300 rounded-lg shadow-lg cursor-pointer bg-gradient-to-r from-purple-500 to-purple-700 hover:from-purple-600 hover:to-purple-800'
        >
          <FaInbox className='w-5 h-5 mr-2' />
          Incoming Files
        </div>
      </div>
    </div>
  );
};

export default FileTrackingDashboard;
