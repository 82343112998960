import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
// import ActionConfirm from "../../Components/Utils/ActionConfirm";
import Select from "../../Components/Utils/Select";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import UpdateLeaveModal from "../Staff/UpdateLeave";

export default function OverallLeaveReport() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  // const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  // const [searchInput, setSearchInput] = useState("");
  const [showUpdateLeaveModal, setShowUpdateLeaveModal] = useState(false);
  const [singleStaff, setSingleStaff] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [overallleaves, setOverAllLeaves] = useState([]);
  const [leave_types, setLeaveTypes] = useState([]);
  const authData = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState();
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  useEffect(() => {
    setInitialValues({
      leave_type_id: "",
      year: "",
      staff: "All",
    });
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    console.log(id);
    if (leave_types.length === 0) {
      BaseApi()
        .get(`/leave-types`)
        .then((response) => {
          setLeaveTypes(response.data);
          // console.log('data is', response.data)
        })
        .catch((error) => {
          console.log("error is", error);
        });
    }
    getStaff(id);
    getStaffWiseLeave({ year: new Date().getFullYear() });
    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getStaff(id) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        let _singleStaff = data.data;
        setSingleStaff(_singleStaff);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  const validationSchema = Yup.object().shape({
    leave_type_id: Yup.string().nullable().required("This field is required!"),

    year: Yup.string().nullable().required("This field is required!"),
  });

  async function getStaffWiseLeave(searchQuery) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`overallleaves/${id}`, { params: searchQuery })
      .then((data) => {
        setLeaves(data.data.leaves);
        setOverAllLeaves(data.data.leave_types);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  // const handleDelete = async (event, payload) => {
  //   event.target.disabled = true;
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (deleteConfirm.state == 1) {
  //     await BaseApi()
  //       .delete(`staff/${payload.id}`)
  //       .then((data) => {
  //         console.log(data.data.id);
  //         let _staff = staff.filter((s) => s.id != data.data.id);
  //         console.log(_staff);
  //         setStaff(_staff);
  //       })
  //       .catch((error) => {
  //         const message =
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //           error.message ||
  //           error.toString();
  //         setErrorMessage(message);
  //       });
  //   }
  //   event.target.disabled = false;
  //   setDeleteConfirm({});
  // };
  // const handleDeactivate = async (event, payload) => {
  //   event.target.disabled = true;
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (deactivateConfirm.state == 1) {
  //     await BaseApi()
  //       .post(`staff/change-status/${payload.id}`)
  //       .then((data) => {
  //         console.log(data.data.id);
  //         let _staff = [...leaves];

  //         const index = _staff.findIndex((s) => s.id === data.data.id);
  //         _staff[index] = data.data;
  //         setLeaves(_staff);
  //         setDeactivateConfirm({});
  //       })
  //       .catch((error) => {
  //         const message =
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //           error.message ||
  //           error.toString();
  //         setErrorMessage(message);
  //       });
  //   }
  //   event.target.disabled = false;
  //   // setDeleteConfirm({});
  // };
  // console.log(handleDeactivate);
  // const handleCheckbox = (event) => {
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     setSelectedIds([...selectedIds, value]);
  //   } else {
  //     var index = selectedIds.indexOf(value);
  //     if (index != -1) {
  //       setSelectedIds(selectedIds.filter((item) => item !== value));
  //     }
  //   }
  // };

  // console.log(selectedIds);
  // const handleBulkDelete = async (event) => {
  //   event.preventDefault();
  //   event.target.disabled = true;

  //   await BaseApi()
  //     .post(`staff/bulk-delete`, selectedIds)
  //     .then((response) => {
  //       let _staff = staff.filter((s) => {
  //         return !response.ids.includes(s.id.toString());
  //       });
  //       setStaff(_staff);
  //     });

  //   setSelectedIds([]);
  //   event.target.disabled = false;
  // };

  const handleUpdateLeaveModalClose = (data) => {
    getStaffWiseLeave({ year: new Date().getFullYear() });
    console.log(data);
    // Store the data passed from the modal
    setShowUpdateLeaveModal(false); // Close the modal
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Leave Report of ' />

            {/* <button
                              onClick={() => {
                                setShowUpdateLeaveModal(true);
                              }}
                              type='button'
                              className='float-right p-1 text-white bg-red-400 rounded-sm shadow'
                            >
                             Assign Leave
                            </button> */}

            {showUpdateLeaveModal && (
              <UpdateLeaveModal
                setShowUpdateLeaveModal={handleUpdateLeaveModalClose}
                staff_id={id}
              />
            )}

            <br />

            <p className='text-lg font-bold'>{singleStaff?.name}</p>
            <p className='text-sm'>
              Department: {singleStaff?.department?.name}
            </p>
            <p className='text-sm'>
              Designation: {singleStaff?.designation?.name}
            </p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={getStaffWiseLeave}
            >
              <Form>
                <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={leave_types}
                    name='leave_type_id'
                    label='Select Leave Type'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "leave_type_id",
                        selectedOption.id
                      );
                    }}
                  />
                  <Select name='year' label='Select Year'>
                    <option value=''>Select Any One</option>
                    <option value='2023'>2023</option>
                    <option value='2024'>2024</option>
                  </Select>
                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='w-8 table-head'>#</th>
                  <th className='table-head'>Leave Type</th>
                  <th className='table-head'>Total Days</th>
                  <th className='table-head'>Total Taken</th>
                  <th className='table-head'>Remaining</th>
                  {/* <th className="table-head">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {overallleaves.length > 0 &&
                  overallleaves.map((ol, i) => {
                    return (
                      <tr key={ol.id} className='odd:bg-white even:bg-gray-50'>
                        <td className='w-32 table-data'>{++i}</td>
                        <td className='table-data'>
                          {ol.id == 3 ? "Only " + ol.name : ol.name}
                        </td>
                        <td className='table-data'>
                          {ol.id == 3 && <>N/A</>}
                          {ol.id != 3 && ol.id != 6 && <>{ol?.total_leaves} </>}
                          {ol.id == 6 && (
                            <>
                              {ol?.total_leaves} as on Date{" "}
                              {moment(ol?.pivot?.date).format("DD/MM/YYYY")}{" "}
                            </>
                          )}
                        </td>
                        <td className='table-data'>{ol?.total_taken}</td>
                        <td className='table-data'>
                          {ol.id == 3 && <>N/A</>}
                          {ol.id != 3 && ol.id != 6 && (
                            <>{ol?.total_leaves - ol?.total_taken}</>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <br />
            <br />

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <br />
            <br />

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='w-8 table-head'>#</th>
                  <th className='table-head '>Leave Type</th>

                  <th className='table-head'>From Date</th>
                  <th className='table-head'>To Date</th>
                  <th className='table-head'>No. of Days</th>
                  <th className='table-head'>Status</th>

                  <th className='table-head'>Action</th>
                </tr>
              </thead>
              <tbody>
                {leaves.length > 0 &&
                  leaves
                    .filter((l) => {
                      if (
                        authData.user.email == "establishmenta@dibru.ac.in" ||
                        authData.user.email == "vc@dibru.ac.in" ||
                        authData.user.email == "registrar@dibru.ac.in"
                      ) {
                        return l.staff?.type == "1" || l.staff?.type == 3;
                      }
                      if (authData.user.email == "establishmentb@dibru.ac.in") {
                        return l.staff?.type == "2";
                      }
                    })

                    .map((leave, i) => {
                      return (
                        <tr
                          key={leave.id}
                          className='odd:bg-white even:bg-gray-50'
                        >
                          <td className='w-32 table-data'>{++i}</td>
                          <td className='table-data'>
                            {leave.leave_type?.name}
                          </td>

                          <td className='table-data'>
                            {moment(leave.from_date).format("DD-MM-YYYY")}
                          </td>
                          <td className='table-data'>
                            {moment(leave.to_date).format("DD-MM-YYYY")}
                          </td>
                          <td className='table-data'>
                            {leave.status != 1 && leave.total_days}

                            {leave?.status == 1 &&
                              leave?.leave_type_id != 3 && (
                                <>
                                  {
                                    leave?.leave_dates.filter(
                                      (ld) => ld.status == 1
                                    ).length
                                  }
                                </>
                              )}

                            {leave?.status == 1 &&
                              leave?.leave_type_id == 3 && (
                                <>{leave?.leave_dates.length}</>
                              )}
                          </td>

                          <td className='table-data'>
                            {" "}
                            {leave?.status == 0 && (
                              <>
                                <span className='text-yellow-600'>
                                  Pending for Approval
                                </span>
                              </>
                            )}
                            {leave?.status == 1 && (
                              <>
                                <span className='text-green-700'>Approved</span>
                              </>
                            )}
                            {leave?.status == 2 && (
                              <>
                                <span className='text-red-700'>Rejected </span>
                              </>
                            )}
                            {leave?.status == 3 && (
                              <>
                                <span className='text-purple-700'>
                                  Forwarded
                                </span>
                              </>
                            )}
                            {leave?.status == -1 && (
                              <>
                                <span className='text-red-900'>Cancelled</span>
                              </>
                            )}
                            {leave?.cancelation_reason != null &&
                              leave?.status != -1 && (
                                <>
                                  <br />
                                  <br />
                                  <span className='text-yellow-600'>
                                    Cancellation Requested
                                  </span>
                                </>
                              )}
                          </td>

                          <td className='table-data'>
                            <button
                              className='px-4 py-2 font-bold text-blue-700 rounded '
                              onClick={() => {
                                navigate(`/leaves/${leave.id}`);
                              }}
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
