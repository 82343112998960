import React, { useEffect, useState } from "react";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";

import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function SubjectStatus() {
  const dispatch = useDispatch();

  const [subjectmasters, setSubjectmasters] = useState({});
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;

  useEffect(() => {
    getSubjectMasters();

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);
  const getSubjectMasters = async () => {
    const _subjectmasters = await BaseApi().get(`subject-masters`);

    console.log(_subjectmasters.data);
    setSubjectmasters(_subjectmasters.data);
  };

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full min-h-screen px-5 overflow-x-scroll '>
            <PageTitle name='Subject/Course Apply Status' />

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Subject/Course Name</th>
                  <th className='table-head'>Type</th>
                  <th className='table-head'>Offered By</th>
                  <th className='table-head'>Semester</th>
                  <th className='table-head'>Intake</th>

                  <th className='table-head'>Remaining</th>

                  <th className='table-head'>Filled</th>
                  <th className='table-head'>Acttion</th>
                </tr>
              </thead>
              <tbody>
                {subjectmasters?.length > 0 &&
                  subjectmasters.map((subject) => {
                    return (
                      <>
                        <tr
                          className={`${
                            subject.intake >
                            subject.remaining + subject.studentsubjects_count
                              ? "bg-[#f3bfbf]"
                              : subject.intake <
                                subject.remaining +
                                  subject.studentsubjects_count
                              ? "bg-red-200"
                              : subject.intake === subject.studentsubjects_count
                              ? "bg-green-200"
                              : ""
                          }`}
                        >
                          <td className='table-data'>{++sl}</td>
                          <td className='table-data'>{subject?.name}</td>
                          <td className='table-data'>
                            {subject.cbcs_type?.name}
                          </td>
                          <td className='table-data'>
                            {subject?.department?.name}
                          </td>
                          <td className='table-data'>{subject?.semester}</td>

                          <td className='table-data'>{subject?.intake}</td>

                          <td className='table-data'>{subject?.remaining}</td>
                          <td className='table-data'>
                            {subject?.studentsubjects_count}
                          </td>

                          <td className='table-data'>
                            <span
                              className='text-blue-800 cursor-pointer'
                              onClick={() =>
                                window.open(
                                  `/subjects?subjectmaster_id=${subject?.id}`,
                                  "_blank"
                                )
                              }
                            >
                              View Students
                            </span>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}
            {subjectmasters?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
