import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import Modal from "../../../utils/Modal";
import TableComponent from "../../../utils/TableComponent";
import FormPage from "./FormPage";

const Patent = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [patents, setPatents] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    inventors_creators: "",
    applicant_name: "",
    application_number: "",
    status: "",
    patent_number: "",
    patent_granted_date: "",
    filling_country: "",
    invention: "",
    filling_date: "",
    published_date: "",
  });
  const headers = {
    title: {
      label: "Title of the Patent",
      path: "title",
    },
    inventors_creators: {
      label: "Inventors",
      path: "inventors_creators",
    },
    applicant_name: {
      label: "Applicant’s Name",
      path: "applicant_name",
    },
    application_number: {
      label: "Application Number",
      path: "application_number",
    },
    status: {
      label: "Status",
      path: "status",
    },
    patent_number: {
      label: "Patent Number",
      path: "patent_number",
    },
    patent_granted_date: {
      label: "Patent Granted Date",
      path: "patent_granted_date",
    },
    filling_country: {
      label: "Filing Country",
      path: "filling_country",
    },
    invention: {
      label: "Field of Invention",
      path: "invention",
    },
    filling_date: {
      label: "Filing Date",
      path: "filling_date",
    },
    published_date: {
      label: "Published Date",
      path: "published_date",
    },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    inventors_creators: Yup.string().required("This field is required!"),
    applicant_name: Yup.string().required("This field is required!"),
    application_number: Yup.string().required("This field is required!"),
    status: Yup.string().required("This field is required!"),
    patent_number: Yup.string().when("status", {
      is: "Granted", // Check if 'indexed_in' is equal to 'Others'
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string(), // Optional: can leave this out if no other condition is needed
    }),
    patent_granted_date: Yup.string().when("status", {
      is: "Granted", // Check if 'indexed_in' is equal to 'Others'
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string(), // Optional: can leave this out if no other condition is needed
    }),

    filling_country: Yup.string().required("This field is required!"),
    invention: Yup.string().required("This field is required!"),
    filling_date: Yup.date().required("This field is required!"),
    published_date: Yup.date().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/patents");
      setPatents(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/patents/${editId}`, values);
        toast.success(`Patent Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/patents", values);
        toast.success(`Patent Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = patents.find((patent) => patent.id === id);
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        inventors_creators: editableData.inventors_creators,
        applicant_name: editableData.applicant_name,
        application_number: editableData.application_number,
        status: editableData.status,
        patent_number: editableData.patent_number,
        patent_granted_date: editableData.patent_granted_date,
        filling_country: editableData.filling_country,
        invention: editableData.invention,
        filling_date: editableData.filling_date,
        published_date: editableData.published_date,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      inventors_creators: "",
      applicant_name: "",
      application_number: "",
      status: "",
      patent_number: "",
      patent_granted_date: "",
      filling_country: "",
      invention: "",
      filling_date: "",
      published_date: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      inventors_creators: "",
      applicant_name: "",
      application_number: "",
      status: "",
      patent_number: "",
      patent_granted_date: "",
      filling_country: "",
      invention: "",
      filling_date: "",
      published_date: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/patents/${id}`);
      toast.success(`Patent Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Patents :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={patents}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default Patent;
