import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfileWeb from "../../utils/TableViewProfileWeb";
import { SiHiveBlockchain } from "react-icons/si";
import moment from "moment";
import { FaBriefcase } from "react-icons/fa";
import { FaCalendarDays, FaFile, FaIndianRupeeSign } from "react-icons/fa6";

const headers = {
  name: { label: "Title", path: "name" },
  organization_name: { label: "Organization Name", path: "organization_name" },
  details: { label: "Brief Description", path: "details" },
  role: { label: "Your Role in the Consultancy", path: "role" },
  amount: { label: "Amount Earned through Consultancy", path: "amount" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const ConsultancyExternalService = ({ view }) => {
  const [consultancyExternalServices, setConsultancyExternalServices] =
    useState([]);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get(
  //       "/profile/consultancy-external-services"
  //     );
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setConsultancyExternalServices(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/consultancy-external-services"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredServices = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by start_date (latest date first)
      const sortedServices = filteredServices.sort((a, b) => {
        const startDateA = getValueByPath(a, headers.start_date.path);
        const startDateB = getValueByPath(b, headers.start_date.path);
        const dateA = startDateA ? moment(startDateA) : moment("1900-01-01");
        const dateB = startDateB ? moment(startDateB) : moment("1900-01-01");
        return dateB - dateA; // Descending order
      });

      setConsultancyExternalServices(sortedServices);
    } catch (error) {
      console.error(error);
    }
  };

  const renderConsultancyExternalService = (experience) => {
    const name = getValueByPath(experience, headers.name.path);
    const organizationName = getValueByPath(
      experience,
      headers.organization_name.path
    );
    const details = getValueByPath(experience, headers.details.path);
    const amount = getValueByPath(experience, headers.amount.path);

    const startDate = formatDate(
      getValueByPath(experience, headers.start_date.path)
    );
    const endDate = getValueByPath(experience, headers.end_date.path)
      ? formatDate(getValueByPath(experience, headers.end_date.path))
      : "Present";

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          <p className='flex items-center gap-2'>
            <FaBriefcase />
            <span className='text-green-600'>{organizationName}</span>
          </p>

          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaFile className='text-yellow-500' />
              {details}
            </span>

            <span className='flex items-center gap-1'>
              <FaIndianRupeeSign className='text-yellow-500' />
              {amount}
            </span>

            <span className='flex items-center gap-1'>
              <FaCalendarDays className='text-yellow-500' />
              {startDate} - {endDate}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        <SiHiveBlockchain />
        Consultancy/External Services
      </h2>
      {consultancyExternalServices.length ? (
        <div className=''>
          {consultancyExternalServices.map(
            (consultancyExternalService, index) => (
              <div key={index} className=''>
                {renderConsultancyExternalService(consultancyExternalService)}
              </div>
            )
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default ConsultancyExternalService;
