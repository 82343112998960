import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import Select from "../../Components/Utils/Select";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import moment from "moment";
import can from "../../Middleware/Permission";

export default function StudentPaymentReports() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();

  // let formSections = useSelector((state) => state.applicants.formSections);

  //   const dispatch = useDispatch();
  useEffect(() => {
    getReports();
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getReports() {
    setReports([]);
    setLoading(true);

    const response = await BaseApi().get(`reports/student-payments`);
    setReports(response.data);
    setLoading(false);
  }

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='Generated Payment Reports' />

            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>File Name</th>
                  <th className='table-head'>From Date</th>
                  <th className='table-head'>To Date</th>
                  <th className='table-head'>Status</th>
                  <th className='table-head'>Generated At</th>
                  <th className='table-head' colSpan={2}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {reports?.length > 0 &&
                  reports.map((report, index) => {
                    return (
                      <>
                        <tr>
                          <td className='table-data'>{++index}</td>
                          <td className='table-data'>{report.file}</td>
                          <td className='table-data'>
                            {moment(report.from_date).format("DD/MM/YYYY")}
                          </td>
                          <td className='table-data'>
                            {moment(report.to_date).format("DD/MM/YYYY")}
                          </td>
                          <td className='table-data'>
                            {report.status == 0 && "Pending Payments"}
                            {report.status == 1 && "Successful Payments"}
                          </td>
                          <td className='table-data'>
                            {moment(report.created_at).format("DD/MM/YYYY")}
                          </td>
                          <td className='table-data'>
                            <a
                              className='text-blue-600 '
                              href={report.report_file}
                            >
                              Download
                            </a>
                          </td>
                          <td className='table-data'>
                            {/* Delete button */}
                            {can("Account Masters") && (
                              <button
                                className='text-red-600'
                                onClick={async () => {
                                  // confirm
                                  if (
                                    !window.confirm(
                                      "Are you sure you want to delete this report?"
                                    )
                                  ) {
                                    return;
                                  }

                                  const response = await BaseApi().delete(
                                    `reports/student-payments/${report.id}`
                                  );
                                  if (response.status == 200) {
                                    getReports();
                                  }
                                }}
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {reports?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
