import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
// import Select from "../../Components/Utils/Select";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";

export default function ViewLeaveDetails() {
  const { id } = useParams();
  // const authData = useSelector((state) => state.auth);
  const [leave, setLeave] = useState({});
  const printRef = useRef();

  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  // const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  const [errorMessage, setErrorMessage] = useState(false);
  const authData = useSelector((state) => state.auth);
  const [showAllDates, setShowAllDates] = useState(false);

  useEffect(() => {
    async function checkData() {
      if (id && id > 0) {
        setLoading(true);

        console.log(" call 2");
        try {
          const _leave = await BaseApi().get(`leaves/${id}`);

          setLeave(_leave.data);
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        }

        setLoading(false);
      }
    }
    checkData();
  }, [id]);

  const requestCancelation = async (reason) => {
    await BaseApi()
      .post(`leave/request_cancelation/${leave?.id}`, { reason })
      .then((data) => {
        toast.success("Cancelation Request Sent!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        setLeave({});
        setLeave(data.data);

        setLoading(false);
      });
    setLoading(false);
  };
  const Print = useReactToPrint({
    content: () => printRef.current,
  });

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      {loading == true && (
        <>
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        </>
      )}

      <div className='py-2' ref={printRef}>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <button
              className='inline-block float-right px-3 py-1 font-normal leading-normal text-center text-white no-underline whitespace-no-wrap align-middle bg-teal-500 border rounded select-none noPrint hover:bg-teal-600 btn-md screen-only '
              onClick={Print}
            >
              Print
            </button>
            <div className='grid grid-cols-6 pt-1 pl-2 pr-2 mt-5 onlyPrint'>
              <div>
                <img
                  style={{ filter: "saturate(200%)" }}
                  src='https://erp.dibru.work/images/dibru.png'
                  className='rounded-full w-50'
                  alt='Avatar'
                />
              </div>
              <div className='col-span-4'>
                <p className='text-lg font-bold text-center'>
                  <img
                    style={{ width: "200px" }}
                    className='w-20 ml-auto mr-auto mt-o '
                    src='/assets/logo-assamese.png'
                  />
                  Dibrugarh University
                </p>
                <p className='text-center text-md '>
                  Dibrugarh-786004, Assam, India
                </p>
              </div>
              <div> </div>
            </div>
            <PageTitle name='Leave Details' />

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <tbody>
                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data' colSpan={4}>
                    <span className='font-bold'>
                      Application Submitted on:{" "}
                      {moment(leave?.created_at).format("DD-MM-YYYY hh:mm A")}
                    </span>
                  </td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>Applied By</td>
                  <td className='table-data'>
                    <b> {leave?.staff?.full_name}</b>
                    <br />
                    {leave?.staff?.designation?.name} <br />
                    {leave?.staff?.department?.name} <br />
                    <br />
                  </td>
                  <td className='table-data'>Leave Type</td>
                  <td className='table-data'>
                    {/* {leave?.leave_type?.name}  */}

                    {[
                      ...new Set(
                        leave?.leave_dates?.map((item) => item.leave_type.name)
                      ),
                    ].toString()}
                  </td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>No. of Days</td>
                  <td className='table-data'>
                    {leave.status != 1 && leave.total_days}

                    {leave?.status == 1 && leave?.leave_type_id != 3 && (
                      <>
                        {
                          leave?.leave_dates.filter((ld) => ld.status == 1)
                            .length
                        }
                      </>
                    )}

                    {leave?.status == 1 && leave?.leave_type_id == 3 && (
                      <>{leave?.leave_dates.length}</>
                    )}
                  </td>
                  <td className='w-32 table-data'>Reason/Remarks</td>
                  <td className='table-data'>{leave?.reason}</td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>
                    Arrangement of Classes/Lab <br /> (if applicable)
                  </td>
                  <td className='table-data'>{leave?.arrangement}</td>
                  <td className='table-data'>
                    Proposed In-charge <br />
                    (if applicable)
                  </td>
                  <td className='table-data'>
                    {leave?.proposed_incharge}

                    {leave.incharges?.map((incharge) => (
                      <>
                        {incharge.staff?.full_name}-{incharge.remarks} <br />
                      </>
                    ))}
                  </td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>Forwarding Authority</td>
                  <td className='table-data'>
                    {leave?.forwarding_authority?.full_name}
                    <br />
                    {leave?.forwarding_authority2?.full_name}
                  </td>
                  <td className='table-data'>Approving Authority</td>
                  <td className='table-data'>
                    {leave?.approving_authority?.full_name}
                  </td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>Status</td>
                  <td className='table-data'>
                    {" "}
                    {leave?.status == 0 && (
                      <>
                        <span className='text-yellow-600'>
                          Pending for Approval
                        </span>
                      </>
                    )}
                    {leave?.status == 1 && (
                      <>
                        <span className='text-green-700'>Approved</span>
                      </>
                    )}
                    {leave?.status == 2 && (
                      <>
                        <span className='text-red-700'>Rejected </span>
                      </>
                    )}
                    {leave?.status == 3 && (
                      <>
                        <span className='text-purple-700'>Forwarded</span>
                      </>
                    )}
                    {leave?.status == -1 && (
                      <>
                        <span className='text-red-900'>Cancelled</span>
                      </>
                    )}
                  </td>
                  <td className='table-data'>Supporting Document</td>
                  <td className='table-data'>
                    {leave?.file != null && (
                      <a
                        target='_blank'
                        href={leave?.supporting_doc}
                        className='text-blue-700 noPrint'
                        rel='noreferrer'
                      >
                        View Document
                      </a>
                    )}
                    {leave?.file == null && "Not Uploaded"}
                    {leave?.file != null && (
                      <span className='onlyPrint'>Uploaded</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr className='h-px my-8 bg-gray-200 border-0 noPrint dark:bg-gray-700' />
            <div className='grid-cols-3 gap-4 lg:grid'>
              <div className=''>
                <h2 className='mb-1 font-bold'>Applied Dates:</h2>
                <table>
                  <tr>
                    <th className={thClass}>From</th>
                    <th className={thClass}>To</th>
                  </tr>
                  <tr>
                    <td className={tdClass}>
                      {moment(leave?.from_date).format("DD-MM-YYYY")}{" "}
                      {leave.leave_type_id == 3 && (
                        <>{leave.sl_from_time ? leave.sl_from_time : ""}</>
                      )}
                    </td>
                    <td className={tdClass}>
                      {moment(leave?.to_date).format("DD-MM-YYYY")}{" "}
                      {leave.leave_type_id == 3 && (
                        <>{leave.sl_from_time ? leave.sl_from_time : ""}</>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
              <div className='col-span-2'>
                <h2 className='mb-1 font-bold'>Status:</h2>
                <table>
                  <tr>
                    <th className={thClass}>Authority</th>
                    <th className={thClass}>Status</th>
                    <th className={thClass}>Date</th>
                    <th className={thClass}>Remark</th>
                  </tr>

                  {leave?.leave_approvals?.length > 0 &&
                    leave?.leave_approvals.map((leave_approve) => {
                      return (
                        <tr
                          key={leave_approve.id}
                          className='odd:bg-white even:bg-gray-50'
                        >
                          <td className={tdClass}>
                            {leave_approve?.approving_authority?.full_name}
                          </td>
                          <td className={tdClass}>
                            {leave_approve?.status == 0 && "Pending"}
                            {leave_approve?.status == 1 &&
                              leave_approve?.type == 1 &&
                              "Approved"}
                            {leave_approve?.status == 1 &&
                              leave_approve?.type == 0 &&
                              "Forwarded"}
                            {leave_approve?.status == 2 &&
                              leave_approve?.type == 0 &&
                              "Rejected"}
                            {leave_approve?.status == 2 &&
                              leave_approve?.type == 1 &&
                              "Rejected"}
                            {leave_approve?.status == -1 && "Cancelled"}
                          </td>
                          <td className={tdClass}>
                            {" "}
                            {leave_approve.approve_date
                              ? moment(leave_approve.approve_date).format(
                                  "DD-MM-YYYY"
                                )
                              : ""}
                          </td>
                          <td className={tdClass}>{leave_approve.remark}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>

            {leave?.leave_type_id != 3 && (
              <>
                <table
                  id='datatable'
                  className='min-w-full mt-5 bg-white lg:mt-1 dark:bg-gray-800'
                >
                  <thead>
                    <tr>
                      <th className={thClass + "w-16"}></th>
                      <th className={thClass}>Date</th>
                      <th colSpan='2' className={thClass}>
                        Leave Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {leave?.leave_dates?.length > 0 &&
                      (showAllDates
                        ? leave.leave_dates
                        : leave.leave_dates.slice(0, 5)
                      ).map((leave_date) => {
                        return (
                          <tr
                            key={leave_date.id}
                            className='odd:bg-white even:bg-gray-50'
                          >
                            <td className={tdClass + "w-32"}>
                              {(leave_date.holiday == "0" ||
                                leave_date.leave_type_id == 4 ||
                                leave_date.leave_type_id == 6) &&
                                leave_date.status == 0 &&
                                "Applied"}{" "}
                              {(leave_date.holiday == "0" ||
                                leave_date.leave_type_id == 4 ||
                                leave_date.leave_type_id == 6) &&
                                leave_date.status == 1 &&
                                "Approved"}{" "}
                              {(leave_date.holiday == "0" ||
                                leave_date.leave_type_id == 4 ||
                                leave_date.leave_type_id == 6) &&
                                leave_date.status == 2 && (
                                  <span className='text-red-700'>Rejected</span>
                                )}{" "}
                              {leave_date.holiday == 1 && (
                                <>
                                  {leave_date.holiday_type_id == -1 && (
                                    <>
                                      <span className='text-red-500'>
                                        Sunday
                                      </span>
                                    </>
                                  )}
                                  {leave_date.holiday_type_id == -2 && (
                                    <>
                                      <span className='text-red-500'>
                                        Saturday
                                      </span>
                                    </>
                                  )}
                                  <>
                                    <span className='text-red-500'>
                                      {" "}
                                      {leave_date.holiday_type?.name}
                                    </span>
                                  </>
                                </>
                              )}
                            </td>

                            <td className={tdClass}>
                              {moment(leave_date.date).format("DD-MM-YYYY")}
                            </td>
                            <td colSpan='2' className={tdClass}>
                              {(leave.leave_type_id == 1 ||
                                leave.leave_type_id == 5) && (
                                <>
                                  {leave_date.holiday == "0" &&
                                    leave_date.leave_type?.name}
                                </>
                              )}
                              {leave.leave_type_id == 2 && (
                                <>{leave_date.leave_type?.name}</>
                              )}
                              {leave.leave_type_id == 4 && (
                                <>{leave_date.leave_type?.name}</>
                              )}
                              {leave.leave_type_id == 6 && (
                                <>{leave_date.leave_type?.name}</>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className='flex items-center justify-center'>
                  {!showAllDates && leave?.leave_dates?.length > 5 && (
                    <button
                      className='text-sm text-blue-500 '
                      onClick={() => setShowAllDates(true)}
                    >
                      Show More
                    </button>
                  )}
                  {showAllDates && leave?.leave_dates?.length > 5 && (
                    <button
                      className='text-sm text-blue-500 '
                      onClick={() => setShowAllDates(false)}
                    >
                      Show Less
                    </button>
                  )}{" "}
                </div>
                <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />
                <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <table>
                    <tr>
                      <th className='table-head'>Have Station Leave?:</th>
                      <td className='table-data'>
                        {leave?.station_leave == 1 ? "Yes" : "No"}
                      </td>
                    </tr>
                    {leave?.station_leave == 1 && (
                      <>
                        <tr>
                          <th className='table-head'>From:</th>
                          <td className='table-data'>
                            {moment(leave.sl_from_date).format("DD-MM-YYYY")}{" "}
                            {leave.sl_from_time}
                          </td>
                        </tr>
                        <tr>
                          <th className='table-head'>To:</th>
                          <td className='table-data'>
                            {moment(leave.sl_to_date).format("DD-MM-YYYY")}{" "}
                            {leave.sl_to_time}
                          </td>
                        </tr>

                        <tr>
                          <th className='table-head'>
                            Address During Absence:
                          </th>
                          <td className='table-data'>{leave?.address}</td>
                        </tr>
                      </>
                    )}
                  </table>
                </div>
              </>
            )}
            <hr className='m-5 noPrint' />
            {leave?.cancelation_reason != null && leave?.status == -1 && (
              <>
                <div>
                  Leave Cancelled: <br />
                  Reason:{leave?.cancelation_reason}{" "}
                </div>
              </>
            )}
            <div className='noPrint'>
              {leave?.status != 2 &&
                leave?.status != -1 &&
                leave.cancelation_reason == null &&
                leave.staff_id == authData.user.id && (
                  <button
                    name='forward'
                    className='px-4 py-2 m-3 font-bold text-white bg-red-500 rounded hover:bg-red-700'
                    onClick={async (event) => {
                      event.target.disabled = true;
                      let reason = window.prompt(
                        "Are you sure? Please enter a reason"
                      );
                      if (reason == null || reason.trim() == "") {
                        return;
                      } else {
                        await requestCancelation(reason);
                      }
                      event.target.disabled = false;
                    }}
                  >
                    Request Cancellation
                  </button>
                )}
              {leave?.status != 2 &&
                leave?.status != -1 &&
                leave.cancelation_reason != null &&
                leave.staff_id == authData.user.id && (
                  <>
                    <div>
                      Cancellation Requested: <br />
                      Reason:{leave?.cancelation_reason}{" "}
                    </div>

                    <button
                      name='forward'
                      className='px-4 py-2 m-3 font-bold text-white bg-purple-500 rounded hover:bg-purple-700'
                      onClick={() => {
                        window.alert(
                          "Already Requested. \nReason: " +
                            leave?.cancelation_reason
                        );
                      }}
                    >
                      Cancellation Requested
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
