import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const AcademicVisit = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [academicVisits, setAcademicVisits] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    institution: "",
    // visit_date: "",
    visit_purpose: "",
    from_date: "",
    to_date: "",
  });
  const headers = {
    institution: { label: "Institution/Organization", path: "institution" },
    // visit_date: { label: "Date of Visit", path: "visit_date" },
    visit_purpose: { label: "Purpose of Visit", path: "visit_purpose" },
    from_date: { label: "From Date", path: "from_date" },
    to_date: { label: "To Date", path: "to_date" },
  };

  const validationSchema = Yup.object({
    institution: Yup.string().required("This field is required!"),
    // visit_date: Yup.date().required("This field is required!"),
    visit_purpose: Yup.string().required("This field is required!"),
    from_date: Yup.date().required("This field is required!"),
    to_date: Yup.date().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/academic-visits");
      setAcademicVisits(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/academic-visits/${editId}`, values);
        toast.success(`Academic Visit Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/academic-visits", values);
        toast.success(`Academic Visit Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = academicVisits.find(
        (academicVisit) => academicVisit.id === id
      );
      setInitialValues({
        id: editableData.id,
        institution: editableData.institution,
        // visit_date: editableData.visit_date,
        visit_purpose: editableData.visit_purpose,
        from_date: editableData.from_date,
        to_date: editableData.to_date,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      institution: "",
      // visit_date: "",
      visit_purpose: "",
      from_date: "",
      to_date: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      institution: "",
      // visit_date: "",
      visit_purpose: "",
      from_date: "",
      to_date: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/academic-visits/${id}`);
      toast.success(`Academic Visit Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Academic Visits :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={academicVisits}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default AcademicVisit;
