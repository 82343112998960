import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  startup_name: { label: "Name of the Startup", path: "startup_name" },
  date_started: { label: "Date Started", path: "date_started" },
  registration_details: {
    label: "Registration Details",
    path: "registration_details",
  },
  objective: { label: "Objective of the Startup", path: "objective" },
  details: { label: "Details of the Startup", path: "details" },
  role: { label: "Your Role in the Startup", path: "role" },
};

const IqacStartupDevelopment = () => {
  const [startupDevelopments, setStartupDevelopments] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/startup-developments?from_iqac=1"
      );
      setStartupDevelopments(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50">
        <h1>Startup: </h1>

        <div className="flex items-end justify-center space-x-2 capitalize text-md">
          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">From: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">To: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="">
            <Button
              type="filter"
              value="Filter"
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={startupDevelopments}
        type="startup-developments"
        setData={setStartupDevelopments}
      />
    </div>
  );
};

export default IqacStartupDevelopment;
