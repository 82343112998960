import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  title: {
    label: "Title of the Patent",
    path: "title",
  },
  inventors_creators: {
    label: "Inventors/Creators",
    path: "inventors_creators",
  },
  applicant_name: {
    label: "Applicant’s Name",
    path: "applicant_name",
  },
  application_number: {
    label: "Application Number",
    path: "application_number",
  },
  status: {
    label: "Status",
    path: "status",
  },
  patent_number: {
    label: "Patent Number",
    path: "patent_number",
  },
  patent_granted_date: {
    label: "Patent Granted Date",
    path: "patent_granted_date",
  },
  filling_country: {
    label: "Filing Country",
    path: "filling_country",
  },
  invention: {
    label: "Field of Invention",
    path: "invention",
  },
  filling_date: {
    label: "Filing Date",
    path: "filling_date",
  },
  published_date: {
    label: "Published Date",
    path: "published_date",
  },
};

const IqacPatent = () => {
  const [patents, setPatents] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/patents?from_iqac=1");
      setPatents(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50'>
        <h1>Patents: </h1>

        <div className='flex items-end justify-center space-x-2 capitalize text-md'>
          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>From: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>To: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className=''>
            <Button
              type='filter'
              value='Filter'
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={patents}
        type='patents'
        setData={setPatents}
      />
    </div>
  );
};

export default IqacPatent;
