import React, { useEffect, useRef, useState } from "react";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import can from "../../Middleware/Permission";

// import Select from "../../Components/Utils/Select";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
import { showApplicantAsync } from "../../Redux/ApplicantSlice";
import Alert from "../../Components/Utils/Alert";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";
import MakeAdmission from "./MakeAdmission";
import BaseApi from "../../Http/BaseApi";
import MakeHostelAdmission from "./MakeHostelAdmission";

export default function ApplicantSearch() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({ id: "" });
  const [applicant, setApplicant] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showHostelModal, setShowHostelModal] = useState(false);

  // const { message } = useSelector((state) => state.message);

  let errorMessage = useSelector((state) => state.applicants.errorMessage);

  // let formSections = useSelector((state) => state.applicants.formSections);

  //   const dispatch = useDispatch();
  useEffect(() => {
    setInitialValues({
      id: "",
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    id: Yup.string().required("This field is required!"),
  });

  const reloadApplicant = async () => {
    setLoading(true);

    await dispatch(showApplicantAsync({ id: applicant.id }))
      .unwrap()
      .then((value) => {
        setApplicant(value);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };
  const handleSearch = async (searchQuery) => {
    console.log(searchQuery);
    setLoading(true);
    setApplicant();
    await dispatch(showApplicantAsync(searchQuery))
      .unwrap()
      .then((value) => {
        let _hostels = value.student.hostels;
        _hostels = _hostels.filter((h) => h.pivot.out_date === null);

        const updatedApplicant = { ...value };
        const _student = { ...updatedApplicant.student };
        _student.hostels = _hostels;
        updatedApplicant.student = _student;
        setApplicant({ ...updatedApplicant });

        // setApplicant(value);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };
  const handleCancelAdmission = async (applicant) => {
    setLoading(true);
    await BaseApi().post(`cancel-admission`, { applicant_id: applicant.id });
    reloadApplicant();
    setLoading(false);
  };
  const handleCancelHostelAdmission = async (applicant) => {
    setLoading(true);
    await BaseApi().post(`cancel-hostel-admission`, {
      applicant_id: applicant.id,
    });
    reloadApplicant();
    setLoading(false);
  };

  useEffect(() => {
    // Get the URL search parameters
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNo = urlParams.get("application_no");

    // Set the application number in the state
    if (applicationNo) {
      setInitialValues({
        id: applicationNo,
      });

      handleSearch({ id: applicationNo });
    }
  }, []);

  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5 overflow-x-scroll min-h-screen '>
            <PageTitle name='Applicant Search' />
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className=' p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8'>
                  <Input
                    type='text'
                    name='id'
                    label='Search By Application Number'
                    placeholder='Enter Application Number'
                  />

                  <div className='text-left mt-5'>
                    <button
                      type='submit'
                      className='group relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <>
              {loading == true && (
                <>
                  <div className='flex'>
                    <span className='m-auto'>
                      <ThreeDots
                        height='100'
                        width='100'
                        color='grey'
                        ariaLabel='loading'
                      />
                    </span>
                  </div>
                </>
              )}
              {applicant != null && (
                <div className='container mx-auto my-5 p-5'>
                  <div className='md:flex no-wrap md:-mx-2 '>
                    <div className='w-full md:w-3/12 md:mx-2'>
                      <div className='bg-white p-3 border-t-4 border-green-400'>
                        <div className='image overflow-hidden'>
                          <img
                            className='h-auto w-full mx-auto'
                            src={applicant.student?.student_photo}
                            onError={(e) => {
                              console.log(e.target.src);
                              if (e.target.src != "/assets/no-photo.jpg")
                                e.target.src = "/assets/no-photo.jpg";
                            }}
                          />
                        </div>
                        <h1 className='text-gray-900 font-bold text-xl leading-8 my-1'>
                          {applicant.student?.name}
                        </h1>
                        <h3 className='text-gray-600 font-lg text-semibold leading-6'>
                          {applicant.programme?.department?.name}
                        </h3>

                        <ul className='bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm'>
                          <li className='flex items-center py-3'>
                            <span>Application No.</span>
                            <span className='ml-auto'>{applicant.id}</span>
                          </li>

                          <li className='flex items-center py-3'>
                            <span>Applied Date</span>
                            <span className='ml-auto'>
                              {moment(applicant.created_at).format(
                                "Do MMM YYYY"
                              )}
                            </span>
                          </li>
                          <li className='flex items-center py-3'>
                            <span>Applied Session</span>
                            <span className='ml-auto'>
                              {applicant.academic_session?.label}
                            </span>
                          </li>

                          {/* <li className="flex items-center py-3">
                    <span>ID</span>
                    <span className="ml-auto">{applicant.unique_id}</span>
                  </li> */}
                        </ul>
                        <div className='my-4'></div>
                      </div>
                    </div>

                    <div className='w-full md:w-9/12 mx-2 h-64'>
                      <div className='bg-white p-3 shadow-sm rounded-sm'>
                        <div className='w-full text-2xl mb-10'>
                          {applicant.form?.name}
                        </div>
                        <div className='flex items-center space-x-2 font-semibold text-gray-900 leading-8'>
                          {/* <span clas="text-green-500">
                    <svg
                      className="h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </span> */}
                          <span className='tracking-wide'>Basic Info</span>
                        </div>
                        <div className='text-gray-700'>
                          <div className='grid md:grid-cols-2 text-sm'>
                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Applicant Name
                              </div>
                              <div className='px-4 py-2'>
                                {applicant.student?.name}
                              </div>
                            </div>

                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Gender
                              </div>
                              <div className='px-4 py-2'>
                                {applicant.student?.gender}
                              </div>
                            </div>
                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Religion
                              </div>
                              <div className='px-4 py-2'>
                                {applicant.student?.religion}
                              </div>
                            </div>
                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Community
                              </div>
                              <div className='px-4 py-2'>
                                {applicant.student.hcaste?.name}
                              </div>
                            </div>
                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Date of Birth
                              </div>
                              <div className='px-4 py-2'>
                                {moment(applicant.student?.dob).format(
                                  "Do MMM YYYY"
                                )}
                              </div>
                            </div>

                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Mobile No.
                              </div>
                              <div className='px-4 py-2'>
                                {applicant.student?.phone}
                              </div>
                            </div>
                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Email
                              </div>
                              <div className='px-4 py-2 break-all'>
                                {applicant.student?.email}
                              </div>
                            </div>
                            <div className='grid grid-cols-2'>
                              <div className='px-4 py-2 font-semibold'>
                                Nationality
                              </div>
                              <div className='px-4 py-2 break-all'>
                                {applicant.student?.nationality}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='my-4'></div>

                      <div className='grid grid-cols-1'>
                        <div className='px-4 py-2 text-center'>
                          {can("Make Admission") &&
                            applicant.status == 0 &&
                            applicant.permitted == 0 &&
                            applicant.active == 0 && (
                              <Button
                                classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                                value={"Make Admission"}
                                icon={"CheckIcon"}
                                onClick={() => {
                                  setShowModal(true);
                                  // navigate(
                                  //   `/applicants/make-admission/${applicant.id}`
                                  // );
                                }}
                              />
                            )}

                          {can("Make Admission") &&
                            applicant.status == 0 &&
                            applicant.permitted == 2 &&
                            applicant.active == 0 && (
                              <>
                                <p>
                                  Payment Request Sent
                                  <Button
                                    classNames='outline-button-danger'
                                    value={"View Payment Requests"}
                                    icon={"EyeIcon"}
                                    type='button'
                                    onClick={() => {
                                      window.open(
                                        `/payments-pending?application_no_erp_id=${applicant.id}`,
                                        "_blank"
                                      );
                                    }}
                                  />
                                </p>
                              </>
                            )}

                          {can("Cancel Admission") && applicant.status == 1 && (
                            <Button
                              classNames='bg-red-600 hover:bg-red-700 focus:ring-red-500'
                              value={"Cancel Admission"}
                              onClick={() => {
                                if (window.confirm("Are you sure?") == true) {
                                  handleCancelAdmission(applicant);
                                } else {
                                  return;
                                }
                              }}
                              icon={"XIcon"}
                            />
                          )}

                          {can("Make Hostel Admission") &&
                            applicant.status == 1 &&
                            applicant.student.hostels.length == 0 && (
                              <Button
                                classNames='ml-10 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                                value={"Hostel Admission"}
                                onClick={() => {
                                  setShowHostelModal(true);
                                }}
                                icon={"CurrencyRupeeIcon"}
                              />
                            )}

                          {/* done */}
                          {/* {can("Make Hostel Admission") &&
                            applicant.status == 1 &&
                            applicant.student?.hostels?.length > 0 &&
                            applicant.student?.hostels[0]?.pivot.payment_id ==
                              null && (
                              <>
                                <p className="mt-5">
                                  Hostel Payment Request Sent
                                  <Button
                                    classNames="outline-button-danger"
                                    value={"View Payment Requests "}
                                    icon={"EyeIcon"}
                                    type="button"
                                    onClick={() => {
                                      navigate(`/payments-pending`);
                                    }}
                                  />
                                </p>
                              </>
                            )} */}
                          {/* done */}
                          {/* {can("Cancel Hostel Admission") &&
                            applicant.status == 1 &&
                            applicant.student?.hostels[0]?.pivot.payment_id !=
                              null && (
                              <Button
                                classNames="ml-10 bg-red-600 hover:bg-red-700 focus:ring-red-500"
                                value={"Cancel Hostel Admission"}
                                onClick={() => {
                                  if (window.confirm("Are you sure?") == true) {
                                    handleCancelHostelAdmission(applicant);
                                  } else {
                                    return;
                                  }
                                  // setShowHostelModal(true);
                                }}
                                icon={"XIcon"}
                              />
                            )} */}
                          {applicant.status == 1 &&
                            applicant.student.hostels?.map((hostel) => (
                              <>
                                {hostel.pivot.out_date == null &&
                                  hostel.pivot.payment_id != null && (
                                    <>
                                      <span
                                        className='ml-10 bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white rounded-full p-3 cursor-pointer'
                                        onClick={() => {
                                          if (hostel.pivot.in_date != null) {
                                            alert(
                                              "Unable to cancel hostel admission. In-Date Given."
                                            );
                                            return;
                                          }
                                          if (!can("Cancel Hostel Admission")) {
                                            alert("Unauthorisez Access!");
                                            return;
                                          }
                                          if (
                                            window.confirm("Are you sure?") ==
                                            true
                                          ) {
                                            handleCancelHostelAdmission(
                                              applicant
                                            );
                                          } else {
                                            return;
                                          }
                                          // setShowHostelModal(true);
                                        }}
                                      >
                                        Admitted to hostel {hostel.code}. Click
                                        to cancel hostel admission
                                      </span>{" "}
                                    </>
                                  )}

                                {can("Make Hostel Admission") &&
                                  hostel.pivot.out_date == null &&
                                  hostel.pivot.payment_id == null && (
                                    <>
                                      <span
                                        onClick={() => {
                                          window.open(
                                            `/payments-pending?application_no_erp_id=${applicant.id}`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Hostel Payment Request Sent for{" "}
                                        {hostel.code}{" "}
                                      </span>
                                    </>
                                  )}
                              </>
                            ))}
                        </div>
                      </div>

                      {showModal ? (
                        <MakeAdmission
                          onMakeAdmission={reloadApplicant}
                          applicant={applicant}
                          setShowModal={setShowModal}
                          loading={loading}
                        />
                      ) : null}

                      {showHostelModal ? (
                        <MakeHostelAdmission
                          onMakeHostelAdmission={reloadApplicant}
                          applicant={applicant}
                          setShowHostelModal={setShowHostelModal}
                          loading={loading}
                        />
                      ) : null}

                      <div className='my-4'></div>
                      <div className='my-4'></div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
}
