// detailFieldsConfig.js
export const EduQualificationFields = [
  { label: "Qualification Type", path: "qualification" },
  { label: "Qualification", path: "qualification" },
  { label: "Institute", path: "institute" },
  { label: "Subject", path: "subject" },
  { label: "Pass Year", path: "pass_year" },
];

export const BodiesOrganizationFields = [
  { label: "Name of the Professional Body", path: "name" },
  { label: "Member Type", path: "member_type" },
  { label: "Month of Joining", path: "monthName" },
  { label: "Year of Joining", path: "year" },
  { label: "Till Month", path: "tillMonthName" },
  { label: "Till Year", path: "till_year" },
];

export const CollaborationFields = [
  {
    label: "Name of the Institute/ Organization/Individual",
    path: "collaboration_name",
  },
  {
    label: "Type of Collaboration",
    path: "collaboration_type",
  },
  { label: "Other Collaboration", path: "other_collaboration" },
  { label: "From Date Collaboration", path: "from_date" },
  { label: "To Date Collaboration", path: "to_date" },
  {
    label: "Objective of the Collaboration",
    path: "collaboration_objective",
  },
  {
    label: "Level of the collaboration",
    path: "collaboration_details",
  },
];

export const CommitteeFields = [
  { label: "Name of the Committee", path: "name" },
  { label: "Member Type", path: "member_type" },
  { label: "Month of Membership", path: "monthName" },
  { label: "Year of Membership", path: "year" },
  { label: "Till Month", path: "tillMonthName" },
  { label: "Till Year", path: "till_year" },
];

export const AcademicVisitsFields = [
  { label: "Institution/Organization", path: "institution" },
  // { label: "Date of Visit", path: "visit_date" },
  { label: "Purpose of Visit", path: "visit_purpose" },
  { label: "From Date", path: "from_date" },
  { label: "To Date", path: "to_date" },
];

export const ConferenceSeminarFields = [
  { label: "Title of the Paper", path: "title" },
  { label: "Name of the Conference/Seminar", path: "conference_name" },
  { label: "Type of the Conference/Seminar", path: "conference_type" },
  { label: "Organizer", path: "organizer" },
  { label: "Start Date", path: "conference_start_date" },
  { label: "End Date", path: "conference_end_date" },
  { label: "Author Names", path: "author_names" },
  { label: "Published", path: "published" },
  { label: "Published URL", path: "published_url" },
  {
    label: "Title of the Conference Proceeding",
    path: "conference_proceeding_title",
  },
  { label: "Month of Publish", path: "monthName" },
  { label: "Year of Publish", path: "year" },
  { label: "Page No", path: "page_no" },
  { label: "Editor Name", path: "editor_name" },
  { label: "Publisher Name", path: "publisher_name" },
  { label: "Indexed In", path: "indexed_in" },
  { label: "ISSN/ISBN", path: "issn_isbn" },
];

export const ConsultancyExternalServicesFields = [
  { label: "Title", path: "name" },
  { label: "Organization Name", path: "organization_name" },
  { label: "Brief Description", path: "details" },
  { label: "Your Role in the Consultancy", path: "role" },
  { label: "Amount Earned through Consultancy", path: "amount" },
  { label: "Start Date", path: "start_date" },
  { label: "End Date", path: "end_date" },
];

export const CorporateLifeFields = [
  { label: "Title of the Publication", path: "name" },
  { label: "Nature of the Activity", path: "nature" },
  { label: "From Date", path: "from_date" },
  { label: "To Date", path: "to_date" },
  { label: "Place of Activity", path: "place" },
];

export const ExperienceFields = [
  { label: "Institute/Organization", path: "institute_organization" },
  { label: "Department", path: "department" },
  { label: "Organization Type", path: "organization_master.name" },
  { label: "Organization URL", path: "organization_url" },
  { label: "Designation", path: "designation" },
  { label: "Start Date", path: "start_date" },
  { label: "End Date", path: "end_date" },
];

export const ExpertiseFields = [
  { label: "Broad Area", path: "broad_area" },
  { label: "Specialization/Area of Research", path: ["specialization"] },
];

export const FIPActivitiesParticipationFields = [
  { label: "Name of the FDP", path: "fdp_name" },
  { label: "FDP Start date", path: "start_date" },
  { label: "FDP End date", path: "end_date" },
  { label: "Organizer", path: "organizer" },
  { label: "Mode of FDP", path: "mode" },
  { label: "Details of FDP", path: "details" },
];

export const HonorsAwardsFields = [
  { label: "Name of the Award", path: "name" },
  { label: "Award Type", path: "award_type" },
  { label: "Month of Award", path: "monthName" },
  { label: "Year of Award", path: "year" },
];

export const JournalPublicationFields = [
  { label: "Title of the Paper", path: "title" },
  { label: "Journal Name", path: "journal" },
  { label: "Journal Type", path: "journal_type" },
  { label: "ISSN", path: "issn" },
  { label: "Month of Publish", path: "monthName" },
  { label: "Year of Publish", path: "year" },
  {
    label: "Are you corresponding/principal author?",
    path: "corresponding_principal",
  },
  { label: "Funding Source", path: "funding_source" },
  { label: "Funding Source Details", path: "funding_source_details" },
  { label: "Author Names: (As per journal)", path: "author_names" },
  { label: "URL", path: "url" },
  { label: "DOI", path: "doi" },
  { label: "Volume", path: "volume" },
  { label: "Page No/Paper No", path: "page_no" },
  { label: "Editor Name", path: "editor_name" },
  { label: "Publisher Name", path: "publisher_name" },
  { label: "Indexed in", path: "indexed_in" },
  { label: "Index in others", path: "indexed_in_others" },
];

export const AuthoredBookFields = [
  {
    label: "Title of the Book",
    path: "title",
  },
  {
    label: "Publisher",
    path: "publisher",
  },
  {
    label: "Month of Publish",
    path: "monthName",
  },
  {
    label: "Year of Publish",
    path: "year",
  },
  {
    label: "Author Names: (As per Book)",
    path: "author_names",
  },
  {
    label: "ISBN",
    path: "isbn",
  },
  {
    label: "Book Type",
    path: "book_type",
  },
  {
    label: "Book Type Others",
    path: "book_type_others",
  },
  {
    label: "Linked In",
    path: "linked_in",
  },
];

export const BookChapterFields = [
  {
    label: "Title of the Book",
    path: "title",
  },
  {
    label: "Book Name",
    path: "book_name",
  },
  {
    label: "Book Type",
    path: "book_type",
  },
  {
    label: "Others",
    path: "book_type_others",
  },
  {
    label: "Publisher",
    path: "publisher",
  },
  {
    label: "Month of Publish",
    path: "monthName",
  },
  {
    label: "Year of Publish",
    path: "year",
  },
  {
    label: "Author Names: (As per Book)",
    path: "author_names",
  },
  {
    label: "ISBN",
    path: "isbn",
  },
  {
    label: "Editor(s)",
    path: "editors",
  },
];

export const BooksEditedFields = [
  {
    label: "Title of the Book",
    path: "title",
  },
  {
    label: "Publisher",
    path: "publisher",
  },
  {
    label: "Month of Publish",
    path: "monthName",
  },
  {
    label: "Year of Publish",
    path: "year",
  },
  {
    label: "Editor Names: (As per Book)",
    path: "editor_names",
  },
  {
    label: "ISBN",
    path: "isbn",
  },
  {
    label: "Book Type",
    path: "book_type",
  },
  {
    label: "Others",
    path: "book_type_others",
  },
  {
    label: "Indexed In",
    path: "indexed_in",
  },
];

export const LecturesTalkFields = [
  { label: "Title of the lecture/talk", path: "title" },
  { label: "Type of lecture/talk", path: "type" },
  { label: "Organization Name", path: "organization_name" },
  {
    label: "Name of the Workshop/Seminar/Occasion/Event",
    path: "workshop_name",
  },
  { label: "Date", path: "lecture_date" },
  { label: "Details", path: "lecture_details" },
];

export const MentorMenteeFields = [
  { label: "Number of Mentee Assigned", path: "mentee_assigned" },
  { label: "Details of the Mentee Assigned", path: "mentee_details" },
  { label: "Number of Meeting Held with Mentee", path: "meeting_held" },
  { label: "Date of Meetings", path: "meeting_date" },
  { label: "Resolution of Meeting", path: "meeting_resolution" },
];

export const PatentFields = [
  { label: "Title of the Patent", path: "title" },
  { label: "Patent Number", path: "patent_number" },
  { label: "Date of Patent", path: "date" },
  { label: "Place of Patent", path: "place" },
];

export const DesignFields = [
  {
    label: "Title of the Design",
    path: "title",
  },
  {
    label: "Creators",
    path: "creators",
  },
  {
    label: "Design Number",
    path: "design_number",
  },
  {
    label: "Registration Date",
    path: "registration_date",
  },
  {
    label: "Country",
    path: "country",
  },
];

export const PhdGuidanceFields = [
  { label: "Name of the Ph. D. Scholar", path: "researcher_name" },
  { label: "Role", path: "role" },
  { label: "Thesis Title", path: "thesis_title" },
  {
    label: "Ph. D. Scholar registered in DU/Other Institute (mention if other)",
  },
  { label: "Other Institute", path: "other_institute" },
];

export const ProjectFields = [
  { label: "Title of the Project", path: "title" },
  { label: "Project Type", path: "type" },
  { label: "Role", path: "role" },
  { label: "Funding Agency", path: "funding_agency" },
  { label: "Grant No.", path: "grant_no" },
  { label: "Amount Granted", path: "amount_granted" },
  { label: "Status", path: "status" },
  { label: "Start Date", path: "start_date" },
  { label: "End Date", path: "end_date" },
  { label: "Project related to", path: "project_related" },
  { label: " Is it a collaborative project?", path: "collaborative_project" },
  {
    label: "Details",
    path: "collaborative_project_details",
  },
  {
    label: "Number of Research Fellows under the Project",
    path: "research_fellow",
  },
];

export const StartupDevelopmentFields = [
  { label: "Name of the Startup", path: "startup_name" },
  { label: "Date Started", path: "date_started" },
  { label: "Registration Details", path: "registration_details" },
  { label: "Objective of the Startup", path: "objective" },
  { label: "Details of the Startup", path: "details" },
  { label: "Your Role in the Startup", path: "role" },
];

export const PresentResearchScholarFields = [
  {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  { label: "Role", path: "role" },
  { label: "Thesis Title", path: "thesis_title" },
  { label: "Status", path: "status" },
  {
    label: "Members of the Research Advisory Committee",
    path: "advisoryCommittee",
  },
];

export const PhdAwardeeFields = [
  {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  { label: "Role", path: "role" },
  { label: "Thesis Title", path: "thesis_title" },
  {
    label: "Ph. D. Scholar registered in DU/Other Institute",
    path: "institute",
  },
  { label: "Other Institute", path: "other_institute" },
  { label: "Status", path: "status" },
  {
    label: "Awarded Month",
    path: "monthName",
  },
  {
    label: "Awarded Year",
    path: "year",
  },
];

export const ExternalPhdScholarsFields = [
  {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  { label: "Role", path: "role" },
  { label: "Thesis Title", path: "thesis_title" },
  { label: "Name of the University/Institute", path: "other_institute" },
  { label: "Status", path: "status" },
];

export const ThesisExaminerUniversitiesFields = [
  {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  { label: "Title of the Thesis", path: "thesis_title" },
  { label: "Name of the University/Institute", path: "other_institute" },
  { label: "Month", path: "monthName" },
  { label: "Year", path: "year" },
];

export const ProgrammeOrganizedsFields = [
  { label: "Type of the Programme", path: "program_type" },
  {
    label: "Other Programme",
    path: "other_program_type",
  },
  { label: "Title of the Programme", path: "programme" },
  { label: "Role", path: "role" },
  { label: "From Date", path: "from_date" },
  { label: "To Date", path: "to_date" },
  {
    label: "Whether any proceeding published?",
    path: "published",
  },
  {
    label: "Title of the  Proceeding",
    path: "proceeding_title",
  },
  { label: "Month of Publish", path: "monthName" },
  { label: "Year of Publish", path: "year" },
  { label: "Editor Name with Affiliation", path: "editor_name" },
  { label: "ISSN/ISBN", path: "issn_isbn" },
  { label: "Publisher Name", path: "publisher_name" },
  { label: "Indexed In", path: "indexed_in" },
];
export const EContentDevelopmentsFields = [
  { label: "Type ", path: "type" },
  { label: "Others Type ", path: "others_type" },
  { label: "Number of Modules", path: "modules" },
  {
    label: "Number of Enrolled Students",
    path: " enrolled_student",
  },
  { label: "URL", path: "url" },
  { label: "Month of Publish", path: "monthName" },
  { label: "Year of Publish", path: "year" },
];
export const WorkshopTrainingsFields = [
  { label: "Brief Description", path: "description" },
  { label: " Organized by", path: "organized_by" },
  { label: "Held at", path: "held_at" },
  { label: "Your Role", path: "role" },
  { label: "Start Date", path: "start_date" },
  { label: "End Date", path: "end_date" },
];
