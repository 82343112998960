import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showApplicantAsync } from "../../Redux/ApplicantSlice";

// import * as Yup from "yup";
// import { OLD_STUDENTS_STATUS } from "./../../Enums/enums";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { getDistrictsAsync, getStatesAsync } from "../../Redux/MasterSlice";

export default function ApplicantShow() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [applicant, setApplicant] = useState();
  const applicantsSlice = useSelector((state) => state.applicants);
  const applicants = applicantsSlice.applicants;

  const master = useSelector((state) => state.master);

  const states = [...master.states];
  const districts = [...master.districts];

  //   const authData = useSelector((state) => state.auth);

  useEffect(() => {
    async function checkData() {
      if (id && id > 0) {
        // var singleApplicant = null;
        // for (let i = 0; i < applicants.length; i++) {
        //   singleApplicant = applicants[i].find((value) => {
        //     return value.id == id;
        //   });

        //   if (singleApplicant != null) {
        //     break;
        //   }
        // }

        // console.log(singleApplicant);
        // if (singleApplicant != null) {
        //   console.log("call1");
        //   setApplicant(singleApplicant);
        // } else {
        setLoading(true);

        console.log(" call 2");

        dispatch(showApplicantAsync({ id }))
          .unwrap()
          .then((value) => {
            setApplicant(value);
            setLoading(false);
          });
        // }
      }
    }
    checkData();
    if (states.length === 0) {
      dispatch(getStatesAsync());
    }
    if (districts.length === 0) {
      dispatch(getDistrictsAsync());
    }
  }, [applicants]);
  //   const checkerApprove = async () => {
  //     await dispatch(approveInvoiceAsync(invoice));
  //     await dispatch(showInvoiceAsync({ id }))
  //       .unwrap()
  //       .then((value) => {
  //         console.log();
  //         setInvoice(value);
  //       });
  //   };

  return (
    <>
      {loading == true && (
        <>
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        </>
      )}
      {applicant != null && (
        <div className='container p-5 mx-auto my-5'>
          <div className='md:flex no-wrap md:-mx-2 '>
            <div className='w-full md:w-3/12 md:mx-2'>
              <div className='p-3 bg-white border-t-4 border-green-400'>
                <div className='overflow-hidden image'>
                  <img
                    className='w-full h-auto mx-auto'
                    src={applicant.student?.student_photo}
                    onError={(e) => {
                      console.log(e);
                      if (e.target.src != "/assets/no-photo.jpg")
                        e.target.src = "/assets/no-photo.jpg";
                    }}
                  />
                </div>
                <h1 className='my-1 text-xl font-bold leading-8 text-gray-900'>
                  {applicant.student?.name}
                </h1>
                <h3 className='leading-6 text-gray-600 font-lg text-semibold'>
                  {applicant.programme?.department?.name}
                </h3>

                <ul className='px-3 py-2 mt-3 text-gray-600 bg-gray-100 divide-y rounded shadow-sm hover:text-gray-700 hover:shadow'>
                  <li className='flex items-center py-3'>
                    <span>Application No.</span>
                    <span className='ml-auto'>{applicant.id}</span>
                  </li>
                  {applicant.samarth_form_no && (
                    <li className='flex items-center py-3'>
                      <span>Samarth Form No.</span>
                      <span className='ml-auto'>
                        {applicant.samarth_form_no}
                      </span>
                    </li>
                  )}
                  <li className='flex items-center py-3'>
                    <span>Entrance. Roll No.</span>
                    <span className='ml-auto'>
                      {applicant.entrance_roll_no}
                    </span>
                  </li>
                  <li className='flex py-3 items-left'>
                    <span className=''>{applicant.exam_venue?.venue}</span>
                  </li>
                  <li className='flex items-center py-3'>
                    <span>Applied Date</span>
                    <span className='ml-auto'>
                      {moment(applicant.created_at).format("Do MMM YYYY")}
                    </span>
                  </li>
                  <li className='flex items-center py-3'>
                    <span>Applied Session</span>
                    <span className='ml-auto'>
                      {applicant.academic_session?.label}
                    </span>
                  </li>

                  {/* <li className="flex items-center py-3">
                    <span>ID</span>
                    <span className="ml-auto">{applicant.unique_id}</span>
                  </li> */}
                </ul>
                <div className='my-4'></div>
                <div className='overflow-hidden image'>
                  <img
                    className='w-full h-auto mx-auto'
                    src={applicant.student?.student_signature}
                    onError={(e) => {
                      console.log(e.target.src);
                      if (e.target.src != "/assets/no-sign.jpg")
                        e.target.src = "/assets/no-sign.jpg";
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='w-full h-64 mx-2 md:w-9/12'>
              <div className='p-3 bg-white rounded-sm shadow-sm'>
                <div className='w-full mb-10 text-2xl'>
                  {applicant.form?.name}
                </div>
                <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                  {/* <span clas="text-green-500">
                    <svg
                      className="h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </span> */}
                  <span className='tracking-wide'>Basic Info</span>
                </div>
                <div className='text-gray-700'>
                  <div className='grid text-sm md:grid-cols-2'>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Applicant Name
                      </div>
                      <div className='px-4 py-2'>{applicant.student?.name}</div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Gender</div>
                      <div className='px-4 py-2'>
                        {applicant.student?.gender}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Religion</div>
                      <div className='px-4 py-2'>
                        {applicant.student?.religion}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Caste</div>
                      <div className='px-4 py-2'>
                        {applicant.student.hcaste?.name}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Community</div>
                      <div className='px-4 py-2'>
                        {applicant.student?.community_id != null &&
                          applicant.student?.community_id != 9 && (
                            <>{applicant.student?.community?.name}</>
                          )}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Permanent Resident of Assam
                      </div>
                      <div className='px-4 py-2'>
                        {applicant.student?.domicile == 1 ? "Yes" : "No"}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Date of Birth
                      </div>
                      <div className='px-4 py-2'>
                        {moment(applicant.student?.dob).format("Do MMM YYYY")}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Mobile No.</div>
                      <div className='px-4 py-2'>
                        {applicant.student?.phone}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Email</div>
                      <div className='px-4 py-2 break-all'>
                        {applicant.student?.email}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Nationality</div>
                      <div className='px-4 py-2 break-all'>
                        {applicant.student?.nationality}
                      </div>
                    </div>

                    {/*                     
                    {student.admittedcustominfos.map((customInfo, index) => (
                      <div key={index} className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">
                          {customInfo.formfield?.name}
                        </div>
                        <div className="px-4 py-2 break-all">
                          {customInfo.data}
                        </div>
                      </div>
                    ))} */}
                  </div>
                </div>
                <div className='my-4'></div>
                <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                  <span className='tracking-wide'>Branch/Specialization</span>
                </div>
                <div className='text-gray-700'>
                  <div className='grid text-sm md:grid-cols-2'>
                    {applicant.subject_takens?.length > 0 &&
                      applicant.subject_takens.map((subjects, index) => (
                        <div key={index} className='grid-cols-2'>
                          <div className='px-4 py-2 '>
                            Pref {subjects.preference}-{subjects.subject.name}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='my-4'></div>
                <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                  <span className='tracking-wide'>Reservation</span>
                </div>
                <div className='text-gray-700'>
                  <div className='grid text-sm md:grid-cols-2'>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        {applicant.reservations?.length > 0 &&
                          applicant.reservations.map((reservation) => (
                            <> {reservation?.reservation.name}</>
                          ))}

                        {applicant.student?.domicile == 1 &&
                          applicant.student?.hcaste?.name &&
                          applicant.student?.hcaste?.name != "General" && (
                            <span
                              className='badge'
                              style={{ fontSize: ".875rem" }}
                            >
                              {applicant.student?.hcaste?.name}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='my-4'></div>
                {applicant.customstudentinfo?.length > 0 &&
                  applicant.customstudentinfo.map((extraSection) => {
                    if (extraSection.type < 1)
                      return (
                        <>
                          <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                            <span className='tracking-wide'>
                              {extraSection.title}
                            </span>
                          </div>
                          <div className='text-gray-700'>
                            <div className='grid text-sm md:grid-cols-2'>
                              {extraSection.fields?.length > 0 &&
                                extraSection.fields.map((field) => {
                                  console.log(field);
                                  //  if type of valus is undefined
                                  if (typeof field?.data?.data == "undefined") {
                                    return (
                                      <div className='grid grid-cols-2'>
                                        <div className='px-4 py-2 font-semibold'>
                                          {field?.name}
                                        </div>
                                        <div className='px-4 py-2 break-all'></div>
                                      </div>
                                    );
                                  }

                                  if (
                                    field?.input_type == "select" &&
                                    field?.input_data != null &&
                                    field?.name == "State"
                                  ) {
                                    return (
                                      <>
                                        <div className='grid grid-cols-2'>
                                          <div className='px-4 py-2 font-semibold'>
                                            {field?.name}
                                          </div>
                                          <div className='px-4 py-2 break-all'>
                                            {
                                              states?.find(
                                                (s) => s.id == field?.data?.data
                                              )?.name
                                            }
                                          </div>
                                        </div>
                                      </>
                                    );
                                  } else if (
                                    field?.input_type == "dependentSelect" &&
                                    field?.input_data != null &&
                                    field?.name == "District"
                                  ) {
                                    return (
                                      <>
                                        <div className='grid grid-cols-2'>
                                          <div className='px-4 py-2 font-semibold'>
                                            {field?.name}
                                          </div>
                                          <div className='px-4 py-2 break-all'>
                                            {
                                              districts?.find(
                                                (s) => s.id == field?.data?.data
                                              )?.name
                                            }
                                          </div>
                                        </div>
                                      </>
                                    );
                                  } else if (
                                    field?.input_type == "select" &&
                                    field?.input_datai != null
                                  ) {
                                    if (
                                      field?.data?.data &&
                                      field?.data?.data != null
                                    ) {
                                      let array =
                                        field?.input_datai?.split(",");

                                      let position = array.indexOf(
                                        field?.data?.data
                                      );

                                      let arrayv =
                                        field?.input_datav?.split(",");

                                      let value = arrayv[position];
                                      return (
                                        <>
                                          <div className='grid grid-cols-2'>
                                            <div className='px-4 py-2 font-semibold'>
                                              {field?.name}
                                            </div>
                                            <div className='px-4 py-2 break-all'>
                                              {value}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  } else {
                                    return (
                                      <>
                                        <div className='grid grid-cols-2'>
                                          <div className='px-4 py-2 font-semibold'>
                                            {field?.name}
                                          </div>
                                          <div className='px-4 py-2 break-all'>
                                            {field?.data?.data}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </>
                      );
                  })}
              </div>
              <div className='my-4'></div>
              {applicant.entrance_exams?.length > 0 && (
                <>
                  <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                    <span className='tracking-wide'>
                      Entrance Examination Details
                    </span>
                  </div>
                  <table className='w-full text-sm text-left '>
                    <thead>
                      <tr>
                        <th scope='col' className='p-2 border'>
                          Examination{" "}
                        </th>
                        <th scope='col' className='p-2 border'>
                          Roll No.
                        </th>
                        <th scope='col' className='p-2 border'>
                          Score/Percentile
                        </th>
                        <th scope='col' className='p-2 border'>
                          Rank
                        </th>
                        <th scope='col' className='p-2 border'>
                          Year
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicant.entrance_exams?.length > 0 &&
                        applicant.entrance_exams.map((exam) => {
                          return (
                            <>
                              <tr className='bg-white odd:bg-white even:bg-gray-50 '>
                                <td className='p-2 border'>
                                  {exam.examination}
                                </td>
                                <td className='p-2 border'>{exam.roll_no}</td>
                                <td className='p-2 border'>{exam.score}</td>
                                <td className='p-2 border'>{exam.rank}</td>
                                <td className='p-2 border'>{exam.year}</td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </>
              )}
              {applicant.previous_exams?.length > 0 && (
                <>
                  <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                    <span className='tracking-wide'>Academic Details(A)</span>
                  </div>
                  <table className='w-full text-sm text-left '>
                    <thead>
                      <tr>
                        <th scope='col' className='p-2 border'>
                          Examination
                        </th>
                        <th scope='col' className='p-2 border'>
                          Board
                        </th>
                        <th scope='col' className='p-2 border'>
                          Roll No.
                        </th>
                        <th scope='col' className='p-2 border'>
                          Passing Year
                        </th>
                        <th scope='col' className='p-2 border'>
                          Subjects
                        </th>
                        <th scope='col' className='p-2 border'>
                          Division
                        </th>

                        <th scope='col' className='p-2 border'>
                          Percentage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicant.previous_exams?.length > 0 &&
                        applicant.previous_exams.map((exam) => {
                          return (
                            <>
                              <tr className='bg-white odd:bg-white even:bg-gray-50 '>
                                <td className='p-2 border'>
                                  {exam.examination}
                                </td>
                                <td className='p-2 border'>{exam.board}</td>
                                <td className='p-2 border'>{exam.mode}</td>
                                <td className='p-2 border'>
                                  {exam.yearofpassing}
                                </td>
                                <td className='p-2 border'>{exam.subjects}</td>
                                <td className='p-2 border'>{exam.division}</td>

                                <td className='p-2 border'>
                                  {exam.percentage}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </>
              )}
              <div className='my-4'></div>
              {applicant.extra_exams?.length > 0 &&
                applicant.extra_exams.map((exam) => (
                  <>
                    <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                      <span className='tracking-wide'>{exam.examination}</span>
                    </div>
                    <table className='w-full text-sm text-left '>
                      {exam.subject1 && (
                        <tr>
                          <td className='p-2 border'>
                            Subject- {exam.subject1}
                          </td>
                          <td className='p-2 border'>
                            Marks- {exam.sub1markssecured}
                          </td>
                          <td className='p-2 border'>
                            Out of- {exam.sub1outof}
                          </td>
                        </tr>
                      )}
                      {exam.subject2 && (
                        <tr>
                          <td className='p-2 border'>
                            Subject- {exam.subject2}
                          </td>
                          <td className='p-2 border'>
                            Marks- {exam.sub2markssecured}
                          </td>
                          <td className='p-2 border'>
                            Out of- {exam.sub2outof}
                          </td>
                        </tr>
                      )}

                      {exam.subject3 && (
                        <tr>
                          <td className='p-2 border'>
                            Subject- {exam.subject3}
                          </td>
                          <td className='p-2 border'>
                            Marks- {exam.sub3markssecured}
                          </td>
                          <td className='p-2 border'>
                            Out of- {exam.sub3outof}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className='p-2 border' colSpan='3'>
                          Percentage of Given Subjects - {exam.totalpercent} %{" "}
                        </td>
                      </tr>
                    </table>
                  </>
                ))}
              <div className='my-4'></div>
              {applicant.customstudentinfo?.length > 0 &&
                applicant.customstudentinfo.map((extraSection) => {
                  if (extraSection.type == 2)
                    return (
                      <>
                        <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                          <span className='tracking-wide'>
                            Documents Uploaded
                          </span>
                        </div>
                        <div className='text-gray-700'>
                          <table className='w-full text-sm text-left '>
                            <thead>
                              <tr>
                                <th className='p-2 border' scope='col'>
                                  Sl. No.{" "}
                                </th>
                                <th
                                  className='p-2 border'
                                  scope='col'
                                  colSpan='2'
                                >
                                  Document
                                </th>
                                <th
                                  className='p-2 border screen-only'
                                  scope='col'
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicant.student_documents.map(
                                (document, index) => (
                                  <>
                                    <tr>
                                      <td className='p-2 border'>{++index}</td>
                                      <td className='p-2 border'>
                                        {document.document_one?.name}
                                      </td>
                                      <td className='p-2 border'>
                                        <span className='badge badge-light'>
                                          Uploaded
                                        </span>
                                      </td>
                                      <td className='border p-2screen-only'>
                                        <a
                                          target='_blank'
                                          href={document.doc}
                                          className='btn btn-primary btn-sm'
                                          rel='noreferrer'
                                        >
                                          View
                                        </a>
                                      </td>
                                    </tr>
                                  </>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                })}
              <div className='my-4'></div>
              <div className='my-4'></div>
              ----------------------------------------------
            </div>
          </div>
        </div>
      )}
    </>
  );
}
