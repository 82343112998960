import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/Common/PageTitle";
import Badge from "../../Components/Utils/Badge";
import Button from "../../Components/Utils/Button";
import BaseApi from "../../Http/BaseApi";

export default function StaffFtDepartments() {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [staff, setStaff] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputDepartment, setSearchInputDepartment] = useState("");

  useEffect(() => {
    getDepartmentsAsync();

    getStaffAsync();

    setFormFields({
      staff_ids: [],
      department_ids: [],
    });
  }, [dispatch]);
  const getDepartmentsAsync = async () => {
    await BaseApi()
      .get(`/ft-departments`)
      .then((data) => {
        setDepartments(data.data);
      });
  };

  const getStaffAsync = async () => {
    await BaseApi()
      .get(`staff`, {})
      .then((data) => {
        setStaff(data.data);
      });
  };

  const handleUserInputChange = (event) => {
    const target = event.target;
    var value = target.value;
    console.log(target.checked);
    if (target.checked) {
      let _formFields = { ...formFields };

      _formFields.staff_ids.push(value);
      setFormFields(_formFields);
    } else {
      let _formFields = { ...formFields };
      var index = _formFields.staff_ids.indexOf(value);
      if (index !== -1) {
        _formFields.staff_ids.splice(index, 1);
      }
      setFormFields(_formFields);
    }
    console.log(" a " + formFields.staff_ids.includes(value) + " ");
  };
  const handleDepartmentInputChange = (event) => {
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      let _formFields = { ...formFields };
      _formFields.department_ids.push(value);
      setFormFields(_formFields);
    } else {
      let _formFields = { ...formFields };
      var index = _formFields.department_ids.indexOf(value);
      if (index !== -1) {
        _formFields.department_ids.splice(index, 1);
      }
      setFormFields(_formFields);
    }
    console.log(formFields);
  };

  const saveData = async (e) => {
    e.preventDefault();

    await BaseApi().post(` /staff-ft-departments`, formFields);
    setFormFields({
      staff_ids: [],
      department_ids: [],
    });
    getStaffAsync();
  };

  const removeDepartment = async (staff_id, departmentId) => {
    let payload = {
      staff_id: staff_id,
      department_id: departmentId,
    };
    console.log(payload);
    await BaseApi().post(` /remove-staff-ft-departments`, payload);
    getStaffAsync();
  };
  return (
    <>
      <PageTitle name='Assign File Tracking Department/Branch/Office to User' />
      <hr />
      <br />
      <div className='mt-10 shadow-2xl sm:mt-0 '>
        <div className=''>
          <div className='overflow-hidden shadow sm:rounded-md'>
            <div className='p-10 overflow-hidden border-b border-gray-200 shadow sm:rounded-lg'>
              <div className='grid grid-cols-2 gap-4'>
                <div className=''>
                  <h3>User List</h3>

                  {staff?.length > 0 && (
                    <>
                      <input
                        className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mt-2
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                        placeholder='Search...'
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </>
                  )}

                  <div className='flex justify-center mt-5'>
                    <ul className='w-full text-gray-900 bg-white border border-gray-200 rounded-lg'>
                      {staff
                        .filter((s) => {
                          if (searchInput.length > 0) {
                            return (
                              s.name
                                .toString()
                                .toLowerCase()
                                .indexOf(searchInput.toLowerCase()) > -1
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((user) => {
                          return (
                            <li
                              key={user.id}
                              className='w-full px-6 py-2 border-b border-gray-200 rounded-t-lg'
                            >
                              <input
                                className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                type='checkbox'
                                checked={formFields.staff_ids.includes(
                                  user.id.toString()
                                )}
                                value={user.id}
                                onChange={(event) =>
                                  handleUserInputChange(event)
                                }
                                id={"user-id" + user.id}
                              />
                              <label
                                className='inline-block text-gray-800 form-check-label'
                                htmlFor={"user-id" + user.id}
                              >
                                {user.name}
                              </label>
                              <hr />
                              <div className='grid grid-cols-3 gap-2'>
                                {user.ft_departments.map((department) => {
                                  return (
                                    <div key={department.id}>
                                      <Badge
                                        status={department.name}
                                        icon='XIcon'
                                        iconClick={() => {
                                          removeDepartment(
                                            user.id,
                                            department.id
                                          );
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className=''>
                  <h3>Department/Branch/Offices</h3>
                  {departments?.length > 0 && (
                    <>
                      <input
                        className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mt-2
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                        placeholder='Search...'
                        onChange={(e) =>
                          setSearchInputDepartment(e.target.value)
                        }
                      />
                    </>
                  )}
                  <div className='flex justify-center mt-5'>
                    <ul className='w-full text-gray-900 bg-white border border-gray-200 rounded-lg'>
                      {departments
                        .filter((p) => {
                          if (searchInputDepartment.length > 0) {
                            return (
                              p.name
                                .toString()
                                .toLowerCase()
                                .indexOf(searchInputDepartment.toLowerCase()) >
                              -1
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((department) => {
                          return (
                            <li
                              key={department.id}
                              className='w-full px-6 py-2 border-b border-gray-200 rounded-t-lg'
                            >
                              <input
                                className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                type='checkbox'
                                checked={formFields.department_ids.includes(
                                  department.id.toString()
                                )}
                                value={department.id}
                                onChange={(event) =>
                                  handleDepartmentInputChange(event)
                                }
                                id={"department-id" + department.id}
                              />
                              <label
                                className='inline-block text-gray-800 form-check-label'
                                htmlFor={"department-id" + department.id}
                              >
                                {department.name}
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className='col-span-2 '>
                  <Button value='Save' icon={"SaveIcon"} onClick={saveData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
