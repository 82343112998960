import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useState,useEffect } from 'react/cjs/react.development';
import { useDispatch, useSelector } from "react-redux";
// import { postLoginAsync } from "../../../Redux/AuthSlice";
import { Formik, Form } from "formik";
// const formikRef = useRef();
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
// import messageSlice from "../../../Redux/MessageSlice";
import BaseApi from "../../../Http/BaseApi";
import { toast } from "react-toastify";
// import axios from "axios";

export default function ForgetPassword() {
  const [formRole, setFormRole] = useState("forget");

  const [initialValues, setInitialValues] = useState();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(message);
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    if (isLoggedIn) {
      console.log("sd");
      navigate("/dashboard");
      window.location.reload();
    }
    setInitialValues({
      username: "",
      otp: "",
      new_password: "",
      confirm_password: "",
      panel: "staff",
    });

    // setIsOtpSent(true);
    // setIsOtpVerified(true);
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    if (formRole === "forget") {
      try {
        const response = await BaseApi(payload.panel).post(
          "/forgot-password",
          payload
        );
        var step = response.data?.step;

        if (step == 1) {
          setIsOtpSent(true);
        }
        if (step == 2) {
          setIsOtpSent(true);
          setIsOtpVerified(true);
          // setInitialValues({
          //   username: "",
          //   otp: "",
          //   new_password:"",
          //   confirm_password:"",
          //   panel: "staff",

          // });
        }
        if (step == 3) {
          setIsOtpSent(false);
          setIsOtpVerified(false);
          setInitialValues({
            username: "",
            otp: "",
            new_password: "",
            confirm_password: "",
            panel: "staff",
          });
          navigate(`/`);
        }

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      }
    }

    setLoading(false);
    setFormRole("forget");
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8  mt-10">
        <div className="max-w-md w-full space-y-8 shadow-2xl p-10 rounded-lg">
          <div>
            <img
              className="mx-auto h-12 w-auto rounded-full"
              src="https://erp.dibru.work/images/dibru.png"
              alt="Logo"
            />

            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-600">
              Dibrugarh University ERP
            </h2>
            <h2 className="mt-1 text-center text-xl font-medium text-gray-600">
              Password Reset
            </h2>
          </div>
          {message && <Alert message={message} />}

          {initialValues && (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="  -space-y-px">
                  <div className="">
                    {isOtpSent != true && (
                      <Input
                        readOnly={isOtpSent ? true : false}
                        showInput={isOtpSent ? "show" : "hide"}
                        label="Registered Email/Contact Number"
                        name="username"
                        placeholder="Enter your registered Email ID or Contact Number"
                      />
                    )}
                  </div>
                </div>
                {isOtpSent == true && (
                  <>
                    <div className="  -space-y-px">
                      <div className="">
                        {isOtpVerified != true && (
                          <Input
                            showInput={isOtpSent ? "show" : "hide"}
                            readOnly={isOtpVerified ? true : false}
                            label="OTP"
                            name="otp"
                            type="number"
                            placeholder="Enter the OTP"
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {isOtpVerified == true && (
                  <>
                    <div className="  -space-y-px">
                      <div className="">
                        <Input
                          label="New Password"
                          type="password"
                          name="new_password"
                          placeholder="New Password"
                        />
                      </div>
                    </div>

                    <div className="  -space-y-px">
                      <div className="">
                        <Input
                          type="password"
                          label="Confirm Password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="text-left mt-5">
                  {formRole === "add" ? (
                    <Button
                      value={loading ? "Saving..." : "Save"}
                      icon={"SaveIcon"}
                      type="submit"
                    />
                  ) : (
                    <>
                      <Button
                        value={loading ? "Submitting..." : "Submit"}
                        icon={"SaveIcon"}
                        type="submit"
                      />
                      <span className="mr-5"></span>
                    </>
                  )}
                </div>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </>
  );
}
