import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import PageTitle from "../../../Components/Common/PageTitle";
import Alert from "../../../Components/Utils/Alert";
import BaseApi from "../../../Http/BaseApi";
// import Select from "../../Components/Utils/Select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { YearSemesters } from "../../../Constants/YearSemesters";
import {
  getAcademicSessionsAsync,
  getPaymentGroupsAsync,
  getProgrammesAsync,
} from "../../../Redux/MasterSlice";
import Button from "../../../Components/Utils/Button";
import tableToCSV from "../../../Components/Utils/TableToCSV";
// import Input from "../../../Components/Utils/Input";

import can from "../../../Middleware/Permission";
import messageSlice from "../../../Redux/MessageSlice";
import PaymentGroupShow from "../PaymentGroup/PaymentGroupShow";
// import Input from "../../../Components/Utils/Input";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function PaymentGroupProgrammeIndex() {
  const formikRef2 = useRef();

  const fk2_prog_reference = useRef();
  const fk2_aca_reference = useRef();
  const fk2_pay_reference = useRef();
  const fk2_sem_reference = useRef();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [paymentGroupProgrammes, setPaymentGroupProgrammes] = useState();
  const [selectedPaymentGroupProgramme, setSelectedPaymentGroupProgramme] =
    useState();
  // const [selectedPayment, setSelectedPayment] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [formRole, setFormRole] = useState("add");
  const [formValues, setFormValues] = useState();
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const paymentGroups = [...master.paymentGroups];
  const academicSessions = [...master.academicSessions];
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  // let formSections = useSelector((state) => state.applicants.formSections);

  useEffect(() => {
    setFormValues({
      id: null,
      programme_id: null,
      payment_group_id: "",
      academic_session_id: "",
      semester: "",
    });
    // callPaymentGroupProgrammes();
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (paymentGroups.length === 0) {
      dispatch(getPaymentGroupsAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const formValidationSchema = Yup.object().shape({
    payment_group_id: Yup.string()
      .nullable()
      .required("This field is required!"),
    programme_id: Yup.string().nullable().required("This field is required!"),
    semester: Yup.string().nullable().required("This field is required!"),
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });

  //   const dispatch = useDispatch();

  async function callPaymentGroupProgrammes(searchInput) {
    console.log("as");
    setLoading(true);
    const _paymentGroupProgrammes = await BaseApi().get(
      `payment-group-programmes`,
      { params: searchInput }
    );
    setLoading(false);
    setPaymentGroupProgrammes(_paymentGroupProgrammes.data);
  }

  const handleDelete = async (event, payload) => {
    console.log(payload);
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`payment-group-programmes/${payload.id}`)
        .then(() => {
          let _paymentGroupProgrammes = paymentGroupProgrammes.filter(
            (p) => p.id != payload.id
          );
          console.log(_paymentGroupProgrammes);
          setPaymentGroupProgrammes(_paymentGroupProgrammes);
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        });
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  const handleSubmit = async (payload) => {
    dispatch(messageSlice.actions.clearMessage());
    setSaving(true);
    console.log(formRole);
    if (formRole === "edit") {
      await BaseApi()
        .post(`payment-group-programmes/${payload.id}`, payload)
        .then((p) => {
          let _paymentGroupProgrammes = [..._paymentGroupProgrammes];
          const index = _paymentGroupProgrammes.findIndex(
            (s) => s.id === p.data.id
          );
          _paymentGroupProgrammes[index] = p.data;
          setPaymentGroupProgrammes(_paymentGroupProgrammes);
        });

      // await dispatch(updatePaymentGroupAsync(payload)).unwrap();
    } else {
      await BaseApi()
        .post(`payment-group-programmes`, payload)
        .then(() => {
          callPaymentGroupProgrammes();

          // let _paymentGroupProgrammes = [...paymentGroupProgrammes];
          // _paymentGroupProgrammes.push(p.data);

          // setPaymentGroupProgrammes(_paymentGroupProgrammes);
        });
      // await dispatch(addPaymentGroupAsync(payload)).unwrap();
    }

    setSaving(false);
    setFormRole("add");
    // resetForm();

    // setFormValues({
    //   id: null,
    //   programme_id: "",
    //   payment_group_id: "",
    //   academic_session_id: "",
    //   semester: "",
    // });

    // fk2_prog_reference.current.clearValue();
    // fk2_aca_reference.current.clearValue();
    // fk2_pay_reference.current.clearValue();
    // fk2_sem_reference.current.clearValue();
  };

  // const onEdit = (event, paymentGroupProgramme) => {
  //   event.preventDefault();
  //   console.log(paymentGroupProgramme);
  //   // formikRef2.current?.setFieldValue("programme_id", 59);
  //   setFormValues({
  //     id: paymentGroupProgramme.id,
  //     programme_id: paymentGroupProgramme.programme_id,
  //     payment_group_id: paymentGroupProgramme.payment_group_id,
  //     academic_session_id: paymentGroupProgramme.academic_session_id,
  //     semester: paymentGroupProgramme.semester,
  //   });
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setFormRole("edit");
  // };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  //   const handleDeletePayment = async (payment) => {
  //     await BaseApi().delete(`paymentGroupProgrammes/${payment.id}`, {
  //       params: { remove_admission_permission: 1 },
  //     });
  //     let newPaymentGroupProgrammes = paymentGroupProgrammes.filter((e) => e.id != payment.id);
  //     setPaymentGroupProgrammes(newPaymentGroupProgrammes);
  //   };
  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='List of Payment Receipts' />
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            <Formik
              enableReinitialize={true}
              innerRef={formikRef2}
              initialValues={formValues}
              validationSchema={formValidationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <h3 className='text-lg font-medium'>Add New Record</h3>
                <table
                  id='datatable'
                  className='min-w-full mt-0 bg-white dark:bg-gray-800'
                >
                  <tr>
                    <th className='table-head w-80'>
                      <CustomSelect
                        reference={fk2_prog_reference}
                        options={programmes}
                        name='programme_id'
                        label='Select Programme'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name} `}
                        onChange={(selectedOption) => {
                          console.log("changed");
                          console.log(selectedOption);
                          if (selectedOption != null) {
                            formikRef2.current?.setFieldValue(
                              "programme_id",
                              selectedOption.id
                            );
                          }
                        }}
                      />
                    </th>
                    <th className='table-head'>
                      <CustomSelect
                        reference={fk2_aca_reference}
                        options={academicSessions}
                        name='academic_session_id'
                        label='Select Academic Session'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          if (selectedOption != null) {
                            formikRef2.current?.setFieldValue(
                              "academic_session_id",
                              selectedOption.id
                            );
                          }
                        }}
                      />
                    </th>
                    <th className='table-head'>
                      <CustomSelect
                        reference={fk2_sem_reference}
                        options={YearSemesters}
                        name='semester'
                        label='Select Semester'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          if (selectedOption != null) {
                            formikRef2.current?.setFieldValue(
                              "semester",
                              selectedOption.value
                            );
                          }
                        }}
                      />
                    </th>
                    <th className='table-head w-80'>
                      <CustomSelect
                        reference={fk2_pay_reference}
                        options={paymentGroups}
                        name='payment_group_id'
                        isSearchable={true}
                        label='Select Payment Group}'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          if (selectedOption != null) {
                            formikRef2.current?.setFieldValue(
                              "payment_group_id",
                              selectedOption.id
                            );
                          }
                        }}
                      />
                    </th>
                    <th className='w-32 table-head'>
                      <div className='mt-5 text-left'>
                        {formRole === "add" ? (
                          <Button
                            value={saving ? "Saving..." : "Save"}
                            icon={"SaveIcon"}
                            type='submit'
                          />
                        ) : (
                          <Button
                            value={saving ? "Updating..." : "Update"}
                            icon={"SaveIcon"}
                            type='submit'
                          />
                        )}
                      </div>
                    </th>
                  </tr>
                </table>
              </Form>
            </Formik>
            <h3 className='mt-5 text-lg font-medium'>Select Session</h3>
            <div className='space-x-2'>
              {academicSessions.length > 0 &&
                academicSessions.map((aca) => (
                  <>
                    <button
                      onClick={() => {
                        setSelectedSessionId(aca.id);
                        callPaymentGroupProgrammes({
                          academic_session_id: aca.id,
                        });
                      }}
                      className={`inline-block px-3 py-1 text-sm font-semibold rounded-full cursor-pointer ${
                        selectedSessionId === aca.id
                          ? "bg-green-500 hover:bg-green-600 text-white" // Highlight style for selected badge
                          : "bg-gray-500 hover:bg-gray-600 text-white" // Default style
                      }`}
                    >
                      {aca.label}
                    </button>
                  </>
                ))}
            </div>

            {paymentGroupProgrammes?.length > 0 && (
              <>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='inline-block m-0 text-gray-700 form-label'
                >
                  Search By Programme Name
                </label>
                <input
                  className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                  placeholder='Type here...'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Session</th>
                  <th className='table-head'>Semester</th>
                  <th className='table-head'>Group Name &amp; ID</th>
                  <th className='table-head'>Total Amount</th>

                  <th className='table-head' colSpan='3'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentGroupProgrammes?.length > 0 &&
                  paymentGroupProgrammes
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.programme.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((payment, index) => {
                      return (
                        <>
                          <tr>
                            <td className='table-data'>{++index}</td>

                            <td className='table-data'>
                              {payment.programme?.name}
                            </td>
                            <td className='table-data'>
                              {payment.academic_session?.label}
                            </td>
                            <td className='table-data'>{payment.semester}</td>
                            <td className='table-data'>
                              {payment.payment_group?.name} -{" "}
                              {payment.payment_group?.id}
                            </td>
                            <td className='table-data'>
                              {payment.payment_group?.payment_group_heads?.reduce(
                                (partialSum, heads) =>
                                  partialSum + (parseInt(heads.amount) || 0),
                                0
                              )}
                            </td>

                            <td className='table-data'>
                              <a
                                target='_blank'
                                onClick={() => {
                                  setSelectedPaymentGroupProgramme(payment);
                                  setShowModal(true);
                                  // navigate(
                                  //   `/applicants/make-admission/${applicant.id}`
                                  // );
                                }}
                                className=''
                                rel='noreferrer'
                              >
                                View
                              </a>
                            </td>
                            <td className='table-data'>
                              {/* <button
                                className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                onClick={(event) => onEdit(event, payment)}
                              >
                                Edit
                              </button> */}
                            </td>

                            <td className='table-data'>
                              {can("Account Masters") &&
                                (deleteConfirm.state == 0 ||
                                  deleteConfirm.itemId != payment.id) && (
                                  <button
                                    className='px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700'
                                    onClick={() =>
                                      setDeleteConfirm({
                                        itemId: payment.id,
                                        state: 1,
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                )}
                              {deleteConfirm.state == 1 &&
                                deleteConfirm.itemId == payment.id && (
                                  <>
                                    <p className='text-red-700'>
                                      {" "}
                                      Are You Sure?{" "}
                                    </p>

                                    <button
                                      className='outline-button-danger'
                                      onClick={(event) =>
                                        handleDelete(event, payment)
                                      }
                                    >
                                      Yes
                                    </button>
                                    <span className='mr-2'></span>
                                    <button
                                      className='outline-button-success'
                                      onClick={() => setDeleteConfirm({})}
                                    >
                                      No
                                    </button>
                                  </>
                                )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {paymentGroupProgrammes?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>

      {showModal ? (
        <PaymentGroupShow
          paymentGroup={selectedPaymentGroupProgramme.payment_group}
          setShowModal={setShowModal}
        />
      ) : null}
    </>
  );
}
