import React, { Fragment, useEffect, useRef, useState } from "react";
import Button from "../../Components/Utils/Button";
import { Link, useParams } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";
import { toast } from "react-toastify";
import moment from "moment";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Input from "../../Components/Utils/Input";
import * as Yup from "yup"; // For validation
import { Form, Formik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import JsBarcode from "jsbarcode";
import { MdCheck } from "react-icons/md";

const FileDetails = () => {
  const { id } = useParams();
  const [fileDetails, setFileDetails] = useState({ movements: [] });
  const [departments, setDepartments] = useState([]);
  const formikRef = useRef();
  const formikRefAccept = useRef();
  const barcodeRef = useRef(null); // Ref to hold barcode SVG

  const [showForwardModal, setShowForwardModal] = useState(false); // Modal visibility state
  const [showAcceptModal, setShowAcceptModal] = useState(false); // Modal visibility state
  const [selectedMovement, setSelectedMovement] = useState(null);
  const openAcceptModal = () => setShowAcceptModal(true);
  const closeAcceptModal = () => setShowAcceptModal(false);

  const openForwardModal = () => setShowForwardModal(true);
  const closeForwardModal = () => setShowForwardModal(false);

  useEffect(() => {
    getData();
  }, [id]);
  const getData = async () => {
    try {
      const response = await BaseApi().get(`/ft-files/${id}`);

      setFileDetails(response.data);

      const departmentResponse = await BaseApi().get("/ft-departments");
      setDepartments(departmentResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };
  // Validation schema using Yup
  const validationSchemaAcceptForm = Yup.object().shape({
    accepted_at: Yup.string().required("Date is required"),
  });

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Date is required"),
    to_department_id: Yup.string().required("Department is required"),
    remarks: Yup.string().required("Remarks are required"),
  });

  const handleForwardSubmit = async (payload) => {
    // confirm alert
    if (!window.confirm("Are you sure you want to forward this file?")) {
      return;
    }
    try {
      const response = await BaseApi().post(`/ft-files/${id}/forward`, payload);
      toast.success("File forwarded successfully");
      closeForwardModal();
      getData();
    } catch (error) {
      toast.error("Error forwarding the file");
      console.error(error);
    }
  };

  const handleAcceptFile = async (payload) => {
    // confirm alert
    if (!window.confirm("Are you sure you want to receive this file?")) {
      return;
    }
    try {
      const response = await BaseApi().post(
        `/ft-files/${selectedMovement.id}/accept`,
        payload
      );
      toast.success("File received successfully");
      closeAcceptModal();
      getData();
    } catch (error) {
      toast.error("Error receiving the file");
      console.error(error);
    }
  };

  // Generate Barcode and Download as PNG
  const downloadBarcode = () => {
    const svgElement = barcodeRef.current;

    if (!svgElement) {
      toast.error("Error generating barcode");
      return;
    }

    // Convert SVG to canvas for download
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions equal to the SVG's
    canvas.width = svgElement.clientWidth;
    canvas.height = svgElement.clientHeight;

    // Create a Blob from the SVG's outerHTML
    const svgBlob = new Blob([svgElement.outerHTML], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);

    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      URL.revokeObjectURL(url); // Clean up the object URL

      // Download the image as PNG
      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = `${fileDetails.file_no}_barcode.png`;
      a.click();
    };
    img.src = url;
  };

  useEffect(() => {
    if (fileDetails.file_no && barcodeRef.current) {
      JsBarcode(barcodeRef.current, fileDetails.file_no, {
        format: "CODE128",
        lineColor: "#000",
        width: 1.5,
        height: 30,
        displayValue: true,
        fontSize: 12,
        background: "transparent", // Make the background transparent
      });
    }
  }, [fileDetails.file_no]);

  return (
    <div className='bg-white '>
      <div className='w-full p-5 '>
        <div className='flex justify-between'>
          <h2 className='mb-6 text-2xl font-semibold text-gray-800'>
            File Details
          </h2>

          <div className='space-x-2'>
            {/* <Button
              classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Print"}
              icon={"PrinterIcon"}
            /> */}
            <Button
              classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download Barcode"}
              icon={"CloudDownloadIcon"}
              type='button'
              onClick={downloadBarcode}
            />
          </div>
        </div>

        {/* File Details Section */}
        <div className='overflow-hidden bg-white rounded-lg shadow-lg'>
          <div className='bg-blue-100 border-b border-blue-200'>
            <h3 className='flex justify-between text-lg font-semibold text-blue-700'>
              <span className='px-6 py-4 '>Basic Information</span>
              <svg ref={barcodeRef}></svg>
            </h3>
          </div>

          <div className='px-6 py-6'>
            <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
              {/* Title */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Title
                </h4>
                <p className='text-sm text-gray-900'>{fileDetails.title}</p>
              </div>

              {/* File Number */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  File Number
                </h4>
                <p className='text-sm text-gray-900'>
                  {fileDetails.file_no} <br />
                  {fileDetails.type == "Part" && (
                    <>
                      Part File of :{" "}
                      <Link
                        className='text-blue-600 coursor-pointer'
                        to={`/file-details/${fileDetails.parent_id}`}
                      >
                        {fileDetails.parent?.file_no}
                      </Link>
                    </>
                  )}
                </p>
              </div>

              {/* Reference No */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Reference No
                </h4>
                <p className='text-sm text-gray-900'>
                  {fileDetails.reference_no}
                </p>
              </div>

              {/* Description */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Description
                </h4>
                <p className='text-sm text-gray-900'>
                  {fileDetails.description}
                </p>
              </div>

              {/* Created by Department */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Created by Department/Branch/Office
                </h4>
                <p className='text-sm text-gray-900'>
                  <span className='px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full '>
                    {fileDetails.department?.name}{" "}
                  </span>
                </p>
              </div>

              {/* Current Department */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Current Department/Branch/Office
                </h4>
                <p className='text-sm text-gray-900'>
                  <span className='px-2 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-full '>
                    {fileDetails.current_department?.name ??
                      fileDetails.department?.name}
                  </span>
                </p>
              </div>

              {/* Created by */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Created by
                </h4>
                <p className='text-sm text-gray-900'>
                  {fileDetails.created_by?.name}
                </p>
              </div>

              {/* Created at */}
              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Created at
                </h4>
                <p className='text-sm text-gray-900'>
                  {moment(fileDetails.created_at).format("DD MMM YYYY")}
                </p>
              </div>

              <div>
                <h4 className='mb-1 text-xs font-semibold text-gray-500 uppercase'>
                  Uploaded File
                </h4>
                <p className='text-sm text-gray-900'>
                  {fileDetails.upload_path ? (
                    <a
                      target='_blank'
                      href={fileDetails.file_url}
                      rel='noreferrer'
                      className='text-blue-600'
                    >
                      View File
                    </a> // Add download link
                  ) : (
                    "No file uploaded"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />

        {/* Movement Tracking Timeline */}
        <div className='overflow-hidden bg-white rounded-lg shadow-md'>
          <div className='flex justify-between px-6 py-4 bg-blue-100 border-b border-blue-200'>
            <h3 className='text-lg font-semibold text-blue-700'>Movements</h3>
            {fileDetails.can_forward && (
              <Button
                type='button'
                onClick={openForwardModal}
                classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                value={"Forward File"}
                icon={"ChevronDoubleRightIcon"}
              />
            )}
          </div>{" "}
          <div className='relative m-5 border-gray-300'>
            {fileDetails.movements.map((movement, index) => (
              <div key={index} className='relative pb-5'>
                {index !== fileDetails.movements.length - 1 && (
                  <div className='absolute top-0 left-2.5 h-full w-0.5 bg-gray-300'></div>
                )}
                <div className='relative flex items-start space-x-4'>
                  <div className='relative'>
                    <div
                      className={`h-6 w-6 rounded-full ${
                        index === 0 ? "bg-green-500" : "bg-blue-500"
                      } border-2 border-white`}
                    ></div>
                  </div>
                  <div className='flex flex-col w-full ml-4 space-y-2'>
                    <div
                      className={`p-4 rounded-lg shadow ${
                        index === 0 ? "bg-green-50" : "bg-blue-50"
                      }`}
                    >
                      <p className='text-sm font-medium text-gray-900'>
                        <span className='font-bold'>
                          {movement.from_department?.name}
                        </span>{" "}
                        forwarded to{" "}
                        <span className='font-bold'>
                          {movement.to_department?.name}
                          {/* if movement.accepted_at is null then show receive pending */}
                        </span>{" "}
                        <span className='text-red-600'>
                          {movement.accepted_at ? "" : " (Receive Pending)"}
                        </span>
                        {fileDetails.can_accept && !movement.accepted_at && (
                          <button
                            onClick={() => {
                              openAcceptModal();
                              setSelectedMovement(movement);
                            }}
                            className='px-2 py-1 ml-10 text-green-500 border border-green-500 rounded-full'
                          >
                            Click to Receive <MdCheck className='inline' />{" "}
                          </button>
                        )}
                      </p>
                      <p className='mt-1 text-sm text-gray-600'>
                        Remarks: {movement.remarks}
                      </p>
                      <p className='text-xs text-right font-extralight'>
                        Forwarded At:{" "}
                        {moment(movement.movement_date).format("DD MMM YYYY")}
                      </p>
                      <p className='text-xs text-right font-extralight'>
                        Accepted At:{" "}
                        {movement.accepted_at
                          ? moment(movement.accepted_at).format("DD MMM YYYY")
                          : "Not Accepted Yet"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Forward Modal */}
          <Transition.Root show={showForwardModal} as={Fragment}>
            <Dialog
              as='div'
              className='fixed inset-0 z-10 overflow-y-auto'
              onClose={closeForwardModal}
            >
              <div className='flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
                </Transition.Child>

                <span className='hidden sm:inline-block sm:align-middle sm:h-screen'>
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <div className='inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      Forward File
                    </Dialog.Title>

                    <div className='mt-4'>
                      {/* Formik Form */}
                      <Formik
                        initialValues={{
                          date: moment().format("YYYY-MM-DD"),
                          to_department_id: "",
                          remarks: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleForwardSubmit}
                        innerRef={formikRef}
                        enableReinitialize={true}
                      >
                        {({ values, setFieldValue, errors, touched }) => (
                          <Form>
                            {/* Date */}
                            <div className='mb-4'>
                              <Input label='Date' type='date' name='date' />
                            </div>

                            {/* To Department */}
                            <div className='mb-4'>
                              <CustomSelect
                                options={departments.filter(
                                  (d) =>
                                    d.id !==
                                    (fileDetails.current_department?.id ??
                                      fileDetails.department?.id)
                                )}
                                name='to_department_id'
                                label={
                                  <div className='flex items-center'>
                                    To Department
                                    <span className='ml-1 text-red-600'>*</span>
                                  </div>
                                }
                                isSearchable={true}
                                getOptionValue={(option) => `${option.id}`}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={async (selectedOption) => {
                                  formikRef.current?.setFieldValue(
                                    "to_department_id",
                                    selectedOption?.id
                                  );
                                }}
                              />
                            </div>

                            {/* Remarks */}
                            <div className='mb-4'>
                              <Input
                                label='Remarks'
                                type='textarea'
                                name='remarks'
                                placeholder='Enter remarks'
                              />
                            </div>

                            <div className='flex justify-between '>
                              {/* cancel button */}
                              <Button
                                type='button'
                                onClick={closeForwardModal}
                                classNames='bg-red-600 hover:bg-red-700 focus:ring-red-500'
                                value={"Cancel"}
                                icon={"XIcon"}
                              />
                              {/* Submit Button */}
                              <Button
                                classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                                value={"Submit"}
                                icon={"SaveIcon"}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          {/* accept Modal */}
          <Transition.Root show={showAcceptModal} as={Fragment}>
            <Dialog
              as='div'
              className='fixed inset-0 z-10 overflow-y-auto'
              onClose={closeAcceptModal}
            >
              <div className='flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
                </Transition.Child>

                <span className='hidden sm:inline-block sm:align-middle sm:h-screen'>
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <div className='inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      Receive File
                    </Dialog.Title>

                    <div className='mt-4'>
                      {/* Formik Form */}
                      <Formik
                        initialValues={{
                          accepted_at: moment().format("YYYY-MM-DD"),
                        }}
                        validationSchema={validationSchemaAcceptForm}
                        onSubmit={handleAcceptFile}
                        innerRef={formikRefAccept}
                        enableReinitialize={true}
                      >
                        {({ values, setFieldValue, errors, touched }) => (
                          <Form>
                            {/* Date */}
                            <div className='mb-4'>
                              <Input
                                label='Date'
                                type='date'
                                name='accepted_at'
                              />
                            </div>

                            <div className='flex justify-between '>
                              {/* cancel button */}
                              <Button
                                type='button'
                                onClick={closeAcceptModal}
                                classNames='bg-red-600 hover:bg-red-700 focus:ring-red-500'
                                value={"Cancel"}
                                icon={"XIcon"}
                              />
                              {/* Submit Button */}
                              <Button
                                classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                                value={"Submit"}
                                icon={"SaveIcon"}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </div>
  );
};

export default FileDetails;
