import { Field, Form, Formik } from "formik";
import React from "react";
import Input from "../../Components/Utils/Input";
import Select from "../../Components/Utils/Select";
import { RejectCategories } from "../../Constants/RejectCategories";
import * as Yup from "yup";

export default function RejectModal({
  student,
  handleReject,
  setShowModal,
  loading,
}) {
  const validationSchema = Yup.object().shape({
    message: Yup.string().nullable().required("This field is required!"),
  });
  return (
    <>
      <Formik
        initialValues={{
          id: student.id,
          message: "",
          remark: "",
          edit: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleReject}
      >
        <Form>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-2/6	 my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Reject Student</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative   p-6 flex-auto">
                  <div className="my-4  text-slate-500 text-lg leading-relaxed">
                    <Select name="message" id="message" label="Category">
                      <option value="">Select One</option>
                      {RejectCategories.length > 0 &&
                        RejectCategories.map((RejectCategory, index) => (
                          <option key={index} value={RejectCategory}>
                            {RejectCategory}
                          </option>
                        ))}
                    </Select>
                    <Input name="remark" id="remark" label="Remarks" />
                    <label>
                      <Field type="checkbox" name="edit" className="my-5" />
                      &nbsp;&nbsp;Check to Give Edit Permission To Student
                    </label>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Loading" : "Reject"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
