import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";

// import Input from "../../Components/Utils/Input";
import messageSlice from "../../../Redux/MessageSlice";
// import { getPaymentHeadsAsync } from "../../Redux/MasterSlice";
import BaseApi from "../../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
// import { YearSemesters } from "../../Constants/YearSemesters";
// import Select from "../../../Components/Utils/Select";
// import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";
// import {
//   getAcademicSessionsAsync,
// } from "../../Redux/MasterSlice";
// import { PromotionDemotion } from "../../Constants/PromotionDemotion";
import moment from "moment";

export default function StudentPromotionModal({
  onStudentPromotionModal,
  selectedIds,
  type,
  studentFilter,
  setShowModal,
}) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const [permited, setPermited] = useState(false);
  const [msg, setMsg] = useState("");

  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();
  const authData = useSelector((state) => state.auth);
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("called again");
    console.log(authData);
    if (type == "promotion") {
      let current_academic_ses = "";

      const programme = programmes.find(
        (p) => p.id == studentFilter.programme_id
      );
      console.log(programme);
      let academic_ses = academicSessions.find(
        (as) => as.id == studentFilter.academic_session_id
      );
      let year_sem = studentFilter.semester + 1;
      console.log(academic_ses, year_sem);

      if (programme.type == 0) {
        if (year_sem % 2 == 0) {
          current_academic_ses = academic_ses.label;
          setMsg(
            "Academic Session: " +
              current_academic_ses +
              " and  Year/Semester: " +
              year_sem
          );
        } else {
          console.log("to year", moment(academic_ses.to_year).format("YYYY"));
          let new_academic_ses = academicSessions.find(
            (acc) =>
              moment(acc.from_year).format("YYYY") ==
              moment(academic_ses.to_year).format("YYYY")
          );
          console.log("from year", new_academic_ses);

          current_academic_ses = new_academic_ses.label
            ? new_academic_ses.label
            : "";
          console.log("from year", current_academic_ses);

          setMsg(
            "Academic Session: " +
              current_academic_ses +
              " and Year/Semester: " +
              year_sem
          );
        }
        setPermited(true);
      } else if (programme.type == 1) {
        let new_academic_ses = academicSessions.find(
          (acc) =>
            moment(acc.from_year).format("YYYY") ==
            moment(academic_ses.to_year).format("YYYY")
        );

        current_academic_ses = new_academic_ses.label
          ? new_academic_ses.label
          : "";

        setPermited(true);
        setMsg(
          "Academic Session: " +
            current_academic_ses +
            " and Year/Semester: " +
            year_sem
        );
      } else {
        console.log(permited);
        setPermited(false);
      }

      console.log("promotion");
    } else if (type == "demotion") {
      console.log("demotion");
    } else {
      console.log("something else");
    }
    dispatch(messageSlice.actions.clearMessage());

    setInitialValues({
      selectedIds: selectedIds,
      type: type,
      details: [],
    });
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    // selectedIds: Yup.string().required("This field is required!"),
    // semester: Yup.string().required("This field is required!"),
    // type: Yup.string().required("This field is required!"),
    // academic_session_id: Yup.string()
    // .nullable()
    // .required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    setLoading(true);
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);

    await BaseApi().post(`make-promotion`, payload);
    setLoading(false);

    setInitialValues({
      selectedIds: selectedIds,
      type: type,
      details: [],
    });
    onStudentPromotionModal();
    setShowModal(false);
  };

  console.log(message);
  return (
    <>
      <div className="justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative   w-800 my-6 mx-auto max-w-4xl">
          {/*content*/}

          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Student Selected: {selectedIds.length}
                {/* {type == "promotion" ? "Student Promotion" : "Student Demotion"} */}
              </h3>
            </div>
            {/*body*/}
            <p style={{ width: "800px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className="relative p-6 flex-auto">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        {/* {programmes.find((p)=>p.id==studentFilter.programme_id).name}
                      {academicSessions.find((as)=>as.id==studentFilter.academic_session_id).label}
                     <br/> {studentFilter.semester + 1} */}
                        {/* console.log('Academic Session-',current_academic_ses);
                         console.log('Year/Semester -',year_sem); */}
                        <h5>Selected students will be promoted to:</h5>
                        <br />
                        <pre>{msg}</pre>
                      </div>
                    </div>

                    <p className="my-4 text-slate-500 text-lg leading-relaxed"></p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    {permited && (
                      <Button
                        disabled={loading}
                        value={loading ? "Saving..." : "Confirm and Promote"}
                        icon={"SaveIcon"}
                        type="submit"
                      />
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
