import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import Input from "../../../../Components/Utils/Input";
import BaseApi from "../../../../Http/BaseApi";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);

  const formikRef = useRef(null);
  const [qualificationMasters, setQualificationMasters] = useState([]);

  useEffect(() => {
    getQualificationMasters();
  }, []);

  const getQualificationMasters = async () => {
    try {
      const response = await BaseApi().get("/qualification-masters");
      console.log("Qualification Masters:", response.data);
      setQualificationMasters(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h1 className="flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase ">
        Educational Qualification
      </h1>
      {qualificationMasters.length > 0 && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="grid grid-cols-2 gap-3 p-5 ">
            <CustomSelect
              defaultInputValue={
                qualificationMasters?.find(
                  (qualification) =>
                    qualification.id == initialValues.qualification_master_id
                )?.name
              }
              options={qualificationMasters}
              name="qualification_master_id"
              label={
                <div className="flex items-center">
                  Qualification Type{" "}
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "qualification_master_id",
                  selectedOption.id
                );
              }}
            />

            <Input
              type="text"
              name="qualification"
              label={
                <div className="flex items-center">
                  Qualification
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              name="institute"
              label={
                <div className="flex items-center">
                  Institute
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              name="subject"
              label={
                <div className="flex items-center">
                  Subject/Specialization
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              name="pass_year"
              label={
                <div className="flex items-center">
                  Pass Year
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />

            <div className="flex justify-between col-span-2 mt-10">
              <button
                className="flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={onCancel}
              >
                <XIcon className="w-5 h-5 mr-2" /> Cancel
              </button>
              {isEditMode ? (
                <Button type="submit" value="Update" icon={"SaveIcon"} />
              ) : (
                <Button type="submit" value="Save" icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default FormPage;
