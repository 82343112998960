import React, { useEffect, useRef, useState } from "react";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

// import Select from "../../Components/Utils/Select";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../../Redux/MasterSlice";
// import { getAdmittedStudentsAsync } from "../../../Redux/StudentSlice";
import PageTitle from "../../../Components/Common/PageTitle";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { YearSemesters } from "../../../Constants/YearSemesters";
import Button from "../../../Components/Utils/Button";
import tableToCSV from "../../../Components/Utils/TableToCSV";
import can from "../../../Middleware/Permission";
import MakeFeeCollection from "./MakeFeeCollection";
import BaseApi from "../../../Http/BaseApi";
// import { SearchNor } from "../../Constants/SearchNor";

export default function DepartmentFeeCollection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [studentFilter, setStudentFilter] = useState();
  const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const [selectedIds, setSelectedIds] = useState([]);
  const [students, setStudents] = useState([]);

  // let students = useSelector((state) => state.students.admittedStudents);

  //   const dispatch = useDispatch();
  console.log(message);
  useEffect(() => {
    // dispatch(messageSlice.actions.clearMessage());
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const initialValues = {
    programme_id: null,
    semester: null,
    academic_session_id: null,
    nor: 10,
  };
  const validationSchema = Yup.object().shape({
    programme_id: Yup.string().nullable().required("This field is required!"),
    semester: Yup.string().nullable().required("This field is required!"),
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  const handleSearch = async (searchQuery) => {
    setLoading(true);
    setSelectedIds([]);
    setStudents([]);
    console.log(searchQuery);
    const response = await BaseApi().get(
      `admitted-students?with_pending_payments=1`,
      {
        params: searchQuery,
      }
    );
    setStudents(response.data);

    // await dispatch(getAdmittedStudentsAsync({ payload: searchQuery }))
    //   .unwrap()
    //   .then(() => {})
    //   .catch(() => {});
    setStudentFilter(searchQuery);

    setLoading(false);
  };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  const handleCheckbox = (event) => {
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      setSelectedIds([...selectedIds, value]);
    } else {
      var index = selectedIds.indexOf(value);
      if (index != -1) {
        setSelectedIds(selectedIds.filter((item) => item != value));
      }
    }
  };

  const reloadApplicant = async () => {
    handleSearch(studentFilter);
  };
  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full overflow-x-scroll'>
            <PageTitle name='Search Student' />

            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className=' p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8'>
                  <CustomSelect
                    options={programmes.filter((p) => p.programmetype_id != 2)}
                    name='programme_id'
                    label='Select Programme'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <CustomSelect
                    options={YearSemesters}
                    name='semester'
                    label='Select Semester'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "semester",
                        selectedOption.value
                      );
                    }}
                  />

                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Academic Session'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div className='text-left mt-5'>
                    <button
                      type='submit'
                      className='group relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr className='w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8'>
                  <th className='table-head'>SL</th>
                  <th className='table-head'></th>
                  <th className='table-head'>ID</th>
                  <th className='table-head'>Name</th>
                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Semester</th>
                  <th className='table-head'>Mobile No.</th>
                  <th className='table-head'>Email</th>

                  <th className='table-head'>Details</th>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 &&
                  students.map((student, index) => (
                    <tr
                      key={student.id}
                      className='h-24 border-gray-300 dark:border-gray-200 border-b'
                    >
                      <td className='table-data'>{++index}</td>

                      <td className={"table-data" + "w-32"}>
                        <input
                          className='form-check-input    text-blue-600 h-8 w-8  border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top  float-left mr-5 cursor-pointer'
                          type='checkbox'
                          value={student.id}
                          onChange={(event) => handleCheckbox(event)}
                          id={student.id}
                        />
                      </td>
                      <td className='table-data'>{student.unique_id || student.unique_idd}</td>
                      <td className='table-data'>
                        <div className='flex items-center'>
                          <div className='h-8 w-8'>
                            <img
                              src={student.student?.student_photo}
                              className='h-full w-full rounded-full overflow-hidden shadow'
                              onError={(e) => {
                                console.log(e.target.src);
                                if (e.target.src != "/assets/no-photo.jpg")
                                  e.target.src = "/assets/no-photo.jpg";
                              }}
                            />
                          </div>
                          <p className='ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm'>
                            {student.student?.name}
                          </p>
                        </div>
                        {student.payment != null && (
                          <div className='mt-3 text-blue-600'>
                            {student.payment?.amount} Payment Requested
                          </div>
                        )}
                      </td>
                      <td className='table-data'>{student.programme?.code}</td>
                      <td className='table-data'>{student.semester}</td>
                      <td className='table-data'>{student.student?.phone}</td>
                      <td className='table-data'>{student.student?.email}</td>

                      <td className='table-data'>
                        <Button
                          classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                          value={"Details"}
                          icon={"ChevronDoubleRightIcon"}
                          onClick={() => {
                            navigate(`/admitted-students/${student.id}`);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {students.length == 0 && (
              <div className='text-center my-10'>No Data</div>
            )}
            <div className='text-center m-10'>
              {can("Fee Collection") && (
                <Button
                  classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  value={"Proceed"}
                  icon={"ChevronDoubleRightIcon"}
                  onClick={() => {
                    if (selectedIds.length > 0) {
                      setShowModal(true);
                    } else {
                      alert("Please select atleast one student.");
                    }

                    // navigate(
                    //   `/applicants/make-admission/${applicant.id}`
                    // );
                  }}
                />
              )}
            </div>
          </div>

          {showModal ? (
            <MakeFeeCollection
              onMakeFeeCollection={reloadApplicant}
              selectedIds={selectedIds}
              studentFilter={studentFilter}
              setShowModal={setShowModal}
              loading={loading}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
