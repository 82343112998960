import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  const modeTypes = [
    { id: 1, name: "Online" },
    { id: 2, name: "Offline" },
    { id: 3, name: "Hybrid" },
  ];
  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        FIP activities/Participation in FDP
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className='grid grid-cols-2 gap-3 p-5 '>
          <Input
            type='text'
            name='fdp_name'
            label={
              <div className='flex items-center'>
                Name of the FDP
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='date'
            name='start_date'
            label={
              <div className='flex items-center'>
                FDP Start date
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='date'
            name='end_date'
            label={
              <div className='flex items-center'>
                FDP End date
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='text'
            name='organizer'
            label={
              <div className='flex items-center'>
                Organizer
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <CustomSelect
            defaultInputValue={initialValues.mode}
            options={modeTypes}
            name='mode'
            label={
              <div className='flex items-center'>
                Mode of FDP
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
            isSearchable={true}
            getOptionValue={(option) => `${option.id}`}
            getOptionLabel={(option) => `${option.name}`}
            onChange={async (selectedOption) => {
              formikRef.current?.setFieldValue("mode", selectedOption.name);
            }}
          />
          {/* <Input
            type='textarea'
            name='details'
            label={
              <div className='flex items-center'>
                Details of FDP
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          /> */}

          <div className='flex justify-between col-span-2 mt-10'>
            <button
              className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
              onClick={onCancel}
            >
              <XIcon className='w-5 h-5 mr-2' /> Cancel
            </button>
            {isEditMode ? (
              <Button type='submit' value='Update' icon={"SaveIcon"} />
            ) : (
              <Button type='submit' value='Save' icon={"SaveIcon"} />
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default FormPage;
