import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
// import { useNavigate } from "react-router-dom";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { getDepartmentsAsync } from "../../Redux/MasterSlice";
import Select from "../../Components/Utils/Select";
import { ThreeDots } from "react-loader-spinner";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";

export default function StaffListToViewLeaves() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const formikRef = useRef();

  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  const [errorMessage, setErrorMessage] = useState(false);
  const [staff, setStaff] = useState([]);
  const authData = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState();
  const master = useSelector((state) => state.master);
  const departments = [...master.departments];
  const [searchInput, setSearchInput] = useState("");
  const [lastPage, setLastPage] = useState();

  console.log(loading);
  //   const dispatch = useDispatch();
  useEffect(() => {
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
    setInitialValues({
      department_id: "",
      status: "",
      type: authData.user.email === "establishmentb@dibru.ac.in" ? 2 : "",
      page: 1,
    });
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // getStaff({
    //   department_id: "",
    //   status: "",
    //   page: 1,
    // });
    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("This field is required!"),
  });

  async function getStaff(searchQuery) {
    console.log(searchQuery);
    setLoading(true);
    if (authData.user.email === "establishmentb@dibru.ac.in") {
      searchQuery.type = 2; // Set 'type' property to an array with value 2
    }
    // if (authData.user.email === "establishmenta@dibru.ac.in") {
    //   searchQuery.type = [1, 3]; // Set 'type' property to an array with values 1 and 2
    // }
    await BaseApi()
      .get(`staff`, { params: { ...searchQuery, paginate: true } })
      .then((data) => {
        let _staff = data.data.data;
        console.log(authData.user.email);
        if (
          authData.user.email == "vc@dibru.ac.in" ||
          authData.user.email == "registrar@dibru.ac.in"
        ) {
          if (searchQuery.page == 1) {
            setStaff(_staff.filter((s) => s.type == "1" || s.type == 3));
          } else {
            setStaff((prevStaff) => [
              ...prevStaff,
              ..._staff.filter((s) => s.type == "1" || s.type == 3),
            ]);
          }
        } else if (authData.user.email == "establishmentb@dibru.ac.in") {
          if (searchQuery.page == 1) {
            setStaff(_staff.filter((s) => s.type == "2"));
          } else {
            setStaff((prevStaff) => [
              ...prevStaff,
              ..._staff.filter((s) => s.type == "2"),
            ]);
          }
        }

        let nextPage = ++searchQuery.page;
        setLastPage(data.data?.last_page);
        if (nextPage <= data.data?.last_page) {
          setInitialValues({
            department_id: searchQuery.department_id,
            status: searchQuery.status,
            type: searchQuery.type,
            page: nextPage,
          });
          getStaff({
            department_id: searchQuery.department_id,
            status: searchQuery.status,
            type: searchQuery.type,
            page: nextPage,
          });
        } else {
          setInitialValues({
            department_id: searchQuery.department_id,
            status: searchQuery.status,
            type: searchQuery.type,
            page: 1,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
  }

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='List of ERP Users' />
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={getStaff}
            >
              <Form>
                <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4'>
                  <CustomSelect
                    options={departments}
                    name='department_id'
                    label='Select Department/Branch'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "department_id",
                        selectedOption.id
                      );
                    }}
                  />
                  <Select name='status' label='Select Status'>
                    <option value=''>Select Any One</option>
                    <option value='1'>Active</option>
                    <option value='0'>Inactive</option>
                    <option value=''>Any</option>
                    {/* <option value="2023">2024</option> */}
                  </Select>

                  <Select name='type' label='Select Type*'>
                    <option value=''>Select Any One</option>

                    {(authData.user.email == "vc@dibru.ac.in" ||
                      authData.user.email == "registrar@dibru.ac.in") && (
                      <>
                        <option value='1'>Teacher</option>
                        <option value='3'>Officer</option>
                      </>
                    )}
                    {authData.user.email == "establishmentb@dibru.ac.in" && (
                      <>
                        <option value='2'>Employee (Grade III)</option>
                      </>
                    )}
                  </Select>

                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && (
                        <span>
                          Searching... {initialValues?.page} / {lastPage}
                        </span>
                      )}
                      {!loading && <span>Search</span>}
                    </button>
                    <button
                      type='button'
                      className='relative px-4 py-2 ml-3 text-sm font-medium text-white bg-orange-600 border border-transparent rounded-md group w-50 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
                      disabled={loading}
                      onClick={() => {
                        setInitialValues({
                          department_id: "",
                          status: "",
                          page: 1,
                        });
                        setStaff([]);
                      }}
                    >
                      Clear Search
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            {staff?.length > 0 && (
              <>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='inline-block m-0 text-gray-700 form-label'
                >
                  Search By Name
                </label>
                <input
                  className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                  placeholder='Type here...'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='w-16 table-head'>#</th>

                  <th className='table-head'>Name</th>
                  <th className='table-head'>Father&apos;s Name</th>
                  <th className='table-head'>Mother&apos;s Name</th>
                  <th className='table-head'>Department/Branch</th>
                  <th className='table-head'>Designations</th>
                  <th className='table-head'>Email</th>
                  <th className='table-head'>Phone</th>

                  <th className='table-head'>Status</th>

                  <th colSpan='2' className='table-head'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {staff?.length > 0 &&
                  staff
                    ?.filter((s) => {
                      if (searchInput?.length > 0) {
                        return (
                          s.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((singleStaff, i) => {
                      return (
                        <>
                          {" "}
                          <tr
                            className={
                              " " +
                              (singleStaff.status == 0
                                ? "bg-red-50"
                                : "odd:bg-white even:bg-gray-50")
                            }
                          >
                            <td className='w-32 table-data'>{++i}</td>

                            <td className='table-data'>{singleStaff.name}</td>
                            <td className='table-data'>
                              {singleStaff.fathers_name}
                            </td>
                            <td className='table-data'>
                              {singleStaff.mothers_name}
                            </td>
                            <td className='table-data'>
                              {singleStaff?.department?.name}
                            </td>
                            <td className='table-data'>
                              {singleStaff?.designation?.name}
                            </td>
                            <td className='table-data'>{singleStaff.email}</td>
                            <td className='table-data'>{singleStaff.phone}</td>

                            <td className='table-data'>
                              {singleStaff.status == 1 ? (
                                <>
                                  <span className='text-green-700'>Active</span>
                                </>
                              ) : (
                                <>
                                  <span className='text-red-700'>Inactive</span>
                                </>
                              )}
                            </td>

                            <>
                              <td className='table-data'>
                                {(authData.user.email == "vc@dibru.ac.in" ||
                                  authData.user.email ==
                                    "registrar@dibru.ac.in") && (
                                  <button
                                    className='px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-blue-700'
                                    onClick={() => {
                                      window.open(
                                        `/leave-report/${singleStaff.id}`,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    View Leaves
                                  </button>
                                )}
                              </td>
                              <td className='table-data'>
                                {singleStaff.deactivate_remarks && (
                                  <div className='mt-1'>
                                    Remarks: {singleStaff.deactivate_remarks}
                                  </div>
                                )}
                              </td>
                            </>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {!loading && staff?.length == 0 && (
              <>
                <div className='m-10 text-center'>No Data</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
