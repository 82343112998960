import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  name: { label: "Title of the Activity", path: "name" },
  nature: { label: "Nature of the Activity", path: "nature" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  place: { label: "Place of Activity", path: "place" },
};

const IqacCorporateLife = () => {
  const [corporateLives, setCorporateLives] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/corporate-lives?from_iqac=1"
      );
      setCorporateLives(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50">
        <h1>Corporate Life: </h1>
        <div className="flex items-end justify-center space-x-2 capitalize text-md">
          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">From: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">To: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="">
            <Button
              type="filter"
              value="Filter"
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>
      <TableFacultyIqac
        headers={headers}
        data={corporateLives}
        type="corporate-lives"
        setData={setCorporateLives}
      />
    </div>
  );
};

export default IqacCorporateLife;
