import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const StartupDevelopment = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [startupDevelopments, setStartupDevelopments] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    startup_name: "",
    date_started: "",
    registration_details: "",
    objective: "",
    details: "",
    role: "",
  });
  const headers = {
    startup_name: { label: "Name of the Startup", path: "startup_name" },
    date_started: { label: "Date Started", path: "date_started" },
    registration_details: {
      label: "Registration Details",
      path: "registration_details",
    },
    objective: { label: "Objective of the Startup", path: "objective" },
    details: { label: "Details of the Startup", path: "details" },
    role: { label: "Your Role in the Startup", path: "role" },
  };

  const validationSchema = Yup.object({
    startup_name: Yup.string().required("This field is required!"),
    date_started: Yup.string().required("This field is required!"),
    registration_details: Yup.string().required("This field is required!"),
    objective: Yup.string().required("This field is required!"),
    details: Yup.string().required("This field is required!"),
    role: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/startup-developments");
      setStartupDevelopments(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/startup-developments/${editId}`, values);
        toast.success(`Startup Updated Successfully`);

        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/startup-developments", values);
        toast.success(`Startup Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = startupDevelopments.find(
        (startupDevelopment) => startupDevelopment.id === id
      );
      setInitialValues({
        id: editableData.id,
        startup_name: editableData.startup_name,
        date_started: editableData.date_started,
        registration_details: editableData.registration_details,
        objective: editableData.objective,
        details: editableData.details,
        role: editableData.role,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      startup_name: "",
      date_started: "",
      registration_details: "",
      objective: "",
      details: "",
      role: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      startup_name: "",
      date_started: "",
      registration_details: "",
      objective: "",
      details: "",
      role: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/startup-developments/${id}`);
      toast.success(`Startup Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Startup :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={startupDevelopments}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default StartupDevelopment;
