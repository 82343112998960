import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const Committee = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [committees, setCommittees] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    member_type: "",
    organization: "",
    month: "",
    year: "",
    till_month: "",
    till_year: "",
  });
  const headers = {
    name: { label: "Name of the Committee", path: "name" },
    member_type: { label: "Member Type", path: "member_type" },
    organization: { label: "Organization", path: "organization" },
    other_organization: {
      label: "Other Organization",
      path: "other_organization",
    },
    month: { label: "Month of Membership", path: "monthName" },
    year: { label: "Year of Membership", path: "year" },
    till_month: { label: "Till Month", path: "tillMonthName" },
    till_year: { label: "Till Year", path: "till_year" },
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required!"),
    member_type: Yup.string().required("This field is required!"),
    organization: Yup.string().required("This field is required!"),
    other_organization: Yup.string().when("organization", {
      is: "Others",
      then: Yup.string().required("This field is required!"),
    }),
    month: Yup.string().required("This field is required!"),
    // year: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/committees");
      setCommittees(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/committees/${editId}`, values);
        toast.success(`Data Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/committees", values);
        toast.success(`Data Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = committees.find((committee) => committee.id === id);
      setInitialValues({
        id: editableData.id,
        name: editableData.name,
        member_type: editableData.member_type,
        organization: editableData.organization,
        other_organization: editableData.other_organization,
        month: editableData.month,
        year: editableData.year,
        till_month: editableData.till_month,
        till_year: editableData.till_year,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      name: "",
      member_type: "",
      organization: "",
      other_organization: "",
      month: "",
      year: "",
      till_month: "",
      till_year: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      name: "",
      member_type: "",
      organization: "",
      other_organization: "",
      month: "",
      year: "",
      till_month: "",
      till_year: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/committees/${id}`);
      toast.success(`Data Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Memberships in Significant Committees:</h1>

          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={committees}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default Committee;
