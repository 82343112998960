import React, { useEffect, useState } from "react";
// import BaseApi from "../../../../Http/BaseApi";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Utils/Button";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";

const headers = {
  name: {
    label: "Name",
    path: "name",
  },
  department: {
    label: "Department",
    path: "department.name",
  },
  designation: {
    label: "Designation",
    path: "designation.name",
  },
  email: {
    label: "Email",
    path: "email",
  },
  contact_no: {
    label: "Contact No",
    path: "phone",
  },
};
const IqacBasicInfo = () => {
  const [basicInfos, setBasicInfos] = useState([]);
  const authData = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    getStaff();
  }, [dispatch, authData]);

  const getStaff = async () => {
    try {
      const response = await BaseApi().get(`staff?type=1&from_iqac=1`);
      setBasicInfos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50'>
        <h1>Basic Information: </h1>
        <div className='flex items-end justify-center space-x-2 capitalize text-md'></div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={basicInfos}
        setData={setBasicInfos}
      />
    </div>
  );
};

export default IqacBasicInfo;
