import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
// import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./EditForm";
import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../../../../Http/BaseApi";
import Alert from "../../../../Components/Utils/Alert";
import { ThreeDots } from "react-loader-spinner";
import Button from "../../../../Components/Utils/Button";
import { IoCall, IoMail } from "react-icons/io5";
import { FaShareAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const BasicInfo = () => {
  const dispatch = useDispatch();

  const authData = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    scopus_link: "",
    orcid_link: "",
    web_profile_pic: "",

    department: "",

    otherDesignations: [],
    google_scholar_link: "",
    web_of_science_link: "",
    research_gate_link: "",
    vidwan_link: "",
    linkedin_link: "",
    mobile_visibility: false,
  });

  useEffect(() => {
    getStaff(authData.user.id);
  }, [dispatch, authData]);

  const getStaff = async (id) => {
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        console.log(data.data);
        setInitialValues({
          name: data.data.full_name,
          designation: data.data.designation.name,
          email: data.data.email,
          contact_no: data.data.phone,

          scopus_link: data.data.scopus_link,
          orcid_link: data.data.orcid_link,
          web_profile_pic: data.data.web_profile_pic,

          department: data.data.department?.name,
          otherDesignations: data.data.other_designations?.map(
            (designation) => designation.name
          ),
          google_scholar_link: data.data.google_scholar_link,
          web_of_science_link: data.data.web_of_science_link,
          vidwan_link: data.data.vidwan_link,
          linkedin_link: data.data.linkedin_link,
          research_gate_link: data.data.research_gate_link,
          mobile_visibility: data.data.mobile_visibility == 1 ? true : false,
        });
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
    setLoading(false);
  };

  const validationSchema = Yup.object({
    // name: Yup.string().required("Name is required"),
    // designation: Yup.string().required("Designation is required"),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    // contact_no: Yup.string().required("Contact No is required"),
  });

  // const handleAddMore = () => {
  //   setInitialValues({
  //     id: "",
  //     name: "",
  //     designation: "",
  //     email: "",
  //     contact_no: "",
  //     department: "",
  //     scopus_link: "",
  //     orcid_link: "",
  //     web_profile_pic: "",

  //     google_scholar_link: "",
  //     web_of_science: "",
  //     vidwan: "",
  //     linkedin: "",
  //   });
  //   setIsAdding(true);
  //   // setEditId(null);
  //   setIsEditing(false);
  // };

  const handleSubmit = async (values) => {
    console.log("Form data submitted: ", values);
    try {
      // Update form data with new values

      await BaseApi().post("/profile/profile-link-update", values);

      // Exit edit mode
      setIsEditing(false);
      getStaff(authData.user.id);
      handleCancel();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsAdding(false);

    // setEditId(null); // Reset editIndexs
  };

  const socialLinks = [
    {
      label: "Scopus",
      name: "scopus_link",
      icon: "./assets/icons/scopus.png",
      color: "bg-green-50",
      borderColor: "border-green-300",
    },
    {
      label: "ORCiD",
      name: "orcid_link",
      icon: "./assets/icons/orcid.png",
      color: "bg-blue-50",
      borderColor: "border-blue-300",
    },
    {
      label: "Google Scholar",
      name: "google_scholar_link",
      icon: "./assets/icons/google_scholar.png",
      color: "bg-red-50",
      borderColor: "border-red-300",
    },
    {
      label: "Web of Science",
      name: "web_of_science_link",
      icon: "./assets/icons/wos.png",
      color: "bg-yellow-50",
      borderColor: "border-yellow-300",
    },
    {
      label: "Vidwan",
      name: "vidwan_link",
      icon: "./assets/icons/vidwan-logo.png",
      color: "bg-purple-50",
      borderColor: "border-purple-300",
    },
    {
      label: "LinkedIn",
      name: "linkedin_link",
      icon: "./assets/icons/linkedin.png",
      color: "bg-blue-50",
      borderColor: "border-blue-300",
    },
    {
      label: "ResearchGate",
      name: "research_gate_link",
      icon: "./assets/icons/researchGate.png",
      color: "bg-blue-50",
      borderColor: "border-blue-300",
    },
  ];

  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  const [fileUrl, setFileUrl] = useState("");

  const [showCropModal, setShowCropModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef();

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setFileUrl(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setShowCropModal(true);
    }
  };

  const onCrop = () => {
    // if (typeof cropperRef?.current?.getCroppedCanvas() === "undefined") {
    //   return;
    // }
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    setCroppedImage(
      cropper
        .getCroppedCanvas({
          width: 200,
          height: 300,
        })
        .toDataURL()
    );
  };

  const onUpload = async () => {
    setLoading(true);
    console.log(croppedImage);
    const blob = await fetch(croppedImage).then((r) => r.blob());
    const formData = new FormData();
    formData.append("web_photo", blob, "cropped-image.jpg");
    await BaseApi()
      .post(`staff/${authData.user.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        getStaff(authData.user.id);
        toast.success("Data Updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        document.getElementById("file").value = "";

        setFileUrl("");
        setShowCropModal(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      });
    setLoading(false);
  };

  return (
    <>
      <div className='space-y-5 '>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Basic Information :</h1>

          <Button
            type='edit'
            value='Edit'
            icon={"PencilIcon"}
            onClick={handleEdit}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        {loading && (
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        )}
        {errorMessage && <Alert message={errorMessage} />}

        <div className='flex items-center justify-center py-10 border bg-blue-50'>
          <div className='max-w-6xl p-6 overflow-hidden bg-white '>
            <div className='flex items-start space-x-5'>
              <div className='flex flex-col justify-between'>
                <div className='relative w-32'>
                  <img
                    className='w-32 rounded-md'
                    src={initialValues.web_profile_pic}
                    onError={(e) => {
                      console.log(e.target.src);
                      if (
                        e.target.src !==
                          "https://ui-avatars.com/api/name=?" +
                            initialValues.name ??
                        "NO"
                      )
                        e.target.src =
                          "https://ui-avatars.com/api/?name=" +
                            initialValues.name ?? "NO";
                    }}
                  />
                  <div className='absolute inset-0 flex items-center justify-center transition-opacity duration-300 bg-black bg-opacity-50 opacity-0 cursor-pointer hover:opacity-100'>
                    <label className='font-bold text-white'>Change Photo</label>
                    <input
                      className='absolute w-full h-full opacity-0 cursor-pointer'
                      id='file'
                      type='file'
                      accept='image/*'
                      onChange={onSelectFile}
                    />
                  </div>
                </div>
                {showCropModal && (
                  <>
                    <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                      <div className='relative w-auto max-w-3xl mx-auto my-6'>
                        {/*content*/}
                        <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                          {/*header*/}
                          <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                            <h3 className='text-3xl font-semibold'>
                              Crop Image
                            </h3>
                            <button
                              className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none'
                              onClick={() => {
                                document.getElementById("file").value = "";

                                setFileUrl("");
                                setShowCropModal(false);
                              }}
                            >
                              <span className='block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none'>
                                ×
                              </span>
                            </button>
                          </div>
                          {/*body*/}
                          <div className='relative grid gap-8 p-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3'>
                            <div className='col-span-2'>
                              <span className='mb-5 text-grey-700'>
                                Crop your image here: <br />
                              </span>
                              <Cropper
                                viewMode={1}
                                src={fileUrl}
                                style={{ height: 200, width: "100%" }}
                                initialAspectRatio={0.66666666666}
                                guides={true}
                                cropBoxResizable={false}
                                cropBoxMovable={false}
                                aspectRatio={0.66666666666}
                                crop={onCrop}
                                ref={cropperRef}
                                center='true'
                                dragMode={"move"}
                              />
                            </div>
                            {croppedImage && (
                              <div>
                                <span className='mb-2 text-grey-700'>
                                  Preview: <br />
                                </span>
                                <img
                                  className='border-2 border-r-4 '
                                  src={croppedImage}
                                  alt='Cropped'
                                />
                              </div>
                            )}
                          </div>
                          {/*footer*/}
                          <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
                            <button
                              className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                              type='button'
                              onClick={() => {
                                document.getElementById("file").value = "";

                                setFileUrl("");
                                setShowCropModal(false);
                              }}
                            >
                              Close
                            </button>
                            <button
                              className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none'
                              type='button'
                              onClick={loading ? null : onUpload}
                            >
                              {loading ? "Uploading..." : "Crop and Upload"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
                  </>
                )}
                <div className='mt-4 text-center'>
                  <button className='z-10 flex items-center justify-between w-full p-2 text-sm bg-white border border-blue-400 rounded-md opacity-100 hover:opacity-70 focus:outline-none'>
                    <span className='text-blue-600 '>Share Profile</span>
                    <FaShareAlt className='inline-block text-blue-600' />
                  </button>
                </div>
              </div>

              <div className='flex flex-col justify-between'>
                {initialValues && (
                  <>
                    {initialValues.name && (
                      <>
                        <h2 className='text-4xl font-semibold text-blue-900'>
                          {initialValues.name}
                        </h2>
                        <p className='text-xl text-gray-800'>
                          {initialValues.designation},{" "}
                          {initialValues.department}
                        </p>
                        <p className='text-sm italic text-green-800'>
                          {initialValues.otherDesignations.join(", ")}
                        </p>
                        <hr className='my-2' />
                        {initialValues.contact_no && (
                          <p className='text-blue-600'>
                            <IoCall className='inline w-6' />
                            {initialValues.contact_no}
                          </p>
                        )}
                        {initialValues.email && (
                          <p className='text-blue-600 lowercase'>
                            <IoMail className='inline w-6' />
                            {initialValues.email}
                          </p>
                        )}{" "}
                        <hr className='my-5' />
                        <div className='flex items-center mt-2 space-x-4'>
                          {socialLinks.map((socialLink, index) => (
                            <a
                              key={index}
                              href={ensureProtocol(
                                initialValues[socialLink.name]
                              )}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <div
                                className={`bg-gray-100 border-blue-300 border rounded-md p-2  flex items-center space-x-1 transition-transform duration-300 transform hover:scale-105`}
                              >
                                <img
                                  src={socialLink.icon}
                                  alt={`${socialLink.label} icon`}
                                  className='w-4 h-4'
                                />
                                <span className='text-xs font-semibold text-blue-800 font-extralight'>
                                  {socialLink.label}
                                </span>
                              </div>
                            </a>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
