import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaUsers } from "react-icons/fa6";
import { FaCalendar, FaRegComments } from "react-icons/fa";

const headers = {
  name: { label: "Name of the Committee", path: "name" },
  member_type: { label: "Member Type", path: "member_type" },
  month: { label: "Month of Membership", path: "monthName" },
  year: { label: "Year of Membership", path: "year" },
  till_month: { label: "Till Month", path: "tillMonthName" },
  till_year: { label: "Till Year", path: "till_year" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const Committee = ({ view }) => {
  const [committees, setCommittees] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/committees");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setCommittees(response.data.data.filter((q) => q[filterField] === "yes"));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/committees");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredCommittees = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year (descending) and month (descending within year)
      const sortedCommittees = filteredCommittees.sort((a, b) => {
        const yearA = parseInt(getValueByPath(a, headers.year.path), 10);
        const yearB = parseInt(getValueByPath(b, headers.year.path), 10);
        const monthA = getValueByPath(a, headers.month.path);
        const monthB = getValueByPath(b, headers.month.path);

        // Compare years first
        if (yearA !== yearB) return yearB - yearA;

        // Compare months within the same year
        return monthB.localeCompare(monthA, undefined, { numeric: true });
      });

      setCommittees(sortedCommittees);
    } catch (error) {
      console.error(error);
    }
  };

  const renderCommittees = (experience) => {
    const name = getValueByPath(experience, headers.name.path);
    const memberType = getValueByPath(experience, headers.member_type.path);
    const month = getValueByPath(experience, headers.month.path);
    const year = getValueByPath(experience, headers.year.path);

    return (
 
 
      <div className='flex items-start justify-start gap-4 px-2 py-2 m-1 ml-5 border-l-2 border-l-green-600'>
        <div className=''>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          <span className='px-3 py-1 mb-1 text-sm font-normal text-gray-500 bg-green-100 rounded-full'>
 
            {memberType}
          </span>
          <span className='flex items-center gap-1'>
            <FaCalendar className='text-yellow-500' />
            Since {month} {year}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        <FaUsers />
        Memberships in Significant Committees{" "}
      </h2>
      {committees.length ? (
        <div className="">
          {committees.map((committees, index) => (
            <div key={index} className="">
              {renderCommittees(committees)}
            </div>
          ))}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default Committee;
