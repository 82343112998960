import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const ConferenceSeminar = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [conferenceSeminars, setConferenceSeminars] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    conference_name: "",
    conference_type: "",
    organizer: "",
    conference_start_date: "",
    conference_end_date: "",
    author_names: "",
    published: "",
    conference_proceeding_title: "",
    published_url: "",
    month: "",
    year: "",
    page_no: "",
    editor_name: "",
    publisher_name: "",
    indexed_in: "",
    issn_isbn: "",
  });
  const headers = {
    title: {
      label: "Title of the Paper",
      path: "title",
    },
    conference_name: {
      label: "Name of the Conference/Seminar",
      path: "conference_name",
    },
    conference_type: {
      label: "Type of the Conference/Seminar",
      path: "conference_type",
    },
    organizer: { label: "Organizer", path: "organizer" },
    conference_start_date: {
      label: "Start Date",
      path: "conference_start_date",
    },
    conference_end_date: { label: "End Date", path: "conference_end_date" },
    author_names: { label: "Author Names", path: "author_names" },
    published: { label: "Published", path: "published" },
    published_url: { label: "Published URL", path: "published_url" },
    conference_proceeding_title: {
      label: "Title of the Conference Proceeding",
      path: "conference_proceeding_title",
    },
    month: { label: "Month of Publish", path: "monthName" },
    year: { label: "Year of Publish", path: "year" },
    page_no: { label: "Page No", path: "page_no" },
    editor_name: { label: "Editor Name", path: "editor_name" },
    publisher_name: { label: "Publisher Name", path: "publisher_name" },
    indexed_in: { label: "Indexed In", path: "indexed_in" },
    issn_isbn: { label: "ISSN/ISBN", path: "issn_isbn" },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    conference_name: Yup.string().required("This field is required!"),
    conference_type: Yup.string().required("This field is required!"),
    organizer: Yup.string().required("This field is required!"),
    conference_start_date: Yup.string().required("This field is required!"),
    conference_end_date: Yup.string().required("This field is required!"),
    author_names: Yup.string().required("This field is required!"),
    published: Yup.string().required("This field is required!"),

    conference_proceeding_title: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    month: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    published_url: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().notRequired(),
    }),

    year: Yup.number().when("published", {
      is: "Yes",
      then: Yup.number()
        .typeError("Year must be a valid year")
        .required("This field is required!")
        .min(1980, "Year must be at least 1980")
        .max(new Date().getFullYear(), `Year can't be in the future`),
      otherwise: Yup.number().notRequired(),
    }),
    editor_name: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    issn_isbn: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    page_no: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    publisher_name: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    indexed_in: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/conference-seminars");
      setConferenceSeminars(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/conference-seminars/${editId}`, values);
        toast.success(`Conference Seminar Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/conference-seminars", values);
        toast.success(`Conference Seminar Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);

      // Check if the error response contains a message about duplicate titles
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;

        // Check for duplicate title error message
        if (errorMessage.toLowerCase().includes("duplicate")) {
          toast.error(
            "Duplicate title detected. Please use a different title."
          );
        } else {
          // Handle other error messages
          toast.error(errorMessage);
        }
      } else {
        // Generic error handler if no specific error message is available
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = conferenceSeminars.find(
        (conferenceSeminar) => conferenceSeminar.id === id
      );
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        conference_name: editableData.conference_name,
        conference_type: editableData.conference_type,
        organizer: editableData.organizer,
        conference_start_date: editableData.conference_start_date,
        conference_end_date: editableData.conference_end_date,
        author_names: editableData.author_names,
        published: editableData.published,
        conference_proceeding_title: editableData.conference_proceeding_title,
        published_url: editableData.published_url,
        month: editableData.month,
        year: editableData.year,
        page_no: editableData.page_no,
        editor_name: editableData.editor_name,
        publisher_name: editableData.publisher_name,
        indexed_in: editableData.indexed_in,
        issn_isbn: editableData.issn_isbn,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      conference_name: "",
      conference_type: "",
      organizer: "",
      conference_start_date: "",
      conference_end_date: "",
      author_names: "",
      published: "",
      published_url: "",
      conference_proceeding_title: "",
      month: "",
      year: "",
      page_no: "",
      editor_name: "",
      publisher_name: "",
      indexed_in: "",
      issn_isbn: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      conference_name: "",
      conference_type: "",
      organizer: "",
      conference_start_date: "",
      conference_end_date: "",
      author_names: "",
      published: "",
      conference_proceeding_title: "",
      published_url: "",
      month: "",
      year: "",
      page_no: "",
      editor_name: "",
      publisher_name: "",
      indexed_in: "",
      issn_isbn: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    try {
      await BaseApi().delete(`/profile/conference-seminars/${id}`);
      toast.success(`Conference Seminar Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Conference/Seminar Paper :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={conferenceSeminars}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default ConferenceSeminar;
