import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BaseApi from "../../Http/BaseApi";
import moment from "moment";
import { BasePayrollDomain } from "../../env";
import axios from "axios";
import { FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";

function PaySlips() {
  const [paySlips, setPaySlips] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    BaseApi()
      .get(`pay-slips`)
      .then((response) => {
        setPaySlips(response.data);
      })
      .catch(() => {
        toast.error("Error on fetching pay slips", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          progress: undefined,
        });
      });
  }, []);

  const handleDownload = async (paySlip) => {
    setLoading(true);
    const url = `${BasePayrollDomain}/salaryRecords/salarySlip?staff_id=${
      paySlip.staff_id
    }&sal_pbr_id=${paySlip.sal_pbr_id}&month=${moment(
      paySlip.salary_month
    ).format("YYYY-MM")}`;

    try {
      const response = await axios.get(url, {
        responseType: "blob", // Important for handling binary data
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        `salarySlip_${moment(paySlip.salary_month).format("YYYY-MM")}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl); // Clean up URL object
      setLoading(false);
    } catch (error) {
      toast.error("Error downloading the PDF", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };
  return (
    <div className='min-h-screen p-10 bg-gray-100'>
      <h1 className='mb-6 text-3xl font-semibold text-left text-gray-800'>
        Download Pay Slips
        {/* report a problem */}
        <Link
          to='/report-problem'
          className='ml-4 text-sm text-red-600 hover:underline'
        >
          Report a problem
        </Link>
      </h1>

      <div className='overflow-x-auto'>
        <table className='min-w-full overflow-hidden bg-white rounded-sm shadow'>
          <thead className='text-white bg-gray-800'>
            <tr>
              <th className='px-6 py-3 text-xs font-medium tracking-wider text-left uppercase'>
                Year
              </th>
              <th className='px-6 py-3 text-xs font-medium tracking-wider text-left uppercase'>
                Month
              </th>
              <th className='px-6 py-3 text-xs font-medium tracking-wider text-left uppercase'>
                Download
              </th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {paySlips.map((paySlip) => (
              <tr key={paySlip.id}>
                <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                  {moment(paySlip.salary_month).format("YYYY")}
                </td>
                <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                  {moment(paySlip.salary_month).format("MMMM")}
                </td>
                <td className='w-64 px-6 py-4 whitespace-nowrap'>
                  <button
                    onClick={() => handleDownload(paySlip)}
                    disabled={loading}
                    className='flex items-center text-indigo-600 hover:text-indigo-900'
                  >
                    <FaFileDownload className='inline mr-1 text-sm' />
                    Download{loading ? "ing" : ""}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='my-5'>
          ** Payslips prior to March, 2024 will be available soon.
        </div>
      </div>
    </div>
  );
}

export default PaySlips;
