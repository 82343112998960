import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";
import Alert from "../../../Components/Utils/Alert";
import Button from "../../../Components/Utils/Button";
import BaseApi from "../../../Http/BaseApi";
// import Select from "../../Components/Utils/Select";
import * as Yup from "yup";
import { YearSemesters } from "../../../Constants/YearSemesters";
import { toast } from "react-toastify";

// import tableToCSV from "../../../Components/Utils/TableToCSV";
import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../../Redux/MasterSlice";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { Form, Formik } from "formik";
import Input from "../../../Components/Utils/Input";
import PendingDetails from "./PendingDetails";
import Select from "../../../Components/Utils/Select";
import Pagination from "../../../Components/Utils/Pagination";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function PendingPayments() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState();
  const [paginations, setPaginations] = useState();
  const formikRef = useRef();
  const formikRef2 = useRef();

  const [searchInput, setSearchInput] = useState("");
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const authData = useSelector((state) => state.auth);

  const [initialValues2, setInitialValues2] = useState({
    application_no_erp_id: "",
    status: 0,
  });

  // const { message } = useSelector((state) => state.message);
  const [showModal, setShowModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(false);

  let errorMessage = useSelector((state) => state.applicants.errorMessage);
  // let formSections = useSelector((state) => state.applicants.formSections);

  const initialValues = {
    from_date: null,
    to_date: null,
    payment_type: null,
    programme_id: null,
    semester: null,
    academic_session_id: null,
    status: 0,
    nor: 10,
  };

  let validationSchema = null;
  if (authData.panel == "staff") {
    validationSchema = Yup.object().shape({
      from_date: Yup.string().nullable().required("This field is required!"),
      to_date: Yup.string().nullable().required("This field is required!"),
      // programme_id: Yup.string().nullable().required("This field is required!"),
      // semester: Yup.string().nullable().required("This field is required!"),
      // academic_session_id: Yup.string()
      //   .nullable()
      //   .required("This field is required!"),
    });
  } else {
    validationSchema = Yup.object().shape({
      // from_date: Yup.string().nullable().required("This field is required!"),
      // to_date: Yup.string().nullable().required("This field is required!"),
      programme_id: Yup.string().nullable().required("This field is required!"),
      semester: Yup.string().nullable().required("This field is required!"),
      academic_session_id: Yup.string()
        .nullable()
        .required("This field is required!"),
    });
  }

  const validationSchema2 = Yup.object().shape({
    application_no_erp_id: Yup.string().required("This field is required!"),
  });

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callPendingPayment(null, searchQuery);
    setLoading(false);
  };

  //   const dispatch = useDispatch();
  useEffect(() => {
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    setLoading(false);
    if (authData.panel == "staff") {
      // callPendingPayment({ status: 0 });
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  useEffect(() => {
    // Get the URL search parameters
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNoErpId = urlParams.get("application_no_erp_id");

    // Set the application number in the state
    if (applicationNoErpId) {
      setInitialValues2({
        application_no_erp_id: applicationNoErpId,
        status: 0,
      });

      handleSearch({ application_no_erp_id: applicationNoErpId, status: 0 });
    }
  }, []);

  async function callPendingPayment(url, searchQuery) {
    console.log("as");
    setLoading(true);
    let _payments = null;
    if (url) {
      _payments = await BaseApi().get(url);
    } else {
      _payments = await BaseApi().get(`payments`, {
        params: searchQuery,
      });
    }

    setLoading(false);
    const { data, ...paginationInfo } = _payments.data;

    setPayments(data);
    setPaginations(paginationInfo);
  }
  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  const handleDeletePayment = async (payment, reason) => {
    var queryParams = null;
    if (payment.payment_type == "Admission") {
      queryParams = { remove_admission_permission: 1, deleteReason: reason };
    } else if (payment.payment_type == "Hostel") {
      queryParams = {
        remove_hostel_admission_permission: 1,
        deleteReason: reason,
      };
    } else {
      queryParams = { deleteReason: reason };
    }
    await BaseApi().delete(`payments/${payment.id}`, {
      params: queryParams,
    });
    let newPayments = payments.filter((e) => e.id != payment.id);
    setPayments(newPayments);
  };

  async function genReport() {
    if (
      formikRef.current.values.from_date == null ||
      formikRef.current.values.to_date == null
    ) {
      toast.error("Please select a date range", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }
    const response = await BaseApi().post(
      `reports/student-payments`,
      formikRef.current.values
    );
    // setMessage(response.data);

    toast.success(response.data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='List of Pending Payments' />

            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-2 p-5 pt-6  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6'>
                  {authData.panel == "staff" && (
                    <>
                      <Input type='date' name='from_date' label='From *' />
                      <Input type='date' name='to_date' label='To *' />
                    </>
                  )}
                  <Select label='Select Payment Category' name='payment_type'>
                    <option selected value={""}>
                      Select
                    </option>
                    <option value={"Admission"}>Admission</option>
                    <option value={"FeeCollection"}>
                      Renewal Fee Collection
                    </option>
                    <option value={"Hostel"}>Hostel Admission</option>
                    <option value={"HostelRenewal"}>Hostel Renewal</option>
                  </Select>
                  <CustomSelect
                    options={programmes}
                    name='programme_id'
                    label='Select Programme'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <CustomSelect
                    options={YearSemesters}
                    name='semester'
                    label='Select Semester'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "semester",
                        selectedOption.value
                      );
                    }}
                  />

                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Academic Session'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div className='mt-6 text-left '>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>

                  {authData.panel == "staff" && (
                    <>
                      <div className='mt-6 text-left '>
                        <button
                          type='button'
                          className='relative px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md group w-50 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                          onClick={genReport}
                        >
                          Generate Report
                        </button>
                      </div>
                      <div className='col-span-2 mt-6 text-left '>
                        <button
                          type='button'
                          onClick={() => {
                            window.open("/student-payments-report", "_blank");
                          }}
                          className='relative px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md group w-50 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                        >
                          Download Reports
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            </Formik>

            <Formik
              innerRef={formikRef2}
              enableReinitialize={true}
              initialValues={initialValues2}
              validationSchema={validationSchema2}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-8 p-5  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>
                  <Input
                    type='text'
                    name='application_no_erp_id'
                    label='Search By Application No./ERP Id.'
                    placeholder='Enter Application No./ERP Id.'
                  />

                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {payments?.length > 0 && (
              <>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='inline-block m-0 text-gray-700 form-label'
                >
                  Search By Name
                </label>
                <input
                  className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                  placeholder='Search from loaded data...'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}

            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>
                  <th className='table-head'>App. No. / Enroll. ID </th>
                  <th className='table-head'>Name</th>
                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Semester</th>
                  <th className='table-head'>Hostel</th>
                  <th className='table-head'>Amount</th>
                  <th className='table-head'>Type</th>
                  <th className='table-head'>Payment Type</th>
                  <th className='table-head'>Status</th>
                  <th className='table-head'>Created By</th>
                  <th className='table-head' colSpan='2'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments?.length > 0 &&
                  payments
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((payment, index) => {
                      return (
                        <>
                          <tr>
                            <td className='table-data'>
                              {index + paginations.from}
                            </td>
                            <td className='table-data'>
                              {payment.year_sem == 1 &&
                                payment.form_student?.id}

                              {payment.year_sem != 1 && (
                                <>
                                  {payment.programme_student?.unique_id
                                    ? payment.programme_student?.unique_id
                                    : payment.programme_student?.unique_idd}
                                </>
                              )}
                            </td>
                            <td className='table-data'>
                              {payment.student?.name}
                            </td>
                            <td className='table-data'>
                              {payment.programme?.name}
                            </td>

                            <td className='table-data'>{payment.year_sem}</td>
                            <td className='table-data'>
                              {payment.hostel?.name}
                            </td>
                            <td className='table-data'>{payment.amount}</td>
                            <td className='table-data'>{payment.type}</td>
                            <td className='table-data'>
                              {payment.payment_type}
                            </td>

                            <td className='table-data'>{payment.status}</td>
                            <td className='table-data'>
                              {payment.staff?.name || "Department"}
                            </td>
                            <td className='table-data'>
                              {authData.panel == "staff" &&
                                payment.payment_type != "Admission" &&
                                payment.payment_type != "Hostel" && (
                                  <Button
                                    classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                                    value={"Collect Offline"}
                                    icon={"CurrencyRupeeIcon"}
                                    onClick={() => {
                                      setShowModal(true);
                                      setSelectedPayment(payment);
                                    }}
                                  />
                                )}
                            </td>
                            <td className='table-data'>
                              {(authData.panel == "staff" ||
                                authData.user.id == payment.staff_id ||
                                (authData.panel == "department" &&
                                  payment.payment_type == "FeeCollection")) && (
                                <Button
                                  classNames='bg-red-600 hover:bg-red-700 focus:ring-red-500'
                                  value={"Delete"}
                                  onClick={() => {
                                    let reason = window.prompt(
                                      "Are you sure? Please enter a reason"
                                    );
                                    if (reason == null || reason.trim() == "") {
                                      return;
                                    } else {
                                      handleDeletePayment(payment, reason);
                                    }
                                  }}
                                  icon={"TrashIcon"}
                                />
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {payments?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}

            {paginations?.total > paginations?.per_page && (
              <div className='m-5'>
                <Pagination
                  links={paginations?.links}
                  onClick={(url) => {
                    callPendingPayment(url, null);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal ? (
        <PendingDetails
          onMakeFeeCollection={() => {
            const updatedPayments = payments.filter(
              (payment) => payment.id !== selectedPayment.id
            );
            setPayments(updatedPayments);
          }}
          selectedPayment={selectedPayment}
          setShowModal={setShowModal}
          loading={loading}
        />
      ) : null}
    </>
  );
}
