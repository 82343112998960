import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const JournalPublication = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [journalPublications, setJournalPublications] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    journal: "",
    journal_type: "",
    issn: "",
    month: "",
    year: "",
    corresponding_principal: "",
    funding_source: "",
    funding_source_details: "",
    author_names: "",
    url: "",
    doi: "",
    volume: "",
    issue: "",
    impact_factor: "",
    page_no: "",
    editor_name: "",
    publisher_name: "",
    indexed_in: "",
    indexed_in_others: "",
  });
  const headers = {
    title: { label: "Title of the Paper", path: "title" },
    journal: { label: "Journal Name", path: "journal" },
    journal_type: { label: "Journal Type", path: "journal_type" },
    issn: { label: "ISSN", path: "issn" },
    month: { label: "Month of Publish", path: "monthName" },
    year: { label: "Year of Publish", path: "year" },
    corresponding_principal: {
      label: "Are you corresponding/principal author?",
      path: "corresponding_principal",
    },
    funding_source: {
      label: "Funding Sources",
      path: "funding_source",
    },
    funding_source_details: {
      label: "Funding Source Details",
      path: "funding_source_details",
    },
    author_names: {
      label: "Author Names: (As appeared in the article)",
      path: "author_names",
    },
    url: { label: "URL/DOI (Optional)", path: "url" },
    volume: { label: "Volume", path: "volume" },
    issue: { label: "Issue", path: "issue" },
    impact_factor: {
      label: "Impact Factor (Thomson Reuters only) (Optional)",
      path: "impact_factor",
    },
    page_no: { label: "Page No/Paper No (Optional)", path: "page_no" },
    editor_name: { label: "Editor Name", path: "editor_name" },
    publisher_name: {
      label: "Publisher Name (Optional)",
      path: "publisher_name",
    },
    indexed_in: { label: "Indexed in", path: "indexed_in" },
    indexed_in_others: {
      label: "Indexed In others",
      path: "indexed_in_others",
    },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    journal: Yup.string().required("This field is required!"),
    journal_type: Yup.string().required("This field is required!"),
    issn: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    author_names: Yup.string().required("This field is required!"),
    volume: Yup.string().required("This field is required!"),
    issue: Yup.string().required("Issue is required"),
    indexed_in: Yup.string().required("This field is required!"),
    indexed_in_others: Yup.string().when("indexed_in", {
      is: "Others", // Check if 'indexed_in' is equal to 'Others'
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string(), // Optional: can leave this out if no other condition is needed
    }),
    corresponding_principal: Yup.string().required("This field is required!"),
    funding_source: Yup.string().required("This field is required!"),
    funding_source_details: Yup.string().when("funding_source", {
      is: "Yes", // Check if 'funding_source' is equal to 'Others'
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string(), // Optional: can leave this out if no other condition is needed
    }),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/journal-publications");
      setJournalPublications(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/journal-publications/${editId}`, values);
        toast.success(`Research Publication Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/journal-publications", values);
        toast.success(`Research Publication Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);

      // Check if the error response contains a message about duplicate titles
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;

        // Check for duplicate title error message
        if (errorMessage.toLowerCase().includes("duplicate")) {
          toast.error(
            "Duplicate title detected. Please use a different title."
          );
        } else {
          // Handle other error messages
          toast.error(errorMessage);
        }
      } else {
        // Generic error handler if no specific error message is available
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = journalPublications.find(
        (journalPublication) => journalPublication.id === id
      );
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        journal: editableData.journal,
        journal_type: editableData.journal_type,
        issn: editableData.issn,
        month: editableData.month,
        year: editableData.year,
        author_names: editableData.author_names,
        url: editableData.url,
        // doi: editableData.doi,
        volume: editableData.volume,
        impact_factor: editableData.impact_factor,
        issue: editableData.issue,
        page_no: editableData.page_no,
        editor_name: editableData.editor_name,
        publisher_name: editableData.publisher_name,
        indexed_in: editableData.indexed_in,
        indexed_in_others: editableData.indexed_in_others,
        corresponding_principal: editableData.corresponding_principal,
        funding_source: editableData.funding_source,
        funding_source_details: editableData.funding_source_details,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      journal: "",
      journal_type: "",
      issn: "",
      month: "",
      year: "",
      author_names: "",
      url: "",
      // doi: "",
      volume: "",
      impact_factor: "",
      issue: "",
      page_no: "",
      editor_name: "",
      publisher_name: "",
      indexed_in: "",
      indexed_in_others: "",
      corresponding_principal: "",
      funding_source: "",
      funding_source_details: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      journal: "",
      journal_type: "",
      issn: "",
      month: "",
      year: "",
      author_names: "",
      url: "",
      // doi: "",
      volume: "",
      impact_factor: "",
      issue: "",
      page_no: "",
      editor_name: "",
      publisher_name: "",
      indexed_in: "",
      indexed_in_others: "",
      corresponding_principal: "",
      funding_source: "",
      funding_source_details: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/journal-publications/${id}`);
      toast.success(`Research Publication Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Research Publication :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={journalPublications}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default JournalPublication;
