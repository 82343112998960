import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import Loader from "../../utils/Loader";

const Expertise = () => {
  const [expertise, setExpertise] = useState(); // Array to store qualifications
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await BaseApi().get("/profile/expertises");
      setExpertise(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Expertise
      </h2>

      {/* <TableViewProfile
        headers={headers}
        data={expertises}
        setData={setExpertises}
      /> */}
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="loader">
            <Loader />
          </div>{" "}
          {/* You can customize this with CSS */}
        </div>
      ) : (
        <div className="px-4">
          <div className="mb-4 text-sm rounded-md ">
            <h3 className="font-semibold text-md">Broad Area</h3>
            <p className="p-2 ">{expertise?.broad_area}</p>
          </div>
          <div className="mb-1 rounded-md ">
            <h4 className="text-sm font-semibold">
              Specialization/Area of Research
            </h4>
            {expertise?.specializations.map((spec, index) => (
              <div key={index} className="p-2 rounded-md ">
                <p>{spec.specialization}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Expertise;
