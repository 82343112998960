import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  title: { label: "Title of the Paper", path: "title" },
  journal: { label: "Journal Name", path: "journal" },
  journal_type: { label: "Journal Type", path: "journal_type" },
  issn: { label: "ISSN", path: "issn" },
  month: { label: "Month of Publish", path: "monthName" },
  year: { label: "Year of Publish", path: "year" },
  corresponding_principal: {
    label: "Are you corresponding/principal author?",
    path: "corresponding_principal",
  },
  funding_source: { label: "Funding Source", path: "funding_source" },
  funding_source_details: {
    label: "Funding Source Details",
    path: "funding_source_details",
  },
  author_names: {
    label: "Author Names: (As appeared in the article)",
    path: "author_names",
  },
  url: { label: "URL", path: "url" },
  doi: { label: "DOI", path: "doi" },
  volume: { label: "Volume", path: "volume" },
  page_no: { label: "Page No/Paper No (Optional)", path: "page_no" },
  editor_name: { label: "Editor Name", path: "editor_name" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed in", path: "indexed_in" },
  indexed_in_others: {
    label: "Indexed in others",
    path: "indexed_in_others",
  },
};

const JournalPublication = () => {
  const [journalPublications, setJournalPublications] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/journal-publications");
      setJournalPublications(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Research Publications
      </h2>
      <TableViewProfile
        headers={headers}
        data={journalPublications}
        type="journal-publications"
        setData={setJournalPublications}
      />
    </div>
  );
};

export default JournalPublication;
