import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import Modal from "../../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../../utils/TableComponent";
import { toast } from "react-toastify";

const PhdAwardee = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [phdAwardees, setPhdAwardees] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    researcher_name: "",
    role: "",
    thesis_title: "",
    // institute: "",
    // other_institute: "",
    status: "",
    month: "",
    year: "",
    // advisoryCommittee: "",
    iqac: "",
  });

  const headers = {
    researcher_name: {
      label: "Name of the Ph. D. Scholar",
      path: "researcher_name",
    },
    role: { label: "Role", path: "role" },
    thesis_title: { label: "Thesis Title", path: "thesis_title" },
    // institute: {
    //   label: "Ph. D. Scholar registered in DU/Other Institute",
    //   path: "institute",
    // },
    // other_institute: { label: "Other Institute", path: "other_institute" },
    status: { label: "Status", path: "status" },
    // advisoryCommittee: {
    //   label: "Members of the Research Advisory Committee",
    //   path: "advisoryCommittee",
    // },
    month: {
      label: "Awarded Month",
      path: "monthName",
    },
    year: {
      label: "Awarded Year",
      path: "year",
    },
    // awarded_date: { label: "Awarded Date", path: "awarded_date" },
  };

  const validationSchema = Yup.object({
    researcher_name: Yup.string().required("This field is required!"),
    role: Yup.string().required("This field is required!"),
    // thesis_title: Yup.string().required("This field is required!"),
    // institute: Yup.string().required("This field is required!"),
    // other_institute: Yup.string().when("institute", {
    //   is: "Other Institute",
    //   then: Yup.string().required("This field is required!"),
    //   otherwise: Yup.string().nullable(),
    // }),
    status: Yup.string().required("This field is required!"),
    // awarded_date: Yup.string().when("status", {
    //   is: "Completed",
    //   then: Yup.string().required("This field is required!"),
    //   otherwise: Yup.string(),
    // }),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    // advisoryCommittee: Yup.string().required(
    //   "This field is required!"
    // ),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/phd-awardees");
      setPhdAwardees(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/phd-awardees/${editId}`, values);
        toast.success("Ph. D. Awardee Updated Successfully");
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/phd-awardees", values);
        toast.success("Ph. D. Awardee Added Successfully");
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = phdAwardees.find(
        (phdAwardee) => phdAwardee.id === id
      );
      setInitialValues({
        id: editableData.id,
        researcher_name: editableData.researcher_name,
        role: editableData.role,
        thesis_title: editableData.thesis_title,
        // institute: editableData.institute,
        // other_institute: editableData.other_institute,
        status: editableData.status,
        month: editableData.month,
        year: editableData.year,
        // awarded_date: editableData.awarded_date,
        // advisoryCommittee: editableData.advisoryCommittee,
        iqac: editableData.iqac,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      role: "",
      // institute: "",
      // other_institute: "",
      status: "",
      month: "",
      year: "",
      // awarded_date: "",
      // advisoryCommittee: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      role: "",
      // institute: "",
      // other_institute: "",
      status: "",
      month: "",
      year: "",
      // advisoryCommittee: "",
      // awarded_date: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    try {
      await BaseApi().delete(`/profile/phd-awardees/${id}`);
      toast.success("Ph. D. Awardee Deleted Successfully");
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Ph.D Awardees :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <>
              <FormPage
                partialEdit={
                  initialValues.iqac == "yes" &&
                  initialValues.status == "Ongoing"
                }
                initialValues={initialValues} // Pass initial values for editing
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isEditMode={isEditing}
              />
            </>
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            editable={true}
            editCheckField="status"
            headers={headers}
            data={phdAwardees}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default PhdAwardee;
