import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaCakeCandles, FaFileLines, FaLink, FaUsers } from "react-icons/fa6";
import { FaCalendarCheck } from "react-icons/fa";

const headers = {
  type: { label: "Type ", path: "type" },
  others_type: { label: "Others Type ", path: "others_type" },
  modules: { label: "Number of Modules", path: "modules" },
  enrolled_student: {
    label: "Number of Enrolled Students",
    path: "enrolled_student",
  },
  url: { label: "URL", path: "url" },
  month: { label: "Month of Publish", path: "monthName" },
  year: { label: "Year of Publish", path: "year" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const EContentDevelopment = ({ view }) => {
  const [eContentDevelopments, setEContentDevelopments] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/e-content-developments");
      const filterField = view === "iqac" ? "iqac" : "website";
      setEContentDevelopments(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderConference = (eContentDevelopment) => {
    const type = getValueByPath(eContentDevelopment, headers.type.path);
    const othersType = getValueByPath(
      eContentDevelopment,
      headers.others_type.path
    );
    const modules = getValueByPath(eContentDevelopment, headers.modules.path);
    const enrolledStudent = getValueByPath(
      eContentDevelopment,
      headers.enrolled_student.path
    );
    const url = getValueByPath(eContentDevelopment, headers.url.path);
    const month = getValueByPath(eContentDevelopment, headers.month.path);
    const year = getValueByPath(eContentDevelopment, headers.year.path);

    return (
      <div className="items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600">
        <div className="p-2 space-y-2">
          <h3 className="text-lg font-medium text-blue-900">{type}</h3>
          <span className="flex items-center gap-1">
            {type === "yes" && <>[{othersType}],</>}
          </span>

          <span className="flex items-center gap-1">
            <FaFileLines className="text-yellow-500" />
            Number of Modules: {modules}
          </span>

          <div className="flex items-center gap-3">
            <span className="flex items-center gap-1">
              <FaUsers className="text-yellow-500" />
              Number of Enrolled Students: {enrolledStudent}
            </span>
          </div>
          <a href={url} className="flex items-center gap-1">
            <FaLink className="text-blue-600" />
            URL: {url}
          </a>
          <div className="flex items-center gap-3">
            <span className="flex items-center gap-1">
              <FaCalendarCheck className="text-yellow-500" />
              {year} - {month}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        E-Content Development
      </h2>
      {eContentDevelopments.length ? (
        <div>
          {eContentDevelopments.length ? (
            <div className="">
              {eContentDevelopments.map((eContentDevelopment, index) => (
                <div key={index} className="">
                  {renderConference(eContentDevelopment)}
                </div>
              ))}
            </div>
          ) : (
            <p className="py-4 text-center text-gray-500">No data found</p>
          )}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default EContentDevelopment;
