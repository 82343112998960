import React, { useEffect, useState } from "react";
import {
  BookOpenIcon,
  UserGroupIcon,
  UsersIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAsync,
  updateDashboardNotesAsync,
} from "../../Redux/MasterSlice";
import { Link, useNavigate } from "react-router-dom";

export default function DepartmentDashboard() {
  const dispatch = useDispatch();
  const [savingNote, setSavingNote] = useState(false);
  const navigate = useNavigate();

  const master = useSelector((state) => state.master);
  const dashboardData = { ...master.dashboardData };

  const [note, setNote] = useState(dashboardData.dashboardNote);
  const [timer, setTimer] = useState(null);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      saveNote({ note: value });
    }, 1000);
    setTimer(newTimer);
  };

  const saveNote = async (noteContent) => {
    setSavingNote(true);
    console.log("Note auto-saved:", noteContent);
    // Simulating a save operation (e.g., API call)
    // localStorage.setItem("quickNote", noteContent);
    await dispatch(updateDashboardNotesAsync(noteContent)).unwrap();
    setSavingNote(false);
  };

  useEffect(() => {
    if (Object.keys(dashboardData).length === 0) {
      dispatch(getDashboardAsync());
    }
  }, []);
  useEffect(() => {
    setNote(dashboardData.dashboardNote);
  }, [dashboardData]);

  return (
    <div className='min-h-screen bg-gray-100'>
      {/* Dashboard Stats */}
      <div className='flex flex-wrap justify-around gap-6 px-5 pt-5'>
        {/* Students Card */}
        <div
          onClick={() => navigate(`/student-counts`, "_self")}
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <UserGroupIcon className='w-12 h-12 text-purple-600' />
          <h3 className='mt-2 text-lg font-semibold text-gray-700'>
            Students <small className='text-xs text-gray-500'>2024-25</small>
          </h3>
          <p className='text-xl font-bold'>{dashboardData.studentCount}</p>
        </div>

        {/* Courses Card */}
        <div
          onClick={() => navigate(`/programmes`, "_self")}
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <BookOpenIcon className='w-12 h-12 text-green-600' />
          <h3 className='mt-2 text-lg font-semibold text-gray-700'>
            Programmes
          </h3>
          <p className='text-xl font-bold'>{dashboardData.programmeCount}</p>
        </div>

        {/* Teachers Card */}
        <div
          onClick={() => navigate(`/registered-phd-students-records`, "_self")}
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <UsersIcon className='w-12 h-12 text-red-600' />
          <h3 className='w-full mt-2 overflow-hidden text-lg font-semibold text-center text-gray-700 whitespace-nowrap overflow-ellipsis'>
            Registered Ph. D. Scholars
          </h3>
          <p className='text-xl font-bold'>{dashboardData.phdCount}</p>
        </div>

        {/* Security Card */}
        <div
          onClick={() => navigate(`/hostelers`, "_self")}
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <OfficeBuildingIcon className='w-12 h-12 text-yellow-600' />
          <h3 className='mt-2 text-lg font-semibold text-gray-700'>
            Hostel Boarders
          </h3>
          <p className='text-xl font-bold'>{dashboardData.hostelerCount}</p>
        </div>
      </div>

      {/* Recent Activity & Quick Actions */}
      <div className='grid grid-cols-2 gap-4 px-5 mt-8'>
        {/* Recent Activity Section */}
        <div className='w-full p-6 bg-white shadow-md rounded-xl '>
          <h2 className='pb-4 text-lg font-semibold text-gray-700 border-b'>
            Applicant Count (2024-2025)
          </h2>
          {/* Activity list placeholder */}
          <ul className='mt-4'>
            {dashboardData.applicantCounts?.map((applicantCount) => (
              <>
                {" "}
                <li className='flex items-center justify-between p-2 hover:bg-gray-50'>
                  <span>{applicantCount.name}</span>
                  {applicantCount.applicant_count}
                </li>
              </>
            ))}

            <li className='flex items-center justify-between p-2 hover:bg-gray-50'>
              <Link
                to='/applicants'
                className='flex items-center justify-center w-full text-blue-600'
                href='#!'
                data-mdb-ripple='true'
                data-mdb-ripple-color='primary'
              >
                View All
              </Link>
            </li>

            {/* More list items */}
          </ul>
        </div>

        {/* Quick Note Section */}
        <div className='w-full p-6 bg-white shadow-md rounded-xl '>
          <h2 className='pb-4 text-lg font-semibold text-gray-700 border-b'>
            Quick Note
            <small className='float-right text-gray-400'>
              {savingNote && "Saving..."}
            </small>
          </h2>
          <textarea
            className='w-full h-56 p-4 mt-2 text-base text-gray-700 bg-yellow-100 border-none rounded-md focus:ring-2 focus:ring-yellow-500 focus:outline-none'
            placeholder='Type your notes here...'
            value={note}
            onChange={handleNoteChange}
          ></textarea>
        </div>
      </div>
    </div>
  );
}
