import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import PageTitle from "../../../Components/Common/PageTitle";
import Alert from "../../../Components/Utils/Alert";
import BaseApi from "../../../Http/BaseApi";
// import Select from "../../Components/Utils/Select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { YearSemesters } from "../../../Constants/YearSemesters";
import { toast } from "react-toastify";

import {
  getAcademicSessionsAsync,
  getPaymentHeadsAsync,
  getProgrammesAsync,
} from "../../../Redux/MasterSlice";
import Button from "../../../Components/Utils/Button";
// import tableToCSV from "../../../Components/Utils/TableToCSV";
import PaymentEdit from "./PaymentEdit";
// import Input from "../../../Components/Utils/Input";

import moment from "moment";
import can from "../../../Middleware/Permission";
import messageSlice from "../../../Redux/MessageSlice";
import BasicInput from "../../../Components/Utils/BasicInput";
import Input from "../../../Components/Utils/Input";
import Select from "../../../Components/Utils/Select";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";
import Pagination from "../../../Components/Utils/Pagination";

export default function PaymentIndex() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState();
  const [deleteReason, setDeleteReason] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showERPIDModal, setShowERPIDModal] = useState(false);
  const [showTxnNoModal, setShowTxnNoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [paginations, setPaginations] = useState();

  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const paymentHeads = [...master.paymentHeads];
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  const authData = useSelector((state) => state.auth);

  // let formSections = useSelector((state) => state.applicants.formSections);

  const initialValuesERPID = {
    unique_id: "",
    status: 1,
  };
  const initialValuesTxnNo = {
    challan_no: "",
    status: 1,
  };

  const initialValues = {
    from_date: null,
    to_date: null,
    payment_type: null,
    type: null,
    programme_id: null,
    semester: null,
    academic_session_id: null,
    status: 1,
    nor: 10,
    head_wise: 0,
  };
  let validationSchema = null;
  const validationSchemaERPID = Yup.object().shape({
    unique_id: Yup.string().required("This field is required!"),
  });
  const validationSchemaTxnNo = Yup.object().shape({
    challan_no: Yup.string().required("This field is required!"),
  });
  if (authData.panel == "staff") {
    validationSchema = Yup.object().shape({
      from_date: Yup.string().nullable().required("This field is required!"),
      to_date: Yup.string().nullable().required("This field is required!"),
      // programme_id: Yup.string().nullable().required("This field is required!"),
      // semester: Yup.string().nullable().required("This field is required!"),
      // academic_session_id: Yup.string()
      //   .nullable()
      //   .required("This field is required!"),
    });
  } else {
    validationSchema = Yup.object().shape({
      // from_date: Yup.string().nullable().required("This field is required!"),
      // to_date: Yup.string().nullable().required("This field is required!"),
      programme_id: Yup.string().nullable().required("This field is required!"),
      semester: Yup.string().nullable().required("This field is required!"),
      academic_session_id: Yup.string()
        .nullable()
        .required("This field is required!"),
    });
  }

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callPayments(null, searchQuery);
    setShowERPIDModal(false);
    setShowTxnNoModal(false);
    setLoading(false);
  };

  //   const dispatch = useDispatch();
  useEffect(() => {
    // callPayments();
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function callPayments(url, searchQuery) {
    if (paymentHeads.length === 0) {
      dispatch(getPaymentHeadsAsync());
    }
    console.log("as");
    setLoading(true);

    let _payments = null;
    if (url) {
      _payments = await BaseApi().get(url);
    } else {
      _payments = await BaseApi().get(`payments`, {
        params: searchQuery,
      });
    }

    setLoading(false);
    // setPayments(_payments.data);
    const { data, ...paginationInfo } = _payments.data;

    setPayments(data);
    setPaginations(paginationInfo);
  }

  const onEdit = (updatedPayment) => {
    console.log(updatedPayment);
    const _payments = [...payments];
    const index = _payments.findIndex((p) => p.id == updatedPayment.id);
    _payments[index] = updatedPayment;
    setPayments(_payments);
  };

  const handleDelete = async (event, payload, deleteReason) => {
    if (deleteReason == null || deleteReason.trim() == "") {
      alert("Enter a reason.");
      return;
    }
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`payments/${payload.id}`, {
          params: { deleteReason },
        })
        .then(() => {
          let _payments = payments.filter((p) => p.id != payload.id);
          console.log(_payments);
          setPayments(_payments);
          setDeleteReason();
        })
        .catch((error) => {
          setDeleteReason();
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        });
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  //   const handleDeletePayment = async (payment) => {
  //     await BaseApi().delete(`payments/${payment.id}`, {
  //       params: { remove_admission_permission: 1 },
  //     });
  //     let newPayments = payments.filter((e) => e.id != payment.id);
  //     setPayments(newPayments);
  //   };

  async function genReport() {
    if (
      formikRef.current.values.from_date == null ||
      formikRef.current.values.to_date == null
    ) {
      toast.error("Please select a date range", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }
    const response = await BaseApi().post(
      `reports/student-payments`,
      formikRef.current.values
    );
    // setMessage(response.data);

    toast.success(response.data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='List of Successful Payments' />
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-2 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6'>
                  {authData.panel == "staff" && (
                    <>
                      {" "}
                      <Input type='date' name='from_date' label='From *' />
                      <Input type='date' name='to_date' label='To *' />
                      <Select
                        label='Select Payment Category'
                        name='payment_type'
                      >
                        <option selected value={""}>
                          Select
                        </option>
                        <option value={"Admission"}>Admission</option>
                        <option value={"FeeCollection"}>
                          Renewal Fee Collection
                        </option>
                        <option value={"Hostel"}>Hostel Admission</option>
                        <option value={"HostelRenewal"}>Hostel Renewal</option>
                      </Select>
                      <Select label='Select Payment Type' name='type'>
                        <option selected value={""}>
                          Select
                        </option>

                        <option value={"Fee Waived"}>Fee Waived</option>
                      </Select>
                    </>
                  )}

                  <CustomSelect
                    options={programmes}
                    name='programme_id'
                    label='Select Programme'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <CustomSelect
                    options={YearSemesters}
                    name='semester'
                    label='Select Semester'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "semester",
                        selectedOption.value
                      );
                    }}
                  />

                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Academic Session'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />
                  <Select name='head_wise' label='Show Headwise'>
                    <option value='1'>Yes</option>
                    <option value='0'>No</option>
                  </Select>

                  <div className='mt-6 text-left '>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                  {authData.panel == "staff" && (
                    <>
                      <div className='mt-6 text-left '>
                        <button
                          type='button'
                          className='relative px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md group w-50 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                          onClick={genReport}
                        >
                          Generate Report
                        </button>
                      </div>
                      <div className='col-span-2 mt-6 text-left '>
                        <button
                          type='button'
                          onClick={() => {
                            window.open("/student-payments-report", "_blank");
                          }}
                          className='relative px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md group w-50 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                        >
                          Download Reports
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            </Formik>
            <div className='p-5 text-left '>
              <button
                type='button'
                className='relative px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md group w-50 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                disabled={loading}
                onClick={() => {
                  setShowERPIDModal(true);
                }}
              >
                Search By Enrolment ID
              </button>
              <button
                type='button'
                className='relative px-4 py-2 ml-5 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md group w-50 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                disabled={loading}
                onClick={() => {
                  setShowTxnNoModal(true);
                }}
              >
                Search By Txn. No.
              </button>
            </div>
            {showERPIDModal && (
              <>
                <div className='fixed inset-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                  <div className='relative w-auto max-w-3xl mx-auto my-6'>
                    {/*content*/}

                    <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                        <h3 className='text-3xl font-semibold'>
                          Search By ERP ID
                        </h3>
                      </div>
                      {/*body*/}
                      <p style={{ width: "700px" }}></p>
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValuesERPID}
                        validationSchema={validationSchemaERPID}
                        onSubmit={handleSearch}
                      >
                        <Form>
                          <div>
                            <div className='relative flex-auto p-6'>
                              <div className='grid grid-cols-2 gap-4'>
                                <Input label='ERP ID.' name='unique_id' />
                              </div>

                              <p className='my-4 text-lg leading-relaxed text-slate-500'></p>
                            </div>
                            {/*footer*/}
                            <div className='flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200'>
                              <button
                                className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                                type='button'
                                onClick={() => setShowERPIDModal(false)}
                              >
                                Close
                              </button>

                              <Button
                                disabled={loading}
                                value={loading ? "Searching..." : "Search"}
                                icon={"SearchIcon"}
                                type='submit'
                              />
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
                <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
              </>
            )}

            {showTxnNoModal && (
              <>
                <div className='fixed inset-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                  <div className='relative w-auto max-w-3xl mx-auto my-6'>
                    {/*content*/}

                    <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                        <h3 className='text-3xl font-semibold'>
                          Search By Trancation No.
                        </h3>
                      </div>
                      {/*body*/}
                      <p style={{ width: "700px" }}></p>
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValuesTxnNo}
                        validationSchema={validationSchemaTxnNo}
                        onSubmit={handleSearch}
                      >
                        <Form>
                          <div>
                            <div className='relative flex-auto p-6'>
                              <div className='grid grid-cols-2 gap-4'>
                                <Input
                                  label='Transaction No.'
                                  name='challan_no'
                                />
                              </div>

                              <p className='my-4 text-lg leading-relaxed text-slate-500'></p>
                            </div>
                            {/*footer*/}
                            <div className='flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200'>
                              <button
                                className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                                type='button'
                                onClick={() => setShowTxnNoModal(false)}
                              >
                                Close
                              </button>

                              <Button
                                disabled={loading}
                                value={loading ? "Searching..." : "Search"}
                                icon={"SearchIcon"}
                                type='submit'
                              />
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
                <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
              </>
            )}

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            {/* <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            /> */}
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            {payments?.length > 0 && (
              <>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='inline-block m-0 text-gray-700 form-label'
                >
                  Search By Name
                </label>
                <input
                  className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                  placeholder='Search from loaded data...'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Name</th>
                  <th className='table-head'>Enrollment ID</th>
                  <th className='table-head'>Phone</th>
                  <th className='table-head'>DHE ID</th>
                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Semester</th>
                  <th className='table-head'>Ref. No.</th>
                  <th className='table-head'>Receipt No.</th>
                  <th className='table-head'>Date</th>
                  <th className='table-head'>Type</th>
                  <th className='table-head'>Category</th>
                  {formikRef.current?.values.head_wise == 1 &&
                    paymentHeads.length > 0 &&
                    paymentHeads.map((head) => {
                      return (
                        <>
                          <th className='table-head'>{head.name}</th>
                        </>
                      );
                    })}
                  <th className='table-head'>Total Amount</th>
                  <th className='table-head'>Paid</th>
                  <th className='table-head'>Balance</th>

                  <th className='table-head'>Created By</th>
                  <th className='table-head' colSpan='3'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments?.length > 0 &&
                  payments
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((payment, index) => {
                      return (
                        <>
                          <tr>
                            <td className='table-data'>
                              {index + paginations.from}
                            </td>
                            <td className='table-data'>
                              {payment.student?.name}
                            </td>
                            <td className='table-data'>
                              {payment.programme_student?.unique_id
                                ? payment.programme_student?.unique_id
                                : payment.programme_student?.unique_idd}
                            </td>{" "}
                            <td className='table-data'>
                              {payment.student?.phone}
                            </td>
                            <td className='table-data'>
                              {payment.student?.dhe_unique_id}
                            </td>
                            <td className='table-data'>
                              {payment.programme?.code}
                            </td>
                            <td className='table-data'>{payment.year_sem}</td>
                            <td className='table-data'>{payment.challan_no}</td>
                            <td className='table-data'>
                              {payment.receipt_no}
                              {"-"}
                              {payment.id}
                            </td>
                            <td className='table-data'>
                              {moment(payment.pay_date).format("Do MMM YYYY")}
                            </td>
                            <td className='table-data'>{payment.type}</td>
                            <td className='table-data'>
                              {payment.payment_type}
                            </td>
                            {formikRef.current?.values.head_wise == 1 &&
                              paymentHeads.length > 0 &&
                              paymentHeads.map((head) => {
                                return (
                                  <>
                                    <td className='table-data'>
                                      {
                                        payment?.payment_details?.find(
                                          (details) =>
                                            details.payment_head_id == head.id
                                        )?.amount
                                      }
                                    </td>
                                  </>
                                );
                              })}
                            <td className='table-data'>
                              {payment.total_amount}
                            </td>
                            <td className='table-data'>{payment.amount}</td>
                            <td className='table-data'>
                              {payment.balance_amount}
                            </td>
                            <td className='table-data'>
                              {payment.staff?.name || "Department"}
                            </td>
                            <td className='table-data'>
                              <a
                                target='_blank'
                                href={`https://staffapi.dibru.work/print_payment/${payment.id}`}
                                className=''
                                rel='noreferrer'
                              >
                                View
                              </a>
                            </td>
                            <td className='table-data'>
                              {can("Edit Payments") && (
                                <span
                                  className='px-4 py-2 font-bold text-white bg-orange-500 rounded hover:bg-orange-700'
                                  onClick={() => {
                                    setSelectedPayment(payment);
                                    setShowModal(true);
                                    // navigate(
                                    //   `/applicants/make-admission/${applicant.id}`
                                    // );
                                  }}
                                >
                                  Edit
                                </span>
                              )}
                            </td>
                            <td className='table-data'>
                              {can("Delete Payments") &&
                                (deleteConfirm.state == 0 ||
                                  deleteConfirm.itemId != payment.id) && (
                                  <button
                                    className='px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700'
                                    onClick={() =>
                                      setDeleteConfirm({
                                        itemId: payment.id,
                                        state: 1,
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                )}
                              {deleteConfirm.state == 1 &&
                                deleteConfirm.itemId == payment.id && (
                                  <>
                                    <p className='text-red-700'>
                                      Are You Sure?
                                    </p>
                                    <BasicInput
                                      label='Reason'
                                      name='reason'
                                      onChange={(event) => {
                                        setDeleteReason(event.target.value);
                                      }}
                                    />
                                    <button
                                      className='outline-button-danger'
                                      onClick={(event) =>
                                        handleDelete(
                                          event,
                                          payment,
                                          deleteReason
                                        )
                                      }
                                    >
                                      Yes
                                    </button>
                                    <span className='mr-2'></span>
                                    <button
                                      className='outline-button-success'
                                      onClick={() => setDeleteConfirm({})}
                                    >
                                      No
                                    </button>
                                  </>
                                )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {payments?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}

            {paginations?.total > paginations?.per_page && (
              <div className='m-5'>
                <Pagination
                  links={paginations?.links}
                  onClick={(url) => {
                    callPayments(url, null);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal ? (
        <PaymentEdit
          onEdit={onEdit}
          payment={selectedPayment}
          setShowModal={setShowModal}
          loading={loading}
        />
      ) : null}
    </>
  );
}
