import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import {
  FaCalendarDays,
  FaUser,
  FaThumbsUp,
  FaCalendarCheck,
} from "react-icons/fa6";
import { MdSubtitles } from "react-icons/md";
import moment from "moment";

const headers = {
  program_type: { label: "Type of the Programme", path: "program_type" },
  other_program_type: {
    label: "Other Programme",
    path: "other_program_type",
  },
  programme: { label: "Title of the Programme", path: "programme" },
  role: { label: "Role", path: "role" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  published: {
    label: "Whether any proceeding published?",
    path: "published",
  },
  proceeding_title: {
    label: "Title of the  Proceeding",
    path: "proceeding_title",
  },
  month: { label: "Month of Publish", path: "monthName" },
  year: { label: "Year of Publish", path: "year" },
  editor_name: { label: "Editor Name with Affiliation", path: "editor_name" },
  issn_isbn: { label: "ISSN/ISBN", path: "issn_isbn" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed In", path: "indexed_in" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const ProgrammeOrganized = ({ view }) => {
  const [programmeOrganizeds, setProgrammeOrganizeds] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/programme-organizeds");
      const filterField = view === "iqac" ? "iqac" : "website";

      // Filter for iqac or website
      const filteredSeminars = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year, month, and day from the start date
      const sortedSeminars = filteredSeminars.sort((a, b) => {
        // Parse the conference start date (assumes YYYY-MM-DD format)
        const dateA = new Date(a.conference_start_date);
        const dateB = new Date(b.conference_start_date);

        // Sort in descending order (latest first)
        return dateB - dateA;
      });

      setProgrammeOrganizeds(sortedSeminars);
    } catch (error) {
      console.error(error);
    }
  };

  const renderConference = (publication) => {
    const programType = getValueByPath(publication, headers.program_type.path);
    const otherProgramType = getValueByPath(
      publication,
      headers.other_program_type.path
    );
    const programme = getValueByPath(publication, headers.programme.path);
    const role = getValueByPath(publication, headers.role.path);

    const fromDate = formatDate(
      getValueByPath(publication, headers.from_date.path)
    );

    const toDate = formatDate(
      getValueByPath(publication, headers.to_date.path)
    );

    const published = getValueByPath(publication, headers.published.path);
    const proceedingTitle = getValueByPath(
      publication,
      headers.proceeding_title.path
    );
    const editorName = getValueByPath(publication, headers.editor_name.path);
    const year = getValueByPath(publication, headers.year.path);
    const month = getValueByPath(publication, headers.month.path);

    return (
 
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <p className='flex items-center gap-2'>
 
            <FaCalendarCheck />
            <span className='text-green-600'>
              {programType === "Other" ? otherProgramType : programType}
            </span>
          </p>
          <h3 className='text-lg font-medium text-blue-900'>{programme}</h3>
          <span className='flex items-center gap-1'>
            <FaThumbsUp className='text-yellow-500' />
            Role: {role}
          </span>
            <span> From Date: {fromDate} - To Date: {toDate}</span>

          {published === "Yes" && (
            <>
              <div className='flex items-center gap-3'>
                <span className='flex items-center gap-1'>
                  <MdSubtitles className='mt-1 text-yellow-500' />
                  {proceedingTitle}
                </span>

                <span className='flex items-center gap-1'>
                  <FaUser className='text-yellow-500' />
                  {editorName}
                </span>
              </div>
              <span className='flex items-center gap-1'>
                <FaCalendarDays className='text-yellow-500' />
                {year} - {month}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Programmes Organized
      </h2>
      {programmeOrganizeds.length ? (
        <div className=''>
          {programmeOrganizeds.map((publication, index) => (
            <div key={index} className=''>
              {renderConference(publication)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default ProgrammeOrganized;
