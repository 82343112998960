import React, { useState, useEffect } from "react";
import {
  MdArrowBack,
  MdDeleteOutline,
  MdOutlineEditCalendar,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import Button from "../../Components/Utils/Button";
import BaseApi from "../../Http/BaseApi"; // Assuming this is your API handler
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FaFileAlt } from "react-icons/fa";

const ListOfFiles = () => {
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to get query params

  const [searchTerm, setSearchTerm] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    searchParams?.get("status") ?? "files_in_branch"
  );

  // useEffect(() => {
  //   // Fetch all files on initial load
  //   fetchFiles();
  // }, []);

  useEffect(() => {
    // Get the 'status' query param value

    const status = searchParams.get("status") ?? "files_in_branch";

    setActiveTab(status);

    // Fetch files based on the 'status' query param
    fetchFiles("", "&status=" + status);
  }, []);

  useEffect(() => {
    // Fetch files based on the active
    setSearchParams({ status: activeTab });
  }, [activeTab]);
  // Fetch files based on the button clicked and the search term
  const fetchFiles = async (term = "", query = "") => {
    console.log(term, query);

    console.log(activeTab);

    if (activeTab == "search" && term.trim() === "") {
      return;
    }
    setLoading(true);
    try {
      const response = await BaseApi().get(`/ft-files?search=${term}${query}`);
      setFiles(response.data); // Assuming response.data contains the list of files
      setLoading(false);
    } catch (error) {
      console.error("Error fetching files:", error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setActiveTab("search");
    if (searchTerm.trim() === "") {
      toast.error("Please enter a search term");
      return;
    }
    fetchFiles(searchTerm); // Fetch files based on the search term
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await BaseApi().delete(`/ft-files/${id}`);
        toast.success("File deleted successfully");
        // Remove the deleted file from the list
        setFiles(files.filter((file) => file.id !== id));
      } catch (error) {
        toast.error("Error deleting file");
        console.error("Error deleting file:", error);
      }
    }
  };

  return (
    <div className='w-full p-5 bg-white'>
      {/* Filter Section */}
      <Link to='/file-tracking' className='text-sm text-blue-600 '>
        <MdArrowBack className='inline' /> File Tracking Dashboard
      </Link>
      <div className='flex items-center justify-between gap-4 mt-5 mb-6 text-center'>
        <h1 className='text-2xl font-semibold text-gray-800'>List of Files</h1>

        <div className='flex gap-2'>
          <Button
            onClick={() => {
              // Navigate to the create
              navigate(`/file-create`);
            }}
            classNames='w-full bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
            value={"Create new file"}
            icon={"PlusIcon"}
          />
        </div>
      </div>

      <div className='flex mb-6 border-b border-gray-300'>
        <div
          onClick={() => {
            setActiveTab("files_in_branch");
            setSearchTerm("");
            fetchFiles("", "&status=files_in_branch");
          }}
          className={`px-4 py-2 cursor-pointer ${
            activeTab === "files_in_branch"
              ? "border-b-2 border-teal-600 text-teal-600"
              : "text-gray-600 hover:text-teal-600"
          }`}
        >
          Files in Your Branch
        </div>

        <div
          onClick={() => {
            setActiveTab("created");
            setSearchTerm("");
            fetchFiles("", "&status=created");
          }}
          className={`px-4 py-2 cursor-pointer ${
            activeTab === "created"
              ? "border-b-2 border-blue-600 text-blue-600"
              : "text-gray-600 hover:text-blue-600"
          }`}
        >
          Created Files
        </div>

        <div
          onClick={() => {
            setActiveTab("pending_acceptances");
            setSearchTerm("");
            fetchFiles("", "&status=pending_acceptances");
          }}
          className={`px-4 py-2 cursor-pointer ${
            activeTab === "pending_acceptances"
              ? "border-b-2 border-yellow-600 text-yellow-600"
              : "text-gray-600 hover:text-yellow-600"
          }`}
        >
          Files Pending Acceptance
        </div>

        <div
          onClick={() => {
            setActiveTab("forwarded_not_accepted");
            setSearchTerm("");
            fetchFiles("", "&status=forwarded_not_accepted");
          }}
          className={`px-4 py-2 cursor-pointer ${
            activeTab === "forwarded_not_accepted"
              ? "border-b-2 border-orange-600 text-orange-600"
              : "text-gray-600 hover:text-orange-600"
          }`}
        >
          Forwarded Files yet to be Accepted
        </div>
        <div className={`flex mb-1 gap-2 `}>
          <input
            type='text'
            autoFocus={activeTab == "search"}
            value={searchTerm}
            onChange={handleInputChange}
            placeholder='Search by Title, File No, or Reference No'
            className={`w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 ${
              activeTab == "search" ? "border-purple-400" : ""
            }`}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch(); // Trigger search when Enter is pressed
              }
            }}
          />
          <Button
            classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
            value={"Search"}
            icon={"SearchIcon"}
            onClick={handleSearch}
          />
        </div>
      </div>

      {/* Table Section */}
      <div className='bg-white rounded-lg shadow-md '>
        {loading ? (
          <p className='px-6 py-4 text-center text-gray-800 '>Loading...</p>
        ) : files.length === 0 ? (
          <p className='px-6 py-4 text-center text-gray-800 '>
            No files found.
          </p>
        ) : (
          <table className='min-w-full text-xs bg-blue-100 divide-y divide-gray-200 rounded-lg'>
            <thead className=''>
              <tr>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  Sl. No
                </th>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  Title
                </th>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  File No
                </th>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  Ref. No
                </th>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  Dept./Branch/Office
                </th>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  Current Dept./Branch/Office
                </th>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  Created By
                </th>
                <th className='px-6 py-3 font-medium tracking-wider text-left text-gray-800 uppercase'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {files.map((file, index) => (
                <tr key={file.id}>
                  <td className='px-6 py-4 whitespace-nowrap'>{index + 1}</td>
                  <td className='px-6 py-4 whitespace-nowrap'>
                    {file.title} <br />
                    <Link
                      className='flex items-center justify-start mt-2 text-blue-600 cursor-pointer '
                      to={`/file-create?parent_id=${file.id}&type=Part`}
                    >
                      <FaFileAlt className='inline' /> Create Part File
                    </Link>
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap'>
                    {file.file_no} <br />
                    {file.type == "Part" && (
                      <span className='text-purple-500'>Part File</span>
                    )}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap'>
                    {file.reference_no}
                  </td>

                  <td className='px-6 py-4 whitespace-nowrap'>
                    {file.department?.name}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap'>
                    {file.current_department?.name ?? file.department?.name}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap'>
                    {file.created_by?.name}
                  </td>

                  <td className='px-6 py-4 whitespace-nowrap'>
                    <div className='flex items-center gap-2'>
                      <Link to={`/file-details/${file.id}`}>
                        <MdOutlineRemoveRedEye className='text-lg text-blue-600 cursor-pointer' />
                      </Link>

                      {file.can_delete && (
                        <MdDeleteOutline
                          className='text-lg text-red-600 cursor-pointer'
                          onClick={() => handleDelete(file.id)} // Call delete handler on click
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ListOfFiles;
