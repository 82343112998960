import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import { getHostelsAsync } from "../../Redux/MasterSlice";
import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
import moment from "moment";
import BasicInput from "../../Components/Utils/BasicInput";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import CustomSelect from "../../Components/Utils/CustomSelect";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function ExHostelerIndex() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [hostelers, setHostelers] = useState();
  const [searchInput, setSearchInput] = useState("");
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const hostels = [...master.hostels];
  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;
  const initialSearchValues = {
    hostel_id: "",
    ex: 1,
  };

  const validationSchema = Yup.object().shape({
    hostel_id: Yup.string().nullable().required("This field is required!"),
  });
  useEffect(() => {
    if (hostels.length === 0) {
      dispatch(getHostelsAsync())
        .unwrap()
        .then((data) => {
          console.log(data[0]);
          if (data?.length == 1) {
            handleSearch({
              hostel_id: data[0].id,
              ex: 1, //ex hostelers
            });
          }
        });
    } else {
      if (hostels?.length == 1) {
        handleSearch({
          hostel_id: hostels[0].id,
          ex: 1, //ex hostelers
        });
      }
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callHostelers(searchQuery);
    setLoading(false);
  };

  async function callHostelers(searchQuery) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`hostelers`, {
        params: searchQuery,
      })
      .then((data) => {
        setHostelers(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  const handleUpdate = async (payload) => {
    await BaseApi().post(`update-hostelers/${payload.id}`, payload);
    toast.success("Data Updated!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,

      progress: undefined,
    });
    let _hostelers = [...hostelers];
    console.log(_hostelers);
    if ("room_no" in payload) {
      _hostelers.find((h) => h.id == payload.id).room_no = payload.room_no;
    }

    if ("bed_no" in payload) {
      _hostelers.find((h) => h.id == payload.id).bed_no = payload.bed_no;
    }

    if ("in_date" in payload) {
      _hostelers.find((h) => h.id == payload.id).in_date = payload.in_date;
    }

    if ("out_date" in payload) {
      _hostelers.find((h) => h.id == payload.id).out_date = payload.out_date;
    }

    setHostelers(_hostelers);

    setLoading(false);
  };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  //   const handleDeletePayment = async (payment) => {
  //     await BaseApi().delete(`payments/${payment.id}`, {
  //       params: { remove_admission_permission: 1 },
  //     });
  //     let newPayments = payments.filter((e) => e.id != payment.id);
  //     setPayments(newPayments);
  //   };
  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='List of Ex-Hostel Boarders' />

            {hostels.length > 1 && (
              <Formik
                innerRef={formikRef}
                initialValues={initialSearchValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className='grid gap-2 p-5 pt-6  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <CustomSelect
                      options={hostels}
                      name='hostel_id'
                      label='Select Hostel'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "hostel_id",
                          selectedOption.id
                        );
                      }}
                    />

                    <div className='p-5 mt-1 text-left '>
                      <button
                        type='submit'
                        className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && <span>Searching...</span>}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}
            {hostelers?.length > 0 && (
              <div>
                <div>
                  {edit && hostels.length == 1 && (
                    <>
                      <Button
                        type='button'
                        classNames=' mr-2   bg-green-600 hover:bg-green-700 focus:ring-green-500'
                        value={"Done"}
                        icon={"CheckIcon"}
                        onClick={() => {
                          setEdit(false);
                        }}
                      />
                    </>
                  )}
                  {!edit && hostels.length == 1 && (
                    <Button
                      type='button'
                      classNames='mr-2   bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                      value={"Edit"}
                      icon={"PencilIcon"}
                      onClick={() => {
                        setEdit(true);
                      }}
                    />
                  )}
                </div>
                <div>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='inline-block mt-5 text-gray-700 form-label'
                  >
                    Search By Name
                  </label>
                  <input
                    className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                    placeholder='Type here...'
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </div>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Name</th>
                  <th className='table-head'>Guardian Contact No.</th>

                  <th className='table-head'>Enrollment ID</th>

                  <th className='table-head'>In Date</th>
                  <th className='table-head'>Out Date</th>
                  <th className='table-head'>Room No.</th>
                  <th className='table-head'>Bed No.</th>
                </tr>
              </thead>
              <tbody>
                {hostelers?.length > 0 &&
                  hostelers
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((hosteler) => {
                      return (
                        <>
                          <tr>
                            <td className='table-data'>{++sl}</td>

                            <td className='table-data'>
                              {hosteler.student?.name}
                            </td>
                            <td className='table-data'>
                              {hosteler.guardian_contact_no}
                            </td>
                            <td className='table-data'>
                              {hosteler.student?.programmes
                                .filter((p) => p.pivot.active == 1)
                                .map((prog) => {
                                  return (
                                    <span
                                      key={prog.pivot.id}
                                      className='text-blue-500 cursor-pointer'
                                      onClick={() =>
                                        window.open(
                                          `/admitted-students/${prog.pivot.id}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {
                                        <>
                                          {prog.pivot.unique_idd ||
                                            prog.pivot.unique_id}
                                        </>
                                      }

                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "15px",
                                          marginLeft: "1px",
                                        }}
                                      >
                                        {prog.pivot.discontinued == 1 &&
                                          "Programme Discontinued"}
                                        {prog.pivot.alumni == 1 && "Alumni"}
                                      </span>
                                      <br />
                                    </span>
                                  );
                                })}

                              {/* <span
                                className='text-blue-500 cursor-pointer'
                                onClick={() =>
                                  window.open(
                                    `/admitted-students/${
                                      hosteler.student?.programmes?.length >
                                        0 &&
                                      hosteler.student?.programmes[
                                        hosteler.student?.programmes.length - 1
                                      ].pivot.id
                                    }`,
                                    "_blank"
                                  )
                                }
                              >
                                {hosteler.student?.programmes?.length > 0 && (
                                  <>
                                    {hosteler.student?.programmes[
                                      hosteler.student?.programmes.length - 1
                                    ].pivot.unique_idd ||
                                      hosteler.student?.programmes[
                                        hosteler.student?.programmes.length - 1
                                      ].pivot.unique_id}
                                  </>
                                )}
                              </span> */}
                            </td>

                            <td className='table-data'>
                              {hosteler.in_date &&
                                moment(hosteler.in_date).format("Do MMM YYYY")}
                            </td>
                            <td className='table-data'>
                              {edit ? (
                                <BasicInput
                                  defaultValue={hosteler.out_date}
                                  type='date'
                                  onBlur={async (e) => {
                                    e.target.disabled = true;
                                    await handleUpdate({
                                      id: hosteler.id,

                                      out_date: e.target.value,
                                    }).catch((e) => {
                                      alert("Something Went Wrong!");
                                      e.target.disabled = false;
                                    });
                                    e.target.disabled = false;
                                    console.log(e.target.value);
                                  }}
                                />
                              ) : (
                                hosteler.out_date &&
                                moment(hosteler.out_date).format("Do MMM YYYY")
                              )}
                            </td>
                            <td className='table-data'>{hosteler.room_no}</td>
                            <td className='table-data'>{hosteler.bed_no}</td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}
            {hostelers?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
