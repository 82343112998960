import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  title: {
    label: "Title of the Patent",
    path: "title",
  },
  inventors_creators: {
    label: "Inventors",
    path: "inventors_creators",
  },
  applicant_name: {
    label: "Applicant’s Name",
    path: "applicant_name",
  },
  application_number: {
    label: "Application Number",
    path: "application_number",
  },
  status: {
    label: "Status",
    path: "status",
  },
  patent_number: {
    label: "Patent Number",
    path: "patent_number",
  },
  patent_granted_date: {
    label: "Patent Granted Date",
    path: "patent_granted_date",
  },
  filling_country: {
    label: "Filing Country",
    path: "filling_country",
  },
  invention: {
    label: "Field of Invention",
    path: "invention",
  },
  filling_date: {
    label: "Filing Date",
    path: "filling_date",
  },
  published_date: {
    label: "Published Date",
    path: "published_date",
  },
};

const Patent = () => {
  const [patents, setPatents] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/patents");
      setPatents(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Patent
      </h2>
      <TableViewProfile
        headers={headers}
        data={patents}
        type="patents"
        setData={setPatents}
      />
    </div>
  );
};

export default Patent;
