import React from "react";

export default function ActionConfirm(props) {
  let actionState = props.actionState;
  let data = props.data;
  let firstOnClick = props.firstOnClick;
  let btnLabel = props.btnLabel;
  let onYesClick = props.onYesClick;
  let onNoClick = props.onNoClick;
  return (
    <>
      {(actionState.state == 0 || actionState.itemId != data.id) && (
        <button
          className="bg-white hover:bg-white text-orange-700 border-2 border-orange-700 font-bold py-2 px-4 rounded"
          onClick={firstOnClick}
        >
          {btnLabel}
        </button>
      )}
      {actionState.state == 1 && actionState.itemId == data.id && (
        <>
          <p className="text-red-700"> Are You Sure? </p>

          <button className="outline-button-danger" onClick={onYesClick}>
            Yes
          </button>
          <span className="mr-2"></span>
          <button className="outline-button-success" onClick={onNoClick}>
            No
          </button>
        </>
      )}
    </>
  );
}
