import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  collaboration_name: {
    label: "Name of the Institute/ Organization/Individual",
    path: "collaboration_name",
  },
  collaboration_type: {
    label: "Type of Collaboration",
    path: "collaboration_type",
  },
  other_collaboration: {
    label: "Other Collaboration",
    path: "other_collaboration",
  },
  from_date: { label: "From Date Collaboration", path: "from_date" },
  to_date: { label: "To Date Collaboration", path: "to_date" },
  collaboration_objective: {
    label: "Objective of the Collaboration",
    path: "collaboration_objective",
  },
  collaboration_details: {
    label: "Level of the collaboration",
    path: "collaboration_details",
  },
};

const IqacCollaboration = () => {
  const [collaborations, setCollaborations] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/collaborations?from_iqac=1"
      );
      setCollaborations(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50'>
        <h1>Collaborations: </h1>
        <div className='flex items-end justify-center space-x-2 capitalize text-md'>
          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>From: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>To: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className=''>
            <Button
              type='filter'
              value='Filter'
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={collaborations}
        type='collaborations'
        setData={setCollaborations}
      />
    </div>
  );
};

export default IqacCollaboration;
