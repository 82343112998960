import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Alert from "../../Components/Utils/Alert";

import PageTitle from "../../Components/Common/PageTitle";
import { Form, Formik } from "formik";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import { YearSemesters } from "../../Constants/YearSemesters";
import { monthNameList } from "../../Constants/months";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function StudentAttendance() {
  const formikRef = useRef();
  const formikRef2 = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [years, setYears] = useState([]);
  const [students, setStudents] = useState({});
  const [edit, setEdit] = useState(false);
  const [attendances, setAttendances] = useState({});
  const [subjectmasters, setSubjectmasters] = useState({});
  const master = useSelector((state) => state.master);

  const academicSessions = [...master.academicSessions];

  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;
  const initialSearchValues = {
    subjectmaster_id: "",
  };
  const initialValues = {
    subjectmaster_id: "",

    month: "",
    year: "",
    total_class: "",
    details: [],
  };

  const validationSchema = Yup.object().shape({
    subjectmaster_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  const validationSchema2 = Yup.object().shape({
    month: Yup.string().nullable().required("This field is required!"),
    year: Yup.string().nullable().required("This field is required!"),
    total_class: Yup.string().nullable().required("This field is required!"),
    subjectmaster_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  useEffect(() => {
    // getSubjectMasters();
    // dispatch(getOldStudentsAsync());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
  }, [dispatch]);
  const getSubjectMasters = async (academic_session_id, semester) => {
    setSubjectmasters({});
    if (academic_session_id && semester) {
      const _subjectmasters = await BaseApi().get(`subject-masters`, {
        params: {
          academic_session_id: academic_session_id,
          semester: semester,
        },
      });

      setSubjectmasters(_subjectmasters.data);
    }
  };
  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);

    await callStudents(searchQuery);
    setLoading(false);
  };

  const handleSubmit = async (payload) => {
    setLoading(true);

    await BaseApi()
      .post(`student-attendance`, payload)
      .then((response) => {
        if (response.data.length > 0) {
          setEdit(false);
          formikRef2.current?.setFieldValue(
            "subjectmaster_id",
            payload.subjectmaster_id
          );
          formikRef2.current?.setFieldValue("month", payload.month);
          formikRef2.current?.setFieldValue("year", payload.year);
          formikRef2.current?.setFieldValue(
            "total_class",
            response.data[0]?.total_class
          );

          formikRef2.current?.setFieldValue(
            "details",
            response.data.map((data) => ({
              programme_student_id: data.programme_student_id,
              class_attended: data.class_attended,
            }))
          );

          setAttendances(response.data);
        } else {
          setEdit(true);
        }
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  };

  const getAttendances = async (payload) => {
    setLoading(true);

    await BaseApi()
      .get(`student-attendance`, { params: payload })
      .then((response) => {
        if (response.data.length > 0) {
          setEdit(false);
          formikRef2.current?.setFieldValue(
            "subjectmaster_id",
            payload.subjectmaster_id
          );
          formikRef2.current?.setFieldValue("month", payload.month);
          formikRef2.current?.setFieldValue("year", payload.year);
          formikRef2.current?.setFieldValue(
            "total_class",
            response.data[0]?.total_class
          );

          formikRef2.current?.setFieldValue(
            "details",
            response.data.map((data) => ({
              programme_student_id: data.programme_student_id,
              class_attended: data.class_attended,
            }))
          );

          setAttendances(response.data);
        } else {
          setEdit(true);
        }
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  };

  async function callStudents(searchQuery) {
    console.log("as");
    setLoading(true);
    setStudents({});
    await BaseApi()
      .get(`subject-masters/students`, {
        params: searchQuery,
      })
      .then((data) => {
        setStudents(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }
  const handleClassInput = (e, programme_student_id) => {
    console.log(programme_student_id);
    console.log(e.target.value);
    let _oldData = [...formikRef2.current.values.details];
    console.log(_oldData);
    _oldData = _oldData.filter((od) => {
      return od.programme_student_id != programme_student_id;
    });

    _oldData.push({
      programme_student_id: programme_student_id,
      class_attended: e.target.value,
    });

    formikRef2.current?.setFieldValue("details", _oldData);
  };
  return (
    <>
      <div className="py-2">
        <div className="mx-auto  container bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full min-h-screen   overflow-x-scroll ">
            <PageTitle name="Search Students" />

            <Formik
              innerRef={formikRef}
              initialValues={initialSearchValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className=" p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-2">
                  <CustomSelect
                    options={academicSessions}
                    name="academic_session_id"
                    label="Select Academic Session"
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );

                      setYears(selectedOption.label.split("-"));
                      getSubjectMasters(
                        selectedOption.id,
                        formikRef.current?.values.semester
                      );
                    }}
                  />

                  <CustomSelect
                    options={YearSemesters}
                    name="semester"
                    label="Select Semester"
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "semester",
                        selectedOption.value
                      );
                      getSubjectMasters(
                        formikRef.current?.values.academic_session_id,
                        selectedOption.value
                      );
                    }}
                  />
                  <CustomSelect
                    options={subjectmasters}
                    name="subjectmaster_id"
                    label="Select Subject/Course"
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "subjectmaster_id",
                        selectedOption.id
                      );

                      formikRef2.current?.setFieldValue(
                        "subjectmaster_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div className="text-left p-5 mt-1  ">
                    <button
                      type="submit"
                      className="group relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>

            <div className="">
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            {loading == true && (
              <>
                <div className="flex">
                  <span className="m-auto">
                    <ThreeDots
                      height="100"
                      width="100"
                      color="grey"
                      ariaLabel="loading"
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}
            <h1 className="text-xl  py-2 px-5  font-bold  ">
              Enter Attendance Records{" "}
            </h1>
            <Formik
              innerRef={formikRef2}
              initialValues={initialValues}
              validationSchema={validationSchema2}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=" p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-2">
                  <CustomSelect
                    readOnly={edit}
                    options={monthNameList.map((t) => ({
                      value: t,
                      label: t,
                    }))}
                    name="month"
                    label="Select Month"
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef2.current?.setFieldValue(
                        "month",
                        selectedOption.value
                      );
                    }}
                  />

                  <CustomSelect
                    readOnly={edit}
                    options={years.map((t) => ({
                      value: t,
                      label: t,
                    }))}
                    name="year"
                    label="Select Year"
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef2.current?.setFieldValue(
                        "year",
                        selectedOption.value
                      );

                      getAttendances({
                        month: formikRef2.current.values.month,
                        year: selectedOption.value,
                        subjectmaster_id:
                          formikRef2.current.values.subjectmaster_id,
                      });
                    }}
                  />
                  {edit && (
                    <Input
                      type="number"
                      label="Total No. of Class"
                      id="total_class"
                      name="total_class"
                    />
                  )}
                </div>

                {edit && attendances.length > 0 && (
                  <>
                    <Button
                      type="button"
                      classNames=" mr-2   bg-green-600 hover:bg-green-700 focus:ring-green-500"
                      value={"Done"}
                      icon={"CheckIcon"}
                      onClick={() => {
                        setEdit(false);
                      }}
                    />
                  </>
                )}
                {!edit && attendances.length > 0 && (
                  <Button
                    type="button"
                    classNames="mr-2   bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                    value={"Edit"}
                    icon={"PencilIcon"}
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                )}
                {!edit && (
                  <Button
                    classNames="float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                    value={"Download CSV"}
                    onClick={() => {
                      tableToCSV("datatable");
                    }}
                    icon={"DownloadIcon"}
                  />
                )}
                <table
                  id="datatable"
                  className="min-w-full mt-5 bg-white dark:bg-gray-800"
                >
                  <thead>
                    <tr>
                      <th className="table-head">#</th>

                      <th className="table-head">Students Name</th>

                      <th className="table-head">Enrollment ID</th>

                      <th className="table-head">Department</th>
                      <th className="table-head">Class Attended</th>
                      {!edit && <th className="table-head">Total Class</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {students?.length > 0 &&
                      students.map((student) => {
                        return (
                          <>
                            <tr>
                              <td className="table-data">{++sl}</td>

                              <td className="table-data">
                                {student.programme_student?.student?.name}
                              </td>
                              <td className="table-data">
                                <span
                                  className="cursor-pointer text-blue-500"
                                  onClick={() =>
                                    window.open(
                                      `/admitted-students/${student.programme_student?.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {student.programme_student?.unique_id}
                                </span>
                              </td>

                              <td className="table-data">
                                {student.programme_student?.department?.name}
                              </td>
                              <td className="table-data">
                                {!edit &&
                                  attendances.length > 0 &&
                                  attendances.find((atten) => {
                                    return (
                                      atten.programme_student_id ==
                                      student.programme_student?.id
                                    );
                                  })?.class_attended}

                                {}
                                {edit && (
                                  <input
                                    placeholder="Enter No. Of Class"
                                    className="border p-2"
                                    type="number"
                                    label="Total No. of Class"
                                    id="total_class"
                                    defaultValue={
                                      formikRef2.current?.values.details.find(
                                        (val) =>
                                          val.programme_student_id ==
                                          student.programme_student?.id
                                      )?.class_attended
                                    }
                                    onChange={(event) =>
                                      handleClassInput(
                                        event,
                                        student.programme_student?.id
                                      )
                                    }
                                  />
                                )}
                              </td>
                              {!edit && (
                                <td className="table-data">
                                  {attendances.length > 0 &&
                                    attendances[0].total_class}
                                </td>
                              )}
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
                {students?.length > 0 && edit && (
                  <div className="text-left p-5 mt-1  ">
                    <button
                      type="submit"
                      className="group relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={loading}
                    >
                      {loading && <span>Saving...</span>}
                      {!loading && <span>Submit</span>}
                    </button>
                  </div>
                )}
              </Form>
            </Formik>
            {students?.length == 0 && (
              <div className="text-center my-10">No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
