import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";
// import Input from "../../Components/Utils/Input";
import moment from "moment";
import BaseApi from "../../Http/BaseApi";
// import Select from "../../../Components/Utils/Select";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";

export default function ChangeDateRangeModal({
  leave,
  setShowChangeDateRangeModal,
}) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("called again");

    setInitialValues({
      leave_id: leave.id,

      from_date: moment(leave.from_date).format("YYYY-MM-DD"),
      to_date: moment(leave.to_date).format("YYYY-MM-DD"),
      total_days: leave.total_days,
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    leave_id: Yup.string().required("This field is required!"),
    from_date: Yup.string().required("This field is required!"),
    to_date: Yup.string().required("This field is required!"),

    total_days: Yup.number()
      .min(1, "Min value 1")
      .max(60, `Maximum permitted days 60`),
  });
  const handleSubmit = async (payload) => {
    setLoading(true);
    console.log(payload);

    const _data = await BaseApi().post(`update-leave-dates`, payload);

    setShowChangeDateRangeModal(_data.data);
    setLoading(false);
    setInitialValues({
      leave_id: leave.id,
      from_date: "",
      to_date: "",
      total_days: "",
      //   type: payment.type,
    });

    // setShowEarnLeaveModal(false);
  };

  const calculateDays = async (from_date, to_date) => {
    if (from_date == "" || to_date == "") {
      formikRef.current?.setFieldValue("total_days", "");

      return;
    }
    setLoading(true);
    let response = await BaseApi().get("/leave/get_no_of_days", {
      params: {
        from_date,
        to_date,
        leave_id: leave.id,
      },
    });
    console.log(response);
    formikRef.current?.setFieldValue("total_days", response.data.count);
    setLoading(false);
  };
  console.log(message);
  return (
    <>
      <div className='fixed inset-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative w-auto max-w-3xl mx-auto my-6'>
          {/*content*/}

          <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
              <h3 className='text-3xl font-semibold'>Change Date Range</h3>
            </div>
            {/*body*/}
            <p style={{ width: "700px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative flex-auto p-6'>
                    <div className='grid grid-cols-3 gap-4'>
                      <div className=''>
                        <label
                          htmlFor={"from_date"}
                          className='block text-sm font-medium text-gray-700'
                        >
                          From *
                        </label>

                        <Field
                          id='from_date'
                          type='date'
                          name='from_date'
                          // value={props.value}
                          // onChange={props.onChange}
                          onChange={(event) => {
                            formikRef.current?.setFieldValue(
                              "from_date",
                              event.target.value
                            );
                            calculateDays(
                              event.target.value,
                              formikRef.current?.values.to_date
                            );
                          }}
                          placeholder='from_date'
                          className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                        />
                        <ErrorMessage
                          name='from_date'
                          component='div'
                          className='py-2 text-red-500'
                        />
                      </div>
                      <div className=''>
                        <label
                          htmlFor={"to_date"}
                          className='block text-sm font-medium text-gray-700'
                        >
                          To *
                        </label>

                        <Field
                          id='to_date'
                          type='date'
                          name='to_date'
                          // value={props.value}
                          // onChange={props.onChange}
                          onChange={(event) => {
                            formikRef.current?.setFieldValue(
                              "to_date",
                              event.target.value
                            );
                            calculateDays(
                              formikRef.current?.values.from_date,
                              event.target.value
                            );
                          }}
                          placeholder='to_date'
                          className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                        />
                        <ErrorMessage
                          name='to_date'
                          component='div'
                          className='py-2 text-red-500'
                        />
                      </div>

                      <Input
                        readOnly={true}
                        label={"No. of Days *"}
                        name='total_days'
                        placeholder='No. of Days'
                      />
                    </div>

                    <p className='my-4 text-lg leading-relaxed text-slate-500'></p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200'>
                    <button
                      className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                      type='button'
                      onClick={() => setShowChangeDateRangeModal(false)}
                    >
                      Close
                    </button>

                    <Button
                      disabled={loading}
                      value={loading ? "..." : "Update"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
    </>
  );
}
