import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../Components/Common/PageTitle";

export default function Masters() {
  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Master Settings' />
            <div className='grid gap-10 pt-6 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'>
              <Link
                to='/designations'
                className='p-10 font-medium text-center text-indigo-600 border-2 border-indigo-300 rounded hover:text-indigo-500'
              >
                Designations
              </Link>
              <Link
                to='/programmes'
                className='p-10 font-medium text-center text-indigo-600 border-2 border-indigo-300 rounded hover:text-indigo-500'
              >
                Programmes
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
