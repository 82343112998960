import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaFileLines,
  FaRegComments,
  FaUsers,
  FaUserTie,
} from "react-icons/fa6";

const headers = {
  startup_name: { label: "Name of the Startup", path: "startup_name" },
  date_started: { label: "Date Started", path: "date_started" },
  registration_details: {
    label: "Registration Details",
    path: "registration_details",
  },
  objective: { label: "Objective of the Startup", path: "objective" },
  details: { label: "Details of the Startup", path: "details" },
  role: { label: "Your Role in the Startup", path: "role" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const StartupDevelopment = ({ view }) => {
  const [startupDevelopments, setStartupDevelopments] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/startup-developments");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setStartupDevelopments(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/startup-developments");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredData = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by date_started (latest first)
      const sortedData = filteredData.sort((a, b) => {
        const dateA = getValueByPath(a, headers.date_started.path);
        const dateB = getValueByPath(b, headers.date_started.path);

        return moment(dateB).diff(moment(dateA)); // Latest date first
      });

      setStartupDevelopments(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const renderStartupDevelopment = (startupDevelopment) => {
    const startupName = getValueByPath(
      startupDevelopment,
      headers.startup_name.path
    );
    const dateStarted = formatDate(
      getValueByPath(startupDevelopment, headers.date_started.path)
    );
    const registrationDetails = getValueByPath(
      startupDevelopment,
      headers.registration_details.path
    );
    const objective = getValueByPath(
      startupDevelopment,
      headers.objective.path
    );
    const details = getValueByPath(startupDevelopment, headers.details.path);
    const role = getValueByPath(startupDevelopment, headers.role.path);

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{startupName}</h3>
          <span className='flex items-center gap-1'>
            <FaFileLines className='text-yellow-500' />
            {details}
          </span>
          <span className='flex items-center gap-1'>
            <FaUserTie className='text-yellow-500' />
            Registration Details: {registrationDetails}
          </span>

          <span className='flex items-center gap-1'>
            <FaUsers className='text-yellow-500' />
            Role: {role}
          </span>

          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaRegComments className='text-yellow-500' />
              Objective: {objective}
            </span>

            <span className='flex items-center gap-1'>
              <FaCalendarDays className='text-yellow-500' />
              {moment(dateStarted).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    // <div>
    //   <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
    //     Startup
    //   </h2>
    //   <TableViewProfileWeb
    //     headers={headers}
    //     data={startupDevelopments}
    //     type='startup-developments'
    //     setData={setStartupDevelopments}
    //   />
    // </div>

    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        {/* <SiHiveBlockchain /> */}
        Startup
      </h2>
      {startupDevelopments.length ? (
        <div className=''>
          {startupDevelopments.map((startupDevelopment, index) => (
            <div key={index} className=''>
              {renderStartupDevelopment(startupDevelopment)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default StartupDevelopment;
