import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import { FaIdCard, FaUser, FaUsers } from "react-icons/fa";
import { FaCalendarDays, FaRegMap } from "react-icons/fa6";
import moment from "moment";

const headers = {
  title: {
    label: "Title of the Design",
    path: "title",
  },
  creators: {
    label: "Creators",
    path: "creators",
  },
  design_number: {
    label: "Design Number",
    path: "design_number",
  },
  registration_date: {
    label: "Registration Date",
    path: "registration_date",
  },
  country: {
    label: "Country",
    path: "country",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const Design = ({ view }) => {
  const [designs, setDesigns] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/designs");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setDesigns(response.data.data.filter((q) => q[filterField] === "yes"));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/designs");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredDesigns = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by published_date (latest date first)
      const sortedDesigns = filteredDesigns.sort((a, b) => {
        const dateA = moment(getValueByPath(a, headers.published_date.path));
        const dateB = moment(getValueByPath(b, headers.published_date.path));
        return dateB - dateA; // Descending order
      });

      setDesigns(sortedDesigns);
    } catch (error) {
      console.error(error);
    }
  };

  const renderDesign = (honorsAward) => {
    const title = getValueByPath(honorsAward, headers.title.path);
    const creators = getValueByPath(honorsAward, headers.creators.path);
    const designNumber = getValueByPath(
      honorsAward,
      headers.design_number.path
    );
    const registrationDate = getValueByPath(
      honorsAward,
      headers.registration_date.path
    );
    const country = getValueByPath(honorsAward, headers.country.path);

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>

          <p className='flex items-center gap-2 text-green-600 whitespace-nowrap'>
            <FaUser />
            <span className='text-green-600'>{creators}</span>
          </p>

          <div className='flex flex-wrap items-center gap-4'>
            <div className='flex items-center gap-1 whitespace-nowrap'>
              <span className='flex items-center gap-1'>
                <FaIdCard className='text-yellow-500' />
                Design Number:
              </span>
              <span>{designNumber}</span>
            </div>

            <div className='flex items-center gap-1 whitespace-nowrap'>
              <FaCalendarDays className='text-yellow-500' />
              Registration Date:{" "}
              {moment(registrationDate).format("DD MMM YYYY")}
            </div>
          </div>
          <div className='flex items-center gap-1 whitespace-nowrap'>
            <FaRegMap className='text-yellow-500' />
            {country}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        <FaUsers />
        Design
      </h2>
      {designs.length ? (
        <div>
          {designs.map((design, index) => (
            <div key={index}>{renderDesign(design)}</div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Design;
