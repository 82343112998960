import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: {
    label: "Role",
    path: "role",
  },
  thesis_title: {
    label: "Thesis Title",
    path: "thesis_title",
  },
  institute: {
    label: "Ph. D. Scholar registered in DU/Other Institute",
    path: "institute",
  },
  other_institute: {
    label: "Other Institute",
    path: "other_institute",
  },
};

const IqacPhdGuidance = () => {
  const [phdGuidances, setPhdGuidances] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/phd-guidances?from_iqac=1"
      );
      setPhdGuidances(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50">
        <h1>Ph. D. Guidance: </h1>

        <div className="flex items-end justify-center space-x-2 capitalize text-md">
          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">From: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">To: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="">
            <Button
              type="filter"
              value="Filter"
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={phdGuidances}
        type="phd-guidances"
        setData={setPhdGuidances}
      />
    </div>
  );
};

export default IqacPhdGuidance;
