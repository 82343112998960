import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  institution: { label: "Institution/Organization", path: "institution" },
  // visit_date: { label: "Date of Visit", path: "visit_date" },
  visit_purpose: { label: "Purpose of Visit", path: "visit_purpose" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
};

const IqacAcademicVisits = () => {
  const [academicVisits, setAcademicVisits] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/academic-visits?from_iqac=1"
      );
      setAcademicVisits(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = () => {};

  return (
    <div>
      <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50'>
        <h1>Academic Visits: </h1>
        <div className='flex items-end justify-center space-x-2 capitalize text-md'>
          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>From: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>To: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className=''>
            <Button
              type='filter'
              value='Filter'
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
        {/* <Button
          type='download'
          value='Download'
          icon={"DownloadIcon"}
          onClick={handleDownload}
        /> */}
      </div>

      <TableFacultyIqac
        headers={headers}
        data={academicVisits}
        type='academic-visits'
        setData={setAcademicVisits}
      />
    </div>
  );
};

export default IqacAcademicVisits;
