import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "../Middleware/ProtectedRoute";
import DepartmentFeeCollection from "../Pages/Accounts/FeeCollection/DepartmentFeeCollection";
import PaymentIndex from "../Pages/Accounts/Payments/PaymentIndex";
import PendingPayments from "../Pages/Accounts/Payments/PendingPayments";
import ApplicantShow from "../Pages/Applicants/ApplicantShow";
import ApplicantIndex from "../Pages/Applicants/AppplicantIndex";
import StudentAttendance from "../Pages/Attendance/StudentAttendance";
import DepartmentChangePassword from "../Pages/Auth/Department/ChangePassword";
import DepartmentDashboard from "../Pages/Dashboard/DepartmentDashboard";
import ApproveOldStudent from "../Pages/OldStudent/ApproveOldStudent";
import OldStudentIndex from "../Pages/OldStudent/OldStudentIndex";
import ViewOldStudent from "../Pages/OldStudent/ViewOldStudent";
import AdmittedStudentIndex from "../Pages/Student/AdmittedStudentIndex";
import ExStudents from "../Pages/Student/ExStudents";
import StudentPromotion from "../Pages/Student/Promotion/StudentPromotion";
// import StudentPromotionModal from "../Pages/Student/Promotion/StudentPromotionModal";
import ViewAdmittedStudent from "../Pages/Student/ViewAdmittedStudent";
import OfferedSubjects from "../Pages/Subjects/OfferedSubjects";
import StudentIDCard from "../Pages/Student/StudentIDCard";
import RegisteredPHDRecords from "../Pages/Student/RegisteredPHDRecords";
import AwardedPHDRecords from "../Pages/Student/AwardedPHDRecords";
import Programmes from "../Pages/Masters/Programmes/Programmes";
import StudentCount from "../Pages/Student/StudentCount";
import PHDRecords from "../Pages/Student/PHDRecords";
import HostelerDepartmentView from "../Pages/Hostel/HostelerDepartmentView";

export default [
  <Route key='department' element={<ProtectedRoute />}>
    <Route path='dashboard' element={<DepartmentDashboard />} />
    <Route path='old-students' element={<OldStudentIndex />} />
    <Route path='old-students/approve/:id' element={<ApproveOldStudent />} />
    <Route path='old-students/:id' element={<ViewOldStudent />} />
    <Route path='admitted-students' element={<AdmittedStudentIndex />} />

    <Route path='ex-students' element={<ExStudents />} />
    <Route path='admitted-students/:id' element={<ViewAdmittedStudent />} />
    <Route path='admitted-students/id-card/:id' element={<StudentIDCard />} />

    {/* new applicants part */}
    <Route path='applicants/' element={<ApplicantIndex />} />
    <Route path='applicants/:id' element={<ApplicantShow />} />
    {/* aec */}
    <Route path='subjects/' element={<OfferedSubjects />} />
    <Route path='change-password' element={<DepartmentChangePassword />} />
    {/* attendance */}
    <Route path='student-attendance' element={<StudentAttendance />} />
    {/* fee collection */}
    <Route
      path='department-fee-collection'
      element={<DepartmentFeeCollection />}
    />
    <Route path='payments' element={<PaymentIndex />} />
    <Route path='payments-pending' element={<PendingPayments />} />

    <Route path='promote-student' element={<StudentPromotion />} />
    <Route path='phd-students-records' element={<PHDRecords />} />

    <Route
      path='registered-phd-students-records'
      element={<RegisteredPHDRecords />}
    />
    <Route
      path='awarded-phd-students-records'
      element={<AwardedPHDRecords />}
    />
    <Route path='programmes' element={<Programmes />} />
    <Route path='student-counts' element={<StudentCount />} />
    <Route path='hostelers' element={<HostelerDepartmentView />} />
  </Route>,
];
