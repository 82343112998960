import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getStudentCountsAsync } from "../../Redux/MasterSlice";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";

export default function StudentCount() {
  const dispatch = useDispatch();

  const master = useSelector((state) => state.master);
  const studentCounts = [...master.studentCounts];

  //   const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(messageSlice.actions.clearMessage());
    if (studentCounts.length === 0) {
      dispatch(getStudentCountsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  // const grandTotal = studentCounts.reduce((total, programme) => {
  //   return (
  //     total +
  //     Object.values(programme.data).reduce((sum, count) => sum + count, 0)
  //   );
  // }, 0);

  let grandTotal = {
    total: 0,
    male: 0,
    female: 0,
    other: 0,
  };
  studentCounts.forEach((programme) => {
    Object.values(programme.data).forEach((semesterData) => {
      grandTotal.total += Number(semesterData.total);
      grandTotal.male += Number(semesterData.male);
      grandTotal.female += Number(semesterData.female);
      grandTotal.other += Number(semesterData.other);
    });
  });

  const semesterTotals = {};

  let thClass =
    " text-gray-600   border p-2    dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Semester wise current student counts' />
            <span className='text-gray-800 text-md'>
              2024-25 Academic Session{" "}
            </span>
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <br /> <br />
            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>Programme Type</th>
                  <th className={thClass}>Programme</th>
                  <th className={thClass}>1st</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>2nd</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>3rd</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>4th</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>5th</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>6th</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>7th</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>8th</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>9th</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>10th</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>

                  <th className={thClass}>Total</th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                </tr>
                <tr>
                  <th className={thClass + "w-16"}></th>
                  <th className={thClass}></th>
                  <th className={thClass}></th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>

                  <th className={thClass}>Total</th>
                  <th className={thClass}>Male</th>
                  <th className={thClass}>Female</th>
                  <th className={thClass}>Other</th>
                </tr>
              </thead>
              <tbody>
                {studentCounts.length > 0 &&
                  studentCounts.map((programme, i) => {
                    let programmeTotal = {
                      total: 0,
                      male: 0,
                      female: 0,
                      other: 0,
                    };

                    return (
                      <tr
                        key={programme.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass + " w-32"}>{++i}</td>
                        <td className={tdClass}>{programme.category_name}</td>
                        <td className={tdClass}>{programme.programme_name}</td>

                        {Object.entries(programme.data).map(
                          ([semester, data]) => {
                            programmeTotal.total += Number(data.total);
                            programmeTotal.male += Number(data.male);
                            programmeTotal.female += Number(data.female);
                            programmeTotal.other += Number(data.other);

                            // Accumulate semester totals
                            if (!semesterTotals[semester]) {
                              semesterTotals[semester] = {
                                total: 0,
                                male: 0,
                                female: 0,
                                other: 0,
                              };
                            }
                            semesterTotals[semester].total += Number(
                              data.total
                            );
                            semesterTotals[semester].male += Number(data.male);
                            semesterTotals[semester].female += Number(
                              data.female
                            );
                            semesterTotals[semester].other += Number(
                              data.other
                            );

                            return (
                              <>
                                <td className={tdClass}> {data.total} </td>
                                <td className={tdClass}> {data.male}</td>
                                <td className={tdClass}> {data.female}</td>
                                <td className={tdClass}>{data.other} </td>
                              </>
                            );
                          }
                        )}
                        <th className={thClass}>{programmeTotal.total}</th>
                        <th className={thClass}>{programmeTotal.male}</th>
                        <th className={thClass}> {programmeTotal.female}</th>
                        <th className={thClass}>{programmeTotal.other}</th>
                      </tr>
                    );
                  })}
                <tr>
                  <th className={thClass + " w-32"}></th>
                  <th className={thClass + " w-32"}></th>
                  <th className={thClass + " w-32"}>Grand Total:</th>
                  {Object.entries(semesterTotals).map(([semester, totals]) => (
                    <React.Fragment key={semester}>
                      <th className={thClass}>{totals.total}</th>
                      <th className={thClass}>{totals.male}</th>
                      <th className={thClass}>{totals.female}</th>
                      <th className={thClass}>{totals.other}</th>
                    </React.Fragment>
                  ))}
                  <th className={thClass}> {grandTotal.total}</th>
                  <th className={thClass}> {grandTotal.male}</th>
                  <th className={thClass}> {grandTotal.female}</th>
                  <th className={thClass}> {grandTotal.other}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
