import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";
import tableToCSV from "../../Components/Utils/TableToCSV";

export default function LeaveApprovalResolved() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  // const authData = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  // const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  const [errorMessage, setErrorMessage] = useState(false);
  const [approvals, setApprovals] = useState([]);
  console.log(loading);

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    getApprovalList();
    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getApprovalList() {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`/leave_approvals?status=resolved`)
      .then((data) => {
        setApprovals(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  // const onEdit = (event, staff) => {
  // console.log(event, staff);
  // };

  // const handleDelete = async (event, payload) => {
  //   event.target.disabled = true;
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (deleteConfirm.state == 1) {
  //     await BaseApi()
  //       .delete(`staff/${payload.id}`)
  //       .then((data) => {
  //         // console.log(data.data.id);
  //         let _staff = staff.filter((s) => s.id != data.data.id);
  //         // console.log(_staff);
  //         setStaff(_staff);
  //       })
  //       .catch((error) => {
  //         const message =
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //           error.message ||
  //           error.toString();
  //         setErrorMessage(message);
  //       });
  //   }
  //   event.target.disabled = false;
  //   setDeleteConfirm({});
  // };

  // const handleDeactivate = async (event, payload) => {
  //   event.target.disabled = true;
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (deactivateConfirm.state == 1) {
  //     await BaseApi()
  //       .post(`staff/change-status/${payload.id}`)
  //       .then((data) => {
  //         console.log(data.data.id);
  //         let _staff = [...staff];

  //         const index = _staff.findIndex((s) => s.id === data.data.id);
  //         _staff[index] = data.data;
  //         setStaff(_staff);
  //         setDeactivateConfirm({});
  //       })
  //       .catch((error) => {
  //         const message =
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //           error.message ||
  //           error.toString();
  //         setErrorMessage(message);
  //       });
  //   }
  //   event.target.disabled = false;
  //   setDeleteConfirm({});
  // };
  // const handleCheckbox = (event) => {
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     setSelectedIds([...selectedIds, value]);
  //   } else {
  //     var index = selectedIds.indexOf(value);
  //     if (index != -1) {
  //       setSelectedIds(selectedIds.filter((item) => item !== value));
  //     }
  //   }
  // };

  // console.log(selectedIds);
  // const handleBulkDelete = async (event) => {
  //   event.preventDefault();
  //   event.target.disabled = true;

  //   await BaseApi()
  //     .post(`staff/bulk-delete`, selectedIds)
  //     .then((response) => {
  //       let _staff = staff.filter((s) => {
  //         return !response.ids.includes(s.id.toString());
  //       });
  //       setStaff(_staff);
  //     });

  //   setSelectedIds([]);
  //   event.target.disabled = false;
  // };

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Leaves Forwarded/Approved" />

            <div className="">
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            {loading == true && (
              <>
                <div className="flex">
                  <span className="m-auto">
                    <ThreeDots
                      height="100"
                      width="100"
                      color="grey"
                      ariaLabel="loading"
                    />
                  </span>
                </div>
              </>
            )}
            <Button
              classNames="float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <table
              id="datatable"
              className="min-w-full mt-3  bg-white dark:bg-gray-800"
            >
              <thead>
                <tr>
                  <th className="table-head w-16">#</th>
                  {/* <th className="table-head">Staff Name</th> */}
                  <th className="table-head">Applied By</th>

                  <th className="table-head">Leave Type</th>
                  {/* <th className="table-head">Supporting Document</th> */}
                  <th className="table-head">From Date</th>
                  <th className="table-head">To Date</th>
                  <th className="table-head">No. of days</th>
                  <th className="table-head">Forwarding Authority</th>
                  <th className="table-head">Approving Authority</th>
                  <th className="table-head">Status</th>
                  <th colSpan="3" className="table-head">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {approvals.length > 0 &&
                  approvals.map((approval, i) => {
                    return (
                      <tr
                        key={approval.id}
                        className="odd:bg-white even:bg-gray-50 "
                      >
                        <td className="table-data w-32">{++i}</td>
                        <td className="table-data">
                          {approval?.leave?.staff.full_name}
                        </td>

                        <td className="table-data">
                          {/* {approval?.leave.leave_type.name} */}

                          {[
                            ...new Set(
                              approval?.leave?.leave_dates?.map(
                                (item) => item.leave_type.name
                              )
                            ),
                          ].toString()}
                        </td>
                        {/* <td className="table-data">
                          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            {" "}
                            View Document
                          </button>
                        </td> */}
                        <td className="table-data">
                          {moment(approval.leave.from_date).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td className="table-data">
                          {moment(approval.leave.to_date).format("DD-MM-YYYY")}
                        </td>
                        <td className="table-data">
                          {approval.leave.status != 1 &&
                            approval.leave.total_days}
                          {approval?.leave?.status == 1 &&
                            approval?.leave?.leave_type_id != 3 && (
                              <>
                                {
                                  approval.leave?.leave_dates.filter(
                                    (ld) => ld.status == 1
                                  ).length
                                }
                              </>
                            )}

                          {approval?.leave?.status == 1 &&
                            approval?.leave?.leave_type_id == 3 && (
                              <>{approval.leave?.leave_dates.length}</>
                            )}
                        </td>
                        <td className="table-data">
                          {approval.leave.forwarding_authority?.full_name}
                          <br />
                          {approval.leave.forwarding_authority2?.full_name}
                        </td>
                        <td className="table-data">
                          {approval.leave.approving_authority?.full_name}
                        </td>

                        <td className="table-data">
                          {" "}
                          {approval?.leave?.status == 0 && (
                            <>
                              <span className="text-yellow-600">
                                Pending for Approval
                              </span>
                            </>
                          )}
                          {approval?.leave?.status == 1 && (
                            <>
                              <span className="text-green-700">Approved</span>
                            </>
                          )}
                          {approval?.leave?.status == 2 && (
                            <>
                              <span className="text-red-700">Rejected </span>
                            </>
                          )}
                          {approval?.leave?.status == 3 && (
                            <>
                              <span className="text-purple-700">Forwarded</span>
                            </>
                          )}
                          {approval?.leave?.status == -1 && (
                            <>
                              <span className="text-red-900">Cancelled</span>
                            </>
                          )}
                          {approval?.leave?.cancelation_reason != null &&
                            approval?.leave?.status != -1 && (
                              <>
                                <br />
                                <br />
                                <span className="text-yellow-600">
                                  Cancellation Requested
                                </span>
                              </>
                            )}
                        </td>
                        <td className="table-data">
                          <Button
                            classNames="bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                            value={"View"}
                            icon={"ChevronDoubleRightIcon"}
                            onClick={() => {
                              navigate(`/leave/approvals/${approval.id}`);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!loading && approvals.length == 0 && (
              <>
                <div className="text-center m-10">No Data</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
