import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  award_name: { label: "Award Name", path: "award_name" },
  award_details: { label: "Award Details", path: "award_details" },
  month: { label: "Month of the Award", path: "monthName" },
  year: { label: "Year of the Award", path: "year" },
  awarded_by: { label: "Awarded By", path: "awarded_by" },
};

const HonorsAward = () => {
  const [honorsAwards, setHonorsAwards] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/honors-awards");
      setHonorsAwards(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Honors & Awards
      </h2>
      <TableViewProfile
        headers={headers}
        data={honorsAwards}
        setData={setHonorsAwards}
        type='honors-awards'
      />
    </div>
  );
};

export default HonorsAward;
