import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";

import { getProgrammesAsync } from "../../../Redux/MasterSlice";
import Button from "../../../Components/Utils/Button";
import tableToCSV from "../../../Components/Utils/TableToCSV";

export default function Programmes() {
  const dispatch = useDispatch();

  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];

  //   const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(messageSlice.actions.clearMessage());
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Programmes' />
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <br /> <br />
            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>Name</th>
                  <th className={thClass}>Code</th>
                  <th className={thClass}>Current Status</th>
                </tr>
              </thead>
              <tbody>
                {programmes.length > 0 &&
                  programmes.map((programme, i) => {
                    return (
                      <tr
                        key={programme.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass + "w-32"}>{++i}</td>
                        <td className={tdClass}>{programme.name}</td>
                        <td className={tdClass}>{programme.code}</td>
                        <td className={tdClass}>
                          {programme.is_active ? (
                            <>
                              <span className='text-green-600'>Active</span>
                            </>
                          ) : (
                            <>
                              <span className='text-red-600'>Inactive</span>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
