import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
// import Select from "../../Components/Utils/Select";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import EarnedLeaveEntryModal from "./EarnedLeaveEntryModal";
import ChangeDateRangeModal from "./ChangeDateRangeModal";
import EarnedLeavePrint from "./EarnedLeavePrint";

export default function LeaveApprove() {
  const { id } = useParams();
  const authData = useSelector((state) => state.auth);
  const [approval, setApproval] = useState();
  const [leave_types, setLeaveTypes] = useState([]);
  const printRef = useRef();
  const printRefEarned = useRef();
  //original
  const [loading, setLoading] = useState(false);
  const [showEarnedLeavePrint, setShowEarnedLeavePrint] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  // const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  const [errorMessage, setErrorMessage] = useState(false);
  const [rejectedDates, setRejectedDates] = useState([]);
  const [showAllDates, setShowAllDates] = useState(false);
  const [showEarnLeaveModal, setShowEarnLeaveModal] = useState(false);
  const [showChangeDateRangeModal, setShowChangeDateRangeModal] =
    useState(false);

  useEffect(() => {
    checkData();
  }, [id]);

  async function checkData() {
    console.log("check data call");
    if (id && id > 0) {
      setLoading(true);

      console.log(" call 2");
      try {
        const _leave = await BaseApi().get(`leave_approvals/${id}`);

        setApproval(_leave.data);

        const rejectedDateIds = _leave.data.leave.leave_dates
          .filter((date) => date.status == 2)
          .map((date) => date.id.toString());
        setRejectedDates(rejectedDateIds);

        if (leave_types.length === 0) {
          callRemainingLeave(_leave.data.leave.staff_id);
        }
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      }

      setLoading(false);
    }
  }

  const callRemainingLeave = (staff_id) => {
    BaseApi()
      .get(`/leaves/remaining/${staff_id}`)
      .then((response) => {
        setLeaveTypes(response.data);
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
  };
  const handleSubmit = async (type, reason) => {
    if (
      type == "forward" &&
      approval?.leave?.leave_type_id == 6 &&
      (authData.user.id == 495 || authData.user.id == 496) &&
      leave_types?.find((lt) => lt.id == 6)?.pivot?.date == null &&
      "can"
    ) {
      alert("Please update the leave record first.");
      return;
    }
    await BaseApi()
      .post(`leave/${type}/${approval?.id}`, { rejectedDates, reason })
      .then(() => {
        if (type == "forwardtovc") {
          type = "Forward";
        }
        toast.success(`Leave ${type}ed!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });

        checkData();

        setLoading(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      });
    setLoading(false);
  };
  const handleCancelation = async (type) => {
    await BaseApi()
      .post(`leave/${type}/${approval?.id}`)
      .then((data) => {
        toast.success(`Successful!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });

        setApproval(data.data);

        setLoading(false);
      });
    setLoading(false);
  };
  const handleReject = async (reason) => {
    await BaseApi()
      .post(`leave/reject/${approval?.id}`, { reason })
      .then(() => {
        toast.success("Leave Rejected!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });

        checkData();

        setLoading(false);
      });
    setLoading(false);
  };

  const handleUpdate = async (payload) => {
    await BaseApi()
      .post(`leave_dates/${payload.leave_date_id}`, payload)
      .then((data) => {
        toast.success("Data Updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        let _approval = { ...approval };

        if (data.data) {
          _approval.leave.leave_dates.find(
            (h) => h.id == payload.leave_date_id
          ).leave_type_id = payload.leave_type_id;
        }
        setApproval(_approval);

        setLoading(false);
      });
    setLoading(false);
  };

  const handleCheckbox = (event) => {
    const target = event.target;
    var value = target.value;
    console.log(target.checked);
    if (target.checked) {
      let _rejectedDates = [...rejectedDates];
      var index = _rejectedDates.indexOf(value);
      if (index !== -1) {
        _rejectedDates.splice(index, 1);
      }
      setRejectedDates(_rejectedDates);
    } else {
      let _rejectedDates = [...rejectedDates];

      _rejectedDates.push(value);
      setRejectedDates(_rejectedDates);
    }
  };
  // const Print = () => {
  //   // //console.log('print');
  //   // let printContents = document.getElementById("printableArea").innerHTML;
  //   // let originalContents = document.body.innerHTML;
  //   // document.body.innerHTML = printContents;
  //   // window.print();
  //   // document.body.innerHTML = originalContents;

  // };

  const handleModalClose = (data) => {
    callRemainingLeave(approval?.leave?.staff_id);
    console.log(data);
    // Store the data passed from the modal
    setShowEarnLeaveModal(false); // Close the modal
  };
  const handleDateRangeModalClose = (data) => {
    checkData();
    console.log(data);
    // Store the data passed from the modal
    setShowChangeDateRangeModal(false); // Close the modal
  };

  const Print = useReactToPrint({
    content: () => printRef.current,
  });
  const PrintEarned = useReactToPrint({
    content: () => printRefEarned.current,
  });
  let thClass =
    " text-gray-600   border p-1  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-1 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      {loading == true && (
        <>
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        </>
      )}

      <div className='onlyPrint' ref={printRefEarned}>
        {showEarnedLeavePrint && (
          <EarnedLeavePrint leave_types={leave_types} approval={approval} />
        )}
        ``
      </div>

      <div className='py-2' ref={printRef}>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5'>
            {approval?.leave?.leave_type_id == 6 && (
              <button
                className='inline-block float-right px-3 py-1 font-normal leading-normal text-center text-white no-underline whitespace-no-wrap align-middle bg-teal-500 border rounded select-none noPrint hover:bg-teal-600 btn-md screen-only '
                onClick={() => {
                  setShowEarnedLeavePrint(true);
                  setTimeout(function () {
                    PrintEarned();
                  }, 500);
                  setTimeout(function () {
                    setShowEarnedLeavePrint(false);
                  }, 500);
                }}
              >
                Print
              </button>
            )}
            {approval?.leave?.leave_type_id != 6 && (
              <button
                className='inline-block float-right px-3 py-1 font-normal leading-normal text-center text-white no-underline whitespace-no-wrap align-middle bg-teal-500 border rounded select-none noPrint hover:bg-teal-600 btn-md screen-only '
                onClick={Print}
              >
                Print
              </button>
            )}
            <div className='grid grid-cols-6 pt-1 pl-2 pr-2 mt-5 onlyPrint'>
              <div>
                <img
                  style={{ filter: "saturate(200%)" }}
                  src='https://erp.dibru.work/images/dibru.png'
                  className='rounded-full w-50'
                  alt='Avatar'
                />
              </div>
              <div className='col-span-4'>
                <p className='text-lg font-bold text-center'>
                  <img
                    style={{ width: "200px" }}
                    className='w-20 ml-auto mr-auto mt-o '
                    src='/assets/logo-assamese.png'
                  />
                  Dibrugarh University
                </p>
                <p className='text-center text-md '>
                  Dibrugarh-786004, Assam, India
                </p>
              </div>
              <div> </div>
            </div>
            <PageTitle name='Leave Details' />

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <tbody>
                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data' colSpan={4}>
                    <span className='font-bold'>
                      Application Submitted on:{" "}
                      {moment(approval?.leave?.created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </span>
                  </td>
                </tr>
                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>Applied By</td>
                  <td className='table-data'>
                    <b> {approval?.leave?.staff?.full_name}</b>
                    <br />
                    {approval?.leave?.staff?.designation?.name} <br />
                    {approval?.leave?.staff?.department?.name} <br />
                    <br />
                  </td>
                  <td className='table-data'>Leave Type</td>
                  <td className='table-data'>
                    {" "}
                    {/* {approval?.leave?.leave_type.name}{" "} */}
                    {[
                      ...new Set(
                        approval?.leave?.leave_dates?.map(
                          (item) => item.leave_type.name
                        )
                      ),
                    ].toString()}
                  </td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>No. of Days</td>
                  <td className='table-data'>
                    {approval?.leave?.status != 1 && approval?.leave.total_days}
                    {approval?.leave?.status == 1 &&
                      approval?.leave?.leave_type_id != 3 && (
                        <>
                          {
                            approval.leave?.leave_dates.filter(
                              (ld) => ld.status == 1
                            ).length
                          }
                        </>
                      )}

                    {approval?.leave?.status == 1 &&
                      approval?.leave?.leave_type_id == 3 && (
                        <>{approval.leave?.leave_dates.length}</>
                      )}
                  </td>
                  <td className='w-32 table-data'>Reason/Remarks</td>
                  <td className='table-data'>{approval?.leave?.reason}</td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>
                    Proposed In-charge <br /> (if applicable)
                  </td>
                  <td className='table-data'>
                    {approval?.leave?.proposed_incharge}
                    {approval?.leave?.incharges?.map((incharge) => (
                      <>
                        {incharge.staff?.full_name}-{incharge.remarks} <br />
                      </>
                    ))}
                  </td>
                  <td className='table-data'>
                    Arrangement of Classes/Lab <br /> (if applicable)
                  </td>
                  <td className='table-data'>{approval?.leave?.arrangement}</td>
                </tr>

                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>Forwarding Authority</td>
                  <td className='table-data'>
                    {approval?.leave?.forwarding_authority?.full_name}
                    <br />
                    {approval?.leave?.forwarding_authority2?.full_name}
                  </td>
                  <td className='table-data'>Approving Authority</td>
                  <td className='table-data'>
                    {approval?.leave?.approving_authority?.full_name}
                  </td>
                </tr>
                <tr className='odd:bg-white even:bg-gray-50'>
                  <td className='table-data'>Status</td>
                  <td className='table-data'>
                    {" "}
                    {approval?.leave?.status == 0 && (
                      <>
                        <span className='text-yellow-600'>
                          Pending for Approval
                        </span>
                      </>
                    )}
                    {approval?.leave?.status == 1 && (
                      <>
                        <span className='text-green-700'>Approved</span>
                      </>
                    )}
                    {approval?.leave?.status == 2 && (
                      <>
                        <span className='text-red-700'>Rejected </span>
                      </>
                    )}
                    {approval?.leave?.status == 3 && (
                      <>
                        <span className='text-purple-700'>Forwarded</span>
                      </>
                    )}
                    {approval?.leave?.status == -1 && (
                      <>
                        <span className='text-red-900'>Cancelled</span>
                      </>
                    )}
                  </td>
                  <td className='table-data'>Supporting Document</td>
                  <td className='table-data'>
                    {approval?.leave?.file != null && (
                      <a
                        target='_blank'
                        href={approval?.leave?.supporting_doc}
                        className='text-blue-700 noPrint'
                        rel='noreferrer'
                      >
                        View Document
                      </a>
                    )}
                    {approval?.leave?.file == null && "Not Uploaded"}
                    {approval?.leave?.file != null && (
                      <span className='onlyPrint'>Uploaded</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr className='h-px my-8 bg-gray-200 border-0 noPrint dark:bg-gray-700' />
            <div className='grid-cols-3 gap-1 lg:grid'>
              <div className=''>
                <h2 className='mb-1 font-bold'>Applied Dates:</h2>
                <table>
                  <tr>
                    <th className={thClass}>From</th>
                    <th className={thClass}>To</th>
                  </tr>
                  <tr>
                    <td className={tdClass}>
                      {moment(approval?.leave?.from_date).format("DD-MM-YYYY")}{" "}
                      {approval?.leave.leave_type_id == 3 && (
                        <>
                          {approval?.leave.sl_from_time
                            ? approval?.leave.sl_from_time
                            : ""}
                        </>
                      )}
                    </td>
                    <td className={tdClass}>
                      {moment(approval?.leave?.to_date).format("DD-MM-YYYY")}{" "}
                      {approval?.leave.leave_type_id == 3 && (
                        <>
                          {approval?.leave.sl_from_time
                            ? approval?.leave.sl_from_time
                            : ""}
                        </>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
              <div className='col-span-2'>
                <h2 className='mb-1 font-bold'>Status:</h2>
                <table>
                  <tr>
                    <th className={thClass}>Authority</th>
                    <th className={thClass}>Status</th>
                    <th className={thClass}>Date</th>
                    <th className={thClass}>Remark</th>
                  </tr>

                  {approval?.leave?.leave_approvals?.length > 0 &&
                    approval?.leave?.leave_approvals.map((leave_approve) => {
                      return (
                        <tr
                          key={leave_approve.id}
                          className='odd:bg-white even:bg-gray-50'
                        >
                          <td className={tdClass}>
                            {leave_approve?.approving_authority?.full_name}
                          </td>
                          <td className={tdClass}>
                            {leave_approve?.status == 0 && "Pending"}
                            {leave_approve?.status == 1 &&
                              leave_approve?.type == 1 &&
                              "Approved"}
                            {leave_approve?.status == 1 &&
                              leave_approve?.type == 0 &&
                              "Forwarded"}
                            {leave_approve?.status == 2 &&
                              leave_approve?.type == 0 &&
                              "Rejected"}
                            {leave_approve?.status == 2 &&
                              leave_approve?.type == 1 &&
                              "Rejected"}
                            {leave_approve?.status == -1 && "Cancelled"}
                          </td>
                          <td className={tdClass}>
                            {" "}
                            {leave_approve.approve_date
                              ? moment(leave_approve.approve_date).format(
                                  "DD-MM-YYYY"
                                )
                              : ""}
                          </td>
                          <td className={tdClass}>{leave_approve.remark}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>

            <hr className='h-px my-8 bg-gray-200 border-0 noPrint dark:bg-gray-700' />

            {approval?.leave.leave_type_id != 3 && (
              <>
                <table
                  id='datatable'
                  className='min-w-full mt-5 bg-white lg:mt-1 dark:bg-gray-800'
                >
                  <thead>
                    <tr>
                      <th className={thClass + "w-16"}>
                        {(authData?.user?.id == 495 ||
                          authData?.user?.id == 496) &&
                          approval?.status == 0 && (
                            <button
                              onClick={() => {
                                setShowChangeDateRangeModal(true);
                              }}
                              type='button'
                              className='p-1 text-white bg-red-400'
                            >
                              Change date range
                            </button>
                          )}

                        {showChangeDateRangeModal && (
                          <ChangeDateRangeModal
                            leave={approval?.leave}
                            setShowChangeDateRangeModal={
                              handleDateRangeModalClose
                            }
                          />
                        )}
                      </th>
                      <th className={thClass}>Date</th>
                      <th colSpan='2' className={thClass}>
                        Select
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {approval?.leave?.leave_dates?.length > 0 &&
                      (showAllDates
                        ? approval.leave.leave_dates
                        : approval.leave.leave_dates.slice(0, 5)
                      ).map((leave_date) => {
                        return (
                          <tr
                            key={leave_date.id}
                            className='odd:bg-white even:bg-gray-50'
                          >
                            <td className={tdClass + "w-32"}>
                              {leave_date.holiday == 1 ? (
                                <>
                                  {(leave_date.leave_type_id == 4 ||
                                    leave_date.leave_type_id == 6) && (
                                    <span className='ml-1 mr-2'>
                                      {leave_date?.leave_type?.name}
                                    </span>
                                  )}

                                  {leave_date.holiday_type_id == -1 && (
                                    <>
                                      <span className='text-red-500'>
                                        Sunday
                                      </span>
                                    </>
                                  )}
                                  {leave_date.holiday_type_id == -2 && (
                                    <>
                                      <span className='text-red-500'>
                                        Saturday
                                      </span>
                                    </>
                                  )}
                                  {leave_date.leave_type_id == 6 && (
                                    <>
                                      {leave_date?.date &&
                                      ["Saturday", "Sunday"].includes(
                                        moment(leave_date?.date).format("dddd")
                                      ) === false
                                        ? moment(leave_date?.date).format(
                                            "dddd"
                                          )
                                        : null}
                                      &nbsp;
                                    </>
                                  )}
                                  <>
                                    <span className='text-red-500'>
                                      {leave_date.holiday_type?.name}
                                    </span>
                                  </>
                                </>
                              ) : (
                                <>
                                  {approval?.leave?.approver_id ==
                                  authData.user.id ? (
                                    <>
                                      {approval?.leave?.status != 1 ||
                                      approval?.leave?.status != 2 ? (
                                        approval?.leave?.leave_type_id == 4 ||
                                        approval?.leave?.leave_type_id == 5 ? (
                                          <>
                                            {" "}
                                            {approval?.leave?.status == 1 ||
                                            approval?.leave?.status == 2 ? (
                                              leave_date?.leave_type?.name
                                            ) : (
                                              <select
                                                value={leave_date.leave_type_id}
                                                name='leave_type_id'
                                                onChange={async (e) => {
                                                  e.target.disabled = true;
                                                  await handleUpdate({
                                                    leave_date_id:
                                                      leave_date.id,

                                                    leave_type_id:
                                                      e.target.value,
                                                  }).catch((e) => {
                                                    alert(
                                                      "Something Went Wrong!"
                                                    );
                                                    e.target.disabled = false;
                                                  });
                                                  e.target.disabled = false;
                                                  console.log(e.target.value);
                                                }}
                                              >
                                                <option>Select</option>
                                                {leave_types.map((option) => {
                                                  return (
                                                    <>
                                                      {" "}
                                                      {(option.id == 1) &
                                                        (option.pivot
                                                          .no_of_leave -
                                                          option.count >
                                                          0) ||
                                                      option.id ==
                                                        approval?.leave
                                                          ?.leave_type_id ? (
                                                        <option
                                                          value={option.id}
                                                        >
                                                          {" "}
                                                          {`${
                                                            option.name
                                                          } (Remaining- ${
                                                            option.pivot
                                                              .no_of_leave -
                                                            option.count
                                                          })`}
                                                        </option>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  );
                                                })}
                                              </select>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <select
                                              disabled={true}
                                              value={leave_date.leave_type_id}
                                              name='leave_type_id'
                                              onChange={async (e) => {
                                                e.target.disabled = true;
                                                await handleUpdate({
                                                  leave_date_id: leave_date.id,

                                                  leave_type_id: e.target.value,
                                                }).catch((e) => {
                                                  alert(
                                                    "Something Went Wrong!"
                                                  );
                                                  e.target.disabled = false;
                                                });
                                                e.target.disabled = false;
                                                console.log(e.target.value);
                                              }}
                                            >
                                              <option>Select</option>
                                              {leave_types.map((option) => {
                                                return (
                                                  <>
                                                    {" "}
                                                    <option value={option.id}>
                                                      {" "}
                                                      {`${
                                                        option.name
                                                      } (Remaining- ${
                                                        option.pivot
                                                          .no_of_leave -
                                                        option.count
                                                      })`}
                                                    </option>
                                                  </>
                                                );
                                              })}
                                            </select>
                                          </>
                                        )
                                      ) : (
                                        <>
                                          {leave_date.leave_type_id == 6 && (
                                            <>
                                              &nbsp;&nbsp;
                                              {leave_date?.date
                                                ? moment(
                                                    leave_date.date
                                                  ).format("dddd")
                                                : ""}
                                            </>
                                          )}
                                          {leave_date?.leave_type?.name}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {leave_date?.leave_type?.name}
                                      {leave_date.leave_type_id == 6 && (
                                        <>
                                          &nbsp;&nbsp;
                                          {leave_date?.date
                                            ? moment(leave_date.date).format(
                                                "dddd"
                                              )
                                            : ""}
                                        </>
                                      )}
                                      {/* {approval?.leave?.status == 0 && (
                                        <>
                                          {`(Remaining- ${
                                            leave_types.find(
                                              (lt) =>
                                                lt.pivot.leave_type_id ==
                                                approval?.leave?.leave_type?.id
                                            )?.pivot.no_of_leave -
                                            leave_types.find(
                                              (lt) =>
                                                lt.pivot.leave_type_id ==
                                                approval?.leave?.leave_type?.id
                                            )?.count
                                          }).`}{" "}
                                        </>
                                      )} */}
                                    </>
                                  )}
                                </>
                              )}
                            </td>

                            <td className={tdClass}>
                              {moment(leave_date.date).format("DD-MM-YYYY")}
                            </td>
                            <td colSpan='2' className={tdClass}>
                              {leave_date.holiday == 1 &&
                              leave_date.leave_type_id != 4 &&
                              leave_date.leave_type_id != 6 ? (
                                ""
                              ) : (
                                <>
                                  {approval?.leave?.status == 1 ||
                                  approval?.leave?.status == 2 ? (
                                    <input
                                      className='float-left w-8 h-8 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input accent-green-500 checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                      type='checkbox'
                                      checked={leave_date.status == 1}
                                      value={leave_date.id}
                                      onChange={() => {}}
                                    />
                                  ) : (
                                    <>
                                      {approval?.leave?.approver_id ==
                                        authData.user.id ||
                                      approval?.approver_id == 495 ||
                                      approval?.approver_id == 496 ? (
                                        <input
                                          className='float-left w-8 h-8 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input accent-green-500 checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                          type='checkbox'
                                          checked={
                                            !rejectedDates.includes(
                                              leave_date.id.toString()
                                            )
                                          }
                                          value={leave_date.id}
                                          onChange={(event) =>
                                            handleCheckbox(event)
                                          }
                                        />
                                      ) : (
                                        <input
                                          className='float-left w-8 h-8 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input accent-green-500 checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                          type='checkbox'
                                          checked='yes'
                                          value={leave_date.id}
                                          onChange={() => {}}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className='flex items-center justify-center'>
                  {!showAllDates &&
                    approval?.leave?.leave_dates?.length > 5 && (
                      <button
                        className='text-sm text-blue-500 '
                        onClick={() => setShowAllDates(true)}
                      >
                        Show More
                      </button>
                    )}
                  {showAllDates && approval?.leave?.leave_dates?.length > 5 && (
                    <button
                      className='text-sm text-blue-500 '
                      onClick={() => setShowAllDates(false)}
                    >
                      Show Less
                    </button>
                  )}{" "}
                </div>

                <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />
                <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <table>
                    <tr>
                      <th className='table-head'>Have Station Leave?:</th>
                      <td className='table-data'>
                        {approval?.leave?.station_leave == 1 ? "Yes" : "No"}
                      </td>
                    </tr>
                    {approval?.leave?.station_leave == 1 && (
                      <>
                        <tr>
                          <th className='table-head'>From:</th>
                          <td className='table-data'>
                            {moment(approval?.leave.sl_from_date).format(
                              "DD-MM-YYYY"
                            )}{" "}
                            {approval?.leave.sl_from_time}
                          </td>
                        </tr>
                        <tr>
                          <th className='table-head'>To:</th>
                          <td className='table-data'>
                            {moment(approval?.leave.sl_to_date).format(
                              "DD-MM-YYYY"
                            )}{" "}
                            {approval?.leave.sl_to_time}
                          </td>
                        </tr>

                        <tr>
                          <th className='table-head'>
                            Address During Absence:
                          </th>
                          <td className='table-data'>
                            {approval?.leave?.address}
                          </td>
                        </tr>
                      </>
                    )}
                  </table>
                </div>
              </>
            )}

            {/* 
            <hr className="m-5" />
            <div className="">
              <b>Forwarding Authority: </b>
              {approval?.leave?.forwarding_authority?.name}
            </div>
            <div className="">
              <b> Approving Authority: </b>{" "}
              {approval?.leave?.approving_authority?.name}
            </div> */}
            {approval?.leave?.leave_type_id == 6 && (
              <div className='mt-10'>
                Last Updated No. of Accumulated Earned Leaves :{" "}
                {leave_types?.find((lt) => lt.id == 6)?.pivot?.no_of_leave ==
                  -1 && <b>Not Calculated</b>}
                {leave_types?.find((lt) => lt.id == 6)?.pivot?.no_of_leave !=
                  -1 && (
                  <b>
                    {leave_types?.find((lt) => lt.id == 6)?.pivot?.no_of_leave}{" "}
                    as on{" "}
                    {moment(
                      leave_types?.find((lt) => lt.id == 6)?.pivot?.date
                    ).format("DD-MM-YYYY")}{" "}
                  </b>
                )}
                <br />
                {(authData.user.id == 495 || authData.user.id == 496) &&
                  approval?.status == 0 && (
                    <button
                      onClick={() => setShowEarnLeaveModal(true)}
                      className='px-4 py-2 my-3 text-sm font-bold text-white bg-green-500 rounded hover:bg-green-700'
                    >
                      Update Record
                    </button>
                  )}
                {showEarnLeaveModal && (
                  <EarnedLeaveEntryModal
                    staff={approval?.leave?.staff}
                    leave_approval={approval}
                    setShowEarnLeaveModal={handleModalClose}
                  />
                )}
              </div>
            )}

            <hr className='m-5' />
            <div className='font-medium'>
              {(authData.user.id == 495 || authData.user.id == 496) && (
                <>
                  No of days applied:{" "}
                  {approval?.leave?.status != 1 && (
                    <b>{approval?.leave.total_days}</b>
                  )}
                  {approval?.leave?.status == 1 &&
                    approval?.leave?.leave_type_id != 3 && (
                      <>
                        {
                          approval.leave?.leave_dates.filter(
                            (ld) => ld.status == 1
                          ).length
                        }
                      </>
                    )}
                  {approval?.leave?.status == 1 &&
                    approval?.leave?.leave_type_id == 3 && (
                      <>{approval.leave?.leave_dates.length}</>
                    )}{" "}
                  - Leave Accumulated:
                  {leave_types?.find((lt) => lt.id == 6)?.pivot?.no_of_leave !=
                    -1 && (
                    <b>
                      {
                        leave_types?.find((lt) => lt.id == 6)?.pivot
                          ?.no_of_leave
                      }
                    </b>
                  )}
                </>
              )}
            </div>
            <div className='noPrint'>
              {approval?.leave?.cancelation_reason == null && (
                <>
                  {approval?.status == 0 && (
                    <button
                      name='forward'
                      className='px-4 py-2 m-3 font-bold text-white bg-red-500 rounded hover:bg-red-700'
                      onClick={async (event) => {
                        event.target.disabled = true;
                        let reason = window.prompt(
                          "Are you sure? Please enter a reason. \n This will also be visible in applier panel. "
                        );
                        if (reason == null || reason.trim() == "") {
                          return;
                        } else {
                          await handleReject(reason);
                        }
                        event.target.disabled = false;
                      }}
                    >
                      Reject
                    </button>
                  )}

                  {(approval?.leave?.forwarder_id == authData.user.id ||
                    approval?.leave?.forwarder_idd == authData.user.id ||
                    approval?.leave?.leave_type_id == 6) &&
                    approval?.type == 0 &&
                    approval.status == 0 && (
                      <button
                        name='forward'
                        className='px-4 py-2 m-3 font-bold text-white bg-blue-500 rounded hover:bg-blue-700'
                        onClick={async (event) => {
                          event.target.disabled = true;
                          let reason = window.prompt(
                            "Are you sure? Please enter remarks (Optional) \n This will also be visible in applier panel"
                          );
                          console.log(reason);
                          if (reason == null) {
                            return;
                          } else {
                            await handleSubmit("forward", reason.trim());
                          }
                          event.target.disabled = false;
                        }}
                        //  onClick={() => {
                        //   if (window.confirm("Are you sure?") != true) {
                        //     return;
                        //   }
                        //   handleSubmit("forward");
                        // }}
                      >
                        Forward
                      </button>
                    )}
                  {approval?.leave?.approver_id == authData.user.id &&
                    approval.status == 0 && (
                      <button
                        name='forward'
                        className='px-4 py-2 m-3 font-bold text-white bg-blue-500 rounded hover:bg-blue-700'
                        onClick={async (event) => {
                          event.target.disabled = true;
                          let reason = window.prompt(
                            "Are you sure? Please enter remarks (Optional)"
                          );
                          if (reason == null) {
                            return;
                          } else {
                            await handleSubmit("approve", reason.trim());
                          }
                          event.target.disabled = false;
                        }}
                        //  onClick={() => {
                        //   if (window.confirm("Are you sure?") != true) {
                        //     return;
                        //   }
                        //   handleSubmit("approve");
                        // }}
                      >
                        Approve
                      </button>
                    )}

                  {approval?.approver_id == 494 &&
                    approval?.status == 0 &&
                    approval?.leave?.leave_type_id != 6 && (
                      <button
                        name='forward'
                        className='float-right px-4 py-2 m-3 font-bold text-white bg-orange-500 rounded hover:bg-orange-700'
                        onClick={async (event) => {
                          event.target.disabled = true;
                          let reason = window.prompt(
                            "Are you sure? Please enter remarks (Optional) \n This will also be visible in applier panel"
                          );
                          console.log(reason);
                          if (reason == null) {
                            return;
                          } else {
                            await handleSubmit("forwardtovc", reason.trim());
                          }
                          event.target.disabled = false;
                        }}
                        //  onClick={() => {
                        //   if (window.confirm("Are you sure?") != true) {
                        //     return;
                        //   }
                        //   handleSubmit("forward");
                        // }}
                      >
                        Forward to Vice-Chancellor
                      </button>
                    )}
                </>
              )}
              {approval?.leave?.cancelation_reason != null &&
                approval?.leave?.status == -1 && (
                  <>
                    <div>
                      Leave Cancelled: <br />
                      Reason:{approval?.leave?.cancelation_reason}{" "}
                    </div>
                  </>
                )}
              {approval?.leave?.cancelation_reason != null &&
                ((approval?.leave?.status == 0 &&
                  (approval?.leave?.approver_id == authData.user.id ||
                    approval?.leave?.forwarder_id == authData.user.id)) ||
                  (approval?.leave?.status == 3 &&
                    approval?.approver_id == authData.user.id &&
                    approval?.status == 0) ||
                  (approval?.leave?.status == 1 &&
                    approval?.leave?.approver_id == authData.user.id) ||
                  (approval?.leave?.status == 0 &&
                    (approval.approver_id == 495 ||
                      approval.approver_id == 496))) && (
                  <>
                    <div>
                      Cancellation Requested: <br />
                      Reason:{approval?.leave?.cancelation_reason}{" "}
                    </div>

                    <button
                      name='forward'
                      className='px-4 py-2 m-3 font-bold text-white bg-orange-500 rounded hover:bg-orange-700'
                      onClick={async (event) => {
                        event.target.disabled = true;
                        if (window.confirm("Are you sure?") != true) {
                          return;
                        }
                        await handleCancelation("reject_cancelation");
                        event.target.disabled = false;
                      }}
                    >
                      Reject Cancellation
                    </button>
                    <button
                      name='forward'
                      className='px-4 py-2 m-3 font-bold text-white bg-green-500 rounded hover:bg-green-700'
                      onClick={async (event) => {
                        event.target.disabled = true;
                        if (window.confirm("Are you sure?") != true) {
                          return;
                        }
                        await handleCancelation("accept_cancelation");
                        event.target.disabled = false;
                      }}
                    >
                      Accept Cancellation
                    </button>
                  </>
                )}
            </div>
            <div className='text-right onlyPrint'>
              <br />
              <br />
              <br />
              Leave granted / Not granted <br />
              Approving Authority
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
