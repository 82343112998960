import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";

import moment from "moment";
import {
  FaBriefcase,
  FaCalendar,
  FaCalendarDays,
  FaCheck,
  FaLocationDot,
  FaMapLocationDot,
} from "react-icons/fa6";
import { FaMapMarker } from "react-icons/fa";

const headers = {
  name: { label: "Title of the Activity", path: "name" },
  nature: { label: "Nature of the Activity", path: "nature" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  place: { label: "Place of Activity", path: "place" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const CorporateLife = ({ view }) => {
  const [corporateLives, setCorporateLives] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/corporate-lives");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredLives = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by 'from_date' in descending order
      const sortedLives = filteredLives.sort((a, b) => {
        const dateA = moment(getValueByPath(a, headers.from_date.path));
        const dateB = moment(getValueByPath(b, headers.from_date.path));
        return dateB - dateA; // Descending order
      });

      setCorporateLives(sortedLives);
    } catch (error) {
      console.error(error);
    }
  };

  const renderCorporateLife = (activity) => {
    const name = getValueByPath(activity, headers.name.path);
    const nature = getValueByPath(activity, headers.nature.path);
    const fromDate = formatDate(
      getValueByPath(activity, headers.from_date.path)
    );

    const toDate = getValueByPath(activity, headers.to_date.path)
      ? formatDate(getValueByPath(activity, headers.to_date.path))
      : "";

    const place = getValueByPath(activity, headers.place.path);

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          <p className='flex items-center gap-2'>
            <FaBriefcase />
            <span className='text-green-600'>{nature}</span>
          </p>
 
          <p className='flex items-center gap-2'>
            <FaCalendar />
            <span className='text-gray-800'>
            {toDate ? (
              <>
                {fromDate} - {toDate}
              </>
            ) : (
              fromDate
            )}</span>

 
            <FaMapMarker />
            <span className='text-gray-800'>{place}</span>
          </p>
        </div>
      </div>
    );
  };

  const renderCorporateLive = (corporateLive) => {
    const name = getValueByPath(corporateLive, headers.name.path);
    const nature = getValueByPath(corporateLive, headers.nature.path);
    const date = formatDate(getValueByPath(corporateLive, headers.date.path));
    const place = getValueByPath(corporateLive, headers.place.path);

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>

          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaCheck className='text-yellow-500' />
              {nature}
            </span>
            <span className='flex items-center gap-1'>
              <FaCalendar className='text-yellow-500' />
              {moment(date).format("YYYY-MM-DD")}
            </span>
            <span className='flex items-center gap-1'>
              <FaMapLocationDot className='text-red-500' />
              {place}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        {/* <SiHiveBlockchain /> */}
        Contribution to Corporate Life
      </h2>
      {corporateLives.length ? (
        <div className=''>
          {corporateLives.map((activity, index) => (
            <div key={index} className=''>
              {renderCorporateLife(activity)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default CorporateLife;
