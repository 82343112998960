import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";
import Loader from "../../utils/Loader";

const headers = {
  qualification_master_id: {
    label: "Qualification Type",
    path: "qualification_master.name",
  },
  qualification: {
    label: "Qualification",
    path: "qualification",
  },
  institute: { label: "Institute", path: "institute" },
  subject: { label: "Subject", path: "subject" },
  pass_year: { label: "Pass Year", path: "pass_year" },
};

const EduQualification = () => {
  const [qualifications, setQualifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await BaseApi().get(
        "/profile/educational-qualifications"
      );
      setQualifications(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Educational Qualifications
      </h2>

      {loading ? (
        <div className="flex justify-center items-center">
          <div className="loader">
            <Loader />
          </div>{" "}
          {/* You can customize this with CSS */}
        </div>
      ) : (
        <TableViewProfile
          headers={headers}
          data={qualifications}
          type="educational-qualifications"
          setData={setQualifications}
        />
      )}
    </div>
  );
};

export default EduQualification;
