import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import { getHostelsAsync } from "../../Redux/MasterSlice";
import BaseApi from "../../Http/BaseApi";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
import BasicInput from "../../Components/Utils/BasicInput";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function NotReportedHostelerIndex() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [hostelers, setHostelers] = useState();
  const [searchInput, setSearchInput] = useState("");
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const hostels = [...master.hostels];
  const [showNotReportedModal, setShowNotReportedModal] = useState(false);
  const [selectedHosteler, setSelectedHosteler] = useState();
  const [notReported, setNotReported] = useState({
    hosteler_id: "",
    remarks: "",
    not_reported: 0,
  });

  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;
  const initialSearchValues = {
    hostel_id: "",
    not_reported: 1,
  };

  const validationSchema = Yup.object().shape({
    hostel_id: Yup.string().nullable().required("This field is required!"),
  });
  useEffect(() => {
    if (hostels.length === 0) {
      dispatch(getHostelsAsync())
        .unwrap()
        .then((data) => {
          if (data?.length == 1) {
            handleSearch({
              hostel_id: data[0].id,
            });
          }
        });
    } else {
      if (hostels?.length == 1) {
        handleSearch({
          hostel_id: hostels[0].id,
        });
      }
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await callHostelers(searchQuery);
    setLoading(false);
  };

  async function callHostelers(searchQuery) {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`not-reported-hostelers`, {
        params: searchQuery,
      })
      .then((data) => {
        setHostelers(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  const handleChangeNotReported = (event) => {
    console.log(event.target.name);
    const values = { ...notReported };
    values[event.target.name] = event.target.value;

    setNotReported(values);
  };

  const addNotReported = async (e) => {
    e.preventDefault();
    console.log(notReported);
    try {
      await BaseApi().post(
        `update-hostelers/${notReported.hosteler_id}`,
        notReported
      );
      toast.success("Status Changed!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,

        progress: undefined,
      });
      setShowNotReportedModal(false);
      setEdit(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      window.alert(message);
    }

    setNotReported({
      hosteler_id: "",
      remarks: "",
      not_reported: 0,
    });

    let _hostelers = hostelers.filter((h) => h.id != notReported.hosteler_id);
    setHostelers(_hostelers);
  };

  //   const handleCancelAdmission = (student) => {
  //     dispatch(cancelOldStudentAsync(student));
  //   };

  //   const handleDeletePayment = async (payment) => {
  //     await BaseApi().delete(`payments/${payment.id}`, {
  //       params: { remove_admission_permission: 1 },
  //     });
  //     let newPayments = payments.filter((e) => e.id != payment.id);
  //     setPayments(newPayments);
  //   };
  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='List of Students who did not take the seat' />

            {hostels.length > 1 && (
              <Formik
                innerRef={formikRef}
                initialValues={initialSearchValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className=' p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-2'>
                    <CustomSelect
                      options={hostels}
                      name='hostel_id'
                      label='Select Hostel'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "hostel_id",
                          selectedOption.id
                        );
                      }}
                    />

                    <div className='text-left p-5 mt-1  '>
                      <button
                        type='submit'
                        className='group relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && <span>Searching...</span>}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}
            {hostelers?.length > 0 && (
              <div>
                <div>
                  {edit && hostels.length == 1 && (
                    <>
                      <Button
                        type='button'
                        classNames=' mr-2   bg-green-600 hover:bg-green-700 focus:ring-green-500'
                        value={"Done"}
                        icon={"CheckIcon"}
                        onClick={() => {
                          setEdit(false);
                        }}
                      />
                    </>
                  )}
                  {!edit && hostels.length == 1 && (
                    <Button
                      type='button'
                      classNames='mr-2   bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                      value={"Edit"}
                      icon={"PencilIcon"}
                      onClick={() => {
                        setEdit(true);
                      }}
                    />
                  )}
                </div>
                <div>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='form-label inline-block mt-5   text-gray-700'
                  >
                    Search By Name
                  </label>
                  <input
                    className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                    placeholder='Type here...'
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </div>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Name</th>

                  <th className='table-head'>Enrollment ID</th>
                </tr>
              </thead>
              <tbody>
                {hostelers?.length > 0 &&
                  hostelers
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((hosteler) => {
                      return (
                        <>
                          <tr
                            className={
                              (hosteler.room_no == null ||
                                hosteler.in_date == null) &&
                              "bg-red-100"
                            }
                          >
                            <td className='table-data'>{++sl}</td>

                            <td className='table-data'>
                              {hosteler.student?.name}

                              <br />
                              {edit && (
                                <button
                                  type='button'
                                  onClick={() => {
                                    setShowNotReportedModal(true);
                                    setSelectedHosteler(hosteler);
                                    const values = { ...notReported };
                                    values["hosteler_id"] = hosteler.id;

                                    setNotReported(values);
                                  }}
                                  className='text-sm font-medium text-green-700 flex items-start'
                                >
                                  Mark As Seat Taken
                                  <ExclamationCircleIcon
                                    className='h-4 w-4 ml-1'
                                    aria-hidden='true'
                                  />
                                </button>
                              )}
                            </td>
                            <td className='table-data'>
                              <span
                                className='cursor-pointer text-blue-500'
                                onClick={() =>
                                  window.open(
                                    `/admitted-students/${
                                      hosteler.student?.programmes?.length >
                                        0 &&
                                      hosteler.student?.programmes[
                                        hosteler.student?.programmes.length - 1
                                      ].pivot.id
                                    }`,
                                    "_blank"
                                  )
                                }
                              >
                                {/* {hosteler.student?.programmes?.length > 0 &&
                                  hosteler.student?.programmes[
                                    hosteler.student?.programmes.length - 1
                                  ].pivot.unique_id} */}

                                {hosteler.student?.programmes?.length > 0 && (
                                  <>
                                    {hosteler.student?.programmes[
                                      hosteler.student?.programmes.length - 1
                                    ].pivot.unique_idd ||
                                      hosteler.student?.programmes[
                                        hosteler.student?.programmes.length - 1
                                      ].pivot.unique_id}
                                  </>
                                )}
                              </span>

                              <br />
                              <br />
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "15px",
                                }}
                              >
                                {hosteler.student?.programmes?.length > 0 &&
                                  hosteler.student?.programmes[
                                    hosteler.student?.programmes.length - 1
                                  ].pivot.discontinued == 1 &&
                                  "Programme Discontinued"}
                                {hosteler.student?.programmes?.length > 0 &&
                                  hosteler.student?.programmes[
                                    hosteler.student?.programmes.length - 1
                                  ].pivot.alumni == 1 &&
                                  "Alumni"}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}

            {showNotReportedModal ? (
              <>
                <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                  <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                    {/*content*/}
                    <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                        <h3 className='text-3xl font-semibold'>
                          {selectedHosteler.student?.name}
                        </h3>

                        <button
                          className='p-1 ml-auto bg-transparent border-0 text-black opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                          onClick={() => {
                            setEdit(false);
                            setShowNotReportedModal(false);
                          }}
                        >
                          X
                        </button>
                      </div>
                      {/*body*/}
                      <div className='relative p-6 flex-auto'>
                        <p className='my-4 text-slate-500 text-lg leading-relaxed'>
                          <BasicInput
                            type='text'
                            name='remarks'
                            defaultValue={selectedHosteler.remarks}
                            onChange={(event) => handleChangeNotReported(event)}
                            label='Remarks (Optional)'
                            placeholder='Enter any remark'
                          />
                          <br />
                        </p>
                      </div>
                      {/*footer*/}
                      <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                        <button
                          className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                          type='button'
                          onClick={async (event) => {
                            event.target.disabled = true;
                            await addNotReported(event);
                            event.target.disabled = false;
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
              </>
            ) : null}

            {hostelers?.length == 0 && (
              <div className='text-center my-10'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
