import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  startup_name: { label: "Name of the Startup", path: "startup_name" },
  date_started: { label: "Date Started", path: "date_started" },
  registration_details: {
    label: "Registration Details",
    path: "registration_details",
  },
  objective: { label: "Objective of the Startup", path: "objective" },
  details: { label: "Details of the Startup", path: "details" },
  role: { label: "Your Role in the Startup", path: "role" },
};

const StartupDevelopment = () => {
  const [startupDevelopments, setStartupDevelopments] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/startup-developments");
      setStartupDevelopments(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Startup
      </h2>
      <TableViewProfile
        headers={headers}
        data={startupDevelopments}
        type="startup-developments"
        setData={setStartupDevelopments}
      />
    </div>
  );
};

export default StartupDevelopment;
