import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";
// import Input from "../../Components/Utils/Input";
import moment from "moment";
import BaseApi from "../../Http/BaseApi";
// import Select from "../../../Components/Utils/Select";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";

export default function EarnedLeaveEntryModal({
  staff,
  leave_approval,
  setShowEarnLeaveModal,
  readOnlyDate = false,
}) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("called again");

    setInitialValues({
      leave_approval_id: leave_approval?.id,
      staff_id: staff.id,
      leave_type_id: 6, //earned leave
      no_of_leave: "",
      earned_remarks: "",
      date: moment(Date.now()).format("YYYY-MM-DD"),
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    staff_id: Yup.string().required("This field is required!"),
    leave_type_id: Yup.string().required("This field is required!"),
    date: Yup.string().required("This field is required!"),
    earned_remarks: Yup.string().required("This field is required!"),

    no_of_leave: Yup.number()
      .min(0, "Min value 0")
      .max(300, `Maximum value 300`)
      .required("This field is required!"),
  });
  const handleSubmit = async (payload) => {
    setLoading(true);
    console.log(payload);

    const _assignedLeave = await BaseApi().post(`assign-leave`, payload);

    setShowEarnLeaveModal(_assignedLeave.data);
    setLoading(false);
    setInitialValues({
      leave_approval_id: leave_approval?.id,
      staff_id: staff.id,
      leave_type_id: 6,
      no_of_leave: "",
      earned_remarks: "",
      //   type: payment.type,
      date: moment(Date.now()).format("YYYY-MM-DD"),
    });

    // setShowEarnLeaveModal(false);
  };

  console.log(message);
  return (
    <>
      <div className='fixed inset-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative w-auto max-w-3xl mx-auto my-6'>
          {/*content*/}

          <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
              <h3 className='text-3xl font-semibold'>Update Earned Leave</h3>
            </div>
            {/*body*/}
            <p style={{ width: "700px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative flex-auto p-6'>
                    <div className='grid grid-cols-2 gap-4'>
                      <Input
                        label='Date'
                        readOnly={readOnlyDate}
                        type='date'
                        name='date'
                      />
                      <Input
                        type='number'
                        label='No. of Leave'
                        name='no_of_leave'
                      />
                      <Input
                        type='text'
                        label='Remarks'
                        name='earned_remarks'
                      />
                    </div>

                    <p className='my-4 text-lg leading-relaxed text-slate-500'></p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200'>
                    <button
                      className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                      type='button'
                      onClick={() => setShowEarnLeaveModal(false)}
                    >
                      Close
                    </button>

                    <Button
                      disabled={loading}
                      value={loading ? "Updating..." : "Update"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
    </>
  );
}
