import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const LecturesTalk = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [lecturesTalks, setLecturesTalks] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    type: "",
    organization_name: "",
    workshop_name: "",
    lecture_date: "",
    lecture_details: "",
  });
  const headers = {
    title: { label: "Title of the lecture/talk", path: "title" },
    type: { label: "Type of lecture/talk", path: "type" },
    organization_name: {
      label: "Organization Name",
      path: "organization_name",
    },
    workshop_name: {
      label: "Name of the Workshop/Seminar/Occasion/Event",
      path: "workshop_name",
    },
    lecture_date: { label: "Date", path: "lecture_date" },
    lecture_details: { label: "Details", path: "lecture_details" },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
    organization_name: Yup.string().required("This field is required!"),
    workshop_name: Yup.string().required("This field is required!"),
    lecture_date: Yup.string().required("This field is required!"),
    // lecture_details: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/lectures-talks");
      setLecturesTalks(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/lectures-talks/${editId}`, values);
        toast.success(`Lecture/Talk Updated Scuccessfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/lectures-talks", values);
        toast.success(`Lecture/Talk Added Scuccessfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = lecturesTalks.find(
        (lecturesTalk) => lecturesTalk.id === id
      );
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        type: editableData.type,
        organization_name: editableData.organization_name,
        workshop_name: editableData.workshop_name,
        lecture_date: editableData.lecture_date,
        lecture_details: editableData.lecture_details,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      type: "",
      organization_name: "",
      workshop_name: "",
      lecture_date: "",
      lecture_details: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      type: "",
      organization_name: "",
      workshop_name: "",
      lecture_date: "",
      lecture_details: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/lectures-talks/${id}`);
      toast.success(`Lecture/Talk Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Lecture/Talk :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={lecturesTalks}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default LecturesTalk;
