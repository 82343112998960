import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  institution: { label: "Institution/Organization", path: "institution" },
  // visit_date: { label: "Date of Visit", path: "visit_date" },
  visit_purpose: { label: "Purpose of Visit", path: "visit_purpose" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
};

const AcademicVisits = () => {
  const [academicVisits, setAcademicVisits] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/academic-visits");
      setAcademicVisits(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Academic Visits
      </h2>
      <TableViewProfile
        headers={headers}
        data={academicVisits}
        type="academic-visits"
        setData={setAcademicVisits}
      />
    </div>
  );
};

export default AcademicVisits;
