import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseApi from "../../../Http/BaseApi";
import {
  AcademicVisitsFields,
  BodiesOrganizationFields,
  CollaborationFields,
  CommitteeFields,
  ConferenceSeminarFields,
  EduQualificationFields,
  JournalPublicationFields,
  ExpertiseFields,
  ExperienceFields,
  AuthoredBookFields,
  BookChapterFields,
  BooksEditedFields,
  PatentFields,
  ConsultancyExternalServicesFields,
  ProjectFields,
  HonorsAwardsFields,
  LecturesTalkFields,
  CorporateLifeFields,
  FIPActivitiesParticipationFields,
  StartupDevelopmentFields,
  PresentResearchScholarFields,
  PhdAwardeeFields,
  ExternalPhdScholarsFields,
  ThesisExaminerUniversitiesFields,
  ProgrammeOrganizedsFields,
  EContentDevelopmentsFields,
  DesignFields,
  WorkshopTrainingsFields,
} from "../ViewProfile/detailFieldsConfig";
import moment from "moment";

// Helper function to get the value from a nested path
const getValueByPath = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const isDate = (value) => {
  // Regex to match typical date formats e.g., 01/01/2000, 2000-01-01, etc.
  const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$|^\d{4}-\d{1,2}-\d{1,2}$/;
  return dateRegex.test(value) && !isNaN(Date.parse(value));
};
const formatDate = (value, format = "DD/MM/YYYY") => {
  // Format the date using moment
  return moment(value).format(format);
};

const detailFieldsConfig = {
  expertise: {
    fields: ExpertiseFields,
    label: "Expertise",
  },
  educational_qualifications: {
    fields: EduQualificationFields,
    label: "Educational Qualifications",
  },
  experiences: {
    fields: ExperienceFields,
    label: "Experience",
  },
  collaboration: {
    fields: CollaborationFields,
    label: "Collaboration",
  },
  committees: {
    fields: CommitteeFields,
    label: "Memberships in Significant Committees",
  },
  academic_visits: {
    fields: AcademicVisitsFields,
    label: "Academic Visits",
  },
  conference_seminar: {
    fields: ConferenceSeminarFields,
    label: "Conference / Seminar",
  },
  journal_publications: {
    fields: JournalPublicationFields,
    label: "Research Publications",
  },
  conference_seminars: {
    fields: ConferenceSeminarFields,
    label: "Conference / Seminars",
  },
  authored_books: {
    fields: AuthoredBookFields,
    label: "Authored Books",
  },
  book_chapters: {
    fields: BookChapterFields,
    label: "Book Chapters",
  },
  edited_books: {
    fields: BooksEditedFields,
    label: "Edited Books",
  },
  patents: {
    fields: PatentFields,
    label: "Patents",
  },
  designs: {
    fields: DesignFields,
    label: "Designs",
  },
  consultancy_external_services: {
    fields: ConsultancyExternalServicesFields,
    label: "Consultancy / External Services",
  },
  projects: {
    fields: ProjectFields,
    label: "Projects",
  },
  honors_awards: {
    fields: HonorsAwardsFields,
    label: "Honors and Awards",
  },
  lectures_talks: {
    fields: LecturesTalkFields,
    label: "Lecture/Talk",
  },
  corporate_lives: {
    fields: CorporateLifeFields,
    label: "Corporate Life",
  },
  collaborations: {
    fields: CollaborationFields,
    label: "Collaborations",
  },
  bodies_organizations: {
    fields: BodiesOrganizationFields,
    label: "Memberships in Professional Bodies/Organization",
  },
  fip_activities_participations: {
    fields: FIPActivitiesParticipationFields,
    label: "FIP Activities / Participation in FDP",
  },
  startup_developments: {
    fields: StartupDevelopmentFields,
    label: "Startup",
  },
  present_research_scholars: {
    fields: PresentResearchScholarFields,
    label: "Present Research Scholar",
  },
  phd_awardees: {
    fields: PhdAwardeeFields,
    label: "Ph. D. Awardee",
  },
  external_phd_scholars: {
    fields: ExternalPhdScholarsFields,
    label: "External Ph. D. Scholars",
  },
  thesis_examiner_universities: {
    fields: ThesisExaminerUniversitiesFields,
    label: "Thesis Examiner of other Universities",
  },
  programme_organizeds: {
    fields: ProgrammeOrganizedsFields,
    label: "Programme Organized",
  },
  e_content_developments: {
    fields: EContentDevelopmentsFields,
    label: "E-Content Development",
  },
  workshop_trainings: {
    fields: WorkshopTrainingsFields,
    label: "Participation in Workshop/Training Programme",
  },
};

const DetailPage = () => {
  const { id, type } = useParams();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await BaseApi().get(`/profile/${type}/${id}`);
        setDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [id, type]);

  if (!details) {
    return <p className="mt-4 text-center">Loading...</p>;
  }

  const detailConfig = detailFieldsConfig[type.replace(/-/g, "_")];
  const fields = detailConfig ? detailConfig.fields : [];
  const label = detailConfig ? detailConfig.label : "Details";

  return (
    <div className="container px-4 py-8 mx-auto">
      <h1 className="flex justify-center mb-10 text-2xl font-medium text-gray-700 uppercase">
        {label}
      </h1>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {fields.map((field, index) => (
          <div
            key={index}
            className="p-6 transition-shadow duration-200 bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-2xl"
          >
            <label className="block mb-2 font-semibold text-gray-700">
              {field.label}
            </label>
            <p className="text-base text-gray-900">
              {
                // Check if the value is a date and format it
                isDate(getValueByPath(details, field.path))
                  ? formatDate(getValueByPath(details, field.path))
                  : getValueByPath(details, field.path)
              }
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailPage;
