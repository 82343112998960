import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  title: {
    label: "Title of the Design",
    path: "title",
  },
  creators: {
    label: "Creators",
    path: "creators",
  },
  design_number: {
    label: "Design Number",
    path: "design_number",
  },
  registration_date: {
    label: "Registration Date",
    path: "registration_date",
  },
  country: {
    label: "Country",
    path: "country",
  },
};

const Design = () => {
  const [designs, setDesigns] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/designs");
      setDesigns(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Design
      </h2>
      <TableViewProfile
        headers={headers}
        data={designs}
        type="designs"
        setData={setDesigns}
      />
    </div>
  );
};

export default Design;
