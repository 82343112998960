import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import { getAcademicSessionsAsync,  getProgrammesAsync } from "../../Redux/MasterSlice";
 // import CustomSelect from "../../Components/Utils/CustomSelect";
 import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
import moment from "moment";
import BasicInput from "../../Components/Utils/BasicInput";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import CustomSelect from "../../Components/Utils/CustomSelect";
 import { getAdmittedStudentsAsync, updateAdmittedStudentsAsync } from "../../Redux/StudentSlice";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function PHDLastFees() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  // const authData = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  // const [errorMessage, setErrorMessage] = useState(false);
  let students = useSelector((state) => state.students.admittedStudents);
  const [searchInput, setSearchInput] = useState("");
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
 

  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;
  const initialValues = {
    programmetype_id:2,
    programme_id: null,
    phd_reg_date: 'true',
    phd_awarded_date: 'false',
    
  };
  const validationSchema = Yup.object().shape({
    // programme_id: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    dispatch({ type: 'students/clearAdmittedStudentsData' });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const handleSearch = async (searchQuery) => {
    setEdit(false);

    setLoading(true);
    console.log(searchQuery);
    await dispatch(getAdmittedStudentsAsync({ payload: searchQuery }))
      .unwrap()
      .then(() => {})
      .catch(() => {});
    setLoading(false);
  };



  const handleUpdate = async (payload) => {
    if ("phd_fee_paid_till" in payload) {
      await dispatch(
        updateAdmittedStudentsAsync({
          fields: {
            phd_fee_paid_till: payload.phd_fee_paid_till,
          },
          table: "programme_student",
          id: payload.programme_student_id,
        })
      );
    } 
 
    toast.success("Data Updated!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,

      progress: undefined,
    });
  };

  // const handleUpdate = async (payload) => {
  //   await BaseApi().post(`update-programme-student/${payload.id}`, payload);
  //   toast.success("Data Updated!", {
  //     position: "top-right",
  //     autoClose: 1000,
  //     hideProgressBar: true,
  //     closeOnClick: true,

  //     progress: undefined,
  //   });
  //   let _students = [...students];
  //   console.log(_students);
  //   if ("phd_reg_date" in payload) {
  //     _students.find((h) => h.id == payload.id).phd_reg_date = payload.phd_reg_date;
  //   }

  //   if ("bed_no" in payload) {
  //     _students.find((h) => h.id == payload.id).bed_no = payload.bed_no;
  //   }

  //   if ("in_date" in payload) {
  //     _students.find((h) => h.id == payload.id).in_date = payload.in_date;
  //   }

  //   if ("out_date" in payload) {
  //     _students.find((h) => h.id == payload.id).out_date = payload.out_date;
  //   }
   

 
  //   setLoading(false);
  // };
  
 
  return (
    <>
      <div className="py-2">
        <div className="mx-auto container bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5 overflow-x-scroll ">
            <PageTitle name="Ph. D. Scholar Last Montly Fees Paid Records" />

           
              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
              >
                <Form>
                  <div className=" p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-2">
                  <CustomSelect
                    options={programmes.filter((p)=> p.programmetype_id==2)}
                    name='programme_id'
                    label='Select Programme (Optional)'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption?.id
                      );
                     
                    }}
                  />
                    <div className="text-left p-5 mt-1  ">
                      <button
                        type="submit"
                        className="group relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={loading}
                      >
                        {loading && <span>Searching...</span>}
                        {!loading && <span>Search</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
          

           
            <Button
              classNames="float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className="flex">
                  <span className="m-auto">
                    <ThreeDots
                      height="100"
                      width="100"
                      color="grey"
                      ariaLabel="loading"
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}
            {students?.length > 0 && (
              <div>
                <div>
                  {edit  && (
                    <>
                      <Button
                        type="button"
                        classNames=" mr-2   bg-green-600 hover:bg-green-700 focus:ring-green-500"
                        value={"Done"}
                        icon={"CheckIcon"}
                        onClick={() => {
                          setEdit(false);
                        }}
                      />
                    </>
                  )}
                  {!edit  && (
                    <Button
                      type="button"
                      classNames="mr-2   bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                      value={"Edit"}
                      icon={"PencilIcon"}
                      onClick={() => {
                        setEdit(true);
                      }}
                    />
                  )}
                </div>
                <div>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label inline-block mt-5   text-gray-700"
                  >
                    Search By Name
                  </label>
                  <input
                    className="  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               "
                    placeholder="Type here..."
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </div>
            )}

            <table
              id="datatable"
              className="min-w-full mt-5 bg-white dark:bg-gray-800"
            >
              <thead>
                <tr>
                  <th className="table-head">#</th>

                  <th className="table-head">Name</th>

                  <th className="table-head">Enrollment ID</th>
                  <th className="table-head">Ph. D. Registration Date</th>

                  <th className="table-head">Monthly Fee Paid Till</th>
                
                  
                </tr>
              </thead>
              <tbody>
                {students?.length > 0 &&
                  students
                    .filter((s) => {
                      if (searchInput.length > 0) {
                        return (
                          s.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((student) => {
                      return (
                        <>
                          <tr
                            
                          >
                            <td className="table-data">{++sl}</td>

                            <td className="table-data">
                              {student.student?.name}
 
                                
                            </td>
                            <td
                          className='table-data cursor-pointer'
                          
                          onClick={() => {
                            window.open(`/admitted-students/${student.id}`);
                          }}
                        >
                          {student.unique_id || student.unique_idd}
                        </td>

                            <td className="table-data">

                              { student.phd_reg_date &&
                                moment(student.phd_reg_date).format("Do MMM YYYY")}
                            </td>
                            <td className="table-data">
                              {edit ? (
                                <BasicInput
                                  defaultValue={student.phd_fee_paid_till}
                                  type="month"
                                  onBlur={async (e) => {
                                    e.target.disabled = true;
                                    await handleUpdate({
                                      programme_student_id: student.id,

                                      phd_fee_paid_till: e.target.value,
                                    }).catch((e) => {
                                      alert("Something Went Wrong!");
                                      e.target.disabled = false;
                                    });
                                    e.target.disabled = false;
                                    console.log(e.target.value);
                                  }}
                                />
                              ) : (
                                student.phd_fee_paid_till &&
                                moment(student.phd_fee_paid_till).format("MMMM YYYY")
                              )}
                            </td>
                           
                            

                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}

           

            {students?.length == 0 && (
              <div className="text-center my-10">No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
