import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  title: { label: "Title of the Project", path: "title" },
  type: { label: "Project Type", path: "type" },
  role: { label: "Role", path: "role" },
  funding_agency: { label: "Funding Agency", path: "funding_agency" },
  grant_no: { label: "Grant No.", path: "grant_no" },
  amount_granted: { label: "Amount Granted", path: "amount_granted" },
  status: { label: "Status", path: "status" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
  project_related: { label: "Project related to", path: "project_related" },
  collaborative_project: {
    label: " Is it a collaborative project?",
    path: "collaborative_project",
  },
  collaborative_project_details: {
    label: "Details",
    path: "collaborative_project_details",
  },
  research_fellow: {
    label: "Number of Research Fellows under the Project",
    path: "research_fellow",
  },
};

const IqacProject = () => {
  const [projects, setProjects] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/projects?from_iqac=1");
      setProjects(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50'>
        <h1>Projects: </h1>
        <div className='flex items-end justify-center space-x-2 capitalize text-md'>
          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>From: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>To: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className=''>
            <Button
              type='filter'
              value='Filter'
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={projects}
        type='projects'
        setData={setProjects}
      />
    </div>
  );
};

export default IqacProject;
