import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../Components/Utils/CustomSelect";
// import Button from "../../Components/Utils/Button";
import BaseApi from "../../Http/BaseApi";
import {
  getAcademicSessionsAsync,
  getPaymentHeadsAsync,
} from "../../Redux/MasterSlice";
import messageSlice from "../../Redux/MessageSlice";
import * as Yup from "yup";
import Select from "../../Components/Utils/Select";
import Button from "../../Components/Utils/Button";
import Input from "../../Components/Utils/Input";
// import Input from "../../Components/Utils/Input";
import moment from "moment";
import { YearSemesters } from "../../Constants/YearSemesters";

export default function MakeAdmission({
  onMakeAdmission,
  applicant,
  setShowModal,
}) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const [programmes, setProgrammes] = useState([]);
  const [paymentGroupHeads, setPaymentGroupHeads] = useState([]);

  const [paymentGroups, setPaymentGroups] = useState([]);

  const master = useSelector((state) => state.master);
  const academicSessions = [...master.academicSessions];

  const paymentHeads = [...master.paymentHeads];
  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();

  const dispatch = useDispatch();
  let sl = 0;
  useEffect(() => {
    console.log("called again");
    dispatch(messageSlice.actions.clearMessage());

    async function getProgrammesWithPaymentGroups() {
      const _programmes = await BaseApi().get(`programmes-of-form`, {
        params: {
          form_id: applicant.form_id,
          applicant_id: applicant.id,
          subject_id:
            applicant.form_id == 71
              ? applicant?.subject_takens[0]?.subject_id
              : applicant.exam_subject_id,
        },
      });

      setProgrammes(_programmes.data);

      setInitialValues({
        applicant_id: applicant.id,
        programme_id: "",
        payment_group_id: "",
        semester: "",
        type: "",
        challan_no: "",
        pay_date: moment(new Date()).format("YYYY-MM-DD"),
        // pay_date: "2022-02-02",
        details: [],
      });
    }
    getProgrammesWithPaymentGroups();

    if (paymentHeads.length === 0) {
      dispatch(getPaymentHeadsAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  useEffect(() => {
    console.log(paymentGroupHeads);
    if (paymentGroupHeads.length > 0) {
      paymentGroupHeads.map((head) => {
        let copy = { ...initialValues };
        let details = copy.details;
        details.push({
          checked: true,
          payment_head_id: head.payment_head_id,
          amount: head.amount,
        });
        setInitialValues({
          applicant_id: initialValues.applicant_id,
          programme_id: initialValues.programme_id,
          payment_group_id: initialValues.payment_group_id,
          semester: initialValues.semester,
          type: initialValues.type,
          challan_no: initialValues.challan_no,
          pay_date: initialValues.pay_date,
          details,
        });
      });
    }

    // dispatch(getOldStudentsAsync());
  }, [paymentGroupHeads]);

  const validationSchema = Yup.object().shape({
    programme_id: Yup.string().required("This field is required!"),
    payment_group_id: Yup.string().required("This field is required!"),
    semester: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
    pay_date: Yup.string().required("This field is required!"),

    challan_no: Yup.string().when("type", {
      is: (type) =>
        type != "Online" && type != "Fee Waived" && type != "Scholarship",
      then: Yup.string().required("This field is required!"),
    }),
  });
  const handleSubmit = async (payload) => {
    setLoading(true);
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);

    await BaseApi().post(`make-admission`, payload);
    setLoading(false);

    setInitialValues({
      applicant_id: applicant.id,
      programme_id: "",
      payment_group_id: "",
      semester: "",
      type: "",
      challan_no: "",
      pay_date: moment(new Date()).format("YYYY-MM-DD"),
      details: [],
    });
    onMakeAdmission();
    setShowModal(false);
  };

  const handlePaymentHeadInputChange = (event) => {
    console.log(initialValues);
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      let copy = { ...initialValues };
      let details = copy.details;

      details.find((e) => e.payment_head_id == value).checked = true;
      console.log(details);
      setInitialValues({
        applicant_id: initialValues.applicant_id,
        programme_id: initialValues.programme_id,
        payment_group_id: initialValues.payment_group_id,
        semester: initialValues.semester,
        type: initialValues.type,
        challan_no: initialValues.challan_no,
        pay_date: initialValues.pay_date,
        details: details,
      });
    } else {
      let copy = { ...initialValues };
      let details = copy.details;

      details.find((e) => e.payment_head_id == value).checked = false;
      console.log(details);
      setInitialValues({
        applicant_id: initialValues.applicant_id,
        programme_id: initialValues.programme_id,
        payment_group_id: initialValues.payment_group_id,
        semester: initialValues.semester,
        type: initialValues.type,
        challan_no: initialValues.challan_no,
        pay_date: initialValues.pay_date,
        details: details,
      });
    }
  };

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  console.log(message);
  return (
    <>
      <div className='fixed inset-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative w-auto max-w-3xl mx-auto my-6'>
          {/*content*/}

          <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
              <h3 className='text-3xl font-semibold'>
                {applicant.student?.name} - Session:{" "}
                {
                  academicSessions?.find(
                    (e) => e.id == applicant.academic_session_id
                  )?.label
                }
              </h3>
            </div>
            {/*body*/}
            <p style={{ width: "700px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative flex-auto p-6'>
                    <div className='grid grid-cols-2 gap-4'>
                      <div className=''>
                        {programmes?.length > 0 && (
                          <CustomSelect
                            isClearable={true}
                            options={programmes}
                            name='programme_id'
                            label='Select Programme'
                            isSearchable={true}
                            getOptionValue={(option) => `${option}`}
                            getOptionLabel={(option) => `${option.name} `}
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "programme_id",
                                selectedOption.id
                              );
                              if (
                                initialValues.programme_id != selectedOption.id
                              ) {
                                setInitialValues({
                                  applicant_id: initialValues.applicant_id,
                                  programme_id: selectedOption.id,
                                  payment_group_id: "",
                                  semester: "",
                                  type: initialValues.type,
                                  challan_no: initialValues.challan_no,
                                  pay_date: initialValues.pay_date,

                                  details: [],
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                      <div>
                        <CustomSelect
                          isClearable={true}
                          options={YearSemesters}
                          name='semester'
                          label='Select Semester'
                          getOptionValue={(option) => `${option}`}
                          getOptionLabel={(option) => `${option.label} `}
                          onChange={(selectedOption) => {
                            console.log(selectedOption);
                            formikRef.current?.setFieldValue(
                              "semester",
                              selectedOption.value
                            );
                            if (
                              initialValues.semester != selectedOption.value
                            ) {
                              console.log(initialValues.semester);
                              console.log(selectedOption.value);
                              setInitialValues({
                                applicant_id: initialValues.applicant_id,
                                programme_id: initialValues.programme_id,
                                semester: selectedOption.value,
                                payment_group_id: "",
                                type: initialValues.type,
                                challan_no: initialValues.challan_no,
                                pay_date: initialValues.pay_date,

                                details: [],
                              });

                              setPaymentGroups(
                                programmes
                                  .find(
                                    (e) => e.id == initialValues.programme_id
                                  )
                                  .payment_groups.filter((pg) => {
                                    return (
                                      pg.pivot?.semester == selectedOption.value
                                    );
                                  })
                              );
                            } else {
                              setPaymentGroups(
                                programmes
                                  .find(
                                    (e) => e.id == initialValues.programme_id
                                  )
                                  .payment_groups.filter((pg) => {
                                    return (
                                      pg.pivot?.semester == selectedOption.value
                                    );
                                  })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className=''>
                        {paymentGroups?.length > 0 && (
                          <CustomSelect
                            isClearable={true}
                            options={paymentGroups}
                            name='payment_group_id'
                            label='Select Payment Receipt'
                            isSearchable={true}
                            getOptionValue={(option) => `${option}`}
                            getOptionLabel={(option) => `${option.name} `}
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "payment_group_id",
                                selectedOption.id
                              );

                              if (
                                initialValues.payment_group_id !=
                                selectedOption.id
                              ) {
                                setInitialValues({
                                  applicant_id: initialValues.applicant_id,
                                  programme_id: initialValues.programme_id,
                                  payment_group_id: selectedOption.id,
                                  semester: initialValues.semester,
                                  type: initialValues.type,
                                  challan_no: initialValues.challan_no,
                                  pay_date: initialValues.pay_date,

                                  details: [],
                                });
                                setPaymentGroupHeads(
                                  paymentGroups.find(
                                    (e) => e.id == selectedOption.id
                                  )?.payment_group_heads
                                );
                              } else {
                                setPaymentGroupHeads(
                                  paymentGroups.find(
                                    (e) => e.id == selectedOption.id
                                  )?.payment_group_heads
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <table className='min-w-full mt-10 bg-white '>
                      <thead>
                        <tr>
                          <th className={thClass}>Select</th>
                          <th className={thClass}>#</th>
                          <th className={thClass}>Head</th>
                          <th className={thClass}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {initialValues?.details.map((head) => {
                          console.log("abc");
                          return (
                            <>
                              <tr className={head.checked ? "" : "bg-gray-100"}>
                                <td className={tdClass}>
                                  <input
                                    className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                    type='checkbox'
                                    checked={head.checked}
                                    value={head.payment_head_id}
                                    onChange={(event) =>
                                      handlePaymentHeadInputChange(event)
                                    }
                                    id={"head-id" + head.payment_head_id}
                                  />
                                </td>
                                <td className={tdClass}>{++sl}</td>
                                <td className={tdClass}>
                                  {
                                    paymentHeads.find(
                                      (e) => e.id == head.payment_head_id
                                    )?.name
                                  }
                                </td>
                                <td className={tdClass}>{head.amount}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th className={thClass}></th>
                          <th className={thClass}></th>
                          <th className={thClass}>Total</th>
                          <th className={thClass}>
                            {initialValues?.details
                              ?.filter((e) => e.checked == true)
                              .reduce(
                                (partialSum, detail) =>
                                  partialSum + (parseInt(detail.amount) || 0),
                                0
                              )}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                    <p className='my-4 text-lg leading-relaxed text-slate-500'></p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200'>
                    <button
                      className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <Select label='Select Payment Type' name='type'>
                      <option selected value={""}>
                        Select
                      </option>
                      <option value={"Online"}>Online(Billdesk)</option>
                      <option value={"SBI Collect"}>SBI Collect</option>
                      <option value={"POS"}>POS</option>
                      <option value={"PNB Counter Deposit"}>
                        PNB Counter Deposit
                      </option>
                      <option value={"Education Loan"}>Education Loan</option>
                      <option value={"Fee Waived"}>Fee Waived</option>
                      <option value={"Scholarship"}>Scholarship</option>
                    </Select>
                    <Input label='Payment Date' type='date' name='pay_date' />
                    <Input label='Ref. No.' name='challan_no' />

                    <Button
                      disabled={loading}
                      value={loading ? "Saving..." : "Submit"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
    </>
  );
}
