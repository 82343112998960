import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";

// import Input from "../../Components/Utils/Input";
import moment from "moment";
import messageSlice from "../../../Redux/MessageSlice";
import { getPaymentHeadsAsync } from "../../../Redux/MasterSlice";
import BaseApi from "../../../Http/BaseApi";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { YearSemesters } from "../../../Constants/YearSemesters";
// import Select from "../../../Components/Utils/Select";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

export default function MakeFeeCollection({
  onMakeFeeCollection,
  selectedIds,
  studentFilter,
  setShowModal,
}) {
  console.log(studentFilter);
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

  const [paymentGroupHeads, setPaymentGroupHeads] = useState([]);
  const [paymentGroups, setPaymentGroups] = useState();

  const master = useSelector((state) => state.master);
  const paymentHeads = [...master.paymentHeads];
  const [initialValues, setInitialValues] = useState();
  // const [paymentType, setPaymentType] = useState();
  const formikRef = useRef();
  const authData = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  let sl = 0;
  useEffect(() => {
    console.log("called again");
    dispatch(messageSlice.actions.clearMessage());

    if (paymentHeads.length === 0) {
      dispatch(getPaymentHeadsAsync());
    }

    setInitialValues({
      selectedIds: selectedIds,

      payment_group_id: "",
      semester: "",
      type: "",
      challan_no: "",
      part_payment: false,
      paid_amount: "",
      last_date: "",
      last_date_fine: "",
      // late_fine: false,
      pay_date: moment(new Date()).format("YYYY-MM-DD"),
      // pay_date: "2022-02-02",
      details: [],
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  useEffect(() => {
    console.log(paymentGroupHeads);
    if (paymentGroupHeads.length > 0) {
      paymentGroupHeads.map((head) => {
        let copy = { ...initialValues };
        let details = copy.details;
        details.push({
          checked: true,
          payment_head_id: head.payment_head_id,
          amount: head.amount,
        });
        setInitialValues({
          selectedIds: initialValues.selectedIds,

          payment_group_id: initialValues.payment_group_id,
          semester: initialValues.semester,
          type: initialValues.type,
          challan_no: initialValues.challan_no,
          part_payment: initialValues.false,
          paid_amount: initialValues.paid_amount,

          pay_date: initialValues.pay_date,
          last_date: initialValues.last_date,
          last_date_fine: initialValues.last_date_fine,
          // late_fine: initialValues.late_fine,
          details,
        });
      });
    }

    // dispatch(getOldStudentsAsync());
  }, [paymentGroupHeads]);

  const validationSchema = Yup.object().shape({
    // selectedIds: Yup.string().required("This field is required!"),
    payment_group_id: Yup.string().required("This field is required!"),
    semester: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),

    pay_date: Yup.string().when("type", {
      is: (type) => type != "Online",
      then: Yup.string().required("This field is required!"),
    }),

    challan_no: Yup.string().when("type", {
      is: (type) => type != "Online" && type !== "Fee Waived",
      then: Yup.string().required("This field is required!"),
    }),

    paid_amount: Yup.string().when("part_payment", {
      is: (type) => type == true,
      then: Yup.string().required("This field is required!"),
    }),

    last_date: Yup.string().when("type", {
      is: (type) => type == "Online",
      then: Yup.string().required("This field is required!"),
    }),

    last_date_fine: Yup.string().when("type", {
      is: (type) => type == "Online",
      then: Yup.string().required("This field is required!"),
    }),
  });

  const callPaymentGroups = async (searchQuery) => {
    const _paymentGroups = await BaseApi().get("payment-groups", {
      params: searchQuery,
    });

    setPaymentGroups(_paymentGroups.data);
  };

  const handleSubmit = async (payload) => {
    if (payload.payment_group_id == 144) {
      if (
        window.confirm(
          `Fee Waiver Criteria: \n  1. Students whose parental annual income is less than Rs. 2.00 lakh from all sources will be eligible to avail this scheme. \n2. If either of the parent (mother or father) of the student is working in the State Govt./ Central Govt./Semi Govt./State or Central Govt. undertakings, such students shall not be eligible under this scheme. \n3. Scheme is only for regular students having minimum 75% of attendance in classes with no back paper in previous examination. \n4. Proof of Income/Documents required: An Income Certificate from the Local Revenue Circle Officer only.`
        ) == false
      ) {
        return;
      }
    }

    setLoading(true);
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);

    await BaseApi().post(`make-fee-collection`, payload);
    setLoading(false);

    setInitialValues({
      selectedIds: selectedIds,
      programme_id: "",
      payment_group_id: "",
      semester: "",
      type: "",
      challan_no: "",
      part_payment: false,
      paid_amount: "",
      pay_date: moment(new Date()).format("YYYY-MM-DD"),
      last_date: "",
      last_date_fine: "",
      // late_fine: false,
      details: [],
    });
    onMakeFeeCollection();
    setShowModal(false);
  };

  // const handlePaymentHeadInputChange = (event) => {
  //   console.log(initialValues);
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     let copy = { ...initialValues };
  //     let details = copy.details;

  //     details.find((e) => e.payment_head_id == value).checked = true;
  //     console.log(details);
  //     setInitialValues({
  //       selectedIds: initialValues.selectedIds,

  //       payment_group_id: initialValues.payment_group_id,
  //       semester: initialValues.semester,
  //       type: initialValues.type,
  //       challan_no: initialValues.challan_no,
  //       pay_date: initialValues.pay_date,
  //      last_date: initialValues.last_date,
  //       last_date_fine: initialValues.last_date_fine,
  //       details: details,
  //     });
  //   } else {
  //     let copy = { ...initialValues };
  //     let details = copy.details;

  //     details.find((e) => e.payment_head_id == value).checked = false;
  //     console.log(details);
  //     setInitialValues({
  //       selectedIds: initialValues.selectedIds,

  //       payment_group_id: initialValues.payment_group_id,
  //       semester: initialValues.semester,
  //       type: initialValues.type,
  //       challan_no: initialValues.challan_no,
  //       pay_date: initialValues.pay_date,
  //     last_date: initialValues.last_date,
  //      last_date_fine: initialValues.last_date_fine,
  //       details: details,
  //     });
  //   }
  // };

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  console.log(message);
  return (
    <>
      <div className='fixed inset-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative max-w-4xl mx-auto my-6 w-800'>
          {/*content*/}

          <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
              <h3 className='text-3xl font-semibold'>
                Student Selected: {selectedIds.length}
              </h3>
            </div>
            {/*body*/}
            <p style={{ width: "800px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative flex-auto p-6'>
                    <div className='grid grid-cols-2 gap-4'>
                      <div>
                        <CustomSelect
                          isClearable={true}
                          options={YearSemesters.filter((e) => {
                            if (authData.panel == "staff") {
                              return e.value != 1;
                            } else {
                              return e.value == studentFilter.semester;
                            }
                          })}
                          name='semester'
                          label='Select Semester (will be shown in receipt)'
                          getOptionValue={(option) => `${option}`}
                          getOptionLabel={(option) => `${option.label} `}
                          onChange={(selectedOption) => {
                            console.log(selectedOption);
                            formikRef.current?.setFieldValue(
                              "semester",
                              selectedOption.value
                            );
                            if (
                              initialValues.semester != selectedOption.value
                            ) {
                              console.log(initialValues.semester);
                              console.log(selectedOption.value);
                              setInitialValues({
                                selectedIds: initialValues.selectedIds,

                                semester: selectedOption.value,
                                payment_group_id: "",
                                type: initialValues.type,
                                challan_no: initialValues.challan_no,
                                part_payment: initialValues.part_payment,
                                paid_amount: initialValues.paid_amount,
                                pay_date: initialValues.pay_date,
                                last_date: initialValues.last_date,
                                last_date_fine: initialValues.last_date_fine,
                                // late_fine: initialValues.late_fine,
                                details: [],
                              });
                              callPaymentGroups({
                                academic_session_id:
                                  studentFilter.academic_session_id,
                                programme_id: studentFilter.programme_id,
                                semester: selectedOption.value,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className=''>
                        {paymentGroups?.length > 0 && (
                          <CustomSelect
                            isClearable={true}
                            options={paymentGroups}
                            name='payment_group_id'
                            label='Select Payment Receipt'
                            isSearchable={true}
                            getOptionValue={(option) => `${option}`}
                            getOptionLabel={(option) => `${option.name} `}
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "payment_group_id",
                                selectedOption.id
                              );

                              if (
                                initialValues.payment_group_id !=
                                selectedOption.id
                              ) {
                                setInitialValues({
                                  selectedIds: initialValues.selectedIds,

                                  payment_group_id: selectedOption.id,
                                  semester: initialValues.semester,
                                  type: initialValues.type,
                                  challan_no: initialValues.challan_no,
                                  part_payment: initialValues.part_payment,
                                  paid_amount: initialValues.paid_amount,
                                  pay_date: initialValues.pay_date,
                                  last_date: initialValues.last_date,
                                  last_date_fine: initialValues.last_date_fine,
                                  // late_fine: initialValues.late_fine,
                                  details: [],
                                });
                                setPaymentGroupHeads(
                                  paymentGroups.find(
                                    (e) => e.id == selectedOption.id
                                  )?.payment_group_heads
                                );
                              } else {
                                setPaymentGroupHeads(
                                  paymentGroups.find(
                                    (e) => e.id == selectedOption.id
                                  )?.payment_group_heads
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <table className='min-w-full mt-10 bg-white '>
                      <thead>
                        <tr>
                          {/* <th className={thClass}>Select</th> */}
                          <th className={thClass}>#</th>
                          <th className={thClass}>Head</th>
                          <th className={thClass}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {initialValues?.details.map((head) => {
                          console.log("abc");
                          return (
                            <>
                              <tr className={head.checked ? "" : "bg-gray-100"}>
                                {/* <td className={tdClass}>
                                  <input
                                    className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                    type="checkbox"
                                    checked={head.checked}
                                    value={head.payment_head_id}
                                    onChange={(event) =>
                                      handlePaymentHeadInputChange(event)
                                    }
                                    id={"head-id" + head.payment_head_id}
                                  />
                                </td> */}
                                <td className={tdClass}>{++sl}</td>
                                <td className={tdClass}>
                                  {
                                    paymentHeads.find(
                                      (e) => e.id == head.payment_head_id
                                    )?.name
                                  }
                                </td>
                                <td className={tdClass}>{head.amount}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th className={thClass}></th>
                          <th className={thClass}>Total</th>
                          <th className={thClass}>
                            {initialValues?.details
                              ?.filter((e) => e.checked == true)
                              .reduce(
                                (partialSum, detail) =>
                                  partialSum + (parseInt(detail.amount) || 0),
                                0
                              )}
                          </th>
                        </tr>
                        {/* {authData.panel == "staff"  && (
                          <tr>
                            <th className={thClass}></th>
                            <th className={thClass}>
                              <input
                                className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                type="checkbox"
                                checked={initialValues?.late_fine}
                                value={initialValues?.late_fine}
                                onChange={(event) => {
                                  console.log(event.target.checked);

                                  setInitialValues({
                                    selectedIds: initialValues.selectedIds,

                                    payment_group_id:
                                      initialValues.payment_group_id,
                                    semester: initialValues.semester,
                                    type: initialValues.type,
                                    challan_no: initialValues.challan_no,
                                    part_payment: initialValues.part_payment,
                                    paid_amount: initialValues.paid_amount,
                                    pay_date: initialValues.pay_date,
                                    last_date: initialValues.last_date,
                                    last_date_fine:
                                      initialValues.last_date_fine,
                                    late_fine: event.target.checked,
                                    details: initialValues.details,
                                  });
                                }}
                                id="part_payment"
                              />
                              <label htmlFor="part_payment">
                                Add Late Fine
                              </label>
                            </th>
                            <th className={thClass}>
                              {initialValues?.late_fine == true && (
                                <>
                                  {" "}
                                  {initialValues?.details
                                    ?.filter((e) => e.checked == true)
                                    .reduce(
                                      (partialSum, detail) =>
                                        partialSum +
                                        (parseInt(detail.amount) || 0),
                                      0
                                    )}{" "}
                                  +200 ={" "}
                                  {initialValues?.details
                                    ?.filter((e) => e.checked == true)
                                    .reduce(
                                      (partialSum, detail) =>
                                        partialSum +
                                        (parseInt(detail.amount) || 0),
                                      0
                                    ) + 200}{" "}
                                </>
                              )}
                            </th>
                          </tr>
                        )} */}
                        {authData.panel == "staff" && (
                          <tr>
                            <th className={thClass}></th>
                            <th className={thClass}>
                              <input
                                className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                                type='checkbox'
                                checked={initialValues?.part_payment}
                                value={initialValues?.part_payment}
                                onChange={(event) => {
                                  console.log(event.target.checked);
                                  setInitialValues({
                                    selectedIds: initialValues.selectedIds,

                                    payment_group_id:
                                      initialValues.payment_group_id,
                                    semester: initialValues.semester,
                                    type: initialValues.type,
                                    challan_no: initialValues.challan_no,
                                    part_payment: event.target.checked,
                                    paid_amount: initialValues.paid_amount,
                                    pay_date: initialValues.pay_date,
                                    last_date: initialValues.last_date,
                                    last_date_fine:
                                      initialValues.last_date_fine,
                                    // late_fine: initialValues.late_fine,
                                    details: initialValues.details,
                                  });
                                }}
                                id='part_payment'
                              />
                              <label htmlFor='part_payment'>
                                Enable Part Payment
                              </label>
                            </th>
                            <th className={thClass}>
                              {authData.panel == "staff" &&
                                initialValues?.part_payment == true && (
                                  <Input
                                    type='number'
                                    label='Paid Amount'
                                    name='paid_amount'
                                  />
                                )}
                            </th>
                          </tr>
                        )}
                      </tfoot>
                    </table>

                    <p className='my-4 text-lg leading-relaxed text-slate-500'></p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200'>
                    <button
                      className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <CustomSelect
                      className='mt-2'
                      isClearable={true}
                      options={
                        authData.panel == "staff"
                          ? [
                              { label: "Online(Billdesk)", value: "Online" },
                              { label: "SBI Collect", value: "SBI Collect" },
                              { label: "POS", value: "POS" },
                              {
                                label: "PNB Counter Deposit",
                                value: "PNB Counter Deposit",
                              },
                              {
                                label: "Education Loan",
                                value: "Education Loan",
                              },
                              { label: "Other", value: "Other" },
                              {
                                label: "Fee Waived",
                                value: "Fee Waived",
                              },
                            ]
                          : [
                              { label: "Online(Billdesk)", value: "Online" },
                              {
                                label: "Fee Waived",
                                value: "Fee Waived",
                              },
                            ]
                      }
                      name='type'
                      label='Payment Type'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.label} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "type",
                          selectedOption.value
                        );

                        setInitialValues({
                          selectedIds: initialValues.selectedIds,

                          payment_group_id: initialValues.payment_group_id,
                          semester: initialValues.semester,
                          type: selectedOption.value,
                          challan_no: initialValues.challan_no,
                          part_payment: initialValues.part_payment,
                          paid_amount: initialValues.paid_amount,
                          pay_date: initialValues.pay_date,
                          last_date: initialValues.last_date,
                          last_date_fine: initialValues.last_date_fine,
                          // late_fine: initialValues.late_fine,
                          details: initialValues.details,
                        });
                      }}
                    />

                    {initialValues?.type != "" &&
                      initialValues?.type != "Online" && (
                        <Input
                          label='Payment Date'
                          type='date'
                          name='pay_date'
                        />
                      )}
                    {initialValues?.type == "Online" && (
                      <>
                        <Input
                          label='Last Date'
                          id='last_date'
                          type='date'
                          name='last_date'
                        />
                        <Input
                          label='Last Date (With Late Fine)'
                          type='date'
                          name='last_date_fine'
                          id='last_date_fine'
                        />
                      </>
                    )}
                    {initialValues?.type != "" &&
                      initialValues?.type != "Online" && (
                        <Input label='Ref. No.' name='challan_no' />
                      )}

                    <Button
                      disabled={loading}
                      value={loading ? "Saving..." : "Submit"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
    </>
  );
}
