"use client";
import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Input from "../../Components/Utils/Input";
import Upload from "../../Components/Utils/Upload";
import Button from "../../Components/Utils/Button";
import BaseApi from "../../Http/BaseApi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdArrowBack } from "react-icons/md";
import { FaTimes } from "react-icons/fa";

const FileCreate = () => {
  const [file, setFile] = useState(null);
  const formikRef = useRef();
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const parent_id = queryParams.get("parent_id"); // Extract parent_notice_id
  const type = queryParams.get("type");
  const [parentFile, setParentFile] = useState(null); // For displaying parent notice title
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (departments.length == 0) {
      getDepartments();
    }

    if (parent_id) {
      getParentFileDetails(parent_id); // Fetch parent notice details
    } else {
      setParentFile(null);
    }
    setInitialValues({
      department_id: "",
      title: "",
      description: "",
      reference_no: "",

      file: null,
      parent_id: parent_id ?? "",
      type: type ?? "Fresh",
    });
  }, []);

  const getParentFileDetails = async (id) => {
    await BaseApi()
      .get(`ft-files/${id}`)
      .then((response) => {
        setParentFile(response.data); // Set the parent notice title
        formikRef.current?.setFieldValue(
          "department_id",
          response.data.department_id
        );
      });
  };

  const getDepartments = async () => {
    try {
      const response = await BaseApi().get("/ft-departments?own=1");

      setDepartments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = Yup.object({
    department_id: Yup.string().required("Department is required"),
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    reference_no: Yup.string().required("Reference number is required"),

    // file: Yup.mixed()
    //   .nullable()
    //   .test("fileSize", "File size is too large", (value) =>
    //     value ? value.size <= 1024 * 1024 * 5 : true
    //   ),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    // confirm alert return false if user clicks cancel
    if (!window.confirm("Are you sure you want to create this file?")) {
      return;
    }

    setLoading(true);
    var form_data = new FormData();
    for (var key in payload) {
      form_data.append(
        key,

        payload[key]
      );
    }

    try {
      const response = await BaseApi().post("/ft-files", form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File created:", response.data);
      resetForm();
      setLoading(false);

      navigate(`/list-of-files`);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,

        progress: undefined,
      });
      setLoading(false);
    }
  };

  return (
    <div className='flex items-center justify-center bg-white '>
      <div className='w-full p-5'>
        <Link to='/file-tracking' className='text-sm text-blue-600 '>
          <MdArrowBack className='inline' /> File Tracking Dashboard
        </Link>

        <h2 className='flex justify-between mt-5 mb-6 text-2xl font-semibold text-gray-800'>
          Create New File
          <Button
            onClick={() => {
              // Navigate to the create
              navigate(`/list-of-files`);
            }}
            classNames='  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
            value={"List of Files"}
            icon={"ViewListIcon"}
          />
        </h2>

        {parentFile && (
          <div className='mb-4 text-lg text-gray-700'>
            Adding Part File to :{" "}
            <strong>
              {parentFile.title} ({parentFile.file_no}){" "}
            </strong>
            <a href={`/file-create`} className='ml-4'>
              <FaTimes className='inline text-red-500 border border-red-500 text-md' />
            </a>
          </div>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formikRef}
          enableReinitialize={true}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form className='space-y-4'>
              {parentFile && (
                <div className='mb-10'>
                  Department: {parentFile.department?.name}{" "}
                </div>
              )}
              {!parentFile && (
                <div className='grid grid-cols-2 gap-4'>
                  <CustomSelect
                    defaultInputValue={initialValues.department_id}
                    options={departments}
                    name='department_id'
                    label={
                      <div className='flex items-center'>
                        Department/Branch/Office
                        <span className='ml-1 text-red-600'>*</span>
                      </div>
                    }
                    isSearchable={true}
                    getOptionValue={(option) => `${option.id}`}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={async (selectedOption) => {
                      console.log("Selected option:", selectedOption);
                      formikRef.current?.setFieldValue(
                        "department_id",
                        selectedOption?.id
                      );
                    }}
                  />
                </div>
              )}

              {/* Title of the File */}
              <div className='grid grid-cols-2 gap-4'>
                <Input
                  type='text'
                  name='title'
                  placeholder='Enter Title of the File'
                  label={
                    <div className='flex items-center'>
                      Title of the File
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />

                {/* Reference No. */}
                <Input
                  type='text'
                  name='reference_no'
                  placeholder='Enter Reference No.'
                  label={
                    <div className='flex items-center'>
                      Manual Reference No.
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />

                {/* Description */}
                <Input
                  type='textarea'
                  placeholder='Enter Description'
                  label={
                    <div className='flex items-center'>
                      Description
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                  name='description'
                />

                {/* Upload File (Optional) */}
              </div>

              <Upload
                name='image'
                label='Upload File (PDF, Image)'
                placeholder='Image'
                accept='.pdf, .jpg, .jpeg, .png'
                onChange={(event) => {
                  console.log("asd");
                  let myFile = event.currentTarget.files[0];
                  formikRef.current.setFieldValue("file", myFile);
                }}
                // onChange={(event) => {
                //   let myFile = event.currentTarget.files[0];
                //   console.log(myFile);
                //   formikRef.current.setFieldValue("image", myFile);
                // }}
              />

              {/* Submit Button */}
              <div className='flex justify-center'>
                <Button
                  classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                  value={"Submit"}
                  icon={"SaveIcon"}
                />
              </div>

              <div className='flex justify-between col-span-2 mt-10'></div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FileCreate;
