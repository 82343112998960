import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  collaboration_name: {
    label: "Name of the Institute/ Organization/Individual",
    path: "collaboration_name",
  },
  collaboration_type: {
    label: "Type of Collaboration",
    path: "collaboration_type",
  },
  other_collaboration: {
    label: "Other Collaboration",
    path: "other_collaboration",
  },
  from_date: { label: "From Date Collaboration", path: "from_date" },
  to_date: { label: "To Date Collaboration", path: "to_date" },
  collaboration_objective: {
    label: "Objective of the Collaboration",
    path: "collaboration_objective",
  },
  collaboration_details: {
    label: "Level of the collaboration",
    path: "collaboration_details",
  },
};

const Collaboration = () => {
  const [collaborations, setCollaborations] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/collaborations");
      setCollaborations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Collaboration
      </h2>
      <TableViewProfile
        headers={headers}
        data={collaborations}
        type="collaborations"
        setData={setCollaborations}
      />
    </div>
  );
};

export default Collaboration;
