import React, { useRef, useState, useEffect } from "react";
import Input from "../../Components/Utils/Input";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../Components/Utils/Button";
import { useParams } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";
import { toast } from "react-toastify";

const FileMovement = () => {
  const { id } = useParams();
  const [fileDetails, setFileDetails] = useState({ movements: [] });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await BaseApi().get(`/ft-files/${id}`);

        setFileDetails(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };

    getData();
  }, [id]);

  const formikRef = useRef();
  const validationSchema = Yup.object({});

  const initialValues = {
    department: "",
    title: "",
    description: "",
    referenceNo: "",
    receivedDepartment: "",
    file: null,
    movementDate: "", // Add movementDate to the initial values
  };

  // Set the current date as the initial date
  const [movementDate, setMovementDate] = useState("");

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMovementDate(today); // Set today's date as the initial value
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    console.log("Form values:", values);
  };

  const departmentTypes = [
    { id: 1, name: "HR" },
    { id: 2, name: "Finance" },
    { id: 3, name: "Operations" },
  ];

  return (
    <div className='flex items-center justify-center bg-gray-100 '>
      <div className='w-full p-8 '>
        <h2 className='mb-6 text-2xl font-semibold text-gray-800'>
          File Movement
        </h2>

        <form className='space-y-6'>
          {/* Title (Readonly Input) */}
          <div className='flex mb-2 text-base font-medium text-gray-700'>
            <p className='mr-2'>Title: </p>
            <span>Title Name</span>
          </div>

          {/* From Department/Branch (Readonly Dropdown) */}
          <div className='flex mb-2 text-base font-medium text-gray-700'>
            <p className='mr-2'> From Department/Branch :</p>
            <span>HR</span>
          </div>

          {/* Movement Date (Date Picker) */}
          <div className='flex mb-2 text-base font-medium text-gray-700'>
            <p className='mr-2'>Movement Date:</p>
            <input
              type='date'
              name='movementDate'
              value={movementDate}
              onChange={(e) => setMovementDate(e.target.value)}
              className='px-2 py-1 border border-gray-300 rounded'
            />
          </div>

          <Formik
            initialValues={{ ...initialValues, movementDate }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
            enableReinitialize={true}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form className='space-y-4'>
                {/* To Department/Branch (Dropdown) */}
                <div className='grid grid-cols-2 gap-4'>
                  <CustomSelect
                    defaultInputValue={initialValues.department}
                    options={departmentTypes}
                    name='department'
                    label={
                      <div className='flex items-center'>
                        To Department/Branch
                        <span className='ml-1 text-red-600'>*</span>
                      </div>
                    }
                    isSearchable={true}
                    getOptionValue={(option) => `${option.id}`}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={async (selectedOption) => {
                      formikRef.current?.setFieldValue(
                        "department",
                        selectedOption.name
                      );
                    }}
                  />

                  <Input
                    type='textarea'
                    name='title'
                    placeholder='Enter Remarks'
                    label={
                      <div className='flex items-center'>
                        Remarks
                        <span className='ml-1 text-red-600'>*</span>
                      </div>
                    }
                  />
                </div>

                {/* Submit Button */}

                <div className='flex justify-end'>
                  <Button
                    classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                    value={"Submit Movement"}
                    icon={"ChevronDoubleRightIcon"}
                  />
                </div>

                <div className='flex justify-between col-span-2 mt-10'></div>
              </Form>
            )}
          </Formik>
        </form>
      </div>
    </div>
  );
};

export default FileMovement;
