export const OLD_STUDENTS_STATUS = {
  0: "Applied",
  1: "Accepted",
  2: "Rejected",
  3: "Cancelled",
  4: "Reuploaded",
};

export const ADMITTED_STUDENTS_STATUS = {
  0: "Applied",
  1: "Admitted",
};
