import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";

// import Input from "../../Components/Utils/Input";
import moment from "moment";
import messageSlice from "../../../Redux/MessageSlice";
import BaseApi from "../../../Http/BaseApi";
import CustomSelect from "../../../Components/Utils/CustomSelect";
// import Select from "../../../Components/Utils/Select";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

export default function PendingDetails({
  selectedPayment,
  onMakeFeeCollection,
  setShowModal,
}) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(false);

  const [initialValues, setInitialValues] = useState();
  // const [paymentType, setPaymentType] = useState();
  const formikRef = useRef();
  const authData = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("called again");
    dispatch(messageSlice.actions.clearMessage());

    checkForLateFine();

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const checkForLateFine = async () => {
    const response = await BaseApi().get("check-late-fine", {
      params: { id: selectedPayment.id },
    });
    setPayment(response.data);
    setInitialValues({
      payment_id: response.data.id,
      type: "",
      challan_no: "",
      part_payment: false,
      paid_amount: "",
      late_fine: response.data.late_fine,
      pay_date: moment(new Date()).format("YYYY-MM-DD"),
      // pay_date: "2022-02-02",
    });
  };
  const validationSchema = Yup.object().shape({
    // selectedIds: Yup.string().required("This field is required!"),

    type: Yup.string().required("This field is required!"),

    pay_date: Yup.string().when("type", {
      is: (type) => type != "Online",
      then: Yup.string().required("This field is required!"),
    }),

    challan_no: Yup.string().when("type", {
      is: (type) => type != "Online",
      then: Yup.string().required("This field is required!"),
    }),

    paid_amount: Yup.string().when("part_payment", {
      is: (type) => type == true,
      then: Yup.string().required("This field is required!"),
    }),
  });

  const handleSubmit = async (payload) => {
    setLoading(true);
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);
    try {
      await BaseApi().post(`pending-to-offline-payment`, payload);
      setLoading(false);
      alert("Payment Successful");
      onMakeFeeCollection();
      setShowModal(false);
    } catch (error) {
      alert("Something went wrong");
    }
  };

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  console.log(message);
  return (
    <>
      <div className='justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative   w-800 my-6 mx-auto max-w-4xl'>
          {/*content*/}

          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-2xl font-semibold'>
                Student Selected: {payment.student?.name}
              </h3>
            </div>
            {/*body*/}
            <p style={{ width: "800px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative p-6 flex-auto'>
                    <table className='min-w-full mt-10   bg-white '>
                      <tfoot>
                        <tr>
                          <th className={thClass}>Programme</th>
                          <th className={thClass}>
                            {payment.programme?.name} - Year/Sem :{" "}
                            {payment.year_sem}
                          </th>
                        </tr>
                        <tr>
                          <th className={thClass}>Last Date Without Fine</th>
                          <th className={thClass}>
                            {payment.last_date}

                            <small className='text-red-600'>
                              {moment(payment.last_date).isBefore(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && " Expired"}
                            </small>
                          </th>
                        </tr>
                        <tr>
                          <th className={thClass}>Last Date With Fine</th>
                          <th className={thClass}>
                            {payment.last_date_fine}

                            <small className='text-red-600'>
                              {moment(payment.last_date_fine).isBefore(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && " Expired"}
                            </small>
                          </th>
                        </tr>
                        <tr>
                          <th className={thClass}>
                            Amount
                            {payment.late_fine}
                            <label htmlFor='late_fine'>
                              (
                              <input
                                className='form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200   align-top bg-no-repeat bg-center bg-contain ml-2  mr-2 cursor-pointer'
                                type='checkbox'
                                checked={initialValues?.late_fine}
                                value={initialValues?.late_fine}
                                onChange={(event) => {
                                  console.log(event.target.checked);
                                  if (event.target.checked == false) {
                                    setPayment({
                                      ...payment,
                                      amount: payment.amount - 500,
                                      late_fine: false,
                                    });
                                  } else {
                                    setPayment({
                                      ...payment,
                                      amount: payment.amount + 500,
                                      late_fine: true,
                                    });
                                  }
                                  setInitialValues({
                                    payment_id: initialValues.payment_id,
                                    type: initialValues.type,
                                    challan_no: initialValues.challan_no,
                                    part_payment: initialValues.part_payment,
                                    paid_amount: initialValues.paid_amount,
                                    pay_date: initialValues.pay_date,
                                    late_fine: event.target.checked,
                                  });
                                }}
                                id='late_fine'
                              />
                              With Late Fine)
                            </label>
                          </th>
                          <th className={thClass}>{payment.amount}</th>
                        </tr>

                        {authData.panel == "staff" &&
                          selectedPayment.payment_type != "HostelRenewal" && (
                            <tr>
                              <th className={thClass}>
                                <input
                                  className='form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                                  type='checkbox'
                                  checked={initialValues?.part_payment}
                                  value={initialValues?.part_payment}
                                  onChange={(event) => {
                                    console.log(event.target.checked);
                                    setInitialValues({
                                      payment_id: initialValues.payment_id,
                                      type: initialValues.type,
                                      challan_no: initialValues.challan_no,
                                      part_payment: event.target.checked,
                                      paid_amount: initialValues.paid_amount,
                                      pay_date: initialValues.pay_date,
                                      late_fine: initialValues.late_fine,
                                    });
                                  }}
                                  id='part_payment'
                                />
                                <label htmlFor='part_payment'>
                                  Enable Part Payment
                                </label>
                              </th>
                              <th className={thClass}>
                                {authData.panel == "staff" &&
                                  initialValues?.part_payment == true && (
                                    <Input
                                      type='number'
                                      label='Paid Amount'
                                      name='paid_amount'
                                    />
                                  )}
                              </th>
                            </tr>
                          )}
                      </tfoot>
                    </table>

                    <p className='my-4 text-slate-500 text-lg leading-relaxed'></p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <CustomSelect
                      className='mt-2'
                      isClearable={true}
                      options={
                        authData.panel == "staff"
                          ? [
                              { label: "SBI Collect", value: "SBI Collect" },
                              { label: "POS", value: "POS" },
                              {
                                label: "PNB Counter Deposit",
                                value: "PNB Counter Deposit",
                              },
                              {
                                label: "Education Loan",
                                value: "Education Loan",
                              },
                              { label: "Other", value: "Other" },
                            ]
                          : [{ label: "Online(Billdesk)", value: "Online" }]
                      }
                      name='type'
                      label='Payment Type'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.label} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "type",
                          selectedOption.value
                        );

                        setInitialValues({
                          payment_id: initialValues.payment_id,
                          type: selectedOption.value,
                          challan_no: initialValues.challan_no,
                          part_payment: initialValues.part_payment,
                          paid_amount: initialValues.paid_amount,
                          pay_date: initialValues.pay_date,
                          late_fine: initialValues.late_fine,
                        });
                      }}
                    />

                    {initialValues?.type != "" &&
                      initialValues?.type != "Online" && (
                        <Input
                          label='Payment Date'
                          type='date'
                          name='pay_date'
                        />
                      )}

                    {initialValues?.type != "" &&
                      initialValues?.type != "Online" && (
                        <Input label='Ref. No.' name='challan_no' />
                      )}

                    <Button
                      disabled={loading}
                      value={loading ? "Saving..." : "Submit"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
}
