import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaAward } from "react-icons/fa6";
import moment from "moment";

const headers = {
  award_name: { label: "Award Name", path: "award_name" },
  award_details: { label: "Award Details", path: "award_details" },
  month: { label: "Month of the Award", path: "monthName" },
  year: { label: "Year of the Award", path: "year" },
  awarded_by: { label: "Awarded By", path: "awarded_by" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const HonorsAward = ({ view }) => {
  const [honorsAwards, setHonorsAwards] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/honors-awards");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setHonorsAwards(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/honors-awards");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredAwards = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year in descending order
      const sortedAwards = filteredAwards.sort((a, b) => {
        const yearA = parseInt(getValueByPath(a, headers.year.path), 10);
        const yearB = parseInt(getValueByPath(b, headers.year.path), 10);
        return yearB - yearA; // Descending order
      });

      setHonorsAwards(sortedAwards);
    } catch (error) {
      console.error(error);
    }
  };

  const renderHonorsAward = (honorsAward) => {
    const awardName = getValueByPath(honorsAward, headers.award_name.path);
    const awardDetail = getValueByPath(honorsAward, headers.award_details.path);
    const month = getValueByPath(honorsAward, headers.month.path);
    const year = getValueByPath(honorsAward, headers.year.path);
    const awardedBy = getValueByPath(honorsAward, headers.awarded_by.path);

    return (
      <div className='flex items-start justify-between py-2'>
        <div className='flex items-center justify-center w-1/5 '>
          <p className='flex flex-col items-center justify-center px-2 py-1 font-normal text-white bg-blue-800 rounded '>
            <span className='text-lg'>{year}</span>
            <span className='text-xs'>{month}</span>
          </p>
        </div>
        <div className='w-4/5'>
          <span className='text-base font-medium text-gray-600'>
            {awardName}
          </span>
          <span className='block text-sm font-normal text-gray-500'>
            {awardedBy}
          </span>
          <span className='block mt-1 text-sm font-normal text-gray-500'>
            {awardDetail}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        <FaAward />
        Honors & Awards
      </h2>
      {honorsAwards.length ? (
        <div className=''>
          {honorsAwards.map((honorsAward, index) => (
            <div key={index} className=''>
              {renderHonorsAward(honorsAward)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default HonorsAward;
