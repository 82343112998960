import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  getDepartmentsAsync,
  getDesignationsAsync,
} from "../../Redux/MasterSlice";
import Input from "../../Components/Utils/Input";
// import CustomSelect from "../../Components/Utils/CustomSelect";

export default function EditPhone() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  // const [singleStaff, setSingleStaff] = useState([]);
  const formikRef = useRef();
  const master = useSelector((state) => state.master);
  const departments = [...master.departments];
  const designations = [...master.designations];
  const authData = useSelector((state) => state.auth);

  const [formRole, setFormRole] = useState("edit");
  const [isOtpSent, setIsOtpSent] = useState(false);

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
    if (designations.length === 0) {
      dispatch(getDesignationsAsync());
    }
    if (authData.user.id == id) {
      // getStaff();
      setInitialValues({
        id: id,

        new_phone: "",
        otp: "",
      });
    } else {
      setErrorMessage("Unauthorised Access");
    }

    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  // async function getStaff() {
  //   console.log("as");
  //   setLoading(true);
  //   await BaseApi()
  //     .get(`staff`, {})
  //     .then((data) => {
  //       console.log(data.data);
  //       // let _singleStaff = data.data.find((e) => e.id == id);

  //     })
  //     .catch((error) => {
  //       const message =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //       setErrorMessage(message);
  //     });

  //   setLoading(false);
  // }

  // const onEdit = (event, staff) => {
  //   event.preventDefault();
  //   setInitialValues({
  //     id: staff.id,
  //     name: staff.name,
  //     email: staff.email,
  //     phone: staff.phone,
  //   });

  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setFormRole("edit");
  // };

  const validationSchema = Yup.object().shape({
    new_phone: Yup.string().nullable().required("This field is required!"),
  });

  const handleSendOtp = async (payload) => {
    console.log("ad");
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      await BaseApi()
        .post(`staff/edit-phone/${payload.id}`, payload)
        .then((response) => {
          var step = response.data?.step;
          // console.log(response.data);
          if (step == 1) {
            setIsOtpSent(true);
          }
          if (step == 2) {
            setIsOtpSent(false);
            setInitialValues({
              id: id,

              new_phone: "",
              otp: "",
            });
          }

          // let _staff = [...staff];
          // const index = _staff.findIndex((s) => s.id === response.data.id);
          // _staff[index] = response.data;
          // setStaff(_staff);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // setErrorMessage(message);
          toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        });
    }

    setLoading(false);
    setFormRole("edit");
  };

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Edit Phone Number" />

            <div className="">
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            {initialValues && (
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSendOtp}
              >
                <Form>
                  <h3 className="text-xl pt-6"></h3>

                  <div className="   grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  gap-8">
                    <Input
                      readOnly={isOtpSent ? true : false}
                      label="New Phone Number"
                      name="new_phone"
                      placeholder="Enter New Phone Number"
                    />
                    {isOtpSent == true && (
                      <>
                        <Input
                          label="OTP"
                          name="otp"
                          placeholder="Enter the OTP"
                        />
                      </>
                    )}

                    <div className="text-left mt-5">
                      {formRole === "add" ? (
                        <Button
                          value={loading ? "Saving..." : "Save"}
                          icon={"SaveIcon"}
                          type="submit"
                        />
                      ) : (
                        <>
                          <Button
                            value={loading ? "Submitting..." : "Submit"}
                            icon={"SaveIcon"}
                            type="submit"
                          />
                          <span className="mr-5"></span>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
