import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

import {
  addPaymentHeadAsync,
  bulkDeletePaymentHeadsAsync,
  deletePaymentHeadAsync,
  getPaymentHeadsAsync,
  updatePaymentHeadAsync,
} from "../../../Redux/MasterSlice";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";

export default function PaymentHead() {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  const { message } = useSelector((state) => state.message);

  const master = useSelector((state) => state.master);
  const paymentHeads = [...master.paymentHeads];

  const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    setInitialValues({
      id: null,
      name: "",
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (paymentHeads.length === 0) {
      dispatch(getPaymentHeadsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const onEdit = (event, paymentHead) => {
    event.preventDefault();
    setInitialValues({
      id: paymentHead.id,
      name: paymentHead.name,
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFormRole("edit");
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      await dispatch(updatePaymentHeadAsync(payload)).unwrap();
    } else {
      await dispatch(addPaymentHeadAsync(payload)).unwrap();
    }

    setLoading(false);
    setFormRole("add");
    resetForm();
    setInitialValues({
      id: null,
      name: "",
    });
  };
  const handleDelete = async (event, payload) => {
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deleteConfirm.state == 1) {
      await dispatch(deletePaymentHeadAsync(payload));
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  const handleCheckbox = (event) => {
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      setSelectedIds([...selectedIds, value]);
    } else {
      var index = selectedIds.indexOf(value);
      if (index != -1) {
        setSelectedIds(selectedIds.filter((item) => item != value));
      }
    }
  };

  console.log(selectedIds);
  const handleBulkDelete = async (event) => {
    event.preventDefault();
    event.target.disabled = true;

    await dispatch(bulkDeletePaymentHeadsAsync({ selectedIds })).unwrap();
    setSelectedIds([]);
    event.target.disabled = false;
  };

  console.log(message);
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Master Payment Heads" />
            {message && <Alert message={message} />}
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=" py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                  <Input
                    label={
                      formRole === "add"
                        ? "Create New Master Head"
                        : "Edit Master Head"
                    }
                    name="name"
                    placeholder="Enter Head Name"
                  />

                  <div className="text-left mt-5">
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <Button
                        value={loading ? "Updating..." : "Update"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    )}
                  </div>
                </div>
              </Form>
            </Formik>

            <br />
            <table
              id="datatable"
              className="min-w-full   bg-white dark:bg-gray-800"
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>
                    {selectedIds.length > 0 && (
                      <button
                        className="outline-button-danger"
                        onClick={handleBulkDelete}
                      >
                        Delete Selected
                      </button>
                    )}
                  </th>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>Name</th>
                  <th colSpan="2" className={thClass}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentHeads.length > 0 &&
                  paymentHeads.map((head, i) => {
                    return (
                      <tr
                        key={head.id}
                        className="odd:bg-white even:bg-gray-50"
                      >
                        <td className={tdClass + "w-32"}>
                          <input
                            className="form-check-input    text-blue-600 h-8 w-8  border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top  float-left mr-5 cursor-pointer"
                            type="checkbox"
                            value={head.id}
                            onChange={(event) => handleCheckbox(event)}
                            id={head.id}
                          />
                        </td>
                        <td className={tdClass + "w-32"}>{++i}</td>
                        <td className={tdClass}>{head.name}</td>
                        <td className={tdClass}>
                          <button
                            className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={(event) => onEdit(event, head)}
                          >
                            Edit
                          </button>
                        </td>
                        <td className={tdClass}>
                          {(deleteConfirm.state == 0 ||
                            deleteConfirm.itemId != head.id) && (
                            <button
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                              onClick={() =>
                                setDeleteConfirm({
                                  itemId: head.id,
                                  state: 1,
                                })
                              }
                            >
                              Delete
                            </button>
                          )}
                          {deleteConfirm.state == 1 &&
                            deleteConfirm.itemId == head.id && (
                              <>
                                <p className="text-red-700"> Are You Sure? </p>

                                <button
                                  className="outline-button-danger"
                                  onClick={(event) => handleDelete(event, head)}
                                >
                                  Yes
                                </button>
                                <span className="mr-2"></span>
                                <button
                                  className="outline-button-success"
                                  onClick={() => setDeleteConfirm({})}
                                >
                                  No
                                </button>
                              </>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
