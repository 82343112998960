import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import { FaBuilding, FaCalendarDays } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";

const headers = {
  institution: { label: "Institution/Organization", path: "institution" },
  visit_date: { label: "Date of Visit", path: "visit_date" },
  visit_purpose: { label: "Purpose of Visit", path: "visit_purpose" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const AcademicVisits = ({ view }) => {
  const [academicVisits, setAcademicVisits] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/academic-visits");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setAcademicVisits(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/academic-visits");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredVisits = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by from_date in descending order
      const sortedVisits = filteredVisits.sort((a, b) => {
        const dateA = moment(getValueByPath(a, headers.from_date.path));
        const dateB = moment(getValueByPath(b, headers.from_date.path));
        return dateB - dateA; // Descending order
      });

      setAcademicVisits(sortedVisits);
    } catch (error) {
      console.error(error);
    }
  };

  const renderAcademicVisit = (visit) => {
    const institution = getValueByPath(visit, headers.institution.path);
    const visitDate = formatDate(
      getValueByPath(visit, headers.visit_date.path)
    );
    const visitPurpose = getValueByPath(visit, headers.visit_purpose.path);
    const fromDate = formatDate(getValueByPath(visit, headers.from_date.path));
    const toDate = getValueByPath(visit, headers.to_date.path)
      ? formatDate(getValueByPath(visit, headers.to_date.path))
      : "Present";

    return (
      <div className="items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600">
        <div className="p-2 space-y-2">
          {/* <h3 className='text-lg font-medium text-blue-900'>{institution}</h3> */}
          <p className="flex items-center gap-2">
            <FaBuilding />
            <span className="text-green-600">{institution}</span>
          </p>
          <p className="text-sm text-gray-500">Purpose: {visitPurpose}</p>
          <div className="flex items-center gap-3">
            <span className="flex items-center gap-1">
              <FaCalendarDays className="text-yellow-500" />
              {fromDate} - {toDate}
            </span>
            {/* <span className='flex items-center gap-1'>
              <FaInfoCircle className='text-yellow-500' />
              {visitDate}
            </span> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Academic Visits
      </h2>
      {academicVisits.length ? (
        <div className="">
          {academicVisits.map((visit, index) => (
            <div key={index} className="">
              {renderAcademicVisit(visit)}
            </div>
          ))}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default AcademicVisits;
