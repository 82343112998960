import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Layout from "../Components/Common/Layout";
import { logout } from "../Redux/AuthSlice";
import moment from "moment";

function ProtectedRoute() {
  const authData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
    "visibilitychange",
  ];
  let timer;
  useEffect(() => {
    if (authData.isLoggedIn) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, async () => {
          if (document.visibilityState === "hidden") {
            localStorage.setItem("lastAccess", Date());
            resetTimer();
            handleTimer();
          } else {
            let lastAccess = await localStorage.getItem("lastAccess");
            let now = moment(Date());
            var duration = moment.duration(now.diff(lastAccess));
            if (duration.asMinutes() > 30) {
              logoutAction();
            } else {
              localStorage.removeItem("lastAccess");
              resetTimer();
              handleTimer();
            }
          }
        });
      });
    }
  }, [authData]);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 1000 * 60 * 30);
  };
  const logoutAction = useCallback(() => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate("/");
        window.location.reload();
      })
      .catch(() => {
        navigate("/");
        window.location.reload();
      });
  }, [dispatch]);

  return authData.isLoggedIn ? <Layout /> : <Navigate to="/" />;
}

export default ProtectedRoute;
