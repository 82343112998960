import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Formik, Form } from "formik";
import BaseApi from "../../../Http/BaseApi";
import { updateAdmittedStudentsAsync } from "../../../Redux/StudentSlice";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Input from "../../../Components/Utils/Input";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { UploadIcon, TrashIcon, XIcon } from "@heroicons/react/outline";

const UpdateFellowshipModal = ({ isOpen, onClose, programmeStudent }) => {
  const formikRef = useRef();
  const [fellowshipNames, setFellowshipNames] = useState([]);
  const dispatch = useDispatch();
  const [initialValues] = useState({
    fellowship_name_id: programmeStudent.fellowship_name_id,
    fellowship_agency_id: programmeStudent.fellowship_agency_id,
    fellowship_start_date: programmeStudent.fellowship_start_date,
    fellowship_end_date: programmeStudent.fellowship_end_date,
  });

  useEffect(() => {
    const getFellowshipNames = async () => {
      try {
        const response = await BaseApi().get("/fellowship-names");
        setFellowshipNames(response.data);
      } catch (error) {
        console.error("Failed to fetch fellowship names", error);
        throw error;
      }
    };
    getFellowshipNames();
  }, []);

  const handleSubmit = async (values) => {
    await dispatch(
      updateAdmittedStudentsAsync({
        fields: {
          fellowship_name_id: values.fellowship_name_id,
          fellowship_agency_id: values.fellowship_agency_id,
          fellowship_start_date: values.fellowship_start_date,
          fellowship_end_date: values.fellowship_end_date,
        },
        table: "programme_student",
        id: programmeStudent.id,
      })
    );
    onClose(); // Close modal on successful submission
  };
  const handleDelete = async () => {
    await dispatch(
      updateAdmittedStudentsAsync({
        fields: {
          fellowship_name_id: null,
          fellowship_agency_id: null,
          fellowship_start_date: null,
          fellowship_end_date: null,
        },
        table: "programme_student",
        id: programmeStudent.id,
      })
    );
    onClose(); // Close modal on successful submission
  };

  const validationSchema = Yup.object().shape({
    fellowship_name_id: Yup.string()
      .nullable()
      .required("This field is required!"),
    fellowship_start_date: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  return (
    <Dialog open={isOpen} onClose={onClose} className='relative z-50'>
      <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

      <div className='fixed inset-0 flex items-center justify-center p-4'>
        <Dialog.Panel className='relative w-full max-w-md p-6 bg-white rounded'>
          <Dialog.Title className='text-lg font-bold'>
            Update Fellowship
          </Dialog.Title>
          {/* close icon */}

          <XIcon
            onClick={onClose}
            className='absolute w-6 h-6 text-red-500 cursor-pointer right-5 top-5'
          />

          <Formik
            initialValues={initialValues}
            innerRef={formikRef}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form className='mt-5 space-y-4'>
                <CustomSelect
                  isSearchable={true}
                  defaultInputValue={programmeStudent?.fellowship_name?.name}
                  label='Select Name of Fellowship'
                  name='fellowship_name_id'
                  options={fellowshipNames}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.name} `}
                  onChange={(selectedOption) => {
                    console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "fellowship_name_id",
                      selectedOption.id
                    );
                    formikRef.current?.setFieldValue(
                      "fellowship_agency_id",
                      selectedOption.fellowship_agency_id
                    );
                  }}
                />
                <Input
                  label='Start Date'
                  name='fellowship_start_date'
                  type='date'
                />
                <Input
                  label='End Date'
                  name='fellowship_end_date'
                  type='date'
                />

                <div className='flex justify-between'>
                  {/* delete button */}
                  <button
                    type='button'
                    onClick={() => {
                      // confirm alert
                      if (
                        window.confirm(
                          "Are you sure you want to delete this fellowship record?"
                        )
                      ) {
                        handleDelete();
                      }
                    }}
                    className='inline-flex items-center px-4 py-2 text-sm font-medium text-red-500 bg-white border border-transparent border-red-500 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  >
                    Delete <TrashIcon className='inline w-3 h-3 ml-1' />
                  </button>

                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    {isSubmitting ? "Updating..." : "Update Fellowship"}{" "}
                    <UploadIcon className='inline w-4 h-4 ml-1' />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default UpdateFellowshipModal;
