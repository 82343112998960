import moment from "moment";
import React from "react";

export default function EarnedLeavePrint({ leave_types, approval }) {
  let thClass =
    " text-gray-600   border p-1  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-1 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <div>
      <div className='grid grid-cols-6 pt-1 pl-2 pr-2 mt-5 onlyPrint'>
        <div className='text-right'>
          <img
            style={{ filter: "saturate(200%)" }}
            src='https://erp.dibru.work/images/dibru.png'
            className='rounded-full w-50'
            alt='Avatar'
          />
        </div>
        <div className='col-span-4'>
          <p className='text-lg font-bold text-center'>
            <img
              style={{ width: "200px" }}
              className='w-20 ml-auto mr-auto mt-o '
              src='/assets/logo-assamese.png'
            />
            Dibrugarh University
          </p>
          <p className='text-center text-md '>Dibrugarh-786004, Assam, India</p>
        </div>
        <div> </div>
      </div>

      <div className='flex flex-wrap items-center justify-around mt-4 text-lg font-medium'>
        <span className='mx-2'>Application no: {approval?.leave?.id} </span>
        <span className='mx-2'>
          {" "}
          Submitted on:{" "}
          {moment(approval?.leave?.created_at).format(
            "DD-MM-YYYY hh:mm A"
          )}{" "}
        </span>
      </div>
      <hr className='w-full mx-auto border-b-2 border-gray-600' />

      <div className='mx-10'>
        <div className='mt-4'>
          <label className='block mb-2'>
            Applicant: {approval?.leave?.staff?.full_name}
          </label>
        </div>

        <div className='mt-1'>
          <label className='block mb-2'>
            Designation: {approval?.leave?.staff?.designation?.name}
          </label>
        </div>

        <div className='mt-1'>
          <label className='block mb-2'>
            Department/Branch: {approval?.leave?.staff?.department?.name}
          </label>
        </div>

        <div className='mt-1'>
          <label className='block mb-2'>
            Nature of Leave:{" "}
            {[
              ...new Set(
                approval?.leave?.leave_dates?.map(
                  (item) => item.leave_type.name
                )
              ),
            ].toString()}
          </label>
        </div>

        <div className='mt-1 '>
          <label className='block'>
            Reason for Leave: {approval?.leave?.reason}
          </label>
        </div>

        <div className='grid grid-cols-1 gap-4 mt-1 '>
          <div>
            <label className='block mb-2'>
              Duration of Leave: From{" "}
              {moment(approval?.leave?.from_date).format("DD-MM-YYYY")} To{" "}
              {moment(approval?.leave?.to_date).format("DD-MM-YYYY")}{" "}
            </label>
          </div>
        </div>

        <div className='mt-1'>
          <label className='block mb-2'>
            Number of Days:{" "}
            {approval?.leave?.status != 1 && approval?.leave.total_days}
            {approval?.leave?.status == 1 &&
              approval?.leave?.leave_type_id != 3 && (
                <>
                  {
                    approval.leave?.leave_dates.filter((ld) => ld.status == 1)
                      .length
                  }
                </>
              )}
            {approval?.leave?.status == 1 &&
              approval?.leave?.leave_type_id == 3 && (
                <>{approval.leave?.leave_dates.length}</>
              )}
          </label>
        </div>

        <div className='grid grid-cols-1 gap-4 mt-1 '>
          <div>
            <label className='block mb-2'>
              Station Leave:{" "}
              {approval?.leave?.station_leave == 1 ? (
                <>
                  From{" "}
                  {moment(approval?.leave.sl_from_date).format("DD-MM-YYYY")}{" "}
                  {approval?.leave.sl_from_time} to{" "}
                  {moment(approval?.leave.sl_to_date).format("DD-MM-YYYY")}{" "}
                  {approval?.leave.sl_to_time}
                </>
              ) : (
                "No"
              )}
            </label>
          </div>
        </div>

        <div className='mt-1'>
          <label className='block mb-2'>Application forwarded by:</label>

          <table>
            <tr>
              <th className={thClass}>Authority</th>
              <th className={thClass}>Status</th>
              <th className={thClass}>Date</th>
              <th className={thClass}>Remark</th>
            </tr>

            {approval?.leave?.leave_approvals?.length > 0 &&
              approval?.leave?.leave_approvals.map((leave_approve) => {
                return (
                  <tr
                    key={leave_approve.id}
                    className='odd:bg-white even:bg-gray-50'
                  >
                    <td className={tdClass}>
                      {leave_approve?.approving_authority?.full_name}
                    </td>
                    <td className={tdClass}>
                      {leave_approve?.status == 0 && "Pending"}
                      {leave_approve?.status == 1 &&
                        leave_approve?.type == 1 &&
                        "Approved"}
                      {leave_approve?.status == 1 &&
                        leave_approve?.type == 0 &&
                        "Forwarded"}
                      {leave_approve?.status == 2 &&
                        leave_approve?.type == 0 &&
                        "Rejected"}
                      {leave_approve?.status == 2 &&
                        leave_approve?.type == 1 &&
                        "Rejected"}
                      {leave_approve?.status == -1 && "Cancelled"}
                    </td>
                    <td className={tdClass}>
                      {" "}
                      {leave_approve.approve_date
                        ? moment(leave_approve.approve_date).format(
                            "DD-MM-YYYY"
                          )
                        : ""}
                    </td>
                    <td className={tdClass}>{leave_approve.remark}</td>
                  </tr>
                );
              })}
          </table>
        </div>

        <div className='mt-1'>
          <label className='block mb-2'>
            Arrangement of Classes (if applicable):{" "}
            {approval?.leave?.arrangement}
          </label>
        </div>

        <div className='mt-1'>
          <label className='block mb-2'>
            Proposed In-charge (if applicable):{" "}
            {approval?.leave?.proposed_incharge}
            {approval?.leave?.incharges?.map((incharge) => (
              <>
                {incharge.staff?.full_name}-{incharge.remarks} <br />
              </>
            ))}
          </label>
        </div>
      </div>
      <hr className='my-2' />
      <h1 className='mt-2 text-xl font-bold text-center'>OFFICIAL USE</h1>

      <div className='mx-10 '>
        <div className='grid grid-cols-1 gap-4 mt-4 md:grid-cols-2'>
          <div>
            <label className='block mb-2'>
              Total Earned Leave accumulated till{" "}
              {leave_types?.find((lt) => lt.id == 6)?.pivot?.no_of_leave ==
                -1 && <b>Not Calculated</b>}
              {leave_types?.find((lt) => lt.id == 6)?.pivot?.no_of_leave !=
                -1 && (
                <b>
                  {moment(
                    leave_types?.find((lt) => lt.id == 6)?.pivot?.date
                  ).format("DD-MM-YYYY")}
                  {": "}
                  {
                    leave_types?.find((lt) => lt.id == 6)?.pivot?.no_of_leave
                  }{" "}
                </b>
              )}
            </label>
          </div>
        </div>

        <div className='mt-4'>
          <label className='block mb-1'>
            Remark: {approval?.earned_remarks}
          </label>
        </div>
      </div>

      <div className='flex flex-wrap items-center justify-between mx-10 mt-5 text-lg'>
        <span className=''>Section Officer</span>
        <span className=''>Dealing Assistant</span>
      </div>
      <hr className='w-4/5 mx-auto border-b-2 border-gray-600' />

      <div className='mx-10 mt-4 '>
        <label className='block underline'>APPROVAL NOTE:</label>
      </div>

      <div className='mx-10 mt-4 '>
        <label className='block mb-16'>Remark:</label>
      </div>

      <div className='mt-1'>
        <div className='flex justify-end mr-4 md:mr-32 '>
          {approval.leave.staff.type == 2 && (
            <label className='mr-2'>Deputy Registrar(Admin)</label>
          )}
          {approval.leave.staff.type == 1 && (
            <label className='mr-2'>Vice-Chancellor/Registrar</label>
          )}
          {approval.leave.staff.type == 3 && (
            <label className='mr-2'>Vice-Chancellor/Registrar</label>
          )}
        </div>
      </div>
    </div>
  );
}
