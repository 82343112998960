import React, { useState } from "react";
import BaseApi from "../Http/BaseApi";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

function ReportProblem() {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  //   const authData = useSelector((state) => state.auth);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("asdas");
    event.target.disabled = true;

    await BaseApi()
      .post(`report-problem`, { subject, description })
      .then(() => {
        toast.success(`Problem Reported.`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      })
      .catch(() => {
        toast.error("Error on reporting the problem", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      });
    event.target.disabled = false;
  };

  return (
    <div className='min-h-screen p-10 bg-white'>
      <h1 className='text-2xl font-bold text-left text-gray-800'>
        Report a Problem
      </h1>
      <small>Please enter the details </small>
      <form className='mt-10'>
        <div className='mb-5'>
          <label
            className='block mb-2 font-bold text-gray-800'
            htmlFor='subject'
          >
            Subject
          </label>
          <input
            className='w-full p-2 bg-white border border-gray-400 rounded-lg'
            id='subject'
            type='text'
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            required
          />
        </div>
        <div className='mb-5'>
          <label
            className='block mb-2 font-bold text-gray-800'
            htmlFor='description'
          >
            Description
          </label>
          <textarea
            className='w-full p-2 bg-white border border-gray-400 rounded-lg'
            id='description'
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            required
          />
        </div>
        <button
          className='px-4 py-2 font-bold text-white bg-blue-500 rounded-lg hover:bg-blue-700'
          type='button'
          onClick={handleSubmit}
        >
          Send to Admin
        </button>
      </form>
    </div>
  );
}

export default ReportProblem;
