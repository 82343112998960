import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  return (
    <>
      <h1 className="flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase ">
        Honors and Awards
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="grid grid-cols-2 gap-3 p-5 ">
          <Input
            type="text"
            name="award_name"
            label={
              <div className="flex items-center">
                Award Name
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
          />
          <Input
            type="textarea"
            name="award_details"
            label={
              <div className="flex items-center">
                Award Details
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
          />
          <CustomSelect
            defaultInputValue={
              monthTypes.find((month) => month.id == initialValues.month)?.name
            }
            options={monthTypes}
            name="month"
            label={
              <div className="flex items-center">
                Month of the Award
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
            isSearchable={true}
            getOptionValue={(option) => `${option.id}`}
            getOptionLabel={(option) => `${option.name}`}
            onChange={async (selectedOption) => {
              formikRef.current?.setFieldValue("month", selectedOption.id);
            }}
          />
          <Input
            type="text"
            name="year"
            label={
              <div className="flex items-center">
                Year
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
          />
          <Input
            type="text"
            name="awarded_by"
            label={
              <div className="flex items-center">
                Awarded By
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
          />
          <div className="flex justify-between col-span-2 mt-10">
            <button
              className="flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              onClick={onCancel}
            >
              <XIcon className="w-5 h-5 mr-2" /> Cancel
            </button>
            {isEditMode ? (
              <Button type="submit" value="Update" icon={"SaveIcon"} />
            ) : (
              <Button type="submit" value="Save" icon={"SaveIcon"} />
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default FormPage;
