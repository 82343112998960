import React, { useEffect, useState } from "react";
import {
  FaCalendarDays,
  FaClipboardList,
  FaUserGraduate,
} from "react-icons/fa6";
import { FaUniversity } from "react-icons/fa";
import BaseApi from "../../../../../../Http/BaseApi";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  thesis_title: { label: "Title of the Thesis", path: "thesis_title" },
  other_institute: {
    label: "Name of the University/Institute",
    path: "other_institute",
  },
  month: {
    label: "Month",
    path: "monthName",
  },
  year: {
    label: "Year",
    path: "year",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const ThesisExaminerUniversitie = ({ view }) => {
  const [thesisExaminerUniversities, setThesisExaminerUniversities] = useState(
    []
  ); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/thesis-examiner-universities"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      setThesisExaminerUniversities(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderThesisExaminerUniversitie = (guidance) => {
    const scholarName = getValueByPath(guidance, headers.researcher_name.path);
    const otherInstitute = getValueByPath(
      guidance,
      headers.other_institute.path
    );
    const thesisTitle = getValueByPath(guidance, headers.thesis_title.path);
    const year = getValueByPath(guidance, headers.year.path);
    const month = getValueByPath(guidance, headers.month.path);

    return (
      <div className="items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600">
        <div className="p-2 space-y-2">
          <h3 className="flex gap-2 items-center text-lg font-medium text-blue-900">
            {" "}
            <FaUserGraduate /> {scholarName}
          </h3>
          <p className="flex items-center gap-2">
            <span className="text-green-600">
              <FaUniversity className="inline" /> {otherInstitute}
            </span>

            <span className="text-gray-800">
              {" "}
              <FaClipboardList className="inline" />
              {thesisTitle}
            </span>
          </p>

          <div className="flex items-center gap-3">
            <span className="flex items-center gap-1">
              <FaCalendarDays className="text-yellow-500" />
              {month} {year}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Thesis Examiner of other Universities
      </h2>
      {thesisExaminerUniversities.length ? (
        <div className="">
          {thesisExaminerUniversities.map((guidance, index) => (
            <div key={index} className="">
              {renderThesisExaminerUniversitie(guidance)}
            </div>
          ))}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default ThesisExaminerUniversitie;
