import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Upload from "../../Components/Utils/Upload";
import StaffSelectionModal from "./StaffSelectionModal"; // Import the modal component
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentsAsync } from "../../Redux/MasterSlice";
import { toast } from "react-toastify";
import {
  FiAlertCircle,
  FiCalendar,
  FiCheck,
  FiFileText,
  FiTag,
} from "react-icons/fi";
import { FaList, FaPlus, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaX } from "react-icons/fa6";

export default function NoticeAndCircularsCreate() {
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [notices, setNotices] = useState([]);
  const [noticeCategories, setNoticeCategories] = useState([]);
  const [formRole, setFormRole] = useState("add");

  const [selectedNotice, setSelectedNotice] = useState();
  const [recipientModalIsOpen, setRecipientModalIsOpen] = useState(false);
  const [staffResults, setStaffResults] = useState([]);
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const master = useSelector((state) => state.master);

  const departments = [...master.departments];

  const openRecipientModal = () => setRecipientModalIsOpen(true);
  const closeRecipientModal = () => setRecipientModalIsOpen(false);
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const queryParams = new URLSearchParams(window.location.search);
  const parent_notice_id = queryParams.get("parent_notice_id"); // Extract parent_notice_id
  const type = queryParams.get("type");
  const [parentNotice, setParentNotice] = useState(null); // For displaying parent notice title

  useEffect(() => {
    if (departments.length == 0) {
      dispatch(getDepartmentsAsync());
    }
    setInitialValues({
      id: null,
      admin_notice_category_id: "",
      title: "",
      description: "",
      date: new Date().toISOString().split("T")[0],
      venue: "",
      time: "",
      duration: "",
      document_url: "",
      link: "",
      parent_id: parent_notice_id || "", // Set parent_id if passed
      type: type || "Fresh",
    });

    getNoticeCategories();
    getNotices();
    if (parent_notice_id) {
      getParentNoticeDetails(parent_notice_id); // Fetch parent notice details
    } else {
      setParentNotice(null);
    }
  }, []);

  const getParentNoticeDetails = async (id) => {
    await BaseApi()
      .get(`notices/${id}`)
      .then((response) => {
        setParentNotice(response.data); // Set the parent notice title
        setSelectedCategory(response.data.admin_notice_category_id); // Set the category based on parent notice
        formikRef.current?.setFieldValue(
          "admin_notice_category_id",
          response.data.admin_notice_category_id
        );
      });
  };

  const getNoticeCategories = async () => {
    await BaseApi()
      .get(`noticeCategories`)
      .then((response) => {
        setNoticeCategories(response.data);
      });
  };

  const getNotices = async () => {
    await BaseApi()
      .get(`notices`)
      .then((response) => {
        setNotices(response.data);
      });
  };

  const validationSchema = Yup.object().shape({
    admin_notice_category_id: Yup.string().required("This field is required!"),
    title: Yup.string().required("Title is required!"),
    description: Yup.string().required("Description is required!"),

    // Conditional fields for category 3 (Venue, Date, Time, Duration, Link)
    venue: Yup.string().when("admin_notice_category_id", {
      is: "3",
      then: Yup.string().required("Venue is required for this category!"),
    }),

    date: Yup.date()
      .required("Date is required!")
      .test(
        "is-today-or-future",
        "Date cannot be in the past!",
        function (value) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set to start of today
          return value >= today;
        }
      ),

    time: Yup.string().when("admin_notice_category_id", {
      is: "3",
      then: Yup.string()
        .required("Time is required for this category!")
        .test(
          "is-future-time",
          "Time cannot be in the past!",
          function (value) {
            const selectedDate = this.resolve(Yup.ref("date"));
            const today = new Date();
            const currentTime = today.getHours() * 60 + today.getMinutes(); // current time in minutes
            const [selectedHours, selectedMinutes] = value
              .split(":")
              .map(Number);
            const selectedTime = selectedHours * 60 + selectedMinutes;

            // Allow any future time if the selected date is today
            if (
              selectedDate &&
              selectedDate.toDateString() === today.toDateString()
            ) {
              return selectedTime >= currentTime;
            }

            return true; // allow any time if the date is in the future
          }
        ),
    }),

    duration: Yup.string().when("admin_notice_category_id", {
      is: "3",
      then: Yup.string().required("Duration is required for this category!"),
    }),

    link: Yup.string().when("admin_notice_category_id", {
      is: "3",
      then: Yup.string().nullable(),
    }),

    document_url: Yup.mixed().test(
      "is-valid-size",
      "Max allowed size is 2MB",
      (value) => !value || (value && value.size <= 2048000)
    ),
  });

  const handleNext = (payload) => {
    setSelectedNotice(payload);
    openRecipientModal();
    getStaff();
  };

  const getStaff = async () => {
    setLoading(true);
    await BaseApi()
      .get(`staff_minimal`)
      .then((response) => {
        setStaffResults(response.data);
      });
    setLoading(false);
  };

  const toggleStaffSelection = (staffId) => {
    setSelectedStaffIds((prev) =>
      prev.includes(staffId)
        ? prev.filter((id) => id !== staffId)
        : [...prev, staffId]
    );
  };

  const handleAddRecipients = async () => {
    // confirm box
    if (!window.confirm("Are you sure you want to publish this?")) {
      return;
    }

    // get the latest formik data here
    setSelectedNotice(formikRef.current.values);

    const payload = {
      ...formikRef.current.values,
      staff: selectedStaffIds, // This is your array of staff IDs
    };

    setLoading(true);

    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    // Fix here: Append each staff ID individually to the FormData
    selectedStaffIds.forEach((staffId, index) => {
      formData.append(`staff[${index}]`, staffId);
    });

    await BaseApi()
      .post(`notices`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {});

    setLoading(false);

    formikRef.current?.resetForm();
    setFormRole("add");

    toast.success(
      noticeCategories.find((category) => category.id == selectedCategory)
        ?.name + " added successfully"
    );
    setSelectedCategory(null);
    setInitialValues({
      id: null,
      admin_notice_category_id: "",
      title: "",
      description: "",
      date: "",
      venue: "",
      time: "",
      duration: "",
      document_url: "",
      link: "",
      parent_id: parent_notice_id || "", // Set parent_id if passed
      type: type || "Fresh",
    });
    setSelectedStaffIds([]);
    setSelectedNotice(null);
  };

  const getCategoryIcon = (categoryId, classNames) => {
    switch (categoryId) {
      case 1:
        return <FiAlertCircle className={classNames} />; // Notice Icon
      case 2:
        return <FiFileText className={classNames} />; // Circular Icon
      case 3:
        return <FiCalendar className={classNames} />; // Meeting Invitation Icon
      default:
        return null;
    }
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Notice, Circulars and Meetings' />
            <Link to='/notice-circulars' className='flex justify-end ml-auto'>
              <button className='px-4 py-2 text-sm font-semibold text-white bg-green-500 rounded-lg'>
                <FaList className='inline' /> View List
              </button>
            </Link>

            {parentNotice && (
              <div className='mb-4 text-lg text-gray-700'>
                Adding {type} to : <strong>{parentNotice.title}</strong>
                <a href={`/notice-circulars-create`} className='ml-4'>
                  <FaTimes className='inline text-red-500 border border-red-500 text-md' />
                </a>
              </div>
            )}

            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleNext}
            >
              <Form>
                <div className='grid grid-cols-3 gap-4 mt-5'>
                  <div className='col-span-2'>
                    <Input name='parent_id' type='hidden' />
                    <Input name='type' type='hidden' />

                    {/* Radio Buttons for Categories */}
                    {selectedCategory ? (
                      // Show only the selected category and a button to change it
                      <div className='flex gap-4 mb-4'>
                        <button
                          disabled={parentNotice}
                          type='button'
                          className={`flex items-center gap-2 px-4 py-2 rounded-lg border bg-blue-500 text-white border-blue-500 transition-colors duration-300 ease-in-out disabled:cursor-not-allowed`}
                          onClick={() => {
                            setSelectedCategory(null);
                            formikRef.current?.setFieldValue(
                              "admin_notice_category_id",
                              null
                            );
                          }} // Reset the category selection
                        >
                          {getCategoryIcon(selectedCategory, "text-white")}
                          {/* Display category icon */}
                          {/* Icon next to category name */}
                          <span>
                            {
                              noticeCategories.find(
                                (category) => category.id == selectedCategory
                              )?.name
                            }
                          </span>
                          <FiCheck className='ml-2' />{" "}
                          {/* Check icon indicating selection */}
                        </button>

                        {/* Button to change category */}
                        <button
                          disabled={parentNotice}
                          type='button'
                          className='flex items-center gap-2 px-4 py-2 ml-4 text-sm text-red-500 transition-colors border border-red-500 rounded-lg hover:bg-red-100 disabled:cursor-not-allowed'
                          onClick={() => {
                            setSelectedCategory(null);
                            formikRef.current?.setFieldValue(
                              "admin_notice_category_id",
                              null
                            );
                          }}
                        >
                          <FaTimes className='text-red-500' />
                          <span>Change Category</span>
                        </button>
                      </div>
                    ) : (
                      // Show all categories if none is selected
                      <div className='flex gap-4 mb-4'>
                        {noticeCategories.map((category) => (
                          <button
                            key={category.id}
                            type='button'
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg border ${
                              selectedCategory == category.id
                                ? "bg-blue-500 text-white border-blue-500"
                                : "bg-gray-100 text-gray-700 border-gray-300"
                            } transition-colors duration-300 ease-in-out hover:bg-blue-500 hover:text-white`}
                            onClick={() => {
                              setSelectedCategory(category.id);
                              formikRef.current?.setFieldValue(
                                "admin_notice_category_id",
                                category.id
                              );
                            }}
                          >
                            {getCategoryIcon(category.id, "gray-700")}
                            {/* Display category-specific icon */}
                            {/* Tag icon */}
                            <span>{category.name}</span>
                            {selectedCategory == category.id && (
                              <FiCheck className='ml-2' /> // Check icon for selected category
                            )}
                          </button>
                        ))}
                      </div>
                    )}

                    {/* Show form fields only if a category is selected */}
                    {selectedCategory && (
                      <>
                        <div className='grid grid-cols-2 gap-8 py-5 '>
                          <div className='col-span-2'>
                            <Input
                              label='Title'
                              name='title'
                              placeholder='Title'
                            />
                          </div>
                          <div className='col-span-2'>
                            <Input
                              type='textarea'
                              label={
                                selectedCategory == 3
                                  ? "Description/Agenda"
                                  : "Description"
                              }
                              name='description'
                              placeholder={
                                selectedCategory == 3
                                  ? "Description/Agenda"
                                  : "Description"
                              }
                            />
                          </div>
                          <Input
                            readOnly={selectedCategory != 3}
                            label='Date'
                            name='date'
                            type='date'
                          />

                          {selectedCategory == "3" && (
                            <>
                              <Input
                                label='Venue'
                                name='venue'
                                placeholder='Venue'
                              />

                              <Input
                                label='Time'
                                name='time'
                                type='time'
                                placeholder='Time'
                                step='60'
                              />
                              <Input
                                label='Duration'
                                name='duration'
                                type='text'
                                placeholder='2 Hours'
                              />
                              <Input
                                label='Online Link (if available)'
                                name='link'
                                placeholder='Link'
                              />
                            </>
                          )}
                          <div className='col-span-2'>
                            <Upload
                              label='PDF/Image'
                              type='file'
                              name='file'
                              onChange={(event) => {
                                let myFile = event.currentTarget.files[0];
                                formikRef.current.setFieldValue(
                                  "document_url",
                                  myFile
                                );
                              }}
                            />
                          </div>
                        </div>

                        {/* Submit Button */}
                        {selectedStaffIds.length > 0 && (
                          <div className='my-5 text-center'>
                            <Button
                              type='button'
                              value='Submit and Send Emails'
                              icon={"SaveIcon"}
                              onClick={handleAddRecipients}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/* Selected Staff Section */}
                  <div className='relative flex flex-col p-4 bg-gray-100 rounded-lg'>
                    <h3 className='flex items-center justify-between mb-2 text-lg font-semibold'>
                      Selected Staff: {selectedStaffIds.length}
                      <Button
                        value='Add Recipients'
                        icon={"PlusIcon"}
                        onClick={() => formikRef.current?.submitForm()}
                      />
                    </h3>

                    {/* Show selected staff names */}
                    <ul className='pl-5 mt-10 list-disc  h-[70vh] overflow-y-auto'>
                      {selectedStaffIds.length > 0 ? (
                        selectedStaffIds.map((staffId) => {
                          const staff = staffResults.find(
                            (s) => s.id == staffId
                          ); // Find staff details
                          return (
                            <li key={staffId} className='mb-2'>
                              <p className='text-sm font-medium text-gray-700'>
                                {staff?.name}
                              </p>
                              <p className='text-xs text-gray-500'>
                                {staff?.designation?.name}
                              </p>
                              <p className='text-xs text-gray-500'>
                                {staff?.department?.name}
                              </p>
                            </li>
                          );
                        })
                      ) : (
                        <p className='text-sm text-center text-gray-500'>
                          No staff selected yet.
                        </p>
                      )}
                    </ul>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>

      {/* Staff selection modal */}
      <StaffSelectionModal
        isOpen={recipientModalIsOpen}
        closeModal={closeRecipientModal}
        staffResults={staffResults}
        selectedStaffIds={selectedStaffIds}
        toggleStaffSelection={toggleStaffSelection}
        setStaffResults={setStaffResults}
        setSelectedStaffIds={setSelectedStaffIds}
        departments={departments}
        selectedNotice={selectedNotice}
        isAddMore={false}
      />
    </>
  );
}
