import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";
import messageSlice from "../../Redux/MessageSlice";
import BaseApi from "../../Http/BaseApi";
import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { useNavigate } from "react-router-dom";
import can from "../../Middleware/Permission";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
// import Input from "../../Components/Utils/Input";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";
import moment from "moment";

export default function BudgetProposalSubmit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [assignedHeads, setAssignedHeads] = useState([]);
  const [openBudgets, setOpenBudgets] = useState([]);

  const [proposals, setProposals] = useState([]);
  const master = useSelector((state) => state.master);
  const academicSessions = [...master.academicSessions];
  const { message } = useSelector((state) => state.message);
  const authData = useSelector((state) => state.auth);

  const costCenters = authData.user?.cost_centers;

  const [selectedCostCenter, setSelectedCostCenter] = useState();

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    if (assignedHeads.length === 0) {
      // getAssignedHeads();
    }
    getOpenBudgets();

    setInitialValues({
      academic_session_id: "",
      acc_budget_form_id: "",
      cost_center_id: "",
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);
  console.log(initialValues);
  let currentSession = academicSessions.find((e) => {
    return e.id == formikRef.current?.values?.academic_session_id;
  });

  let lastSession = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 1;
  });

  let last2Session = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 2;
  });
  const getAssignedHeads = async (acc_budget_master_id) => {
    await BaseApi()
      .get(
        `get-assigned-heads?acc_budget_master_id=${acc_budget_master_id}&cost_center_id=${
          selectedCostCenter.id ?? 0
        }`
      )
      .then(async (response) => {
        let heads = response.data;

        await heads.map((head) => {
          if (head.parent.parent) {
            if (!heads.some((h) => h.id == head.parent.parent.id))
              heads.push(head.parent.parent);
            delete head.parent.parent;
          }
          if (head.parent) {
            if (!heads.some((h) => h.id == head.parent.id))
              heads.push(head.parent);
            delete head.parent;
          }
        });

        console.log("Flat Heads", heads);
        setAssignedHeads(heads);
      });
  };

  const getOpenBudgets = async () => {
    await BaseApi()
      .get(`get-open-budgets`)
      .then((response) => {
        console.log(response.data);
        setOpenBudgets(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
    acc_budget_form_id: Yup.string().required("This field is required!"),
    cost_center_id: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    if (!window.confirm("Are you sure?")) return;
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);
    setLoading(true);
    await BaseApi().post(`budget-proposal-submit`, payload);

    setLoading(false);
    // resetForm({ academic_session_id: "", details: [] });

    // setInitialValues({
    //   academic_session_id: "",
    //   type: type,
    //   details: [],
    // });
    dispatch(messageSlice.actions.setMessage("Budget Submitted Successfully"));
    callProposals({
      id: formikRef.current?.values?.acc_budget_form_id,
      academic_session_id: formikRef.current?.values?.academic_session_id,
    });
  };

  const callProposals = async (selectedOption) => {
    setLoading(true);
    const _proposals = await BaseApi().get(`budget-proposals`, {
      params: {
        acc_budget_form_id: selectedOption.id,
        cost_center_id: selectedCostCenter.id,
      },
    });

    setProposals(_proposals.data);

    setInitialValues({
      acc_budget_form_id: selectedOption.id,
      academic_session_id: selectedOption.academic_session_id,
      cost_center_id: selectedCostCenter.id,
    });
    setLoading(false);
  };

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Budget Final Summary" />

            <h2 className="text-xl ml-5">
              Cost & Revenue Center: {authData.user?.cost_center?.name}
            </h2>
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=" py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                  <CustomSelect
                    options={costCenters}
                    name="cost_center_id"
                    label="Select Cost/Revenue Center"
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option?.name}`}
                    onChange={async (selectedOption) => {
                      // console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "cost_center_id",
                        selectedOption.id
                      );
                      setSelectedCostCenter(selectedOption);
                      setProposals([]);
                      setAssignedHeads([]);
                    }}
                  />
                  {selectedCostCenter && (
                    <CustomSelect
                      options={openBudgets}
                      name="acc_budget_form_id"
                      label="Select Budget Master"
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) =>
                        `${option.budget_master?.name} - ${
                          option.academic_session?.label
                        }  | Last Date:  ${moment(option.to_date).format(
                          "Do MMM YYYY"
                        )} `
                      }
                      onChange={async (selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "acc_budget_form_id",
                          selectedOption.id
                        );

                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          selectedOption.academic_session_id
                        );
                        await getAssignedHeads(
                          selectedOption.acc_budget_master_id
                        );

                        callProposals(selectedOption);
                      }}
                    />
                  )}
                </div>
                {loading == true && (
                  <>
                    <div className="flex">
                      <span className="m-auto">
                        <ThreeDots
                          height="100"
                          width="100"
                          color="grey"
                          ariaLabel="loading"
                        />
                      </span>
                    </div>
                  </>
                )}
                <h1 className="my-5 font-medium text-xl">
                  {" "}
                  Receipts Side:
                  <span
                    className=" float-right
                             bg-blue-600  hover:bg-blue-700 text-sm cursor-pointer text-white  py-1 px-2 rounded"
                    onClick={() => {
                      navigate(`/budget-proposal-entry/receipt/`);
                    }}
                  >
                    Edit
                  </span>
                  <Button
                    classNames="float-right mr-5 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                    value={"Download CSV"}
                    onClick={() => tableToCSV("datatableReceipt")}
                    icon={"DownloadIcon"}
                    type="button"
                  />
                </h1>

                <table
                  id="datatableReceipt"
                  className="min-w-full mt-5 bg-white dark:bg-gray-800"
                >
                  <thead>
                    <tr className="w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8">
                      <th className="table-head"> Head of Accounts</th>
                      <th className="table-head">
                        {" "}
                        Actual
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 2;
                          })?.label
                        }{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>
                      </th>
                      <th className="table-head">
                        {" "}
                        Budget
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 1;
                          })?.label
                        }{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>
                      </th>
                      <th className="table-head">
                        {" "}
                        Revised
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 1;
                          })?.label
                        }{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>
                      </th>
                      <th className="table-head">
                        {" "}
                        Budget {currentSession?.label}{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedHeads
                      .filter(
                        (ah) => ah.acc_payment_head_id == null && ah.type == 1
                      )
                      ?.map((head) => {
                        return (
                          <>
                            {assignedHeads.some(
                              (detail1) =>
                                detail1.acc_payment_head_id == head.id
                            ) ? (
                              <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
                                <td
                                  colSpan="5"
                                  className="  table-data font-bold"
                                >
                                  {head.name}
                                </td>
                              </tr>
                            ) : (
                              dataRowReceipt(
                                assignedHeads.indexOf(head),
                                head,
                                proposals,
                                last2Session,
                                lastSession,
                                currentSession
                              )
                            )}

                            {assignedHeads
                              .filter(
                                (detail1) =>
                                  detail1.acc_payment_head_id == head.id
                              )
                              ?.map((child) => {
                                return (
                                  <>
                                    {assignedHeads.some(
                                      (detail2) =>
                                        detail2.acc_payment_head_id == child.id
                                    ) ? (
                                      <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
                                        <td
                                          colSpan="5"
                                          className="font-medium table-data"
                                        >
                                          {child.name}
                                        </td>
                                      </tr>
                                    ) : (
                                      dataRowReceipt(
                                        assignedHeads.indexOf(child),
                                        child,
                                        proposals,
                                        last2Session,
                                        lastSession,
                                        currentSession
                                      )
                                    )}
                                    {assignedHeads
                                      .filter(
                                        (detail2) =>
                                          detail2.acc_payment_head_id ==
                                          child.id
                                      )
                                      ?.map((child1) => {
                                        return (
                                          <>
                                            {dataRowReceipt(
                                              assignedHeads.indexOf(child1),
                                              child1,
                                              proposals,
                                              last2Session,
                                              lastSession,
                                              currentSession
                                            )}
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}

                    {/* {assignedHeads
                      .filter((ah) => ah.type == 1)
                      .map((head, index) => {
                        console.log("abc");
                        return (
                          <>
                            {dataRow(
                              index,
                              head,
                              proposals,
                              last2Session,
                              lastSession,
                              currentSession
                            )}
                          </>
                        );
                      })} */}
                  </tbody>
                  <thead>
                    <tr className="w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8">
                      <th className="table-head"> Total</th>
                      <th className="table-head">
                        {" "}
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == last2Session.id &&
                              p.budget_type == 2
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_receipt) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                      <th className="table-head">
                        {" "}
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == lastSession.id &&
                              p.budget_type == 0
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_receipt) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                      <th className="table-head">
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == lastSession.id &&
                              p.budget_type == 1
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_receipt) || 0),
                            0
                          )
                          .toFixed(2)}{" "}
                      </th>
                      <th className="table-head">
                        {" "}
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == currentSession.id &&
                              p.budget_type == 0
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_receipt) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                    </tr>
                  </thead>
                </table>

                <hr className="m-10" />
                <h1 className="my-5 font-medium text-xl">
                  {" "}
                  Payments Side:
                  <span
                    className=" float-right
                             bg-blue-600  hover:bg-blue-700 text-sm cursor-pointer text-white  py-1 px-2 rounded"
                    onClick={() => {
                      navigate(`/budget-proposal-entry/payment`);
                    }}
                  >
                    Edit
                  </span>
                  <Button
                    classNames="float-right mr-5 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                    value={"Download CSV"}
                    onClick={() => tableToCSV("datatablePayment")}
                    icon={"DownloadIcon"}
                    type="button"
                  />
                </h1>
                <table
                  id="datatablePayment"
                  className="min-w-full mt-5 bg-white dark:bg-gray-800"
                >
                  <thead>
                    <tr className="w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8">
                      <th className="table-head"> Head of Accounts</th>
                      <th className="table-head">
                        {" "}
                        Actual
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 2;
                          })?.label
                        }{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>{" "}
                      </th>
                      <th className="table-head">
                        {" "}
                        Budget
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 1;
                          })?.label
                        }{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>{" "}
                      </th>
                      <th className="table-head">
                        {" "}
                        Revised
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 1;
                          })?.label
                        }{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>
                      </th>
                      <th className="table-head">
                        {" "}
                        Budget {currentSession?.label}{" "}
                        <small className="text-gray-400 text-xs">
                          <br />
                          Amount in Rs.
                        </small>{" "}
                      </th>
                      <th className="table-head"> Justification </th>
                    </tr>
                  </thead>

                  <tbody>
                    {assignedHeads
                      .filter(
                        (ah) => ah.acc_payment_head_id == null && ah.type == 2
                      )
                      ?.map((head) => {
                        return (
                          <>
                            {assignedHeads.some(
                              (detail1) =>
                                detail1.acc_payment_head_id == head.id
                            ) ? (
                              <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
                                <td
                                  colSpan="6"
                                  className="font-bold table-data"
                                >
                                  {head.name}
                                </td>
                              </tr>
                            ) : (
                              dataRowPayment(
                                assignedHeads.indexOf(head),
                                head,
                                proposals,
                                last2Session,
                                lastSession,
                                currentSession
                              )
                            )}

                            {assignedHeads
                              .filter(
                                (detail1) =>
                                  detail1.acc_payment_head_id == head.id
                              )
                              ?.map((child) => {
                                return (
                                  <>
                                    {assignedHeads.some(
                                      (detail2) =>
                                        detail2.acc_payment_head_id == child.id
                                    ) ? (
                                      <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
                                        <td
                                          colSpan="6"
                                          className="font-medium table-data"
                                        >
                                          {child.name}
                                        </td>
                                      </tr>
                                    ) : (
                                      dataRowPayment(
                                        assignedHeads.indexOf(child),
                                        child,
                                        proposals,
                                        last2Session,
                                        lastSession,
                                        currentSession
                                      )
                                    )}
                                    {assignedHeads
                                      .filter(
                                        (detail2) =>
                                          detail2.acc_payment_head_id ==
                                          child.id
                                      )
                                      ?.map((child1) => {
                                        return (
                                          <>
                                            {dataRowPayment(
                                              assignedHeads.indexOf(child1),
                                              child1,
                                              proposals,
                                              last2Session,
                                              lastSession,
                                              currentSession
                                            )}
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                  </tbody>

                  <tfoot>
                    <tr className="w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8">
                      <th className="table-head"> Total</th>
                      <th className="table-head">
                        {" "}
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == last2Session.id &&
                              p.budget_type == 2
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_payment) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                      <th className="table-head">
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == lastSession.id &&
                              p.budget_type == 0
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_payment) || 0),
                            0
                          )
                          .toFixed(2)}{" "}
                      </th>
                      <th className="table-head">
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == lastSession.id &&
                              p.budget_type == 1
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_payment) || 0),
                            0
                          )
                          .toFixed(2)}{" "}
                      </th>
                      <th className="table-head">
                        {" "}
                        {proposals
                          .filter(
                            (p) =>
                              p.academic_session_id == currentSession.id &&
                              p.budget_type == 0
                          )
                          ?.reduce(
                            (partialSum, proposal) =>
                              partialSum +
                              (parseInt(proposal.proposed_amount_payment) || 0),
                            0
                          )
                          .toFixed(2)}{" "}
                      </th>
                      <th className="table-head"> </th>
                    </tr>
                    <tr className="w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8">
                      <th className="table-head"> Surplus/Deficit</th>
                      <th className="table-head">
                        {" "}
                        {(
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == last2Session.id &&
                                p.budget_type == 2
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_receipt) ||
                                  0),
                              0
                            ) -
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == last2Session.id &&
                                p.budget_type == 2
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_payment) ||
                                  0),
                              0
                            )
                        ).toFixed(2)}
                      </th>
                      <th className="table-head">
                        {" "}
                        {(
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == lastSession.id &&
                                p.budget_type == 0
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_receipt) ||
                                  0),
                              0
                            ) -
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == lastSession.id &&
                                p.budget_type == 0
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_payment) ||
                                  0),
                              0
                            )
                        ).toFixed(2)}
                      </th>
                      <th className="table-head">
                        {" "}
                        {(
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == lastSession.id &&
                                p.budget_type == 1
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_receipt) ||
                                  0),
                              0
                            ) -
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == lastSession.id &&
                                p.budget_type == 1
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_payment) ||
                                  0),
                              0
                            )
                        ).toFixed(2)}{" "}
                      </th>
                      <th className="table-head">
                        {" "}
                        {(
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == currentSession.id &&
                                p.budget_type == 0
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_receipt) ||
                                  0),
                              0
                            ) -
                          proposals
                            .filter(
                              (p) =>
                                p.academic_session_id == currentSession.id &&
                                p.budget_type == 0
                            )
                            ?.reduce(
                              (partialSum, proposal) =>
                                partialSum +
                                (parseInt(proposal.proposed_amount_payment) ||
                                  0),
                              0
                            )
                        ).toFixed(2)}{" "}
                      </th>
                      <th className="table-head"> </th>
                    </tr>
                  </tfoot>
                </table>

                {currentSession &&
                  !can("Account Masters") &&
                  proposals.length > 0 &&
                  proposals.filter((p) => p.draft == 1).length > 0 && (
                    <div className="text-center mt-5">
                      <button
                        className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Final Submit
                      </button>
                    </div>
                  )}

                {currentSession &&
                  !can("Account Masters") &&
                  proposals.length > 0 &&
                  proposals.filter((p) => p.draft == 1).length == 0 && (
                    <div className="text-center mt-5">
                      <span className="bg-gradient-to-r  from-pink-500 to-yellow-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                        Budget Submitted
                      </span>
                    </div>
                  )}
              </Form>
            </Formik>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}
function dataRowPayment(
  index,
  head,
  proposals,
  last2Session,
  lastSession,
  currentSession
) {
  return (
    <tr
      key={index}
      className="h-12 border-gray-300 dark:border-gray-200 border-b"
    >
      <td className="table-data">
        {head.code} - {head.name}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == last2Session.id &&
              p.budget_type == 2
          )
          ?.proposed_amount_payment?.toFixed(2)}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == lastSession.id &&
              p.budget_type == 0
          )
          ?.proposed_amount_payment?.toFixed(2)}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == lastSession.id &&
              p.budget_type == 1
          )
          ?.proposed_amount_payment?.toFixed(2)}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == currentSession.id &&
              p.budget_type == 0
          )
          ?.proposed_amount_payment?.toFixed(2)}
      </td>
      <td className="table-data">
        {proposals.find((p) => p.acc_payment_head_id == head.id)?.justification}
      </td>
    </tr>
  );
}

function dataRowReceipt(
  index,
  head,
  proposals,
  last2Session,
  lastSession,
  currentSession
) {
  return (
    <tr
      key={index}
      className="h-12 border-gray-300 dark:border-gray-200 border-b"
    >
      <td className="table-data">
        {head.code} - {head.name}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == last2Session.id &&
              p.budget_type == 2
          )
          ?.proposed_amount_receipt?.toFixed(2)}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == lastSession.id &&
              p.budget_type == 0
          )
          ?.proposed_amount_receipt?.toFixed(2)}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == lastSession.id &&
              p.budget_type == 1
          )
          ?.proposed_amount_receipt?.toFixed(2)}
      </td>
      <td className="table-data">
        {proposals
          .find(
            (p) =>
              p.acc_payment_head_id == head.id &&
              p.academic_session_id == currentSession.id &&
              p.budget_type == 0
          )
          ?.proposed_amount_receipt?.toFixed(2)}
      </td>
    </tr>
  );
}
