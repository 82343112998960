import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../Components/Utils/Input";
import BaseApi from "../../Http/BaseApi";
import "./IDCard.css";
import * as Yup from "yup";
import ReactToPrint from "react-to-print";
import Button from "../../Components/Utils/Button";
import IDCardComponent from "./IDCardComponent";
import can from "../../Middleware/Permission";
import { useSelector } from "react-redux";

export default function StudentIDCard() {
  const { id } = useParams();
  const formikRef = useRef();
  const componentRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [changeBloodGroup, setChangeBloodGroup] = useState(false);
  const [bloodGroup, setBloodGroup] = useState(false);
  const [address, setAddress] = useState(false);
  const [pin, setPin] = useState(false);
  const [district, setDistrict] = useState(false);
  const [state, setState] = useState(false);
  const authData = useSelector((state) => state.auth);

  const [student, setStudent] = useState();
  const [initialValues, setInitialValues] = useState();

  const validationSchema = Yup.object().shape({
    valid_upto: Yup.string().required("This field is required!"),
    programme_student_id: Yup.string().required("This field is required!"),
  });

  const generateIDCard = async (payload) => {
    setLoading(true);

    const _id_card = await BaseApi().post(
      `admitted-students/generate-id-card`,
      payload
    );
    const _student = { ...student };
    _student.id_card = _id_card.data;

    setStudent(_student);
    console.log(_student);
    setLoading(false);
  };

  const updateAddress = async () => {
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: {
        address: { formsection_id: 22, formfield_id: 119, data: address },
        pin: { formsection_id: 22, formfield_id: 39, data: pin },
        district: { formsection_id: 22, formfield_id: 147, data: district },
        state: { formsection_id: 22, formfield_id: 148, data: state },
      },
      table: "admitted_custom_infos",
    });
    checkData();
    setChangeAddress(false);
  };
  const updateBloodGroup = async () => {
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: {
        blood_group: {
          formsection_id: 21,
          formfield_id: 15,
          data: bloodGroup,
        },
      },
      table: "admitted_custom_infos",
    });
    checkData();
    setChangeBloodGroup(false);
  };

  useEffect(() => {
    if (!can("Manage ID Card") && authData.panel != "department") {
      navigate(`/not-found`);
    }

    checkData();
  }, [id]);

  const requestEdit = async (value) => {
    if (!window.confirm("Are you sure?")) return;
    console.log(value);
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: { request_photo: value },
      table: "students",
    });
    let _student = {
      ...student,
      student: { ...student.student },
    };
    _student.student.request_photo = value;
    setStudent(_student);
  };

  async function checkData() {
    if (id && id > 0) {
      const _student = await BaseApi()
        .get(`admitted-students/get-id-card/${id}`)
        .catch(() => {
          window.alert("Something Went Wrong");
        });
      setStudent(_student.data);

      if (_student.data.id_card?.valid_upto) {
        setInitialValues({
          valid_upto: _student.data.id_card?.valid_upto,
          programme_student_id: id,
        });
      } else {
        const _validityDates = await BaseApi().get(`id-card-validity-dates`, {
          params: {
            academic_session_id: _student.data.academic_session_id,
            programme_id: _student.data.programme_id,
          },
        });
        setInitialValues({
          valid_upto: _validityDates.data?.valid_upto,
          programme_student_id: id,
        });
      }
    }
  }
  const changeStatus = async (status) => {
    await BaseApi().post(
      `admitted-students/update-id-card/${student.id_card.id}`,
      {
        status: status,
      }
    );
    const _student = { ...student };
    _student.id_card.status = status;

    setStudent(_student);
  };

  const deleteCard = async () => {
    await BaseApi().delete(
      `admitted-students/delete-id-card/${student.id_card.id}`
    );
    const _student = { ...student };
    _student.id_card = null;

    setStudent(_student);
  };

  return (
    <>
      {student != null && (
        <div className='ml-10'>
          {student.id_card == null && (
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={generateIDCard}
            >
              <Form>
                <div className=' py-5 grid  grid-cols-6  pt-6 gap-1'>
                  <Input label='Add Valid Upto' type='date' name='valid_upto' />

                  <div className='flex items-end'>
                    <button
                      className='   w-32 h-10   outline-button-success p-5'
                      disabled={loading}
                      type='submit'
                    >
                      {loading ? "Saving..." : "Generate"}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          )}
          {student.id_card != null && student.id_card.status == 1 && (
            <>
              {" "}
              <button
                className=' outline-button-danger p-5'
                disabled={loading}
                onClick={() => {
                  if (window.confirm("Are you sure?") == true) {
                    deleteCard();
                  } else {
                    return;
                  }
                }}
                type='button'
              >
                {loading ? "Saving..." : "Delete"}
              </button>
              <button
                className=' outline-button-danger p-5'
                disabled={loading}
                onClick={() => {
                  if (window.confirm("Are you sure?") == true) {
                    changeStatus(0);
                  } else {
                    return;
                  }
                }}
                type='button'
              >
                {loading ? "Saving..." : "Deactivate"}
              </button>
              <ReactToPrint
                trigger={() => (
                  <Button
                    classNames='  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 mb-5'
                    value={"Print"}
                    icon={"PrinterIcon"}
                  />
                )}
                content={() => componentRef.current}
              />
            </>
          )}

          {student.id_card != null && student.id_card.status == 0 && (
            <>
              <button
                className=' outline-button-success p-5'
                disabled={loading}
                onClick={() => {
                  if (window.confirm("Are you sure?") == true) {
                    changeStatus(1);
                  } else {
                    return;
                  }
                }}
                type='button'
              >
                {loading ? "Saving..." : "Reactivate ID Card"}
              </button>
            </>
          )}

          <button
            className=' outline-button-success p-5'
            disabled={loading}
            onClick={() => {
              setShowModal(true);
            }}
            type='button'
          >
            Zoom View
          </button>
          {showModal && (
            <>
              <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative   my-6 mx-auto'>
                  {/*content*/}
                  <div
                    style={{ width: "800px", height: "600px" }}
                    className='border-0  rounded-lg shadow-lg relative flex flex-col   bg-white outline-none focus:outline-none'
                  >
                    {/*header*/}
                    <div className='flex items-start justify-between p-5   border-slate-200 rounded-t'>
                      <button
                        className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                        onClick={() => setShowModal(false)}
                      >
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className='relative p-6 flex-auto mt-10'>
                    
                        <IDCardComponent
                          zoom={true}
                          student={student}
                          componentRef={componentRef}
                        />
                      
                    </div>
                    {/*footer*/}
                    <div className='flex items-center justify-end p-6   border-slate-200 rounded-b'>
                      <button
                        className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        type='button'
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
            </>
          )}
          <IDCardComponent student={student} componentRef={componentRef} />
          <br />
          {!changeAddress && (
            <button
              className='mt-10 outline-button-success p-5'
              disabled={loading}
              onClick={() => {
                setChangeAddress(true);
                setAddress(
                  student.admittedcustominfos?.length > 0 &&
                    student.admittedcustominfos.find((field) => {
                      return (
                        field.formfield_id == 119 && field.formsection_id == 22
                      );
                    })?.data
                );
                setPin(
                  student.admittedcustominfos?.length > 0 &&
                    student.admittedcustominfos.find((field) => {
                      return (
                        field.formfield_id == 39 && field.formsection_id == 22
                      );
                    })?.data
                );

                setDistrict(
                  student.admittedcustominfos?.length > 0 &&
                    student.admittedcustominfos.find((field) => {
                      return (
                        field.formfield_id == 147 && field.formsection_id == 22
                      );
                    })?.data
                );
                setState(
                  student.admittedcustominfos?.length > 0 &&
                    student.admittedcustominfos.find((field) => {
                      return (
                        field.formfield_id == 148 && field.formsection_id == 22
                      );
                    })?.data
                );

              }}
              type='button'
            >
              {loading ? "Saving..." : "Change Address"}
            </button>
          )}
          {changeAddress && (
            <>
              <br />
              <br />
              <p className='font-medium'> Edit Details:</p>
              <br />
              <label>Address:</label>
              <input
                name='address'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={`appearance-none rounded-none relative block w-100
                px-3 py-2 border border-gray-300 placeholder-gray-500
                 text-gray-900   focus:outline-none
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
              />
              <label>PIN:</label>
              <input
                name='pin'
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                className={`appearance-none rounded-none relative block w-100
                px-3 py-2 border border-gray-300 placeholder-gray-500
                 text-gray-900   focus:outline-none
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
              />
              <label>District:</label>
              <input
                name='district'
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                className={`appearance-none rounded-none relative block w-100
                px-3 py-2 border border-gray-300 placeholder-gray-500
                 text-gray-900   focus:outline-none
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
              />
              <label>State:</label>

              <select 
               className={`appearance-none rounded-none relative block w-100
               px-3 py-2 border border-gray-300 placeholder-gray-500
                text-gray-900   focus:outline-none
                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
               name='state'
               required=''
               onChange={(e) => setState(e.target.value)}
               value={state}
               >
    <option value="">Select One</option>
        <option value="1">Andhra Pradesh</option>
        <option value="2">Arunachal Pradesh</option>
        <option  value="3">Assam</option>
        <option value="4">Bihar</option>
        <option value="5">Chhattisgarh</option>
        <option value="6">Goa</option>
        <option value="7">Gujarat</option>
        <option value="8">Haryana</option>
        <option value="9">Himachal Pradesh</option>
        <option value="10">Jharkhand</option>
        <option value="11">Karnataka</option>
        <option value="12">Kerala</option>
        <option value="13">Madhya Pradesh</option>
        <option value="14">Maharashtra</option>
        <option value="15">Manipur</option>
        <option value="16">Meghalaya</option>
        <option value="17">Mizoram</option>
        <option value="18">Nagaland</option>
        <option value="19">Odisha</option>
        <option value="20">Punjab</option>
        <option value="21">Rajasthan</option>
        <option value="22">Sikkim</option>
        <option value="23">Tamil Nadu</option>
        <option value="24">Telangana</option>
        <option value="25">Tripura</option>
        <option value="26">Uttar Pradesh</option>
        <option value="27">Uttarakhand</option>
        <option value="28">West Bengal</option>
        <option value="29">Delhi</option>
    </select>

              
              <Button
                classNames='mt-5  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 mb-5'
                value={"Update"}
                onClick={() => {
                  updateAddress();
                }}
                icon={"PencilIcon"}
              />
              <Button
                classNames=' ml-5 mt-5  bg-red-600 hover:bg-red-700 focus:ring-red-500 mb-5'
                value={"Cancel"}
                onClick={() => setChangeAddress(false)}
                icon={"XIcon"}
              />
            </>
          )}

          {!changeBloodGroup && (
            <button
              className='mt-10 outline-button-success p-5'
              disabled={loading}
              onClick={() => {
                setChangeBloodGroup(true);
                setBloodGroup(
                  student.admittedcustominfos?.length > 0 &&
                    student.admittedcustominfos.find((field) => {
                      return (
                        field.formfield_id == 15 && field.formsection_id == 21
                      );
                    })?.data
                );
              }}
              type='button'
            >
              {loading ? "Saving..." : "Change Blood Group"}
            </button>
          )}
          {changeBloodGroup && (
            <>
              <br />
              <br />
              <p className='font-medium'> Edit Details:</p>
              <br />
              <label>Blood Group:</label>

              <select
                className={`appearance-none rounded-none relative block w-100
                px-3 py-2 border border-gray-300 placeholder-gray-500
                 text-gray-900   focus:outline-none
                  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                name='bloodGroup'
                required=''
                onChange={(e) => setBloodGroup(e.target.value)}
                value={bloodGroup}
              >
                <option value=''>Select One</option>
                <option value='1'>A+</option>
                <option selected='' value='2'>
                  B+
                </option>
                <option value='3'>O+</option>
                <option value='4'>AB+</option>
                <option value='5'>A-</option>
                <option value='6'>B-</option>
                <option value='7'>O-</option>
                <option value='8'>AB-</option>
              </select>

              <Button
                classNames='mt-5  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 mb-5'
                value={"Update"}
                onClick={() => {
                  updateBloodGroup();
                }}
                icon={"PencilIcon"}
              />
              <Button
                classNames=' ml-5 mt-5  bg-red-600 hover:bg-red-700 focus:ring-red-500 mb-5'
                value={"Cancel"}
                onClick={() => setChangeBloodGroup(false)}
                icon={"XIcon"}
              />
            </>
          )}

          {(can("Manage ID Card")  || authData.panel == "department") && (
            <>
              {student.student?.request_photo != 1 && (
                <Button
                  classNames=' ml-5 mt-5 outline-button-success'
                  value={"Request Photo Re-Upload"}
                  onClick={() => {
                    requestEdit(1);
                  }}
                  icon={"CheckIcon"}
                />
              )}
              {student.student?.request_photo == 1 && (
                <Button
                  classNames=' ml-5 mt-5 outline-button-danger'
                  value={"Cancel Photo Re-Uplaod Request"}
                  onClick={() => {
                    requestEdit(0);
                  }}
                  icon={"XIcon"}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
