import React, { useEffect, useState } from "react";
import { FaClipboardList, FaUserGraduate } from "react-icons/fa6";
import { FaCalendar, FaRegThumbsUp, FaUniversity } from "react-icons/fa";
import BaseApi from "../../../../../../Http/BaseApi";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: { label: "Role", path: "role" },
  thesis_title: { label: "Thesis Title", path: "thesis_title" },
  institute: {
    label: "Ph. D. Scholar registered in DU/Other Institute",
    path: "institute",
  },
  other_institute: { label: "Other Institute", path: "other_institute" },
  status: { label: "Status", path: "status" },

  month: {
    label: "Awarded Month",
    path: "monthName",
  },
  year: {
    label: "Awarded Year",
    path: "year",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const PhdAwardee = ({ view }) => {
  const [phdAwardees, setPhdAwardees] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/phd-awardees");
      const filterField = view === "iqac" ? "iqac" : "website";
      setPhdAwardees(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderPhdAwardee = (guidance) => {
    const scholarName = getValueByPath(guidance, headers.researcher_name.path);
    const role = getValueByPath(guidance, headers.role.path);
    const thesisTitle = getValueByPath(guidance, headers.thesis_title.path);
    const institute = getValueByPath(guidance, headers.institute.path);
    const otherInstitute = getValueByPath(
      guidance,
      headers.other_institute.path
    );

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'> Role: {role}</h3>
          <p className='flex items-center gap-2'>
            <span className='text-green-600'>
              <FaUserGraduate className='inline' /> {scholarName}
            </span>

            <FaClipboardList />
            <span className='text-gray-800'>{thesisTitle}</span>
          </p>
          <div className='flex items-center gap-2'>
            <span className='flex items-center gap-1'>
              <FaRegThumbsUp className='text-yellow-500' />
              Awarded
            </span>
            <p className='flex items-center gap-2'>
              <FaUniversity />
              <span className='text-gray-800'>Dibrugarh University</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Ph. D. Awardee
      </h2>
      {phdAwardees.length ? (
        <div className=''>
          {phdAwardees.map((guidance, index) => (
            <div key={index} className=''>
              {renderPhdAwardee(guidance)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default PhdAwardee;
