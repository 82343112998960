import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BaseApi from "../Http/BaseApi";
import messageSlice from "./MessageSlice";

export const getOldStudentsAsync = createAsyncThunk(
  "oldStudents/getOldStudentsAsync",
  async (payload) => {
    console.log(payload);
    const response = await BaseApi().get(`old-students`, {
      params: payload.payload,
    });
    console.log(response.data);
    return response.data;
  }
);

export const approveOldStudentAsync = createAsyncThunk(
  "oldStudents/approveOldStudentAsync",
  async ({ payload }, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `old-students/approve/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const rejectOldStudentAsync = createAsyncThunk(
  "oldStudents/rejectOldStudentAsync",
  async (payload) => {
    console.log(payload);
    const response = await BaseApi().put(
      `old-students/reject/${payload.id}`,
      payload
    );

    return response.data;
  }
);
export const cancelOldStudentAsync = createAsyncThunk(
  "oldStudents/cancelOldStudentAsync",
  async (payload) => {
    console.log(payload);
    const response = await BaseApi().put(
      `old-students/cancel-admission/${payload.id}`,
      payload
    );

    return response.data;
  }
);
export const showOldStudentAsync = createAsyncThunk(
  "oldStudents/showOldStudentAsync",
  async (payload) => {
    const response = await BaseApi().get(`old-students/${payload.id}`);

    return response.data;
  }
);
const initialState = {
  oldStudents: [],
  singleOldStudent: {},
  errorMessage: "",
};

const oldStudentSlice = createSlice({
  name: "oldStudents",
  initialState,
  reducers: {},
  extraReducers: {
    [getOldStudentsAsync.fulfilled]: (state, action) => {
      console.log("get oldStudents data");
      return { ...state, oldStudents: action.payload };
    },
    [getOldStudentsAsync.rejected]: (state) => {
      console.log("error getting oldStudents data");
      return { ...state, errorMessage: "Something went wrong" };
    },

    [showOldStudentAsync.fulfilled]: (state, action) => {
      return { ...state, singleOldStudent: action.payload };
    },
    [approveOldStudentAsync.fulfilled]: (state, action) => {
      const index = state.oldStudents.findIndex(
        (oldStudent) => oldStudent.id === action.payload.id
      );
      if (index > -1) {
        state.oldStudents[index] = action.payload;
      }

      // return {...state, oldStudents: action.payload} ;
    },
    [rejectOldStudentAsync.fulfilled]: (state, action) => {
      const index = state.oldStudents.findIndex(
        (oldStudent) => oldStudent.id === action.payload.id
      );
      if (index > -1) {
        state.oldStudents[index] = action.payload;
      }

      // return {...state, oldStudents: action.payload} ;
    },
    [cancelOldStudentAsync.fulfilled]: (state, action) => {
      const index = state.oldStudents.findIndex(
        (oldStudent) => oldStudent.id === action.payload.id
      );
      if (index > -1) {
        state.oldStudents[index] = action.payload;
      }

      // return {...state, oldStudents: action.payload} ;
    },
  },
});

export default oldStudentSlice.reducer;
