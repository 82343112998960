import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BaseApi from "../Http/BaseApi";
// import messageSlice from "./MessageSlice";

export const getApplicantsAsync = createAsyncThunk(
  "applicants/getApplicantsAsync",
  async (payload) => {
    console.log(payload);
    const response = await BaseApi().get(`applicants`, {
      params: payload.payload,
    });
    console.log(response.data);
    return response.data;
  }
);

export const showApplicantAsync = createAsyncThunk(
  "applicants/showApplicantAsync",
  async (payload) => {
    const response = await BaseApi().get(`applicants/${payload.id}`);

    return response.data;
  }
);
const initialState = {
  lastPage: null,
  applicants: [],
  formSections: [],
  prevExamData: [],
  extraExamData: [],
  entranceExamData: [],
  extraData: [],
  singleApplicant: {},
  errorMessage: "",
};

const applicantSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {},
  extraReducers: {
    [getApplicantsAsync.fulfilled]: (state, action) => {
      console.log("get applicants data");
      if (action.payload.form_students.current_page == 1) {
        state.applicants = [];
        state.formSections = [];
        state.prevExamData = [];
        state.extraExamData = [];
        state.entranceExamData = [];
        state.extraData = [];
        state.lastPage = null;
      }
      state.applicants.push(action.payload.form_students.data);
      state.formSections.push(action.payload.form_sections);
      state.prevExamData.push(action.payload.prev_exam_data);
      state.extraExamData.push(action.payload.extra_exam_data);
      state.entranceExamData.push(action.payload.entrance_exam_data);
      state.extraData.push(action.payload.extra_data);
      state.lastPage = action.payload.form_students.last_page;
    },
    [getApplicantsAsync.rejected]: (state) => {
      console.log("error getting applicants data");
      return { ...state, errorMessage: "Something went wrong" };
    },

    [showApplicantAsync.fulfilled]: (state, action) => {
      return { ...state, singleApplicant: action.payload, errorMessage: null };
    },
    [showApplicantAsync.rejected]: (state) => {
      return { ...state, errorMessage: "Something went wrong" };
    },
  },
});

export default applicantSlice.reducer;
