import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { XIcon } from "@heroicons/react/outline";
import Input from "../../../../../Components/Utils/Input";
import CustomSelect from "../../../../../Components/Utils/CustomSelect";
import Button from "../../../../../Components/Utils/Button";

const FormPage = ({
  partialEdit,
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  const roleTypes = [
    { id: 1, name: "Supervisor" },
    { id: 2, name: "Co-Supervisor" },
    { id: 3, name: "Member of DAC/RAC" },
  ];
  const statusTypes = [
    { id: 1, name: "Ongoing" },
    { id: 3, name: "Discontinued" },
    { id: 4, name: "Awarded" },
  ];

  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  console.log(partialEdit);
  return (
    <>
      <h1 className='flex flex-col items-center justify-center p-3 mt-2 text-xl font-normal uppercase '>
        External Ph. D. Scholars{" "}
        <span className='text-sm text-red-600'>
          (Ph.d Scholars registered in Other Universities/Institutes)
        </span>
      </h1>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-2 gap-3 p-5 mb-10 '>
            <Input
              type='text'
              name='researcher_name'
              label={
                <div className='flex items-center'>
                  Name of the Ph. D. Scholar
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              readOnly={partialEdit}
            />

            <CustomSelect
              defaultInputValue={initialValues.role}
              options={roleTypes}
              name='role'
              label={
                <div className='flex items-center'>
                  Role
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                console.log("Selected option:", selectedOption);
                formikRef.current?.setFieldValue("role", selectedOption.name);
              }}
              readOnly={partialEdit}
            />
            <Input
              type='text'
              name='thesis_title'
              label={
                <div className='flex items-center'>
                  Thesis Title
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              readOnly={partialEdit}
            />

            <Input
              type='text'
              name='other_institute'
              label={
                <div className='flex items-center'>
                  Name of the University/Institute
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              readOnly={partialEdit}
            />

            <CustomSelect
              defaultInputValue={initialValues.status}
              options={statusTypes}
              name='status'
              label={
                <div className='flex items-center'>
                  Status
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                console.log("Selected option:", selectedOption);
                formikRef.current?.setFieldValue("status", selectedOption.name);
                if (selectedOption.name === "Status") {
                  formikRef.current?.setFieldValue("awarded_date", "");
                }
              }}
            />
            {values.status === "Awarded" && (
              <>
                <CustomSelect
                  defaultInputValue={
                    monthTypes.find((month) => month.id == initialValues.month)
                      ?.name
                  }
                  options={monthTypes}
                  name='month'
                  label={
                    <div className='flex items-center'>
                      Awarded Month
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                  isSearchable={true}
                  getOptionValue={(option) => `${option.id}`}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={async (selectedOption) => {
                    formikRef.current?.setFieldValue(
                      "month",
                      selectedOption.id
                    );
                  }}
                />
                <Input
                  type='text'
                  name='year'
                  label={
                    <div className='flex items-center'>
                      Awarded Year
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />{" "}
              </>
            )}

            <div className='flex justify-between col-span-2'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>

              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
