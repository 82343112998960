import React from "react";
import * as HIcons from "@heroicons/react/outline";

export default function Badge(props) {
  let TheIcon = null;
  if (props.icon) {
    const { ...icons } = HIcons;
    TheIcon = icons[props.icon];
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      {props.status ?? "Active"}
      <button className="bg-transparent hover">
        {TheIcon && (
          <TheIcon
            className="h-4 w-4 ml-1"
            aria-hidden="true"
            onClick={props.iconClick ?? null}
          />
        )}
      </button>
    </span>
  );
}
