import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfileWeb from "../../utils/TableViewProfileWeb";
import {
  FaBriefcase,
  FaCalendarDays,
  FaIndianRupeeSign,
  FaRegThumbsUp,
  FaRProject,
  FaUser,
} from "react-icons/fa6";
import moment from "moment";
import { SiHiveBlockchain } from "react-icons/si";

const headers = {
  title: { label: "Title of the Project", path: "title" },
  type: { label: "Project Type", path: "type" },
  role: { label: "Role", path: "role" },
  funding_agency: { label: "Funding Agency", path: "funding_agency" },
  grant_no: { label: "Grant No.", path: "grant_no" },
  amount_granted: { label: "Amount Granted", path: "amount_granted" },
  status: { label: "Status", path: "status" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
  project_related: { label: "Project related to", path: "project_related" },
  collaborative_project: {
    label: "Is it a collaborative project?",
    path: "collaborative_project",
  },
  collaborative_project_details: {
    label: "Details",
    path: "collaborative_project_details",
  },
  research_fellow: {
    label: "Number of Research Fellows under the Project",
    path: "research_fellow",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const Project = ({ view }) => {
  const [projects, setProjects] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/projects");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setProjects(response.data.data.filter((q) => q[filterField] === "yes"));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/projects");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredProjects = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by start_date (latest date first)
      const sortedProjects = filteredProjects.sort((a, b) => {
        const startDateA = getValueByPath(a, headers.start_date.path);
        const startDateB = getValueByPath(b, headers.start_date.path);
        const dateA = startDateA ? moment(startDateA) : moment("1900-01-01");
        const dateB = startDateB ? moment(startDateB) : moment("1900-01-01");
        return dateB - dateA; // Descending order
      });

      setProjects(sortedProjects);
    } catch (error) {
      console.error(error);
    }
  };

  const renderProject = (project) => {
    const title = getValueByPath(project, headers.title.path);
    const type = getValueByPath(project, headers.type.path);
    const role = getValueByPath(project, headers.role.path);
    const fundingAgency = getValueByPath(project, headers.funding_agency.path);
    const grantNo = getValueByPath(project, headers.grant_no.path);
    const amountGranted = getValueByPath(project, headers.amount_granted.path);
    const status = getValueByPath(project, headers.status.path);

    const startDate = formatDate(
      getValueByPath(project, headers.start_date.path)
    );
    const endDate = getValueByPath(project, headers.end_date.path)
      ? formatDate(getValueByPath(project, headers.end_date.path))
      : "Present";

    return (
      <div className='items-start px-2 py-2 m-5 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-2'>
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>
          <p className='flex items-center gap-2'>
            <FaBriefcase /> Funding Agency:{" "}
            <span className='text-green-600'>{fundingAgency}</span>
          </p>

          <div className='flex items-center gap-3'>
            <span className='flex items-center gap-1'>
              <FaRegThumbsUp
                className={
                  status === "Ongoing" ? "text-yellow-500" : "text-green-500"
                }
              />
              {status}
            </span>

            <span className='flex items-center gap-1'>
              <FaUser className='text-yellow-500' />
              {role}
            </span>

            <span className='flex items-center gap-1'>
              <FaIndianRupeeSign className='text-yellow-500' />
              {amountGranted}
            </span>

            <span className='flex items-center gap-1'>
              <FaCalendarDays className='text-yellow-500' />
              {startDate} - {endDate}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className='flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        <SiHiveBlockchain />
        Projects
      </h2>
      {projects.length ? (
        <div className=''>
          {projects.map((project, index) => (
            <div key={index} className=''>
              {renderProject(project)}
            </div>
          ))}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Project;
