import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "../../Components/Utils/Input";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import {
  getDepartmentsAsync,
  getDesignationsAsync,
} from "../../Redux/MasterSlice";
import Select from "../../Components/Utils/Select";
import CustomSelect from "../../Components/Utils/CustomSelect";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Select from "../../Components/Utils/Select";
// import CustomSelect from "../../Components/Utils/CustomSelect";

export default function StaffAdminEdit() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [showCropModalSignature, setShowCropModalSignature] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [signatureFileUrl, setSignatureFileUrl] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedSignatureImage, setCroppedSignatureImage] = useState(null);
  const cropperRef = useRef();
  const cropperRefSignature = useRef();

  // const [selectedIds, setSelectedIds] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  const [singleStaff, setSingleStaff] = useState([]);
  // const [singleStaff, setSingleStaff] = useState([]);
  const formikRef = useRef();
  const master = useSelector((state) => state.master);
  const departments = [...master.departments];
  const designations = [...master.designations];
  const authData = useSelector((state) => state.auth);

  const [formRole, setFormRole] = useState("edit");

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setFileUrl(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setShowCropModal(true);
    }
  };

  const onCrop = () => {
    // if (typeof cropperRef?.current?.getCroppedCanvas() === "undefined") {
    //   return;
    // }
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    setCroppedImage(
      cropper
        .getCroppedCanvas({
          width: 200,
          height: 300,
        })
        .toDataURL()
    );
  };

  const onUpload = async () => {
    setLoading(true);
    console.log(croppedImage);
    const blob = await fetch(croppedImage).then((r) => r.blob());
    const formData = new FormData();
    formData.append("photo", blob, "cropped-image.jpg");
    await BaseApi()
      .post(`staff/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let _singleStaff = { ...singleStaff };
        _singleStaff.photo = response.data.photo;
        _singleStaff.profile_pic = response.data.profile_pic;
        setSingleStaff(_singleStaff);
        toast.success("Data Updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        document.getElementById("file").value = "";

        setFileUrl("");
        setShowCropModal(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      });
    setLoading(false);
  };

  const onSelectSignatureFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSignatureFileUrl(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setShowCropModalSignature(true);
    }
  };

  const onSignatureCrop = () => {
    // if (typeof cropperRef?.current?.getCroppedCanvas() === "undefined") {
    //   return;
    // }
    const imageElement = cropperRefSignature?.current;
    const cropper = imageElement?.cropper;

    setCroppedSignatureImage(
      cropper
        .getCroppedCanvas({
          width: 200,
          height: 300,
        })
        .toDataURL()
    );
  };

  const onSignatureUpload = async () => {
    setLoading(true);
    console.log(croppedSignatureImage);
    const blob = await fetch(croppedSignatureImage).then((r) => r.blob());
    const formData = new FormData();
    formData.append("signature", blob, "cropped-signature.jpg");
    await BaseApi()
      .post(`staff/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let _singleStaff = { ...singleStaff };
        _singleStaff.photo = response.data.photo;
        _singleStaff.profile_pic = response.data.profile_pic;
        _singleStaff.signature = response.data.signature;
        _singleStaff.signature_file = response.data.signature_file;

        setSingleStaff(_singleStaff);
        toast.success("Data Updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        document.getElementById("signatureFile").value = "";

        setSignatureFileUrl("");
        setShowCropModalSignature(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      });
    setLoading(false);
  };

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
    if (designations.length === 0) {
      dispatch(getDesignationsAsync());
    }
    getStaff();

    // setErrorMessage("Unauthorised Access");

    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getStaff() {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        let _singleStaff = data.data;
        setSingleStaff(_singleStaff);
        setInitialValues({
          id: id,
          unique_id: _singleStaff.unique_id,
          salutation: _singleStaff.salutation,
          name: _singleStaff.name,
          email: _singleStaff.email,
          blood_group: _singleStaff.blood_group,
          phone: _singleStaff.phone,
          fathers_name: _singleStaff.fathers_name,
          mothers_name: _singleStaff.mothers_name,
          address: _singleStaff.address,
          emergency_contact: _singleStaff.emergency_contact,
          category: _singleStaff.category,
          religion: _singleStaff.religion,
          gender: _singleStaff.gender,
          pwd: _singleStaff.pwd,
          type: _singleStaff.type,
          dob: _singleStaff.dob,

          dojoining: _singleStaff.dojoining,
          nature_of_employee: _singleStaff.nature_of_employee,
          pan: _singleStaff.pan,
          account_no: _singleStaff.account_no,
          ifsc: _singleStaff.ifsc,
          branch: _singleStaff.branch,
          bank: _singleStaff.bank,

          department_id: _singleStaff.department_id,
          designation_id: _singleStaff.designation_id,
        });
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  // const onEdit = (event, staff) => {
  //   event.preventDefault();
  //   setInitialValues({
  //     id: staff.id,
  //     name: staff.name,
  //     email: staff.email,
  //     phone: staff.phone,
  //   });

  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setFormRole("edit");
  // };

  const validationSchema = Yup.object().shape({
    // unique_id: Yup.string().nullable().required("This field is required!"),
    name: Yup.string().nullable().required("This field is required!"),
    email: Yup.string().when("type", {
      is: (type) => type !== "6",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(),
    }),
    phone: Yup.string().when("type", {
      is: (type) => type !== "6",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(),
    }),

    department_id: Yup.string().nullable().required("This field is required!"),
    designation_id: Yup.string().nullable().required("This field is required!"),
    // blood_group: Yup.string().nullable().required("This field is required!"),
    gender: Yup.string().nullable().required("This field is required!"),
    salutation: Yup.string().nullable().required("This field is required!"),
    type: Yup.string().nullable().required("This field is required!"),
    dob: Yup.string().nullable().required("This field is required!"),
    nature_of_employee: Yup.string()
      .nullable()
      .required("This field is required!"),
    photo: Yup.mixed()

      .test("fileSize", "File too large", (value) => {
        if (value) {
          return value.size <= 10000000;
        } else {
          return true;
        }
      })
      .test("fileFormat", "Unsupported File Format", (value) => {
        if (!value) {
          return true;
        }
        return value.type === "image/jpeg" || value.type === "image/png";
      }),
    // signature: Yup.mixed()

    // .test("fileSize", "File too large", (value) => {
    //   if (value) {
    //     return value.size <= 10000000;
    //   } else {
    //     return true;
    //   }
    // })
    // .test("fileFormat", "Unsupported File Format", (value) => {
    //   if (!value) {
    //     return true;
    //   }
    //   return value.type === "image/jpeg" || value.type === "image/png";
    // }),
  });

  const handleSubmit = async (payload) => {
    console.log("ad");
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);

    var form_data = new FormData();

    for (var key in payload) {
      if (payload[key] !== null) {
        form_data.append(
          key,

          payload[key]
        );
      }
    }

    if (formRole === "edit") {
      await BaseApi()
        .post(`staff/${payload.id}`, form_data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setSingleStaff(response.data);
          toast.success("Data Updated!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
          toast.error(message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        });
    }

    setLoading(false);
    setFormRole("edit");
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Edit Profile' />

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            {initialValues && (
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <h3 className='pt-6 text-xl'></h3>

                  <div className='grid grid-cols-4 gap-8'>
                    <Select name='type' label='Type*'>
                      <option value=''>Select Any One</option>

                      {authData.user.email == "establishmenta@dibru.ac.in" && (
                        <>
                          <option value='1'>Teaching</option>
                          <option value='3'>Officer</option>
                        </>
                      )}
                      {authData.user.email == "establishmentb@dibru.ac.in" && (
                        <>
                          <option value='2'>Employee (Grade III)</option>
                          <option value='6'>Employee (Grade IV)</option>
                        </>
                      )}
                      {authData.user.email != "establishmenta@dibru.ac.in" &&
                        authData.user.email != "establishmentb@dibru.ac.in" && (
                          <>
                            {" "}
                            <option value='1'>Teaching</option>
                            <option value='3'>Officer</option>
                            <option value='2'>Employee (Grade III)</option>
                            <option value='6'>Employee (Grade IV)</option>
                          </>
                        )}
                    </Select>
                    <Input
                      label='Employee ID No.*'
                      name='unique_id'
                      placeholder='Enter Employee ID No.'
                    />
                    <div></div>
                    <div></div>
                    <Select
                      label='Salutation*'
                      // value={authData.user?.salutation}
                      name='salutation'
                      placeholder='Salutation'
                    >
                      <option value=''>Select</option>
                      <option value='Prof.'>Prof.</option>
                      <option value='Dr.'>Dr.</option>
                      <option value='Mr.'>Mr.</option>
                      <option value='Mrs.'>Mrs.</option>
                      <option value='Ms.'>Ms.</option>
                    </Select>
                    <Input
                      label='Name*'
                      name='name'
                      placeholder='Enter Staff Name'
                    />
                    <Input
                      label="Father's Name"
                      name='fathers_name'
                      placeholder="Enter Father's Name"
                    />
                    <Input
                      label="Mother's Name"
                      name='mothers_name'
                      placeholder="Enter Mother's Name"
                    />
                    <Input
                      label='Email(Optional for Grave IV)'
                      name='email'
                      placeholder='Enter Email'
                    />
                    <Input
                      label='Phone(Optional for Grave IV)'
                      name='phone'
                      placeholder='Enter Phone'
                    />
                    {departments.length > 0 && (
                      <CustomSelect
                        options={departments}
                        name='department_id'
                        label='Select Department/Branch *'
                        isSearchable={true}
                        defaultInputValue={
                          departments.find(
                            (department) =>
                              department.id == initialValues.department_id
                          )?.name
                        }
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "department_id",
                            selectedOption?.id
                          );
                        }}
                      />
                    )}
                    {designations.length > 0 && (
                      <CustomSelect
                        options={
                          authData.user.email === "establishmentb@dibru.ac.in"
                            ? designations?.filter(
                                (d) => !d.name.toLowerCase().includes("officer")
                              )
                            : designations
                        }
                        name='designation_id'
                        label='Select Designation*'
                        isSearchable={true}
                        defaultInputValue={
                          designations.find(
                            (designation) =>
                              designation.id == initialValues.designation_id
                          )?.name
                        }
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "designation_id",
                            selectedOption?.id
                          );
                        }}
                      />
                    )}
                    <Select name='category' label='Caste'>
                      <option value=''>Select Any One</option>
                      <option value='GENERAL'>GENERAL</option>
                      <option value='OBC/MOBC'>OBC/MOBC</option>
                      <option value='SC'>SC</option>
                      <option value='ST-P'>ST-P</option>
                      <option value='ST-H'>ST-H</option>
                    </Select>
                    <Select name='religion' label='Religion'>
                      <option value=''>Select Any One</option>
                      <option value='Hindu'>Hindu</option>
                      <option value='Muslim'>Muslim</option>
                      <option value='Christians'>Christians</option>
                      <option value='Sikhs'>Sikhs</option>
                      <option value='Buddhists'>Buddhists</option>
                      <option value='Jains'>Jains</option>
                    </Select>
                    <Select name='gender' label='Gender*'>
                      <option value=''>Select Any One</option>
                      <option value='male'>Male</option>
                      <option value='female'>Female</option>
                    </Select>
                    <Select name='pwd' label='Pwd'>
                      <option value=''>Select Any One</option>
                      <option value='yes'>Yes</option>
                      <option value='no'>No</option>
                    </Select>
                    <Input label='Date of Birth*' type='date' name='dob' />
                    <Input
                      label='Date of Joining'
                      type='date'
                      name='dojoining'
                    />
                    <Select
                      name='nature_of_employee'
                      label='Nature of Employee*'
                    >
                      <option value=''>Select Any One</option>
                      <option value='Permanent'>Permanent</option>
                      <option value='Contractual'>Contractual</option>
                    </Select>

                    <Input
                      label='Pan Card No'
                      name='pan'
                      placeholder='Pan Card No'
                    />
                    <Input
                      label='Bank Account No.'
                      name='account_no'
                      placeholder='Bank Account No.'
                    />
                    {/* ifsc, branch, bank */}
                    <Input
                      label='IFSC Code'
                      name='ifsc'
                      placeholder='IFSC Code'
                    />
                    <Input label='Branch' name='branch' placeholder='Branch' />
                    <Input label='Bank' name='bank' placeholder='Bank' />

                    <Select
                      label='Blood Group'
                      name='blood_group'
                      placeholder='Blood Group'
                    >
                      <option value=''>Select</option>
                      <option value='A+'>A+</option>
                      <option value='A-'>A-</option>
                      <option value='B+'>B+</option>
                      <option value='B-'>B-</option>
                      <option value='AB+'>AB+</option>
                      <option value='AB-'>AB-</option>
                      <option value='O+'>O+</option>
                      <option value='O-'>O-</option>
                    </Select>
                    <Input
                      as='textarea'
                      label='Address'
                      name='address'
                      placeholder='Address'
                    />
                    <Input
                      label='Emergency Contact Number'
                      name='emergency_contact'
                      placeholder='Emergency Contact Number'
                    />

                    {/* <Input
                      label="Aadhaar Number"
                      name="aadhaar"
                      value={authData.user?.aadhaar}
                      placeholder="Enter Aadhar No."
                    /> */}

                    <div className='col-span-3'>
                      <div>
                        <img
                          style={{ width: "100px" }}
                          className='my-5 border-2 border-r-4 border-grey-400'
                          src={singleStaff.profile_pic}
                          onError={(e) => {
                            console.log(e.target.src);
                            if (
                              e.target.src !=
                                "https://ui-avatars.com/api/name=?" +
                                  singleStaff.name ??
                              "NO"
                            )
                              e.target.src =
                                "https://ui-avatars.com/api/?name=" +
                                  singleStaff.name ?? "NO";
                          }}
                        />
                        <label className='font-bold'>Upload Photo</label> <br />
                        <input
                          id='file'
                          type='file'
                          accept='image/*'
                          onChange={onSelectFile}
                        />
                        {showCropModal && (
                          <>
                            <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                              <div className='relative w-auto max-w-3xl mx-auto my-6'>
                                {/*content*/}
                                <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                                  {/*header*/}
                                  <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                                    <h3 className='text-3xl font-semibold'>
                                      Crop Image
                                    </h3>
                                    <button
                                      className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none'
                                      onClick={() => {
                                        document.getElementById("file").value =
                                          "";

                                        setFileUrl("");
                                        setShowCropModal(false);
                                      }}
                                    >
                                      <span className='block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none'>
                                        ×
                                      </span>
                                    </button>
                                  </div>
                                  {/*body*/}
                                  <div className='relative grid gap-8 p-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3'>
                                    <div className='col-span-2'>
                                      <span className='mb-5 text-grey-700'>
                                        Crop your image here: <br />
                                      </span>
                                      <Cropper
                                        viewMode={1}
                                        src={fileUrl}
                                        style={{ height: 200, width: "100%" }}
                                        initialAspectRatio={0.66666666666}
                                        guides={true}
                                        cropBoxResizable={false}
                                        cropBoxMovable={false}
                                        aspectRatio={0.66666666666}
                                        crop={onCrop}
                                        ref={cropperRef}
                                        center='true'
                                        dragMode={"move"}
                                      />
                                    </div>
                                    {croppedImage && (
                                      <div>
                                        <span className='mb-2 text-grey-700'>
                                          Preview: <br />
                                        </span>
                                        <img
                                          className='border-2 border-r-4 '
                                          src={croppedImage}
                                          alt='Cropped'
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {/*footer*/}
                                  <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
                                    <button
                                      className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                                      type='button'
                                      onClick={() => {
                                        document.getElementById("file").value =
                                          "";

                                        setFileUrl("");
                                        setShowCropModal(false);
                                      }}
                                    >
                                      Close
                                    </button>
                                    <button
                                      className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none'
                                      type='button'
                                      onClick={loading ? null : onUpload}
                                    >
                                      {loading
                                        ? "Uploading..."
                                        : "Crop and Upload"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
                          </>
                        )}
                      </div>{" "}
                    </div>
                    <div className='col-span-3'>
                      <div>
                        <img
                          style={{ width: "400px", height: "100px" }}
                          className='my-5 border-2 border-r-4 border-grey-400'
                          src={singleStaff.signature_file}
                          onError={(e) => {
                            console.log(e.target.src);
                            if (e.target.src != "/assets/no-sign.jpg")
                              e.target.src = "/assets/no-sign.jpg";
                          }}
                        />
                        <label className='font-bold'>Upload Signature</label>{" "}
                        <br />
                        <input
                          id='signatureFile'
                          type='file'
                          accept='image/*'
                          onChange={onSelectSignatureFile}
                        />
                        {showCropModalSignature && (
                          <>
                            <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                              <div className='relative w-auto max-w-3xl mx-auto my-6'>
                                {/*content*/}
                                <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                                  {/*header*/}
                                  <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                                    <h3 className='text-3xl font-semibold'>
                                      Crop Image
                                    </h3>
                                    <button
                                      className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none'
                                      onClick={() => {
                                        document.getElementById(
                                          "signatureFile"
                                        ).value = "";

                                        setSignatureFileUrl("");
                                        setShowCropModalSignature(false);
                                      }}
                                    >
                                      <span className='block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none'>
                                        ×
                                      </span>
                                    </button>
                                  </div>
                                  {/*body*/}
                                  <div className='relative grid gap-8 p-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3'>
                                    <div className='col-span-2'>
                                      <span className='mb-5 text-grey-700'>
                                        Crop your signature here: <br />
                                      </span>
                                      <Cropper
                                        viewMode={1}
                                        src={signatureFileUrl}
                                        style={{ height: 200, width: "100%" }}
                                        initialAspectRatio={4}
                                        guides={true}
                                        cropBoxResizable={false}
                                        cropBoxMovable={false}
                                        aspectRatio={4}
                                        crop={onSignatureCrop}
                                        ref={cropperRefSignature}
                                        center='true'
                                        dragMode={"move"}
                                      />
                                    </div>
                                    {croppedSignatureImage && (
                                      <div>
                                        <span className='mb-2 text-grey-700'>
                                          Preview: <br />
                                        </span>
                                        <img
                                          className='border-2 border-r-4 '
                                          src={croppedSignatureImage}
                                          alt='Cropped'
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {/*footer*/}
                                  <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
                                    <button
                                      className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                                      type='button'
                                      onClick={() => {
                                        document.getElementById(
                                          "signatureFile"
                                        ).value = "";

                                        setSignatureFileUrl("");
                                        setShowCropModalSignature(false);
                                      }}
                                    >
                                      Close
                                    </button>
                                    <button
                                      className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none'
                                      type='button'
                                      onClick={
                                        loading ? null : onSignatureUpload
                                      }
                                    >
                                      {loading
                                        ? "Uploading..."
                                        : "Crop and Upload"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
                          </>
                        )}
                      </div>{" "}
                    </div>
                  </div>
                  <Input type='hidden' name='admin' value='1' />
                  {/* <Input label="Signature" type="file" name="signature" />   */}

                  <div className='mt-5 text-center'>
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        type='submit'
                      />
                    ) : (
                      <>
                        <Button
                          value={loading ? "Updating..." : "Update"}
                          icon={"SaveIcon"}
                          type='submit'
                        />
                        <span className='mr-5'></span>
                      </>
                    )}
                  </div>
                </Form>
              </Formik>
            )}
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
