import React from "react";
import moment from "moment"; // Import moment for date formatting
import Button from "../../../Components/Utils/Button";
import tableToCSV from "../../../Components/Utils/TableToCSV";

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const isDate = (value) => {
  // Regex to match typical date formats e.g., 01/01/2000, 2000-01-01, etc.
  const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$|^\d{4}-\d{1,2}-\d{1,2}$/;
  return dateRegex.test(value) && !isNaN(Date.parse(value));
};
const formatDate = (value, format = "DD/MM/YYYY") => {
  // Format the date using moment
  return moment(value).format(format);
};

const TableFacultyIqac = ({ headers, data }) => {
  console.log(data);

  console.log(headers);
  const headerValues = Object.values(headers);

  return (
    <>
      <div className='pb-10 mt-4 overflow-x-auto'>
        <Button
          classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
          value={"Download CSV"}
          onClick={() => tableToCSV("datatable")}
          icon={"DownloadIcon"}
        />

        {data.length ? (
          <table id='datatable' className='min-w-full divide-y divide-gray-200'>
            <thead className=''>
              <tr className=''>
                <th className='px-4 py-3 text-xs font-medium tracking-wider text-left text-blue-500 uppercase'>
                  #
                </th>
                {headerValues.map((header, index) => (
                  <th
                    key={index}
                    className='px-4 py-3 text-xs font-medium tracking-wider text-left text-blue-500 uppercase whitespace-nowrap'
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className='px-4 py-1 text-sm text-gray-900'>
                    {rowIndex + 1}
                  </td>
                  {headerValues.map((header, cellIndex) => (
                    <td
                      key={cellIndex}
                      className='px-4 py-1 text-sm text-gray-900'
                    >
                      {
                        // Check if the value is a date and format it
                        isDate(getValueByPath(row, header.path))
                          ? formatDate(getValueByPath(row, header.path))
                          : getValueByPath(row, header.path)
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className='py-4 text-center text-gray-500'>No data found</p>
        )}
      </div>
    </>
  );
};

export default TableFacultyIqac;
