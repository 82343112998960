import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";

// import Input from "../../Components/Utils/Input";
 import BaseApi from "../../Http/BaseApi";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Button from "../../Components/Utils/Button";
import messageSlice from "../../Redux/MessageSlice";
  
export default function MakeHostelTransfer({
  onMakeFeeCollection,
  selectedIds,
  hostels,
  academic_session_id,
  setShowModal,
}) {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

 
   const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();

  const dispatch = useDispatch();
   console.log(academic_session_id);
  useEffect(() => {
    console.log("called again");
    dispatch(messageSlice.actions.clearMessage());
 

    setInitialValues({
      selectedIds: selectedIds,
      hostel_id: "",
     
      
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);
 

  const validationSchema = Yup.object().shape({
    // selectedIds: Yup.string().required("This field is required!"),
    hostel_id: Yup.string().required("This field is required!"),
 
  });

  const handleSubmit = async (payload) => {
    console.log(payload);
    setLoading(true);
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);

    await BaseApi().post(`make-hostel-transfer`, payload);
    setLoading(false);

    setInitialValues({
      selectedIds: selectedIds,
      hostel_id: "",
      
    });
    onMakeFeeCollection();
    setShowModal(false);
  };

   console.log(initialValues);
 
  console.log(message);
  return (
    <>
      <div className='justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative   w-auto my-6 mx-auto max-w-3xl'>
          {/*content*/}

          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-3xl font-semibold'>
                Hostel Boarder Selected: {" "}{selectedIds.length}
              </h3>
            </div>
            {/*body*/}
            <p style={{ width: "700px" }}></p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className='relative p-6 flex-auto'>
                    <div className='grid grid-cols-2 gap-4'>
                      <div className=''>
                        {hostels?.length > 0 && (
                            <CustomSelect
                            options={hostels}
                            name="hostel_id"
                            label="Select Hostel to be transfered:"
                            isSearchable={true}
                            getOptionValue={(option) => `${option}`}
                            getOptionLabel={(option) => `${option.name} `}
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "hostel_id",
                                selectedOption.id
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                   
                    <p className='my-4 text-red-500 text-sm leading-relaxed'>
                      
                    Hostel boarders with an <b>in date</b> specified will have their <b>out date</b> updated to today&apos;s date, while those without an <b>in date</b> will be marked as <b>seat not taken</b>.
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    
                    <Button
                      disabled={loading}
                      value={loading ? "Saving..." : "Transfer"}
                      icon={"SaveIcon"}
                      type='submit'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
}
