import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// import { Form, Formik } from "formik";
// import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
// import ActionConfirm from "../../Components/Utils/ActionConfirm";
// import Select from "../../Components/Utils/Select";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

// import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import EventCalendar from "react-event-calendar";

export default function HolidayCalendar() {
  const localizer = momentLocalizer(moment);

  const dispatch = useDispatch();
  // const formikRef = useRef();
  const [date, setDate] = useState(new Date());

  // const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  const [errorMessage, setErrorMessage] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // const events1 = [
  //   {
  //     id: 1,
  //     title: "Event 1",
  //     start: new Date(),
  //     end: new Date(new Date().getTime() + 60 * 60 * 1000),
  //   },
  //   {
  //     id: 2,
  //     title: "Event 2",
  //     start: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
  //     end: new Date(
  //       new Date().getTime() + 2 * 24 * 60 * 60 * 1000 + 60 * 60 * 1000
  //     ),
  //   },
  //   // ...
  // ];

  // const [initialValues, setInitialValues] = useState();
  //   const dispatch = useDispatch();
  useEffect(() => {
    // setInitialValues({
    //   year: "",
    // });
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    getHolidayCalendar({ year: new Date().getFullYear() });
    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  // const validationSchema = Yup.object().shape({
  //   year: Yup.string().nullable().required("This field is required!"),
  // });

  async function getHolidayCalendar(searchQuery) {
    console.log("as");

    await BaseApi()
      .get(`holiday-calendars`, { params: searchQuery })
      .then((data) => {
        setEvents(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
  }

  const customDayPropGetter = (date) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return {
        className: "weekend-day",
      };
    }
    return {};
  };
  // const events1 = [
  //   {
  //     start: "2015-07-20",
  //     end: "2015-07-02",
  //     eventClasses: "optionalEvent",
  //     title: "test event",
  //     description: "This is a test description of an event",
  //   },
  //   {
  //     start: "2015-07-19",
  //     end: "2015-07-25",
  //     title: "test event",
  //     description: "This is a test description of an event",
  //     data: "you can add what ever random data you may want to use later",
  //   },
  // ];

  // const handleDelete = async (event, payload) => {
  //   event.target.disabled = true;
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (deleteConfirm.state == 1) {
  //     await BaseApi()
  //       .delete(`staff/${payload.id}`)
  //       .then((data) => {
  //         console.log(data.data.id);
  //         let _staff = staff.filter((s) => s.id != data.data.id);
  //         console.log(_staff);
  //         setStaff(_staff);
  //       })
  //       .catch((error) => {
  //         const message =
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //           error.message ||
  //           error.toString();
  //         setErrorMessage(message);
  //       });
  //   }
  //   event.target.disabled = false;
  //   setDeleteConfirm({});
  // };
  const handleDeactivate = async (event, payload) => {
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deactivateConfirm.state == 1) {
      await BaseApi()
        .post(`staff/change-status/${payload.id}`)
        .then((data) => {
          console.log(data.data.id);
          let _staff = [...events];

          const index = _staff.findIndex((s) => s.id === data.data.id);
          _staff[index] = data.data;
          setEvents(_staff);
          setDeactivateConfirm({});
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        });
    }
    event.target.disabled = false;
    // setDeleteConfirm({});
  };
  console.log(handleDeactivate);
  // const handleCheckbox = (event) => {
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     setSelectedIds([...selectedIds, value]);
  //   } else {
  //     var index = selectedIds.indexOf(value);
  //     if (index != -1) {
  //       setSelectedIds(selectedIds.filter((item) => item !== value));
  //     }
  //   }
  // };

  // console.log(selectedIds);
  // const handleBulkDelete = async (event) => {
  //   event.preventDefault();
  //   event.target.disabled = true;

  //   await BaseApi()
  //     .post(`staff/bulk-delete`, selectedIds)
  //     .then((response) => {
  //       let _staff = staff.filter((s) => {
  //         return !response.ids.includes(s.id.toString());
  //       });
  //       setStaff(_staff);
  //     });

  //   setSelectedIds([]);
  //   event.target.disabled = false;
  // };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Holiday Calendar' />

            {/* <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={getHolidayCalendar}
            >
              <Form>
                <div className="grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                  <Select name="year" label="Select Year">
                    <option value="">Select Any One</option>
                    <option value="2022">2023</option>
                    <option value="2023">2024</option>
                  </Select>
                  <div className="mt-5 text-left">
                    <button
                      type="submit"
                      className="relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik> */}

            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
              <div>
                <Calendar
                  localizer={localizer}
                  events={events}
                  views={["month"]}
                  defaultView='month'
                  onNavigate={setDate}
                  date={date}
                  style={{ height: 500 }}
                  onSelectEvent={(event) => setSelectedEvent(event)}
                  dayPropGetter={customDayPropGetter}
                />
                {selectedEvent && (
                  <div className='fixed inset-x-0 bottom-0 z-50 px-4 py-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center'>
                    <div className='fixed inset-0 transition-opacity'>
                      <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
                    </div>
                    <div className='relative px-4 pt-5 pb-4 overflow-hidden transition-all transform bg-white rounded-lg shadow-xl sm:max-w-lg sm:w-full sm:p-6'>
                      <div>
                        <div className='flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full'>
                          <svg
                            className='w-6 h-6 text-red-600'
                            stroke='currentColor'
                            fill='none'
                            viewBox='0 0 24 24'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                            />
                          </svg>
                        </div>
                        <div className='mt-3 text-center sm:mt-5'>
                          <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {selectedEvent.title}
                          </h3>
                          <div className='mt-2'>
                            <p className='text-sm leading-5 text-gray-500'>
                              {selectedEvent.type == "Restricted" &&
                                "Restricted Holiday"}
                              {selectedEvent.type != "Restricted" && "Holiday"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='mt-5 sm:mt-6'>
                        <span className='flex w-full rounded-md shadow-sm'>
                          <button
                            onClick={() => setSelectedEvent(null)}
                            type='button'
                            className='relative flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-white bg-red-600 border border-transparent rounded-md hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-800'
                          >
                            Close
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <EventCalendar
                month={7}
                year={2015}
                events={events1}
                onEventClick={(target, eventData, day) =>
                  console.log(eventData, day)
                }
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
