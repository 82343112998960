import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  institute_organization: {
    label: "Institute/Organization",
    path: "institute_organization",
  },
  department: { label: "Department", path: "department" },
  organization_master_id: {
    label: "Organization Type",
    path: "organization_master.name",
  },
  organization_url: { label: "Organization URL", path: "organization_url" },
  designation: { label: "Designation", path: "designation" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const Experience = () => {
  const [experiences, setExperiences] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/experiences");
      setExperiences(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Experience
      </h2>
      <TableViewProfile
        headers={headers}
        data={experiences}
        type='experiences'
        setData={setExperiences}
      />
    </div>
  );
};

export default Experience;
