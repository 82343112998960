import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../Redux/MasterSlice";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import can from "../../Middleware/Permission";
import messageSlice from "../../Redux/MessageSlice";
// import Input from "../../../Components/Utils/Input";

// import Input from "../../../Components/Utils/Input";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function IdCardMaster() {
  const formikRef2 = useRef();

  const fk2_prog_reference = useRef();
  const fk2_aca_reference = useRef();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [idCardMasters, setIdCardMasters] = useState();
  // const [selectedPayment, setSelectedPayment] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [formRole, setFormRole] = useState("add");
  const [formValues, setFormValues] = useState();

  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  // let formSections = useSelector((state) => state.applicants.formSections);

  useEffect(() => {
    setFormValues({
      id: null,
      programme_id: null,

      academic_session_id: "",
      valid_upto: "",
    });
    callIdCardMasters();
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }

    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const formValidationSchema = Yup.object().shape({
    valid_upto: Yup.string().nullable().required("This field is required!"),
    programme_id: Yup.string().nullable().required("This field is required!"),
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });

  //   const dispatch = useDispatch();

  async function callIdCardMasters() {
    console.log("as");
    setLoading(true);
    const _idCardMasters = await BaseApi().get(`id-card-masters`);
    setLoading(false);
    setIdCardMasters(_idCardMasters.data);
  }

  const handleDelete = async (event, payload) => {
    console.log(payload);
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`id-card-masters/${payload.id}`)
        .then(() => {
          let _idCardMasters = idCardMasters.filter((p) => p.id != payload.id);
          console.log(_idCardMasters);
          setIdCardMasters(_idCardMasters);
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        });
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  const handleSubmit = async (payload) => {
    setErrorMessage(null);
    dispatch(messageSlice.actions.clearMessage());
    setSaving(true);
    console.log(formRole);
    if (formRole === "edit") {
      await BaseApi()
        .post(`id-card-masters/${payload.id}`, payload)
        .then((p) => {
          let _idCardMasters = [..._idCardMasters];
          const index = _idCardMasters.findIndex((s) => s.id === p.data.id);
          _idCardMasters[index] = p.data;
          setIdCardMasters(_idCardMasters);
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
          setSaving(false);
        });

      // await dispatch(updatePaymentGroupAsync(payload)).unwrap();
    } else {
      await BaseApi()
        .post(`id-card-masters`, payload)
        .then((p) => {
          let _idCardMasters = [...idCardMasters];
          _idCardMasters.push(p.data);

          setIdCardMasters(_idCardMasters);
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
          setSaving(false);
        });
      // await dispatch(addPaymentGroupAsync(payload)).unwrap();
    }

    setSaving(false);
    setFormRole("add");
  };
  return (
    <>
      <div className="py-2">
        <div className="mx-auto container bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5 overflow-x-scroll ">
            <PageTitle name="ID Card Validity List" />
            <div className="">
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            {loading == true && (
              <>
                <div className="flex">
                  <span className="m-auto">
                    <ThreeDots
                      height="100"
                      width="100"
                      color="grey"
                      ariaLabel="loading"
                    />
                  </span>
                </div>
              </>
            )}

            <Formik
              enableReinitialize={true}
              innerRef={formikRef2}
              initialValues={formValues}
              validationSchema={formValidationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <h3 className="font-medium text-lg">Add New Record</h3>
                <table
                  id="datatable"
                  className="min-w-full  mt-0 bg-white dark:bg-gray-800"
                >
                  <tr>
                    <th className="table-head w-80">
                      <CustomSelect
                        reference={fk2_prog_reference}
                        options={programmes}
                        name="programme_id"
                        label="Select Programme"
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name} `}
                        onChange={(selectedOption) => {
                          console.log("changed");
                          console.log(selectedOption);
                          if (selectedOption != null) {
                            formikRef2.current?.setFieldValue(
                              "programme_id",
                              selectedOption.id
                            );
                          }
                        }}
                      />
                    </th>
                    <th className="table-head">
                      <CustomSelect
                        reference={fk2_aca_reference}
                        options={academicSessions}
                        name="academic_session_id"
                        label="Select Academic Session"
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          if (selectedOption != null) {
                            formikRef2.current?.setFieldValue(
                              "academic_session_id",
                              selectedOption.id
                            );
                          }
                        }}
                      />
                    </th>

                    <th className="table-head w-80">
                      <Input name="valid_upto" type="date" label="Valid Upto" />
                    </th>
                    <th className="table-head w-32">
                      <div className="text-left mt-5">
                        {formRole === "add" ? (
                          <Button
                            value={saving ? "Saving..." : "Save"}
                            icon={"SaveIcon"}
                            type="submit"
                          />
                        ) : (
                          <Button
                            value={saving ? "Updating..." : "Update"}
                            icon={"SaveIcon"}
                            type="submit"
                          />
                        )}
                      </div>
                    </th>
                  </tr>
                </table>
              </Form>
            </Formik>
            {idCardMasters?.length > 0 && (
              <>
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label inline-block m-0   text-gray-700"
                >
                  Search By Programme Name
                </label>
                <input
                  className="  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               "
                  placeholder="Type here..."
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}
            <Button
              classNames="float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            <table
              id="datatable"
              className="min-w-full mt-5 bg-white dark:bg-gray-800"
            >
              <thead>
                <tr>
                  <th className="table-head">#</th>

                  <th className="table-head">Programme</th>
                  <th className="table-head">Session</th>
                  <th className="table-head">Valid Upto</th>

                  <th className="table-head">Action</th>
                </tr>
              </thead>
              <tbody>
                {idCardMasters?.length > 0 &&
                  idCardMasters
                    .filter((p) => {
                      if (searchInput.length > 0) {
                        return (
                          p.programme.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((card, index) => {
                      return (
                        <>
                          <tr>
                            <td className="table-data">{++index}</td>

                            <td className="table-data">
                              {card.programme?.name}
                            </td>
                            <td className="table-data">
                              {card.academic_session?.label}
                            </td>
                            <td className="table-data">
                              {moment(card.valid_upto).format("DD/MM/YYYY")}
                            </td>

                            <td className="table-data">
                              {can("Account Masters") &&
                                (deleteConfirm.state == 0 ||
                                  deleteConfirm.itemId != card.id) && (
                                  <button
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() =>
                                      setDeleteConfirm({
                                        itemId: card.id,
                                        state: 1,
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                )}
                              {deleteConfirm.state == 1 &&
                                deleteConfirm.itemId == card.id && (
                                  <>
                                    <p className="text-red-700">
                                      {" "}
                                      Are You Sure?{" "}
                                    </p>

                                    <button
                                      className="outline-button-danger"
                                      onClick={(event) =>
                                        handleDelete(event, card)
                                      }
                                    >
                                      Yes
                                    </button>
                                    <span className="mr-2"></span>
                                    <button
                                      className="outline-button-success"
                                      onClick={() => setDeleteConfirm({})}
                                    >
                                      No
                                    </button>
                                  </>
                                )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {idCardMasters?.length == 0 && (
              <div className="text-center my-10">No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
