import React, { useEffect, useRef, useState } from "react";
import Badge from "../../Components/Utils/Badge";
import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { OLD_STUDENTS_STATUS } from "./../../Enums/enums";
import PageTitle from "../../Components/Common/PageTitle";

import {
  // cancelOldStudentAsync,
  getOldStudentsAsync,
  rejectOldStudentAsync,
} from "../../Redux/OldStudentSlice";
import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../Redux/MasterSlice";
import Select from "../../Components/Utils/Select";
import CustomSelect from "../../Components/Utils/CustomSelect";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import RejectModal from "./RejectModal";
import { YearSemesters } from "../../Constants/YearSemesters";

export default function OldStudentIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const [isPHD, setIsPHD] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState();
  // const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];

  let oldStudents = useSelector((state) => state.oldStudents.oldStudents);
  const [searchParams] = useSearchParams();

  //   const dispatch = useDispatch();
  const initialValues = {
    programme_id: searchParams.get("programme_id") ?? "",
    present_sem: searchParams.get("present_sem") ?? "",
    academic_session_id: searchParams.get("academic_session_id") ?? "",
    status: searchParams.get("status") ?? "",
  };
  const [searchTerms] = useState(initialValues);

  useEffect(() => {
    console.log("call call");
    if (Array.from(searchParams.entries()).length == 0) {
      formikRef.current?.resetForm();
    }
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    if (
      (oldStudents.length === 0 &&
        Array.from(searchParams.entries()).length > 0) ||
      (oldStudents.length > 0 && Array.from(searchParams.entries()).length == 0)
    ) {
      dispatch(getOldStudentsAsync({ payload: initialValues }));
    }
  }, [searchParams]);

  const validationSchema = !isPHD
    ? Yup.object().shape({
        programme_id: Yup.string()
          .nullable()
          .required("This field is required!"),
        present_sem: Yup.string()
          .nullable()
          .required("This field is required!"),
        academic_session_id: Yup.string()
          .nullable()
          .required("This field is required!"),
      })
    : Yup.object().shape({
        programme_id: Yup.string()
          .nullable()
          .required("This field is required!"),
      });
  const handleSearch = async (searchQuery) => {
    setLoading(true);
    navigate({
      to: "old-students",
      search: createSearchParams(searchQuery).toString(),
    });
    await dispatch(getOldStudentsAsync({ payload: searchQuery }));

    setLoading(false);
  };
  const handleReject = (formValue) => {
    dispatch(rejectOldStudentAsync(formValue))
      .unwrap()
      .then(() => {
        setShowModal(false);
      });
  };

  // const handleCancelAdmission = (student) => {
  //   dispatch(cancelOldStudentAsync(student));
  // };

  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full overflow-x-scroll xl:overflow-x-hidden'>
            <PageTitle name='List of Students Applied' />
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={searchTerms}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
              onReset={() => {
                console.log("reset fone");
              }}
            >
              <Form>
                <div className=' p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8'>
                  <CustomSelect
                    options={programmes}
                    name='programme_id'
                    label='Select Programme'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption.id
                      );

                      if (selectedOption.programmetype_id == 2) {
                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          ""
                        );
                        formikRef.current?.setFieldValue("present_sem", "");
                        setIsPHD(true);
                      } else {
                        setIsPHD(false);
                      }
                    }}
                  />
                  {!isPHD && (
                    <>
                      <CustomSelect
                        options={YearSemesters}
                        name='present_sem'
                        label='Select Semester'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "present_sem",
                            selectedOption.value
                          );
                        }}
                      />

                      <CustomSelect
                        options={academicSessions}
                        name='academic_session_id'
                        label='Select Academic Session'
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.label} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "academic_session_id",
                            selectedOption.id
                          );
                        }}
                      />
                    </>
                  )}
                  <Select label='Select Status' id='status' name='status'>
                    <option value=''>Select One</option>
                    {Object.keys(OLD_STUDENTS_STATUS).length > 0 &&
                      Object.keys(OLD_STUDENTS_STATUS).map((key, index) => (
                        <option key={index} value={key}>
                          {OLD_STUDENTS_STATUS[key]}
                        </option>
                      ))}
                  </Select>
                  <div className='text-left mt-5'>
                    <button
                      type='submit'
                      className='group relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <table className='min-w-full bg-white dark:bg-gray-800'>
              <thead>
                <tr className='w-full h-16 border-gray-300 dark:border-gray-200 border-b py-8'>
                  <th className='pl-8 text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    SL
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Approve
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Reject
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Status
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Applied
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Name
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Present Sem.
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Guardian Name
                  </th>

                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Mobile Number
                  </th>
                  <th className='text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4'>
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {oldStudents.length > 0 &&
                  oldStudents.map((student, index) => (
                    <tr
                      key={student.id}
                      className='h-24 border-gray-300 dark:border-gray-200 border-b'
                    >
                      <td className='pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        {++index}
                      </td>
                      <td className='text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        {student.status == 0 && (
                          <Button
                            classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                            value={"Approve"}
                            icon={"CheckIcon"}
                            onClick={() => {
                              navigate(`/old-students/approve/${student.id}`);
                            }}
                          />
                        )}
                      </td>
                      <td className='text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        {student.status == 0 && (
                          <Button
                            classNames='bg-red-600 hover:bg-red-700 focus:ring-red-500'
                            value={"Reject"}
                            onClick={() => {
                              setShowModal(true);
                              setSelectedStudent(student);
                            }}
                            icon={"XIcon"}
                          />
                        )}

                        {/* {student.status == 1 && (
                          <Button
                            classNames="bg-red-600 hover:bg-red-700 focus:ring-red-500"
                            value={"Cancel Admission"}
                            onClick={() => {
                              if (window.confirm("Are you sure?") == true) {
                                handleCancelAdmission(student);
                              } else {
                                return;
                              }
                            }}
                            icon={"XIcon"}
                          />
                        )} */}
                      </td>
                      <td className='text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        <Badge status={OLD_STUDENTS_STATUS[student.status]} />

                        {student.active == 0 && (
                          <>
                            <br />
                            <small>Waiting to resubmit</small>
                          </>
                        )}
                      </td>
                      <td className='text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        {moment(student.applied_date).format("Do MMM YYYY")}
                      </td>
                      <td className='pr-6 whitespace-no-wrap'>
                        <div className='flex items-center'>
                          <div className='h-8 w-8'>
                            <img
                              src={student.profile_pic}
                              className='h-full w-full rounded-full overflow-hidden shadow'
                              onError={(e) => {
                                console.log(e.target.src);
                                if (e.target.src != "/assets/no-photo.jpg")
                                  e.target.src = "/assets/no-photo.jpg";
                              }}
                            />
                          </div>
                          <p className='ml-2 text-gray-800 dark:text-gray-100 tracking-normal leading-4 text-sm'>
                            {student.name} <br />
                          </p>
                        </div>
                      </td>
                      <td className='text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        {student.present_sem}
                      </td>
                      <td className='text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        {student.guardian}
                      </td>
                      <td className='text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4'>
                        {student.mobile}
                      </td>
                      <td className='pr-6'>
                        <Button
                          classNames='bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                          value={"Details"}
                          icon={"ChevronDoubleRightIcon"}
                          onClick={() => {
                            navigate(`/old-students/${student.id}`);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {oldStudents.length == 0 && (
              <div className='text-center my-10'>No Data</div>
            )}
          </div>
        </div>
      </div>
      {showModal ? (
        <RejectModal
          student={selectedStudent}
          handleReject={handleReject}
          setShowModal={setShowModal}
          loading={loading}
        />
      ) : null}
    </>
  );
}
