import moment from "moment";
import React, { useEffect, useState } from "react";
// import moment from "moment";

export default function SelectLeaveDatesModal({
  data,
  setShowDatesModal,
  handleLeaveDateChange,
  setHolidayIncluded,
}) {
  const [localData, setLocalData] = useState(data);
  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleCheckboxChange = (index) => {
    console.log(index);
    const newLeaveDates = localData.leave_dates.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          apply_leave:
            item.status == "Eligible" ? item.apply_leave : !item.apply_leave,
        };
      }
      return item;
    });
    console.log(newLeaveDates);
    setLocalData({ ...data, leave_dates: newLeaveDates });
  };

  return (
    <>
      <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative max-w-4xl mx-auto my-6 lg:w-3/6'>
          {/*content*/}
          <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
              <h3 className='text-3xl font-semibold'>
                Include Saturday/Sunday/Holidays in Leave
              </h3>
              <button className='float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none'>
                <span className='block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none'>
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className='relative flex-auto p-4'>
              <div className='my-1 text-sm font-bold text-center '>
                From : {moment(localData.from_date).format("DD/MM/YYYY")} To :{" "}
                {moment(localData.to_date).format("DD/MM/YYYY")}
                <table className='w-full mt-10'>
                  <tbody>
                    {localData.leave_dates.map((leaveDate, index) => (
                      <tr key={index}>
                        <td className='table-data'>
                          {moment(leaveDate.date).format("DD/MM/YYYY")}
                        </td>
                        <td className='table-data'>
                          {leaveDate.status != "Eligible" && leaveDate.status}
                        </td>
                        <td className='table-data'>
                          <input
                            type='checkbox'
                            checked={leaveDate.apply_leave || false}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/*footer*/}
            <div className='flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200'>
              <button
                onClick={() => setShowDatesModal(false)}
                className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear '
                type='button'
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleLeaveDateChange(localData.leave_dates);
                  setShowDatesModal(false);
                  setHolidayIncluded(true);
                }}
                className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none'
                type='button'
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
    </>
  );
}
