import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHeadsAsync } from "../../../Redux/MasterSlice";

export default function PaymentGroupShow({ paymentGroup, setShowModal }) {
  const dispatch = useDispatch();

  const master = useSelector((state) => state.master);
  // const paymentGroups = [...master.paymentGroups];
  const paymentHeads = [...master.paymentHeads];
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  useEffect(() => {
    if (paymentHeads.length === 0) {
      dispatch(getPaymentHeadsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  console.log(paymentGroup);
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Payment Group Details</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-black text-lg leading-relaxed">
                Group Name: &nbsp;
                {paymentGroup.name}
              </p>

              <table className="min-w-full   bg-white ">
                <thead>
                  <tr>
                    <th className={thClass}>#</th>
                    <th className={thClass}>Head</th>
                    <th className={thClass}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentGroup.payment_group_heads
                    .filter(
                      (detail) => detail.amount != null && detail.amount > 0
                    )
                    .map((detail, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className={tdClass}>{++index}</td>
                            <td className={tdClass}>
                              {
                                paymentHeads.find(
                                  (head) => head.id == detail.payment_head_id
                                )?.name
                              }
                            </td>
                            <td className={tdClass}>{detail.amount}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <th className={thClass}></th>
                    <th className={thClass}>Total</th>
                    <th className={thClass}>
                      {paymentGroup?.payment_group_heads?.reduce(
                        (partialSum, detail) =>
                          partialSum + (parseInt(detail.amount) || 0),
                        0
                      )}
                    </th>
                  </tr>
                </tfoot>
              </table>
              <p className="my-4 text-slate-500 text-lg leading-relaxed"></p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              {/* <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Submit
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
