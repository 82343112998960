import React, { useState } from "react";
import PresentResearchScholar from "./PresentResearchScholar/PresentResearchScholar";
import PhdAwardee from "./PhdAwardee/PhdAwardee";
import ExternalPhdScholar from "./ExternalPhdScholar/ExternalPhdScholar";
import ThesisExaminerUniversitie from "./ThesisExaminerUniversitie/ThesisExaminerUniversitie";

const PhdGuidance = () => {
  const [activeTab, setActiveTab] = useState(0); // Set initial activeTab to 0

  const tabs = [
    {
      id: 0,
      label: "Present Research Scholars",
      content: <PresentResearchScholar />,
    },
    {
      id: 1,
      label: "Ph.D Awardees",
      content: <PhdAwardee />,
    },
    {
      id: 2,
      label: "External Ph. D. Scholars",
      content: <ExternalPhdScholar />,
    },
    {
      id: 3,
      label: "Thesis Examiner of other Universities",
      content: <ThesisExaminerUniversitie />,
    },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="relative max-w-full mx-auto">
      <div className="flex w-full overflow-x-auto border-b border-gray-200">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`${
              activeTab === tab.id
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-500"
            } focus:outline-none py-2 px-4 mx-2`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-4">
        {tabs.map((tab) => (
          <div key={tab.id}>
            {activeTab === tab.id && (
              <>
                <p>{tab.content}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhdGuidance;
