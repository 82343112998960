import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Select from "../../Components/Utils/Select";
import { FaArrowRight, FaEnvelope, FaPlus } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import BaseApi from "../../Http/BaseApi";
import { toast } from "react-toastify";

export default function StaffSelectionModal({
  isOpen,
  closeModal,
  loading,
  staffResults,
  selectedStaffIds,
  toggleStaffSelection,
  setStaffResults,
  setSelectedStaffIds,
  departments,
  selectedNotice,
  isAddMore,
  getNotices = () => {},
}) {
  const formikRef = useRef();
  const [toastMessage, setToastMessage] = useState(""); // State to store the toast message
  const [totalAddedStaff, setTotalAddedStaff] = useState(0); // State to store total staff count added to the list

  const [selectAll, setSelectAll] = useState(false); // State to manage "Select All"

  useEffect(() => {
    if (selectedNotice) {
      setSelectedStaffIds([]);
      setTotalAddedStaff(0);
    }
  }, [selectedNotice]);
  useEffect(() => {
    setTotalAddedStaff(selectedStaffIds.length);
  }, []);

  const handleAddToList = () => {
    const newlySelectedStaffIds = staffResults
      .filter((staff) => selectedStaffIds.includes(staff.id))
      .map((staff) => staff.id);

    const uniqueSelectedStaffIds = new Set([
      ...selectedStaffIds,
      ...newlySelectedStaffIds,
    ]);

    // Update the selected staff IDs list and show toast notification
    setSelectedStaffIds(Array.from(uniqueSelectedStaffIds));

    // Update the total added staff count
    setTotalAddedStaff(uniqueSelectedStaffIds.size);

    // Set the toast message with the count of newly added staff
    setToastMessage(
      `${newlySelectedStaffIds.length} staff added to the list. Total: ${uniqueSelectedStaffIds.size}`
    );

    // Hide the toast after 3 seconds
    setTimeout(() => {
      setToastMessage("");
    }, 3000);
  };

  const handleSelectAll = (filteredStaff) => {
    const newStaffIds = filteredStaff.map((staff) => staff.id);
    if (selectAll) {
      // Deselect all filtered staff
      const remainingStaffIds = selectedStaffIds.filter(
        (id) => !newStaffIds.includes(id)
      );
      setSelectedStaffIds(remainingStaffIds);
    } else {
      // Select all filtered staff
      const updatedStaffIds = Array.from(
        new Set([...selectedStaffIds, ...newStaffIds])
      );
      setSelectedStaffIds(updatedStaffIds);
    }
    setSelectAll(!selectAll);
  };

  const submitRecipients = async () => {
    if (!window.confirm("Are you sure you want to add these recipients?")) {
      return;
    }
    try {
      await BaseApi().post(
        `/notices/${selectedNotice.id}/add-more-recipients`,
        {
          selectedStaffIds,
        }
      );
      toast.success("Recipients added successfully");
      // Optionally, you can call a function to refresh the list or any other action here.
      getNotices();
      closeModal();
    } catch (error) {
      toast.error("Error adding recipients");
      console.error("Error adding recipients:", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} className='relative z-50'>
      <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

      <div className='fixed inset-0 flex items-center justify-center p-4'>
        <Dialog.Panel className='relative w-full p-6 bg-white rounded-lg max-w-8xl'>
          {/* Show total added staff at the top */}

          <XIcon
            onClick={closeModal}
            className='absolute w-6 h-6 text-red-500 cursor-pointer right-5 top-5'
          />

          <Formik
            initialValues={{ department_id: "", status: "", type: "" }}
            onSubmit={(values) => {}}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className='grid gap-8 mt-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4'>
                  {/* Type Dropdown */}
                  <div>
                    <p className='text-xl font-bold border-b'>
                      {selectedNotice.title}
                    </p>
                    <Dialog.Title className='font-bold text-md'>
                      Search and Add Staff
                    </Dialog.Title>
                  </div>

                  <Select
                    name='type'
                    label='Select Type*'
                    onChange={(e) => {
                      setFieldValue("type", e.target.value);
                      // Clear department_id if type is not Teacher

                      setFieldValue("department_id", "");
                    }}
                  >
                    <option value=''>All</option>
                    <option value='1'>Teacher</option>
                    <option value='3'>Officer</option>
                    <option value='2'>Employee (Grade III)</option>
                  </Select>

                  {/* Department Dropdown: Shown only if Type is "Teacher" */}
                  {values.type === "1" && (
                    <CustomSelect
                      options={departments}
                      name='department_id'
                      label='Select Department/Branch'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(selectedOption) => {
                        setFieldValue("department_id", selectedOption?.id);
                      }}
                    />
                  )}
                </div>

                {/* Staff Results - Scrollable */}
                <div className='mt-4 overflow-y-auto border-b border-t p-5  h-[60vh]'>
                  <h2 className='mb-4 text-lg font-semibold'>
                    Search Results (
                    {
                      staffResults.filter(
                        (staff) =>
                          // Filter by type
                          (values.type == "" || staff.type == values.type) &&
                          // Filter by department only if type is "Teacher" (type === "1")
                          (values.type != "1" ||
                            values.department_id == "" ||
                            values.department_id == null ||
                            staff.department_id == values.department_id)
                      ).length
                    }
                    )
                  </h2>

                  {/* Select All Checkbox */}
                  <label className='flex items-center space-x-2'>
                    <input
                      type='checkbox'
                      className='w-5 h-5 text-indigo-600 border-gray-300 rounded'
                      checked={selectAll}
                      onChange={() =>
                        handleSelectAll(
                          staffResults.filter(
                            (staff) =>
                              (values.type == "" ||
                                staff.type == values.type) &&
                              (values.type != "1" ||
                                values.department_id == "" ||
                                values.department_id == null ||
                                staff.department_id == values.department_id)
                          )
                        )
                      }
                    />
                    <span>Select All</span>
                  </label>

                  <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                    {staffResults
                      .filter(
                        (staff) =>
                          // Filter by type
                          (values.type == "" || staff.type == values.type) &&
                          // Filter by department only if type is "Teacher" (type === "1")
                          (values.type != "1" ||
                            values.department_id == "" ||
                            values.department_id == null ||
                            staff.department_id == values.department_id)
                      )

                      .map((staff) => (
                        <div
                          key={staff.id}
                          className='flex items-center justify-between p-4 bg-white rounded-lg shadow-md dark:bg-gray-800'
                        >
                          <div className='flex items-center space-x-3'>
                            <input
                              type='checkbox'
                              className='w-5 h-5 text-indigo-600 border-gray-300 rounded'
                              checked={selectedStaffIds.includes(staff.id)}
                              onChange={() => toggleStaffSelection(staff.id)}
                            />
                            <div>
                              <h3 className='text-base font-medium text-gray-900 dark:text-gray-100'>
                                {staff.name}
                              </h3>
                              <p className='text-sm text-gray-500 dark:text-gray-400'>
                                {staff.designation?.name}
                              </p>
                              <p className='text-sm text-gray-500 dark:text-gray-400'>
                                {staff.department?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* Add to List Button */}
                </div>
              </Form>
            )}
          </Formik>

          <div className='flex justify-between gap-5 mt-4'>
            <button
              className='px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600'
              onClick={handleAddToList}
            >
              Add to List
              <FaPlus className='inline w-4 h-4 ml-2' />
            </button>
            <div>
              <p className='mt-1 text-sm text-gray-600'>
                <span className='p-2 mr-10 font-semibold text-green-800 bg-green-100 rounded-md '>
                  Total recipients added to list: {totalAddedStaff}
                </span>
                <button
                  className='px-4 py-2 mr-4 font-medium text-white bg-red-500 rounded-md'
                  onClick={() => {
                    setSelectedStaffIds([]);
                    setTotalAddedStaff(0);
                  }} // Clear recipients
                >
                  Clear Recipients
                  <FaDeleteLeft className='inline w-4 h-4 ml-2' />
                </button>
                <button
                  disabled={totalAddedStaff == 0}
                  className='px-4 py-2 font-medium text-white bg-green-500 rounded-md '
                  onClick={async () => {
                    if (isAddMore) {
                      await submitRecipients();
                    } else {
                      closeModal();
                    }
                  }}
                >
                  {isAddMore && (
                    <>
                      Submit and Send Email
                      <FaEnvelope className='inline w-4 h-4 ml-2' />
                    </>
                  )}
                  {!isAddMore && (
                    <>
                      Next
                      <FaArrowRight className='inline w-4 h-4 ml-2' />
                    </>
                  )}
                </button>
              </p>
            </div>
          </div>

          {/* Toast Message */}
          {toastMessage && (
            <div className='absolute h-12 p-3 text-white bg-green-500 rounded-md shadow-lg bottom-5 top-10 right-5'>
              {toastMessage}
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
