import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";
import messageSlice from "../../Redux/MessageSlice";
import BaseApi from "../../Http/BaseApi";
import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Input from "../../Components/Utils/Input";
import { useParams } from "react-router-dom";
import can from "../../Middleware/Permission";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";
import moment from "moment";

export default function BudgetProposalEntry() {
  const { type } = useParams();
  // const { costCenter_id } = useParams();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [assignedHeads, setAssignedHeads] = useState([]);
  const [openBudgets, setOpenBudgets] = useState([]);
  const [proposals, setProposals] = useState([]);
  const master = useSelector((state) => state.master);
  const academicSessions = [...master.academicSessions];
  const authData = useSelector((state) => state.auth);
  const [budgets, setBudgets] = useState([]);

  const costCenters = authData.user?.cost_centers;

  const [selectedCostCenter, setSelectedCostCenter] = useState();

  const { message } = useSelector((state) => state.message);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    // getAssignedHeads();
    setSelectedCostCenter(null);
    setOpenBudgets([]);
    setAssignedHeads([]);

    setInitialValues({
      acc_budget_form_id: null,
      academic_session_id: null,
      type: type,
      cost_center_id: null,
      details: [],
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch, type]);
  let currentSession = academicSessions.find((e) => {
    return e.id == formikRef.current?.values?.academic_session_id;
  });

  let lastSession = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 1;
  });

  let last2Session = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 2;
  });
  const getAssignedHeads = async (acc_budget_master_id) => {
    console.log(1.1);
    await BaseApi()
      .get(
        `get-assigned-heads?acc_budget_master_id=${acc_budget_master_id}&cost_center_id=${
          selectedCostCenter.id ?? 0
        }&type=${type == "receipt" ? 1 : 2}`
      )
      .then(async (response) => {
        var heads = response.data;
        console.log(1.2);
        await heads.map((head) => {
          if (head.parent.parent) {
            if (!heads.some((h) => h.id == head.parent.parent.id))
              heads.push(head.parent.parent);
            delete head.parent.parent;
          }
          if (head.parent) {
            if (!heads.some((h) => h.id == head.parent.id))
              heads.push(head.parent);
            delete head.parent;
          }
        });

        console.log("Flat Heads", heads);
        console.log(1.3);
        setAssignedHeads(heads);
        console.log(1.4);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getOpenBudgets = async (cost_center_id) => {
    await BaseApi()
      .get(
        `get-open-budgets?cost_center_id=${cost_center_id}&type=${
          type == "receipt" ? 1 : 2
        }`
      )
      .then((response) => {
        setOpenBudgets(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
    cost_center_id: Yup.string().required("This field is required!"),
    acc_budget_form_id: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    dispatch(messageSlice.actions.clearMessage());
    // console.log(payload);
    setLoading(true);
    await BaseApi().post(`budget-proposal-entry`, payload);

    setLoading(false);
    // resetForm({ academic_session_id: "", details: [] });

    // setInitialValues({
    //   academic_session_id: "",
    //   type: type,
    //   details: [],
    // });
    dispatch(messageSlice.actions.setMessage("Saved As Draft"));
  };

  useEffect(() => {
    console.log("changed");
    // console.log(add);
    if (initialValues) {
      setInitialValues({
        acc_budget_form_id: initialValues.acc_budget_form_id,
        academic_session_id: initialValues.academic_session_id,
        type: initialValues.type,
        cost_center_id: initialValues.cost_center_id,
        details: [],
      });
    }
    assignedHeads.map((head) => {
      const exists = proposals.filter((e) => e.acc_payment_head_id === head.id);

      const lastYearBudexists = budgets.filter(
        (e) => e.acc_payment_head_id === head.id
      );
      console.log("old budgets", lastYearBudexists);
      if (exists != null && exists.length > 0 && initialValues) {
        // console.log();
        let copy = { ...initialValues };
        let details = copy.details;
        details.push({
          name: head.name,
          parent_id: head.acc_payment_head_id,
          acc_payment_head_id: head.id,
          budget_current: exists.find(
            (e) =>
              e.academic_session_id == currentSession.id && e.budget_type == 0
          )?.[
            type == "receipt"
              ? "proposed_amount_receipt"
              : "proposed_amount_payment"
          ],
          rev_last: exists.find(
            (e) => e.academic_session_id == lastSession.id && e.budget_type == 1
          )?.[
            type == "receipt"
              ? "proposed_amount_receipt"
              : "proposed_amount_payment"
          ],
          budget_last:
            exists.find(
              (e) =>
                e.academic_session_id == lastSession.id && e.budget_type == 0
            )?.[
              type == "receipt"
                ? "proposed_amount_receipt"
                : "proposed_amount_payment"
            ] ??
            lastYearBudexists.find(
              (e) =>
                e.academic_session_id == lastSession.id && e.budget_type == 0
            )?.[type == "receipt" ? "receipt_amount" : "payment_amount"],
          actual_prev: exists.find(
            (e) =>
              e.academic_session_id == last2Session.id && e.budget_type == 2
          )?.[
            type == "receipt"
              ? "proposed_amount_receipt"
              : "proposed_amount_payment"
          ],
          justification: exists.find(() => true).justification,
        });
        setInitialValues({
          name: initialValues.name,
          parent_id: initialValues.parent_id,
          acc_budget_form_id: initialValues.acc_budget_form_id,
          academic_session_id: initialValues.academic_session_id,
          type: initialValues.type,
          cost_center_id: selectedCostCenter.id,
          details,
        });
      } else if (initialValues) {
        // console.log("null");
        let copy = { ...initialValues };
        let details = copy.details;
        // console.log();
        details.push({
          name: head.name,
          parent_id: head.acc_payment_head_id,
          acc_payment_head_id: head.id,
          budget_current: null,
          rev_last: null,
          budget_last: lastYearBudexists.find(
            (e) => e.academic_session_id == lastSession.id && e.budget_type == 0
          )?.[type == "receipt" ? "receipt_amount" : "payment_amount"],
          actual_prev: null,
          justification: null,
        });
        setInitialValues({
          name: initialValues.name,
          parent_id: initialValues.parent_id,
          acc_budget_form_id: initialValues.acc_budget_form_id,
          academic_session_id: initialValues.academic_session_id,
          type: initialValues.type,
          cost_center_id: selectedCostCenter.id,
          details,
        });
      }
    });

    // dispatch(getOldStudentsAsync());
  }, [proposals, budgets]);

  const callProposals = async (selectedOption) => {
    // console.log(selectedOption);
    setLoading(true);
    const _proposals = await BaseApi()
      .get(`budget-proposals`, {
        params: {
          acc_budget_form_id: selectedOption.id,
          cost_center_id: selectedCostCenter.id,
        },
      })
      .catch(() => {
        setLoading(false);
      });

    setProposals(_proposals.data);

    setInitialValues({
      acc_budget_form_id: selectedOption.id,
      academic_session_id: selectedOption.academic_session_id,
      type: type,
      cost_center_id: selectedCostCenter.id,
      details: [],
    });
    setLoading(false);
  };
  console.log(costCenters);

  const callBudgets = async (
    current_acc_budget_form_id,
    previous_acc_budget_form_id,
    costCenter_id,
    academic_session_id
  ) => {
    setLoading(true);
    const _budgets = await BaseApi()
      .get(`budgets`, {
        params: {
          acc_budget_form_id: previous_acc_budget_form_id,
          cost_center_id: costCenter_id,
        },
      })
      .catch(() => {
        setLoading(false);
      });

    setBudgets(_budgets.data);

    setInitialValues({
      acc_budget_form_id: current_acc_budget_form_id,
      academic_session_id: academic_session_id,
      type: type,
      cost_center_id: costCenter_id,
      details: [],
    });
    setLoading(false);
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle
              name={
                "Budget " +
                (type == "receipt" ? "Receipt " : "Payment ") +
                "Entry"
              }
            />

            {/* <h2 className="ml-5 text-xl">
              {type == "receipt" ? "Revenue " : "Cost "} Center:{" "}
              {authData.user?.cost_center?.name} -
              {type == "receipt"
                ? authData.user?.cost_center?.revenue_center_code
                : authData.user?.cost_center?.cost_center_code}
            </h2> */}
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={costCenters}
                    name='cost_center_id'
                    label='Select Cost/Revenue Center'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option?.name}`}
                    onChange={async (selectedOption) => {
                      // console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "cost_center_id",
                        selectedOption.id
                      );
                      setSelectedCostCenter(selectedOption);
                      getOpenBudgets(selectedOption.id);

                      setProposals([]);
                      setAssignedHeads([]);
                    }}
                  />
                  {selectedCostCenter && (
                    <CustomSelect
                      key={type}
                      options={openBudgets}
                      name='acc_budget_form_id'
                      label='Select Budget Master'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) =>
                        `${option.budget_master?.name} - ${
                          option.academic_session?.label
                        } 
                      | Last Date:  ${moment(option.to_date).format(
                        "Do MMM YYYY"
                      )}
                      `
                      }
                      onChange={async (selectedOption) => {
                        // console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "acc_budget_form_id",
                          selectedOption.id
                        );

                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          selectedOption.academic_session_id
                        );

                        await getAssignedHeads(
                          selectedOption.acc_budget_master_id
                        );

                        await callProposals(selectedOption);
                        console.log(openBudgets);

                        await callBudgets(
                          selectedOption.id,
                          openBudgets.find((e) => e.id == selectedOption.id)
                            ?.previous_form?.id,
                          selectedCostCenter.id,
                          selectedOption.academic_session_id
                        );
                      }}
                    />
                  )}
                </div>

                {loading == true && (
                  <>
                    <div className='flex'>
                      <span className='m-auto'>
                        <ThreeDots
                          height='100'
                          width='100'
                          color='grey'
                          ariaLabel='loading'
                        />
                      </span>
                    </div>
                  </>
                )}
                <div className='grid grid-cols-12 gap-2 font-medium'>
                  <div className='col-span-6 sm:col-span-3'>
                    Head of Accounts
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Actual
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 2;
                      })?.label
                    }
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Budget
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 1;
                      })?.label
                    }{" "}
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Revised
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 1;
                      })?.label
                    }{" "}
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Budget {currentSession?.label}{" "}
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  {type == "payment" && (
                    <div className='col-span-6 sm:col-span-1'>
                      Justification
                    </div>
                  )}
                </div>

                {/* //////////////// */}

                {initialValues?.details
                  .filter((detail) => detail.parent_id == null)
                  ?.map((head) => {
                    return (
                      <>
                        {initialValues?.details.some(
                          (detail1) =>
                            detail1.parent_id == head.acc_payment_head_id
                        ) ? (
                          <div className='col-span-6 py-3 text-sm font-bold sm:col-span-3'>
                            {head.name}
                          </div>
                        ) : (
                          inputRow(
                            initialValues?.details.indexOf(head),
                            head,
                            type
                          )
                        )}

                        {initialValues?.details
                          .filter(
                            (detail1) =>
                              detail1.parent_id == head.acc_payment_head_id
                          )
                          ?.map((child) => {
                            console.log(child);
                            return (
                              <>
                                {initialValues?.details.some(
                                  (detail2) =>
                                    detail2.parent_id ==
                                    child.acc_payment_head_id
                                ) ? (
                                  <div className='col-span-6 py-3 text-sm font-medium sm:col-span-3'>
                                    &nbsp;&nbsp;{child.name}
                                  </div>
                                ) : (
                                  inputRow(
                                    initialValues?.details.indexOf(child),
                                    child,
                                    type
                                  )
                                )}
                                {initialValues?.details
                                  .filter(
                                    (detail2) =>
                                      detail2.parent_id ==
                                      child.acc_payment_head_id
                                  )
                                  ?.map((child1) => {
                                    console.log(child1);

                                    return (
                                      <>
                                        {inputRow(
                                          initialValues?.details.indexOf(
                                            child1
                                          ),
                                          child1,
                                          type
                                        )}
                                      </>
                                    );
                                  })}
                              </>
                            );
                          })}
                      </>
                    );
                  })}

                {currentSession &&
                  (proposals.length == 0 ||
                    proposals.filter((p) => p.draft == 1).length > 0) && (
                    <div className='mt-5 text-center'>
                      <button
                        className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gradient-to-r from-pink-500 to-yellow-500 active:bg-pink-600 hover:shadow-lg focus:outline-none'
                        type='submit'
                      >
                        Save as Draft
                      </button>
                    </div>
                  )}

                {currentSession &&
                  !can("Account Masters") &&
                  proposals.length > 0 &&
                  proposals.filter((p) => p.draft == 1).length == 0 && (
                    <div className='mt-5 text-center'>
                      <span className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gradient-to-r from-pink-500 to-yellow-500 active:bg-red-600 hover:shadow-lg focus:outline-none'>
                        Budget Submitted
                      </span>
                    </div>
                  )}

                {currentSession &&
                  can("Account Masters") &&
                  proposals.length > 0 &&
                  proposals.filter((p) => p.draft == 1).length == 0 && (
                    <div className='mt-5 text-center'>
                      <button
                        type='submit'
                        className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gradient-to-r from-pink-500 to-yellow-500 active:bg-red-600 hover:shadow-lg focus:outline-none'
                      >
                        Edit Budget
                      </button>
                    </div>
                  )}
              </Form>
            </Formik>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
function inputRow(index, child, type) {
  return (
    <div key={index} className='grid grid-cols-12 gap-2'>
      <div className='col-span-6 py-3 pl-3 text-sm sm:col-span-3'>
        {child.name}
      </div>
      <div className='col-span-6 sm:col-span-2'>
        <Input
          type='number'
          readOnly={true}
          label=''
          name={`details[${index}].actual_prev`}
        />
      </div>
      <div className='col-span-6 sm:col-span-2'>
        <Input
          type='number'
          readOnly={true}
          label=''
          name={`details[${index}].budget_last`}
        />
      </div>
      <div className='col-span-6 sm:col-span-2'>
        <Input type='number' label='' name={`details[${index}].rev_last`} />
      </div>
      <div className='col-span-6 sm:col-span-2'>
        <Input
          type='number'
          label=''
          name={`details[${index}].budget_current`}
        />
      </div>
      {type == "payment" && (
        <div className='col-span-6 sm:col-span-1'>
          <Input
            type='text'
            label=''
            name={`details[${index}].justification`}
            placeholder='Optional'
          />
        </div>
      )}
    </div>
  );
}
