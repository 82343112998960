import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import Input from "../../Components/Utils/Input";
import Select from "../../Components/Utils/Select";
import {
  approveOldStudentAsync,
  showOldStudentAsync,
} from "../../Redux/OldStudentSlice";
// import moment from "moment";
import {
  getAcademicSessionsAsync,
  getCastesAsync,
  getHostelsAsync,
  getProgrammesAsync,
} from "../../Redux/MasterSlice";
import { IdProofs } from "../../Constants/IdProofs";

export default function ApproveOldStudent() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [student, setStudent] = useState();
  const [loading, setLoading] = useState(false);
  const oldStudentsSlice = useSelector((state) => state.oldStudents);
  const oldStudents = oldStudentsSlice.oldStudents;

  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const hostels = [...master.hostels];
  const castes = [...master.castes];
  const navigate = useNavigate();

  //   const [showModal, setShowModal] = useState(false);

  const { message } = useSelector((state) => state.message);
  //   const dispatch = useDispatch();
  //   console.log(message);
  useEffect(() => {
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    if (hostels.length === 0) {
      dispatch(getHostelsAsync());
    }

    if (castes.length === 0) {
      dispatch(getCastesAsync());
    }

    async function checkData() {
      if (id && id > 0) {
        const singleStudent = await oldStudents.find((value) => value.id == id);
        if (singleStudent != null) {
          //   console.log("call1");

          if (singleStudent.programme.programmetype_id == 2) {
            setStudent({ ...singleStudent, present_sem: 0 });
          } else {
            setStudent(singleStudent);
          }
        } else {
          //   console.log(" call 2");

          dispatch(showOldStudentAsync({ id }))
            .unwrap()
            .then((value) => {
              console.log(value.programme.programmetype_id);
              if (value.programme.programmetype_id == 2) {
                setStudent({ ...value, present_sem: 0 });
              } else {
                setStudent(value);
              }

              // setStudent(value);
            });
        }
      }
    }
    checkData();
  }, [oldStudents]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required!"),
    // password: Yup.string().required("This field is required!"),
  });

  const handleApprove = (formValue) =>
    //   formValue
    {
      console.log("asd");
      setLoading(true);
      dispatch(approveOldStudentAsync({ payload: formValue }))
        .unwrap()
        .then(() => {
          setLoading(false);
          navigate(-1);
        })
        .catch(() => {
          setLoading(false);
        });
    };

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full overflow-x-scroll xl:overflow-x-hidden'>
            <PageTitle name='Approve Student' />
            {message && <Alert message={message} />}
            {student != null && (
              <Formik
                initialValues={student}
                validationSchema={validationSchema}
                onSubmit={handleApprove}
              >
                <Form>
                  <div className='grid gap-8 p-5 pt-6  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    <div className='overflow-hidden image'>
                      <img
                        className='w-32 h-auto '
                        src={student.profile_pic}
                        onError={(e) => {
                          console.log(e.target.src);
                          if (e.target.src != "/assets/no-photo.jpg")
                            e.target.src = "/assets/no-photo.jpg";
                        }}
                      />
                      <div className='w-32 h-auto '>
                        <br />
                      </div>

                      <img
                        className='w-32 h-auto'
                        src={student.profile_sign}
                        onError={(e) => {
                          console.log(e.target.src);
                          if (e.target.src != "/assets/no-sign.jpg")
                            e.target.src = "/assets/no-sign.jpg";
                        }}
                      />
                    </div>
                    <div></div>
                    <div></div>
                    <Input
                      label='Applied Date'
                      id='applied_date'
                      name='applied_date'
                      readOnly={true}
                      type='date'
                    />

                    <Select
                      label='Academic Session'
                      id='academic_session_id'
                      name='academic_session_id'
                      readOnly={true}
                      disabled={true}
                    >
                      <option>Select One</option>
                      {academicSessions.length > 0 &&
                        academicSessions.map((academicSession, index) => (
                          <option key={index} value={academicSession.id}>
                            {academicSession.label}
                          </option>
                        ))}
                    </Select>
                    <Input
                      label='Student Name'
                      id='name'
                      name='name'
                      readOnly={true}
                    />
                    <Input
                      label='Guardian Name'
                      id='guardian'
                      name='guardian'
                      readOnly={true}
                    />
                    <Input
                      label='Mobile Number'
                      id='mobile'
                      name='mobile'
                      readOnly={true}
                    />
                    <Input
                      label='Email'
                      id='email'
                      name='email'
                      readOnly={true}
                    />
                    <Select
                      label='Gender'
                      name='gender'
                      readOnly={true}
                      disabled={true}
                    >
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      <option value={"other"}>Other</option>
                    </Select>

                    <Select
                      label='Religion'
                      name='religion'
                      readOnly={true}
                      disabled={true}
                    >
                      <option value={"Hinduism"}>Hinduism</option>
                      <option value={"Islam"}>Islam</option>
                      <option value={"Christianity"}>Christianity</option>
                      <option value={"Sikhism"}>Sikhism</option>
                      <option value={"Buddhism"}>Buddhism</option>
                      <option value={"Jainism"}>Jainism</option>
                      <option value={"Other"}>Other</option>
                    </Select>

                    <Select
                      label='Caste'
                      id='caste_id'
                      name='caste_id'
                      readOnly={true}
                      disabled={true}
                    >
                      <option value={null}>Select</option>
                      {castes.length > 0 &&
                        castes.map((caste, index) => (
                          <option key={index} value={caste.id}>
                            {caste.name}
                          </option>
                        ))}
                    </Select>
                    <Input
                      label='DOB'
                      id='dob'
                      name='dob'
                      type='date'
                      readOnly={true}
                    />

                    {/* <Select
                      label="Do you stay in University Hostel?"
                      id="stay_hostel"
                      name="stay_hostel"
                      readOnly={true}
                      disabled={true}
                    >
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </Select> */}

                    {/* <Select
                      label="Select Hostel"
                      id="hostel_id"
                      name="hostel_id"
                      readOnly={true}
                      disabled={true}
                    >
                      <option value={null}>N/A</option>
                      {hostels.length > 0 &&
                        hostels.map((hostel, index) => (
                          <option key={index} value={hostel.id}>
                            {hostel.name}
                          </option>
                        ))}
                    </Select> */}

                    <Select
                      label='Identifier'
                      id='identifier'
                      name='identifier'
                      readOnly={true}
                      disabled={true}
                    >
                      {IdProofs.length > 0 &&
                        IdProofs.map((proof, index) => (
                          <option key={index} value={proof}>
                            {proof}
                          </option>
                        ))}
                    </Select>
                    <Input
                      label='Identifier No'
                      id='identifier_no'
                      name='identifier_no'
                      readOnly={true}
                    />

                    <Select
                      label='Programme'
                      id='programme_id'
                      name='programme_id'
                    >
                      {programmes.length > 0 &&
                        programmes.map((programme, index) => (
                          <option key={index} value={programme.id}>
                            {programme.name}
                          </option>
                        ))}
                    </Select>
                    <Select
                      label='Present Sem'
                      id='present_sem'
                      name='present_sem'
                    >
                      <option value={"N/A"}>N/A</option>
                      {/* <option value={1}>1</option> */}
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      {/* <option value={4}>4</option> */}
                      <option value={5}>5</option>
                      {/* <option value={6}>6</option> */}
                      <option value={7}>7</option>
                      {/* <option value={8}>8</option> */}
                      <option value={9}>9</option>
                      {/* <option value={10}>10</option> */}
                      <option value={11}>11</option>
                      {/* <option value={12}>12</option> */}
                    </Select>
                    <Select
                      label='Enrollment Year'
                      id='enrollment'
                      name='enrollment'
                    >
                      {[
                        2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                        2019, 2020, 2021, 2022,
                      ].map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>

                  <div className='flex items-center justify-center mx-auto my-10'>
                    {message && <Alert message={message} />}
                  </div>

                  <div className='flex justify-center'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-100 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Saving...</span>}
                      {!loading && <span>Approve and Generate ID</span>}
                    </button>
                  </div>
                </Form>
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
