import React from "react";
import * as HIcons from "@heroicons/react/outline";

export default function Alert(props) {
  return (
    <div
      role="alert"
      className="flex sm:items-center justify-between transition duration-150 ease-in-out  pl-3 pr-4  sm:pr-8 py-4 sm:py-0 shadow rounded shadow relative"
      id="notification"
    >
      <HIcons.ExclamationCircleIcon width="30" color="red" />
      <div className="flex items-start w-full ml-11 pt-3 pb-3">
        <div>
          <p className="text-sm sm:text-lg font-bold md:leading-5   text-red-800">
            Message:
          </p>
          <p className="text-xs font-medium leading-4 sm:leading-3 text-red-600   mt-1.5 sm:mt-3">
            {props.message}
          </p>
        </div>
      </div>
    </div>
  );
}
