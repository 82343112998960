import { Field, ErrorMessage } from "formik";
import React from "react";

export default function ToggleSwitch({ label, name }) {
  return (
    <div className="mt-4">
      {label ? (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      ) : null}
      <Field name={name}>
        {({ field, form }) => (
          <div
            className={`relative w-11 h-6 mt-2 ${
              field.value ? "bg-blue-600" : "bg-gray-200"
            } rounded-full cursor-pointer`}
            onClick={() => form.setFieldValue(name, !field.value)}
          >
            <input
              type="checkbox"
              id={name}
              className="sr-only peer"
              checked={field.value}
              onChange={() => form.setFieldValue(name, !field.value)}
            />
            <div
              className={`absolute top-0.5 left-0.5 h-5 w-5 bg-white border border-gray-300 rounded-full transition-transform ${
                field.value ? "transform translate-x-full" : ""
              }`}
            ></div>
          </div>
        )}
      </Field>
      <ErrorMessage name={name} component="div" className="text-red-500 py-2" />
    </div>
  );
}
