import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  name: { label: "Name of the Committee", path: "name" },
  member_type: { label: "Member Type", path: "member_type" },
  month: { label: "Month of Membership", path: "monthName" },
  year: { label: "Year of Membership", path: "year" },
  till_month: { label: "Till Month", path: "tillMonthName" },
  till_year: { label: "Till Year", path: "till_year" },
};
const Committee = () => {
  const [committees, setCommittees] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/committees");
      setCommittees(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Memberships in Significant Committees
      </h2>
      <TableViewProfile
        headers={headers}
        data={committees}
        type="committees"
        setData={setCommittees}
      />
    </div>
  );
};

export default Committee;
