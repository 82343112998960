import React from "react";

function Pagination(props) {
  return (
    <>
      <div className='flex justify-center'>
        <nav className='block'>
          <ul className='flex pl-0 rounded list-none'>
            {props.links.length > 0 &&
              props.links.map(({ label, url, active }, index) => (
                <li className='relative' key={index}>
                  <span
                    className={
                      "inline-flex cursor-pointer items-center px-4 py-2  border border-gray-300 bg-white text-sm    hover:text-gray-500" +
                      (active == true
                        ? " text-blue-500 font-bold"
                        : "text-gray-700 font-medium") +
                      (index == 0
                        ? " rounded-l-md "
                        : index == props.links.length - 1
                        ? " rounded-r-md "
                        : "")
                    }
                    onClick={() => {
                      if(url){
                        props.onClick(url);
                      }
                    
                    }}
                    dangerouslySetInnerHTML={{
                      __html: label,
                    }}
                  ></span>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Pagination;
