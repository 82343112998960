import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BaseApi from "../Http/BaseApi";
import messageSlice from "./MessageSlice";
// import messageSlice from "./MessageSlice";
// import messageSlice from "./MessageSlice";

export const getDashboardAsync = createAsyncThunk(
  "master/getDashboardAsync",
  async () => {
    // console.log("programme call");
    const response = await BaseApi().get(`dashboard`);
    // console.log(response.data);
    return response.data;
  }
);

export const getStudentCountsAsync = createAsyncThunk(
  "master/getStudentCountsAsync",
  async () => {
    // console.log("programme call");
    const response = await BaseApi().get(`student-counts`);

    return response.data;
  }
);

export const getProgrammesAsync = createAsyncThunk(
  "master/getProgrammesAsync",
  async () => {
    // console.log("programme call");
    const response = await BaseApi().get(`programmes`);
    // console.log(response.data);
    return response.data;
  }
);

export const getFormsAsync = createAsyncThunk(
  "master/getFormsAsync",
  async () => {
    // console.log("programme call");
    const response = await BaseApi().get(`forms`);
    // console.log(response.data);
    return response.data;
  }
);

export const getAcademicSessionsAsync = createAsyncThunk(
  "master/getAcademicSessionsAsync",
  async () => {
    const response = await BaseApi().get(`academic-sessions`);
    // console.log(response.data);
    return response.data;
  }
);

export const getHostelsAsync = createAsyncThunk(
  "master/getHostelsAsync",
  async () => {
    const response = await BaseApi().get(`hostels`);
    // console.log(response.data);
    return response.data;
  }
);

export const getCastesAsync = createAsyncThunk(
  "master/getCastesAsync",
  async () => {
    const response = await BaseApi().get(`castes`);
    // console.log(response.data);
    return response.data;
  }
);

export const getPaymentHeadsAsync = createAsyncThunk(
  "master/getPaymentHeadsAsync",
  async () => {
    const response = await BaseApi().get(`payment-heads`);
    // console.log(response.data);
    return response.data;
  }
);

export const addPaymentHeadAsync = createAsyncThunk(
  "master/addPaymentHeadAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("payment-heads", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePaymentHeadAsync = createAsyncThunk(
  "master/updatePaymentHeadAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `payment-heads/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletePaymentHeadAsync = createAsyncThunk(
  "master/deletePaymentHeadAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`payment-heads/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkDeletePaymentHeadsAsync = createAsyncThunk(
  "master/bulkDeletePaymentHeadsAsync",
  async (payload, thunkAPI) => {
    try {
      await BaseApi().post(`payment-heads/bulk-delete`, payload);

      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getAccPaymentHeadsAsync = createAsyncThunk(
  "master/getAccPaymentHeadsAsync",
  async () => {
    const response = await BaseApi().get(`acc-payment-heads`);
    // console.log(response.data);
    return response.data;
  }
);

export const addAccPaymentHeadAsync = createAsyncThunk(
  "master/addAccPaymentHeadAsyncAcc",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("acc-payment-heads", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateAccPaymentHeadAsync = createAsyncThunk(
  "master/updateAccPaymentHeadAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `acc-payment-heads/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteAccPaymentHeadAsync = createAsyncThunk(
  "master/deleteAccPaymentHeadAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`acc-payment-heads/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkDeleteAccPaymentHeadsAsync = createAsyncThunk(
  "master/bulkDeleteAccPaymentHeadsAsync",
  async (payload, thunkAPI) => {
    try {
      await BaseApi().post(`acc-payment-heads/bulk-delete`, payload);

      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCostCentersAsync = createAsyncThunk(
  "master/getCostCentersAsync",
  async () => {
    console.log("master cost");
    const response = await BaseApi().get(`cost-centers`);
    // console.log(response.data);
    return response.data;
  }
);

export const addCostCenterAsync = createAsyncThunk(
  "master/addCostCenterAsyncAcc",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("cost-centers", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCostCenterAsync = createAsyncThunk(
  "master/updateCostCenterAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `cost-centers/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteCostCenterAsync = createAsyncThunk(
  "master/deleteCostCenterAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`cost-centers/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPaymentGroupsAsync = createAsyncThunk(
  "master/getPaymentGroupsAsync",
  async () => {
    const response = await BaseApi().get(`payment-groups`);
    // console.log(response.data);
    return response.data;
  }
);

export const addPaymentGroupAsync = createAsyncThunk(
  "master/addPaymentGroupAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("payment-groups", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePaymentGroupAsync = createAsyncThunk(
  "master/updatePaymentGroupAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `payment-groups/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletePaymentGroupAsync = createAsyncThunk(
  "master/deletePaymentGroupAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`payment-groups/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkDeletePaymentGroupsAsync = createAsyncThunk(
  "master/bulkDeletePaymentGroupsAsync",
  async (payload, thunkAPI) => {
    try {
      await BaseApi().post(`payment-groups/bulk-delete`, payload);

      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getDepartmentsAsync = createAsyncThunk(
  "master/getDepartmentsAsync",
  async () => {
    const response = await BaseApi().get(`departments`);
    // console.log(response.data);
    return response.data;
  }
);
export const getDesignationsAsync = createAsyncThunk(
  "master/getDesignationsAsync",
  async () => {
    const response = await BaseApi().get(`designations`);
    // console.log(response.data);
    return response.data;
  }
);

export const addDesignationAsync = createAsyncThunk(
  "master/addDesignationAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("designations", payload);
      thunkAPI.dispatch(messageSlice.actions.setMessage("Saved Successfully"));
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDesignationAsync = createAsyncThunk(
  "master/updateDesignationAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `designations/${payload.id}`,
        payload
      );
      thunkAPI.dispatch(messageSlice.actions.setMessage("Saved Successfully"));
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteDesignationAsync = createAsyncThunk(
  "master/deleteDesignationAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`designations/${payload.id}`);
      thunkAPI.dispatch(
        messageSlice.actions.setMessage("Deleted Successfully")
      );
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getLeaveTypesAsync = createAsyncThunk(
  "master/getLeaveTypesAsync",
  async () => {
    const response = await BaseApi().get(`leave-types`);
    return response.data;
  }
);

export const addLeaveTypeAsync = createAsyncThunk(
  "master/addLeaveTypeAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("leave-types", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateLeaveTypeAsync = createAsyncThunk(
  "master/updateLeaveTypeAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `leave-types/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteLeaveTypeAsync = createAsyncThunk(
  "master/deleteLeaveTypeAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`leave-types/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkDeleteLeaveTypesAsync = createAsyncThunk(
  "master/bulkDeleteLeaveTypesAsync",
  async (payload, thunkAPI) => {
    try {
      await BaseApi().post(`leave-types/bulk-delete`, payload);

      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStatesAsync = createAsyncThunk(
  "master/getStatesAsync",
  async () => {
    const response = await BaseApi().get(`states`);
    return response.data;
  }
);
export const getDistrictsAsync = createAsyncThunk(
  "master/getDistrictsAsync",
  async () => {
    const response = await BaseApi().get(`districts`);
    return response.data;
  }
);

export const updateDashboardNotesAsync = createAsyncThunk(
  "master/updateDashboardNotesAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(`dashboard/notes`, payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  studentCounts: [],
  dashboardData: [],
  programmes: [],
  forms: [],
  academicSessions: [],
  hostels: [],
  castes: [],
  paymentHeads: [],
  costCenters: [],
  accPaymentHeads: [],
  paymentGroups: [],
  departments: [],
  designations: [],
  leaveTypes: [],
  staff: [],
  states: [],
  districts: [],

  errorMessage: "",
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {},
  extraReducers: {
    [getDashboardAsync.fulfilled]: (state, action) => {
      return { ...state, dashboardData: action.payload };
    },
    [getStudentCountsAsync.fulfilled]: (state, action) => {
      return { ...state, studentCounts: action.payload };
    },
    [getProgrammesAsync.fulfilled]: (state, action) => {
      return { ...state, programmes: action.payload };
    },
    [getFormsAsync.fulfilled]: (state, action) => {
      return { ...state, forms: action.payload };
    },
    [getAcademicSessionsAsync.fulfilled]: (state, action) => {
      return { ...state, academicSessions: action.payload };
    },
    [getHostelsAsync.fulfilled]: (state, action) => {
      return { ...state, hostels: action.payload };
    },
    [getCastesAsync.fulfilled]: (state, action) => {
      return { ...state, castes: action.payload };
    },
    [getPaymentHeadsAsync.fulfilled]: (state, action) => {
      return { ...state, paymentHeads: action.payload };
    },
    [addPaymentHeadAsync.fulfilled]: (state, action) => {
      state.paymentHeads.push(action.payload);
    },

    [updatePaymentHeadAsync.fulfilled]: (state, action) => {
      const index = state.paymentHeads.findIndex(
        (head) => head.id === action.payload.id
      );
      state.paymentHeads[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deletePaymentHeadAsync.fulfilled]: (state, action) => {
      let paymentHeads = state.paymentHeads.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, paymentHeads: paymentHeads };
    },
    [bulkDeletePaymentHeadsAsync.fulfilled]: (state, action) => {
      let paymentHeads = state.paymentHeads.filter((head) => {
        return !action.payload.selectedIds.includes(head.id.toString());
      });

      return { ...state, paymentHeads: paymentHeads };
    },
    [getAccPaymentHeadsAsync.fulfilled]: (state, action) => {
      return { ...state, accPaymentHeads: action.payload };
    },

    [addAccPaymentHeadAsync.fulfilled]: (state, action) => {
      state.accPaymentHeads.push(action.payload);
    },

    [updateAccPaymentHeadAsync.fulfilled]: (state, action) => {
      const index = state.accPaymentHeads.findIndex(
        (head) => head.id === action.payload.id
      );
      state.accPaymentHeads[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deleteAccPaymentHeadAsync.fulfilled]: (state, action) => {
      let accPaymentHeads = state.accPaymentHeads.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, accPaymentHeads: accPaymentHeads };
    },
    [bulkDeleteAccPaymentHeadsAsync.fulfilled]: (state, action) => {
      let accPaymentHeads = state.accPaymentHeads.filter((head) => {
        return !action.payload.selectedIds.includes(head.id.toString());
      });

      return { ...state, accPaymentHeads: accPaymentHeads };
    },

    [getCostCentersAsync.fulfilled]: (state, action) => {
      return { ...state, costCenters: action.payload };
    },

    [addCostCenterAsync.fulfilled]: (state, action) => {
      state.costCenters.push(action.payload);
    },

    [updateCostCenterAsync.fulfilled]: (state, action) => {
      const index = state.costCenters.findIndex(
        (head) => head.id === action.payload.id
      );
      state.costCenters[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deleteCostCenterAsync.fulfilled]: (state, action) => {
      let costCenters = state.costCenters.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, costCenters: costCenters };
    },
    [getPaymentGroupsAsync.fulfilled]: (state, action) => {
      return { ...state, paymentGroups: action.payload };
    },
    [addPaymentGroupAsync.fulfilled]: (state, action) => {
      state.paymentGroups.push(action.payload);
    },

    [updatePaymentGroupAsync.fulfilled]: (state, action) => {
      const index = state.paymentGroups.findIndex(
        (group) => group.id === action.payload.id
      );
      state.paymentGroups[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deletePaymentGroupAsync.fulfilled]: (state, action) => {
      let paymentGroups = state.paymentGroups.filter(
        (group) => group.id != action.payload.id
      );

      return { ...state, paymentGroups: paymentGroups };
    },
    [bulkDeletePaymentGroupsAsync.fulfilled]: (state, action) => {
      let paymentGroups = state.paymentGroups.filter((group) => {
        return !action.payload.selectedIds.includes(group.id.toString());
      });

      return { ...state, paymentGroups: paymentGroups };
    },
    [getDepartmentsAsync.fulfilled]: (state, action) => {
      return { ...state, departments: action.payload };
    },
    [getDesignationsAsync.fulfilled]: (state, action) => {
      return { ...state, designations: action.payload };
    },
    [addDesignationAsync.fulfilled]: (state, action) => {
      state.designations.push(action.payload);
    },

    [updateDesignationAsync.fulfilled]: (state, action) => {
      const index = state.designations.findIndex(
        (head) => head.id === action.payload.id
      );
      state.designations[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deleteDesignationAsync.fulfilled]: (state, action) => {
      let designations = state.designations.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, designations: designations };
    },
    ///

    [getLeaveTypesAsync.fulfilled]: (state, action) => {
      return { ...state, leaveTypes: action.payload };
    },
    [addLeaveTypeAsync.fulfilled]: (state, action) => {
      state.leaveTypes.push(action.payload);
    },

    [updateLeaveTypeAsync.fulfilled]: (state, action) => {
      const index = state.leaveTypes.findIndex(
        (head) => head.id === action.payload.id
      );
      state.leaveTypes[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deleteLeaveTypeAsync.fulfilled]: (state, action) => {
      let leaveTypes = state.leaveTypes.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, leaveTypes: leaveTypes };
    },
    [getStatesAsync.fulfilled]: (state, action) => {
      return { ...state, states: action.payload };
    },
    [getDistrictsAsync.fulfilled]: (state, action) => {
      return { ...state, districts: action.payload };
    },
    [updateDashboardNotesAsync.fulfilled]: (state, action) => {
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          dashboardNote: action.payload,
        },
      };
    },
  },
});

export default masterSlice.reducer;
