import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {  useParams } from "react-router-dom";
 import BaseApi from "../../Http/BaseApi";
import "./StaffIDCard.css";
import * as Yup from "yup";
import ReactToPrint from "react-to-print";
import Button from "../../Components/Utils/Button";
import IDCardComponent from "./IDCardComponent";
import can from "../../Middleware/Permission";
 
export default function StaffIDCard() {
  const { id } = useParams();
  const formikRef = useRef();
  const componentRef = useRef();
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [changeAddress, setChangeAddress] = useState(false);
  // const [changeBloodGroup, setChangeBloodGroup] = useState(false);
  // const [bloodGroup, setBloodGroup] = useState(false);
  // const [address, setAddress] = useState(false);
  // const [pin, setPin] = useState(false);
  // const [district, setDistrict] = useState(false);
  // const [state, setState] = useState(false);
 
  const [staff, setStaff] = useState();
  const [initialValues, setInitialValues] = useState();

  const validationSchema = Yup.object().shape({
   
    staff_id: Yup.string().required("This field is required!"),
  });

  const generateIDCard = async (payload) => {
    setLoading(true);

    const _id_card = await BaseApi().post(
      `staff/generate-id-card`,
      payload
    );
    const _staff = { ...staff };
    _staff.id_card = _id_card.data;

    setStaff(_staff);
    console.log(_staff);
    setLoading(false);
  };

 
 

  useEffect(() => {
    if (!can("Manage Staff ID Card")) {
      // navigate(`/not-found`);
    }

    checkData();
  }, [id]);

 

  async function checkData() {
    if (id && id > 0) {
      const _staff = await BaseApi()
        .get(`staff/get-id-card/${id}`)
        .catch(() => {
          window.alert("Something Went Wrong");
        });
      setStaff(_staff.data);

    
        setInitialValues({
         
          staff_id: id,
        });
      
    }
  }
  const changeStatus = async (status) => {
    await BaseApi().post(
      `staff/update-id-card/${staff.id_card.id}`,
      {
        status: status,
      }
    );
    const _staff = { ...staff };
    _staff.id_card.status = status;

    setStaff(_staff);
  };

  const deleteCard = async () => {
    await BaseApi().delete(
      `staff/delete-id-card/${staff.id_card.id}`
    );
    const _staff = { ...staff };
    _staff.id_card = null;

    setStaff(_staff);
  };

  return (
    <>
      {staff != null && (
        <div className='ml-10'>
          {staff.id_card == null && (
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={generateIDCard}
            >
              <Form>
                <div className=' py-5 grid  grid-cols-6  pt-6 gap-1'>
 
                  <div className='flex items-end'>
                    <button
                      className='   w-32 h-10   outline-button-success p-5'
                      disabled={loading}
                      type='submit'
                    >
                      {loading ? "Saving..." : "Generate"}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          )}
          {staff.id_card != null && staff.id_card.status == 1 && (
            <>
              {" "}
              <button
                className=' outline-button-danger p-5'
                disabled={loading}
                onClick={() => {
                  if (window.confirm("Are you sure?") == true) {
                    deleteCard();
                  } else {
                    return;
                  }
                }}
                type='button'
              >
                {loading ? "Saving..." : "Delete"}
              </button>
              <button
                className=' outline-button-danger p-5'
                disabled={loading}
                onClick={() => {
                  if (window.confirm("Are you sure?") == true) {
                    changeStatus(0);
                  } else {
                    return;
                  }
                }}
                type='button'
              >
                {loading ? "Saving..." : "Deactivate"}
              </button>
              <ReactToPrint
                trigger={() => (
                  <Button
                    classNames='  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 mb-5'
                    value={"Print"}
                    icon={"PrinterIcon"}
                  />
                )}
                content={() => componentRef.current}
              />
            </>
          )}

          {staff.id_card != null && staff.id_card.status == 0 && (
            <>
              <button
                className=' outline-button-success p-5'
                disabled={loading}
                onClick={() => {
                  if (window.confirm("Are you sure?") == true) {
                    changeStatus(1);
                  } else {
                    return;
                  }
                }}
                type='button'
              >
                {loading ? "Saving..." : "Reactivate ID Card"}
              </button>
            </>
          )}

          <button
            className=' outline-button-success p-5'
            disabled={loading}
            onClick={() => {
              setShowModal(true);
            }}
            type='button'
          >
            Zoom View
          </button>
          {showModal && (
            <>
              <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative   my-6 mx-auto'>
                  {/*content*/}
                  <div
                    style={{ width: "800px", height: "600px" }}
                    className='border-0  rounded-lg shadow-lg relative flex flex-col   bg-white outline-none focus:outline-none'
                  >
                    {/*header*/}
                    <div className='flex items-start justify-between p-5   border-slate-200 rounded-t'>
                      <button
                        className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                        onClick={() => setShowModal(false)}
                      >
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className='relative p-6 flex-auto mt-10'>
                    
                        <IDCardComponent
                          zoom={true}
                          staff={staff}
                          componentRef={componentRef}
                        />
                      
                    </div>
                    {/*footer*/}
                    <div className='flex items-center justify-end p-6   border-slate-200 rounded-b'>
                      <button
                        className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        type='button'
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
            </>
          )}
          <IDCardComponent staff={staff} componentRef={componentRef} />
          <br />
     
                <Button
                  classNames=' ml-5 mt-5 outline-button-success'
                  value={"Edit"}
                  onClick={() => {
                   window.open('/staff/edit-for-id-card/'+staff.id)
                  }}
                  icon={"PencilIcon"}
                />
             
        </div>
      )}
    </>
  );
}
