import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  title: {
    label: "Title of the Paper",
    path: "title",
  },
  conference_name: {
    label: "Name of the Conference/Seminar",
    path: "conference_name",
  },
  conference_type: { label: "Conference Type", path: "conference_type" },
  organizer: { label: "Organizer", path: "organizer" },
  conference_start_date: { label: "Start Date", path: "conference_start_date" },
  conference_end_date: { label: "End Date", path: "conference_end_date" },
  corresponding_principal: {
    label: "Are you corresponding/principal author?",
    path: "corresponding_principal",
  },
  funding_source: { label: "Funding Source", path: "funding_source" },
  funding_source_details: {
    label: "Funding Source Details",
    path: "funding_source_details",
  },
  author_names: { label: "Author Names", path: "author_names" },
  published: { label: "Published", path: "published" },
  published_url: { label: "Published URL", path: "published_url" },
  month: { label: "Month of Publish", path: "monthName" },
  year: { label: "Year of Publish", path: "year" },
  page_no: { label: "Page No", path: "page_no" },
  editor_name: { label: "Editor Name", path: "editor_name" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed In", path: "indexed_in" },
};

const IqacConferenceSeminar = () => {
  const [conferenceSeminars, setConferenceSeminars] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/conference-seminars?from_iqac=1"
      );
      setConferenceSeminars(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50">
        <h1>Conference/Seminar Paper: </h1>
        <div className="flex items-end justify-center space-x-2 capitalize text-md">
          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">From: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="flex flex-row items-center justify-between space-x-1">
            <label htmlFor="month">To: </label>
            <input
              type="month"
              name="month"
              id="month"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div className="">
            <Button
              type="filter"
              value="Filter"
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={conferenceSeminars}
        type="conference-seminars"
        setData={setConferenceSeminars}
      />
    </div>
  );
};

export default IqacConferenceSeminar;
