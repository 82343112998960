import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";
import IDCardComponent from "./IDCardComponent";
import ReactToPrint from "react-to-print";
import Button from "../../Components/Utils/Button";

export default function PublicIDCard() {
  const { id } = useParams();
  const [student, setStudent] = useState();
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();
  const [searchParams] = useSearchParams ();
  const print= searchParams.get("p") ?? "";  // for print button

console.log(id);
  useEffect(() => {
    async function checkData() {
      setLoading(true);
      if (id && id > 0) {
        const _student = await BaseApi("staff")
          .get(`admitted-students/verify-id-card/${id}`)
          .catch(() => {
            setLoading(false);
          });
        setStudent(_student.data);
        setLoading(false);
      }
    }
    checkData();
  }, [id]);
  return (
    <>
      {loading && <>Loading ID Card...</>}
      {student == null && loading == false && <>ID Card Not Found</>}
      {student != null && (
        <div className="m-10">
          <IDCardComponent student={student}   componentRef={componentRef} />
        </div>


      )}

{print==1 && student != null  && (


<ReactToPrint
trigger={() => (
  <Button
    classNames='ml-10  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 mb-5'
    value={"Print"}
    icon={"PrinterIcon"}
  />
)}
content={() => componentRef.current}
/>
)}
    </>
  );
}
