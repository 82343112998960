import React, { useEffect, useState } from "react";
import {
  PhoneIcon,
  PencilAltIcon,
  PencilIcon,
  MailIcon,
  UserCircleIcon,
  ViewListIcon,
  ShieldCheckIcon,
} from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardNotesAsync } from "../../Redux/MasterSlice";
import { Link } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";

export default function StaffDashboard() {
  const dispatch = useDispatch();
  const [savingNote, setSavingNote] = useState(false);

  const master = useSelector((state) => state.master);
  const dashboardData = { ...master.dashboardData };

  const [timer, setTimer] = useState(null);
  const authData = useSelector((state) => state.auth);
  const [staff, setStaff] = useState(null);
  const [note, setNote] = useState();

  useEffect(() => {
    getStaff(authData.user.id);
  }, [dispatch, authData]);

  const getStaff = async (id) => {
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        setStaff(data.data);
        setNote(data.data.dashboard_note);
      })
      .catch(() => {});
  };
  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      saveNote({ note: value });
    }, 1000);
    setTimer(newTimer);
  };

  const saveNote = async (noteContent) => {
    setSavingNote(true);
    console.log("Note auto-saved:", noteContent);
    // Simulating a save operation (e.g., API call)
    // localStorage.setItem("quickNote", noteContent);
    await dispatch(updateDashboardNotesAsync(noteContent)).unwrap();
    setSavingNote(false);
  };

  useEffect(() => {
    // if (Object.keys(dashboardData).length === 0) {
    //   dispatch(getDashboardAsync());
    // }
  }, []);
  useEffect(() => {
    setNote(dashboardData.dashboardNote);
  }, [dashboardData.dashboardNote]);

  return (
    <div className='min-h-screen bg-gray-100'>
      {/* Dashboard Stats */}
      <div className='flex flex-wrap justify-around gap-6 px-5 pt-5'>
        {/* Students Card */}
        <Link
          to='/leaves/create'
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <PencilAltIcon className='w-12 h-12 text-purple-600' />
          <h3 className='mt-2 text-lg font-semibold text-gray-700'>
            Apply Leave
          </h3>
        </Link>

        {/* Courses Card */}
        <Link
          to='/leaves'
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <ViewListIcon className='w-12 h-12 text-green-600' />
          <h3 className='mt-2 text-lg font-semibold text-gray-700'>
            View My Leaves
          </h3>
        </Link>

        {/* Teachers Card */}
        <Link
          to={`/staff/edit-authority/${staff?.id}`}
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <ShieldCheckIcon className='w-12 h-12 text-red-600' />
          <h3 className='w-full mt-2 overflow-hidden text-lg font-semibold text-center text-gray-700 whitespace-nowrap overflow-ellipsis'>
            Edit Authority
          </h3>
        </Link>

        {/* Security Card */}
        <Link
          to='/profile'
          className='flex flex-col items-center justify-center w-full p-6 bg-white shadow-md cursor-pointer rounded-xl md:w-1/5'
        >
          <UserCircleIcon className='w-12 h-12 text-yellow-600' />
          <h3 className='mt-2 text-lg font-semibold text-gray-700'>Profile</h3>
        </Link>
      </div>

      {/* Recent Activity & Quick Actions */}
      <div className='grid grid-cols-2 gap-4 px-5 mt-8'>
        {/* Monthly Fee Collection Chart */}
        <div className='w-full bg-white shadow-md rounded-xl'>
          {staff != null && (
            <div>
              <div className='relative w-full mx-auto bg-white rounded-lg shadow'>
                <div className='flex justify-center'>
                  <img
                    src={staff.profile_pic}
                    alt=''
                    className='w-32 h-32 mx-auto transition duration-200 transform border-4 border-white rounded-full shadow-md hover:scale-110'
                  />
                </div>

                <div className='mt-5'>
                  <h1 className='text-3xl font-bold text-center text-gray-900'>
                    {staff.full_name}
                  </h1>

                  <div className='my-5 text-center '>
                    <span className='inline-flex justify-center font-bold'>
                      <PhoneIcon
                        aria-hidden='true'
                        className='w-8 h-8 mb-4 text-black'
                      />
                      {staff.phone}
                      <Link
                        to={`/staff/edit-phone/${staff.id}`}
                        className='float-right m-1 text-blue-700'
                      >
                        <PencilIcon
                          aria-hidden='true'
                          className='w-4 h-4 mb-4 text-blue-700'
                        />
                      </Link>
                    </span>
                    <span className='inline-flex justify-center font-bold lowercase'>
                      {staff.email_verified == 0 ||
                      staff.email_verified == null ? (
                        <>
                          <MailIcon
                            aria-hidden='true'
                            className='w-8 h-8 mb-4 text-black'
                          />
                          <span className='text-red-700'>Verify Email</span>
                          <Link
                            to={`/staff/edit-email/${staff.id}`}
                            className='float-right m-1 text-blue-700'
                          >
                            <PencilIcon
                              aria-hidden='true'
                              className='w-4 h-4 mb-4 text-blue-700'
                            />
                          </Link>{" "}
                        </>
                      ) : (
                        <>
                          <MailIcon
                            aria-hidden='true'
                            className='w-8 h-8 mb-4 text-black'
                          />
                          {staff.email}
                          <Link
                            to={`/staff/edit-email/${staff.id}`}
                            className='float-right m-1 text-blue-700'
                          >
                            <PencilIcon
                              aria-hidden='true'
                              className='w-4 h-4 mb-4 text-blue-700'
                            />
                          </Link>
                        </>
                      )}
                    </span>
                  </div>
                  <div className='flex items-center justify-center '>
                    <Link
                      to={`/staff/edit/${staff.id}`}
                      className='inline-flex items-center px-4 py-2 text-blue-500 transition duration-150 ease-in-out bg-white border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 '
                    >
                      <PencilAltIcon className='w-5 h-5 mr-2 -ml-1' />
                      Edit Profile
                    </Link>
                  </div>
                  <div className='grid-cols-2 gap-4 lg:grid lg:mx-auto '>
                    <div className=''>
                      {" "}
                      <div className='w-full'>
                        {/* <h3 className="px-6 text-lg font-medium text-left text-gray-900">
                      Basic Info.
                    </h3> */}
                        <div className='w-full mt-1 overflow-hidden text-sm'>
                          <dl className='my-8 leading-loose'>
                            <div className='px-6 bg-white '>
                              <dt className='text-lg font-medium'>Name:</dt>
                              <dd className='ml-2'> {staff.full_name}</dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Father&apos;s Name:
                              </dt>
                              <dd className='ml-2'>{staff.fathers_name}</dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Department:
                              </dt>
                              <dd className='ml-2'>{staff.department?.name}</dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Designation:
                              </dt>
                              <dd className='ml-2'>
                                {staff.designation?.name}
                              </dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Contact Information:
                              </dt>
                              <dd className='ml-2'>Email: {staff.email}</dd>
                              <dd className='ml-2'>Phone: {staff.phone}</dd>
                              <dd className='ml-2'>Address: {staff.address}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <div className='w-full'>
                        {/* <h3 className="px-6 text-lg font-medium text-left text-gray-900">
                      Basic Info.
                    </h3> */}
                        <div className='w-full mt-1 overflow-hidden text-sm'>
                          <dl className='my-8 leading-loose'>
                            <div className='px-6 bg-white '>
                              <dt className='mt-2 text-lg font-medium'>
                                Date of Birth:
                              </dt>
                              <dd className='ml-2'>{staff.dob}</dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Mother&apos;s Name:
                              </dt>
                              <dd className='ml-2'>{staff.mothers_name}</dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Date of Joining:
                              </dt>
                              <dd className='ml-2'>{staff.dojoining}</dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Blood Group:
                              </dt>
                              <dd className='ml-2'>{staff.blood_group}</dd>
                              <dt className='mt-2 text-lg font-medium'>
                                Nature of Employment
                              </dt>
                              <dd className='ml-2'>
                                {staff.nature_of_employee}
                              </dd>
                              {/* <dt className="mt-2 text-lg font-medium">PAN</dt>
                          <dd className="ml-2">{staff.pan}</dd> */}

                              {/* <dt className="mt-2 text-lg font-medium">
                            Bank Details.
                          </dt>
                          <dd className="ml-2"></dd> */}
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Quick Note Section */}
        <div className='w-full p-6 bg-white shadow-md rounded-xl '>
          <h2 className='pb-4 text-lg font-semibold text-gray-700 border-b'>
            Quick Note
            <small className='float-right text-gray-400'>
              {savingNote && "Saving..."}
            </small>
          </h2>
          <textarea
            className='w-full h-56 p-4 mt-2 text-base text-gray-700 bg-yellow-100 border-none rounded-md focus:ring-2 focus:ring-yellow-500 focus:outline-none'
            placeholder='Type your notes here...'
            value={note}
            onChange={handleNoteChange}
          ></textarea>
        </div>
      </div>
    </div>
  );
}
