import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  thesis_title: { label: "Title of the Thesis", path: "thesis_title" },
  other_institute: {
    label: "Name of the University/Institute",
    path: "other_institute",
  },
  month: {
    label: "Month",
    path: "monthName",
  },
  year: {
    label: "Year",
    path: "year",
  },
};

const ThesisExaminerUniversitie = () => {
  const [thesisExaminerUniversities, setThesisExaminerUniversities] = useState(
    []
  ); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/thesis-examiner-universities"
      );
      setThesisExaminerUniversities(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Thesis Examiner of other Universities
      </h2>
      <TableViewProfile
        headers={headers}
        data={thesisExaminerUniversities}
        type="thesis-examiner-universities"
        setData={setThesisExaminerUniversities}
      />
    </div>
  );
};

export default ThesisExaminerUniversitie;
