import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableFacultyIqac from "../../utils/TableFacultyIqac";
import Button from "../../../../Components/Utils/Button";

const headers = {
  staff_name: {
    label: "Name",
    path: "staff.name",
  },
  award_name: { label: "Award Name", path: "award_name" },
  award_details: { label: "Award Details", path: "award_details" },
  month: { label: "Month of the Award", path: "monthName" },
  year: { label: "Year of the Award", path: "year" },
  awarded_by: { label: "Awarded By", path: "awarded_by" },
};

const IqacHonorsAward = () => {
  const [honorsAwards, setHonorsAwards] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/honors-awards?from_iqac=1"
      );
      setHonorsAwards(response.data.data.filter((q) => q.iqac == "yes"));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase rounded-md shadow-lg bg-blue-50'>
        <h1>Honors and Awards: </h1>
        <div className='flex items-end justify-center space-x-2 capitalize text-md'>
          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>From: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className='flex flex-row items-center justify-between space-x-1'>
            <label htmlFor='month'>To: </label>
            <input
              type='month'
              name='month'
              id='month'
              className='relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
            />
          </div>

          <div className=''>
            <Button
              type='filter'
              value='Filter'
              icon={"FilterIcon"}
              // onClick={handleDownload}
            />
          </div>
        </div>
      </div>

      <TableFacultyIqac
        headers={headers}
        data={honorsAwards}
        setData={setHonorsAwards}
        type='honors-awards'
      />
    </div>
  );
};

export default IqacHonorsAward;
