import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/Common/PageTitle";
import Badge from "../../Components/Utils/Badge";
import Button from "../../Components/Utils/Button";
import BaseApi from "../../Http/BaseApi";

export default function StaffPermission() {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [staff, setStaff] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputPermission, setSearchInputPermission] = useState("");

  useEffect(() => {
    getPermissionsAsync();

    getStaffAsync();

    setFormFields({
      staff_ids: [],
      permission_ids: [],
    });
  }, [dispatch]);
  const getPermissionsAsync = async () => {
    await BaseApi()
      .get(`permissions`, {})
      .then((data) => {
        setPermissions(data.data);
      });
  };

  const getStaffAsync = async () => {
    await BaseApi()
      .get(`staff`, {})
      .then((data) => {
        setStaff(data.data);
      });
  };

  const handleUserInputChange = (event) => {
    const target = event.target;
    var value = target.value;
    console.log(target.checked);
    if (target.checked) {
      let _formFields = { ...formFields };

      _formFields.staff_ids.push(value);
      setFormFields(_formFields);
    } else {
      let _formFields = { ...formFields };
      var index = _formFields.staff_ids.indexOf(value);
      if (index !== -1) {
        _formFields.staff_ids.splice(index, 1);
      }
      setFormFields(_formFields);
    }
    console.log(" a " + formFields.staff_ids.includes(value) + " ");
  };
  const handlePermissionInputChange = (event) => {
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      let _formFields = { ...formFields };
      _formFields.permission_ids.push(value);
      setFormFields(_formFields);
    } else {
      let _formFields = { ...formFields };
      var index = _formFields.permission_ids.indexOf(value);
      if (index !== -1) {
        _formFields.permission_ids.splice(index, 1);
      }
      setFormFields(_formFields);
    }
    console.log(formFields);
  };

  const saveData = async (e) => {
    e.preventDefault();

    await BaseApi().post(`staff-permissions`, formFields);
    setFormFields({
      staff_ids: [],
      permission_ids: [],
    });
    getStaffAsync();
  };

  const removePermission = async (staff_id, permissionId) => {
    let payload = {
      staff_id: staff_id,
      permission_id: permissionId,
    };
    console.log(payload);
    await BaseApi().post(`remove-staff-permissions`, payload);
    getStaffAsync();
  };
  return (
    <>
      <PageTitle name="User Permissions" />
      <hr />
      <br />
      <div className="mt-10 sm:mt-0 shadow-2xl ">
        <div className="    ">
          <div className="shadow  overflow-hidden sm:rounded-md">
            <div className="shadow overflow-hidden p-10 border-b border-gray-200 sm:rounded-lg">
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <h3>User List</h3>

                  {staff?.length > 0 && (
                    <>
                      <input
                        className="  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mt-2
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               "
                        placeholder="Search..."
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </>
                  )}

                  <div className="flex justify-center mt-5">
                    <ul className="bg-white rounded-lg border border-gray-200 w-full text-gray-900">
                      {staff
                        .filter((s) => {
                          if (searchInput.length > 0) {
                            return (
                              s.name
                                .toString()
                                .toLowerCase()
                                .indexOf(searchInput.toLowerCase()) > -1
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((user) => {
                          return (
                            <li
                              key={user.id}
                              className="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg"
                            >
                              <input
                                className="form-check-input   h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                checked={formFields.staff_ids.includes(
                                  user.id.toString()
                                )}
                                value={user.id}
                                onChange={(event) =>
                                  handleUserInputChange(event)
                                }
                                id={"user-id" + user.id}
                              />
                              <label
                                className="form-check-label inline-block text-gray-800"
                                htmlFor={"user-id" + user.id}
                              >
                                {user.name}
                              </label>
                              <hr />
                              <div className="grid grid-cols-3 gap-2">
                                {user.permissions.map((permission) => {
                                  return (
                                    <div key={permission.id}>
                                      <Badge
                                        status={permission.name}
                                        icon="XIcon"
                                        iconClick={() => {
                                          removePermission(
                                            user.id,
                                            permission.id
                                          );
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="">
                  <h3>Permissions</h3>
                  {permissions?.length > 0 && (
                    <>
                      <input
                        className="  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mt-2
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               "
                        placeholder="Search..."
                        onChange={(e) =>
                          setSearchInputPermission(e.target.value)
                        }
                      />
                    </>
                  )}
                  <div className="flex justify-center mt-5">
                    <ul className="bg-white rounded-lg border border-gray-200 w-full text-gray-900">
                      {permissions
                        .filter((p) => {
                          if (searchInputPermission.length > 0) {
                            return (
                              p.name
                                .toString()
                                .toLowerCase()
                                .indexOf(searchInputPermission.toLowerCase()) >
                              -1
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((permission) => {
                          return (
                            <li
                              key={permission.id}
                              className="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg"
                            >
                              <input
                                className="form-check-input   h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                checked={formFields.permission_ids.includes(
                                  permission.id.toString()
                                )}
                                value={permission.id}
                                onChange={(event) =>
                                  handlePermissionInputChange(event)
                                }
                                id={"permission-id" + permission.id}
                              />
                              <label
                                className="form-check-label inline-block text-gray-800"
                                htmlFor={"permission-id" + permission.id}
                              >
                                {permission.name}
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-span-2 ">
                  <Button value="Save" icon={"SaveIcon"} onClick={saveData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
