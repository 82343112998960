import React from "react";
import { FaGlobe, FaRegEdit, FaTrashAlt } from "react-icons/fa";
import moment from "moment"; // Import moment for date formatting
import { IoIosLock, IoIosUnlock } from "react-icons/io";

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const isDate = (value) => {
  // Regex to match typical date formats e.g., 01/01/2000, 2000-01-01, etc.
  const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$|^\d{4}-\d{1,2}-\d{1,2}$/;
  return dateRegex.test(value) && !isNaN(Date.parse(value));
};
const formatDate = (value, format = "DD/MM/YYYY") => {
  // Format the date using moment
  return moment(value).format(format);
};

const TableComponent = ({
  editable = false,
  editCheckField,
  headers,
  data,
  handleEdit,
  handleDelete,
}) => {
  console.log(data);

  console.log(headers);
  const headerValues = Object.values(headers);

  return (
    <div className='pb-10 overflow-x-auto'>
      {data.length ? (
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className=''>
            <tr className=''>
              {/* <th className='px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'></th> */}
              <th
                colSpan={2}
                className='px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
              >
                Action
              </th>
              <th className='px-4 py-3 text-xs font-medium tracking-wider text-left text-blue-500 uppercase'>
                #
              </th>
              {headerValues.map((header, index) => (
                <th
                  key={index}
                  className='px-4 py-3 text-xs font-medium tracking-wider text-left text-blue-500 uppercase whitespace-nowrap'
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className='text-xs text-gray-400'>
                  <div className='flex items-center justify-between w-12'>
                    <FaGlobe
                      className={`cursor-pointer inline mr-2 ${
                        row.website === "yes"
                          ? "text-green-600"
                          : "text-gray-500"
                      }`}
                      title='Website'
                      size={18}
                    />

                    {row.iqac == "yes" ? (
                      <>
                        {row[editCheckField] == "Ongoing" ? (
                          <IoIosUnlock
                            className='inline text-yellow-500'
                            size={20}
                          />
                        ) : (
                          <IoIosLock
                            className='inline text-red-600'
                            size={20}
                          />
                        )}
                      </>
                    ) : (
                      <IoIosUnlock className='inline text-grey-500' size={20} />
                    )}
                  </div>
                </td>
                <td className='gap-4 px-4 py-1 text-sm text-gray-900 '>
                  <div className='flex items-center justify-between w-12'>
                    {(row.iqac != "yes" ||
                      (editable == true &&
                        row[editCheckField] == "Ongoing")) && (
                      <>
                        <FaRegEdit
                          onClick={() => handleEdit(row.id)}
                          className='text-green-500 cursor-pointer'
                          size={16}
                        />
                      </>
                    )}
                    {row.iqac != "yes" && (
                      <>
                        <FaTrashAlt
                          onClick={() => handleDelete(row.id)}
                          className='text-red-500 cursor-pointer'
                          size={16}
                        />
                      </>
                    )}
                  </div>
                </td>
                <td className='px-4 py-1 text-sm text-gray-900'>
                  {rowIndex + 1}
                </td>
                {headerValues.map((header, cellIndex) => (
                  <td
                    key={cellIndex}
                    className='px-4 py-1 text-sm text-gray-900'
                  >
                    {
                      // Check if the value is a date and format it
                      isDate(getValueByPath(row, header.path))
                        ? formatDate(getValueByPath(row, header.path))
                        : getValueByPath(row, header.path)
                    }
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default TableComponent;
