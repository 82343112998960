import moment from "moment";
import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { encode as base64_encode } from "base-64";
import { useDispatch, useSelector } from "react-redux";
import { getStatesAsync } from "../../Redux/MasterSlice";

export default function IDCardComponent({ student, componentRef, zoom }) {
  const master = useSelector((state) => state.master);
  const dispatch = useDispatch();

  const states = [...master.states];

  let field = student.admittedcustominfos.find((field) => {
    return field.formfield_id == 15 && field.formsection_id == 21;
  });

  let array = field?.formfield?.input_datai?.split(",");

  let position = array?.indexOf(field?.data);

  let arrayv = field?.formfield?.input_datav?.split(",");

  let bloodGroup = arrayv ? arrayv[position] : "";

  let idBase64 = base64_encode(student?.id_card?.id);

  useEffect(() => {
    if (states.length === 0) {
      dispatch(getStatesAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  // address part

  let address =
    (student.admittedcustominfos?.length > 0 &&
      student.admittedcustominfos
        .find((field) => {
          return field.formfield_id == 119 && field.formsection_id == 22;
        })
        ?.data?.toLowerCase()) ??
    (student.admittedcustominfos?.length > 0 &&
      student.admittedcustominfos
        .find((field) => {
          return field.formfield_id == 119 && field.formsection_id == 21;
        })
        ?.data?.toLowerCase());

  let pin =
    student.admittedcustominfos?.length > 0 &&
    student.admittedcustominfos
      .find((field) => {
        return field.formfield_id == 39 && field.formsection_id == 22;
      })
      ?.data?.toLowerCase();

  let district =
    student.admittedcustominfos?.length > 0 &&
    student.admittedcustominfos
      .find((field) => {
        return field.formfield_id == 147 && field.formsection_id == 22;
      })
      ?.data?.toLowerCase();

  let stateId =
    student.admittedcustominfos?.length > 0 &&
    student.admittedcustominfos.find((field) => {
      return field.formfield_id == 148 && field.formsection_id == 22;
    })?.data;

  // end  address part

  // enrollment year for students other than phd

  let enrollmentYear =
    // (student.admittedcustominfos?.length > 0 &&
    //   student.admittedcustominfos
    //     .find((field) => {
    //       return field.formfield_id == 165 && field.formsection_id == 21;
    //     })
    //     ?.data.toLowerCase()) ??
    student.academic_session?.label.substr(0, 4) -
    Math.round(student.semester / 2) +
    1;

  // enrollment year for phd students
  let phdEnrollmentYear =
    (student.admittedcustominfos?.length > 0 &&
      student.admittedcustominfos
        .find((field) => {
          return field.formfield_id == 165 && field.formsection_id == 21;
        })
        ?.data?.toLowerCase()) ??
    student.academic_session?.label.substr(0, 4);
  // endenrollment year

  return (
    <div className={zoom ? "main-div-zoom" : "main-div"} ref={componentRef}>
      <div className='grid grid-cols-6 pt-1 pr-2 pl-2'>
        <div>
          <img
            style={{ filter: "saturate(200%)" }}
            src='https://erp.dibru.work/images/dibru.png'
            className='rounded-full w-10'
            alt='Avatar'
          />
        </div>
        <div className='col-span-4'>
          <p className='text-xs text-center font-bold'>
            <img
              style={{ width: "100px" }}
              className='w-20 mt-o mr-auto ml-auto '
              src='/assets/logo-assamese.png'
            />
            Dibrugarh University
          </p>
          <p className='text-xs text-center ' style={{ fontSize: ".50rem" }}>
            Dibrugarh-786004, Assam, India
          </p>
        </div>
        <div>
          <QRCode
            value={"https://verify.dibru.ac.in" + `/id-card/?id=${idBase64}`}
            className='float-right    '
            level='L'
            size={45}
          />
        </div>
      </div>
      <div className='grid grid-cols-6' style={{ marginTop: "-8px" }}>
        <div className='text-center  col-span-2'>
          <span className='text-xxs font-bold  pl-2 text-center'></span>
        </div>
        <div className='col-span-2'>
          <div className='text-center'>
            <span
              className='text-xxs font-bold text-center'
              style={{ border: "1px solid", padding: "1px 4px 1px 4px" }}
            >
              Student Identity Card
            </span>
          </div>
        </div>
        <div className='text-right pr-2 col-span-2'>
          <span className='text-xxs font-bold text-center'>
            No.: {student.id_card?.sl_no}
          </span>
        </div>
      </div>
      <div className='image hidden background'>
        <img
          src='https://erp.dibru.work/du_blur_image.png'
          style={{
            height: "1in",
            width: "1in",
            margin: "0 auto",

            display: "block",
            opacity: "0.3",
          }}
        />
      </div>
      {(student.id_card == null || student.id_card.status == 0) && (
        <div className=' -rotate-45 background'>
          <p
            style={{
              height: "1in",
              width: "1in",
              margin: "0 auto",
              padding: "20px",
              display: "block",
              opacity: "0.9",
            }}
          >
            Preview
          </p>
        </div>
      )}
      <div
        className='grid grid-cols-6 pt-1 pr-2 pl-2'
        style={({ color: "#000 !important" }, { fontWeight: "700" })}
      >
        <div className='col-span-5'>
          <div className='grid text-xxs grid-cols-9 '>
            <div className='col-span-3'>Enrollment ID: </div>
            <div className='col-span-6'>
              {student.unique_id ?? student.unique_idd}
            </div>
            <div className='col-span-3'>Name: </div>
            <div className='col-span-6 capitalize'>
              {student.student?.name?.toLowerCase()}
            </div>
            <div className='col-span-3'>Department/Center: </div>
            <div className='col-span-6'>{student?.department?.name}</div>
            <div className='col-span-3'>Programme: </div>
            <div className='col-span-6'> {student.programme?.name}</div>
            <div className='col-span-3'>Enrollment Year: </div>
            <div className='col-span-3'>
              {student.semester > 0 ? enrollmentYear : phdEnrollmentYear}
            </div>

            <div className='col-span-2'>Blood Group: </div>
            <div className='col-span-1'>{bloodGroup}</div>

            <div className='col-span-3'>Hostel: </div>
            <div className='col-span-3'>
              {student.student?.hostels?.find(
                (h) =>
                  h.pivot?.payment_id != null &&
                  h.pivot?.in_date != null &&
                  h.pivot?.out_date == null
              )?.code ?? "N/A"}
            </div>
            <div className='col-span-2'>Room No.: </div>
            <div className='col-span-1'>
              {student.student?.hostels?.find(
                (h) =>
                  h.pivot?.payment_id != null &&
                  h.pivot?.in_date != null &&
                  h.pivot?.out_date == null
              )?.pivot.room_no ?? "N/A"}
            </div>
            <div className='col-span-3'>Address: </div>
            {/* <div className='col-span-6 capitalize'>
              {address} {pin ? "-" + pin : ""}, {district}, {
                                        states?.find((s) => s.id == stateId)
                                          ?.name
                                      }
            </div> */}

            <div className='col-span-6 capitalize'>
              {[
                address,
                pin && "-" + pin,
                district,
                states?.find((s) => s.id == stateId)?.name,
              ]
                .filter(Boolean) // Remove undefined or falsy values
                .join(", ")}{" "}
              {/* Join the parts with a comma and space */}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-end justify-between'>
          <img
            src={student.student?.student_photo}
            className='float-right  w-10'
            alt='Avatar'
          />
        </div>
      </div>
      <div
        className='text-center px-2'
        style={{
          position: "absolute",
          bottom: "15px",
          margin: " 0 auto",
          width: "3.4in",
        }}
      >
        <img
          style={{ filter: "brightness(0%)" }}
          className='w-10 mt-o float-right '
          src='/assets/dean-sign.png'
        />
      </div>
      <div
        className='text-center px-2'
        style={{
          position: "absolute",
          bottom: "1px",
          margin: " 0 auto",
          width: "3.3in",
        }}
      >
        <p
          className='text-xxs float-left'
          style={({ color: "#000 !important" }, { "font-weight": "700" })}
        >
          Issued On:{" "}
          {student.id_card?.created_at
            ? moment(student.id_card?.created_at).format("DD-MM-YYYY")
            : " "}{" "}
          | Valid Upto:
          {student.id_card?.valid_upto
            ? moment(student.id_card?.valid_upto).format("DD-MM-YYYY")
            : ""}
        </p>

        <p
          className='text-xxs float-right'
          style={({ color: "#000 !important" }, { "font-weight": "700" })}
        >
          {" "}
          Dean, Student Affairs
        </p>
      </div>
    </div>
  );
}
