import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const Experience = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [experiences, setExperiences] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    institute_organization: "",
    department: "",
    organization_master_id: "",
    organization_url: "",
    designation: "",
    start_date: "",
    end_date: "",
  });
  const headers = {
    institute_organization: {
      label: "Institute/Organization",
      path: "institute_organization",
    },
    department: { label: "Department", path: "department" },
    organization_master_id: {
      label: "Organization Type",
      path: "organization_master.name",
    },
    organization_url: {
      label: "Organization's Website",
      path: "organization_url",
    },
    designation: { label: "Designation", path: "designation" },
    start_date: { label: "Start Date", path: "start_date" },
    end_date: { label: "End Date", path: "end_date" },
  };

  const validationSchema = Yup.object({
    institute_organization: Yup.string().required(
      "Institute/Organization is required"
    ),
    department: Yup.string().required("Department is required"),
    organization_master_id: Yup.string().required(
      "Organization Type is required"
    ),
    designation: Yup.string().required("Designation is required"),
    start_date: Yup.string().required("Start Date is required"),
    // end_date: Yup.string().required("End Date is required"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/experiences");
      setExperiences(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/experiences/${editId}`, values);
        toast.success(`Experiences updated successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/experiences", values);
        toast.success(`Experiences added successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = experiences.find(
        (experience) => experience.id === id
      );
      setInitialValues({
        id: editableData.id,
        institute_organization: editableData.institute_organization,
        department: editableData.department,
        organization_master_id: editableData.organization_master_id,
        organization_url: editableData.organization_url,
        designation: editableData.designation,
        start_date: editableData.start_date,
        end_date: editableData.end_date,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      institute_organization: "",
      department: "",
      organization_master_id: "",
      organization_url: "",
      designation: "",
      start_date: "",
      end_date: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      institute_organization: "",
      department: "",
      organization_master_id: "",
      organization_url: "",
      designation: "",
      start_date: "",
      end_date: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    try {
      await BaseApi().delete(`/profile/experiences/${id}`);
      toast.success(`Experiences deleted successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Experience :</h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            headers={headers}
            data={experiences}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default Experience;
