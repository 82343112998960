import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Button from "../../Components/Utils/Button";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "../../Components/Utils/Input";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
 

import {
  getDepartmentsAsync,
  getDesignationsAsync,
} from "../../Redux/MasterSlice";
  
// import BasicInput from "../../Components/Utils/BasicInput";
// import Select from "../../Components/Utils/Select";
// import CustomSelect from "../../Components/Utils/CustomSelect";

export default function StaffAdminEdit() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [showCropModalSignature, setShowCropModalSignature] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [signatureFileUrl, setSignatureFileUrl] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedSignatureImage, setCroppedSignatureImage] = useState(null);
  const cropperRef = useRef();
  const cropperRefSignature = useRef();

  // const [selectedIds, setSelectedIds] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  const [singleStaff, setSingleStaff] = useState([]);
  // const [singleStaff, setSingleStaff] = useState([]);
  const formikRef = useRef();
  const master = useSelector((state) => state.master);
  const departments = [...master.departments];
  const designations = [...master.designations];
 
  const [formRole, setFormRole] = useState("edit");

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setFileUrl(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setShowCropModal(true);
    }
  };

  const onCrop = () => {
    // if (typeof cropperRef?.current?.getCroppedCanvas() === "undefined") {
    //   return;
    // }
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    setCroppedImage(
      cropper
        .getCroppedCanvas({
          width: 200,
          height: 300,
        })
        .toDataURL()
    );
  };

  const onUpload = async () => {
    setLoading(true);
    console.log(croppedImage);
    const blob = await fetch(croppedImage).then((r) => r.blob());
    const formData = new FormData();
    formData.append("photo", blob, "cropped-image.jpg");
    formData.append("admin", 1);
    await BaseApi()
      .post(`staff/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let _singleStaff = { ...singleStaff };
        _singleStaff.photo = response.data.photo;
        _singleStaff.profile_pic = response.data.profile_pic;
        setSingleStaff(_singleStaff);
        toast.success("Data Updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        document.getElementById("file").value = "";

        setFileUrl("");
        setShowCropModal(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      });
    setLoading(false);
  };

  const onSelectSignatureFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSignatureFileUrl(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setShowCropModalSignature(true);
    }
  };

  const onSignatureCrop = () => {
    // if (typeof cropperRef?.current?.getCroppedCanvas() === "undefined") {
    //   return;
    // }
    const imageElement = cropperRefSignature?.current;
    const cropper = imageElement?.cropper;

    setCroppedSignatureImage(
      cropper
        .getCroppedCanvas({
          width: 200,
          height: 300,
        })
        .toDataURL()
    );
  };

  const onSignatureUpload = async () => {
    setLoading(true);
    console.log(croppedSignatureImage);
    const blob = await fetch(croppedSignatureImage).then((r) => r.blob());
    const formData = new FormData();
    formData.append("signature", blob, "cropped-signature.jpg");
    formData.append("admin", 1);
    await BaseApi()
      .post(`staff/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let _singleStaff = { ...singleStaff };
        _singleStaff.photo = response.data.photo;
        _singleStaff.profile_pic = response.data.profile_pic;
        _singleStaff.signature = response.data.signature;
        _singleStaff.signature_file = response.data.signature_file;

        setSingleStaff(_singleStaff);
        toast.success("Data Updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
        document.getElementById("signatureFile").value = "";

        setSignatureFileUrl("");
        setShowCropModalSignature(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,

          progress: undefined,
        });
      });
    setLoading(false);
  };

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
    if (designations.length === 0) {
      dispatch(getDesignationsAsync());
    }
    getStaff();

    // setErrorMessage("Unauthorised Access");

    // dispatch(messageSlice.actions.clearMessage());

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getStaff() {
    console.log("as");
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        let _singleStaff = data.data;
        setSingleStaff(_singleStaff);
        setInitialValues({
          id: id,
          admin:1,
          address: _singleStaff.address,
        
        
        });
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });

    setLoading(false);
  }

  // const onEdit = (event, staff) => {
  //   event.preventDefault();
  //   setInitialValues({
  //     id: staff.id,
  //     name: staff.name,
  //     email: staff.email,
  //     phone: staff.phone,
  //   });

  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setFormRole("edit");
  // };

  const validationSchema = Yup.object().shape({
  
    
    address: Yup.string().nullable().required("This field is required!"),
     
  });

  const handleSubmit = async (payload) => {
    console.log("ad");
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(
        key,

        payload[key]
      );
    }

    if (formRole === "edit") {
      await BaseApi()
        .post(`staff/${payload.id}`, form_data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setSingleStaff(response.data);
          toast.success("Data Updated!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
          toast.error(message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,

            progress: undefined,
          });
        });
    }

    setLoading(false);
    setFormRole("edit");
    
  };

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Edit Details for ID Card" />

            <div className="">
              {errorMessage && <Alert message={errorMessage} />}
            </div>
            {initialValues && (
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <h3 className="text-xl pt-6"></h3>

                  <div className="   grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  gap-8">
                  
                    <Input
                      as="textarea"
                      label="Address"
                      name="address"
                      placeholder="Address"
                    />
                    
                  
                   
                     <div className="col-span-3">
                      <div>
                        <img
                          style={{ width: "100px" }}
                          className="my-5  border-2 border-r-4 border-grey-400"
                          src={singleStaff.profile_pic}
                          onError={(e) => {
                            console.log(e.target.src);
                            if (
                              e.target.src !=
                                "https://ui-avatars.com/api/name=?" +
                                  singleStaff.name ??
                              "NO"
                            )
                              e.target.src =
                                "https://ui-avatars.com/api/?name=" +
                                  singleStaff.name ?? "NO";
                          }}
                        />
                        <label className="font-bold">Upload Photo</label> <br/>
                        <input
                          id="file"
                          type="file"
                          accept="image/*"
                          onChange={onSelectFile}
                        />

                        {showCropModal && (
                          <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                  {/*header*/}
                                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                      Crop Image
                                    </h3>
                                    <button
                                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                      onClick={() => {
                                        document.getElementById("file").value =
                                          "";

                                        setFileUrl("");
                                        setShowCropModal(false);
                                      }}
                                    >
                                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                      </span>
                                    </button>
                                  </div>
                                  {/*body*/}
                                  <div className="relative p-6  grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3  gap-8">
                                    <div className="col-span-2">
                                      <span className="text-grey-700 mb-5">
                                        Crop your image here: <br />
                                      </span>
                                      <Cropper
                                        viewMode={1}
                                        src={fileUrl}
                                        style={{ height: 200, width: "100%" }}
                                        initialAspectRatio={0.66666666666}
                                        guides={true}
                                        cropBoxResizable={false}
                                        cropBoxMovable={false}
                                        aspectRatio={0.66666666666}
                                        crop={onCrop}
                                        ref={cropperRef}
                                        center="true"
                                        dragMode={"move"}
                                      />
                                    </div>
                                    {croppedImage && (
                                      <div>
                                        <span className="text-grey-700 mb-2">
                                          Preview: <br />
                                        </span>
                                        <img
                                          className="  border-2 border-r-4"
                                          src={croppedImage}
                                          alt="Cropped"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {/*footer*/}
                                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() => {
                                        document.getElementById("file").value =
                                          "";

                                        setFileUrl("");
                                        setShowCropModal(false);
                                      }}
                                    >
                                      Close
                                    </button>
                                    <button
                                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={loading ? null : onUpload}
                                    >
                                      {loading
                                        ? "Uploading..."
                                        : "Crop and Upload"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                          </>
                        )}
                      </div>{" "}
                      
                    </div>
                    <div className="col-span-3">
                      <div>
                        <img
                          style={{ width: "400px" , height:"100px"}}
                          className="my-5  border-2 border-r-4 border-grey-400"
                          src={singleStaff.signature_file}
                          onError={(e) => {
                            console.log(e.target.src);
                            if (e.target.src != "/assets/no-sign.jpg")
                              e.target.src = "/assets/no-sign.jpg";
                          }}
                        /><label className="font-bold">Upload Signature</label> <br/>
                        <input
                         
                          id="signatureFile"
                          type="file"
                          accept="image/*"
                          onChange={onSelectSignatureFile}
                        />

                        {showCropModalSignature && (
                          <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                  {/*header*/}
                                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                      Crop Image
                                    </h3>
                                    <button
                                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                      onClick={() => {
                                        document.getElementById("signatureFile").value =
                                          "";

                                        setSignatureFileUrl("");
                                        setShowCropModalSignature(false);
                                      }}
                                    >
                                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                      </span>
                                    </button>
                                  </div>
                                  {/*body*/}
                                  <div className="relative p-6  grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3  gap-8">
                                    <div className="col-span-2">
                                      <span className="text-grey-700 mb-5">
                                        Crop your signature here: <br />
                                      </span>
                                      <Cropper
                                        viewMode={0}
                                        src={signatureFileUrl}
                                        style={{ height: 200, width: "100%" }}
                                        initialAspectRatio={4}
                                        guides={true}
                                        cropBoxResizable={true}
                                        cropBoxMovable={false}
                                        aspectRatio={4}
                                        crop={onSignatureCrop}
                                        ref={cropperRefSignature}
                                        center="true"
                                        dragMode={"move"}
                                      />
                                    </div>
                                    {croppedSignatureImage && (
                                      <div>
                                        <span className="text-grey-700 mb-2">
                                          Preview: <br />
                                        </span>
                                        <img
                                          className="  border-2 border-r-4"
                                          src={croppedSignatureImage}
                                          alt="Cropped"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {/*footer*/}
                                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() => {
                                        document.getElementById("signatureFile").value =
                                          "";

                                        setSignatureFileUrl("");
                                        setShowCropModalSignature(false);
                                      }}
                                    >
                                      Close
                                    </button>
                                    <button
                                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={loading ? null : onSignatureUpload}
                                    >
                                      {loading
                                        ? "Uploading..."
                                        : "Crop and Upload"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                          </>
                        )}
                      </div>{" "}
                     
                    </div>

                  </div>
                    <Input type="hidden" name="admin" value="1" />
                    {/* <Input label="Signature" type="file" name="signature" />   */}
                 
                  <div className="text-center mt-5">
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        type="submit"
                      />
                    ) : (
                      <>
                        <Button
                          value={loading ? "Updating..." : "Update"}
                          icon={"SaveIcon"}
                          type="submit"
                        />
                        <span className="mr-5"></span>
                      </>
                    )}
                  </div>
                </Form>
              </Formik>
            )}
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
