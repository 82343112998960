import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: { label: "Role", path: "role" },
  thesis_title: { label: "Thesis Title", path: "thesis_title" },
  other_institute: {
    label: "Name of the University/Institute",
    path: "other_institute",
  },
  status: { label: "Status", path: "status" },
};

const ExternalPhdScholar = () => {
  const [externalPhdScholars, setExternalPhdScholars] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/external-phd-scholars");
      setExternalPhdScholars(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        External Ph. D. Scholar
      </h2>
      <TableViewProfile
        headers={headers}
        data={externalPhdScholars}
        type="external-phd-scholars"
        setData={setExternalPhdScholars}
      />
    </div>
  );
};

export default ExternalPhdScholar;
