import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import { FaBriefcase, FaGlobe } from "react-icons/fa6";

const headers = {
  institute_organization: {
    label: "Institute/Organization",
    path: "institute_organization",
  },
  department: { label: "Department", path: "department" },
  organization_master_id: {
    label: "Organization Type",
    path: "organization_master.name",
  },
  organization_url: { label: "Organization URL", path: "organization_url" },
  designation: { label: "Designation", path: "designation" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const Experience = ({ view }) => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     const response = await BaseApi().get("/profile/experiences");
  //     const filterField = view === "iqac" ? "iqac" : "website";
  //     setExperiences(
  //       response.data.data.filter((q) => q[filterField] === "yes")
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/experiences");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredExperiences = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort the experiences by start_date (latest first)
      const sortedExperiences = filteredExperiences.sort((a, b) => {
        const startA = moment(a.start_date);
        const startB = moment(b.start_date);
        return startB - startA; // Sort in descending order (latest first)
      });

      setExperiences(sortedExperiences);
    } catch (error) {
      console.error(error);
    }
  };

  const renderExperience = (experience) => {
    const instituteOrganization = getValueByPath(
      experience,
      headers.institute_organization.path
    );
    const department = getValueByPath(experience, headers.department.path);
    const organizationType = getValueByPath(
      experience,
      headers.organization_master_id.path
    );
    const organizationUrl = getValueByPath(
      experience,
      headers.organization_url.path
    );
    const designation = getValueByPath(experience, headers.designation.path);
    const startDate = formatDate(
      getValueByPath(experience, headers.start_date.path)
    );
    const endDateValue = getValueByPath(experience, headers.end_date.path);
    const endDate = endDateValue
      ? moment(endDateValue).format("YYYY")
      : "Present";

    return (
      <div className="flex items-center justify-between px-2 py-2 border-b">
        <div className="w-1/4 pr-8">
          <p className="font-normal text-gray-600">
            <span className="px-3 py-1 text-sm font-medium text-gray-700 bg-blue-200 rounded-full">
              {moment(startDate).format("YYYY")} - {endDate}
            </span>
          </p>
        </div>
        <div className="w-3/4">
          <span className="text-base font-medium text-gray-600">
            {designation} at{" "}
            <a
              href={organizationUrl ?? "#"}
              className="text-blue-600 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {instituteOrganization}
            </a>
          </span>
          <span className="block text-sm font-normal text-gray-500">
            {department}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="flex items-center justify-start gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        <FaBriefcase />
        Experience
      </h2>
      {experiences.length ? (
        <div className="">
          {experiences.map((experience, index) => (
            <div key={index} className="">
              {renderExperience(experience)}
            </div>
          ))}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default Experience;
