import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../Components/Utils/Alert";
import BaseApi from "../../Http/BaseApi";
import * as HIcons from "@heroicons/react/outline";
import { ThreeDots } from "react-loader-spinner";

export default function ProfilePage() {
  const dispatch = useDispatch();
  const [staff, setStaff] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const authData = useSelector((state) => state.auth);
  useEffect(() => {
    getStaff(authData.user.id);
  }, [dispatch, authData]);

  const getStaff = async (id) => {
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        setStaff(data.data);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
    setLoading(false);
  };

  return (
    <div className='mx-auto '>
      {loading == true && (
        <>
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        </>
      )}

      {staff != null && (
        <div>
          <div className=''>
            {errorMessage && <Alert message={errorMessage} />}
          </div>
          <div className='relative w-full mx-auto bg-white rounded-lg shadow'>
            <div className='flex justify-center'>
              <img
                src={staff.profile_pic}
                alt=''
                className='w-32 h-32 mx-auto transition duration-200 transform border-4 border-white rounded-full shadow-md hover:scale-110'
              />
            </div>

            <div className='mt-5'>
              <h1 className='text-3xl font-bold text-center text-gray-900'>
                {staff.full_name}
              </h1>
              {/* <p className="text-sm font-medium text-center text-gray-700">
                {staff.designation?.name} - {staff.department?.name}
              </p> */}
              <p>
                <span></span>
              </p>
              <div className='px-6 my-5 text-center'>
                <span className='inline-flex justify-center font-bold'>
                  <HIcons.PhoneIcon
                    aria-hidden='true'
                    className='w-8 h-8 mb-4 text-black'
                  />
                  {staff.phone}
                  <a
                    href={`staff/edit-phone/${staff.id}`}
                    className='float-right m-1 text-blue-700'
                  >
                    <HIcons.PencilIcon
                      aria-hidden='true'
                      className='w-4 h-4 mb-4 text-blue-700'
                    />
                  </a>
                </span>
                <span className='inline-flex justify-center font-bold'>
                  {staff.email_verified == 0 || staff.email_verified == null ? (
                    <>
                      <HIcons.MailIcon
                        aria-hidden='true'
                        className='w-8 h-8 mb-4 text-black'
                      />
                      <span className='text-red-700'>Verify Email</span>
                      <a
                        href={`staff/edit-email/${staff.id}`}
                        className='float-right m-1 text-blue-700'
                      >
                        <HIcons.PencilIcon
                          aria-hidden='true'
                          className='w-4 h-4 mb-4 text-blue-700'
                        />
                      </a>{" "}
                    </>
                  ) : (
                    <>
                      <HIcons.MailIcon
                        aria-hidden='true'
                        className='w-8 h-8 mb-4 text-black'
                      />
                      {staff.email}
                      <a
                        href={`staff/edit-email/${staff.id}`}
                        className='float-right m-1 text-blue-700'
                      >
                        <HIcons.PencilIcon
                          aria-hidden='true'
                          className='w-4 h-4 mb-4 text-blue-700'
                        />
                      </a>
                    </>
                  )}
                </span>
              </div>
              <div className='flex items-center justify-center '>
                <a
                  href={`staff/edit/${staff.id}`}
                  className='inline-flex items-center px-4 py-2 text-blue-500 transition duration-150 ease-in-out bg-white border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 '
                >
                  <HIcons.PencilAltIcon className='w-5 h-5 mr-2 -ml-1' />
                  Edit Profile
                </a>
              </div>
              <div className='grid-cols-2 gap-4 lg:grid lg:mx-auto lg:w-2/4'>
                <div className=''>
                  {" "}
                  <div className='w-full'>
                    {/* <h3 className="px-6 text-lg font-medium text-left text-gray-900">
                      Basic Info.
                    </h3> */}
                    <div className='w-full mt-1 overflow-hidden text-sm'>
                      <dl className='my-8 leading-loose'>
                        <div className='px-6 bg-white '>
                          <dt className='text-lg font-medium'>Name:</dt>
                          <dd className='ml-2'> {staff.full_name}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Father&apos;s Name:
                          </dt>
                          <dd className='ml-2'>{staff.fathers_name}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Department:
                          </dt>
                          <dd className='ml-2'>{staff.department?.name}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Designation:
                          </dt>
                          <dd className='ml-2'>{staff.designation?.name}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Contact Information:
                          </dt>
                          <dd className='ml-2'>Email: {staff.email}</dd>
                          <dd className='ml-2'>Phone: {staff.phone}</dd>
                          <dd className='ml-2'>Address: {staff.address}</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className=''>
                  <div className='w-full'>
                    {/* <h3 className="px-6 text-lg font-medium text-left text-gray-900">
                      Basic Info.
                    </h3> */}
                    <div className='w-full mt-1 overflow-hidden text-sm'>
                      <dl className='my-8 leading-loose'>
                        <div className='px-6 bg-white '>
                          <dt className='mt-2 text-lg font-medium'>
                            Date of Birth:
                          </dt>
                          <dd className='ml-2'>{staff.dob}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Mother&apos;s Name:
                          </dt>
                          <dd className='ml-2'>{staff.mothers_name}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Date of Joining:
                          </dt>
                          <dd className='ml-2'>{staff.dojoining}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Blood Group:
                          </dt>
                          <dd className='ml-2'>{staff.blood_group}</dd>
                          <dt className='mt-2 text-lg font-medium'>
                            Nature of Employment
                          </dt>
                          <dd className='ml-2'>{staff.nature_of_employee}</dd>
                          <dt className='mt-2 text-lg font-medium'>PAN</dt>
                          <dd className='ml-2 uppercase'>{staff.pan}</dd>
                          {/* <dt className="mt-2 text-lg font-medium">PAN</dt>
                          <dd className="ml-2">{staff.pan}</dd> */}

                          {/* <dt className="mt-2 text-lg font-medium">
                            Bank Details.
                          </dt>
                          <dd className="ml-2"></dd> */}
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
